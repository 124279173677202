// import { Actions } from ".";
import * as types from "../constTypes";

const initialState = {};

export const getLandList = (state = initialState, action) => {
  switch (action.type) {
    case types.landdetailTypes.LAND_LIST:
      return action.payload || [];
    default:
      return state;
  }
};

export const farmers_farming_land_list = (state = initialState, action) => {
  switch (action.type) {
    case types.landdetailTypes.FARMER_FARMING_LAND_LIST:
      return action.payload || [];
    default:
      return state;
  }
};
