import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pricelistActions } from "../../../redux/actions";

export const usePriceListHooks = () => {
  const dispatch = useDispatch();

  const { pricelistgroupDropdownList } = useSelector((obj) => obj);

  const get_pricelistGroupDropdownList = () =>
    dispatch(pricelistActions.getPricelistGroupDropdownList());

  useEffect(() => {
    get_pricelistGroupDropdownList();
  }, []);

  return {
    pricelistgroupDropdownList,
  };
};
