import { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";
import highcharts3d from "highcharts/highcharts-3d";
import highchartsMore from "highcharts/highcharts-more";
import highchartsolidguage from "highcharts/modules/solid-gauge";
// import "highcharts/css/themes/dark-unica.css";

Highcharts.setOptions({
  colors: Highcharts.map(Highcharts.getOptions().colors, function (color) {
    return {
      radialGradient: {
        cx: 0.5,
        cy: 0.3,
        r: 0.7,
      },
      stops: [
        [0, color],
        [1, Highcharts.color(color).brighten(-0.3).get("rgb")], // darken
      ],
    };
  }),
});

drilldown(Highcharts);
highcharts3d(Highcharts);
highchartsMore(Highcharts);
highchartsolidguage(Highcharts);

//solidGauge(Highcharts);

export const HighCharts = (props) => {
  const { options } = props;
  const chartComponentRef = useRef(null);
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        ...options,
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: true,
        },
      }}
      ref={chartComponentRef}
    />
  );
};

export default HighCharts;
