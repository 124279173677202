// @mui
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

// eslint-disable-next-line
export default function CustomAutoComplete({
  label,
  placeholder,
  key,
  error,
  ...other
}) {
  return (
    <Autocomplete
      noOptionsText="No Record Found"
      key={key}
      renderInput={(params) => (
        <TextField
          label={label}
          inputProps={{
            style: {
              height: "15px",
            },
          }}
          placeholder={placeholder}
          error={error}
          {...params}
        />
      )}
      {...other}
    />
  );
}
