import * as types from "../constTypes";

const initialState = {};

export const employeeList = (state = initialState, action) => {
  switch (action.type) {
    case types.employeeTypes.EMPLOYEE_DETAIL_LIST:
      return action.payload || [];
    default:
      return state;
  }
};

export const employeeDropdownList = (state = [], action) => {
  switch (action.type) {
    case types.employeeTypes.EMPLOYEE_DROPDOWN_LIST:
      return action.payload || [];
    default:
      return state;
  }
};

export const employee_farmer_list = (state = [], action) => {
  switch (action.type) {
    case types.employeeTypes.EMPLOYEE_FARMER_LIST:
      return action.payload || [];
    default:
      return state;
  }
};

export const employee_farmer_list_by_id = (state = initialState, action) => {
  switch (action.type) {
    case types.employeeTypes.EMPLOYEE_FARMER_LIST_BY_ID:
      return action.payload;
    default:
      return state;
  }
};

export const farmer_slot_booking_list = (state = [], action) => {
  switch (action.type) {
    case types.employeeTypes.FARMER_SLOT_BOOKING_LIST:
      return action.payload;
    default:
      return state;
  }
};
