/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import {
  bagissueActions,
  commonActions,
  farmerpurchaseActions,
  productActions,
} from "../../redux/actions";
import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { keys } from "../../config/keys";
import { useLocation, useNavigate } from "react-router-dom";
import { GridAction } from "../../common/useGridAction";
import { toast } from "react-toastify";
import { usePermissionHooks } from "../../common/useCheckPermission";
import { IconButton } from "@mui/material";
import { Print } from "@mui/icons-material";
import { get_farmer_purchase_by_id } from "../../redux/actions/farmerpurchase";

export const useInvoiceListHooks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const permission = usePermissionHooks(location);

  const { invoice_list } = useSelector((obj) => obj);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [printData, setPrintData] = useState(null);
  const [showprint, setShowPrint] = useState(false);
  const [printType, setPrintType] = useState({ gatepass: true });
  const column = useMemo(
    () => [
      {
        Header: "Invoice Date",
        accessor: "voucherdate",
        Cell: (props) => keys().shortDate(props.value),
      },
      {
        Header: "Invoice #",
        accessor: "voucherno",
      },
      {
        Header: "Farmer",
        accessor: "farmer",
      },
      {
        Header: "Grand Total",
        accessor: "grandtotal",
      },
      {
        Header: "Qty/Maan",
        accessor: "totalqtymaan",
      },
      {
        Header: "Price/Maan",
        accessor: "pricepermaan",
      },
      {
        id: "printinvoice",
        Header: "Print Invoice",
        accessor: "",
        Cell: (cellProps) => {
          return (
            <IconButton
              size={"small"}
              color="info"
              disabled={!cellProps.row.original.billgenerated}
              onClick={() =>
                cellProps.row.original.billgenerated
                  ? onPrintClick(cellProps, false)
                  : null
              }
            >
              <Print fontSize={"small"} />
            </IconButton>
          );
          //_purchase_by_id(formData?.id_voucherno);
        },
      },
      {
        ...GridAction({
          permission: { edit: false, delete: true },
          onEditClick: (cellProps) => {
            navigate("/farmerpurchases/add", { state: cellProps.row.original });
          },
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original.id);
          },
        }),
      },
    ],
    // eslint-disable-next-line
    []
  );
  const onPrintClick = (cellProps, gatepass) => {
    dispatch(
      get_farmer_purchase_by_id(cellProps.row.original.purchaseno, {
        onSuccess: (res) => {
          setLoading(false);
          if (res?.data?.rows[1].length > 0) {
            const data = res?.data?.rows[1][0];
            const printData = {
              ...data,
              purchasedate: dayjs(data.purchasedate),
              purchasedetails: res?.data?.rows[2],
            };
            setPrintType({ gatepass });
            setPrintData(printData);
            setShowPrint(true);
            //navigate("/print", { state: { data: printData } });
            // setFormData((prev) => ({
            //   ...prev,
            //   ...data,
            //   purchasedate: dayjs(data.purchasedate),
            //   purchasedetails: res?.data?.rows[2],
            // }));
          }
        },
        onError: () => setLoading(false),
      })
    );
  };

  
  const onGridDelete = async () => {
    setDeleteLoading(true);
    if (deleteId > 0) {
      dispatch(
        farmerpurchaseActions.delete_farmer_invoice(
          { id: deleteId },
          {
            onSuccess: () => {
              toast.success("Invoice Deleted Successfully!!!");
              _invoice_list();
            },
            onError: (err) =>
              toast.error("Something went wrong. Please try again."),
          }
        )
      );
      setDeleteLoading(false);
      setDeleteModal(false);
    }
  };

  const _invoice_list = () =>
    dispatch(
      farmerpurchaseActions.get_invoice_list({
        onSuccess: () => setLoading(false),
        onError: () => setLoading(false),
      })
    );

  useEffect(() => {
    setLoading(!loading);
    _invoice_list();
    //checkSerialPort();
  }, []);

  return {
    invoice_list,
    loading,
    deleteModal,
    onGridDelete,
    deleteLoading,
    setDeleteModal,
    column,
    permission,
    showprint,
    printData,
    setShowPrint,
    printType,
  };
};
