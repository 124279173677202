import * as types from "../constTypes";

const initialState = [];

export const surveyList = (state = initialState, action) => {
  switch (action.type) {
    case types.SURVEY_LIST:
      return action.payload || [];
    default:
      return state;
  }
};

export const farmer_survey_list = (state = [], action) => {
  switch (action.type) {
    case types.FARMER_SURVEY_LIST:
      return action.payload || [];
    default:
      return state;
  }
};
