import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { get_templates_list_by_type } from "../../redux/actions/templates";
import TemplateComponent from "react-mustache-template-component";
import { useReactToPrint } from "react-to-print";
import DownloadFarmerAgreement from "./DownloadFarmerAgreement";
import { Button } from "@mui/material";
import { Download, Print } from "@mui/icons-material";
import html2pdf from "html2pdf.js";

const Agreement = (props) => {
  console.log(props);
  const dispatch = useDispatch();

  const params = useParams();
  console.log(params);
  const [template, setTemplate] = useState("");
  const [singleData, setSingleData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // dispatch(
    //   get_templates_list_by_type("agreement", {
    //     onSuccess: (res) =>
    //       setTemplate(res.length > 0 ? res[0].templatecontent : ""),
    //   })
    // );

    const t = localStorage.getItem("agreement");
    //console.log(t);
    try {
      console.log(JSON.parse(t));
      setSingleData(JSON.parse(t));
    } catch (ex) {}
    // setSingleData({ ...t });
    // console.log(t);
  }, []);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleDownload = () => {
    const element = document.getElementById("agreementPdf");
    const options = {
      filename: `${singleData?.farmerCode}.pdf`,
      //image: { type: 'jpeg', quality: 0.20 },
      html2canvas: { scale: 2, useCORS: true },
    };

    html2pdf().set(options).from(element).save();
    // setCounter(0);
  };

  return (
    <div>
      {singleData && Object.keys(singleData).length > 0 && (
        <div className="m-4 g-3">
          <Button
            className="custom-btn-page-layout custom-btn-purple"
            variant="contained"
            startIcon={<Print />}
            onClick={handlePrint}
          >
            {`Print`}
          </Button>
          <Button
            className="custom-btn-page-layout custom-btn-warning ms-3"
            variant="contained"
            startIcon={<Download />}
            onClick={handleDownload}
          >
            {`Download`}
          </Button>
          <DownloadFarmerAgreement
            ref={componentRef}
            selectedPdfRow={singleData}
            setMainLoader={() => setLoading(false)}
            download={false}
            showPrint={true}
          />
        </div>
      )}
      {/* {Object.keys(singleData).length > 0 && (
        <TemplateComponent template={template} data={{ ...singleData }} />
      )} */}
    </div>
  );
};

export default Agreement;
