import * as types from "../constTypes";

const pricelistgroup_dropdown_list = [];
// const initialState = {};

export const pricelistgroupDropdownList = (
  state = pricelistgroup_dropdown_list,
  action
) => {
  switch (action.type) {
    case types.pricelistTypes.PRICELIST_GROUP_DROPDOWN_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const farmer_price_list = (initialstate = [], action) => {
  switch (action.type) {
    case types.NG_FARMER_PRICE_LIST:
      return action.payload;
    default:
      return initialstate;
  }
};
