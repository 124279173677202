// import { Actions } from ".";
import * as types from "../constTypes";

const initialState = {};

export const yieldEstimationList = (state = initialState, action) => {
  switch (action.type) {
    case types.yieldEstimationTypes.YIELDESTIMATION_LIST:
      return action.payload || [];
    default:
      return state;
  }
};

const yieldestimation_config_list = [];
export const yieldestimationconfigList = (
  state = yieldestimation_config_list,
  action
) => {
  switch (action.type) {
    case types.yieldEstimationTypes.YIELDESTIMATION_CONFIG_LIST:
      return action.payload;
    default:
      return state;
  }
};