import * as types from "../constTypes";

const initialState = {
  farmercount_widget: [],
  village_farmer_count: [],
  taluka_farmer_count: [],
  district_farmer_count: [],
  bags_issued: [],
  yield_estimated: [],
  average_yield_estimation: [],
  variety_wise_land_size: [],
  variety_wise_village: [],
  variety_wise_village_drill: [],
  variety_wise_yield_estimation: [],
};

export const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case types.DASH_FARMER_VILLAGE_WISE:
      return { ...state, village_farmer_count: action.payload || [] };
    case types.DASH_FARMER_TALUKA_WISE:
      return { ...state, taluka_farmer_count: action.payload || [] };
    case types.DASH_FARMER_DISTRICT_WISE:
      return { ...state, district_farmer_count: action.payload || [] };
    case types.DASH_BAGS_ISSUED:
      return { ...state, bags_issued: action.payload || [] };
    case types.DASH_YIELD_ESTIMATED:
      return { ...state, yield_estimated: action.payload || [] };
    case types.DASH_AVERAGE_YIELD_ESTIMATED:
      return { ...state, average_yield_estimation: action.payload || [] };
    case types.DASH_VARIETY_WISE_LAND_SIZE:
      return { ...state, variety_wise_land_size: action.payload || [] };
    case types.DASH_VILLAGE_WISE_VARIETY:
      return { ...state, variety_wise_village: action.payload || [] };
    case types.DASH_VILLAGE_WISE_VARIETY_DRILL_DATA:
      return { ...state, variety_wise_village_drill: action.payload || [] };
    case types.DASH_VILLAGE_WISE_YIELD_ESTIMATION:
      return { ...state, variety_wise_yield_estimation: action.payload || [] };
    default:
      return state;
  }
};
