import { Col, Row } from "reactstrap";
import PageLayout from "../../Component/PageLayout";
import QrReader from "../../common/QRScanner";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { textFieldProps } from "../../common/commonControlProps";
import { FindInPage } from "@mui/icons-material";
import { useState } from "react";

const IncentiveScanner = () => {
  const navigate = useNavigate();
  const onSuccess = (data) => {
    navigate(`/scanincentiveupdate/${Number(data)}`);
  };
  const [vocuherno, setVoucherno] = useState(0);
  return (
    <PageLayout title={"Incentive Scanner"}>
      <Row justify className="m-3 justify-content-between" >
        <Col lg={4} md={4} sm={12}>
          <TextField
            {...textFieldProps}
            label="Voucher #"
            type={"number"}
            value={vocuherno}
            onChange={(e) => setVoucherno(e.target.value)}
          />
          <Button
            className="custom-btn-page-layout custom-btn-purple text-white "
            startIcon={<FindInPage />}
            fullWidth
            type="button"
            onClick={() => onSuccess(vocuherno)}
            disabled={vocuherno <= 0}
          >
            {`Get Details`}
          </Button>
        </Col>
      </Row>
      <Row justify className="m-3">
        <Col lg={4} md={4} sm={12}>
          <QrReader type="incentiveupdate" onSuccess={onSuccess} />
        </Col>
      </Row>
    </PageLayout>
  );
};

export default IncentiveScanner;
