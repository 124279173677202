import React, { useMemo, useEffect, useCallback, useState } from "react";
import PageLayout from "../../Component/PageLayout";
import { Col, Input, InputGroup, InputGroupText } from "reactstrap";
import TableContainer from "../../Component/TableContainer";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Component/DeleteModal";
import CustomPagination from "../../Component/CustomPagination";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import useBreakPoints from "../../hooks/useBreakPoints";
import { employeeActions } from "../../redux/actions";
import { usePermissionHooks } from "../../common/useCheckPermission";
import { GridAction } from "../../common/useGridAction";
import LoaderComponent from "../../Component/LoaderComponent";
import { Avatar } from "@mui/material";

const EmployeeList = () => {
  const location = useLocation();
  const { permissions, token } = useSelector((state) => state.layout);
  const { employeeDropdownList } = useSelector((state) => state);
  const permission = usePermissionHooks(location);
  let permissionsObj = {};
  // eslint-disable-next-line
  permissions?.filter((er) => {
    if (er?.ModuleData?.length > 0) {
      // eslint-disable-next-line
      let filtererdDarta = er?.ModuleData?.filter((ed) => {
        if (ed?.subModuleData?.length > 0) {
          // eslint-disable-next-line
          ed?.subModuleData?.map((qw) => {
            if (qw?.path === "/employeeList") {
              permissionsObj = qw;
            }
          });
        }
      });
      return filtererdDarta;
    }
  });

  let initialState = {};

  if (
    !permissionsObj?.edit &&
    !permissionsObj?.delete &&
    !permissionsObj?.print
  ) {
    initialState.hiddenColumns = ["Action"];
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isTablet } = useBreakPoints();

  const [employeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(5); // default limit
  const [offset, setOffset] = useState(0); // default offset
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  // Get Country List
  const getEmployeeListAPi = useCallback(async (limit, offset) => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}company/employeelist?limit=${limit}&offset=${offset}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();
      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        setEmployeeList(_data?.employeeList);
        setTotalCount(_data?.paginationDetails?.count);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  const getEmployeeDatabyidAPi = useCallback(async (id) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("id", id);

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}company/employeedatabyid`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        console.log(_data);

        setLoading(false);
        return { ..._data.employee[0] };
        // setKitList(_data?.farmerData);
        // setTotalCount(_data?.paginationDetails?.count);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  const _get_employee_list = () =>
    dispatch(employeeActions.getEmployeeDropdownList());

  useEffect(() => {
    _get_employee_list();
  }, []);

  // useEffect(() => {
  //   getEmployeeListAPi(limit, offset);
  //   // eslint-disable-next-line
  // }, [getEmployeeListAPi]);

  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    let formData = new FormData();
    formData.append("id", deleteId);
    formData.append("isDelete", "Yes");

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}company/deleteemployee`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        getEmployeeListAPi(limit, offset);
        setDeleteModal(false);
        toast?.success(_data?.message);
        setDeleteLoading(false);
      } else {
        setDeleteLoading(false);
        setDeleteModal(false);

        toast.error(_data?.message);
      }
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
    }
  };

  const column = useMemo(
    () => [
      {
        Header: "",
        accessor: "photo",
        filterable: false,
        sortable: false,
        Cell: ({ value }) => (
          <Avatar variant="square" src={value} sx={{ width: 24, height: 24 }} />
        ),
      },
      {
        Header: "Code",
        accessor: "employeecode",
        sortable: false,
      },
      {
        Header: "Name",
        accessor: "employeeName",
      },
      {
        Header: "Role",
        accessor: "roleName",
      },
      {
        Header: "Department",
        accessor: "departmentName",
      },
      {
        Header: "Username",
        accessor: "userid",
      },
      {
        ...GridAction({
          permission,
          onEditClick: (cellProps) => {
            getEmployeeDatabyidAPi(cellProps.row.original.id).then((res) =>
              navigate(`/editemployee`, {
                state: { ...res },
              })
            );
          },
          onViewClick: (cellProps) =>
            getEmployeeDatabyidAPi(cellProps.row.original.id).then((res) =>
              navigate(`/viewemployee`, {
                state: { ...res },
              })
            ),
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original.id);
          },
        }),
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  const onChangeSearch = async (value) => {
    setLoading(true);
    setSearchInput(value);

    let formData = new FormData();

    formData.append("employeeName", value);

    try {
      const apiCall = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }company/employeefilter?limit=${5}&offset=${0}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        setEmployeeList(_data?.filteredEmployeeList);
        setTotalCount(_data?.filteredEmployeeList?.count);
      } else {
        setLoading(false);
        toast?.error(_data?.message);
      }
    } catch (error) {
      setLoading(false);
      toast?.error(error);
      console.log(error);
    }
  };

  return (
    <PageLayout
      title={"Employee List"}
      loading={loading}
      addBtn={permission?.create ? true : false}
      backBtnTitle={`Add Employee`}
      onClick={() => navigate("/addemployee")}
    >
      {loading ? (
        <LoaderComponent />
      ) : (
        <div
          className="d-grid"
          style={{ margin: "10px 20px", border: "1px solid #DEE2E6" }}
        >
          <TableContainer
            columns={column}
            //data={employeeList}
            data={employeeDropdownList}
            isGlobalFilter={true}
            showColumnFilters={true}
            isAddUserList={false}
            tableClass={"table-sm"}
            divClass={"table-responsive"}
            showPagination={true}
            initialState={{ pageSize: 10, pageIndex: 0 }}
            exports={{
              excel: true,
              pdf: true,
            }}
          />
        </div>
      )}

      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={handleDeleteRequest}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
    </PageLayout>
  );
};

export default EmployeeList;
