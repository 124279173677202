/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { grnValidationSchema } from "./validationSchema";
import { yieldConfigurationActions } from "../../redux/actions";
import { statusOption } from "./../../hooks/StaticData";
import { toast } from "react-toastify";

const initialValues = {
  id: 0,
  name: "",
  dakru: 0.0,
  gariyu: 0.0,
  bhukho: 0.0,
  estimated: 0.0,
  isactive: 1,
  selStatus: statusOption[0] || null,
};

export const useYieldConfigurationHooks = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialValues);
  const [rowData, setRowData] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [singleData, setSingleData] = useState();
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
   const [searchInput, setSearchInput] = useState("");

  const { yieldConfigurationList } = useSelector((obj) => obj);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(grnValidationSchema),
  });

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const ondropdownchange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      isactive: selectedOption?.value,
      selStatus: selectedOption || null
    }));
  };

  const resetFormData = () => {
    // setFormData(initialValues);
    // setSelStatus(statusOption[0]);
  };

  const onSubmit = async () => {
    let chkDuplicateConfigure = rowData.filter(
      (obj) =>
        formData.name.trim().toLowerCase() === obj.name.trim().toLowerCase() && formData.id !== obj.id
    );

    if (chkDuplicateConfigure.length > 0){
      toast.warning("Yield Configuration already exit")
      return true;
    }


      if (formData.id > 0) {
        await dispatch(
          yieldConfigurationActions.updateYieldConfiguration(
            { ...formData },
            () => {
              toast.success("Yield Configuration update successfully");
            }
          )
        );
      } else {
        await dispatch(
          yieldConfigurationActions.saveYieldConfiguration(
            { ...formData },
            () => {
              toast.success("Yield Configuration create successfully");
            }
          )
        );
      }
    await dispatch(yieldConfigurationActions.getYieldConfiguration());
    setFormData(initialValues);
    setDeleteModal(false);
    setEditModal(false);
    setFormData((prev) => ({ ...prev, selStatus: statusOption[0] }));
  };

  useEffect(() => {
    dispatch(yieldConfigurationActions.getYieldConfiguration());
    setDeleteModal(false);
    setEditModal(false);
    setFormData((prev) => ({ ...prev, selStatus: statusOption[0] }));
  }, []);

  useEffect(() => {
    if (yieldConfigurationList.length > 0) {
      setRowData(yieldConfigurationList);
    } else {
      setRowData([]);
    }
  }, [yieldConfigurationList]);

  useEffect(() => {
    if (editModal && singleData.id > 0) {
      setFormData(singleData);
      setFormData((prev) => ({
        ...prev,
        selStatus: statusOption.filter(
          (obj) => singleData.isactive === obj.value
        )[0],
      }));
    }
  }, [editModal, singleData]);

  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    let formData = new FormData();
    formData.append("id", deleteId);
    formData.append("isDelete", "Yes");

    if (deleteId > 0) {
      await dispatch(
        yieldConfigurationActions.deleteYieldConfiguration(deleteId)
      );
      await dispatch(yieldConfigurationActions.getYieldConfiguration());
      toast.success("Yield Configuration delete successfully");
      setDeleteLoading(false);
      setDeleteModal(false);
    }
  };

  const onCancelClick = () => {
    setFormData(initialValues);
  };

  useEffect(() => {
    if (searchInput.length > 0) {
      const filteredData = rowData.filter((item) => {
        return (
          item.name.toLowerCase().includes(searchInput.toLowerCase()) ||
          item.dakru.toString().includes(searchInput) ||
          item.gariyu.toString().includes(searchInput) ||
          item.bhukho.toString().includes(searchInput) ||
          item.estimated.toString().includes(searchInput) ||
          item.isStatus.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
      setRowData(filteredData);
    }

  },[searchInput])
  
  
  return {
    setFormData,
    formData,
    setDeleteModal,
    deleteModal,
    setEditModal,
    editModal,
    setDeleteId,
    deleteId,
    setDeleteLoading,
    deleteLoading,
    setSingleData,
    singleData,
    setRowData,
    rowData,
    handleChange,
    initialValues,
    register,
    handleSubmit,
    errors,
    resetFormData,
    control,
    onSubmit,
    ondropdownchange,
    yieldConfigurationList,
    handleDeleteRequest,
    onCancelClick,
    setSearchInput,
  };
};
