import React, { useMemo } from "react";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../Component/CustomModal";

import TableContainer from "../../Component/TableContainer";
const CheckLandAvalibility = ({
  setLandDetailsError,
  landDetailsError,
  editModal,
  onCloseModal,
  setCheckAvailabilty,
  checkAvailabilty,
  availibilityData,
  availibilityDataIndex,
  setLandDetails,
  landDetails,
}) => {
  const navigate = useNavigate();
  const column = useMemo(
    () => [
      {
        Header: "Farmer Name",
        accessor: "registeredFarmer.name",
        filterable: false,
      },
      {
        Header: "Contact",
        accessor: "registeredFarmer.mobileNo",
        filterable: false,
      },
      {
        Header: "Village",
        accessor: "registeredFarmer.village.villageName",
        filterable: false,
      },
      {
        Header: "Survey No",
        accessor: "surveyNo",
        filterable: false,
      },
      {
        Header: "Total Vigha",
        accessor: "vigha",
        filterable: false,
      },
      {
        Header: "Registered Vigha",
        accessor: "regVigha",
        filterable: false,
      },
      {
        Header: "Availible Land",
        accessor: "remainingLandVigha",
        filterable: false,
      },

      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  const handleSubmitCheckAvailabilty = (e) => {
    e.preventDefault();

    let arr = [...landDetails];
    if (availibilityData?.remainLand === "0.0") {
      toast.error(
        "No Land Available For Registration Please Check Land Details."
      );
      navigate("/farmerdashboard");
    } else {
      if (availibilityData?.landData?.length > 0) {
        arr[availibilityDataIndex]["totalLand"] =
          availibilityData?.landData?.[0]["totalLand"];
        arr[availibilityDataIndex]["vigha"] =
          availibilityData?.landData?.[0]["vigha"];
        arr[availibilityDataIndex]["registeredLand"] =
          availibilityData?.remainLand;
        arr[availibilityDataIndex]["regVigha"] =
        Number(availibilityData?.remainLand / 2400).toFixed(2);
        arr[availibilityDataIndex]["remainLand"] = availibilityData?.remainLand;
        arr[availibilityDataIndex]["remainLandVigha"] = Number(availibilityData?.remainLand / 2400).toFixed(3);

        let arr3 = [...landDetailsError];
        arr3[availibilityDataIndex]["totalLand"] = false;
        arr3[availibilityDataIndex]["vigha"] = false;
        arr3[availibilityDataIndex]["registeredLand"] = false;
      } else {
        // arr[availibilityDataIndex]['totalLand'] =  availibilityData?.landData?.[0]['totalLand']
        // arr[availibilityDataIndex]['vigha'] = availibilityData?.landData?.[0]['vigha']
        arr[availibilityDataIndex]["registeredLand"] = "";
        arr[availibilityDataIndex]["remainLand"] = "";
        arr[availibilityDataIndex]["remainLandVigha"] = "";
        arr[availibilityDataIndex]["regVigha"] = "";
      }
      setLandDetails(arr);
      onCloseModal();
      let arr2 = [...checkAvailabilty];
      arr2[availibilityDataIndex]["check"] = true;
      setCheckAvailabilty(arr2);
    }
  };

  return (
    <CustomModal
      size={"lg"}
      // loading={loading}
      title={"View Land Availibility"}
      handleSubmit={handleSubmitCheckAvailabilty}
      modalState={editModal}
      onCloseModal={onCloseModal}
      //   editState={true}
      customBtn={true}
    >
      <div
        className="col-md-12"
        style={{ marginTop: "20px", textAlign: "center" }}
      >
        <h5>{availibilityData?.avabilityDetils}</h5>
      </div>

      {availibilityData?.landData?.length > 0 && (
        <div className="col-md-12" style={{ marginTop: "40px" }}>
          <TableContainer
            columns={column}
            data={availibilityData.landData}
            isGlobalFilter={false}
            isAddUserList={false}
            tableClass={"table-sm"}
            divClass={"table-responsive"}
          />
        </div>
      )}
    </CustomModal>
  );
};

export default CheckLandAvalibility;
