/* eslint-disable no-restricted-globals */
import axios from "../../common/axios";
import { toast } from "react-toastify";
import { keys } from "../../config/keys";
import { NG_FARMER_PRICE_LIST } from "../constTypes";

const _farmer_price = (data, type) => {
  return {
    type: type,
    payload: data,
  };
};

export const get_ng_farmer_price = () => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/price`)
    .then((res) => {
      dispatch(_farmer_price(res.data.rows[1], NG_FARMER_PRICE_LIST));
    })
    .catch((e) => {
      toast.error(e.toString());
    });
};

export const save =
  (data, { onSuccess, onError }) =>
  async (dispatch) => {
    await axios({
      method: "post",
      url: `${keys().ServicePath}/api/price`,
      data: data,
    })
      .then((res) => {
        if (onSuccess) onSuccess(res);
      })
      .catch((e) => {
        toast.error(e.toString());
        if (onError) onError(e);
      });
  };

export const delete_price = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys().ServicePath}/api/price/delete`, {
      ...data,
    })
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};
