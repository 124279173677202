/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import PageLayout from "../../../Component/PageLayout";
import TableContainer from "../../../Component/TableContainer";
import { useNavigate } from "react-router-dom";
import { useFarmerAllocationListHooks } from "./useFarmerAllocationHooks";
import LoaderComponent from "../../../Component/LoaderComponent";
import { GridAction } from "../../../common/useGridAction";
import DeleteModal from "../../../Component/DeleteModal";

const FarmerAllocationList = () => {
  const navigate = useNavigate();
  const {
    permission,
    employee_farmer_list,
    onGridDelete,
    setDeleteModal,
    setDeleteId,
    deleteModal,
    deleteLoading,
  } = useFarmerAllocationListHooks(0);

  const [loading, setLoading] = useState(false);

  const column = useMemo(
    () => [
      {
        id: "sr",
        Header: "Sr. No.",
        Cell: (cellProps) => {
          return <span>{cellProps?.row?.index + 1}</span>;
        },
      },
      {
        Header: "Technician Name",
        accessor: "employeename",
      },
      {
        Header: "Unit",
        accessor: "unitname",
      },
      {
        Header: "District(s) Alloted",
        accessor: "totaldistrict",
      },
      {
        Header: "Taluka(s) Alloted",
        accessor: "totaltaluka",
      },
      {
        Header: "Farmer(s) Alloted",
        accessor: "totalfarmers",
      },
      {
        ...GridAction({
          permission,
          onEditClick: (cellProps) => {
            navigate(`/farmerallocation`, {
              state: { id: cellProps.row.original.id },
            });
          },
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original.id);
          },
        }),
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <PageLayout
      title={"Farmer Allocation List"}
      loading={loading}
      addBtn={permission?.create ? true : false}
      backBtnTitle={`Allocate Farmer(s) to Technician`}
      onClick={() => navigate("/farmerallocation", { state: { id: 0 } })}
    >
      {loading ? (
        <LoaderComponent />
      ) : (
        <div
          className="d-grid"
          style={{ margin: "10px 20px", border: "1px solid #DEE2E6" }}
        >
          <TableContainer
            columns={column}
            data={employee_farmer_list}
            isGlobalFilter={true}
            showColumnFilters={true}
            isAddUserList={false}
            tableClass={"table-sm"}
            divClass={"table-responsive"}
            showPagination={true}
            initialState={{ pageSize: 10, pageIndex: 0 }}
          />
        </div>
      )}

      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={onGridDelete}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
    </PageLayout>
  );
};

export default FarmerAllocationList;
