import * as types from "../constTypes";

const initialValue = [];
export const main_modules_list = (state = initialValue, action) => {
  switch (action.type) {
    case types.MAIN_MODULE_LIST:
      return [...action.payload];
    default:
      return state;
  }
};

export const module_list = (state = initialValue, action) => {
  switch (action.type) {
    case types.MODULES_LIST:
      return [...action.payload];
    default:
      return state;
  }
};

export const sub_module_list = (state = initialValue, action) => {
  switch (action.type) {
    case types.SUB_MODULE_LIST:
      return [...action.payload];
    default:
      return state;
  }
};

export const pages_list = (state = initialValue, action) => {
  switch (action.type) {
    case types.PAGES_LIST:
      return [...action.payload];
    default:
      return state;
  }
};

