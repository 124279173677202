/* eslint-disable no-restricted-globals */
import axios from "../../common/axios";
import { toast } from "react-toastify";
import { GRN_DATA_LIST, GRN_DETAIL_DATA_BY_PO_ID } from "../constTypes/grn";
import { keys } from "../../config/keys";

const _grnList = (data, type) => {
  return {
    type: type,
    payload: data,
  };
};

export const getGrnData = () => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/grn`)
    .then((res) => {
      dispatch(_grnList(res.data.rows[1], GRN_DATA_LIST));
    })
    .catch((e) => {
      toast.error(e.toString());
    });
};

export const savegrnData = (data, onSuccess, onError) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${keys().ServicePath}/api/grn/save`,
    data: data,
    headers: { "Content-Type": "application/json" },
  })
    .then((res) => {
      if (onSuccess) onSuccess(res);
    })
    .catch((e) => {
      toast.error(e.toString());
      if (onError) onError(e);
    });
};

export const getGrnDetailDataByPOid =
  (purchaseorderid, { onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .get(
        `${
          keys().ServicePath
        }/api/grn/get_orderedReceivedTotal?purchaseorderid=${purchaseorderid}`
      )
      .then((res) => {
        dispatch(_grnList(res.data.rows[1], GRN_DETAIL_DATA_BY_PO_ID));
        if (onSuccess) onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        toast.error(e.toString());
        if (onError) onError(e);
      });
  };

export const get_latest_grn_no =
  ({ onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .get(`${keys().ServicePath}/api/grn/get_lastest_grn_no`)
      .then((res) => {
        if (onSuccess) onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (onError) onError(e);
      });
  };

export const get_grn_by_id =
  (id, { onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .get(`${keys().ServicePath}/api/grn/${id}`)
      .then((res) => {
        console.log({ ...res.data.rows[1][0], podetails: res.data.rows[2] });
        if (onSuccess)
          onSuccess({ ...res.data.rows[1][0], podetails: res.data.rows[2] });
      })
      .catch((e) => {
        if (onError) onError(e);
      });
  };

export const delete_grn = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys().ServicePath}/api/grn/delete_grn`, {
      ...data,
    })
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};
