/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PageLayout from "../../Component/PageLayout";
import { Col, Row } from "reactstrap";
import TableContainer from "../../Component/TableContainer";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Component/DeleteModal";
import { Print } from "..";
import { useInvoiceListHooks } from "./useInvoiceListHooks";

const InvoiceList = () => {
  const navigate = useNavigate();
  const g = JSON.parse(localStorage.getItem("newtoken"));
  const {
    invoice_list,
    loading,
    column,
    onGridDelete,
    setDeleteModal,
    deleteModal,
    deleteLoading,
    permission,
    showprint,
    printData,
    setShowPrint,
    printType,
  } = useInvoiceListHooks();

  return (
    <PageLayout
      title={"Farmer's Purchase Invoice"}
      loading={loading}
      // addBtn={permission?.create ? true : false}
      addBtn={true}
      backBtnTitle={`Add Invoice`}
      onClick={() =>
        navigate(g.isnorthgujarat ? "/invoice/addng" : "/invoice/add")
      }
    >
      <Row>
        <Col md={showprint ? 6 : 12}>
          <div
            className="d-grid"
            style={{ margin: "10px 10px", border: "1px solid #DEE2E6" }}
          >
            <TableContainer
              columns={column}
              data={invoice_list}
              isGlobalFilter={true}
              showColumnFilters={true}
              isAddUserList={false}
              tableClass={"table-sm"}
              showPagination={true}
              initialState={{ pageSize: 20, pageIndex: 0 }}
              divClass={"table-responsive"}
              exportfileName="Bags Issue List"
            />
          </div>
        </Col>

        {showprint && (
          <Col md={6}>
            <Print
              data={printData}
              onPrint={() => setShowPrint(false)}
              bill={!printType.gatepass}
              gatepass={printType.gatepass}
              onCancel={() => setShowPrint(false)}
            />
          </Col>
        )}
      </Row>
      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={onGridDelete}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
    </PageLayout>
  );
};

export default InvoiceList;
