import React, { useCallback, useEffect, useState } from "react";
import {
  Label,
  Input,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
import { toast } from "react-toastify";
import CustomModal from "../../Component/CustomModal";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import useBreakPoints from "../../hooks/useBreakPoints";
const EditDesignation = ({
  editModal,
  onCloseModal,
  onSubmitModal,
  singleData,
}) => {
  const [loading, setLoading] = useState(false);
  const {isMobile} = useBreakPoints()
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const { loginDetails,token } = useSelector((state) => state.layout);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inputData, setInputData] = useState({
    designation: singleData?.designation,
    department: {label : singleData?.departmentDetails?.departmentName , value : singleData?.departmentDetails?.id},
    remark: singleData?.remarks,
  });

 const [inputDataError, setInputDataError] = useState({
    designation: false,
    department: false,
  });

  // Get Unit Options
  const getDepartmentOptionsListAPi = useCallback(async () => {
    setLoading(true);
    try {
      // eslint-disable-next-line
      let formData = new FormData();

      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}company/departmentdropdown?companyId=${loginDetails?.companyId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];
        // eslint-disable-next-line
        _data?.departmentDropList?.map((er) => {
          arr.push({ label: er?.departmentName, value: er.id });
        });
        setDepartmentOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getDepartmentOptionsListAPi();
  }, [getDepartmentOptionsListAPi]);

 const handleChangeInput = (name, value) => {
    if (name === "designation") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, designation: false });
      } else {
        setInputDataError({ ...inputDataError, designation: true });
      }
    } else  if (name === "department") {
      setInputData({ ...inputData, [name]: value });

      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, department: false });
      } else {
        setInputDataError({ ...inputDataError, department: true });
      }
    } else {
      setInputData({ ...inputData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputData.designation) {
      toast.error("Designation is Required!");
      setInputDataError({ ...inputDataError, designation: true });
    } else if (!inputData.department) {
      toast.error("Department is Required!");
      setInputDataError({ ...inputDataError, department: true });
    } else {
      setLoading(true);
      let formData = new FormData();

      formData.append("designation", inputData.designation);
      formData.append("departmentId", inputData.department.value);
      formData.append("remarks", inputData.remark);
      formData.append("id", singleData.id);

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}company/editdesignation`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

        if (_data?.status) {
          toast?.success(_data?.message);
          setLoading(false);
          setInputData({
            designation: "",
            department: "",
            remark: "",
          });
          onSubmitModal();
        } else {
          setLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };
  return (
    <CustomModal
      loading={loading}
      title={"Edit Designation"}
      handleSubmit={handleSubmit}
      modalState={editModal}
      onCloseModal={onCloseModal}
      editState={true}
    >
      <FormGroup>
      <Row className="gap-3" style={{ margin: 20 }}>
          <Col md={12} className={`g-3 d-flex ${isMobile ? "flex-column" : ""}`}>
            <Col md={5}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Designation <span className="required_span"> * </span>
              </Label>
            </Col>
            <Col md={7} className={`${isMobile ? "" : "mx-3"}`}>
              <Input
                id="exampleEmail"
                name="designation"
                value={inputData?.designation}
                onChange={(e) => {
                  handleChangeInput(e.target.name, e.target.value);
                }}
                 className={`cust-input ${inputDataError?.designation  ? "border-danger" : ""}`}
                type="text"
              />
            </Col>
          </Col>

          <Col md={12} className={`g-3 d-flex  ${isMobile ? "flex-column" : "" }`}>
            <Col md={5}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Department <span className="required_span"> * </span>
              </Label>
            </Col>
            <Col sm={7} className={`${isMobile ? "" : "mx-3"}`}>
              <Select
                isMulti={false}
                onChange={(e) => {
                  handleChangeInput("department", e);
                }}
                value={inputData?.department}
                options={departmentOptions}
                // menuPlacement="top"
                name="choices-single-default"
                id="countryAddCompany"
                className={`w-100 ${inputDataError?.department  ? "border border-danger" : ""}`}
              ></Select>
            </Col>
          </Col>
          <Col md={12} className={`g-3 d-flex  ${isMobile ? "flex-column" : "" }`}>
            <Col md={5}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Remark
              </Label>
            </Col>
            <Col sm={7} className={`${isMobile ? "" : "mx-3"}`}>
              <Input
                id="exampleEmail"
                name="remark"
                value={inputData?.remark}
                onChange={(e) => {
                  handleChangeInput(e.target.name, e.target.value);
                }}
                className="cust-input"
                type="text"
              />
            </Col>
          </Col>
        
        </Row>
      </FormGroup>
    </CustomModal>
  );
};

export default EditDesignation;
