/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import PageLayout from "../../Component/PageLayout";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import TableContainer from "../../Component/TableContainer";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useBreakPoints from "../../hooks/useBreakPoints";
import { useGRNHooks } from "../GRN/useGRNHooks";
import LoaderComponent from "../../Component/LoaderComponent";
import { keys } from "../../config/dev";
import { GridAction } from "../../common/useGridAction";
import DeleteModal from "../../Component/DeleteModal";

const ViewGrnList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { grnList, get_grn_list, permission, delete_GRN } = useGRNHooks(0);

  const [loading, setLoading] = useState(false);

  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    get_grn_list();
  }, []);

  const column = useMemo(
    () => [
      {
        Header: "GRN Date",
        accessor: "invoicedate",
        Cell: (props) => keys.shortDate(props.value),
      },
      {
        Header: "GRN #",
        accessor: "invoicenumber",
      },
      {
        Header: "Vendor",
        accessor: "vendorName",
      },
      // {
      //   Header: "Vendor Type",
      //   accessor: "vendorType",
      // },
      {
        Header: "Voucher Number",
        accessor: "vouchernumber",
      },

      {
        Header: "Total Order Quantity",
        accessor: "totalorderqty",
      },
      {
        Header: "Total Received Quantity",
        accessor: "totalreceivedqty",
      },
      {
        Header: "Order Date",
        accessor: "orderDate",
        Cell: (props) => keys.shortDate(props.value),
      },
      {
        Header: "Due Date",
        accessor: "dueDate",
        Cell: (props) => keys.shortDate(props.value),
      },
      {
        Header: "Delivery Terms",
        accessor: "deliveryTerms",
      },
      {
        ...GridAction({
          permission,
          onEditClick: (cellProps) => {
            navigate(`/addgrn`, { state: { id: cellProps.row.original.id } });
          },
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original.id);
          },
        }),
      },
    ],
    // eslint-disable-next-line
    []
  );

  const onGridDelete = async () => {
    setDeleteLoading(true);
    if (deleteId > 0) {
      delete_GRN(deleteId);
      setDeleteLoading(false);
      setDeleteModal(false);
    }
  };

  return (
    <PageLayout
      title={"Goods Received Note (GRN)"}
      loading={loading}
      addBtn={permission?.create ? true : false}
      backBtnTitle={`Create GRN`}
      onClick={() => navigate("/addgrn", { state: { id: 0 } })}
    >
      {loading ? (
        <LoaderComponent />
      ) : (
        <div
          className="d-grid"
          style={{ margin: "10px 20px", border: "1px solid #DEE2E6" }}
        >
          <TableContainer
            columns={column}
            data={Array.isArray(grnList) ? grnList : []}
            isGlobalFilter={true}
            showColumnFilters={true}
            isAddUserList={false}
            tableClass={"table-sm"}
            divClass={"table-responsive"}
            showPagination={true}
            initialState={{ pageSize: 10, pageIndex: 0 }}
          />
        </div>
      )}

      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={onGridDelete}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
    </PageLayout>
  );
};

export default ViewGrnList;

// function GrnView() {
//   return (
//     <div>

//     </div>
//   )
// }
