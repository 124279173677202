import { Col, Form, Row } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PageLayout from "../../Component/PageLayout";
import { Autocomplete, Button, TextField } from "@mui/material";
import { textFieldProps } from "../../common/commonControlProps";
import { Cancel, Save } from "@mui/icons-material";
import { useDocumentCreatorHooks } from "./useDocumentCreatorHooks";
import { useNavigate } from "react-router-dom";

const DocumentCreator = () => {
  const {
    formData,
    handleChange,
    handleUnitChange,
    handleEditorChange,
    handleSubmit,
    onSubmit,
    companyunitlist,
  } = useDocumentCreatorHooks();

  const navigate = useNavigate();
  return (
    <PageLayout title={"Document Creator"}>
      <Form className="m-4 g-2" onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-2">
          <Col md={3} sm={12}>
            <Autocomplete
              fullWidth
              options={companyunitlist}
              value={formData?.selectedunit}
              onChange={handleUnitChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...textFieldProps}
                  label="Select Unit"
                />
              )}
            />
          </Col>
          <Col md={7} sm={12}>
            <TextField
              label="Template Name"
              name="templatename"
              value={formData?.templatename}
              onChange={handleChange}
              required
              {...textFieldProps}
            />
          </Col>
          <Col lg={1} md={1} sm={12} className="d-flex align-items-center">
            <Button
              className="custom-btn-page-layout text-white "
              size="small"
              startIcon={<Save />}
              fullWidth
              type="submit"
            >
              {` Save`}
            </Button>
          </Col>
          <Col lg={1} md={1} sm={12} className="d-flex align-items-center">
            <Button
              type="button"
              size="small"
              className="custom-btn-page-layout custom-btn-danger "
              fullWidth
              startIcon={<Cancel />}
              onClick={() => navigate("/templatelist")}
            >
              Cancel
            </Button>
          </Col>
          <Col md={12}>
            <CKEditor
              editor={Editor}
              data={formData.templatecontent}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                //console.log("Editor is ready to use!", editor.getData());
              }}
              onChange={(event, editor) => {
                console.log("event", event);
                console.log("event editor", editor.getData());
                console.log("event editor", editor.sourceElement);
                console.log("event editor", editor.data);
                handleEditorChange(editor);
              }}
              onBlur={(event, editor) => {
                //console.log("Blur.", editor.sourceElement);
              }}
              onFocus={(event, editor) => {
                // console.log("Focus.", editor.data);
              }}
            />
          </Col>
        </Row>
      </Form>
    </PageLayout>
  );
};

export default DocumentCreator;
