import * as types from "../constTypes";

const incentivetype_dropdown_list = [];
const incentive_dropdown_list = [];
const incentive_type_list = [];
const farmer_incentive_detail_list = [];

export const incentivetypeDropdownList = (
  state = incentivetype_dropdown_list,
  action
) => {
  switch (action.type) {
    case types.incentiveTypes.INCENTIVETYPE_DROPDOWN_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const incentivetypeList = (
  state = incentive_type_list,
  action
) => {
  switch (action.type) {
    case types.incentiveTypes.INCENTIVETYPE_LIST:
      return [...action.payload];
    default:
      return state;
  }
};

export const incentiveDropdownList = (
  state = incentive_dropdown_list,
  action
) => {
  switch (action.type) {
    case types.incentiveTypes.INCENTIVE_DROPDOWN_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const farmerincentiveDetailList = (
  state = farmer_incentive_detail_list,
  action
) => {
  switch (action.type) {
    case types.incentiveTypes.FARMER_INCENTIVE_DETAIL_LIST:
      return action.payload;
    default:
      return state;
  }
};
