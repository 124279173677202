import {
  createContext,
  useRef,
  forwardRef,
  useContext,
  useEffect,
} from "react";
import {
  ListSubheader,
  Typography,
  useMediaQuery,
  useTheme,
  styled,
  Popper,
} from "@mui/material";
import { VariableSizeList } from "react-window";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

const LISTBOX_PADDING = 8; // px

const renderRow = (props) => {
  const { data, index, style } = props;

  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography
      component="li"
      {...dataSet[0]}
      style={inlineStyle}
      variant="subtitle1"
    >
      {dataSet[1].label}
    </Typography>
  );
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}

          // getOptionSelected={(option, value) => option === value}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

// ListboxComponent.propTypes = {
//   children: PropTypes.node,
// };

const VirtualizeAutoComplete = (props) => {
  return (
    <Autocomplete
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      renderOption={(props, option, state) => [props, option, state.index]}
      isOptionEqualToValue={(option, value) =>
        value === undefined ||
        value === "" ||
        option.toString() === value.toString()
      }
      {...props}

      // options={farmerDropdownList}
      //  groupBy={(option) => option[0].toUpperCase()}
      // renderInput={(params) => (
      //   <TextField
      //     {...params}
      //     variant="outlined"
      //     label="Farmer Code"
      //     size="small"
      //   />
      // )}
    />
  );
};
export default VirtualizeAutoComplete;
