import { Col, Form, Row } from "reactstrap";
import PageLayout from "../../Component/PageLayout";
import VirtualizeAutoComplete from "../../common/VirtualizedAutoComplete";
import { useNGFarmerPriceHooks } from "./useNGFarmerPrice";
import { textFieldProps } from "../../common/commonControlProps";
import { Button, InputAdornment, TextField } from "@mui/material";
import { ErrorMessage } from "../../common/Error";
import { Cancel, Save } from "@mui/icons-material";
import TableContainer from "../../Component/TableContainer";

const NGFarmerPrice = () => {
  const { list, action, state, form } = useNGFarmerPriceHooks();
  return (
    <PageLayout title={"Farmer's Price List"}>
      <Form className="" onSubmit={form.handleSubmit(action.onSubmit)}>
        <Row className="mt-2 g-3" style={{ margin: "10px 20px" }}>
          <Col lg={4} md={6} sm={12}>
            <VirtualizeAutoComplete
              {...form.register("farmerid")}
              name="selectedFarmer"
              size="small"
              options={list.farmerDropdownList}
              clearText={false}
              value={state?.formData?.selectedfarmer}
              onChange={(e, option) =>
                action.onfarmerdropdownchange(
                  e,
                  option,
                  "farmerid",
                  "selectedfarmer"
                )
              }
              renderInput={(params) => (
                <TextField
                  {...form.register("farmerid")}
                  {...params}
                  label="Farmer Code"
                  {...textFieldProps}
                  error={form?.errors?.farmerid}
                />
              )}
            />
            {form?.errors?.farmerid && (
              <ErrorMessage message={form?.errors?.farmerid?.message} />
            )}
          </Col>
        </Row>
        <Row className="g-3" style={{ margin: "10px 20px" }}>
          <Col lg={2} md={2} sm={12}>
            <TextField
              label={`Overall Price`}
              {...textFieldProps}
              {...form.register("overallprice")}
              name="overallprice"
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{`₹`}</InputAdornment>
                ),
              }}
              onChange={action.onInputChange}
              value={state.formData.overallprice}
              error={form.errors?.overallprice}
            />
            {form?.errors?.overallprice && (
              <ErrorMessage message={form?.errors?.overallprice?.message} />
            )}
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              label={`Average Price`}
              {...textFieldProps}
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{`₹`}</InputAdornment>
                ),
              }}
              {...form.register("avgprice")}
              name="avgprice"
              onChange={action.onInputChange}
              value={state.formData.avgprice}
              error={form.errors?.avgprice}
            />
            {form?.errors?.avgprice && (
              <ErrorMessage message={form?.errors?.avgprice?.message} />
            )}
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              label={`Sample 1 Price`}
              {...textFieldProps}
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{`₹`}</InputAdornment>
                ),
              }}
              {...form.register("sample1price")}
              name="sample1price"
              onChange={action.onInputChange}
              value={state.formData.sample1price}
              error={form.errors?.sample1price}
            />
            {form?.errors?.sample1price && (
              <ErrorMessage message={form?.errors?.sample1price?.message} />
            )}
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              label={`Sample 2 Price`}
              {...textFieldProps}
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{`₹`}</InputAdornment>
                ),
              }}
              {...form.register("sample2price")}
              name="sample2price"
              onChange={action.onInputChange}
              value={state.formData.sample2price}
              error={form.errors?.sample2price}
            />
            {form?.errors?.sample2price && (
              <ErrorMessage message={form?.errors?.sample2price?.message} />
            )}
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              label={`Sample 3 Price`}
              {...textFieldProps}
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{`₹`}</InputAdornment>
                ),
              }}
              {...form.register("sample3price")}
              name="sample3price"
              onChange={action.onInputChange}
              value={state.formData.sample3price}
              error={form.errors?.sample3price}
            />
            {form?.errors?.sample3price && (
              <ErrorMessage message={form?.errors?.sample3price?.message} />
            )}
          </Col>
        </Row>
        <Row className="g-3" style={{ margin: "10px 20px" }}>
          <Col
            lg={1}
            md={1}
            sm={12}
            className="d-flex justify-content-center align-items-center"
          >
            <Button
              className="custom-btn-page-layout text-white "
              startIcon={<Save />}
              fullWidth
              type="submit"
              size="small"
            >
              {` Save`}
            </Button>
          </Col>
          <Col
            lg={1}
            md={1}
            sm={12}
            className="d-flex justify-content-center align-items-center"
          >
            <Button
              type="button"
              className="custom-btn-page-layout custom-btn-danger "
              onClick={action.onCancel}
              startIcon={<Cancel />}
              size="small"
              fullWidth
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
      <Row className="g-3" style={{ margin: "10px 20px" }}>
        <Col md={12} lg={12} sm={12}>
          <div className="d-grid" style={{ border: "1px solid #DEE2E6" }}>
            <TableContainer
              columns={state.columns}
              data={list.farmer_price_list}
              isGlobalFilter={true}
              showColumnFilters={true}
              isAddUserList={false}
              tableClass={"table-sm"}
              showPagination={true}
              initialState={{ pageSize: 20, pageIndex: 0 }}
              divClass={"table-responsive"}
              exportfileName="Price list"
              exports={{
                excel: true,
                pdf: true,
                //tally: true,
                // onTallyClick: () => exportTally(),
              }}
            />
          </div>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default NGFarmerPrice;
