import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logOutRequest } from "../store/reducers/layoutReducer";

const ProductOption = () => {
  const { token } = useSelector((state) => state.layout);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getProductOption = useCallback(async () => {
    let arr = [];

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/productdrop`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
      }

      if (_data?.status) {
        _data?.productList?.map((er) => {
          arr.push({ label: er?.productName, value: er?.id });
          return er;
        });
      } else {
        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    return arr;
    // eslint-disable-next-line
  }, []);

  return { getProductOption };
};

export default ProductOption;
