import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import PageLayout from "../../Component/PageLayout";
import { Button, FormControlLabel, TextField } from "@mui/material";
import { textFieldProps } from "../../common/commonControlProps";
import { ErrorMessage } from "../../common/Error";
import { usePublishingHooks } from "./usePublishingHooks";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import FileToBase64 from "../../common/FileToBase64";
import { Cancel, Save } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const DocumentPublishing = () => {
  const navigate = useNavigate();

  const {
    handleChange,
    formData,
    errors,
    handleEditorChange,
    onFileChange,
    register,
    handleSubmit,
    onSubmit,
  } = usePublishingHooks();
  return (
    <PageLayout title={"Document Publishing"}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="m-4 g-2">
          <Col lg={12} md={12} sm={12}>
            <TextField
              {...register("title")}
              id="title"
              name="title"
              onChange={handleChange}
              value={formData.title}
              error={errors?.title}
              label={`Title`}
              title=""
              {...textFieldProps}
            />
            {errors?.title && <ErrorMessage message={errors?.title?.message} />}
          </Col>
          <Col lg={12} md={12} sm={12}>
            <TextField
              multiline
              rows={2}
              {...register("description")}
              name="description"
              onChange={handleChange}
              value={formData.description}
              error={errors?.description}
              label={`Description`}
              title=""
              {...textFieldProps}
            />
            {errors?.description && (
              <ErrorMessage message={errors?.description?.message} />
            )}
          </Col>
          <Col lg={12} md={12} sm={12}>
            <CKEditor
              editor={Editor}
              data={formData.content}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                //console.log("Editor is ready to use!", editor.getData());
              }}
              onChange={(event, editor) => {
                console.log("event", event);
                console.log("event editor", editor.getData());
                console.log("event editor", editor.sourceElement);
                console.log("event editor", editor.data);
                handleEditorChange(editor);
              }}
              onBlur={(event, editor) => {
                //console.log("Blur.", editor.sourceElement);
              }}
              onFocus={(event, editor) => {
                // console.log("Focus.", editor.data);
              }}
            />
          </Col>
          <Col lg={3} md={3} sm={12}>
            <TextField
              disabled
              label={`File name`}
              helperText={"(Max size 150 KB)"}
              InputLabelProps={{ shrink: true }}
              value={(formData.file && formData.file?.name) || ""}
              {...textFieldProps}
            />
          </Col>
          <Col lg={2} md={2} sm={12} className="d-flex align-items-center">
            <FileToBase64 onChange={onFileChange} />
          </Col>
        </Row>
        <Row className="m-4 g-2">
          <Col lg={1} md={1} sm={12}>
            <Button
              className="custom-btn-page-layout text-white "
              startIcon={<Save />}
              fullWidth
              type="submit"
              size="small"
            >
              {` Save`}
            </Button>
          </Col>
          <Col lg={1} md={1} sm={12}>
            <Button
              type="button"
              className="custom-btn-page-layout custom-btn-danger "
              onClick={(e) => navigate("/publishedlist")}
              startIcon={<Cancel />}
              size="small"
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </PageLayout>
  );
};

export default DocumentPublishing;
