import * as farmerTypes from "./farmer";
import * as vendorTypes from "./vendor";
import * as yieldConfigurationTypes from "./yieldConfiguration";
import * as employeeTypes from "./employee";
import * as yieldEstimationTypes from "./yieldEstimation";
import * as grnTypes from "./grn";
import * as incentiveTypes from "./incentive";
import * as pricelistTypes from "./pricelist";
import * as commonTypes from "./common";
import * as landdetailTypes from "./landdetail";
import * as purchaseTypes from "./purchase";

const PURCHASE_ORDER_LIST = "PURCHASE_ORDER_LIST";
const PRODUCT_LIST = "PRODUCT_LIST";
const PRODUCT_GROUP_CATEGORY_LIST = "PRODUCT_GROUP_CATEGORY_LIST";
const BAG_MASTER_LIST = "BAG_MASTER_LIST";
const BAG_ISSUE_LIST = "BAG_ISSUE_LIST";
const BAG_ISSUE_LIST_FARMER = "BAG_ISSUE_LIST_FARMER";
const DASH_FARMER_VILLAGE_WISE = "DASH_FARMER_VILLAGE_WISE";
const DASH_FARMER_DISTRICT_WISE = "DASH_FARMER_DISTRICT_WISE";
const DASH_FARMER_TALUKA_WISE = "DASH_FARMER_TALUKA_WISE";
const DASH_BAGS_ISSUED = "DASH_BAGS_ISSUED";
const DASH_YIELD_ESTIMATED = "DASH_YIELD_ESTIMATED";
const DASH_AVERAGE_YIELD_ESTIMATED = "DASH_AVERAGE_YIELD_ESTIMATED";
const DASH_VARIETY_WISE_LAND_SIZE = "DASH_VARIETY_WISE_LAND_SIZE";
const DASH_VILLAGE_WISE_VARIETY = "DASH_VILLAGE_WISE_VARIETY";
const DASH_VILLAGE_WISE_YIELD_ESTIMATION = "DASH_VILLAGE_WISE_YIELD_ESTIMATION";
const DASH_VILLAGE_WISE_VARIETY_DRILL_DATA =
  "DASH_VILLAGE_WISE_VARIETY_DRILL_DATA";
const SURVEY_LIST = "SURVEY_LIST";
const FARMER_SURVEY_LIST = "FARMER_SURVEY_LIST";

const WATER_WASTE_LIST = "WATER_WASTE_LIST";
const NG_FARMER_PRICE_LIST = "NG_FARMER_PRICE_LIST";

const TEMPLATES_LIST = "TEMPLATES_LIST";
const PUBLISHED_DOCUMENTS = "PUBLISHED_DOCUMENTS";
const EVENT_LIST = "EVENT_LIST";
const MODULE_LIST = "MODULE_LIST";
const ROLE_LIST = "ROLE_LIST";

const MAIN_MODULE_LIST = "MAIN_MODULE_LIST";
const MODULES_LIST = "MODULES_LIST";
const SUB_MODULE_LIST = "SUB_MODULE_LIST";
const PAGES_LIST = "PAGES_LIST";



export {
  farmerTypes,
  vendorTypes,
  yieldConfigurationTypes,
  employeeTypes,
  yieldEstimationTypes,
  incentiveTypes,
  grnTypes,
  pricelistTypes,
  commonTypes,
  landdetailTypes,
  purchaseTypes,
  BAG_ISSUE_LIST,
  PURCHASE_ORDER_LIST,
  PRODUCT_LIST,
  BAG_MASTER_LIST,
  BAG_ISSUE_LIST_FARMER,
  DASH_FARMER_VILLAGE_WISE,
  DASH_FARMER_DISTRICT_WISE,
  DASH_FARMER_TALUKA_WISE,
  DASH_BAGS_ISSUED,
  DASH_YIELD_ESTIMATED,
  DASH_AVERAGE_YIELD_ESTIMATED,
  DASH_VARIETY_WISE_LAND_SIZE,
  DASH_VILLAGE_WISE_VARIETY,
  DASH_VILLAGE_WISE_VARIETY_DRILL_DATA,
  DASH_VILLAGE_WISE_YIELD_ESTIMATION,
  SURVEY_LIST,
  WATER_WASTE_LIST,
  NG_FARMER_PRICE_LIST,
  PRODUCT_GROUP_CATEGORY_LIST,
  TEMPLATES_LIST,
  PUBLISHED_DOCUMENTS,
  EVENT_LIST,
  MODULE_LIST,
  ROLE_LIST,
  FARMER_SURVEY_LIST,
  MAIN_MODULE_LIST,
  SUB_MODULE_LIST,
  PAGES_LIST,
  MODULES_LIST
};
