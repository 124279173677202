import axios from "../../common/axios";
import { toast } from "react-toastify";
import { keys } from "../../config/keys";
import { PRICELIST_GROUP_DROPDOWN_LIST } from "../constTypes/pricelist";

const _pricelistgroupDropdownList = (data) => {
  return {
    type: PRICELIST_GROUP_DROPDOWN_LIST,
    payload: data,
  };
};

export const getPricelistGroupDropdownList = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/pricelist/getPricelistgroupDropdownList`)
    .then((res) => {
      dispatch(_pricelistgroupDropdownList(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};
