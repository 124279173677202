/* eslint-disable no-restricted-globals */
import axios from "../../common/axios";
import { toast } from "react-toastify";
import { keys } from "../../config/keys";
import { FARMER_FARMING_LAND_LIST, LAND_LIST } from "../constTypes/landdetail";

export const saveLandDetail =
  (data, onSuccess, onError) => async (dispatch) => {
    await axios({
      method: "post",
      url: `${keys().ServicePath}/api/landdetail/save`,
      data: data,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (onSuccess) onSuccess(res);
      })
      .catch((e) => {
        toast.error(e.toString());
        if (onError) onError(e);
      });
  };

const _landDetailList = (data) => {
  return {
    type: LAND_LIST,
    payload: data,
  };
};

const _farmerlandDetailList = (data) => {
  return {
    type: FARMER_FARMING_LAND_LIST,
    payload: data,
  };
};

export const getLandDetailList = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/landdetail`)
    .then((res) => {
      dispatch(_landDetailList(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const getLandDetailListByFarmerId =
  (id, callback) => async (dispatch) => {
    await axios
      .get(`${keys().ServicePath}/api/landdetail/${id}`)
      .then((res) => {
        dispatch(_landDetailList(res.data.rows[1]));
        if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const getFarmersRegisteredLandList = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/landdetail/farmerlandlist`)
    .then((res) => {
      dispatch(_farmerlandDetailList(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};
export const updateLandDetail =
  (data, onSuccess, onError) => async (dispatch) => {
    await axios({
      method: "patch",
      url: `${keys().ServicePath}/api/landdetail`,
      data: data,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (onSuccess) onSuccess(res);
      })
      .catch((e) => {
        toast.error(e.toString());
        if (onError) onError(e);
      });
  };


  export const deleteLandDetail =
    (id, onSuccess, onError) => async (dispatch) => {
      await axios
        .delete(`/api/landdetail/${id}`)
        .then((res) => {
          if (onSuccess) onSuccess(res);
        })
        .catch((err) => {
          if (onError) onError(err);
        });
    };

   