/* eslint-disable no-restricted-globals */
import axios from "../../common/axios";
import { toast } from "react-toastify";
import {
  // VENDOR_DETAILS,
  // VENDOR_DROPDOWN,
  VENDOR_LIST,
  // CREDITORS_LIST,
  // DEBTORS_LIST,
} from "../constTypes/vendor";
import { keys } from "../../config/keys";
import { call, put, takeEvery, all } from "redux-saga/effects";

const _vendorList = (data) => {
  return {
    type: VENDOR_LIST,
    payload: data,
  };
};

export const getVendors = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/vendor`)
    .then((res) => {
      dispatch(_vendorList(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

// export const getVendors = () => async (dispatch) => {
//   try {
//     const res = await axios.get(`${keys().ServicePath}/api/vendor`);
//     console.log("getven-->", res.data.rows[1]);
//     dispatch(_vendorList(res.data.rows[1]));
//   } catch (e) {
//     console.error(e);
//     toast.error(e.toString());
//   }
// };

// const _vendor_details_by_id = (data: any) => {
//   return {
//     type: VENDOR_DETAILS,
//     payload: data,
//   };
// };

// export const getVendorDetailsById = (id: number) => async (dispatch: any) => {
//   await axios
//     .get(`${keys().ServicePath}/api/vendor/${id}`)
//     .then((res) => {
//       const data: any = res.data.rows[1][0];
//       data.birthDate = new Date(res.data.rows[1][0].birthDate);
//       data.address = res.data.rows[2];
//       data.bankDetails = res.data.rows[3];
//       data.documentDetails = res.data.rows[4];
//       //const data = res.data.rows[0]
//       dispatch(_vendor_details_by_id(data));
//     })
//     .catch((e) => {
//       toast.error(e.toString());
//     });
// };

// const _vendor_dropdown = (data: any) => {
//   return {
//     type: VENDOR_DROPDOWN,
//     payload: data,
//   };
// };

// export const getVendorDropDownList = () => async (dispatch: any) => {
//   await axios
//     .get(`${keys().ServicePath}/api/vendor/vendor_dropdown_list`)
//     .then((res) => {
//       const data: any[] = [
//         ...res.data.rows[1],
//         { value: 0, label: "V.K.PATEL TRANSLINES", entityTypeId: 4 },
//       ];
//       dispatch(_vendor_dropdown(data));
//     })
//     .catch((e) => {
//       toast.error(e.toString());
//     });
// };

// export const getPartyVendorList = () => async (dispatch: any) => {
//   await axios
//     .get(`${keys().ServicePath}/api/vendor/party_vendor_list`)
//     .then((res) => {
//       const data: any[] = res.data.rows[1];
//       dispatch(_vendor_dropdown(data));
//     })
//     .catch((e) => {
//       toast.error(e.toString());
//     });
// };

// const _Creditors_List = (data: any) => {
//   return {
//     type: CREDITORS_LIST,
//     payload: data,
//   };
// };

// const _Debtors_List = (data: any) => {
//   return {
//     type: DEBTORS_LIST,
//     payload: data,
//   };
// };

// export const getCreditorDebtorList = () => async (dispatch: any) => {
//   await axios
//     .get(`${keys().ServicePath}/api/vendor/creditordebtorlist`)
//     .then((res) => {
//       dispatch(_Creditors_List(res.data.rows[4]));
//       dispatch(_Debtors_List(res.data.rows[2]));
//     })
//     .catch((e) => {
//       toast.error(e.toString());
//     });
// };
