import React from "react";
import FooterLogo from "../assests/Images/Footer-Logo.png";

const Footer = () => {
  return (
    <div
      className="w-100 d-flex justify-content-end align-items-center p-2 mb-0 gap-1 pe-1"
      style={{ opacity: 0.35 }}
    >
      <span style={{ fontSize: "small" }}>Developed By </span>
      <img src={FooterLogo} alt="FooterLogo" />
    </div>
  );
};

export default Footer;
