import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { keys } from "../../config/keys";
import Barcode from "react-barcode";

const initialvalue = {
  weight: 0,
  noofbags: 0,
  bagweight: 0,
  addkasor: 0,
  minuskasor: 0,
  incentive: 0,
  netweight: 0,
  productweight: 0,
  actualbags: 0,
  totalbagweight: 0,
  producttype: [
    {
      value: 1,
      label: "Bhukho",
      details: [
        {
          id: 1,
          name: "bhuko_kasor_1",
          label: `પત્તીમાં  માં ડોખરું `,
          operation: `addsub`,
          value: 0,
          producttypeid: 1
        },
        {
          id: 2,
          name: "bhuko_kasor_2",
          label: `પત્તીમાં માં રાડીયું `,
          operation: `sub`,
          value: 0,
          producttypeid: 1
        },
        {
          id: 3,
          name: "bhuko_kasor_3",
          label: `પત્તીમાં માં માટી `,
          operation: `sub`,
          value: 0,
          producttypeid: 1
        },
        {
          id: 4,
          name: "bhuko_kasor_4",
          label: `પત્તીમાં માં ભેજ  `,
          operation: `per`,
          value: 0,
          producttypeid: 1
        },
        {
          id: 5,
          name: "bhuko_kasor_5",
          label: `Others`,
          operation: `sub`,
          value: 0,
          producttypeid: 1
        }
      ]
    },
    {
      value: 2,
      label: "Dakru",
      details: [
        {
          id: 1,
          name: "dakru_kasor_1",
          label: `ડોખરું માં રાડીયું`,
          operation: `sub`,
          value: 0,
          producttypeid: 2
        },
        {
          id: 2,
          name: "dakru_kasor_2",
          label: `ડોખરું માં માટી `,
          operation: `sub`,
          value: 0,
          producttypeid: 2
        },
        {
          id: 3,
          name: "dakru_kasor_3",
          label: `ડોખરું માં ભેજ `,
          operation: `per`,
          value: 0,
          producttypeid: 2
        },
        {
          id: 4,
          name: "dakru_kasor_4",
          label: `Others`,
          operation: `sub`,
          value: 0
        }
      ]
    },
    {
      value: 3,
      label: "Gariyo",
      details: [
        {
          id: 1,
          name: "gariyu_kasor_1",
          label: `ગારિયામાં ડોખરું`,
          operation: `sub`,
          value: 0,
          producttypeid: 3
        },
        {
          id: 2,
          name: "gariyu_kasor_2",
          label: `ગારિયામાં માં માટી `,
          operation: `sub`,
          value: 0,
          producttypeid: 3
        },
        {
          id: 3,
          name: "gariyu_kasor_3",
          label: `ગારિયામાં માં ભેજ `,
          operation: `per`,
          value: 0,
          producttypeid: 3
        },
        {
          id: 4,
          name: "gariyu_kasor_4",
          label: `Others`,
          operation: `sub`,
          value: 0
        }
      ]
    }
  ]
};

const PurchaseBill = React.forwardRef((props, ref) => {
  const { data } = props;


  const [total, setTotal] = useState(initialvalue);

  useEffect(() => {
    setTotal(initialvalue);
  }, []);

  useEffect(() => {
    setTotal(initialvalue);
    data.purchasedetails
      .filter((x) => x.producttypeid !== 4)
      .map((item) =>
        setTotal((prev) => ({
          ...prev,
          weight: prev.weight + item.totalbagweight,
          noofbags: prev.noofbags + item.actualbags,
          bagweight: prev.bagweight + item.bagweight,
          addkasor: prev.addkasor + item.addkasor,
          minuskasor: prev.minuskasor + item.minuskasor,
          incentive: prev.incentive + item.incentiveperkg,
          netweight: prev.netweight + item.netweight,
          productweight: prev.productweight + item.productweight,
          actualbags: prev.actualbags + item.actualbags,
          totalbagweight: prev.totalbagweight + item.totalbagweight
        }))
      );
  }, [data]);

  // useEffect(() => {
  //   console.log(total);
  // }, [total]);
  return (
    <div
      ref={ref}
      className="d-flex justify-content-center flex-column px-3 py-2"
      //style={{ width: "300px" }}
    >
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <h4>{`NISOL MANUFACTURING COMPANY PRIVATE LIMITED`}</h4>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <h6>{`Farmer Purchase Bill`}</h6>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          {<b>{`Farmer Code #:`}</b>}
          {` ${data.farmercode}`}
        </div>
        <div className="col-6">
          {<b>{`Date:`}</b>}
          {` ${data.purchasedate}`}
        </div>
        <div className="col-6">
          {<b>{`Farmer Name:`}</b>}
          {` ${data.name}`}
        </div>
        <div className="col-6">
          {<b>{`Voucher #:`}</b>}
          {` ${data.voucherno}`}
        </div>
        <div className="col-6">
          {<b>{`Village:`}</b>}
          {` ${data.villagename}`}
        </div>
        <div className="col-6">
          {<b>{`Vehicle No:`}</b>}
          {` ${data.vehicleno}`}
        </div>
        <div className="col-6">
          {<b>{`Variety:`}</b>}
          {` ${
            data?.purchasedetails.length > 0
              ? data?.purchasedetails[0].productname
              : ""
          } `}
        </div>
        <div className="col-6">
          {<b>{`Gross Weight:`}</b>}
          {` ${data.vehicleweight} KG`}
        </div>
        <div className="col-6">
          {<b>{`Vigha:`}</b>}
          {` ${
            data?.purchasedetails.length > 0
              ? data?.purchasedetails[0].actuallandbigha
              : ""
          }`}
        </div>
        <div className="col-6">
          {<b>{`Incentive:`}</b>}
          {` ${total.incentive} `}
        </div>
        {/* <div className="col-12">
          {<b>{`Print Time`}</b>}
          {` ${dayjs(Date()).format(keys().dbTimeFormat)}`}
        </div> */}
      </div>
      <div className="row">
        <div className="col-12">
          <table className="table table-sm table-bordered">
            <thead>
              <tr>
                <th>{`Material Name`}</th>

                <th>{`Weight`}</th>
                <th>{`Bag Qty`}</th>
                <th>{`Bag Weight`}</th>
                <th>{`- Kasor`}</th>
                <th>{`+ Kasor`}</th>
                <th>{`Incentive`}</th>
                <th>{`Net Weight`}</th>
              </tr>
            </thead>
            <tbody>
              {data?.purchasedetails
                ?.filter((x) => x.producttypeid !== 4)
                .map((item) => {
                  return (
                    <tr key={Math.random()}>
                      <td>{item.producttype}</td>
                      <td className="text-end">{item.productweight}</td>
                      <td className="text-end">{item.actualbags}</td>
                      <td className="text-end">{Number(item.totalbagweight).toFixed(3)}</td>
                      <td className="text-end">{Number(item.minuskasor).toFixed(3)}</td>
                      <td className="text-end">{Number(item.addkasor).toFixed(3)}</td>
                      <td className="text-end">{Number(item.incentiveperkg)}</td>
                      <td className="text-end">{Number(item.netweight).toFixed(3)}</td>
                    </tr>
                  );
                })}
            </tbody>
            <tfoot>
              <tr>
                <th>{"Total"}</th>
                <th className="text-end">{total.productweight}</th>
                <th className="text-end">{total.actualbags}</th>
                <th className="text-end">{Number(total.totalbagweight).toFixed(3)}</th>
                <th className="text-end">{Number(total.minuskasor).toFixed(3)}</th>
                <th className="text-end">{Number(total.addkasor).toFixed(3)}</th>
                <th className="text-end">{Number(total.incentive)}</th>
                <th className="text-end">{Number(total.netweight).toFixed(3)}</th>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="col-12 d-flex flex-column">
          {initialvalue?.producttype.map((x) => {
            let t = data?.purchasedetails?.filter(
              (y) =>
                (y.producttypeid === x.value && y.bhuko_kasor_1 > 0) ||
                y.bhuko_kasor_2 > 0 ||
                y.bhuko_kasor_3 > 0 ||
                y.bhuko_kasor_4 > 0 ||
                y.bhuko_kasor_5 > 0 ||
                y.dakru_kasor_1 > 0 ||
                y.dakru_kasor_2 > 0 ||
                y.dakru_kasor_3 > 0 ||
                y.dakru_kasor_4 > 0 ||
                y.gariyu_kasor_1 > 0 ||
                y.gariyu_kasor_2 > 0 ||
                y.gariyu_kasor_3 > 0 ||
                y.gariyu_kasor_4 > 0
            );
          
            if (t.length>0) {
              return x.details.map(
                (d) =>
                  t[0][d.name] > 0 && <small className="fw-small">{`${d.label} - ${t[0][d.name]}(g)`}</small>
              );
            }
          })}
        </div>

        <div className="col-12 mt-1 text-center">
          <Barcode
            value={data?.voucherno}
            height={30}
            displayValue={true}
            format={"CODE128"}
            fontSize={10}
          />
        </div>
      </div>
    </div>
  );
});

export default PurchaseBill;
