/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { grnValidationSchema } from "./validationSchema";
import { yieldConfigurationActions } from "../../redux/actions";
import { statusOption } from "./../../hooks/StaticData";
import { toast } from "react-toastify";

const initialValues = {
  id: 0,
  name: "",
  dakru: 0.0,
  gariyu: 0.0,
  bhukho: 0.0,
  estimated: 0.0,
  isactive: 1,
  selStatus: statusOption[0] || null,
};

export const usePurchaseInvoiceHooks = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialValues);
  

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(grnValidationSchema),
  });

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const ondropdownchange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      isactive: selectedOption.value,
      selStatus: selectedOption,
    }));
  };

  const resetFormData = () => {
    // setFormData(initialValues);
    // setSelStatus(statusOption[0]);
  };

  const onSubmit = async () => {
    if (formData.id > 0) {
      await dispatch(
        yieldConfigurationActions.updateYieldConfiguration(
          { ...formData },
          () => {
            toast.success("Yield Configuration update successfully");
          }
        )
      );
    } else {
      await dispatch(
        yieldConfigurationActions.saveYieldConfiguration(
          { ...formData },
          () => {
            toast.success("Yield Configuration create successfully");
          }
        )
      );
    }
    await dispatch(yieldConfigurationActions.getYieldConfiguration());
    setFormData(initialValues);
    setFormData((prev) => ({ ...prev, selStatus: statusOption[0] }));
  };

  useEffect(() => {
    dispatch(yieldConfigurationActions.getYieldConfiguration());

    setFormData((prev) => ({ ...prev, selStatus: statusOption[0] }));
  }, []);

  const onCancelClick = () => {
    setFormData(initialValues);
  };


  return {
    setFormData,
    formData,
    handleChange,
    initialValues,
    register,
    handleSubmit,
    errors,
    resetFormData,
    control,
    onSubmit,
    ondropdownchange,
    onCancelClick,

  };
};
