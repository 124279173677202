import React, { useCallback, useEffect, useState } from "react";
import { Label, Input, Row, Col, FormGroup } from "reactstrap";
import { toast } from "react-toastify";
import CustomModal from "../../../Component/CustomModal";
import Select from "react-select";
import Flatpickr from "react-flatpickr";

import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../../store/reducers/layoutReducer";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import useBreakPoints from "../../../hooks/useBreakPoints";
import CustomAutoComplete from "../../../Component/CustomAutoComplete";
import { TextField } from "@mui/material";
const EditTransportMaster = ({
  editModal,
  onCloseModal,
  onSubmitModal,
  singleData,
}) => {
  const [loading, setLoading] = useState(false);
  const { isMobile } = useBreakPoints();
  const [submitLoading, setSubmitLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginDetails, token } = useSelector((state) => state.layout);

  const [inputData, setInputData] = useState({
    unitId: singleData?.unit?.unitName,
    villageId: singleData?.village?.villageName,
    kilometer: singleData?.kiloMeters,
    rate: singleData?.rate,
    effectiveDate: singleData?.effectiveDate,
  });

  const [inputDataError, setInputDataError] = useState({
    unitId: false,
    villageId: false,
    kilometer: false,
    rate: false,
    effectiveDate: false,
  });

  const handleChangeInput = (name, value) => {
    setInputData({ ...inputData, [name]: value });
    if (value?.length > 0) {
      setInputDataError({ ...inputDataError, [name]: false });
    } else {
      setInputDataError({ ...inputDataError, [name]: true });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputData.kilometer) {
      toast.error("Kilometer is Required!");
      setInputDataError({ ...inputDataError, kilometer: true });
    } else if (!inputData.rate) {
      toast.error("Rate is Required!");
      setInputDataError({ ...inputDataError, rate: true });
    } else {
      setSubmitLoading(true);
      let formData = new FormData();

      formData.append("kilometer", inputData.kilometer);
      formData.append("rate", inputData.rate);
      formData.append("id", singleData?.id);

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}company/edittransportation`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setSubmitLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setSubmitLoading(false);

          onSubmitModal();
        } else {
          setSubmitLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setSubmitLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  return (
    <CustomModal
      loading={submitLoading}
      title={"Edit Transport Master"}
      handleSubmit={handleSubmit}
      modalState={editModal}
      onCloseModal={onCloseModal}
      editState={true}
    >
      {loading ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <ClipLoader color="#75D100" size={50} />
        </div>
      ) : (
        <form>
          <div>
            <Row className=" g-3 mt-2 ">
              <Col lg={12} md={12} sm={12} className="d-flex">
                {/* <CustomAutoComplete
                  name="unitId"
                  fullWidth
                  disabled
                  key={`unit-${unitOptions?.length}`}
                  label="Location Name *"
                  value={
                    unitOptions.map(
                      (product) => product?.label === inputData?.unitId
                    )?.label
                  }
                  options={unitOptions.map((product) => product.label)}
                  size="small"
                  getOptionLabel={(option) => option}
                  onChange={(event, option) => {
                    let selectedData = unitOptions?.find(
                      (er) => er?.label === option
                    );
                    setInputData({ ...inputData, unitId: selectedData?.value });
                    setInputDataError({ ...inputDataError, unitId: false });
                  }}
                  error={inputDataError?.unitId}
                  renderOption={(props, option) => {
                    const { id, label } = unitOptions.filter(
                      (product) => product.label === option
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label}
                      </li>
                    );
                  }}
                /> */}
                <TextField
                  id="outlined-basic"
                  label="Location Name *"
                  fullWidth
                  name="unit"
                  size="small"
                  value={inputData?.unitId}
                  error={inputDataError?.unitId}
                  disabled
                  variant="outlined"
                />
              </Col>
              <Col lg={12} md={12} sm={12} className="d-flex">
                <TextField
                  id="outlined-basic"
                  label="Village *"
                  fullWidth
                  name="village"
                  size="small"
                  disabled
                  value={inputData?.villageId}
                  error={inputDataError?.villageId}
                  variant="outlined"
                />
                {/* <CustomAutoComplete
                  name="VillageId"
                  fullWidth
                  key={`unit-${villageOptions?.length}`}
                  disabled
                  label="Village *"
                  value={
                    villageOptions.map(
                      (product) => product?.label === inputData?.unitId
                    )?.label
                  }
                  options={villageOptions}
                  size="small"
                  getOptionLabel={(option) => option?.label}
                  onChange={(event, option) => {
                    console.log("option: ", option);

                    setInputData({ ...inputData, villages: option });
                    setInputDataError({ ...inputDataError, villageId: false });
                  }}
                  error={inputDataError?.villageId}
                  renderOption={(props, option) => {
                    const { id, label } = villageOptions?.filter(
                      (product) => product.label === option?.label
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label || ""}
                      </li>
                    );
                  }}
                /> */}
              </Col>
              <Col lg={12} md={12} sm={12} className="d-flex">
                <TextField
                  id="outlined-basic"
                  label="Kilometer *"
                  type="number"
                  fullWidth
                  name="kilometer"
                  size="small"
                  value={inputData?.kilometer}
                  error={inputDataError?.kilometer}
                  onChange={(e) => {
                    handleChangeInput(e.target.name, e.target.value);
                  }}
                  variant="outlined"
                />
              </Col>
              <Col lg={12} md={12} sm={12} className="d-flex">
                <TextField
                  id="outlined-basic"
                  type="number"
                  size="small"
                  label="Rate *"
                  name="rate"
                  fullWidth
                  value={inputData?.rate}
                  error={inputDataError?.rate}
                  onChange={(e) => {
                    handleChangeInput(e.target.name, e.target.value);
                  }}
                  variant="outlined"
                />
              </Col>
            </Row>
          </div>
          <div>
            <Row className=" g-3 mt-2 ">
              <Col lg={12} md={12} sm={12} className="d-flex">
                <Flatpickr
                  className={`form-control ${
                    inputDataError?.effectiveDate ? "border border-danger" : ""
                  }`}
                  options={{
                    dateFormat: "Y-m-d",
                    minDate: new Date(),
                  }}
                  disabled
                  placeholder="Effective Date *"
                  value={inputData?.effectiveDate}
                  onChange={(date, dateStr) => {
                    setInputData({
                      ...inputData,
                      effectiveDate: dateStr,
                    });
                    setInputDataError({
                      ...inputDataError,
                      effectiveDate: false,
                    });
                  }}
                />
              </Col>
            </Row>
          </div>
        </form>
      )}
    </CustomModal>
  );
};

export default EditTransportMaster;
