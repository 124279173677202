import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import LoaderComponent from "./LoaderComponent";
import { Button, CircularProgress, styled } from "@mui/material";
import { Add, Check, Close, Update } from "@mui/icons-material";

const CustomModalContainer = styled(Modal)(({ theme, headerColor }) => {
  return {
    "& .modal-content": { borderRadius: "0.2rem" },
    "& .modal-footer": {
      padding: "0.3rem",
    },
    "& .modal-header": {
      padding: "0.8rem",
      borderRadius: 0,
      background: "#000000",
      background: `-webkit-linear-gradient(to right,#000000,${headerColor.color})`,
      background: `linear-gradient(to right,#000000,${headerColor.color})`,
      color: "#fff",
    },
  };
});

const ModalContainer = ({
  loading,
  children,
  title,
  modalState,
  onCloseModal,
  onClick,
  btnText = "Ok",
  size,
  disableButton = false,
  headerColor = "#0f9b0f",
  btnCloseText = "Cancel",
}) => {
  return (
    <CustomModalContainer
      id="showModal"
      size={size ? size : "md"}
      isOpen={modalState}
      toggle={() => onCloseModal()}
      centered
      headerColor={{ color: headerColor }}
    >
      {loading && <LoaderComponent />}
      <div>
        <ModalHeader
          className="p-3 modal-header-color text-white"
          style={{ textTransform: "uppercase" }}
          toggle={() => onCloseModal()}
        >
          {title}
        </ModalHeader>
        <ModalBody className="p-3">{children}</ModalBody>

        <div className="hstack gap-2 justify-content-end p-3">
          <Button
            type="button"
            className="custom-btn-page-layout custom-btn-grey"
            onClick={() => {
              onCloseModal(false);
            }}
            startIcon={<Close />}
          >
            {btnCloseText}
          </Button>

          <Button
            type="button"
            className="custom-btn-page-layout custom-btn-success"
            disabled={disableButton ? disableButton : loading}
            startIcon={
              loading ? (
                <CircularProgress size={15} color="secondary" />
              ) : (
                <Check />
              )
            }
            onClick={onClick ? onClick : null}
          >
            {btnText}
          </Button>
        </div>
      </div>
    </CustomModalContainer>
  );
};
export default ModalContainer;
