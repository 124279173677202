import { Col, Row } from "reactstrap";
import PageLayout from "../../Component/PageLayout";
import QrReader from "../../common/QRScanner";
import { useNavigate } from "react-router-dom";
// import QrReader from "react-qr-scanner";
import { toast } from "react-toastify";
import { Button, TextField } from "@mui/material";
import { textFieldProps } from "../../common/commonControlProps";
import { useState } from "react";
import { FindInPage } from "@mui/icons-material";

const BagUpdateScanner = () => {
  const navigate = useNavigate();
  const onSuccess = (data) => {
    navigate(`/scanbagsupdate/${Number(data)}`);
  };

  const [vocuherno, setVoucherno] = useState(0);
  const onError = (data) => {
    // console.log(data);
    toast.error(data);
  };
  const previewStyle = {
    height: 240,
    width: 320
  };
  return (
    <PageLayout title={"Scan Bag Update"}>
      <Row justify className="m-3 justify-content-between" >
        <Col lg={4} md={4} sm={12}>
          <TextField
            {...textFieldProps}
            label="Voucher #"
            type={"number"}
            value={vocuherno}
            onChange={(e) => setVoucherno(e.target.value)}
          />
          <Button
            className="custom-btn-page-layout custom-btn-purple text-white "
            startIcon={<FindInPage />}
            fullWidth
            type="button"
            onClick={() => onSuccess(vocuherno)}
            disabled={vocuherno <= 0}
          >
            {`Get Details`}
          </Button>
        </Col>
      </Row>
      <Row justify className="m-3">
        <Col lg={4} md={4} sm={12}>
          <QrReader type="bagupdate" onSuccess={onSuccess} />
          {/* <QrReader
            onSuccess={onSuccess}
            delay={500}
            style={previewStyle}
            onError={onError}
            onScan={onSuccess}
            facingMode="rear"
          /> */}
        </Col>
      </Row>
    </PageLayout>
  );
};

export default BagUpdateScanner;
