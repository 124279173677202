import React, { useState, useEffect, useMemo, useCallback } from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import PageLayout from "../../Component/PageLayout";
import { Col, Row, FormGroup, Form } from "reactstrap";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Autocomplete,
  TextField,
  Typography,
  styled,
  Paper,
  Button,
  FormHelperText,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { keys } from "../../config/keys";
import { useSurveyHooks } from "./useSurveyHooks";
import { textFieldProps } from "../../common/commonControlProps";
import { useNavigate } from "react-router-dom";
import { Cancel, DesignServices, Save, Update } from "@mui/icons-material";
import { SurveyCreator } from "survey-creator-react";
import SurveyCreatorWidget from "./SurveyCreator";
import DesignSurvey from "./DesignSurvey";

const SurveyManage = () => {
  const navigate = useNavigate();

  const {
    register,
    errors,
    formData,
    handleChange,
    handleSubmit,
    onSubmit,
    onStartDateChange,
    onEndDateChange,
    onWEFDateChange,
    onUpdateSurveyDesign,
  } = useSurveyHooks();

  const [loading, setLoading] = useState(false);
  const [isDesignerOpen, setIsDesignerOpen] = useState(false);

  //   const [survey, setSurvey] = useState(new Survey.Model({}));
  //   const [survey, setSurvey] = useState(null);
  //   const [existingData, setExistingData] = useState({
  //     name: "John Doe",
  //     rating: 4,
  //     comments: "Great experience!",
  //   });

  //   // Initialize the survey model
  //   useEffect(() => {
  //     const surveyModel = new Survey.Model({
  //       elements: [
  //         {
  //           type: "text",
  //           name: "name",
  //           title: "Enter your name:",
  //         },
  //         {
  //           type: "dropdown",
  //           name: "rating",
  //           title: "Rate our service:",
  //           choices: [1, 2, 3, 4, 5],
  //         },
  //         {
  //           type: "comment",
  //           name: "comments",
  //           title: "Additional comments:",
  //         },
  //       ],
  //     });
  //     setSurvey(surveyModel);
  //   }, []);

  //   // Set existing data when the component mounts
  //   useEffect(() => {
  //     if (survey) {
  //       survey.data = existingData;
  //       if (survey.dataChanged) {
  //         survey.dataChanged.fire(); // Ensure survey.dataChanged is defined before calling fire
  //       }
  //     }
  //   }, [existingData, survey]);

  //   // Handle survey completion
  //   const onSurveyComplete = (result) => {
  //     console.log("Survey data:", result.data);
  //     // Perform CRUD operations with the survey data (e.g., send to server, update state, etc.)
  //   };

  return (
    <PageLayout
      title={"Survey Manage"}
      loading={loading}
      backBtnTitle={`Back to Survey list`}
      onClick={() => navigate("/surveylist")}
    >
      <Form onSubmit={handleSubmit(onSubmit)} className="m-4 g-2">
        {!isDesignerOpen && (
          <Row>
            <Col lg={6} md={6} sm={12}>
              <FormGroup>
                <TextField
                  {...register("title")}
                  error={errors?.title}
                  id="title"
                  name="title"
                  value={formData?.title}
                  label="Survey Title"
                  onChange={handleChange}
                  {...textFieldProps}
                />
                {/* <FormHelperText>{`Last Voucher # - ${formData?.lastvoucherno}`}</FormHelperText> */}
                {/* {errors?.title && (
                <ErrorMessage message={errors?.voucherno?.message} />
              )}  */}
              </FormGroup>
            </Col>

            <Col lg={2} md={4} sm={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  format={keys().shortDateFormat}
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                      variant: "standard",
                    },
                  }}
                  onChange={onStartDateChange}
                  value={formData?.startdate}
                  clearable
                />
              </LocalizationProvider>
            </Col>

            <Col lg={2} md={4} sm={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  format={keys().shortDateFormat}
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                      variant: "standard",
                    },
                  }}
                  onChange={onEndDateChange}
                  value={formData?.enddate}
                  clearable
                />
              </LocalizationProvider>
            </Col>

            <Col lg={2} md={4} sm={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="WEF Date"
                  format={keys().shortDateFormat}
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                      variant: "standard",
                    },
                  }}
                  onChange={onWEFDateChange}
                  value={formData?.wefdate}
                  clearable
                />
              </LocalizationProvider>
            </Col>
          </Row>
        )}
        {isDesignerOpen && formData.id === 0 && (
          <DesignSurvey
            onUpdateDesign={(data) => {
              onUpdateSurveyDesign(data);
              setIsDesignerOpen(false);
            }}
          />
        )}

        {isDesignerOpen && formData.id > 0 && (
          <DesignSurvey
            id={formData.id}
            jsonObj={formData.jSONObj}
            onUpdateDesign={(data) => {
              onUpdateSurveyDesign(data);
              setIsDesignerOpen(false);
            }}
          />
        )}

        {!isDesignerOpen && (
          <Row className="mt-2">
            <Col lg={2} md={2} sm={12}>
              <Button
                className="custom-btn-page-layout custom-btn-warning btn btn-sm text-white btn-block"
                type="button"
                onClick={() => setIsDesignerOpen(true)}
                fullWidth
                startIcon={<DesignServices />}
                size="small"
              >
                {formData.id > 0 ? `Edit Your Survey` : `Design Your Survey`}
              </Button>
            </Col>
            <Col lg={2} md={2} sm={12}>
              <Button
                className="custom-btn-page-layout custom-btn-success"
                fullWidth
                type="submit"
                size="small"
                startIcon={formData.id > 0 ? <Update /> : <Save />}
              >
                {formData.id > 0 ? ` Update` : `  Save`}
              </Button>
            </Col>
            <Col lg={2} md={2} sm={12}>
              <Button
                className="custom-btn-page-layout custom-btn-danger btn btn-sm text-white btn-block"
                type="button"
                // onClick={onCancelClick}
                startIcon={<Cancel />}
                fullWidth
                size="small"
              >
                {` Cancel`}
              </Button>
            </Col>
          </Row>
        )}
      </Form>

      {/* <div>
            <Row className=" g-3 mt-2 ">
              <Col lg={3} md={12} sm={12} className="d-flex">
                <CustomAutoComplete
                  name="talukaId"
                  fullWidth
                  label="Taluka *"
                  key={`taluka-${talukaArray?.length}-${counter}`}
                  value={
                    talukaArray.map(
                      (product) => product?.label === inputData?.talukaId
                    )?.label
                  }
                  options={talukaArray.map((product) => product.label)}
                  size="small"
                  getOptionLabel={(option) => option}
                  onChange={(event, option) => {
                    let selectedData = talukaArray?.find(
                      (er) => er?.label === option
                    );
                    setInputData({
                      ...inputData,
                      talukaId: selectedData?.value,
                      villages: [],
                    });
                    setVillageArray([]);
                    getVillageOptionsListAPi(selectedData?.value);

                    setInputDataError({ ...inputDataError, talukaId: false });
                  }}
                  error={inputDataError?.talukaId}
                  renderOption={(props, option) => {
                    const { id, label } = talukaArray.filter(
                      (product) => product.label === option
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label}
                      </li>
                    );
                  }}
                />
              </Col>

              <Col lg={3} md={12} sm={12} className="d-flex">
                <CustomAutoComplete
                  name="VillageId"
                  fullWidth
                  multiple
                  label="Village *"
                  value={inputData?.villages}
                  options={villageArray}
                  size="small"
                  getOptionLabel={(option) => option?.label}
                  onChange={(event, option) => {
                    setInputData({ ...inputData, villages: option });
                    setInputDataError({ ...inputDataError, villageId: false });
                  }}
                  error={inputDataError?.villageId}
                  renderOption={(props, option) => {
                    const { id, label } = villageArray?.filter(
                      (product) => product.label === option?.label
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label}
                      </li>
                    );
                  }}
                />
              </Col>

              <Col lg={3} md={12} sm={12} className="d-flex">
                <button
                  type="submit"
                  disabled={searchLoading}
                  className="custom-btn-page-layout btn text-white"
                >
                  {searchLoading ? (
                    <ClipLoader color="#75D100" size={25} />
                  ) : (
                    "Search"
                  )}
                </button>
              </Col>
            </Row>
          </div> */}
    </PageLayout>
  );
};

export default SurveyManage;
