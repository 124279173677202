import { Col, Form, Row } from "reactstrap";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { keys } from "../../config/keys";
import PageLayout from "../../Component/PageLayout";
import { textFieldProps } from "../../common/commonControlProps";
import { Button, FormHelperText, TextField, Typography } from "@mui/material";
import VirtualizeAutoComplete from "../../common/VirtualizedAutoComplete";
import { useNavigate } from "react-router-dom";
import TableContainer from "../../Component/TableContainer";
import { GetApp, Save, QrCode } from "@mui/icons-material";
import PurchaseBill from "../FarmerPurchase/PurchaseBill";
import { GridAction } from "../../common/useGridAction";
import { useMemo } from "react";
import { useUpdateBagsOnlyScanHooks } from "./useUpdateBagsOnlyScanHooks";
import { ErrorMessage } from "../../common/Error";

export const UpdateBagsOnlyScan = () => {
  const navigate = useNavigate();

  const {
    error,
    formData,
    handleSubmit,
    onSubmit,
    farmerDropdownList,
    handleChange,
    onFarmerChange,
    onDateChange,
    errors,
    farmerYieldData,
    column,
    register,
    producttype,
    onAddClick,
    handleBlur,
    onCancelClick,
    onProductTypeChange,
    previous_purchases_by_farmer,
    previous_purchase_columns,
    gatepassComponentRef,
    onPrintGatePass,
    onSubmitandPrint,
    fetch_details,
    previousProduct,
    currentProduct,
    handleCurrentProductDetailsChange,
    onAdd
  } = useUpdateBagsOnlyScanHooks();

  const purchasedetailscolumn = useMemo(
    () => [
      {
        Header: "Type",
        accessor: "producttype"
      },
      {
        Header: "Actual Bags Counted",
        accessor: "actualbags"
      },
      {
        ...GridAction({
          permission: { edit: false, delete: false, print: false }
        })
      }
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <PageLayout
      title={"Purchase - Update Bags"}
      // backBtnTitle={`BAck to List`}
      // onClick={() => navigate("/farmerpurchases")}
    >
      <Form className="m-4 g-2" onSubmit={handleSubmit(onSubmit)}>
        <Row className="mt-2 g-2">
          <Col lg={12} md={12} sm={12}>
            <table className="table table-sm table-bordered">
              <tbody>
                <tr>
                  <th>{`Vocuher No`}</th>
                  <td>{formData.voucherno}</td>
                </tr>
                <tr>
                  <th>{`Farmer Code`}</th>
                  <td>{formData?.farmerCode}</td>
                </tr>
                <tr>
                  <th>{`Farmer Name`}</th>
                  <td>{formData?.name}</td>
                </tr>
                <tr>
                  <th>{`Vehicle No`}</th>
                  <td>{formData?.vehicleno}</td>
                </tr>
                <tr>
                  <th>{`Purchase Date`}</th>
                  <td>{keys().shortDate(formData?.purchasedate)}</td>
                </tr>
                <tr>
                  <th>{`Vehicle Weight`}</th>
                  <td>{formData?.vehicleweight}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <Row className="mt-2 g-2">
          <Col lg={2} md={2} sm={12}>
            <VirtualizeAutoComplete
              name="selectedproducttype"
              size="small"
              options={formData.purchasedetails}
              getOptionDisabled={(option) => {
                return (
                  formData.verifiedproduct.filter(
                    (x) => x.producttypeid === option.producttypeid
                  ).length > 0
                );
              }}
              clearText={false}
              value={formData?.selectedproducttype}
              onChange={onProductTypeChange}
              renderInput={(params) => (
                <TextField
                  {...register("selectedproducttype")}
                  {...params}
                  label="Type"
                  {...textFieldProps}
                />
              )}
            />
          </Col>
          <Col lg={1} md={1} sm={12}>
            <TextField
              label={`No of Bags`}
              {...textFieldProps}
              value={formData?.selectedproducttype?.noofbags}
              InputLabelProps={{ shrink: true }}
              disabled
            />
          </Col>
          {/* <Col lg={1} md={1} sm={12}>
            <TextField
              label={`Weight`}
              name="weight"
              {...textFieldProps}
              InputLabelProps={{ shrink: true }}
              // value={
              //   formData.verifiedproduct.length <= 0
              //     ? formData.vehicleweight
              //     : currentProduct?.weight
              // }
              value={
                currentProduct?.weight <= 0
                  ? formData.vehicleweight
                  : currentProduct?.weight
              }
              onChange={handleCurrentProductDetailsChange}
            />
          </Col> */}
          <Col lg={1} md={1} sm={12}>
            <TextField
              label={`Actual Bags`}
              name="actualbags"
              type="number"
              {...textFieldProps}
              value={currentProduct?.actualbags}
              InputLabelProps={{ shrink: true }}
              onChange={handleCurrentProductDetailsChange}
            />
          </Col>
          <Col lg={1} md={1} sm={12}>
            <TextField
              label={`Bag weight (grams)`}
              {...textFieldProps}
              InputLabelProps={{ shrink: true }}
              value={currentProduct?.bagweight}
              disabled
            />
          </Col>
          <Col lg={1} md={1} sm={12}>
            <TextField
              label={`Total Bag weight (kg)`}
              {...textFieldProps}
              InputLabelProps={{ shrink: true }}
              value={currentProduct?.totalbagweight}
              disabled
            />
          </Col>
          <Col lg={1} md={1} sm={12}>
            <TextField
              label={`Kasor/bag (grams)`}
              name="kasorperbag"
              {...textFieldProps}
              InputLabelProps={{ shrink: true }}
              type="number"
              value={currentProduct?.kasorperbag}
              onChange={handleCurrentProductDetailsChange}
            />
          </Col>
          <Col lg={3} md={3} sm={12}>
            <TextField
              label={`Remarks for Kasor`}
              name="kasorremarks"
              {...textFieldProps}
              InputLabelProps={{ shrink: true }}
              value={currentProduct?.kasorremarks}
              onChange={handleCurrentProductDetailsChange}
            />
          </Col>
          {
            <Col md={6} lg={6} sm={12}>
              {formData?.producttype
                ?.find((x) => x.value === currentProduct?.producttypeid)
                ?.details.map((x) => (
                  <Row>
                    <Col lg={3} md={3} sm={6}>
                      <Typography variant="subtitle2">{x.label}</Typography>
                    </Col>
                    <Col lg={3} md={3} sm={6}>
                      <TextField
                        name={x.name}
                        {...textFieldProps}
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        value={currentProduct[x.name] || 0}
                        onChange={handleCurrentProductDetailsChange}
                      />
                    </Col>
                  </Row>
                ))}
            </Col>
          }
          {/*
         
          <Col lg={1} md={1} sm={12}>
            <TextField
              label={`Incentive/Kg`}
              name="incentiveperkg"
              {...textFieldProps}
              InputLabelProps={{ shrink: true }}
              type="number"
              value={currentProduct?.incentiveperkg}
              onChange={handleCurrentProductDetailsChange}
              disabled={currentProduct?.producttypeid !== 1}
            />
          </Col>
          <Col lg={1} md={1} sm={12}>
            <TextField
              label={`Net Weight`}
              {...textFieldProps}
              InputLabelProps={{ shrink: true }}
              type="number"
              value={currentProduct?.netweight}
              disabled
            />
          </Col>
          
          <Col lg={3} md={3} sm={12}>
            <TextField
              label={`Remarks for Incentive`}
              name="incentiveremarks"
              {...textFieldProps}
              InputLabelProps={{ shrink: true }}
              value={currentProduct?.incentiveremarks}
              onChange={handleCurrentProductDetailsChange}
            />
          </Col> */}
          <Col lg={2} md={2} sm={12} className="d-flex align-items-center">
            <Button
              className="custom-btn-page-layout text-white "
              size="small"
              startIcon={<Save />}
              fullWidth
              type="button"
              disabled={currentProduct?.noofbags <= 0 ? true : false}
              onClick={onAdd}
            >
              {`Save`}
            </Button>
          </Col>
          {[...formData.purchasedetails].length > 0 && (
            <Col lg={12} md={12} sm={12}>
              <TableContainer
                columns={purchasedetailscolumn}
                // data={formData.purchasedetails}
                data={[
                  ...formData?.verifiedproduct.filter(
                    (x) => x.producttypeid !== 4
                  )
                ]}
                isGlobalFilter={false}
                tableClass={"table-sm"}
                showPagination={false}
                showFooter={false}
                initialState={{ pageSize: 20, pageIndex: 0 }}
                divClass={"table-responsive"}
                exports={{ excel: false, pdf: false }}
              />
            </Col>
          )}
          <Col lg={2} md={2} sm={12} className="d-flex align-items-center">
            <Button
              className="custom-btn-page-layout custom-btn-warning text-white "
              size="small"
              startIcon={<QrCode />}
              fullWidth
              type="button"
              onClick={() => navigate("/")}
            >
              {`Back To Scan`}
            </Button>
          </Col>
          {/* 
          {Object.values(error).filter((x) => x.value).length > 0 && (
            <ul className="list-group list-group-flush">
              {Object.values(error)
                .filter((x) => x.value)
                .map((x) => (
                  <li class="list-group-item text-danger" key={Math.random()}>
                    {x.message}
                  </li>
                ))}
            </ul>
          )} */}
        </Row>
      </Form>
      <div className="d-none">
        <PurchaseBill ref={gatepassComponentRef} data={formData} />
      </div>
    </PageLayout>
  );
};

export default UpdateBagsOnlyScan;
