import React, { useEffect, useRef, useState } from "react";
import PageLayout from "../../Component/PageLayout";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import StateOption from "../../hooks/StateOptions";
import CountryOption from "../../hooks/CountryOption";
import DistrictOption from "../../hooks/DistrictOption";
import TalukaOption from "../../hooks/TalukaOption";
import VillageOption from "../../hooks/VillageOption";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteModal from "../../Component/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import useBreakPoints from "../../hooks/useBreakPoints";

const EditLandRegistration = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.layout);

  const [countryArray, setCountryArray] = useState([]);
  // eslint-disable-next-line
  const [stateArray, setStateArray] = useState([]);
  // eslint-disable-next-line
  const [districtArray, setDistrictArray] = useState([]);
  // eslint-disable-next-line
  const [talukaArray, setTalukaArray] = useState([]);

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const { isTablet } = useBreakPoints();

  const [guardianStateArray, setGuardianStateArray] = useState();
  const [guardianDistrictArray, setGuardianDistrictArray] = useState();
  const [guardianTalukaArray, setGuardianTalukaArray] = useState();
  const [guardianVillageArray, setGuardianVillageArray] = useState();

  const { getStateOption } = StateOption();
  const { getCountryOption } = CountryOption();
  const { getDistrictOption } = DistrictOption();
  const { getTalukaOption } = TalukaOption();
  const { getVillageOption } = VillageOption();

  useEffect(() => {
    getCountryOption().then((res) => {
      setCountryArray(res);
    });
    getStateOption(location?.state?.country?.id).then((res) => {
      setStateArray(res);
    });
    getDistrictOption(location?.state?.state?.id).then((res) => {
      setDistrictArray(res);
    });
    getTalukaOption(location?.state?.district?.id).then((res) => {
      setTalukaArray(res);
    });
    getVillageOption(location?.state?.taluka?.id).then((res) => {
      setTalukaArray(res);
    });
    // eslint-disable-next-line
  }, []);

  const [landDetails, setLandDetails] = useState([
    {
      country: "",
      state: "",
      dist: "",
      taluka: "",
      village: "",
      surveyNo: "",
      totalLand: "",
      registeredLand: "",
      vigha: "",
      ownerShipStatus: false,
      landOwnerName: "",
      address: "",
      guardianCountry: "",
      guardianState: "",
      guardianDist: "",
      guardianTaluka: "",
      guardianVillage: "",
      guardianPincode: "",
      guardianName: "",
      tenantDocument: "",
      agreementDocument: "",
      landDocument: "",
    },
  ]);

  const [landDetailsError, setLandDetailsError] = useState([
    {
      country: false,
      state: false,
      dist: false,
      taluka: false,
      village: false,
      surveyNo: false,
      totalLand: false,
      registeredLand: false,
      vigha: false,
      ownerShipStatus: false,
      landOwnerName: false,
      address: false,
      guardianCountry: false,
      guardianState: false,
      guardianDist: false,
      guardianTaluka: false,
      guardianVillage: false,
      guardianPincode: false,
      guardianName: false,
      tenantDocument: false,
      agreementDocument: false,
      landDocument: false,
    },
  ]);

  useEffect(() => {
    if (location?.state?.landDetails) {
      let arr = [];
      let arr5 = [];
      // eslint-disable-next-line
      location?.state?.landDetails?.map((er) => {
        let countArray = [];
        let staArray = [];
        let distArray = [];
        let taluArray = [];
        let villArray = [];

        getCountryOption().then((res) => {
          // eslint-disable-next-line
          res?.map((er) => {
            countArray?.push(er);
          });
        });
        getStateOption(location?.state?.countryDetails?.id).then((res) => {
          // eslint-disable-next-line
          res?.map((er) => {
            staArray.push(er);
          });
        });
        getDistrictOption(location?.state?.stateDetails?.id).then((res) => {
          // eslint-disable-next-line
          res?.map((er) => {
            distArray.push(er);
          });
        });
        getTalukaOption(location?.state?.districtDetails?.id).then((res) => {
          // eslint-disable-next-line
          res?.map((er) => {
            taluArray.push(er);
          });
        });
        getVillageOption(location?.state?.talukaDetails?.id).then((res) => {
          // eslint-disable-next-line
          res?.map((er) => {
            villArray.push(er);
          });
        });

        arr.push({
          landDetailsId: er?.id,
          country: {
            label: er?.country?.name,
            value: er?.country?.id,
          },
          state: {
            label: er?.state?.stateName,
            value: er?.state?.id,
          },
          dist: {
            label: er?.district?.districtName,
            value: er?.district?.id,
          },
          taluka: {
            label: er?.taluka?.talukaName,
            value: er?.taluka?.id,
          },
          village: {
            label: er?.village?.villageName,
            value: er?.village?.id,
          },
          countryArray: countArray,
          stateArray: staArray,
          districtArray: distArray,
          talukaArray: taluArray,
          villageArray: villArray,
          surveyNo: er?.surveyNo,
          totalLand: er?.totalLand,
          vigha: er?.vigha,
          registeredLand: er?.registeredLand,
          ownerShipStatus: er?.ownerShipStatus === "True" ? true : false,
          landOwnerName: er?.landOwnerName,
          address: er?.address,
          guardianCountry: {
            label: er?.guardianCountry?.name,
            value: er?.guardianCountry?.id,
          },
          guardianState: {
            label: er?.guardianState?.stateName,
            value: er?.guardianState?.id,
          },
          guardianDist: {
            label: er?.guardianDistrict?.districtName,
            value: er?.guardianDistrict?.id,
          },
          guardianTaluka: {
            label: er?.guardianTaluka?.talukaName,
            value: er?.guardianTaluka?.id,
          },
          guardianVillage: {
            label: er?.guardianVillage?.villageName,
            value: er?.guardianVillage?.id,
          },
          guardianPincode: er?.guardianPincode,
          guardianName: er?.guardianName,
          tenantDocument: {
            url: er?.tenantDocument,
            name: er?.tenantDocument?.split("/").at(-1).slice(5),
          },
          agreementDocument: {
            url: er?.agreementDocument,
            name: er?.agreementDocument?.split("/").at(-1).slice(5),
          },
          landDocument: {
            url: er?.landDocument,
            name: er?.landDocument?.split("/").at(-1).slice(5),
          },
        });

        arr5.push({
          country: false,
          state: false,
          dist: false,
          taluka: false,
          village: false,
          surveyNo: false,
          totalLand: false,
          registeredLand: false,
          vigha: false,
          ownerShipStatus: false,
          landOwnerName: false,
          address: false,
          guardianCountry: false,
          guardianState: false,
          guardianDist: false,
          guardianTaluka: false,
          guardianVillage: false,
          guardianPincode: false,
          guardianName: false,
          tenantDocument: false,
          agreementDocument: false,
          landDocument: false,
        });
      });
      setLandDetails(arr);
      setLandDetailsError(arr5);
    }
    // eslint-disable-next-line
  }, [location?.state?.landDetails]);

  // Accordian Toggle For Multiple land Registration
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  // handleChange Input
  const handleChangeInput = (index, name, value) => {
    let arr = [...landDetails];

    if (name === "surveyNo") {
      arr[index][name] = value;
      let arr5 = [...landDetailsError];
      if (value?.length > 0) {
        arr5[index]["surveyNo"] = false;
      } else {
        arr5[index]["surveyNo"] = true;
      }
      setLandDetailsError(arr5);
    } else if (name === "registeredLand") {
      if (parseInt(value) <= parseInt(arr[index]["totalLand"])) {
        arr[index][name] = value;
        let arr5 = [...landDetailsError];
        if (value?.length > 0) {
          arr5[index]["registeredLand"] = false;
        } else {
          arr5[index]["registeredLand"] = true;
        }
        setLandDetailsError(arr5);
      } else {
        if (value === "") {
          arr[index][name] = value;
          let arr5 = [...landDetailsError];
          if (value?.length > 0) {
            arr5[index]["registeredLand"] = false;
          } else {
            arr5[index]["registeredLand"] = true;
          }
          setLandDetailsError(arr5);
        }
      }
    } else if (name === "totalLand") {
      arr[index][name] = value;
      let arr5 = [...landDetailsError];
      if (value?.length > 0) {
        arr5[index]["totalLand"] = false;
      } else {
        arr5[index]["totalLand"] = true;
      }
      setLandDetailsError(arr5);
    } else if (name === "vigha") {
      arr[index][name] = value;
      let arr5 = [...landDetailsError];
      if (value?.length > 0) {
        arr5[index]["vigha"] = false;
      } else {
        arr5[index]["vigha"] = true;
      }
      setLandDetailsError(arr5);
    } else if (name === "guardianPincode") {
      arr[index][name] = value;
      let arr5 = [...landDetailsError];
      if (value?.label?.length > 0) {
        arr5[index]["guardianPincode"] = false;
      } else {
        arr5[index]["guardianPincode"] = true;
      }
      setLandDetailsError(arr5);
    } else if (name === "address") {
      arr[index][name] = value;
      let arr5 = [...landDetailsError];
      if (value?.length > 0) {
        arr5[index]["address"] = false;
      } else {
        arr5[index]["address"] = true;
      }
      setLandDetailsError(arr5);
    } else {
      arr[index][name] = value;
    }

    if (name === "country") {
      getStateOption(value.value)
        .then((res) => {
          const updatedLandDetails = [...landDetails];
          updatedLandDetails[index][name] = value;
          updatedLandDetails[index]["state"] = "";
          updatedLandDetails[index]["dist"] = "";
          updatedLandDetails[index]["taluka"] = "";
          updatedLandDetails[index]["village"] = "";
          updatedLandDetails[index] = {
            ...updatedLandDetails[index],
            stateArray: res,
          };

          setLandDetails(updatedLandDetails);
        })

        .catch((error) => {
          console.error("Error fetching state options:", error);
        });

      let arr5 = [...landDetailsError];
      if (value?.label?.length > 0) {
        arr5[index]["country"] = false;
      } else {
        arr5[index]["country"] = true;
      }
      setLandDetailsError(arr5);
    } else if (name === "state") {
      getDistrictOption(value.value)
        .then((res) => {
          const updatedLandDetails = [...landDetails];
          updatedLandDetails[index][name] = value;
          updatedLandDetails[index]["dist"] = "";
          updatedLandDetails[index]["taluka"] = "";
          updatedLandDetails[index]["village"] = "";
          updatedLandDetails[index] = {
            ...updatedLandDetails[index],
            districtArray: res,
          };

          setLandDetails(updatedLandDetails);
        })
        .catch((error) => {
          console.error("Error fetching state options:", error);
        });
      let arr5 = [...landDetailsError];
      if (value?.label?.length > 0) {
        arr5[index]["state"] = false;
      } else {
        arr5[index]["state"] = true;
      }
      setLandDetailsError(arr5);
    } else if (name === "dist") {
      getTalukaOption(value.value)
        .then((res) => {
          const updatedLandDetails = [...landDetails];
          updatedLandDetails[index][name] = value;
          updatedLandDetails[index]["taluka"] = "";
          updatedLandDetails[index]["village"] = "";
          updatedLandDetails[index] = {
            ...updatedLandDetails[index],
            talukaArray: res,
          };

          setLandDetails(updatedLandDetails);
        })
        .catch((error) => {
          console.error("Error fetching state options:", error);
        });
      let arr5 = [...landDetailsError];
      if (value?.label?.length > 0) {
        arr5[index]["dist"] = false;
      } else {
        arr5[index]["dist"] = true;
      }
      setLandDetailsError(arr5);
    } else if (name === "taluka") {
      getVillageOption(value.value)
        .then((res) => {
          const updatedLandDetails = [...landDetails];
          updatedLandDetails[index][name] = value;

          updatedLandDetails[index]["village"] = "";
          updatedLandDetails[index] = {
            ...updatedLandDetails[index],
            villageArray: res,
          };

          setLandDetails(updatedLandDetails);
        })
        .catch((error) => {
          console.error("Error fetching state options:", error);
        });
      let arr5 = [...landDetailsError];
      if (value?.label?.length > 0) {
        arr5[index]["taluka"] = false;
      } else {
        arr5[index]["taluka"] = true;
      }
      setLandDetailsError(arr5);
    } else if (name === "village") {
      let arr5 = [...landDetailsError];
      if (value?.label?.length > 0) {
        arr5[index]["village"] = false;
      } else {
        arr5[index]["village"] = true;
      }
      setLandDetailsError(arr5);
    }

    if (name === "guardianCountry") {
      getStateOption(value.value).then((res) => {
        setGuardianStateArray(res);
      });
      let arr5 = [...landDetailsError];
      if (value?.label?.length > 0) {
        arr5[index]["guardianCountry"] = false;
      } else {
        arr5[index]["guardianCountry"] = true;
      }
      setLandDetailsError(arr5);
    }
    if (name === "guardianState") {
      getDistrictOption(value.value).then((res) => {
        setGuardianDistrictArray(res);
      });
      let arr5 = [...landDetailsError];
      if (value?.label?.length > 0) {
        arr5[index]["guardianState"] = false;
      } else {
        arr5[index]["guardianState"] = true;
      }
      setLandDetailsError(arr5);
    }
    if (name === "guardianDist") {
      getTalukaOption(value.value).then((res) => {
        setGuardianTalukaArray(res);
      });

      let arr5 = [...landDetailsError];
      if (value?.label?.length > 0) {
        arr5[index]["guardianDist"] = false;
      } else {
        arr5[index]["guardianDist"] = true;
      }
      setLandDetailsError(arr5);
    }
    if (name === "guardianTaluka") {
      getVillageOption(value.value).then((res) => {
        setGuardianVillageArray(res);
      });
      let arr5 = [...landDetailsError];
      if (value?.label?.length > 0) {
        arr5[index]["guardianTaluka"] = false;
      } else {
        arr5[index]["guardianTaluka"] = true;
      }
      setLandDetailsError(arr5);
    }

    if (name === "ownerShipStatus") {
      if (value) {
        arr[index]["guardianCountry"] = "";
        arr[index]["guardianState"] = "";
        arr[index]["guardianDist"] = "";
        arr[index]["guardianTaluka"] = "";
        arr[index]["guardianVillage"] = "";
        arr[index]["address"] = "";
      } else {
        arr[index]["guardianCountry"] = {
          label: location?.state.farmerCountry?.name,
          value: location?.state.farmerCountry?.id,
        };
        arr[index]["guardianState"] = {
          label: location?.state.farmerState?.stateName,
          value: location?.state.farmerState?.id,
        };
        arr[index]["guardianDist"] = {
          label: location?.state.farmerDistrict?.districtName,
          value: location?.state.farmerDistrict?.id,
        };
        arr[index]["guardianTaluka"] = {
          label: location?.state.farmerTaluka?.talukaName,
          value: location?.state.farmerTaluka?.id,
        };
        arr[index]["guardianVillage"] = {
          label: location?.state.farmerVillage?.villageName,
          value: location?.state.farmerVillage?.id,
        };
        arr[index]["address"] =
          location?.state?.addressLine1 +
          location?.state?.addressLine2 +
          location?.state?.addressLine3;
      }
    }

    setLandDetails(arr);
  };

  //   handleDelete LandDetails
  const handleDeleteLandDetails = (index) => {
    setDeleteId(index);
    setDeleteModal(true);
  };

  // handleDelete Btn
  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    let arr = [...landDetails];

    arr.splice(deleteId, 1);
    setLandDetails(arr);
    let arr5 = [...landDetailsError];
    arr5.splice(deleteId, 1);
    setLandDetailsError(arr5);

    let formData = new FormData();
    formData.append("id", arr[deleteId].landDetailsId);
    formData.append("isDelete", "Yes");

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/deleteland`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setDeleteModal(false);
        toast?.success(_data?.message);
        setDeleteLoading(false);
      } else {
        setDeleteLoading(false);
        setDeleteModal(false);

        toast.error(_data?.message);
      }
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
    }
  };

  // Document Upload document
  const landDocumentImage = useRef(null);
  const handlelandDocumentImageUploadBtn = () =>
    landDocumentImage.current.click();

  const agreementImage = useRef(null);
  const handleagreementImageUploadBtn = () => agreementImage.current.click();

  const tenetImage = useRef(null);
  const handletenetImageUploadBtn = () => tenetImage.current.click();

  //   HandleChange Farmer Photo
  const handleImageChange = async (index, name, value) => {
    if (value) {
      const allowedTypes = ["image/jpeg", "image/png"];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!allowedTypes.includes(value.type)) {
        toast.error("Invalid file type. Please select a JPEG, PNG.");
      } else if (value.size > maxSize) {
        toast.error("File size exceeds the limit of 5MB.");
      } else {
        if (name === "landDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["landDocument"] = false;
          setLandDetailsError(arr5);
        } else if (name === "tenantDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["tenantDocument"] = false;
          setLandDetailsError(arr5);
        } else if (name === "agreementDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["agreementDocument"] = false;
          setLandDetailsError(arr5);
        }

        const formData = new FormData();
        formData.append("media", value);

        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/uploadmedia`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          let arr = [...landDetails];
          arr[index][name] = { url: _data.uploadedURL, name: _data?.filename };

          setLandDetails(arr);
        } else {
          toast.error(_data?.error);
        }

        return { url: "https://httpbin.org/post" };
      }
    }
  };

  const handleSubmit = async (e, index) => {
    e.preventDefault();

    if (!landDetails[index]?.country) {
      toast.error("Country is Required!");
      let arr = [...landDetailsError];
      arr[index]["country"] = true;
      setLandDetailsError(arr);
    } else if (!landDetails[index]?.state) {
      toast?.error("State is Required!");
      let arr = [...landDetailsError];
      arr[index]["state"] = true;
      setLandDetailsError(arr);
    } else if (!landDetails[index]?.dist) {
      toast?.error("District is Required!");
      let arr = [...landDetailsError];
      arr[index]["dist"] = true;
      setLandDetailsError(arr);
    } else if (!landDetails[index]?.taluka) {
      toast?.error("Taluka is Required!");
      let arr = [...landDetailsError];
      arr[index]["taluka"] = true;
      setLandDetailsError(arr);
    } else if (!landDetails[index]?.village) {
      toast?.error("village is Required!");
      let arr = [...landDetailsError];
      arr[index]["village"] = true;
      setLandDetailsError(arr);
    } else if (!landDetails[index]?.address) {
      toast?.error("address is Required!");
      let arr = [...landDetailsError];
      arr[index]["address"] = true;
      setLandDetailsError(arr);
    } else if (!landDetails[index]?.surveyNo) {
      toast?.error("surveyNo is Required!");
      let arr = [...landDetailsError];
      arr[index]["surveyNo"] = true;
      setLandDetailsError(arr);
    } else if (!landDetails[index]?.totalLand) {
      toast?.error("Total Land is Required!");
      let arr = [...landDetailsError];
      arr[index]["totalLand"] = true;
      setLandDetailsError(arr);
    } else if (!landDetails[index]?.vigha) {
      toast?.error("Vigha is Required!");
      let arr = [...landDetailsError];
      arr[index]["vigha"] = true;
      setLandDetailsError(arr);
    } else if (!landDetails[index]?.registeredLand) {
      toast?.error("Registered Land is Required!");
      let arr = [...landDetailsError];
      arr[index]["registeredLand"] = true;
      setLandDetailsError(arr);
    } else if (!landDetails[index]?.guardianCountry) {
      toast?.error("Country is Required!");
      let arr = [...landDetailsError];
      arr[index]["guardianCountry"] = true;
      setLandDetailsError(arr);
    } else if (!landDetails[index]?.guardianState) {
      toast?.error("State is Required!");
      let arr = [...landDetailsError];
      arr[index]["guardianState"] = true;
      setLandDetailsError(arr);
    } else if (!landDetails[index]?.guardianDist) {
      toast?.error("District is Required!");
      let arr = [...landDetailsError];
      arr[index]["guardianDist"] = true;
      setLandDetailsError(arr);
    } else if (!landDetails[index]?.guardianTaluka) {
      toast?.error("Taluka is Required!");
      let arr = [...landDetailsError];
      arr[index]["guardianTaluka"] = true;
      setLandDetailsError(arr);
    } else if (!landDetails[index]?.guardianVillage) {
      toast?.error("village is Required!");
      let arr = [...landDetailsError];
      arr[index]["guardianVillage"] = true;
      setLandDetailsError(arr);
    } else if (!landDetails[index]?.guardianPincode) {
      toast?.error("Pincode is Required!");
      let arr = [...landDetailsError];
      arr[index]["guardianPincode"] = true;
      setLandDetailsError(arr);
    } else if (!landDetails[index]?.landDocument) {
      toast?.error("LandDocument is Required!");
      let arr = [...landDetailsError];
      arr[index]["landDocument"] = true;
      setLandDetailsError(arr);
    } else if (
      !landDetails[index]?.tenantDocument &&
      landDetails[index]?.ownerShipStatus
    ) {
      toast?.error("Tenant Document is Required!");
      let arr = [...landDetailsError];
      arr[index]["tenantDocument"] = true;
      setLandDetailsError(arr);
    } else if (
      !landDetails[index]?.agreementDocument &&
      landDetails[index]?.ownerShipStatus
    ) {
      toast?.error("Agreement Document is Required!");
      let arr = [...landDetailsError];
      arr[index]["agreementDocument"] = true;
      setLandDetailsError(arr);
    } else {
      setLoading(true);
      let formData = new FormData();

      formData.append("id", landDetails[index]?.landDetailsId);
      formData.append("countryId", landDetails[index].country.value);
      formData.append("stateId", landDetails[index].state.value);
      formData.append("districtId", landDetails[index].state.value);
      formData.append("talukaId", landDetails[index].taluka.value);
      formData.append("villageId", landDetails[index].village.value);
      formData.append("surveyNo", landDetails[index].surveyNo);
      formData.append("totalLand", landDetails[index].totalLand);
      formData.append("vigha", landDetails[index].vigha);
      formData.append("registeredLand", landDetails[index].registeredLand);
      formData.append("ownerShipStatus", landDetails[index].ownerShipStatus);
      formData.append("landOwnerName", landDetails[index].landOwnerName);
      formData.append("address", landDetails[index].address);
      formData.append("guardianName", landDetails[index].guardianName);
      formData.append(
        "guardianCountryId",
        landDetails[index].guardianCountry.value
      );
      formData.append(
        "guardianStateId",
        landDetails[index].guardianState.value
      );
      formData.append(
        "guardianDistrictId",
        landDetails[index].guardianState.value
      );
      formData.append(
        "guardianTalukaId",
        landDetails[index].guardianTaluka.value
      );
      formData.append(
        "guardianVillageId",
        landDetails[index].guardianVillage.value
      );
      formData.append("guardianPincode", landDetails[index].guardianPincode);
      formData.append("tenantDocument", landDetails[index].tenantDocument.url);
      formData.append(
        "agreementDocument",
        landDetails[index].agreementDocument.url
      );
      formData.append("landDocument", landDetails[index].landDocument.url);

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/editland`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }
        // dispatch(logOutRequest());
        // toast.error("Session Expired.");

        if (_data) {
          toast?.success(_data?.message);
          setLoading(false);
        } else {
          setLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  return (
    <PageLayout
      title={"Edit Land registration"}
      backBtnTitle={"Back"}
      backBtnTitleLink={"/landregistration"}
    >
      {landDetails?.map((item, index) => (
        <Accordion
          key={index}
          open={open}
          className={index === 0 ? "mt-4" : ""}
          toggle={toggle}
        >
          <fieldset
            className="cus-add-req-sur border-none m-0 p-2"
            style={{ border: "none" }}
          >
            <AccordionItem>
              <AccordionHeader targetId={`${index + 1}`}>
                Land No : {index + 1}
              </AccordionHeader>
              <AccordionBody accordionId={`${index + 1}`}>
                <div className="w-100 d-flex  justify-content-end align-items-center">
                  <div className="flex-shrink-0" style={{ marginRight: 30 }}>
                    <button
                      type="button"
                      className="custom-btn-page-layout btn text-white mx-3 mb-3"
                      onClick={() => {
                        handleDeleteLandDetails(index);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        className="me-2 mb-1"
                      >
                        <g clipPath="url(#clip0_217_1693)">
                          <path
                            d="M6.63458 -0.0178223C5.50496 -0.0178223 4.59746 0.920832 4.59746 2.05564V2.30775H2.30765C1.98977 2.30775 1.73073 2.56679 1.73073 2.88468V3.4616H1.15381V4.61545H2.30765V13.2693C2.30765 14.2183 3.08939 15.0001 4.03842 15.0001H10.9615C11.9105 15.0001 12.6923 14.2183 12.6923 13.2693V4.61545H13.8461V3.4616H13.2692V2.88468C13.2692 2.56679 13.0102 2.30775 12.6923 2.30775H10.4025V2.05506C10.4025 0.920832 9.49496 -0.0178223 8.36535 -0.0178223H6.63458ZM6.63458 1.17179H8.36535C8.82919 1.17179 9.21285 1.55025 9.21285 2.05506V2.30775H5.7865V2.05506C5.7865 1.55025 6.17015 1.17237 6.634 1.17237L6.63458 1.17179ZM3.4615 4.61545H6.41823C6.48977 4.62295 6.56073 4.63333 6.63458 4.63333H8.36535C8.43919 4.63333 8.50958 4.62295 8.58169 4.61545H11.5384V13.2693C11.5384 13.5941 11.2863 13.8462 10.9615 13.8462H4.03842C3.71362 13.8462 3.4615 13.5941 3.4615 13.2693V4.61545ZM4.61535 5.76929V12.6924H5.76919V5.76929H4.61535ZM6.92304 5.76929V12.6924H8.07689V5.76929H6.92304ZM9.23073 5.76929V12.6924H10.3846V5.76929H9.23073Z"
                            fill="#fff"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_217_1693">
                            <rect width="15" height="15" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="mt-1">Delete</span>
                    </button>
                  </div>
                </div>

                {/* State, Dist and Taluka  */}
                <div>
                  <Row className=" mt-2 g-3 ">
                    <Col lg={3} md={12} sm={12} className="d-flex ">
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Country<span className="required_span">*</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Select
                          isMulti={false}
                          onChange={(e) => {
                            handleChangeInput(index, "country", e);
                          }}
                          value={item?.country}
                          options={countryArray}
                          // menuPlacement="top"
                          name="choices-single-default"
                          id="countryAddCompany"
                          className={`w-100 ${
                            landDetailsError[index]["country"]
                              ? "border border-danger"
                              : ""
                          }`}
                        ></Select>
                      </Col>
                    </Col>
                    <Col lg={3} md={12} sm={12} className="d-flex ">
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          State<span className="required_span">*</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Select
                          isMulti={false}
                          onChange={(e) => {
                            handleChangeInput(index, "state", e);
                          }}
                          value={item?.state}
                          options={item?.stateArray}
                          // menuPlacement="top"
                          name="choices-single-default"
                          id="countryAddCompany"
                          className={`w-100 ${
                            landDetailsError[index]["state"]
                              ? "border border-danger"
                              : ""
                          }`}
                        ></Select>
                      </Col>
                    </Col>
                    <Col lg={3} md={12} sm={12} className="d-flex ">
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Dist<span className="required_span">*</span>
                        </Label>
                      </Col>
                      <Col sm={8} className="d-flex mt-2">
                        <Select
                          isMulti={false}
                          onChange={(e) => {
                            handleChangeInput(index, "dist", e);
                          }}
                          value={item?.dist}
                          name="dist"
                          options={item?.districtArray}
                          className={`w-100 ${
                            landDetailsError[index]["dist"]
                              ? "border border-danger"
                              : ""
                          }`}
                        ></Select>
                      </Col>
                    </Col>
                    <Col lg={3} md={12} sm={12} className="d-flex ">
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Taluka<span className="required_span">*</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Select
                          isMulti={false}
                          onChange={(e) => {
                            handleChangeInput(index, "taluka", e);
                          }}
                          value={item?.taluka}
                          name="taluka"
                          options={item?.talukaArray}
                          // menuPlacement="top"

                          className={`w-100 ${
                            landDetailsError[index]["taluka"]
                              ? "border border-danger"
                              : ""
                          }`}
                        ></Select>
                      </Col>
                    </Col>
                  </Row>
                </div>

                {/* village,surveyNo and totalLand  */}
                <div>
                  <Row className=" mt-2 g-3 ">
                    <Col lg={3} md={12} sm={12} className="d-flex ">
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Village<span className="required_span">*</span>
                        </Label>
                      </Col>
                      <Col md={8}>
                        <Select
                          isMulti={false}
                          onChange={(e) => {
                            handleChangeInput(index, "village", e);
                          }}
                          value={item?.village}
                          options={item?.villageArray}
                          // menuPlacement="top"
                          name="choices-single-default"
                          id="countryAddCompany"
                          className={`w-100 ${
                            landDetailsError[index]["village"]
                              ? "border border-danger"
                              : ""
                          }`}
                        ></Select>
                      </Col>
                    </Col>
                    <Col lg={3} md={12} sm={12} className="d-flex ">
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Survey No.<span className="required_span">*</span>
                        </Label>
                      </Col>
                      <Col md={8} className="d-flex mt-2">
                        <Input
                          id="exampleEmail"
                          value={item?.surveyNo}
                          name="surveyNo"
                          onChange={(e) =>
                            handleChangeInput(
                              index,
                              e.target.name,
                              e.target.value
                            )
                          }
                          className={`cust-input ${
                            landDetailsError[index]["surveyNo"]
                              ? "border-danger"
                              : ""
                          }`}
                          type="text"
                        />
                      </Col>
                    </Col>
                    <Col lg={6} md={12} sm={12} className="d-flex ">
                      <Col md={4}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Total Land in Document (In Sqm)
                          <span className="required_span">*</span>
                        </Label>
                      </Col>
                      <Col md={8}>
                        <Input
                          id="exampleEmail"
                          value={item?.totalLand}
                          name="totalLand"
                          onChange={(e) =>
                            handleChangeInput(
                              index,
                              e.target.name,
                              e.target.value
                            )
                          }
                          className={`cust-input ${
                            landDetailsError[index]["totalLand"]
                              ? "border-danger"
                              : ""
                          }`}
                          type="text"
                        />
                      </Col>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row className=" mt-2 g-3 ">
                    <Col lg={4} md={12} sm={12} className="d-flex ">
                      <Col md={4}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Total Vigha <span className="required_span">*</span>
                        </Label>
                      </Col>
                      <Col md={8}>
                        <Input
                          id="exampleEmail"
                          value={item?.vigha}
                          name="vigha"
                          onChange={(e) =>
                            handleChangeInput(
                              index,
                              e.target.name,
                              e.target.value
                            )
                          }
                          className={`cust-input ${
                            landDetailsError[index]["vigha"]
                              ? "border-danger"
                              : ""
                          }`}
                          type="text"
                        />
                      </Col>
                    </Col>
                    <Col lg={4} md={12} sm={12} className="d-flex ">
                      <Col md={7}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Total Registered Land (In Sqm)
                          <span className="required_span">*</span>
                        </Label>
                      </Col>
                      <Col md={5}>
                        <Input
                          id="exampleEmail"
                          value={item?.registeredLand}
                          name="registeredLand"
                          onChange={(e) =>
                            handleChangeInput(
                              index,
                              e.target.name,
                              e.target.value
                            )
                          }
                          className={`cust-input ${
                            landDetailsError[index]["registeredLand"]
                              ? "border-danger"
                              : ""
                          }`}
                          type="text"
                        />
                      </Col>
                    </Col>
                  </Row>
                </div>

                {/*  Ownership Status */}

                <div
                  style={{}}
                  className={`mt-4 ${
                    isTablet
                      ? ""
                      : "d-flex justify-content-start align-items-center"
                  }`}
                >
                  <Label className="me-4" style={{ fontWeight: 500 }}>
                    Ownership Status :
                  </Label>
                  <div>
                    <Label style={{ fontWeight: 500 }} className="me-2">
                      Is it Rented ?{" "}
                    </Label>
                    <Input
                      id="exampleEmail"
                      checked={item?.ownerShipStatus}
                      name="ownerShipStatus"
                      onChange={(e) =>
                        handleChangeInput(
                          index,
                          e.target.name,
                          e.target.checked
                        )
                      }
                      className="cust-input "
                      type="checkbox"
                    />
                  </div>
                </div>

                {/* LandOwnerName/ FarmerName  and Address  */}
                <div style={{}} className="pb-0">
                  <Row className=" mt-2 g-3 ">
                    <Col lg={4} md={12} sm={12} className="d-flex ">
                      <Col md={4}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          {!item?.ownerShipStatus
                            ? "Land Owner Name"
                            : "Farmer Name"}
                          <span className="required_span">*</span>
                        </Label>
                      </Col>
                      <Col md={8}>
                        <Input
                          id="exampleEmail"
                          value={
                            !item?.ownerShipStatus
                              ? item?.landOwnerName
                              : item?.guardianName
                          }
                          name={
                            !item?.ownerShipStatus
                              ? "landOwnerName"
                              : "guardianName"
                          }
                          onChange={(e) =>
                            handleChangeInput(
                              index,
                              e.target.name,
                              e.target.value
                            )
                          }
                          className="cust-input"
                          type="text"
                        />
                      </Col>
                    </Col>
                    <Col lg={8} md={12} sm={12} className="d-flex ">
                      <Col md={1}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Address<span className="required_span">*</span>
                        </Label>
                      </Col>
                      <Col md={9} className="d-flex mt-2">
                        <Input
                          id="exampleEmail"
                          value={item?.address}
                          name="address"
                          onChange={(e) =>
                            handleChangeInput(
                              index,
                              e.target.name,
                              e.target.value
                            )
                          }
                          className={`cust-input ${
                            landDetailsError[index]["address"]
                              ? "border-danger"
                              : ""
                          }`}
                          type="text"
                        />
                      </Col>
                    </Col>
                  </Row>
                </div>
                {/* Farmer State  and Farmer Dist  */}
                <div style={{}}>
                  <Row className=" mt-2 g-3 ">
                    <Col lg={4} md={12} sm={12} className="d-flex ">
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Country<span className="required_span">*</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Select
                          isMulti={false}
                          onChange={(e) => {
                            handleChangeInput(index, "guardianCountry", e);
                          }}
                          value={item?.guardianCountry}
                          options={countryArray}
                          // menuPlacement="top"
                          name="choices-single-default"
                          id="countryAddCompany"
                          className={`w-100 ${
                            landDetailsError[index]["guardianCountry"]
                              ? "border border-danger"
                              : ""
                          }`}
                        ></Select>
                      </Col>
                    </Col>
                    <Col lg={4} md={12} sm={12} className="d-flex ">
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          State<span className="required_span">*</span>
                        </Label>
                      </Col>
                      <Col md={8}>
                        <Select
                          isMulti={false}
                          onChange={(e) => {
                            handleChangeInput(index, "guardianState", e);
                          }}
                          value={item?.guardianState}
                          options={guardianStateArray}
                          // menuPlacement="top"
                          name="choices-single-default"
                          id="stateAddCompany"
                          className={`w-100 ${
                            landDetailsError[index]["guardianState"]
                              ? "border border-danger"
                              : ""
                          }`}
                        ></Select>
                      </Col>
                    </Col>
                    <Col lg={4} md={12} sm={12} className="d-flex ">
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Dist<span className="required_span">*</span>
                        </Label>
                      </Col>
                      <Col md={8} className="d-flex mt-2">
                        <Select
                          isMulti={false}
                          onChange={(e) => {
                            handleChangeInput(index, "guardianDist", e);
                          }}
                          value={item?.guardianDist}
                          options={guardianDistrictArray}
                          // menuPlacement="top"
                          name="choices-single-default"
                          id="stateAddCompany"
                          className={`w-100 ${
                            landDetailsError[index]["guardianDist"]
                              ? "border border-danger"
                              : ""
                          }`}
                        ></Select>
                      </Col>
                    </Col>
                  </Row>
                </div>

                {/* Farmer Village, Farmer Taluka   and pincode  */}
                <div style={{}}>
                  <Row className=" mt-2 g-3 ">
                    <Col lg={4} md={12} sm={12} className="d-flex ">
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Taluka<span className="required_span">*</span>
                        </Label>
                      </Col>
                      <Col md={9} className="d-flex mt-2">
                        <Select
                          isMulti={false}
                          onChange={(e) => {
                            handleChangeInput(index, "guardianTaluka", e);
                          }}
                          value={item?.guardianTaluka}
                          options={guardianTalukaArray}
                          // menuPlacement="top"
                          name="choices-single-default"
                          id="stateAddCompany"
                          className={`w-100 ${
                            landDetailsError[index]["guardianTaluka"]
                              ? "border border-danger"
                              : ""
                          }`}
                        ></Select>
                      </Col>
                    </Col>
                    <Col lg={4} md={12} sm={12} className="d-flex ">
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Village<span className="required_span">*</span>
                        </Label>
                      </Col>
                      <Col md={8}>
                        <Select
                          isMulti={false}
                          onChange={(e) => {
                            handleChangeInput(index, "guardianVillage", e);
                          }}
                          value={item?.guardianVillage}
                          options={guardianVillageArray}
                          // menuPlacement="top"
                          name="choices-single-default"
                          id="stateAddCompany"
                          className={`w-100 ${
                            landDetailsError[index]["guardianVillage"]
                              ? "border border-danger"
                              : ""
                          }`}
                        ></Select>
                      </Col>
                    </Col>

                    <Col lg={4} md={12} sm={12} className="d-flex ">
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Pincode<span className="required_span">*</span>
                        </Label>
                      </Col>
                      <Col md={8} className="d-flex mt-2">
                        <Input
                          id="exampleEmail"
                          value={item?.guardianPincode}
                          name="guardianPincode"
                          onChange={(e) =>
                            handleChangeInput(
                              index,
                              e.target.name,
                              e.target.value
                            )
                          }
                          className={`cust-input ${
                            landDetailsError[index]["guardianPincode"]
                              ? "border-danger"
                              : ""
                          }`}
                          type="number"
                          maxLength={6}
                        />
                      </Col>
                    </Col>
                  </Row>
                </div>
                {/* Upload Document  */}
                <div style={{}}>
                  <Row className=" mt-2 g-3 ">
                    <Col lg={4} md={6} sm={12} className="d-flex ">
                      <button
                        type="button"
                        onClick={handlelandDocumentImageUploadBtn}
                        className={` ${
                          landDetailsError[index]["landDocument"]
                            ? "border border-danger"
                            : ""
                        }`}
                        style={{
                          border: "none",
                          color: "white",
                          padding: 8,
                          background:
                            "linear-gradient(180deg, #C1C1C1 0%, #6D6D6D 100%)",
                          borderRadius: 5,
                        }}
                      >
                        Upload
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          style={{ height: 16, width: 16, marginLeft: 2 }}
                        >
                          <path
                            d="M4 12.6667H12"
                            stroke="white"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 10H9.33333V8H11L8 5M8 10H6.66667V8H5L8 5"
                            stroke="white"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>

                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept="image/png, image/jpeg , image/jpg,application/pdf "
                        name="landDocument"
                        ref={landDocumentImage}
                        onChange={(e) =>
                          handleImageChange(
                            index,
                            e.target.name,
                            e.target.files[0]
                          )
                        }
                      />

                      <span
                        className="mx-3"
                        style={{ fontWeight: 500, fontSize: 10 }}
                      >
                        {item?.landDocument
                          ? item?.landDocument?.name
                          : " Upload Agreement between owner and Tenant. (PDF/image)"}
                      </span>
                    </Col>

                    <Col lg={4} md={6} sm={12} className="d-flex ">
                      {item?.ownerShipStatus && (
                        <>
                          <button
                            type="button"
                            onClick={handleagreementImageUploadBtn}
                            className={` ${
                              landDetailsError[index]["agreementDocument"]
                                ? "border border-danger"
                                : ""
                            }`}
                            style={{
                              border: "none",
                              color: "white",
                              padding: 8,
                              background:
                                "linear-gradient(180deg, #C1C1C1 0%, #6D6D6D 100%)",
                              borderRadius: 5,
                            }}
                          >
                            Upload
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              style={{ height: 16, width: 16, marginLeft: 2 }}
                            >
                              <path
                                d="M4 12.6667H12"
                                stroke="white"
                                strokeWidth="1.33333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8 10H9.33333V8H11L8 5M8 10H6.66667V8H5L8 5"
                                stroke="white"
                                strokeWidth="1.33333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>

                          <input
                            style={{ display: "none" }}
                            type="file"
                            accept="image/png, image/jpeg , image/jpg,application/pdf "
                            name="agreementDocument"
                            ref={agreementImage}
                            onChange={(e) =>
                              handleImageChange(
                                index,
                                e.target.name,
                                e.target.files[0]
                              )
                            }
                          />

                          <span
                            className="mx-3"
                            style={{ fontWeight: 500, fontSize: 10 }}
                          >
                            {item?.agreementDocument
                              ? item?.agreementDocument?.name
                              : " Option to upload copy of 7/12 (PDF/image)"}
                          </span>
                        </>
                      )}
                    </Col>
                    <Col lg={4} md={6} sm={12} className="d-flex ">
                      {item?.ownerShipStatus && (
                        <>
                          <button
                            type="button"
                            onClick={handletenetImageUploadBtn}
                            className={` ${
                              landDetailsError[index]["tenantDocument"]
                                ? "border border-danger"
                                : ""
                            }`}
                            style={{
                              border: "none",
                              color: "white",
                              padding: 8,
                              background:
                                "linear-gradient(180deg, #C1C1C1 0%, #6D6D6D 100%)",
                              borderRadius: 5,
                            }}
                          >
                            Upload
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              style={{ height: 16, width: 16, marginLeft: 2 }}
                            >
                              <path
                                d="M4 12.6667H12"
                                stroke="white"
                                strokeWidth="1.33333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8 10H9.33333V8H11L8 5M8 10H6.66667V8H5L8 5"
                                stroke="white"
                                strokeWidth="1.33333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>

                          <input
                            style={{ display: "none" }}
                            type="file"
                            accept="image/png, image/jpeg , image/jpg,application/pdf "
                            name="tenantDocument"
                            ref={tenetImage}
                            onChange={(e) =>
                              handleImageChange(
                                index,
                                e.target.name,
                                e.target.files[0]
                              )
                            }
                          />

                          <span
                            className="mx-3"
                            style={{ fontWeight: 500, fontSize: 10 }}
                          >
                            {item?.tenantDocument
                              ? item?.tenantDocument?.name
                              : "Photo of Tenant"}
                          </span>
                        </>
                      )}
                    </Col>
                  </Row>
                </div>

                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="custom-btn-page-layout btn text-white mx-3"
                    disabled={loading}
                    onClick={(e) => {
                      handleSubmit(e, index);
                    }}
                  >
                    {loading ? (
                      <ClipLoader color="#75D100" size={25} />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </AccordionBody>
            </AccordionItem>
          </fieldset>
        </Accordion>
      ))}

      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={handleDeleteRequest}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
    </PageLayout>
  );
};

export default EditLandRegistration;
