import React, { useCallback, useEffect, useState } from "react";
import { Label, Input, Row, Col, FormGroup } from "reactstrap";
import { toast } from "react-toastify";
import CustomModal from "../../../Component/CustomModal";
import Select from "react-select";
import Flatpickr from "react-flatpickr";

import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../../store/reducers/layoutReducer";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import useBreakPoints from "../../../hooks/useBreakPoints";
import CustomAutoComplete from "../../../Component/CustomAutoComplete";
import { TextField } from "@mui/material";
const EditPriceListGroup = ({
  editModal,
  onCloseModal,
  onSubmitModal,
  singleData,
}) => {
  const [loading, setLoading] = useState(false);
  const { isMobile } = useBreakPoints();
  const [submitLoading, setSubmitLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginDetails, token } = useSelector((state) => state.layout);
  console.log('singleData:',singleData)
  const [inputData, setInputData] = useState({
    priceListName: singleData?.priceListName,
  });
  console.log('inoutData',inputData)
  let todayDate = new Date();

  const [inputDataError, setInputDataError] = useState({
    priceListName: false,
  });

  const handleChangeInput = (name, value) => {
    setInputData({ ...inputData, [name]: value });
    if (value?.length > 0) {
      setInputDataError({ ...inputDataError, [name]: false });
    } else {
      setInputDataError({ ...inputDataError, [name]: true });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputData.priceListName) {
      toast.error("Price List Name is Required!");
      setInputDataError({ ...inputDataError, kilometer: true });
    }else {
      setSubmitLoading(true);
      let formData = new FormData();

      formData.append("priceListName", inputData.priceListName);
      formData.append("id", singleData?.id);

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/editpricelistgroup`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setSubmitLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setSubmitLoading(false);

          onSubmitModal();
        } else {
          setSubmitLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setSubmitLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  return (
    <CustomModal
      loading={submitLoading}
      title={"Edit Price List Group"}
      handleSubmit={handleSubmit}
      modalState={editModal}
      onCloseModal={onCloseModal}
      editState={true}
    >
      {loading ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <ClipLoader color="#75D100" size={50} />
        </div>
      ) : (
        <form>
          <div>
            <Row className=" g-3 mt-2 ">
            <Col lg={12} md={12} sm={12} className="d-flex">
                <TextField
                  id="outlined-basic"
                  label="Price List Name *"
                  type="text"
                  fullWidth
                  name="priceListName"
                  size="small"
                  value={inputData?.priceListName}
                  error={inputDataError?.priceListName}
                  onChange={(e) => {
                    handleChangeInput(e.target.name, e.target.value);
                  }}
                  variant="outlined"
                />
              </Col>
            </Row>
          </div>
        </form>
      )}
    </CustomModal>
  );
};

export default EditPriceListGroup;
