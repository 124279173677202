// import { Actions } from ".";
import * as types from "../constTypes";

const initialState = [];

export const farmer_purchase_list = (state = initialState, action) => {
  switch (action.type) {
    case types.farmerTypes.FARMER_PURCHASE_LIST:
      return action.payload || [];
    default:
      return state;
  }
};

export const previous_purchases_by_farmer = (state = initialState, action) => {
  switch (action.type) {
    case types.farmerTypes.PREVIOUS_PURCHASES_FROM_FARMER:
      return action.payload || [];
    default:
      return state;
  }
};

export const purchases_from_farmer = (state = initialState, action) => {
  switch (action.type) {
    case types.farmerTypes.PURCHASES_FROM_FARMER:
      return action.payload || [];
    default:
      return state;
  }
};

export const invoice_list = (state = initialState, action) => {
  switch (action.type) {
    case types.farmerTypes.FARMER_INVOICE_LIST:
      return action.payload || [];
    default:
      return state;
  }
};

export const farmer_incentives = (state = initialState, action) => {
  switch (action.type) {
    case types.farmerTypes.FARMER_INCENTIVES:
      return action.payload || [];
    default:
      return state;
  }
};

export const farmer_transportation = (state = initialState, action) => {
  switch (action.type) {
    case types.farmerTypes.FARMER_TRANSPORTATION:
      return action.payload || [];
    default:
      return state;
  }
};

export const farmer_unaccountbags = (state = initialState, action) => {
  switch (action.type) {
    case types.farmerTypes.FARMER_UNACCOUNTBAGS:
      return action.payload || [];
    default:
      return state;
  }
};
