import React, { useEffect, useState } from "react";
import PageLayout from "../../Component/PageLayout";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useLocation } from "react-router-dom";

const ViewFarmerKits = () => {
  const location = useLocation();
  const [kitDetails, setKitDetails] = useState([
    {
      vendor: "",
      product: "",
      invoiceNo: "",
      invoiceDate: "",
      amount: "",
      remark: "",
      invoiceDoc: "",
    },
  ]);

  useEffect(() => {
    if (location?.state?.kitDetails) {
      let arr = [];
      // eslint-disable-next-line
      location?.state?.kitDetails?.map((er) => {
        arr.push({
          kitDetailsId: er?.id,
          vendor: er?.vendor?.vendorName,
          product: er?.product?.productName,
          invoiceNo: er?.invoiceNo,
          invoiceDate: er?.invoiceDate,
          amount: er?.amount,
          remark: er?.remark,
          invoiceDoc: er?.invoiceDoc,
        });
      });
      setKitDetails(arr);
    }
  }, [location?.state?.kitDetails]);

  // Accordian Toggle For Multiple land Registration
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <PageLayout
      title={"View Purchased Kits"}
      backBtnTitle={"Back"}
      backBtnTitleLink={"/kitissue"}
    >
      {kitDetails?.map((item, index) => (
        <Accordion
          key={index}
          open={open}
          className={index === 0 ? "mt-4" : ""}
          toggle={toggle}
        >
          <fieldset
            className="cus-add-req-sur border-none m-0 p-2"
            style={{ border: "none" }}
          >
            <AccordionItem>
              <AccordionHeader targetId={`${index + 1}`}>
                Kit No : {index + 1}
              </AccordionHeader>
              <AccordionBody accordionId={`${index + 1}`}>
                {/* State, Dist and Taluka  */}
                <div>
                  <FormGroup row>
                    <Row className="">
                      <Col lg={3} md={6} sm={12} className="d-flex ">
                        <Col md={4}>
                          <Label
                            style={{ fontWeight: 500 }}
                            for="exampleEmail"
                            md={12}
                          >
                            Vendor
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <Input
                            id="exampleEmail"
                            value={item?.vendor}
                            name="guardianPincode"
                            disabled
                            className="cust-input"
                            type="text"
                          />
                        </Col>
                      </Col>
                      <Col
                        lg={3}
                        md={6}
                        sm={12}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <Col md={3}>
                          <Label
                            style={{ fontWeight: 500 }}
                            for="exampleEmail"
                            md={12}
                          >
                            Product
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <Input
                            id="exampleEmail"
                            value={item?.product}
                            name="guardianPincode"
                            disabled
                            className="cust-input"
                            type="text"
                          />
                        </Col>
                      </Col>
                    </Row>
                  </FormGroup>
                </div>

                {/* village,surveyNo and totalLand  */}
                <div>
                  <FormGroup row>
                    <Row className="">
                      <Col
                        lg={3}
                        md={6}
                        sm={12}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <Col md={4}>
                          <Label
                            style={{ fontWeight: 500 }}
                            for="exampleEmail"
                            md={12}
                          >
                            Invice No.
                          </Label>
                        </Col>
                        <Col md={8} className="d-flex mt-2">
                          <Input
                            id="exampleEmail"
                            value={item?.invoiceNo}
                            name="invoiceNo"
                            disabled
                            className="cust-input"
                            type="text"
                          />
                        </Col>
                      </Col>
                      <Col
                        lg={3}
                        md={6}
                        sm={12}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <Col md={4}>
                          <Label
                            style={{ fontWeight: 500 }}
                            for="exampleEmail"
                            md={12}
                          >
                            Invoice Date
                          </Label>
                        </Col>
                        <Col md={8} className="d-flex mt-2">
                          <Input
                            id="exampleEmail"
                            value={item?.invoiceDate}
                            name="invoiceDate"
                            disabled
                            className="cust-input"
                            type="text"
                          />
                        </Col>
                      </Col>
                    </Row>
                  </FormGroup>
                </div>
                <div>
                  <FormGroup row>
                    <Row className="">
                      <Col
                        lg={3}
                        md={6}
                        sm={12}
                        className="d-flex justify-content-center align-items-center "
                      >
                        <Col md={4}>
                          <Label
                            style={{ fontWeight: 500 }}
                            for="exampleEmail"
                            md={12}
                          >
                            Amount
                          </Label>
                        </Col>
                        <Col md={8} className="d-flex ">
                          <Input
                            id="exampleEmail"
                            value={item?.amount}
                            name="amount"
                            disabled
                            className="cust-input"
                            type="text"
                          />
                        </Col>
                      </Col>
                      <Col
                        lg={3}
                        md={6}
                        sm={12}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <Col md={4}>
                          <Label
                            style={{ fontWeight: 500 }}
                            for="exampleEmail"
                            md={12}
                          >
                            Remark
                          </Label>
                        </Col>
                        <Col md={8}>
                          <Input
                            id="exampleEmail"
                            value={item?.remark}
                            name="remark"
                            disabled
                            className="cust-input"
                            type="text"
                          />
                        </Col>
                      </Col>
                    </Row>
                  </FormGroup>
                </div>
              </AccordionBody>
            </AccordionItem>
          </fieldset>
        </Accordion>
      ))}
    </PageLayout>
  );
};

export default ViewFarmerKits;
