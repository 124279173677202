/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import {
  bagissueActions,
  commonActions,
  farmerpurchaseActions,
  productActions,
} from "../../redux/actions";
import { useEffect, useMemo, useRef, useState } from "react";
import dayjs from "dayjs";
import { keys } from "../../config/keys";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { farmerPurchaseValidationSchema } from "./validationSchema";
import {
  getYieldEstimtedFarmerDropdownList,
  get_farmer_by_booking,
} from "../../redux/actions/farmer";
import { get_bag_issued_to_farmer_summary } from "../../redux/actions/bagissue";
import { GridAction } from "../../common/useGridAction";
import { toast } from "react-toastify";
import { usePermissionHooks } from "../../common/useCheckPermission";
import { useReactToPrint } from "react-to-print";
import { Button, IconButton } from "@mui/material";
import { Delete, Print } from "@mui/icons-material";
import { get_farmer_purchase_by_id } from "../../redux/actions/farmerpurchase";
import socket from "../../common/socketClient";
// import axios from "axios";
// import xml2js from "xml2js";
// import { FarmerPurchaseList } from "..";

export const useFarmerPurchaseListHooks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const permission = usePermissionHooks(location);

  const { farmer_purchase_list } = useSelector((obj) => obj);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [printData, setPrintData] = useState(null);
  const [showprint, setShowPrint] = useState(false);
  const [printType, setPrintType] = useState({ gatepass: true });
  const column = useMemo(
    () => [
      {
        Header: "Purchase Date",
        accessor: "purchasedate",
        Cell: (props) => keys().shortDate(props.value),
      },
      {
        Header: "Purchase #",
        accessor: "purchaseno",
      },
      {
        Header: "Farmer",
        accessor: "name",
      },
      {
        Header: "Vehicle #",
        accessor: "vehicleno",
      },
      {
        Header: "Gross",
        accessor: "grossweight",
      },
      {
        Header: "Purchase From",
        accessor: "purchasedfrom",
      },
      {
        id: "gatepass",
        Header: "Entry Gate Pass",
        accessor: "gatepassgenerated",
        Cell: (cellProps) => {
          return (
            <IconButton
              size={"small"}
              color="info"
              disabled={!cellProps.row.original.gatepassgenerated}
              onClick={() =>
                cellProps.row.original.gatepassgenerated
                  ? onPrintClick(cellProps, true)
                  : null
              }
            >
              <Print fontSize={"small"} />
            </IconButton>
          );
          //_purchase_by_id(formData?.id_voucherno);
        },
      },
      {
        id: "printbill",
        Header: "Tractor Receipt",
        accessor: "billgenerated",
        Cell: (cellProps) => {
          return (
            <IconButton
              size={"small"}
              color="info"
              // disabled={!cellProps.row.original.billgenerated}
              disabled={!cellProps.row.original.IsTractorReceiptGenerated}
              onClick={() =>
                cellProps.row.original.billgenerated
                  ? onPrintClick(cellProps, false)
                  : null
              }
            >
              <Print fontSize={"small"} />
            </IconButton>
          );
          //_purchase_by_id(formData?.id_voucherno);
        },
      },
      {
        ...GridAction({
          permission,
          onEditClick: (cellProps) => {
            navigate("/farmerpurchases/add", { state: cellProps.row.original });
          },
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original.id);
          },
        }),
      },
    ],
    // eslint-disable-next-line
    []
  );

  const gate_pass_column = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "purchasedate",
        Cell: (props) => keys().shortDate(props.value),
      },
      {
        Header: "Voucher #",
        accessor: "purchaseno",
      },
      {
        Header: "Farmer",
        accessor: "name",
      },
      {
        Header: "Vehicle #",
        accessor: "vehicleno",
      },
      {
        Header: "Gross",
        accessor: "grossweight",
      },
      // {
      //   Header: "Purchase From",
      //   accessor: "purchasedfrom",
      // },
      {
        id: "gatepass",
        Header: "Entry Gate Pass",
        accessor: "gatepassgenerated",
        Cell: (cellProps) => {
          return (
            <IconButton
              size={"small"}
              color="info"
              disabled={!cellProps.row.original.gatepassgenerated}
              onClick={() =>
                cellProps.row.original.gatepassgenerated
                  ? onPrintClick(cellProps, true)
                  : null
              }
            >
              <Print fontSize={"small"} />
            </IconButton>
          );
          //_purchase_by_id(formData?.id_voucherno);
        },
      },
      {
        id: "printbill",
        Header: "Tractor Receipt",
        accessor: "billgenerated",
        Cell: (cellProps) => {
          return (
            <IconButton
              size={"small"}
              color="info"
              // disabled={!cellProps.row.original.billgenerated}
              disabled={!cellProps.row.original.IsTractorReceiptGenerated}
              onClick={() =>
                cellProps.row.original.billgenerated
                  ? onPrintClick(cellProps, false)
                  : null
              }
            >
              <Print fontSize={"small"} />
            </IconButton>
          );
          //_purchase_by_id(formData?.id_voucherno);
        },
      },
      {
        id: "action",
        Header: "",
        Cell: (cellProps) => {
          return (
            (permission?.delete === true || permission?.delete === 1) &&
            !cellProps.row.original.intransit &&
            !cellProps.row.original.billgenerated && (
              <IconButton
                size={"small"}
                color="error"
                onClick={() => {
                  setDeleteModal(true);
                  setDeleteId(cellProps.row.original.id);
                }}
              >
                <Delete fontSize={"small"} />
              </IconButton>
            )
          );
        },

        //_purchase_by_id(formData?.id_voucherno);
      },
      // {
      //   ...GridAction({
      //     permission,
      //     onEditClick: (cellProps) => {
      //       navigate("/farmerpurchases/add", { state: cellProps.row.original });
      //     },
      //     onDeleteClick: (cellProps) => {
      //       setDeleteModal(true);
      //       setDeleteId(cellProps.row.original.id);
      //     },
      //   }),
      // },
    ],
    // eslint-disable-next-line
    []
  );
  const onPrintClick = (cellProps, gatepass) => {
    dispatch(
      get_farmer_purchase_by_id(cellProps.row.original.purchaseno, {
        onSuccess: (res) => {
          setLoading(false);
          if (res?.data?.rows[1].length > 0) {
            const data = res?.data?.rows[1][0];
            const printData = {
              ...data,
              purchasedate: dayjs(data.purchasedate),
              purchasedetails: res?.data?.rows[2],
            };
            setPrintType({ gatepass });
            setPrintData(printData);
            setShowPrint(true);
            //navigate("/print", { state: { data: printData } });
            // setFormData((prev) => ({
            //   ...prev,
            //   ...data,
            //   purchasedate: dayjs(data.purchasedate),
            //   purchasedetails: res?.data?.rows[2],
            // }));
          }
        },
        onError: () => setLoading(false),
      })
    );
  };

  const onGridDelete = async () => {
    setDeleteLoading(true);
    if (deleteId > 0) {
      dispatch(
        farmerpurchaseActions.delete_farmer_purchase(
          { id: deleteId },
          {
            onSuccess: () => {
              toast.success("Purchasee Deleted Successfully!!!");
              _farmer_purchase_list();
            },
            onError: (err) =>
              toast.error("Something went wrong. Please try again."),
          }
        )
      );
      setDeleteLoading(false);
      setDeleteModal(false);
    }
  };

  const onGridGatePassDelete = async () => {
    setDeleteLoading(true);
    if (deleteId > 0) {
      dispatch(
        farmerpurchaseActions.delete_gate_pass(
          { id: deleteId },
          {
            onSuccess: () => {
              toast.success("Gatepass Deleted Successfully!!!");
              _farmer_purchase_list();
            },
            onError: (err) =>
              toast.error("Something went wrong. Please try again."),
          }
        )
      );
      setDeleteLoading(false);
      setDeleteModal(false);
    }
  };

  const _farmer_purchase_list = () =>
    dispatch(
      farmerpurchaseActions.get_farmer_purchase_list({
        onSuccess: () => setLoading(false),
        onError: () => setLoading(false),
      })
    );

  useEffect(() => {
    setLoading(!loading);
    _farmer_purchase_list();
  }, []);

  const exportTally = () => {
    let obj = {
      farmer_purchase_list: farmer_purchase_list,
    };
    dispatch(
      farmerpurchaseActions.import_tally_farmer_purchase(obj, {
        onSuccess: (res) => toast.success(res),
        onError: (e) => toast.error(e),
      })
    );
  };

  return {
    farmer_purchase_list,
    loading,
    deleteModal,
    onGridDelete,
    deleteLoading,
    setDeleteModal,
    column,
    permission,
    showprint,
    printData,
    setShowPrint,
    printType,
    exportTally,
    onGridGatePassDelete,
    gate_pass_column,
  };
};

const initialValues = {
  purchasedatestring: dayjs(Date()).format(keys().dbDateFormat),
  purchasedate: dayjs(Date()),
  voucherno: "",
  lastvoucherno: "",
  referencevoucherno: "",
  selectedFarmer: null,
  farmerid: 0,
  name: "",
  vehicleno: "",
  vehicleweight: 0,
  purchasedfrom: "",
  kasor: 0,
  bagdetails: {
    farmerid: 0,
    bagnos: "",
    farmername: "",
    noofbagsissued: 0,
    bagsused: 0,
  },
  selectedproducttype: null,
  producttypeid: 0,
  productid: 0,
  weight: 0,
  noofbags: 0,
  bagweight: 0,
  addkasor: 0,
  minuskasor: 0,
  incentive: 0,
  netweight: 0,
  purchasedetails: [],
  bookingtype: 1,
  bookingid: 0,
  farmerCode: "",
  farmer: "",
  multiplefarmer: false,
};

const initialErrorValues = {
  productweight: {
    value: false,
    message: "Product weight should not be greater than allowed limit",
  },
  noofbags: {
    value: false,
    message: "",
  },
};

export const useFarmerPurchaseHooks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const gatepassComponentRef = useRef();
  const {
    farmerDropdownList,
    bag_issue_list_farmer,
    previous_purchases_by_farmer,
  } = useSelector((obj) => obj);
  const [loading, setLoading] = useState(false);
  const [farmerYieldData, setFarmerYieldEstimationData] = useState([]);
  const [bags_in_stock, setAvailableBags] = useState([]);
  const [total_bags_stock, settotalbagsStock] = useState([
    { stock: 0, capacity: 0 },
  ]);
  const [formData, setFormData] = useState(initialValues);
  const [error, setError] = useState(initialErrorValues);
  const [totalEstimatedYield, setTotalEstimatedYield] = useState(0);
  const [estimatedBagsToIssue, setEstimatedBagsToIssue] = useState(0);
  const [multifarmerdata, setmultifarmerdata] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(farmerPurchaseValidationSchema),
  });

  useEffect(() => {
    setLoading(!loading);
    get_latest_voucher();
    farmer_dropdown_list();
  }, []);

  useEffect(() => {
    if (location.state !== null) {
      const data = location.state;
      setFormData((prev) => ({
        ...prev,
        farmerid: data.farmerid,
        selectedFarmer: farmerDropdownList.find((x) => x.id === data.farmerid),
      }));

      get_farmer_yield_estimation(data.farmerid);
      bag_issused_to_farmer_summary(data.farmerid);
    }
  }, [location.state]);

  useEffect(() => {
    if (bag_issue_list_farmer.length > 0) {
      setFormData((prev) => ({
        ...prev,
        bagdetails: bag_issue_list_farmer[0],
      }));
    }
  }, [bag_issue_list_farmer]);

  useEffect(() => {
    farmer_dropdown_list();
  }, [formData.bookingtype]);

  socket.on("gateweight", (data) => {
    setFormData((prev) => ({ ...prev, vehicleweight: data }));
  });

  // useEffect(() => {
  //   if (formData.farmerid > 0 && formData.productid > 0) {
  //     const _data = total_bags_stock[0];
  //     const bag_capacity = _data?.capacity || 0;
  //     setEstimatedBagsToIssue(
  //       Math.ceil(totalEstimatedYield / bag_capacity) || 0
  //     );
  //   }
  // }, [
  //   totalEstimatedYield,
  //   total_bags_stock,
  //   formData.farmerid,
  //   formData.productid,
  // ]);

  const column = useMemo(
    () => [
      {
        Header: "Product",
        accessor: "productname",
        Footer: "TOTAL",
      },
      {
        Header: "Type",
        accessor: "yield",
      },
      {
        Header: "Minimum",
        accessor: "minimum",
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = useMemo(
            () => info.rows.reduce((sum, row) => row.values.minimum + sum, 0),
            [info.rows]
          );

          return Number(total).toFixed(3);
        },
      },
      {
        Header: "Actual",
        accessor: "actual",
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = useMemo(
            () => info.rows.reduce((sum, row) => row.values.actual + sum, 0),
            [info.rows]
          );
          return Number(total).toFixed(3);
        },
      },
      {
        Header: "Maximum",
        accessor: "maximum",
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = useMemo(
            () => info.rows.reduce((sum, row) => row.values.maximum + sum, 0),
            [info.rows]
          );
          return Number(total).toFixed(3);
        },
      },
      {
        Header: "Previous Purchases",
        accessor: "previouspurchase",
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => row.values.previouspurchase + sum,
                0
              ),
            [info.rows]
          );
          return Number(total).toFixed(3);
        },
      },

      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  const purchasedetailscolumn = useMemo(
    () => [
      {
        Header: "Type",
        accessor: "producttype",
      },

      {
        Header: "Weight",
        accessor: "weight",
      },
      {
        Header: "No of bags",
        accessor: "noofbags",
      },
      {
        Header: "Kasor",
        accessor: "kasor",
      },
      {
        Header: "Incentive",
        accessor: "incentive",
      },
      {
        Header: "Net Weight",
        accessor: "netweight",
      },

      {
        ...GridAction({
          permission: { edit: false, delete: true, print: false },
          onDeleteClick: (cellProps) => {
            deleteRecord(cellProps);
          },
        }),
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  const deleteRecord = (pCellProps) => {
    const t = Array.from(pCellProps.data);
    const tmpData = t.filter((x) => Number(x.id) !== Number(pCellProps.row.id));
    setFormData((prev) => ({ ...prev, purchasedetails: tmpData }));
  };

  const bags_issued_to_farmer_columns = useMemo(
    () => [
      {
        Header: "Issue Date",
        accessor: "issuedatetime",
        Cell: ({ value }) => keys().shortDate(value),
      },
      {
        Header: "Voucher #",
        accessor: "voucherno",
      },
      {
        Header: "Bag Nos.",
        accessor: "bagnos",
      },
    ],
    []
  );

  const previous_purchase_columns = useMemo(
    () => [
      {
        Header: "Voucher #",
        accessor: "voucherno",
      },

      {
        Header: "Purchase Date",
        accessor: "purchasedate",
        Cell: ({ value }) => keys().shortDate(value),
      },
      {
        Header: "Vehicle No",
        accessor: "vehicleno",
      },
      {
        Header: "Bhuko",
        accessor: "bhuko",
      },
      {
        Header: "Dakru",
        accessor: "dakru",
      },
      {
        Header: "Gariyo",
        accessor: "gariyo",
      },
      {
        Header: "Bags Returned",
        accessor: "bagsused",
      },
    ],
    // eslint-disable-next-line
    []
  );

  const _get_product_list = () =>
    dispatch(
      productActions.get_product_list({
        onSuccess: (res) => {
          setLoading(false);
        },
      })
    );

  const _get_previous_purchase_from_farmers = (farmerid) =>
    dispatch(
      farmerpurchaseActions.get_previous_purchases_from_farmer(farmerid)
    );

  const get_latest_voucher = () =>
    dispatch(
      commonActions.get_last_latest_voucherno("purchase_master", {
        onSuccess: (res) => {
          if (res.length > 0) {
            setFormData((prev) => ({
              ...prev,
              ...res[0],
              referencevoucherno: res[0].voucherno,
            }));
          }
        },
      })
    );

  const bag_issused_to_farmer_summary = (farmerid) =>
    dispatch(
      bagissueActions.get_bag_issued_to_farmer_summary(farmerid, {
        onSuccess: () => setLoading(false),
        onError: () => setLoading(false),
      })
    );

  const farmer_dropdown_list = () =>
    dispatch(
      get_farmer_by_booking(formData.bookingtype, {
        onSuccess: () => setLoading(false),
        onError: () => setLoading(false),
      })
    );

  const get_farmer_yield_estimation = (farmerId) =>
    dispatch(
      farmerpurchaseActions.get_farmers_yield_estimation(farmerId, {
        onSuccess: (res) => {
          setFarmerYieldEstimationData([...res]);
          if (res.length > 0) {
            setFormData((prev) => ({
              ...prev,
              productid: res[0].productid,
            }));
          }
        },
      })
    );

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleChangeUpperCase = (e) => {
    let val = String(e.target.value).toUpperCase();
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: val,
    }));
  };
  const handleCheckChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const onFarmerChange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      farmerid: selectedOption?.id || 0,
      selectedFarmer: selectedOption || null,
    }));

    if (selectedOption !== null) {
      get_farmer_yield_estimation(selectedOption.id);
      bag_issused_to_farmer_summary(selectedOption.id);
      _get_previous_purchase_from_farmers(selectedOption.id);
    } else setFarmerYieldEstimationData([]);
  };

  const onCancelClick = (e) => {
    setFormData(initialValues);
    setError(initialErrorValues);
  };
  const onProductTypeChange = (e, selectedOption) => {
    console.log(selectedOption);
    setFormData((prev) => ({
      ...prev,
      producttypeid: selectedOption?.value || 0,
      selectedproducttype: selectedOption || null,
    }));
  };

  const validatePurchaseEntry = () => {
    const wt = formData.weight;
    let value = false;
    let message = "";

    const cap = farmerYieldData.find(
      (x) => x.producttype === formData.producttypeid
    );

    if (wt > cap.maximum - cap.previouspurchase) {
      value = true;
      message = `Product Weight cannot be more than allowed limit (${
        cap.maximum - cap.previouspurchase
      })`;
    }
    setError((prev) => ({ ...prev, productweight: { value, message } }));
  };

  const handleBlur = (e) => {
    const wt = formData.weight;
    const capacity =
      bag_issue_list_farmer.length > 0 ? bag_issue_list_farmer[0].capacity : 0;
    const estimatedBags = Math.ceil(wt / capacity);
    setFormData((prev) => ({
      ...prev,
      noofbags: estimatedBags,
      netweight: formData.weight,
    }));
    validatePurchaseEntry();
  };

  useEffect(() => {
    let value = false,
      message = "";
    const tmpbagsused = formData.purchasedetails.reduce(
      (sum, row) => Number(row.noofbags) + sum,
      0
    );

    if (
      formData.noofbags >
      formData.bagdetails.noofbagsissued -
        formData.bagdetails.bagsused -
        tmpbagsused
    ) {
      value = true;
      message = "Bags cannot be greater than issued bags";
    }
    setError((prev) => ({
      ...prev,
      noofbags: {
        value,
        message,
      },
    }));
  }, [formData.noofbags]);

  const onAddClick = (e) => {
    console.log(formData);
    const data = {
      id: Math.floor(Math.random() * 100 + 1),
      purchaseid: 0,
      vehicleweight: formData.vehicleweight,
      productid: formData.productid,
      producttypeid: formData.producttypeid,
      producttype: formData.selectedproducttype.label,
      weight: formData.weight,
      noofbags: formData.noofbags,
      bagweight: formData.bagweight,
      kasor: formData.kasor,
      addkasor: formData.kasor > 0 ? formData.kasor : 0,
      minuskasor: formData.kasor < 0 ? formData.kasor : 0,
      incentive: formData.incentive,
      netweight: formData.netweight,
    };
    const t = Array.from(formData.purchasedetails);
    t.push(data);
    setFormData((prev) => ({
      ...prev,
      purchasedetails: t,
    }));
  };

  // useEffect(() => {
  //   console.log(formData.purchasedetails);
  // }, [formData.purchasedetails]);

  const onDateChange = (dateValue) => {
    setFormData((prev) => ({
      ...prev,
      purchasedate: dateValue,
      purchasedatestring: dayjs(dateValue).format("YYYY-MM-DD"),
    }));
  };

  const onSubmit = () => {
    const {
      purchasedate,
      lastvoucherno,
      selectedFarmer,
      purchasedfrom,
      kasor,
      bagdetails,
      selectedproducttype,
      producttypeid,
      productid,
      weight,
      noofbags,
      bagweight,
      addkasor,
      minuskasor,
      incentive,
      netweight,
      ...others
    } = formData;
    let _data = { ...others, bookingid: selectedFarmer?.bookingid || 0 };

    if (formData.purchasedetails.length > 0) {
      const temp = {
        ...formData.purchasedetails[0],
        producttypeid: 4,
        producttype: "Empty Vehicle",
      };
      _data.purchasedetails.push(temp);
    }

    if (formData.purchasedetails.length > 0) {
      if (multifarmerdata.length > 0) {
        dispatch(
          farmerpurchaseActions.save_farmer_purchase_multi(multifarmerdata, {
            onSuccess: (res) => {
              // navigate("/farmerpurchases");
              setFormData(initialValues);
              get_latest_voucher();
            },
          })
        );
      } else {
        dispatch(
          farmerpurchaseActions.save_farmer_purchase(_data, {
            onSuccess: (res) => {
              // navigate("/farmerpurchases");
              setFormData(initialValues);
              get_latest_voucher();
            },
          })
        );
      }
    } else {
      toast.error("Please add purchase details.");
    }    
  };

  const onAddFarmer = () => {
    const {
      purchasedate,
      lastvoucherno,
      selectedFarmer,
      purchasedfrom,
      kasor,
      bagdetails,
      selectedproducttype,
      producttypeid,
      productid,
      weight,
      noofbags,
      bagweight,
      addkasor,
      minuskasor,
      incentive,
      netweight,
      ...others
    } = formData;
    let _data = {
      ...others,
      bookingid: selectedFarmer?.bookingid || 0,
      selectedFarmer,
    };

    if (formData.purchasedetails.length > 0) {
      const temp = {
        ...formData.purchasedetails[0],
        producttypeid: 4,
        producttype: "Empty Vehicle",
      };
      _data.purchasedetails.push(temp);
    }

    setmultifarmerdata([...multifarmerdata, _data]);

    // setFormData(initialValues);
    setFormData((prev) => ({
      ...prev,
      selectedFarmer: null,
      purchasedetails: [],
      voucherno: String(Number(prev.voucherno) + 1).padStart(5, "0"),
    }));
    setFarmerYieldEstimationData([]);
  };

  // useEffect(() => {
  //   console.log(multifarmerdata);
  // }, [multifarmerdata]);
  const onSubmitandPrint = () => {
    if (formData.purchasedetails.length > 0) {
      onSubmit();
      onPrintGatePass();
    } else {
      toast.error("Please add purchase details.");
    }
  };

  const onPrintGatePass = useReactToPrint({
    content: () => gatepassComponentRef.current,
  });

  return {
    register,
    error,
    errors,
    column,
    onSubmit,
    formData,
    handleBlur,
    onAddClick,
    handleSubmit,
    handleChange,
    handleCheckChange,
    onDateChange,
    onCancelClick,
    bags_in_stock,
    onFarmerChange,
    farmerYieldData,
    total_bags_stock,
    farmerDropdownList,
    onProductTypeChange,
    estimatedBagsToIssue,
    purchasedetailscolumn,
    bag_issue_list_farmer,
    previous_purchase_columns,
    previous_purchases_by_farmer,
    bags_issued_to_farmer_columns,
    gatepassComponentRef,
    onPrintGatePass,
    onSubmitandPrint,
    onAddFarmer,
    multifarmerdata,
    producttype: keys().producttype,
    handleChangeUpperCase,
  };
};
