import * as yup from "yup";

export const waterWasteManagementValidationSchema = yup.object({
  mainbore: yup
    .number()
    .required("Main bore reading is required")
    .typeError("Main bore reading is required"),
  sftnrtankin: yup
    .number()
    .required("SFTNR Tank IN reading is required")
    .typeError("SFTNR Tank IN reading is required"),
  sftnrtankout: yup
    .number()
    .required("SFTNR OUT reading is required")
    .typeError("SFTNR OUT reading is required"),
  security: yup
    .number()
    .required("Security reading is required")
    .typeError("Security reading is required"),
  canteen: yup
    .number()
    .required("Canteen reading is required")
    .typeError("Canteen reading is required"),
  stpwash: yup
    .number()
    .required("STP Wash reading is required")
    .typeError("STP Wash reading is required"),
  chrtoi: yup
    .number()
    .required("CHR TOI reading is required")
    .typeError("CHR TOI reading is required"),
  chrsh: yup
    .number()
    .required("CHR SH reading is required")
    .typeError("CHR SH reading is required"),
  sftnrwash: yup
    .number()
    .required("SFTNR Wash reading is required")
    .typeError("SFTNR Wash reading is required"),
  stpin: yup
    .number()
    .required("STP IN reading is required")
    .typeError("STP IN reading is required"),
  stpout: yup
    .number()
    .required("STOP OUT reading is required")
    .typeError("STOP OUT reading is required"),
  totalizerreading: yup
    .number()
    .required("Totalizer reading is required")
    .typeError("Totalizer reading is required"),
});
