import React, { useCallback, useEffect, useRef, useState } from "react";
import PageLayout from "../../Component/PageLayout";
import {
  Col,
  Input,
  Label,
  Row,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import StateOption from "../../hooks/StateOptions";
import CountryOption from "../../hooks/CountryOption";
import DistrictOption from "../../hooks/DistrictOption";
import TalukaOption from "../../hooks/TalukaOption";
import VillageOption from "../../hooks/VillageOption";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import { currencyArray } from "../../hooks/StaticData";
import useBreakPoints from "../../hooks/useBreakPoints";

// regex
// const gstRegex =
// /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[0-9A-Za-z]{1}[A-Za-z]{1}$/;
const panRegex = /^([A-Z]{5}[0-9]{4}[A-Z]{1})$/;
const cinRegex = /^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/;
const tanRegex = /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/;
// const msmeRegex = /^(UAM|UAN)\d{9}$/;
const iecRegex = /^[A-Za-z]{5}\d{5}$/;

const AddCompany = () => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.layout);
  const { isMobile, isTablet } = useBreakPoints();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [countryArray, setCountryArray] = useState([]);
  const [stateArray, setStateArray] = useState([]);
  const [districtArray, setDistrictArray] = useState([]);
  const [talukaArray, setTalukaArray] = useState([]);
  const [villageArray, setVillageArray] = useState([]);

  const [step, setStep] = useState("step1");
  const [financialYearOption, setFinancialYearOption] = useState([]);
  const [inputData, setInputData] = useState({
    companyName: "",
    companyLogo: "",
    aliasName: "",
    companyCode: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    country: "",
    state: "",
    city: "",
    dist: "",
    taluka: "",
    village: "",
    pincode: "",
    mobileNo: "",
    phoneNo: "",
    faxNo: "",
    website: "",
    currency: currencyArray?.find((er) => er?.value === "inr"),
    financialYear: "",
    gstNo: "",
    panNo: "",
    cinNo: "",
    TanNo: "",
    msmeNo: "",
    iecCode: "",
    gstDocument: "",
    cinDocument: "",
    msmeDocument: "",
    panDocument: "",
    tanDocument: "",
    iecDocument: "",
    email: "",
  });
  const [inputDataError, setInputDataError] = useState({
    companyName: false,
    companyLogo: false,
    companyCode: false,
    addressLine1: false,
    addressLine2: false,
    addressLine3: false,
    country: false,
    state: false,
    city: false,
    dist: false,
    taluka: false,
    village: false,
    pincode: false,
    mobileNo: false,
    faxNo: false,
    currency: false,
    financialYear: false,
    gstNo: false,
    panNo: false,
    cinNo: false,
    TanNo: false,
    msmeNo: false,
    iecCode: false,
    gstDocument: false,
    cinDocument: false,
    msmeDocument: false,
    panDocument: false,
    tanDocument: false,
    iecDocument: false,
    email: false,
  });

  const [unitDetails, setUnitDetails] = useState([]);
  const [unitDetailsError, setUnitDetailsError] = useState([]);
  function isValidEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  const { getStateOption } = StateOption();
  const { getCountryOption } = CountryOption();
  const { getDistrictOption } = DistrictOption();
  const { getTalukaOption } = TalukaOption();
  const { getVillageOption } = VillageOption();

  useEffect(() => {
    getCountryOption().then((res) => {
      setCountryArray(res);
    });
    // eslint-disable-next-line
  }, []);

  // Accordian Toggle For Multiple Unit
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  // handleChange Unit Input
  const handleChangeUnitInput = (index, name, value) => {
    if (name === "country") {
      getStateOption(value.value)
        .then((res) => {
          const updatedUnitDetails = [...unitDetails];
          updatedUnitDetails[index][name] = value;
          updatedUnitDetails[index]["state"] = {};
          updatedUnitDetails[index]["dist"] = {};
          updatedUnitDetails[index]["taluka"] = {};
          updatedUnitDetails[index]["village"] = {};
          updatedUnitDetails[index] = {
            ...updatedUnitDetails[index],
            stateArray: res,
          };

          setUnitDetails(updatedUnitDetails);
          let arr5 = [...unitDetailsError];

          if (value?.label?.length > 0) {
            arr5[index]["country"] = false;
          } else {
            arr5[index]["country"] = true;
          }
          setUnitDetailsError(arr5);
        })
        .catch((error) => {
          console.error("Error fetching state options:", error);
        });
    } else if (name === "state") {
      getDistrictOption(value.value)
        .then((res) => {
          const updatedUnitDetails = [...unitDetails];
          updatedUnitDetails[index][name] = value;
          updatedUnitDetails[index]["dist"] = {};
          updatedUnitDetails[index]["taluka"] = {};
          updatedUnitDetails[index]["village"] = {};
          updatedUnitDetails[index] = {
            ...updatedUnitDetails[index],
            districtArray: res,
          };

          setUnitDetails(updatedUnitDetails);
          let arr5 = [...unitDetailsError];

          if (value?.label?.length > 0) {
            arr5[index]["state"] = false;
          } else {
            arr5[index]["state"] = true;
          }
          setUnitDetailsError(arr5);
        })
        .catch((error) => {
          console.error("Error fetching state options:", error);
        });
    } else if (name === "dist") {
      getTalukaOption(value.value)
        .then((res) => {
          const updatedUnitDetails = [...unitDetails];
          updatedUnitDetails[index][name] = value;
          updatedUnitDetails[index]["taluka"] = {};
          updatedUnitDetails[index]["village"] = {};
          updatedUnitDetails[index] = {
            ...updatedUnitDetails[index],
            talukaArray: res,
          };

          setUnitDetails(updatedUnitDetails);
          let arr5 = [...unitDetailsError];

          if (value?.label?.length > 0) {
            arr5[index]["dist"] = false;
          } else {
            arr5[index]["dist"] = true;
          }
          setUnitDetailsError(arr5);
        })
        .catch((error) => {
          console.error("Error fetching state options:", error);
        });
    } else if (name === "taluka") {
      getVillageOption(value.value)
        .then((res) => {
          const updatedUnitDetails = [...unitDetails];
          updatedUnitDetails[index][name] = value;

          updatedUnitDetails[index]["village"] = {};
          updatedUnitDetails[index] = {
            ...updatedUnitDetails[index],
            villageArray: res,
          };

          setUnitDetails(updatedUnitDetails);
          let arr5 = [...unitDetailsError];

          if (value?.label?.length > 0) {
            arr5[index]["taluka"] = false;
          } else {
            arr5[index]["taluka"] = true;
          }
          setUnitDetailsError(arr5);
        })
        .catch((error) => {
          console.error("Error fetching state options:", error);
        });
    } else if (name === "village") {
      let arr = [...unitDetails];

      arr[index][name] = value;

      setUnitDetails(arr);
      let arr5 = [...unitDetailsError];

      if (value?.label?.length > 0) {
        arr5[index]["village"] = false;
      } else {
        arr5[index]["village"] = true;
      }
    } else if (name === "unitName") {
      let arr = [...unitDetails];

      arr[index][name] = value;

      setUnitDetails(arr);
      let arr5 = [...unitDetailsError];

      if (value?.length >= 3) {
        arr5[index]["unitName"] = false;
      } else {
        arr5[index]["unitName"] = true;
      }
    } else if (name === "aliasName") {
      let arr = [...unitDetails];

      arr[index][name] = value;

      setUnitDetails(arr);
      let arr5 = [...unitDetailsError];

      if (value?.length >= 3) {
        arr5[index]["aliasName"] = false;
      } else {
        arr5[index]["aliasName"] = true;
      }
    } else if (name === "addressLine1") {
      let arr = [...unitDetails];

      arr[index][name] = value;

      setUnitDetails(arr);
      let arr5 = [...unitDetailsError];

      if (value?.length >= 3) {
        arr5[index]["addressLine1"] = false;
      } else {
        arr5[index]["addressLine1"] = true;
      }
    } else if (name === "addressLine2") {
      let arr = [...unitDetails];

      arr[index][name] = value;
      let arr5 = [...unitDetailsError];

      setUnitDetails(arr);

      if (value?.length >= 3) {
        arr5[index]["addressLine2"] = false;
      } else {
        arr5[index]["addressLine2"] = true;
      }
    } else if (name === "addressLine3") {
      let arr = [...unitDetails];

      arr[index][name] = value;
      let arr5 = [...unitDetailsError];

      setUnitDetails(arr);

      if (value?.length >= 3) {
        arr5[index]["addressLine3"] = false;
      } else {
        arr5[index]["addressLine3"] = true;
      }
    } else if (name === "pincode") {
      let arr = [...unitDetails];

      arr[index][name] = value;
      let arr5 = [...unitDetailsError];

      setUnitDetails(arr);

      if (value?.length === 6) {
        arr5[index]["pincode"] = false;
      } else {
        arr5[index]["pincode"] = true;
      }
    } else if (name === "mobileNo") {
      let arr = [...unitDetails];

      arr[index][name] = value;
      let arr5 = [...unitDetailsError];

      setUnitDetails(arr);

      if (value?.length === 10) {
        arr5[index]["mobileNo"] = false;
      } else {
        arr5[index]["mobileNo"] = true;
      }
    } else if (name === "email") {
      let arr = [...unitDetails];

      arr[index][name] = value;
      let arr5 = [...unitDetailsError];

      setUnitDetails(arr);

      if (value?.length >= 3 && isValidEmail(value)) {
        arr5[index]["email"] = false;
      } else {
        arr5[index]["email"] = true;
      }
    } else {
      let arr = [...unitDetails];

      arr[index][name] = value;
    }
  };

  //   handleDelete UNit
  const handleDeleteUnitDetails = (index) => {
    let arr = [...unitDetails];
    let arr2 = [...unitDetailsError];

    arr.splice(index, 1);
    arr2.splice(index, 1);
    setUnitDetails(arr);
    setUnitDetailsError(arr2);
  };

  // Company Upload Logo
  const companyLogoImage = useRef(null);
  const gstDocument = useRef(null);
  const cinDocument = useRef(null);
  const msmeDocument = useRef(null);
  const panDocument = useRef(null);
  const tanDocument = useRef(null);
  const iecDocument = useRef(null);

  const handleCompanyLogoImageUploadBtn = () =>
    companyLogoImage.current.click();

  const handlegstDocumentUploadBtn = () => gstDocument.current.click();

  const handlecinDocumentUploadBtn = () => cinDocument.current.click();

  const handlemsmeDocumentUploadBtn = () => msmeDocument.current.click();

  const handlepanDocumentUploadBtn = () => panDocument.current.click();

  const handletanDocumentUploadBtn = () => tanDocument.current.click();

  const handleiecDocumentUploadBtn = () => iecDocument.current.click();

  //   HandleChange Image Logo
  const handleImageChange = async (name, value) => {
    const allowedTypes = ["image/jpeg", "image/png"];
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (value) {
      if (!allowedTypes.includes(value.type)) {
        toast.error("Invalid file type. Please select a JPEG, PNG.");
      } else if (value.size > maxSize) {
        toast.error("File size exceeds the limit of 5MB.");
      } else {
        if (name === "companyLogo") {
          setInputDataError({ ...inputDataError, companyLogo: false });
        } else if (name === "gstDocument") {
          setInputDataError({ ...inputDataError, gstDocument: false });
        } else if (name === "cinDocument") {
          setInputDataError({ ...inputDataError, cinDocument: false });
        } else if (name === "msmeDocument") {
          setInputDataError({ ...inputDataError, msmeDocument: false });
        } else if (name === "panDocument") {
          setInputDataError({ ...inputDataError, panDocument: false });
        } else if (name === "tanDocument") {
          setInputDataError({ ...inputDataError, tanDocument: false });
        } else if (name === "iecDocument") {
          setInputDataError({ ...inputDataError, iecDocument: false });
        }

        const formData = new FormData();
        formData.append("media", value);

        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/uploadmedia`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          setInputData({
            ...inputData,
            [name]: { url: _data.uploadedURL, name: _data?.filename },
          });
        } else {
          toast.error(_data?.error);
        }

        return { url: "https://httpbin.org/post" };
      }
    }
  };

  // handleNext Btn
  const handleNextBtn = (stepNo) => {
    if (stepNo === "step1") {
      if (!inputData?.companyName) {
        setInputDataError({ ...inputDataError, companyName: true });
        toast.error("Company Name is Required!");
      } else if (!inputData?.companyCode) {
        setInputDataError({ ...inputDataError, companyCode: true });
        toast.error("Company Code is Required!");
      } else if (!inputData?.companyLogo) {
        setInputDataError({ ...inputDataError, companyLogo: true });
        toast.error("Company Logo is Required!");
      } else if (!inputData?.addressLine1) {
        setInputDataError({ ...inputDataError, addressLine1: true });
        toast.error("Address Line1 is Required!");
      } else if (!inputData?.addressLine2) {
        setInputDataError({ ...inputDataError, addressLine2: true });
        toast.error("Address Line2 is Required!");
      } else if (!inputData?.addressLine3) {
        setInputDataError({ ...inputDataError, addressLine3: true });
        toast.error("Address Line3 is Required!");
      } else if (!inputData?.country) {
        setInputDataError({ ...inputDataError, country: true });
        toast.error("Country is Required!");
      } else if (!inputData?.state) {
        setInputDataError({ ...inputDataError, state: true });
        toast.error("State is Required!");
      } else if (!inputData?.dist) {
        setInputDataError({ ...inputDataError, dist: true });
        toast.error("Dist is Required!");
      } else if (!inputData?.taluka) {
        setInputDataError({ ...inputDataError, taluka: true });
        toast.error("Taluka is Required!");
      } else if (!inputData?.village) {
        setInputDataError({ ...inputDataError, village: true });
        toast.error("Village is Required!");
      } else if (!inputData?.pincode) {
        setInputDataError({ ...inputDataError, pincode: true });
        toast.error("Pincode is Required!");
      } else if (
        inputData?.pincode?.length < 6 ||
        inputData?.pincode?.length > 6
      ) {
        setInputDataError({ ...inputDataError, pincode: true });
        toast.error("Pincode is not in Correct Format!");
      } else if (!inputData?.mobileNo) {
        setInputDataError({ ...inputDataError, mobileNo: true });
        toast.error("Mobile No is Required!");
      } else if (
        inputData?.mobileNo?.length < 10 ||
        inputData?.mobileNo?.length > 10
      ) {
        setInputDataError({ ...inputDataError, mobileNo: true });
        toast.error("Mobile No is not in Correct Format!");
      } else if (
        (inputData?.phoneNo && inputData?.phoneNo?.length < 10) ||
        inputData?.phoneNo?.length > 10
      ) {
        setInputDataError({ ...inputDataError, phoneNo: true });
        toast.error("phone No is not in Correct Format!");
      } else if (!inputData?.email) {
        setInputDataError({ ...inputDataError, email: true });
        toast.error("Email  is Required!");
      } else if (!isValidEmail(inputData?.email)) {
        setInputDataError({ ...inputDataError, email: true });
        toast.error("Email is not in Correct Format!");
      } else {
        setStep("step2");

        let arr = [...unitDetails];
        let arr2 = [...unitDetailsError];

        let obj = {
          unitName: inputData?.companyName,
          aliasName: inputData?.aliasName,
          addressLine1: inputData?.addressLine1,
          addressLine2: inputData?.addressLine2,
          addressLine3: inputData?.addressLine3,
          country: inputData?.country,
          state: inputData?.state,
          dist: inputData?.dist,
          taluka: inputData?.taluka,
          village: inputData?.village,
          pincode: inputData?.pincode,
          mobileNo: inputData?.mobileNo,
          email: inputData?.email,
          gstNo: inputData?.gstNo,
        };

        let objerror = {
          unitName: false,
          aliasName: false,
          addressLine1: false,
          addressLine2: false,
          addressLine3: false,
          country: false,
          state: false,
          dist: false,
          taluka: false,
          village: false,
          pincode: false,
          mobileNo: false,
          email: false,
          gstNo: false,
        };

        if (unitDetails?.length === 0) {
          arr.unshift(obj);
          arr2.unshift(objerror);
          setUnitDetails(arr);
          setUnitDetailsError(arr2);
        }
      }
    }

    if (stepNo === "step2") {
      if (!inputData?.currency) {
        setInputDataError({ ...inputDataError, currency: true });
        toast.error("Currency is Required!");
      } else if (!inputData?.financialYear) {
        setInputDataError({ ...inputDataError, financialYear: true });
        toast.error("Financial year is Required!");
      } else if (!inputData?.gstNo) {
        setInputDataError({ ...inputDataError, gstNo: true });
        toast.error("GST No is Required!");
        // } else if (!gstRegex.test(inputData?.gstNo)) {
        //   toast.error("GST No is not in Correct Format!");
      } else if (!inputData?.panNo) {
        setInputDataError({ ...inputDataError, panNo: true });
        toast.error("PAN No is Required!");
      } else if (!panRegex.test(inputData?.panNo)) {
        setInputDataError({ ...inputDataError, panNo: true });
        toast.error("PAN No is not in Correct Format!");
      } else if (!inputData?.cinNo) {
        setInputDataError({ ...inputDataError, cinNo: true });
        toast.error("CIN No is Required!");
      } else if (!cinRegex.test(inputData?.cinNo)) {
        setInputDataError({ ...inputDataError, cinNo: true });
        toast.error("CIN No is not in Correct Format!");
      } else if (!inputData?.TanNo) {
        setInputDataError({ ...inputDataError, TanNo: true });
        toast.error("TAN No is Required!");
      } else if (!tanRegex.test(inputData?.TanNo)) {
        setInputDataError({ ...inputDataError, TanNo: true });
        toast.error("TAN No is not in Correct Format!");
      } else if (!inputData?.msmeNo) {
        setInputDataError({ ...inputDataError, msmeNo: true });
        toast.error("MSME No is Required!");
        // } else if (!msmeRegex.test(inputData?.msmeNo)) {
        //   toast.error("MSME No is not in Correct Format!");
      } else if (!inputData?.iecCode) {
        setInputDataError({ ...inputDataError, iecCode: true });
        toast.error("IEC Code is Required!");
      } else if (!iecRegex.test(inputData?.iecCode)) {
        setInputDataError({ ...inputDataError, iecCode: true });
        toast.error("IEC Code is not in Correct Format!");
      } else if (!inputData?.gstDocument) {
        setInputDataError({ ...inputDataError, gstDocument: true });
        toast.error("GST Document is Required!");
      } else if (!inputData?.cinDocument) {
        setInputDataError({ ...inputDataError, cinDocument: true });
        toast.error("CIN Document is Required!");
      } else if (!inputData?.panDocument) {
        setInputDataError({ ...inputDataError, panDocument: true });
        toast.error("PAN Document is Required!");
      } else if (!inputData?.tanDocument) {
        setInputDataError({ ...inputDataError, tanDocument: true });
        toast.error("TAN Document is Required!");
      } else if (!inputData?.msmeDocument) {
        setInputDataError({ ...inputDataError, msmeDocument: true });
        toast.error("MSME Document is Required!");
      } else if (!inputData?.iecDocument) {
        setInputDataError({ ...inputDataError, iecDocument: true });
        toast.error("IEC Document is Required!");
      } else {
        setStep("step3");
      }
    }
  };

  // handleChange Input
  const handleChangeInput = (name, value) => {
    if (name === "country") {
      getStateOption(value.value).then((res) => {
        setStateArray(res);
      });

      setInputData({
        ...inputData,
        [name]: value,
        state: "",
        dist: "",
        taluka: "",
        village: "",
      });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, country: false });
      } else {
        setInputDataError({ ...inputDataError, country: true });
      }
    } else if (name === "state") {
      getDistrictOption(value.value).then((res) => {
        setDistrictArray(res);
      });
      setInputData({
        ...inputData,
        [name]: value,
        dist: "",
        taluka: "",
        village: "",
      });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, state: false });
      } else {
        setInputDataError({ ...inputDataError, state: true });
      }
    } else if (name === "dist") {
      getTalukaOption(value.value).then((res) => {
        setTalukaArray(res);
      });
      setInputData({ ...inputData, [name]: value, taluka: "", village: "" });

      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, dist: false });
      } else {
        setInputDataError({ ...inputDataError, dist: true });
      }
    } else if (name === "taluka") {
      getVillageOption(value.value).then((res) => {
        setVillageArray(res);
      });
      setInputData({ ...inputData, [name]: value, village: "" });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, taluka: false });
      } else {
        setInputDataError({ ...inputDataError, taluka: true });
      }
    } else if (name === "village") {
      setInputData({ ...inputData, [name]: value });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, village: false });
      } else {
        setInputDataError({ ...inputDataError, village: true });
      }
    } else if (name === "companyCode") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length >= 3) {
        setInputDataError({ ...inputDataError, companyCode: false });
      } else {
        setInputDataError({ ...inputDataError, companyCode: true });
      }
    } else if (name === "companyName") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length >= 3) {
        setInputDataError({ ...inputDataError, companyName: false });
      } else {
        setInputDataError({ ...inputDataError, companyName: true });
      }
    } else if (name === "addressLine1") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length >= 3) {
        setInputDataError({ ...inputDataError, addressLine1: false });
      } else {
        setInputDataError({ ...inputDataError, addressLine1: true });
      }
    } else if (name === "addressLine2") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length >= 3) {
        setInputDataError({ ...inputDataError, addressLine2: false });
      } else {
        setInputDataError({ ...inputDataError, addressLine2: true });
      }
    } else if (name === "addressLine3") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length >= 3) {
        setInputDataError({ ...inputDataError, addressLine3: false });
      } else {
        setInputDataError({ ...inputDataError, addressLine3: true });
      }
    } else if (name === "pincode") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length === 6) {
        setInputDataError({ ...inputDataError, pincode: false });
      } else {
        setInputDataError({ ...inputDataError, pincode: true });
      }
    } else if (name === "mobileNo") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length === 10) {
        setInputDataError({ ...inputDataError, mobileNo: false });
      } else {
        setInputDataError({ ...inputDataError, mobileNo: true });
      }
    } else if (name === "financialYear") {
      setInputData({ ...inputData, [name]: value });
      if (value?.label?.length >= 3) {
        setInputDataError({ ...inputDataError, financialYear: false });
      } else {
        setInputDataError({ ...inputDataError, financialYear: true });
      }
    } else if (name === "gstNo") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length >= 3) {
        setInputDataError({ ...inputDataError, gstNo: false });
      } else {
        setInputDataError({ ...inputDataError, gstNo: true });
      }
    } else if (name === "panNo") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length >= 3 && panRegex.test(value)) {
        setInputDataError({ ...inputDataError, panNo: false });
      } else {
        setInputDataError({ ...inputDataError, panNo: true });
      }
    } else if (name === "cinNo") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length >= 3 && cinRegex.test(value)) {
        setInputDataError({ ...inputDataError, cinNo: false });
      } else {
        setInputDataError({ ...inputDataError, cinNo: true });
      }
    } else if (name === "TanNo") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length >= 3 && tanRegex.test(value)) {
        setInputDataError({ ...inputDataError, TanNo: false });
      } else {
        setInputDataError({ ...inputDataError, TanNo: true });
      }
    } else if (name === "msmeNo") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length >= 3) {
        setInputDataError({ ...inputDataError, msmeNo: false });
      } else {
        setInputDataError({ ...inputDataError, msmeNo: true });
      }
    } else if (name === "iecCode") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length >= 3 && iecRegex.test(value)) {
        setInputDataError({ ...inputDataError, iecCode: false });
      } else {
        setInputDataError({ ...inputDataError, iecCode: true });
      }
    } else if (name === "email") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length >= 3 && isValidEmail(value)) {
        setInputDataError({ ...inputDataError, email: false });
      } else {
        setInputDataError({ ...inputDataError, email: true });
      }
    } else {
      setInputData({ ...inputData, [name]: value });
    }
  };

  const getFinacalOption = useCallback(async () => {
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}admin1/financialyear`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        let arr = [];

        _data?.yearList?.map((er) => {
          arr.push({ label: er?.year, value: er?.id });
          return er;
        });

        setFinancialYearOption(arr);
      } else {
        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getFinacalOption();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = false;

    // eslint-disable-next-line
    unitDetails?.map((er, index) => {
      if (!er?.unitName) {
        toast.error("Unit Name is Required!");
        let arr = [...unitDetailsError];
        arr[index]["unitName"] = true;
        setUnitDetailsError(arr);
        error = true;
      } else if (!er?.aliasName) {
        toast.error("Alias Name is Required!");
        let arr = [...unitDetailsError];
        arr[index]["aliasName"] = true;
        setUnitDetailsError(arr);
        error = true;
      } else if (!er?.addressLine1) {
        toast.error("Address Line 1 is Required!");
        let arr = [...unitDetailsError];
        arr[index]["addressLine1"] = true;
        setUnitDetailsError(arr);
        error = true;
      } else if (!er?.addressLine2) {
        toast.error("Address Line 2 is Required!");
        let arr = [...unitDetailsError];
        arr[index]["addressLine2"] = true;
        setUnitDetailsError(arr);
        error = true;
      } else if (!er?.addressLine3) {
        toast.error("Address Line 3 is Required!");
        let arr = [...unitDetailsError];
        arr[index]["addressLine3"] = true;
        setUnitDetailsError(arr);
        error = true;
      } else if (!er?.country?.label) {
        toast.error("Country is Required!");
        let arr = [...unitDetailsError];
        arr[index]["country"] = true;
        setUnitDetailsError(arr);
        error = true;
      } else if (!er?.state?.label) {
        toast?.error("State is Required!");
        let arr = [...unitDetailsError];
        arr[index]["state"] = true;
        setUnitDetailsError(arr);
        error = true;
      } else if (!er?.dist?.label) {
        toast?.error("District is Required!");
        error = true;
        let arr = [...unitDetailsError];
        arr[index]["dist"] = true;
        setUnitDetailsError(arr);
      } else if (!er?.taluka?.label) {
        toast?.error("Taluka is Required!");
        error = true;
        let arr = [...unitDetailsError];
        arr[index]["taluka"] = true;
        setUnitDetailsError(arr);
      } else if (!er?.village?.label) {
        toast?.error("village is Required!");
        error = true;
        let arr = [...unitDetailsError];
        arr[index]["village"] = true;
        setUnitDetailsError(arr);
      } else if (!er?.pincode) {
        let arr = [...unitDetailsError];
        arr[index]["pincode"] = true;
        setUnitDetailsError(arr);
        toast.error("Pincode is Required!");
        error = true;
      } else if (er?.pincode?.length < 6 || er?.pincode?.length > 6) {
        let arr = [...unitDetailsError];
        arr[index]["pincode"] = true;
        setUnitDetailsError(arr);
        toast.error("Pincode is not in Correct Format!");
        error = true;
      } else if (!er?.mobileNo) {
        let arr = [...unitDetailsError];
        arr[index]["mobileNo"] = true;
        setUnitDetailsError(arr);
        toast.error("Mobile No is Required!");
        error = true;
      } else if (er?.mobileNo?.length < 10 || er?.mobileNo?.length > 10) {
        let arr = [...unitDetailsError];
        arr[index]["mobileNo"] = true;
        setUnitDetailsError(arr);
        toast.error("Mobile No is not in Correct Format!");
        error = true;
      } else if (!er?.email) {
        let arr = [...unitDetailsError];
        arr[index]["email"] = true;
        setUnitDetailsError(arr);
        toast.error("Email  is Required!");
        error = true;
      } else if (!isValidEmail(er?.email)) {
        let arr = [...unitDetailsError];
        arr[index]["email"] = true;
        setUnitDetailsError(arr);
        toast.error("Email is not in Correct Format!");
      }
    });

    if (!error) {
      setLoading(true);
      let formData = new FormData();
      formData.append("companyName", inputData.companyName);
      formData.append("companyCode", inputData.companyCode);
      formData.append("aliasName", inputData.aliasName);
      formData.append("addressLine1", inputData.addressLine1);
      formData.append("addressLine2", inputData.addressLine2);
      formData.append("addressLine3", inputData.addressLine3);
      formData.append("countryId", inputData.country.value);
      formData.append("stateId", inputData.state.value);
      formData.append("districtId", inputData.dist.value);
      formData.append("talukaId", inputData.taluka.value);
      formData.append("villageId", inputData.village.value);
      formData.append("pincode", inputData.pincode);
      formData.append("email", inputData.email);
      formData.append("logo", inputData.companyLogo.url);
      if (inputData.phoneNo?.length > 0) {
        formData.append("phoneNo", `+91${inputData.phoneNo}`);
      }
      formData.append("mobileNo", `+91${inputData.mobileNo}`);
      formData.append("faxNo", inputData.faxNo);
      formData.append("website", inputData.website);
      formData.append("currency", inputData.currency.value);
      formData.append("financialYearId", inputData.financialYear.value);
      formData.append("gstNo", inputData.gstNo);
      formData.append("panNo", inputData.panNo);
      formData.append("tanNo", inputData.TanNo);
      formData.append("cinNo", inputData.cinNo);
      formData.append("msmeNo", inputData.msmeNo);
      formData.append("iecCode", inputData.iecCode);
      formData.append("gstCertificate", inputData.gstDocument.url);
      formData.append("panImg", inputData.panDocument.url);
      formData.append("tanCertificate", inputData.tanDocument.url);
      formData.append("cinCertificate", inputData.cinDocument.url);
      formData.append("msmeCertificate", inputData.msmeDocument.url);
      formData.append("iecdocument", inputData.iecDocument.url);
      formData.append("unitData", JSON.stringify(unitDetails));

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}company/addcompany`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }
        // dispatch(logOutRequest());
        // toast.error("Session Expired.");

        if (_data?.status) {
          toast?.success(_data?.message);
          setLoading(false);
          navigate("/companylist");
        } else {
          setLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };
  return (
    <PageLayout
      title={
        step === "step3"
          ? "add new unit"
          : step === "step2"
          ? "statutory & Other Details"
          : "ADD new company"
      }
      subTitle={
        isTablet
          ? ""
          : "Please provide information for company participating in this program"
      }
    >
      <div
        className="d-flex justify-content-start align-items-center mt-5"
        style={{ margin: 20 }}
      >
        <form className="w-100">
          {step === "step1" && (
            <>
              {/* Company Details Section  */}
              <div>
                <Row className={`${isMobile ? "" : "g-3 mt-2"}`}>
                  <Col
                    lg={4}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={4}>
                      <Label
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Company Name <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Input
                        id="exampleEmail"
                        value={inputData?.companyName}
                        name="companyName"
                        onChange={(e) =>
                          handleChangeInput(e.target.name, e.target.value)
                        }
                        className={`cust-input ${
                          inputDataError?.companyName ? "border-danger" : ""
                        }`}
                        type="text"
                      />
                    </Col>
                  </Col>
                  <Col
                    lg={4}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={4}>
                      <Label
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Company Logo <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <button
                        type="button"
                        onClick={handleCompanyLogoImageUploadBtn}
                        className={`${
                          inputDataError?.companyLogo
                            ? "border border-danger"
                            : ""
                        }`}
                        style={{
                          border: "none",
                          color: "white",
                          padding: 8,
                          background:
                            "linear-gradient(180deg, #C1C1C1 0%, #6D6D6D 100%)",
                          borderRadius: 5,
                        }}
                      >
                        Upload
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          style={{ height: 16, width: 16, marginLeft: 2 }}
                        >
                          <path
                            d="M4 12.6667H12"
                            stroke="white"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 10H9.33333V8H11L8 5M8 10H6.66667V8H5L8 5"
                            stroke="white"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>

                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept="image/png, image/jpeg , image/jpg"
                        name="companyLogo"
                        ref={companyLogoImage}
                        onChange={(e) =>
                          handleImageChange(e.target.name, e.target.files[0])
                        }
                      />

                      <span className="mx-3" style={{ fontWeight: 500 }}>
                        {inputData?.companyLogo
                          ? inputData?.companyLogo.name
                          : " .jpg, .jpeg, .png etc."}
                      </span>
                    </Col>
                  </Col>
                </Row>
              </div>
              <div>
                <Row className={`${isMobile ? "" : "g-3 mt-2"}`}>
                  <Col
                    lg={4}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={4}>
                      <Label
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Alias Name
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Input
                        id="exampleEmail"
                        value={inputData?.aliasName}
                        name="aliasName"
                        onChange={(e) =>
                          handleChangeInput(e.target.name, e.target.value)
                        }
                        className={`cust-input`}
                        type="text"
                      />
                    </Col>
                  </Col>
                </Row>
              </div>
              <div>
                <Row className={`${isMobile ? "" : "g-3 mt-2"}`}>
                  <Col
                    lg={4}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={4}>
                      <Label
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Company Code <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Input
                        id="exampleEmail"
                        value={inputData?.companyCode}
                        name="companyCode"
                        onChange={(e) =>
                          handleChangeInput(e.target.name, e.target.value)
                        }
                        className={`cust-input ${
                          inputDataError?.companyCode ? "border-danger" : ""
                        }`}
                        type="text"
                      />
                    </Col>
                  </Col>
                </Row>
              </div>

              {/* Company Address Section  */}

              <div
                style={{ marginTop: 40 }}
                className="d-flex justify-content-start align-items-center"
              >
                <h5 className="mr-3">Company Address</h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="2"
                  viewBox="0 0 1377 2"
                  fill="none"
                  style={{ marginLeft: 5, width: "80%" }}
                >
                  <path d="M0.5 1L1377 1" stroke="#999999" />
                </svg>
              </div>

              {/* Address Line 1 & 2 */}
              <div>
                <Row className={`${isMobile ? "" : "g-3 mt-2"}`}>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={4}>
                      <Label
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Address Line 1{" "}
                        <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Input
                        id="exampleEmail"
                        value={inputData?.addressLine1}
                        name="addressLine1"
                        onChange={(e) =>
                          handleChangeInput(e.target.name, e.target.value)
                        }
                        className={`cust-input ${
                          inputDataError?.addressLine1 ? "border-danger" : ""
                        }`}
                        type="text"
                      />
                    </Col>
                  </Col>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={4}>
                      <Label
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Address Line 2{" "}
                        <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Input
                        id="exampleEmail"
                        value={inputData?.addressLine2}
                        name="addressLine2"
                        onChange={(e) =>
                          handleChangeInput(e.target.name, e.target.value)
                        }
                        className={`cust-input ${
                          inputDataError?.addressLine2 ? "border-danger" : ""
                        }`}
                        type="text"
                      />
                    </Col>
                  </Col>
                </Row>
              </div>

              {/* AddressLine 3 & Country */}
              <div>
                <Row className={`${isMobile ? "" : "g-3 mt-2"}`}>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={4}>
                      <Label
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Address Line 3{" "}
                        <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Input
                        id="exampleEmail"
                        value={inputData?.addressLine3}
                        name="addressLine3"
                        onChange={(e) =>
                          handleChangeInput(e.target.name, e.target.value)
                        }
                        className={`cust-input ${
                          inputDataError?.addressLine3 ? "border-danger" : ""
                        }`}
                        type="text"
                      />
                    </Col>
                  </Col>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex "}`}
                  >
                    <Col md={4}>
                      <Label
                        className="me-2"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Country <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      {/* <Input
                          id="exampleEmail"
                          value={inputData?.country}
                          name="country"
                          onChange={(e) =>
                            handleChangeInput(e.target.name, e.target.value)
                          }
                          className={`cust-input ${inputDataError?.companyName? "border-danger" : ""}`}
                          type="text"
                        /> */}

                      <Select
                        isMulti={false}
                        onChange={(e) => {
                          handleChangeInput("country", e);
                        }}
                        value={inputData?.country}
                        options={countryArray}
                        // menuPlacement="top"
                        name="choices-single-default"
                        id="countryAddCompany"
                        className={`w-100 ${
                          inputDataError?.country ? "border border-danger" : ""
                        }`}
                      ></Select>
                    </Col>
                  </Col>
                </Row>
              </div>

              {/* State & Dist */}
              <div>
                <Row className={`${isMobile ? "" : "g-3 mt-2"}`}>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex "}`}
                  >
                    <Col md={4}>
                      <Label
                        className="me-2"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        State <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Select
                        isMulti={false}
                        onChange={(e) => {
                          handleChangeInput("state", e);
                        }}
                        value={inputData?.state}
                        options={stateArray}
                        // menuPlacement="top"
                        name="choices-single-default"
                        id="countryAddCompany"
                        className={`w-100 ${
                          inputDataError?.state ? "border border-danger" : ""
                        }`}
                      ></Select>
                    </Col>
                  </Col>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={4}>
                      <Label
                        className="me-2"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Dist <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Select
                        isMulti={false}
                        onChange={(e) => {
                          handleChangeInput("dist", e);
                        }}
                        value={inputData?.dist}
                        name="dist"
                        options={districtArray}
                        // menuPlacement="top"

                        className={`w-100 ${
                          inputDataError?.dist ? "border border-danger" : ""
                        }`}
                      ></Select>
                    </Col>
                  </Col>
                </Row>
              </div>

              {/* City  & Taluka */}
              <div>
                <Row className={`${isMobile ? "" : "g-3 mt-2"}`}>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={4}>
                      <Label
                        className="me-2"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Taluka <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Select
                        isMulti={false}
                        onChange={(e) => {
                          handleChangeInput("taluka", e);
                        }}
                        value={inputData?.taluka}
                        name="taluka"
                        options={talukaArray}
                        // menuPlacement="top"

                        className={`w-100 ${
                          inputDataError?.taluka ? "border border-danger" : ""
                        }`}
                      ></Select>
                    </Col>
                  </Col>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={4}>
                      <Label
                        className="me-2"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Village <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Select
                        isMulti={false}
                        onChange={(e) => {
                          handleChangeInput("village", e);
                        }}
                        value={inputData?.village}
                        name="village"
                        options={villageArray}
                        // menuPlacement="top"

                        className={`w-100 ${
                          inputDataError?.village ? "border border-danger" : ""
                        }`}
                      ></Select>
                    </Col>
                  </Col>
                </Row>
              </div>

              {/* Village & Pincode */}
              <div>
                <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={4}>
                      <Label
                        className="me-2"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Pincode <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Input
                        id="exampleEmail"
                        value={inputData?.pincode}
                        name="pincode"
                        onChange={(e) =>
                          handleChangeInput(e.target.name, e.target.value)
                        }
                        className={`cust-input ${
                          inputDataError?.pincode ? "border-danger" : ""
                        }`}
                        type="number"
                        maxLength={6}
                      />
                    </Col>
                  </Col>

                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={4}>
                      <Label
                        className="me-2"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Mobile No. <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Input
                        id="exampleEmail"
                        minLength={10}
                        value={inputData?.mobileNo}
                        name="mobileNo"
                        onChange={(e) =>
                          handleChangeInput(e.target.name, e.target.value)
                        }
                        className={`cust-input ${
                          inputDataError?.mobileNo ? "border-danger" : ""
                        }`}
                        type="number"
                      />
                    </Col>
                  </Col>
                </Row>
              </div>

              {/* Mobile No  & Phone No. */}
              <div>
                <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={4}>
                      <Label
                        className="me-2"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Phone No.
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Input
                        id="exampleEmail"
                        value={inputData?.phoneNo}
                        name="phoneNo"
                        onChange={(e) =>
                          handleChangeInput(e.target.name, e.target.value)
                        }
                        minLength={10}
                        className={`cust-input`}
                        type="number"
                      />
                    </Col>
                  </Col>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={4}>
                      <Label
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Email <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Input
                        id="exampleEmail"
                        value={inputData?.email}
                        name="email"
                        onChange={(e) =>
                          handleChangeInput(e.target.name, e.target.value)
                        }
                        className={`cust-input ${
                          inputDataError?.email ? "border-danger" : ""
                        }`}
                        type="email"
                      />
                    </Col>
                  </Col>
                </Row>
              </div>

              {/* Next Button View */}
              <div>
                <Col
                  md={12}
                  xl={8}
                  lg={12}
                  className="d-flex justify-content-end align-items-end mt-2"
                >
                  <button
                    type="button"
                    className="custom-btn-page-layout btn text-white "
                    onClick={() => handleNextBtn("step1")}
                  >
                    Next
                    {!isMobile && (
                      <svg
                        style={{ marginLeft: 5 }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M1 8C1 9.38447 1.41054 10.7378 2.17971 11.889C2.94888 13.0401 4.04213 13.9373 5.32122 14.4672C6.6003 14.997 8.00776 15.1356 9.36563 14.8655C10.7235 14.5954 11.9708 13.9287 12.9497 12.9497C13.9287 11.9708 14.5954 10.7235 14.8655 9.36563C15.1356 8.00776 14.997 6.6003 14.4672 5.32122C13.9373 4.04213 13.0401 2.94888 11.889 2.17971C10.7378 1.41054 9.38447 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8ZM4 7.5H10.075L7.285 4.6965L8 4L12 8L8 12L7.285 11.2865L10.075 8.5H4V7.5Z"
                          fill="white"
                        />
                      </svg>
                    )}
                  </button>
                </Col>
              </div>
            </>
          )}
          {step === "step2" && (
            <>
              {/* Fax No. & Website */}
              <div>
                <Row className={`${isMobile ? "" : "g-3 mt-2"}`}>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={3}>
                      <Label
                        className="me-3"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Fax No.
                      </Label>
                    </Col>
                    <Col sm={7}>
                      <Input
                        id="exampleEmail"
                        value={inputData?.faxNo}
                        name="faxNo"
                        onChange={(e) =>
                          handleChangeInput(e.target.name, e.target.value)
                        }
                        className={`cust-input`}
                        type="text"
                      />
                    </Col>
                  </Col>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={3}>
                      <Label
                        className="me-3"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Website
                      </Label>
                    </Col>
                    <Col sm={7}>
                      <Input
                        id="exampleEmail"
                        value={inputData?.website}
                        name="website"
                        onChange={(e) =>
                          handleChangeInput(e.target.name, e.target.value)
                        }
                        className={`cust-input `}
                        type="text"
                      />
                    </Col>
                  </Col>
                </Row>
              </div>
              {/* Currency & Financial Year */}
              <div>
                <Row className={`${isMobile ? "" : "g-3 mt-2"}`}>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={3}>
                      <Label
                        className="me-3"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Currency <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={7}>
                      <Select
                        isMulti={false}
                        onChange={(e) => {
                          handleChangeInput("currency", e);
                        }}
                        value={inputData?.currency}
                        name="currency"
                        isDisabled
                        options={currencyArray}
                        // menuPlacement="top"

                        className={`w-100 ${
                          inputDataError?.currency ? "border border-danger" : ""
                        }`}
                      ></Select>
                    </Col>
                  </Col>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={3}>
                      <Label
                        className="me-3"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Financial Year{" "}
                        <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={7}>
                      <Select
                        isMulti={false}
                        onChange={(e) => {
                          handleChangeInput("financialYear", e);
                        }}
                        value={inputData?.financialYear}
                        name="dist"
                        options={financialYearOption}
                        // menuPlacement="top"

                        className={`w-100 ${
                          inputDataError?.financialYear
                            ? "border border-danger"
                            : ""
                        }`}
                      ></Select>
                    </Col>
                  </Col>
                </Row>
              </div>
              {/* GST No. & PAN No. */}
              <div>
                <Row className={`${isMobile ? "" : "g-3 mt-2"}`}>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={3}>
                      <Label
                        className="me-3"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        GST No. <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={7}>
                      <Input
                        id="exampleEmail"
                        value={inputData?.gstNo}
                        name="gstNo"
                        onChange={(e) =>
                          handleChangeInput(e.target.name, e.target.value)
                        }
                        className={`cust-input ${
                          inputDataError?.gstNo ? "border-danger" : ""
                        }`}
                        type="text"
                      />
                    </Col>
                  </Col>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={3}>
                      <Label
                        className="me-3"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        PAN No. <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={7}>
                      <Input
                        id="exampleEmail"
                        value={inputData?.panNo}
                        name="panNo"
                        onChange={(e) =>
                          handleChangeInput(
                            e.target.name,
                            e.target.value.toUpperCase()
                          )
                        }
                        className={`cust-input ${
                          inputDataError?.panNo ? "border-danger" : ""
                        }`}
                        type="text"
                      />
                    </Col>
                  </Col>
                </Row>
              </div>
              {/* CIN No. & TAN No. */}
              <div>
                <Row className={`${isMobile ? "" : "g-3 mt-2"}`}>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={3}>
                      <Label
                        className="me-3"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        CIN No. <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={7}>
                      <Input
                        id="exampleEmail"
                        value={inputData?.cinNo}
                        name="cinNo"
                        onChange={(e) =>
                          handleChangeInput(
                            e.target.name,
                            e.target.value.toUpperCase()
                          )
                        }
                        className={`cust-input ${
                          inputDataError?.cinNo ? "border-danger" : ""
                        }`}
                        type="text"
                      />
                    </Col>
                  </Col>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={3}>
                      <Label
                        className="me-3"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        TAN No. <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={7}>
                      <Input
                        id="exampleEmail"
                        value={inputData?.TanNo}
                        name="TanNo"
                        onChange={(e) =>
                          handleChangeInput(
                            e.target.name,
                            e.target.value.toUpperCase()
                          )
                        }
                        className={`cust-input ${
                          inputDataError?.TanNo ? "border-danger" : ""
                        }`}
                        type="text"
                      />
                    </Col>
                  </Col>
                </Row>
              </div>
              {/* MSME No. & IEC Code */}
              <div>
                <Row className={`${isMobile ? "" : "g-3 mt-2"}`}>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={3}>
                      <Label
                        className="me-3"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        MSME No. <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={7}>
                      <Input
                        id="exampleEmail"
                        value={inputData?.msmeNo}
                        name="msmeNo"
                        onChange={(e) =>
                          handleChangeInput(
                            e.target.name,
                            e.target.value.toUpperCase()
                          )
                        }
                        className={`cust-input ${
                          inputDataError?.msmeNo ? "border-danger" : ""
                        }`}
                        type="text"
                      />
                    </Col>
                  </Col>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={3}>
                      <Label
                        className="me-3"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        IEC Code <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={7}>
                      <Input
                        id="exampleEmail"
                        value={inputData?.iecCode}
                        name="iecCode"
                        onChange={(e) =>
                          handleChangeInput(
                            e.target.name,
                            e.target.value.toUpperCase()
                          )
                        }
                        className={`cust-input ${
                          inputDataError?.iecCode ? "border-danger" : ""
                        }`}
                        type="text"
                      />
                    </Col>
                  </Col>
                </Row>
              </div>
              {/* gst Document & cin Code */}
              <div>
                <Row className={`${isMobile ? "" : "g-3 mt-2"}`}>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={4}>
                      <Label
                        className="me-3"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        GST Document <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <button
                        type="button"
                        onClick={handlegstDocumentUploadBtn}
                        className={`${
                          inputDataError?.gstDocument
                            ? "border border-danger"
                            : ""
                        }`}
                        style={{
                          border: "none",
                          color: "white",
                          padding: 8,
                          background:
                            "linear-gradient(180deg, #C1C1C1 0%, #6D6D6D 100%)",
                          borderRadius: 5,
                        }}
                      >
                        Upload
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          style={{ height: 16, width: 16, marginLeft: 2 }}
                        >
                          <path
                            d="M4 12.6667H12"
                            stroke="white"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 10H9.33333V8H11L8 5M8 10H6.66667V8H5L8 5"
                            stroke="white"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>

                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept="image/png, image/jpeg , image/jpg"
                        name="gstDocument"
                        ref={gstDocument}
                        onChange={(e) =>
                          handleImageChange(e.target.name, e.target.files[0])
                        }
                      />

                      <span className="mx-3" style={{ fontWeight: 500 }}>
                        {inputData?.gstDocument
                          ? inputData?.gstDocument?.name
                          : ".jpg, .jpeg, .png etc."}
                      </span>
                    </Col>
                  </Col>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={4}>
                      <Label
                        className="me-3"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        CIN Document <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <button
                        type="button"
                        onClick={handlecinDocumentUploadBtn}
                        className={`${
                          inputDataError?.cinDocument
                            ? "border border-danger"
                            : ""
                        }`}
                        style={{
                          border: "none",
                          color: "white",
                          padding: 8,
                          background:
                            "linear-gradient(180deg, #C1C1C1 0%, #6D6D6D 100%)",
                          borderRadius: 5,
                        }}
                      >
                        Upload
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          style={{ height: 16, width: 16, marginLeft: 2 }}
                        >
                          <path
                            d="M4 12.6667H12"
                            stroke="white"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 10H9.33333V8H11L8 5M8 10H6.66667V8H5L8 5"
                            stroke="white"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>

                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept="image/png, image/jpeg , image/jpg"
                        name="cinDocument"
                        ref={cinDocument}
                        onChange={(e) =>
                          handleImageChange(e.target.name, e.target.files[0])
                        }
                      />

                      <span className="mx-3" style={{ fontWeight: 500 }}>
                        {inputData?.cinDocument
                          ? inputData?.cinDocument?.name
                          : ".jpg, .jpeg, .png etc."}
                      </span>
                    </Col>
                  </Col>
                </Row>
              </div>

              {/* pan Document & tan Documenr */}
              <div>
                <Row className={`${isMobile ? "" : "g-3 mt-2"}`}>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={4}>
                      <Label
                        className="me-3"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        PAN Document <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <button
                        type="button"
                        onClick={handlepanDocumentUploadBtn}
                        className={`${
                          inputDataError?.panDocument
                            ? "border border-danger"
                            : ""
                        }`}
                        style={{
                          border: "none",
                          color: "white",
                          padding: 8,
                          background:
                            "linear-gradient(180deg, #C1C1C1 0%, #6D6D6D 100%)",
                          borderRadius: 5,
                        }}
                      >
                        Upload
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          style={{ height: 16, width: 16, marginLeft: 2 }}
                        >
                          <path
                            d="M4 12.6667H12"
                            stroke="white"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 10H9.33333V8H11L8 5M8 10H6.66667V8H5L8 5"
                            stroke="white"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>

                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept="image/png, image/jpeg , image/jpg"
                        name="panDocument"
                        ref={panDocument}
                        onChange={(e) =>
                          handleImageChange(e.target.name, e.target.files[0])
                        }
                      />

                      <span className="mx-3" style={{ fontWeight: 500 }}>
                        {inputData?.panDocument
                          ? inputData?.panDocument?.name
                          : ".jpg, .jpeg, .png etc."}
                      </span>
                    </Col>
                  </Col>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={4}>
                      <Label
                        className="me-3"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        TAN Document <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <button
                        type="button"
                        onClick={handletanDocumentUploadBtn}
                        className={`${
                          inputDataError?.tanDocument
                            ? "border border-danger"
                            : ""
                        }`}
                        style={{
                          border: "none",
                          color: "white",
                          padding: 8,
                          background:
                            "linear-gradient(180deg, #C1C1C1 0%, #6D6D6D 100%)",
                          borderRadius: 5,
                        }}
                      >
                        Upload
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          style={{ height: 16, width: 16, marginLeft: 2 }}
                        >
                          <path
                            d="M4 12.6667H12"
                            stroke="white"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 10H9.33333V8H11L8 5M8 10H6.66667V8H5L8 5"
                            stroke="white"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>

                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept="image/png, image/jpeg , image/jpg"
                        name="tanDocument"
                        ref={tanDocument}
                        onChange={(e) =>
                          handleImageChange(e.target.name, e.target.files[0])
                        }
                      />

                      <span className="mx-3" style={{ fontWeight: 500 }}>
                        {inputData?.tanDocument
                          ? inputData?.tanDocument?.name
                          : ".jpg, .jpeg, .png etc."}
                      </span>
                    </Col>
                  </Col>
                </Row>
              </div>

              {/* msme Document & iec Code */}
              <div>
                <Row className={`${isMobile ? "" : "g-3 mt-2"}`}>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={4}>
                      <Label
                        className="me-3"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        MSME Document <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <button
                        type="button"
                        onClick={handlemsmeDocumentUploadBtn}
                        className={`${
                          inputDataError?.msmeDocument
                            ? "border border-danger"
                            : ""
                        }`}
                        style={{
                          border: "none",
                          color: "white",
                          padding: 8,
                          background:
                            "linear-gradient(180deg, #C1C1C1 0%, #6D6D6D 100%)",
                          borderRadius: 5,
                        }}
                      >
                        Upload
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          style={{ height: 16, width: 16, marginLeft: 2 }}
                        >
                          <path
                            d="M4 12.6667H12"
                            stroke="white"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 10H9.33333V8H11L8 5M8 10H6.66667V8H5L8 5"
                            stroke="white"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>

                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept="image/png, image/jpeg , image/jpg"
                        name="msmeDocument"
                        ref={msmeDocument}
                        onChange={(e) =>
                          handleImageChange(e.target.name, e.target.files[0])
                        }
                      />

                      <span className="mx-3" style={{ fontWeight: 500 }}>
                        {inputData?.msmeDocument
                          ? inputData?.msmeDocument?.name
                          : ".jpg, .jpeg, .png etc."}
                      </span>
                    </Col>
                  </Col>
                  <Col
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    className={`${isMobile ? "" : "d-flex"}`}
                  >
                    <Col md={4}>
                      <Label
                        className="me-3"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        IEC Document <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <button
                        type="button"
                        onClick={handleiecDocumentUploadBtn}
                        className={`${
                          inputDataError?.iecDocument
                            ? "border border-danger"
                            : ""
                        }`}
                        style={{
                          border: "none",
                          color: "white",
                          padding: 8,
                          background:
                            "linear-gradient(180deg, #C1C1C1 0%, #6D6D6D 100%)",
                          borderRadius: 5,
                        }}
                      >
                        Upload
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          style={{ height: 16, width: 16, marginLeft: 2 }}
                        >
                          <path
                            d="M4 12.6667H12"
                            stroke="white"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 10H9.33333V8H11L8 5M8 10H6.66667V8H5L8 5"
                            stroke="white"
                            strokeWidth="1.33333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>

                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept="image/png, image/jpeg , image/jpg"
                        name="iecDocument"
                        ref={iecDocument}
                        onChange={(e) =>
                          handleImageChange(e.target.name, e.target.files[0])
                        }
                      />

                      <span className="mx-3" style={{ fontWeight: 500 }}>
                        {inputData?.iecDocument
                          ? inputData?.iecDocument?.name
                          : ".jpg, .jpeg, .png etc."}
                      </span>
                    </Col>
                  </Col>
                </Row>
              </div>
              <div className="">
                <Col
                  xl={8}
                  lg={12}
                  className="d-flex justify-content-end align-items-end mt-5"
                >
                  <button
                    type="button"
                    className="custom-btn-page-layout btn text-white "
                    onClick={() => setStep("step1")}
                  >
                    {!isMobile && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        style={{ marginRight: 5 }}
                      >
                        <path
                          d="M15 8C15 9.38447 14.5895 10.7378 13.8203 11.889C13.0511 13.0401 11.9579 13.9373 10.6788 14.4672C9.3997 14.997 7.99224 15.1356 6.63437 14.8655C5.2765 14.5954 4.02922 13.9287 3.05025 12.9497C2.07129 11.9708 1.4046 10.7235 1.1345 9.36563C0.864407 8.00776 1.00303 6.6003 1.53284 5.32122C2.06266 4.04213 2.95986 2.94888 4.11101 2.17971C5.26215 1.41054 6.61553 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8ZM12 7.5H5.925L8.715 4.6965L8 4L4 8L8 12L8.715 11.2865L5.925 8.5H12V7.5Z"
                          fill="white"
                        />
                      </svg>
                    )}
                    Previous
                  </button>
                  <button
                    type="button"
                    className="custom-btn-page-layout btn text-white mx-4"
                    onClick={() => handleNextBtn("step2")}
                  >
                    Next
                    {!isMobile && (
                      <svg
                        style={{ marginLeft: 5 }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M1 8C1 9.38447 1.41054 10.7378 2.17971 11.889C2.94888 13.0401 4.04213 13.9373 5.32122 14.4672C6.6003 14.997 8.00776 15.1356 9.36563 14.8655C10.7235 14.5954 11.9708 13.9287 12.9497 12.9497C13.9287 11.9708 14.5954 10.7235 14.8655 9.36563C15.1356 8.00776 14.997 6.6003 14.4672 5.32122C13.9373 4.04213 13.0401 2.94888 11.889 2.17971C10.7378 1.41054 9.38447 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8ZM4 7.5H10.075L7.285 4.6965L8 4L12 8L8 12L7.285 11.2865L10.075 8.5H4V7.5Z"
                          fill="white"
                        />
                      </svg>
                    )}
                  </button>
                </Col>
              </div>
            </>
          )}

          {step === "step3" && (
            <>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="custom-btn-page-layout btn text-white mx-4"
                  onClick={() => {
                    setUnitDetails([
                      ...unitDetails,
                      {
                        unitName: "",
                        aliasName: "",
                        addressLine1: "",
                        addressLine2: "",
                        addressLine3: "",
                        country: "",
                        state: "",
                        dist: "",
                        taluka: "",
                        village: "",
                        pincode: "",
                        mobileNo: "",
                        email: "",
                        gstNo: "",
                        countryArray: countryArray,
                        stateArray: [],
                        districtArray: [],
                        villageArray: [],
                        talukaArray: [],
                      },
                    ]);
                    setUnitDetailsError([
                      ...unitDetailsError,
                      {
                        unitName: false,
                        aliasName: false,
                        addressLine1: false,
                        addressLine2: false,
                        addressLine3: false,
                        country: false,
                        state: false,
                        dist: false,
                        taluka: false,
                        village: false,
                        pincode: false,
                        mobileNo: false,
                        email: false,
                        gstNo: false,
                      },
                    ]);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="me-2"
                  >
                    <path
                      d="M8.00016 2.66659C10.9408 2.66659 13.3335 5.05925 13.3335 7.99992C13.3335 10.9406 10.9408 13.3333 8.00016 13.3333C5.0595 13.3333 2.66683 10.9406 2.66683 7.99992C2.66683 5.05925 5.0595 2.66659 8.00016 2.66659ZM8.00016 1.33325C4.31816 1.33325 1.3335 4.31792 1.3335 7.99992C1.3335 11.6819 4.31816 14.6666 8.00016 14.6666C11.6822 14.6666 14.6668 11.6819 14.6668 7.99992C14.6668 4.31792 11.6822 1.33325 8.00016 1.33325ZM11.3335 7.33325H8.66683V4.66658H7.3335V7.33325H4.66683V8.66658H7.3335V11.3333H8.66683V8.66658H11.3335V7.33325Z"
                      fill="white"
                    />
                  </svg>
                  New Unit
                </button>
              </div>

              {unitDetails?.map((item, index) => (
                <Accordion
                  key={index}
                  open={open}
                  className={index === 0 ? "mt-4" : ""}
                  toggle={toggle}
                >
                  <fieldset
                    className="cus-add-req-sur border-none m-0 p-2"
                    style={{ border: "none" }}
                  >
                    <AccordionItem>
                      <AccordionHeader targetId={`${index + 1}`}>
                        Unit : {index + 1}
                      </AccordionHeader>
                      <AccordionBody accordionId={`${index + 1}`}>
                        <div className="w-100 d-flex  justify-content-end align-items-center">
                          <div
                            className="flex-shrink-0"
                            style={{ marginRight: 30 }}
                          >
                            <button
                              type="button"
                              className="custom-btn-page-layout btn text-white mx-3 mb-3"
                              onClick={() => {
                                handleDeleteUnitDetails(index);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                                className="me-2 mb-1"
                              >
                                <g clipPath="url(#clip0_217_1693)">
                                  <path
                                    d="M6.63458 -0.0178223C5.50496 -0.0178223 4.59746 0.920832 4.59746 2.05564V2.30775H2.30765C1.98977 2.30775 1.73073 2.56679 1.73073 2.88468V3.4616H1.15381V4.61545H2.30765V13.2693C2.30765 14.2183 3.08939 15.0001 4.03842 15.0001H10.9615C11.9105 15.0001 12.6923 14.2183 12.6923 13.2693V4.61545H13.8461V3.4616H13.2692V2.88468C13.2692 2.56679 13.0102 2.30775 12.6923 2.30775H10.4025V2.05506C10.4025 0.920832 9.49496 -0.0178223 8.36535 -0.0178223H6.63458ZM6.63458 1.17179H8.36535C8.82919 1.17179 9.21285 1.55025 9.21285 2.05506V2.30775H5.7865V2.05506C5.7865 1.55025 6.17015 1.17237 6.634 1.17237L6.63458 1.17179ZM3.4615 4.61545H6.41823C6.48977 4.62295 6.56073 4.63333 6.63458 4.63333H8.36535C8.43919 4.63333 8.50958 4.62295 8.58169 4.61545H11.5384V13.2693C11.5384 13.5941 11.2863 13.8462 10.9615 13.8462H4.03842C3.71362 13.8462 3.4615 13.5941 3.4615 13.2693V4.61545ZM4.61535 5.76929V12.6924H5.76919V5.76929H4.61535ZM6.92304 5.76929V12.6924H8.07689V5.76929H6.92304ZM9.23073 5.76929V12.6924H10.3846V5.76929H9.23073Z"
                                    fill="#fff"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_217_1693">
                                    <rect width="15" height="15" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              <span className="mt-1">Delete</span>
                            </button>
                          </div>
                        </div>

                        {/* Unit Name & Alias Name */}

                        <div>
                          <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
                            <Col
                              xl={4}
                              lg={6}
                              md={12}
                              sm={12}
                              className={`${isMobile ? "" : "d-flex"}`}
                            >
                              <Col md={4}>
                                <Label
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  Unit Name{" "}
                                  <span className="required_span"> * </span>
                                </Label>
                              </Col>
                              <Col md={8}>
                                <Input
                                  id="exampleEmail"
                                  value={item?.unitName}
                                  name="unitName"
                                  onChange={(e) =>
                                    handleChangeUnitInput(
                                      index,
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  className={`cust-input ${
                                    unitDetailsError[index]["unitName"]
                                      ? "border-danger"
                                      : ""
                                  }`}
                                  type="text"
                                />
                              </Col>
                            </Col>
                            <Col
                              xl={4}
                              lg={6}
                              md={12}
                              sm={12}
                              className={`${isMobile ? "" : "d-flex"}`}
                            >
                              <Col md={4}>
                                <Label
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  Alias Name{" "}
                                  <span className="required_span"> * </span>
                                </Label>
                              </Col>
                              <Col md={8}>
                                <Input
                                  id="exampleEmail"
                                  value={item?.aliasName}
                                  name="aliasName"
                                  onChange={(e) =>
                                    handleChangeUnitInput(
                                      index,
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  className={`cust-input ${
                                    unitDetailsError[index]["aliasName"]
                                      ? "border-danger"
                                      : ""
                                  }`}
                                  type="text"
                                />
                              </Col>
                            </Col>
                          </Row>
                        </div>

                        {/* Address Line 1 & 2 */}
                        <div>
                          <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
                            <Col
                              xl={4}
                              lg={6}
                              md={12}
                              sm={12}
                              className={`${isMobile ? "" : "d-flex"}`}
                            >
                              <Col md={4}>
                                <Label
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  Address Line 1{" "}
                                  <span className="required_span"> * </span>
                                </Label>
                              </Col>
                              <Col sm={8}>
                                <Input
                                  id="exampleEmail"
                                  value={item?.addressLine1}
                                  name="addressLine1"
                                  onChange={(e) =>
                                    handleChangeUnitInput(
                                      index,
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  className={`cust-input ${
                                    unitDetailsError[index]["addressLine1"]
                                      ? "border-danger"
                                      : ""
                                  }`}
                                  type="text"
                                />
                              </Col>
                            </Col>
                            <Col
                              xl={4}
                              lg={6}
                              md={12}
                              sm={12}
                              className={`${isMobile ? "" : "d-flex"}`}
                            >
                              <Col md={4}>
                                <Label
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  Address Line 2{" "}
                                  <span className="required_span"> * </span>
                                </Label>
                              </Col>
                              <Col sm={8}>
                                <Input
                                  id="exampleEmail"
                                  value={item?.addressLine2}
                                  name="addressLine2"
                                  onChange={(e) =>
                                    handleChangeUnitInput(
                                      index,
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  className={`cust-input ${
                                    unitDetailsError[index]["addressLine2"]
                                      ? "border-danger"
                                      : ""
                                  }`}
                                  type="text"
                                />
                              </Col>
                            </Col>
                          </Row>
                        </div>

                        {/* AddressLine 3 & Country */}
                        <div>
                          <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
                            <Col
                              xl={4}
                              lg={6}
                              md={12}
                              sm={12}
                              className={`${isMobile ? "" : "d-flex"}`}
                            >
                              <Col md={4}>
                                <Label
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  Address Line 3{" "}
                                  <span className="required_span"> * </span>
                                </Label>
                              </Col>
                              <Col sm={8}>
                                <Input
                                  id="exampleEmail"
                                  value={item?.addressLine3}
                                  name="addressLine3"
                                  onChange={(e) =>
                                    handleChangeUnitInput(
                                      index,
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  className={`cust-input ${
                                    unitDetailsError[index]["addressLine3"]
                                      ? "border-danger"
                                      : ""
                                  }`}
                                  type="text"
                                />
                              </Col>
                            </Col>
                            <Col
                              xl={4}
                              lg={6}
                              md={12}
                              sm={12}
                              className={`${isMobile ? "" : "d-flex "}`}
                            >
                              <Col md={4}>
                                <Label
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  Country{" "}
                                  <span className="required_span"> * </span>
                                </Label>
                              </Col>
                              <Col sm={8}>
                                <Select
                                  isMulti={false}
                                  onChange={(e) => {
                                    handleChangeUnitInput(index, "country", e);
                                  }}
                                  value={item?.country}
                                  options={item?.countryArray}
                                  // menuPlacement="top"
                                  name="choices-single-default"
                                  id="countryAddCompany"
                                  className={`w-100 ${
                                    unitDetailsError[index]["country"]
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                ></Select>
                              </Col>
                            </Col>
                          </Row>
                        </div>

                        {/* State & Dist */}
                        <div>
                          <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
                            <Col
                              xl={4}
                              lg={6}
                              md={12}
                              sm={12}
                              className={`${isMobile ? "" : "d-flex "}`}
                            >
                              <Col md={4}>
                                <Label
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  State{" "}
                                  <span className="required_span"> * </span>
                                </Label>
                              </Col>
                              <Col sm={8}>
                                <Select
                                  isMulti={false}
                                  onChange={(e) => {
                                    handleChangeUnitInput(index, "state", e);
                                  }}
                                  value={item?.state}
                                  options={item?.stateArray}
                                  // menuPlacement="top"
                                  name="choices-single-default"
                                  id="stateAddCompany"
                                  className={`w-100 ${
                                    unitDetailsError[index]["state"]
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                ></Select>
                              </Col>
                            </Col>
                            <Col
                              xl={4}
                              lg={6}
                              md={12}
                              sm={12}
                              className={`${isMobile ? "" : "d-flex"}`}
                            >
                              <Col md={4}>
                                <Label
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  Dist{" "}
                                  <span className="required_span"> * </span>
                                </Label>
                              </Col>
                              <Col sm={8}>
                                <Select
                                  isMulti={false}
                                  onChange={(e) => {
                                    handleChangeUnitInput(index, "dist", e);
                                  }}
                                  value={item?.dist}
                                  options={item?.districtArray}
                                  // menuPlacement="top"
                                  name="choices-single-default"
                                  id="distAddCompany"
                                  className={`w-100 ${
                                    unitDetailsError[index]["dist"]
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                ></Select>
                              </Col>
                            </Col>
                          </Row>
                        </div>

                        {/* village  & Taluka */}
                        <div>
                          <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
                            <Col
                              xl={4}
                              lg={6}
                              md={12}
                              sm={12}
                              className={`${isMobile ? "" : "d-flex"}`}
                            >
                              <Col md={4}>
                                <Label
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  Taluka{" "}
                                  <span className="required_span"> * </span>
                                </Label>
                              </Col>
                              <Col sm={8}>
                                <Select
                                  isMulti={false}
                                  onChange={(e) => {
                                    handleChangeUnitInput(index, "taluka", e);
                                  }}
                                  value={item?.taluka}
                                  options={item?.talukaArray}
                                  // menuPlacement="top"
                                  name="choices-single-default"
                                  id="talukaAddCompany"
                                  className={`w-100 ${
                                    unitDetailsError[index]["taluka"]
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                ></Select>
                              </Col>
                            </Col>
                            <Col
                              xl={4}
                              lg={6}
                              md={12}
                              sm={12}
                              className={`${isMobile ? "" : "d-flex"}`}
                            >
                              <Col md={4}>
                                <Label
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  Village{" "}
                                  <span className="required_span"> * </span>
                                </Label>
                              </Col>
                              <Col sm={8}>
                                <Select
                                  isMulti={false}
                                  onChange={(e) => {
                                    handleChangeUnitInput(index, "village", e);
                                  }}
                                  value={item?.village}
                                  options={item?.villageArray}
                                  // menuPlacement="top"
                                  name="choices-single-default"
                                  id="villageAddCompany"
                                  className={`w-100 ${
                                    unitDetailsError[index]["village"]
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                ></Select>
                              </Col>
                            </Col>
                          </Row>
                        </div>

                        {/* phone & Pincode */}
                        <div>
                          <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
                            <Col
                              xl={4}
                              lg={6}
                              md={12}
                              sm={12}
                              className={`${isMobile ? "" : "d-flex"}`}
                            >
                              <Col md={4}>
                                <Label
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  Pincode{" "}
                                  <span className="required_span"> * </span>
                                </Label>
                              </Col>
                              <Col sm={8}>
                                <Input
                                  id="exampleEmail"
                                  value={item?.pincode}
                                  name="pincode"
                                  onChange={(e) =>
                                    handleChangeUnitInput(
                                      index,
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  className={`cust-input ${
                                    unitDetailsError[index]["pincode"]
                                      ? "border-danger"
                                      : ""
                                  }`}
                                  type="number"
                                  maxLength={6}
                                />
                              </Col>
                            </Col>

                            <Col
                              xl={4}
                              lg={6}
                              md={12}
                              sm={12}
                              className={`${isMobile ? "" : "d-flex"}`}
                            >
                              <Col md={4}>
                                <Label
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  Mobile No.{" "}
                                  <span className="required_span"> * </span>
                                </Label>
                              </Col>
                              <Col sm={8}>
                                <Input
                                  id="exampleEmail"
                                  minLength={10}
                                  value={item?.mobileNo}
                                  name="mobileNo"
                                  onChange={(e) =>
                                    handleChangeUnitInput(
                                      index,
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  className={`cust-input ${
                                    unitDetailsError[index]["mobileNo"]
                                      ? "border-danger"
                                      : ""
                                  }`}
                                  type="number"
                                />
                              </Col>
                            </Col>
                          </Row>
                        </div>

                        {/* Mobile No  & Phone No. */}
                        <div>
                          <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
                            <Col
                              xl={4}
                              lg={6}
                              md={12}
                              sm={12}
                              className={`${isMobile ? "" : "d-flex"}`}
                            >
                              <Col md={4}>
                                <Label
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  Email{" "}
                                  <span className="required_span"> * </span>
                                </Label>
                              </Col>
                              <Col sm={8}>
                                <Input
                                  id="exampleEmail"
                                  value={item?.email}
                                  name="email"
                                  onChange={(e) =>
                                    handleChangeUnitInput(
                                      index,
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  minLength={10}
                                  className={`cust-input ${
                                    unitDetailsError[index]["email"]
                                      ? "border-danger"
                                      : ""
                                  }`}
                                  type="email"
                                />
                              </Col>
                            </Col>
                          </Row>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  </fieldset>
                </Accordion>
              ))}

              <div className="">
                <Col
                  md={8}
                  className="d-flex justify-content-end align-items-end mt-5"
                >
                  <button
                    type="button"
                    className="custom-btn-page-layout btn text-white "
                    onClick={() => setStep("step2")}
                  >
                    {!isMobile && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        style={{ marginRight: 5 }}
                      >
                        <path
                          d="M15 8C15 9.38447 14.5895 10.7378 13.8203 11.889C13.0511 13.0401 11.9579 13.9373 10.6788 14.4672C9.3997 14.997 7.99224 15.1356 6.63437 14.8655C5.2765 14.5954 4.02922 13.9287 3.05025 12.9497C2.07129 11.9708 1.4046 10.7235 1.1345 9.36563C0.864407 8.00776 1.00303 6.6003 1.53284 5.32122C2.06266 4.04213 2.95986 2.94888 4.11101 2.17971C5.26215 1.41054 6.61553 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8ZM12 7.5H5.925L8.715 4.6965L8 4L4 8L8 12L8.715 11.2865L5.925 8.5H12V7.5Z"
                          fill="white"
                        />
                      </svg>
                    )}
                    Previous
                  </button>
                  <button
                    type="button"
                    className="custom-btn-page-layout btn text-white mx-4"
                    onClick={(e) => handleSubmit(e)}
                  >
                    {loading ? (
                      <ClipLoader color="#75D100" size={25} />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </Col>
              </div>
            </>
          )}
        </form>
      </div>
    </PageLayout>
  );
};

export default AddCompany;
