import React, { useState } from "react";
import {
  Modal,
  Label,
  Input,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
const ViewIncentiveScheme = ({
  setSingleIncentive,
  singleIncentive,
  setShowModal,
}) => {
  const [modalState, setModalState] = useState(true);
  const onCloseModal = () => {
    setShowModal(false);
    setSingleIncentive("");
    setModalState(false);
  };

  return (
    <Modal
      id="showModal"
      size="md"
      isOpen={modalState}
      toggle={() => onCloseModal()}
      centered
    >
      <ModalHeader
        className="p-3 "
        style={{ color: "#1B5E0A", textTransform: "uppercase" }}
        toggle={() => onCloseModal()}
      >
        {singleIncentive?.schemeTypeDetails?.schemeTypeName}
      </ModalHeader>
      <ModalBody className="p-3">
        {singleIncentive?.schemeName && (
          <Row className=" mt-2 g-3">
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-items-center"
            >
              <Col md={5}>
                <Label
                  style={{
                    fontWeight: 600,

                    paddingRight: 10,
                    fontSize: 14,
                  }}
                  for="exampleEmail"
                  md={12}
                >
                  Scheme Name <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col md={7}>
                <Input
                  type="text"
                  value={singleIncentive?.schemeName}
                  name="rate"
                  placeholder="Scheme Name"
                  className="cust-input"
                  disabled
                />
              </Col>
            </Col>
          </Row>
        )}

        {singleIncentive?.schemeTypeDetails?.schemeTypeName && (
          <Row className=" mt-2 g-3">
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-items-center"
            >
              <Col md={5}>
                <Label
                  style={{
                    fontWeight: 600,

                    paddingRight: 10,
                    fontSize: 14,
                  }}
                  for="exampleEmail"
                  md={12}
                >
                  Scheme Type <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col md={7}>
                <Input
                  type="text"
                  value={singleIncentive?.schemeTypeDetails?.schemeTypeName}
                  name="rate"
                  placeholder="Scheme Type"
                  className="cust-input"
                  disabled
                />
              </Col>
            </Col>
          </Row>
        )}

        {singleIncentive?.applicableFrom && (
          <Row className=" mt-2 g-3">
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-items-center"
            >
              <Col md={5}>
                <Label
                  style={{
                    fontWeight: 600,

                    paddingRight: 10,
                    fontSize: 14,
                  }}
                  for="exampleEmail"
                  md={12}
                >
                  Applicable From <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col md={7}>
                <Input
                  type="text"
                  value={singleIncentive?.applicableFrom}
                  name="rate"
                  placeholder="Applicable From"
                  className="cust-input"
                  disabled
                />
              </Col>
            </Col>
          </Row>
        )}

        {singleIncentive?.conditionDetails?.photo && (
          <Row className=" mt-2 g-3">
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex align-items-center"
            >
              <Col md={5}>
                <Label
                  style={{
                    fontWeight: 600,

                    paddingRight: 10,
                    fontSize: 14,
                  }}
                  for="exampleEmail"
                  md={12}
                >
                  {singleIncentive?.schemeTypeDetails?.id === 1
                    ? " Required Photo of Usages of PPE Kit"
                    : singleIncentive?.schemeTypeDetails?.id === 2
                    ? "Required Photo of Usages of CPA chemical"
                    : singleIncentive?.schemeTypeDetails?.id === 3
                    ? "Photo of Certificate Indicating the % >"
                    : singleIncentive?.schemeTypeDetails?.id === 4
                    ? "Photo of Uses Required"
                    : singleIncentive?.schemeTypeDetails?.id === 5
                    ? "Photo of Uses Required"
                    : singleIncentive?.schemeTypeDetails?.id === 6
                    ? "Photo of Uses Required"
                    : singleIncentive?.schemeTypeDetails?.id === 7
                    ? "Return Back of PPE Kit"
                    : singleIncentive?.schemeTypeDetails?.id === 8
                    ? "Return Back of PPE Kit"
                    : ""}

                  <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col md={7}>
                <FormGroup switch>
                  <Input
                    type="switch"
                    style={{ height: 24, width: 44 }}
                    checked={
                      singleIncentive?.conditionDetails?.photo === "True"
                        ? true
                        : false
                    }
                    name="requiredPhoto"
                    disabled
                  />
                </FormGroup>
              </Col>
            </Col>
          </Row>
        )}

        {singleIncentive?.conditionDetails?.returnUsedKit && (
          <Row className=" mt-2 g-3">
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-items-center"
            >
              <Col md={5}>
                <Label
                  style={{
                    fontWeight: 600,

                    paddingRight: 10,
                    fontSize: 14,
                  }}
                  for="exampleEmail"
                  md={12}
                >
                  {singleIncentive?.schemeTypeDetails?.id === 1
                    ? "Return of Used PPE Kit must be required"
                    : singleIncentive?.schemeTypeDetails?.id === 2
                    ? "Return of Used bottle of CPA chemical"
                    :singleIncentive?.schemeTypeDetails?.id === 7
                    ? "Return Back of PPE Kit"
                    : singleIncentive?.schemeTypeDetails?.id === 8
                    ? "Return Back of PPE Kit"
                    : ""}{" "}
                  <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col md={7}>
                <FormGroup switch>
                  <Input
                    type="switch"
                    style={{ height: 24, width: 44 }}
                    checked={
                      singleIncentive?.conditionDetails?.returnUsedKit ===
                      "True"
                        ? true
                        : false
                    }
                    name="returnPpeKit"
                    disabled
                  />
                </FormGroup>
              </Col>
            </Col>
          </Row>
        )}

        {singleIncentive?.conditionDetails?.invoiceCopy && (
          <Row className=" mt-2 g-3">
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-items-center"
            >
              <Col md={5}>
                <Label
                  style={{
                    fontWeight: 600,

                    paddingRight: 10,
                    fontSize: 14,
                  }}
                  for="exampleEmail"
                  md={12}
                >
                  {singleIncentive?.schemeTypeDetails?.id === 7
                    ? "Invoice Copy must be registered at the time of Farmer Registration"
                    : singleIncentive?.schemeTypeDetails?.id === 2
                    ? "Copy Of Invoice Required"
                    : singleIncentive?.schemeTypeDetails?.id === 8
                    ? "Invoice Copy must be registered at the time of Farmer Registration"
                    : ""}{" "}
                  <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col md={7}>
                <FormGroup switch>
                  <Input
                    type="switch"
                    style={{ height: 24, width: 44 }}
                    checked={
                      singleIncentive?.conditionDetails?.returnUsedKit ===
                      "True"
                        ? true
                        : false
                    }
                    name="returnPpeKit"
                    disabled
                  />
                </FormGroup>
              </Col>
            </Col>
          </Row>
        )}

        {singleIncentive?.conditionDetails?.rate && (
          <Row className=" mt-2 g-3">
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-items-center"
            >
              <Col md={5}>
                <Label
                  style={{
                    fontWeight: 600,

                    paddingRight: 10,
                    fontSize: 14,
                  }}
                  for="exampleEmail"
                  md={12}
                >
                  Rate <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col md={7}>
                <Input
                  type="number"
                  value={singleIncentive?.conditionDetails?.rate}
                  name="rate"
                  placeholder="Rate"
                  className="cust-input"
                  disabled
                />
              </Col>
            </Col>
          </Row>
        )}

        {singleIncentive?.conditionDetails?.applicationOnPer?.id && (
          <Row className=" mt-2 g-3">
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-items-center"
            >
              <Col md={5}>
                <Label
                  style={{
                    fontWeight: 600,

                    paddingRight: 10,
                    fontSize: 14,
                  }}
                  for="exampleEmail"
                  md={12}
                >
                  Applicable on Per <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col md={7}>
                <Input
                  type="text"
                  value={
                    singleIncentive?.conditionDetails?.applicationOnPer?.name
                  }
                  name="rate"
                  placeholder="Applicable on Per"
                  className="cust-input"
                  disabled
                />
              </Col>
            </Col>
          </Row>
        )}

        {singleIncentive?.isActive && (
          <Row className=" mt-2 g-3">
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-items-center"
            >
              <Col md={5}>
                <Label
                  style={{
                    fontWeight: 600,

                    paddingRight: 10,
                    fontSize: 14,
                  }}
                  for="exampleEmail"
                  md={12}
                >
                  Is Active <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col md={7}>
                <FormGroup switch>
                  <Input
                    type="switch"
                    style={{ height: 24, width: 44 }}
                    checked={
                      singleIncentive?.isActive === "True" ? true : false
                    }
                    name="requiredPhoto"
                    disabled
                  />
                </FormGroup>
              </Col>
            </Col>
          </Row>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ViewIncentiveScheme;
