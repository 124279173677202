import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PageLayout from "../../Component/PageLayout";
import { Col, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import TableContainer from "../../Component/TableContainer";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DeleteModal from "../../Component/DeleteModal";
import CustomPagination from "../../Component/CustomPagination";
import EditNewKitIssue from "./EditNewKitIssue";
import { ClipLoader } from "react-spinners";
import useBreakPoints from "../../hooks/useBreakPoints";
import ImageCapturePopup from "./ImageCapturePopup";
import { Button, CircularProgress } from "@mui/material";
import { CloudUpload, PhotoCamera, Save } from "@mui/icons-material";
import dayjs from "dayjs";

const AddNewKitIssue = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMobile } = useBreakPoints();
  const location = useLocation();
  const { token } = useSelector((state) => state.layout);
  const [loading, setLoading] = useState(false);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  let initialState = {};

  const [kitList, setKitList] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(5); // default limit
  const [offset, setOffset] = useState(0);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [singleData, setSingleData] = useState();
  const [inputData, setInputData] = useState({
    vendor: "",
    product: "",
    invoiceNo: "",
    invoiceDate: "",
    amount: "",
    remark: "",
    invoiceDocument: "",
  });

  const [showCapturePopup, setShowCapturePopup] = useState(false);
  const [inputDataError, setInputDataError] = useState({
    vendor: false,
    product: false,
    invoiceNo: false,
    invoiceDate: false,
    amount: false,
    invoiceDocument: false,
  });

  // handleChange Input
  const handleChangeInput = (name, value) => {
    if (name === "vendor") {
      setInputData({ ...inputData, [name]: value });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, vendor: false });
      } else {
        setInputDataError({ ...inputDataError, vendor: true });
      }
    } else if (name === "product") {
      setInputData({ ...inputData, [name]: value });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, product: false });
      } else {
        setInputDataError({ ...inputDataError, product: true });
      }
    } else if (name === "invoiceNo") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, invoiceNo: false });
      } else {
        setInputDataError({ ...inputDataError, invoiceNo: true });
      }
    } else if (name === "amount") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, amount: false });
      } else {
        setInputDataError({ ...inputDataError, amount: true });
      }
    } else {
      setInputData({ ...inputData, [name]: value });
    }
  };

  const getVendorListAPi = useCallback(async () => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/vendordrop`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];
        // eslint-disable-next-line
        _data?.vendorDropList
          ?.filter((x) => x.vendorType === "FP")
          .map((er) => {
            arr.push({ label: er?.vendorName, value: er?.id });
          });

        setVendorOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  const getProductListAPi = useCallback(async () => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/productdrop`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];

        // eslint-disable-next-line
        _data?.productList?.map((er) => {
          if (er?.typeName !== "RAW MATERIAL") {
            arr.push({ label: er?.productName, value: er?.id });
          }
        });

        setProductOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  const kitListAPi = useCallback(async () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("farmerId", location?.state?.id);

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/farmerdatabyid`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        setKitList(_data?.farmerData);
        setTotalCount(_data?.paginationDetails?.count);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  const onSubmitModal = () => {
    kitListAPi(limit, offset);
    setEditModal(false);
  };

  useEffect(() => {
    getProductListAPi();
    getVendorListAPi();
    kitListAPi();
  }, [getProductListAPi, getVendorListAPi, kitListAPi]);

  const invoiceDocument = useRef(null);
  const handleInvoiceDocumentUploadBtn = () => invoiceDocument.current.click();
  //   HandleChange Farmer Photo

  const handleImageChange = async (name, value) => {
    if (value) {
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!allowedTypes.includes(value.type)) {
        toast.error("Invalid file type. Please select a JPEG, PNG.");
      } else if (value.size > maxSize) {
        toast.error("File size exceeds the limit of 5MB.");
      } else {
        if (name === "invoiceDocument") {
          setInputDataError({ ...inputDataError, invoiceDocument: false });
        }
        const formData = new FormData();
        formData.append("media", value);

        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/uploadmedia`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          setInputData({
            ...inputData,
            [name]: { url: _data.uploadedURL, name: _data?.filename },
          });
        } else {
          toast.error(_data?.error);
        }

        return { url: "https://httpbin.org/post" };
      }
    }
  };

  //   HandleSubmit Btn

  const handleSubmit = async (e) => {
    if (!inputData?.vendor) {
      setInputDataError({ ...inputDataError, vendor: true });
      toast?.error("Vendor Name is Required!");
    } else if (!inputData?.product) {
      setInputDataError({ ...inputDataError, product: true });
      toast?.error("Product Name is Required!");
    } else if (!inputData?.invoiceNo) {
      setInputDataError({ ...inputDataError, invoiceNo: true });
      toast?.error("Invoice No is Required!");
    } else if (!inputData?.invoiceDate) {
      setInputDataError({ ...inputDataError, invoiceDate: true });
      toast?.error("Invoice Date is Required!");
    } else if (!inputData?.amount) {
      setInputDataError({ ...inputDataError, amount: true });
      toast?.error("Amount is Required!");
    }
    // else if (!inputData?.invoiceDocument) {
    //   setInputDataError({ ...inputDataError, invoiceDocument: true });
    //   toast?.error("Invoice Document is Required!");
    // }
    else {
      setSubmitLoading(true);

      let formData = new FormData();
      formData.append("farmerId", location?.state?.id);
      formData.append("vendorId", inputData?.vendor?.value);
      formData.append("productId", inputData?.product?.value);
      formData.append("invoiceNo", inputData?.invoiceNo);
      formData.append("invoiceDate", inputData?.invoiceDate);
      formData.append("amount", inputData?.amount);
      formData.append("remark", inputData?.remark);
      formData.append("invoiceDoc", inputData?.invoiceDocument?.url);

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/addkitissue`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setSubmitLoading(false);
        }

        if (_data?.status) {
          setInputData({
            vendor: "",
            product: "",
            invoiceNo: "",
            invoiceDate: "",
            amount: "",
            remark: "",
            invoiceDocument: "",
          });
          setSubmitLoading(false);
          kitListAPi();
        } else {
          setSubmitLoading(false);

          toast.error(_data?.message);
        }
      } catch (error) {
        console.log(error);
        setSubmitLoading(false);
      }
    }
  };

  //   colums Array
  const column = useMemo(
    () => [
      {
        Header: "No",
        accessor: "id",
        filterable: false,
      },
      {
        Header: "Vendor Name",
        accessor: "vendor.vendorName",
        filterable: false,
      },
      {
        Header: "Product Name",
        accessor: "product.productName",
        filterable: false,
      },
      {
        Header: "Invoice No",
        accessor: "invoiceNo",
        filterable: false,
      },
      {
        Header: "Date",
        accessor: "invoiceDate",
        filterable: false,
      },
      {
        Header: "Amount",
        accessor: "amount",
        filterable: false,
      },
      {
        Header: "View Invoice",

        Cell: (cellProps) => {
          return (
            <div>
              <Link
                target="_blank"
                to={cellProps?.row?.original?.invoiceDoc}
                className="border-0"
                style={{ background: "transparent" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    d="M4.5 6.995H4V7.995H4.5V6.995ZM10.5 7.995H11V6.995H10.5V7.995ZM4.5 9.5H4V10H4.5V9.5ZM10.5 9.5V10H11V9.5H10.5ZM4.5 5.5V5H4V5.5H4.5ZM10.5 5.5H11V5H10.5V5.5ZM13.5 3.5H14V3.293L13.854 3.146L13.5 3.5ZM10.5 0.5L10.854 0.146L10.707 0H10.5V0.5ZM4.5 7.995H10.5V6.995H4.5V7.995ZM4.5 10H10.5V9H4.5V10ZM4.5 6H10.5V5H4.5V6ZM12.5 14H2.5V15H12.5V14ZM2 13.5V1.5H1V13.5H2ZM13 3.5V13.5H14V3.5H13ZM2.5 1H10.5V0H2.5V1ZM10.146 0.854L13.146 3.854L13.854 3.146L10.854 0.146L10.146 0.854ZM2.5 14C2.36739 14 2.24021 13.9473 2.14645 13.8536C2.05268 13.7598 2 13.6326 2 13.5H1C1 13.8978 1.15804 14.2794 1.43934 14.5607C1.72064 14.842 2.10218 15 2.5 15V14ZM12.5 15C12.8978 15 13.2794 14.842 13.5607 14.5607C13.842 14.2794 14 13.8978 14 13.5H13C13 13.6326 12.9473 13.7598 12.8536 13.8536C12.7598 13.9473 12.6326 14 12.5 14V15ZM2 1.5C2 1.36739 2.05268 1.24021 2.14645 1.14645C2.24021 1.05268 2.36739 1 2.5 1V0C2.10218 0 1.72064 0.158035 1.43934 0.43934C1.15804 0.720644 1 1.10218 1 1.5H2ZM4 5.5V9.5H5V5.5H4ZM7 5.5V9.5H8V5.5H7ZM10 5.5V9.5H11V5.5H10ZM4 4H7V3H4V4ZM8 12H11V11H8V12Z"
                    fill="#03A8DC"
                  />
                </svg>
              </Link>
            </div>
          );
        },
      },
      {
        id: "Action",
        Header: () => (
          <span
            className="d-flex justify-content-center"
            style={{ textAlign: "center" }}
          >
            Action
          </span>
        ),
        Cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <div className="hstack gap-2">
                {/* Edit Button */}
                <button
                  className="border-0"
                  style={{ background: "transparent" }}
                  onClick={() => {
                    setEditModal(true);
                    setSingleData(cellProps.row.original);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_32_318)">
                      <path
                        d="M6.87549 2.5H2.50049C2.16897 2.5 1.85103 2.6317 1.6166 2.86612C1.38218 3.10054 1.25049 3.41848 1.25049 3.75V12.5C1.25049 12.8315 1.38218 13.1495 1.6166 13.3839C1.85103 13.6183 2.16897 13.75 2.50049 13.75H11.2505C11.582 13.75 11.9 13.6183 12.1344 13.3839C12.3688 13.1495 12.5005 12.8315 12.5005 12.5V8.125"
                        stroke="#3E7531"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.563 1.56249C11.8116 1.31385 12.1489 1.17416 12.5005 1.17416C12.8521 1.17416 13.1893 1.31385 13.438 1.56249C13.6866 1.81113 13.8263 2.14836 13.8263 2.49999C13.8263 2.85162 13.6866 3.18885 13.438 3.43749L7.50049 9.37499L5.00049 9.99999L5.62549 7.49999L11.563 1.56249Z"
                        stroke="#3E7531"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_32_318">
                        <rect
                          width="15"
                          height="15"
                          fill="white"
                          transform="translate(0.000488281)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                {/* Delete Button */}
                <button
                  className="border-0"
                  style={{ background: "transparent" }}
                  onClick={() => {
                    setDeleteModal(true);
                    setDeleteId(cellProps.row.original.id);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_32_330)">
                      <path
                        d="M6.63507 -0.0178833C5.50545 -0.0178833 4.59795 0.92077 4.59795 2.05558V2.30769H2.30814C1.99026 2.30769 1.73122 2.56673 1.73122 2.88462V3.46154H1.1543V4.61539H2.30814V13.2692C2.30814 14.2183 3.08987 15 4.03891 15H10.962C11.911 15 12.6928 14.2183 12.6928 13.2692V4.61539H13.8466V3.46154H13.2697V2.88462C13.2697 2.56673 13.0106 2.30769 12.6928 2.30769H10.403V2.055C10.403 0.920771 9.49545 -0.0178833 8.36584 -0.0178833H6.63507ZM6.63507 1.17173H8.36584C8.82968 1.17173 9.21334 1.55019 9.21334 2.055V2.30769H5.78699V2.055C5.78699 1.55019 6.17064 1.17231 6.63449 1.17231L6.63507 1.17173ZM3.46199 4.61539H6.41872C6.49026 4.62289 6.56122 4.63327 6.63507 4.63327H8.36584C8.43968 4.63327 8.51007 4.62289 8.58218 4.61539H11.5389V13.2692C11.5389 13.594 11.2868 13.8462 10.962 13.8462H4.03891C3.7141 13.8462 3.46199 13.594 3.46199 13.2692V4.61539ZM4.61584 5.76923V12.6923H5.76968V5.76923H4.61584ZM6.92353 5.76923V12.6923H8.07737V5.76923H6.92353ZM9.23122 5.76923V12.6923H10.3851V5.76923H9.23122Z"
                        fill="#EC0505"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_32_330">
                        <rect
                          width="15"
                          height="15"
                          fill="white"
                          transform="translate(0.000488281)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>
          );
        },
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );
  // handleDelete Btn
  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    let formData = new FormData();
    formData.append("id", deleteId);
    formData.append("isDelete", "Yes");

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/deletekitissue`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        kitListAPi(limit, offset);
        setDeleteModal(false);
        toast?.success(_data?.message);
        setDeleteLoading(false);
      } else {
        setDeleteLoading(false);
        setDeleteModal(false);

        toast.error(_data?.message);
      }
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
    }
  };

  const getCheckOptions = (productOptions) => {
    let arr = [];
    // eslint-disable-next-line
    productOptions?.map((er) => {
      let check = kitList?.[0]?.kitDetails?.find(
        (ed) => ed?.product?.id === er?.value
      );
      // if (!check) {
      //   arr?.push(er);
      // }
      arr?.push(er);
    });

    return arr;
  };

  return (
    <PageLayout
      title={"KIT Issue & Invoice Updation"}
      loading={loading}
      backBtnTitle={"Back to kit issue list"}
      onClick={() => navigate("/kitissue")}
    >
      {loading ? (
        ""
      ) : (
        <>
          <div className="mt-5" style={{ margin: 40 }}>
            <div>
              <Row className={`${isMobile ? "" : " mt-2 g-3"}`}>
                <Col
                  lg={4}
                  md={12}
                  sm={12}
                  className={`${isMobile ? "" : "d-flex"}`}
                >
                  <Col md={3}>
                    <Label
                      className="me-3"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      Vendor Name<span className="required_span">*</span>
                    </Label>
                  </Col>
                  <Col sm={8}>
                    <Select
                      isMulti={false}
                      onChange={(e) => {
                        handleChangeInput("vendor", e);
                      }}
                      value={inputData?.vendor}
                      options={vendorOptions}
                      // menuPlacement="top"
                      name="choices-single-default"
                      id="countryAddCompany"
                      className={`w-100 ${
                        inputDataError?.vendor ? "border border-danger " : ""
                      }`}
                    ></Select>
                  </Col>
                </Col>
                <Col
                  lg={4}
                  md={12}
                  sm={12}
                  className={`${isMobile ? "" : "d-flex"}`}
                >
                  <Col md={3}>
                    <Label
                      className="me-3"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      Product Name<span className="required_span">*</span>
                    </Label>
                  </Col>
                  <Col sm={8}>
                    <Select
                      isMulti={false}
                      onChange={(e) => {
                        handleChangeInput("product", e);
                      }}
                      value={inputData?.product}
                      options={getCheckOptions(productOptions)}
                      // menuPlacement="top"
                      name="choices-single-default"
                      id="countryAddCompany"
                      className={`w-100 ${
                        inputDataError?.product ? "border border-danger " : ""
                      }`}
                    ></Select>
                  </Col>
                </Col>
                <Col
                  lg={4}
                  md={12}
                  sm={12}
                  className={`${isMobile ? "" : "d-flex"}`}
                >
                  <Col md={3}>
                    <Label
                      className="me-3"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      Invoice No.<span className="required_span">*</span>
                    </Label>
                  </Col>
                  <Col sm={8}>
                    <Input
                      id="exampleEmail"
                      value={inputData?.invoiceNo}
                      name="invoiceNo"
                      onChange={(e) =>
                        handleChangeInput(e.target.name, e.target.value)
                      }
                      className={`cust-input ${
                        inputDataError?.invoiceNo ? "border-danger" : ""
                      }`}
                      type="number"
                    />
                  </Col>
                </Col>
              </Row>
            </div>
            <div>
              <Row className={`${isMobile ? "" : " mt-2 g-3"}`}>
                <Col
                  lg={4}
                  md={12}
                  sm={12}
                  className={`${isMobile ? "" : "d-flex"}`}
                >
                  <Col md={3}>
                    <Label
                      className="me-3"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      Invoice Date<span className="required_span">*</span>
                    </Label>
                  </Col>
                  <Col sm={8}>
                    <Flatpickr
                      className={`form-control ${
                        inputDataError?.invoiceDate
                          ? "border border-danger "
                          : ""
                      }`}
                      options={{
                        dateFormat: "Y-m-d",
                        // minDate: new Date().setMonth(new Date().getMonth() - 1),
                        maxDate: new Date(),
                      }}
                      value={inputData?.invoiceDate}
                      onChange={(date, dateStr) => {
                        setInputData({ ...inputData, invoiceDate: dateStr });
                        if (dateStr?.length > 0) {
                          setInputDataError({
                            ...inputDataError,
                            invoiceDate: false,
                          });
                        } else {
                          setInputDataError({
                            ...inputDataError,
                            invoiceDate: true,
                          });
                        }
                      }}
                    />
                  </Col>
                </Col>
                <Col
                  lg={4}
                  md={12}
                  sm={12}
                  className={`${isMobile ? "" : "d-flex"}`}
                >
                  <Col md={3}>
                    <Label
                      className="me-3"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      Amount<span className="required_span">*</span>
                    </Label>
                  </Col>
                  <Col sm={8}>
                    <Input
                      id="exampleEmail"
                      value={inputData?.amount}
                      name="amount"
                      onChange={(e) =>
                        handleChangeInput(e.target.name, e.target.value)
                      }
                      className={`cust-input ${
                        inputDataError?.amount ? "border-danger" : ""
                      }`}
                      type="number"
                    />
                  </Col>
                </Col>
                <Col
                  lg={4}
                  md={12}
                  sm={12}
                  className={`${isMobile ? "" : "d-flex"}`}
                >
                  <Col md={3}>
                    <Label
                      className="me-3"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      Remark
                    </Label>
                  </Col>
                  <Col sm={8}>
                    <Input
                      id="exampleEmail"
                      value={inputData?.remark}
                      name="remark"
                      onChange={(e) =>
                        handleChangeInput(e.target.name, e.target.value)
                      }
                      className="cust-input"
                      type="text"
                    />
                  </Col>
                </Col>
              </Row>
            </div>

            <div>
              <Row className={`${isMobile ? "" : " g-3"} mt-2 `}>
                <Col lg={3} md={3} sm={12} className={`${isMobile ? "" : ""}`}>
                  <Button
                    type="button"
                    onClick={handleInvoiceDocumentUploadBtn}
                    className={`custom-btn-page-layout custom-btn-upload btn-sm ${
                      inputDataError?.invoiceDocument
                        ? " border border-danger"
                        : ""
                    }`}
                    startIcon={<CloudUpload />}
                    fullWidth
                  >
                    {`Upload Invoice Document`}
                  </Button>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    accept="image/png, image/jpeg , image/jpg"
                    name="invoiceDocument"
                    ref={invoiceDocument}
                    onChange={(e) =>
                      handleImageChange(e.target.name, e.target.files[0])
                    }
                  />

                  {/* <button
                      type="button"
                      onClick={handleInvoiceDocumentUploadBtn}
                      className={`${
                        inputDataError?.invoiceDocument
                          ? "border border-danger"
                          : ""
                      }  `}
                      style={{
                        border: "none",
                        color: "white",
                        padding: 8,
                        background:
                          "linear-gradient(180deg, #C1C1C1 0%, #6D6D6D 100%)",
                        borderRadius: 5
                      }}
                    >
                      Upload
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        style={{ height: 16, width: 16, marginLeft: 2 }}
                      >
                        <path
                          d="M4 12.6667H12"
                          stroke="white"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8 10H9.33333V8H11L8 5M8 10H6.66667V8H5L8 5"
                          stroke="white"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>

                    <input
                      style={{ display: "none" }}
                      type="file"
                      accept="image/png, image/jpeg , image/jpg,application/pdf "
                      name="invoiceDocument"
                      ref={invoiceDocument}
                      onChange={(e) =>
                        handleImageChange(e.target.name, e.target.files[0])
                      }
                    />

                    <span
                      className="mx-3"
                      style={{ fontWeight: 500, fontSize: 10 }}
                    >
                      {inputData?.invoiceDocument
                        ? inputData?.invoiceDocument?.name
                        : "Upload copy of invoice (PDF/image)"}
                    </span> */}
                </Col>
                <Col lg={3}>
                  <Button
                    type="button"
                    className="custom-btn-page-layout custom-btn-upload btn-sm"
                    startIcon={<PhotoCamera />}
                    onClick={() => setShowCapturePopup(true)}
                    fullWidth
                  >
                    {`Capture Invoice`}
                  </Button>
                </Col>
                <Col
                  lg={3}
                  md={3}
                  sm={12}
                  className={`d-flex ${
                    isMobile ? "" : "justify-content-end align-items-center"
                  }`}
                >
                  <Button
                    type="button"
                    className="custom-btn-page-layout btn text-white mx-3"
                    onClick={(e) => handleSubmit(e)}
                    disabled={submitLoading}
                    startIcon={
                      submitLoading ? (
                        <CircularProgress size={15} color="warning" />
                      ) : (
                        <Save />
                      )
                    }
                    fullWidth
                  >
                    {`Submit`}
                  </Button>
                  {/* <button
                    type="button"
                    className="custom-btn-page-layout btn text-white mx-3 me-5"
                    onClick={(e) => handleSubmit(e)}
                    disabled={submitLoading}
                  >
                    {submitLoading ? (
                      <ClipLoader color="#75D100" size={25} />
                    ) : (
                      "Submit"
                    )}
                  </button> */}
                </Col>
              </Row>
            </div>

            {inputData.invoiceDocument && (
              <Row>
                <Col lg={3} md={3} sm={12}>
                  <img src={inputData.invoiceDocument.url} width={200} alt="" />
                </Col>
              </Row>
            )}
          </div>

          <Row className={`${isMobile ? "" : " mt-2 g-3"}`}>
            <Col md={12}>
              {/* serach View  */}
              <div className="" style={{ margin: 20 }}>
                <InputGroup className="search-inp">
                  <Input placeholder="Search" />
                  <InputGroupText>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M15.1987 13.8333L10.9037 9.53833C11.6977 8.33158 12.0157 6.87314 11.7962 5.44537C11.5766 4.01761 10.8351 2.72208 9.71524 1.80963C8.59536 0.897181 7.17674 0.432709 5.73406 0.506144C4.29138 0.579579 2.92726 1.1857 1.90581 2.20715C0.884365 3.22859 0.278248 4.59271 0.204813 6.03539C0.131379 7.47807 0.595851 8.89669 1.5083 10.0166C2.42075 11.1365 3.71628 11.878 5.14404 12.0975C6.57181 12.317 8.03025 11.999 9.237 11.205L13.532 15.5L15.1987 13.8333ZM1.86534 6.33333C1.86534 4.03584 3.7345 2.16667 6.032 2.16667C8.3295 2.16667 10.1987 4.03584 10.1987 6.33333C10.1987 8.63083 8.3295 10.5 6.032 10.5C3.7345 10.5 1.86534 8.63083 1.86534 6.33333Z"
                        fill="#999999"
                      />
                    </svg>
                  </InputGroupText>
                </InputGroup>
              </div>

              {/* Table View */}
              <div
                className="d-grid"
                style={{ margin: "0 20px", border: "1px solid #DEE2E6" }}
              >
                <TableContainer
                  columns={column}
                  data={kitList?.[0]?.kitDetails || []}
                  isGlobalFilter={false}
                  isAddUserList={false}
                  tableClass={"table-sm"}
                  divClass={"table-responsive"}
                  initialState={initialState}
                />
                <CustomPagination
                  apiCallFunction={kitListAPi}
                  rowDetails={kitList}
                  totalCount={totalCount}
                  limit={limit}
                  offset={offset}
                  setLimit={setLimit}
                  setOffset={setOffset}
                />
              </div>
              <DeleteModal
                deleteModal={deleteModal}
                handleDeleteRequest={handleDeleteRequest}
                setDeleteModal={setDeleteModal}
                deleteLoading={deleteLoading}
              />
              {editModal && (
                <EditNewKitIssue
                  editModal={editModal}
                  onCloseModal={() => setEditModal(false)}
                  onSubmitModal={onSubmitModal}
                  singleData={singleData}
                />
              )}
            </Col>
          </Row>

          {showCapturePopup && (
            <ImageCapturePopup
              onClose={() => setShowCapturePopup(false)}
              onCapture={(imgData) =>
                handleImageChange("invoiceDocument", imgData)
              }
              showCapturePopup={showCapturePopup}
              filename={`${location?.state?.farmerCode}_Kit_${dayjs(
                new Date()
              ).unix()}`}
              //onFilebase64={onFileBase64}
            />
          )}
        </>
      )}
    </PageLayout>
  );
};

export default AddNewKitIssue;
