import React, { useCallback, useEffect, useState } from "react";
import { Cancel, Check, PhotoCamera, Save, Scanner } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField
} from "@mui/material";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { Col, Form, Input, Row } from "reactstrap";

import { toast } from "react-toastify";
import FileViewer from "react-file-viewer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "../../Component/PageLayout";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import VirtualizeAutoComplete from "../../common/VirtualizedAutoComplete";
import { useFarmerDocumentHooks } from "./useFarmerHooks";
import { textFieldProps } from "../../common/commonControlProps";
import { ErrorMessage } from "../../common/Error";
import FileToBase64 from "../../common/FileToBase64";
import TableContainer from "../../Component/TableContainer";
import DeleteModal from "../../Component/DeleteModal";

import ImageCapturePopup from "./ImageCapturePopup";
import dayjs from "dayjs";
import ModalContainer from "../../Component/ModalContainer";
import { green, red } from "@mui/material/colors";

const FarmerDocuments = () => {
  const navigate = useNavigate();
  const {
    layout: { token }
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [inputData, setInputData] = useState();
  const [showCapturePopup, setShowCapturePopup] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);
  const [pendingDocs, setPendingDocs] = useState([]);
  const [requiredDocs, setRequiredDocs] = useState([]);

  const handleChangeCheckbox = (name, value) => {
    setInputData({ ...inputData, [name]: value ? "True" : "False" });
  };
  const handleChangeRemark = (name, value) => {
    setInputData({ ...inputData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let formData = new FormData();

    formData.append("id", inputData?.id);
    formData.append("panRemark", inputData?.panRemark);
    formData.append("panStatus", inputData?.panStatus);
    formData.append("chequeRemark", inputData?.chequeRemark);
    formData.append("chequeStatus", inputData?.chequeStatus);
    formData.append("aadharRemark", inputData?.aadharRemark);
    formData.append("aadharStatus", inputData?.aadharStatus);

    if (inputData?.panImg !== "No") {
      if (
        inputData?.panStatus === "True" &&
        inputData?.chequeStatus === "True" &&
        inputData?.aadharStatus === "True"
      ) {
        formData.append("status", "True");
      } else {
        formData.append("status", "False");
      }
    } else {
      if (
        inputData?.chequeStatus === "True" &&
        inputData?.aadharStatus === "True"
      ) {
        formData.append("status", "True");
      } else {
        formData.append("status", "False");
      }
    }

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/adddocumentverification`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: formData
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        toast?.success(_data?.message);
        setLoading(false);
        navigate("/farmerdashboard");
      } else {
        setLoading(false);
        toast?.error(_data?.message);
      }
    } catch (error) {
      setLoading(false);
      toast?.error(error);
      console.log(error);
    }
  };

  const getfarmerDocument = useCallback(async (farmerId) => {
    setLoading(true);
    let formData = new FormData();

    formData.append("farmerId", farmerId);

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/farmerdocuments`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`
          },

          body: formData
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);

        setInputData(_data?.farmerDocumentData[0]?.documentDetails?.[0]);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  const scan = () => {
    window.scanner.scan(displayImagesOnPage, {
      use_asprise_dialog: true, // Whether to use Asprise Scanning Dialog
      show_scanner_ui: true, // Whether scanner UI should be shown
      twain_cap_setting: {
        // Optional scanning settings
        ICAP_PIXELTYPE: "TWPT_RGB" // Color
      },
      output_settings: [
        {
          type: "return-base64",
          format: "pdf"
        }
      ]
    });
  };

  const bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
  };

  const displayImagesOnPage = (successful, mesg, response) => {
    if (!successful) {
      // On error
      console.error("Failed: " + mesg);
      return;
    }
    if (
      successful &&
      mesg != null &&
      mesg.toLowerCase().indexOf("user cancel") >= 0
    ) {
      // User cancelled.
      console.info("User cancelled");
      return;
    }
    const scannedImages = window.scanner.getScannedImages(
      response,
      true,
      false
    ); // returns an array of ScannedImage
    console.log("Items ", scannedImages);

    if (scannedImages?.length > 0) {
      let simg = scannedImages[0]; //[];

      let obj = {
        name: `${dayjs(new Date()).unix()}.pdf`,
        type: "application/pdf",
        base64: simg?.src,
        size: bytesToSize(filesizeinBytes(simg?.src))
      };

      onFileChange(obj);
      // simg.push(obj);
      // handleScannedImages([...simg]);
    }
    // for (
    //   var i = 0;
    //   scannedImages instanceof Array && i < scannedImages.length;
    //   i++
    // ) {
    //   var scannedImage = scannedImages[i];
    //   var elementImg = window.scanner.createDomElementFromModel({
    //     name: "img",
    //     attributes: {
    //       class: "scanned",
    //       src: scannedImage.src
    //     }
    //   });
    // }
  };
  const filesizeinBytes = (base64) => {
    const base64String = base64.replaceAll("=", "");
    const bytes = base64String.length * (3 / 4);

    return bytes;
  };

  useEffect(() => {
    if (location?.state) {
      getfarmerDocument(location?.state?.id);
    } else {
      navigate("/documentverification");
    }
    // eslint-disable-next-line
  }, [location?.state]);

  const {
    errors,
    formData,
    register,
    onSubmit,
    onCancel,
    deleteModal,
    fileViewer,
    onFileChange,
    handleChange,
    deleteLoading,
    setDeleteModal,
    onDocumentChange,
    document_columns,
    document_master_list,
    farmer_document_list,
    delete_farmer_document,
    handleScannedImages,
    handleSubmit: handleSubmitNew
  } = useFarmerDocumentHooks();

  const handleCapture = (imageData) => {};

  const onFileBase64 = (obj) => {
    onFileChange(obj);
  };

  const onExitClick = () => {
    navigate("/documentverification");
  };

  useEffect(() => {
    const ownership = location?.state?.ownership || 0;

    const requiredDoc =
      ownership > 0
        ? document_master_list.filter((x) => x.required > 0).map((x) => x.id)
        : document_master_list.filter((x) => x.required === 1).map((x) => x.id);

    const _requiredDocs =
      ownership > 0
        ? document_master_list.filter((x) => x.required > 0)
        : document_master_list.filter((x) => x.required === 1);

    setRequiredDocs([..._requiredDocs]);
    const uploadedDoc = farmer_document_list?.map((x) => x.documentid);

    const pendingDoc = requiredDoc.filter((x) => !uploadedDoc.includes(x));

    console.log("_requiredDocs", _requiredDocs);
    console.log("pendingDoc", pendingDoc);
    if (pendingDoc.length > 0) {
      const pd = document_master_list.filter((x) => pendingDoc.includes(x.id));

      setPendingDocs([...pd]);
    } else setPendingDocs([]);
  }, [farmer_document_list, document_master_list, location?.state?.ownership]);

  return (
    <PageLayout
      title={"Farmer Document Verification"}
      loading={loading}
      backBtnTitle={"Back to Document Verification List"}
      onClick={() => {
        // const requiredDoc = document_master_list
        //   .filter((x) => x.required === 1)
        //   .map((x) => x.id);
        // const uploadedDoc = farmer_document_list.map((x) => x.documentid);

        // const pendingDoc = requiredDoc.filter((x) => !uploadedDoc.includes(x));

        // console.log(uploadedDoc,pendingDoc)
        if (pendingDocs.length > 0) {
          // const pd = document_master_list.filter((x) =>
          //   pendingDocs.includes(x.id)
          // );

          // setPendingDocs([...pd]);

          setShowExitModal(true);
        } else {
          navigate("/documentverification");
        }
      }}
    >
      <Form className="m-4" onSubmit={handleSubmitNew(onSubmit)}>
        <Row>
          <Col lg={3} md={3} sm={12}>
            <VirtualizeAutoComplete
              name="document"
              size="small"
              options={document_master_list}
              clearText={false}
              value={formData?.selectedDocument}
              onChange={onDocumentChange}
              renderInput={(params) => (
                <TextField
                  {...register("selectedDocument")}
                  {...params}
                  label="Document"
                  error={errors?.selectedDocument}
                  {...textFieldProps}
                />
              )}
            />
            {errors?.selectedDocument && (
              <ErrorMessage message={errors?.selectedDocument?.message} />
            )}
          </Col>

          <Col lg={3} md={3} sm={12}>
            <TextField
              disabled
              label={`File name`}
              helperText={"(Max size 150 KB)"}
              InputLabelProps={{ shrink: true }}
              value={(formData.file && formData.file?.name) || ""}
              {...textFieldProps}
            />
          </Col>
          <Col lg={2} md={2} sm={12} className="d-flex align-items-center">
            <FileToBase64 onChange={onFileChange} />
          </Col>
          <Col lg={1} md={1} sm={12} className="d-flex align-items-center">
            <FormControlLabel
              control={
                <Checkbox
                  name="isverified"
                  color="success"
                  size="small"
                  checked={formData.isverified}
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.checked);
                  }}
                />
              }
              label="Verified"
              labelPlacement="top"
            />
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              name="remarks"
              value={formData.remarks}
              onChange={(e) => {
                handleChange(e.target.name, e.target.value);
              }}
              placeholder="Remarks"
              label="Remarks"
              {...textFieldProps}
            ></TextField>
          </Col>
        </Row>
        {formData?.file && (
          <Row>
            <Col lg={3} md={3} sm={12}>
              <img src={formData?.file?.base64} width={200} alt="" />
            </Col>
          </Row>
        )}
        {formData?.scannedimages.length > 0 && (
          <Row>
            {formData?.scannedimages?.map((item) => {
              return (
                <Col lg={3} md={3} sm={12}>
                  <img src={item?.base64} width={200} alt="" />
                </Col>
              );
            })}
          </Row>
        )}
        <Row className="mt-2 g-2">
          <Col lg={1} md={1} sm={12}>
            <Button
              type="button"
              className="custom-btn-page-layout custom-btn-info btn-sm"
              startIcon={<Scanner />}
              onClick={() => scan()}
              fullWidth
            >
              {`Scan`}
            </Button>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <Button
              type="button"
              className="custom-btn-page-layout custom-btn-upload btn-sm"
              startIcon={<PhotoCamera />}
              onClick={() => setShowCapturePopup(true)}
              fullWidth
            >
              {`Capture Photo`}
            </Button>
          </Col>
          <Col lg={1} md={1} sm={12}>
            <Button
              className="custom-btn-page-layout text-white btn-sm"
              startIcon={<Save />}
              fullWidth
              type="submit"
            >
              {` Save`}
            </Button>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <Button
              type="button"
              className="custom-btn-page-layout custom-btn-danger btn-sm"
              startIcon={<Cancel />}
              onClick={onCancel}
              fullWidth
            >
              {`Cancel`}
            </Button>
          </Col>
        </Row>
      </Form>
      <Row className="mx-2">
        <Col md={12} lg={12} sm={12}>
          {requiredDocs.length > 0 && (
            <List>
              {requiredDocs?.map((x) => {
                const isUploaded = pendingDocs.filter(
                  (y) => y.id === x.id
                ).length;

                return (
                  <ListItem style={{ padding: 0 }}>
                    <ListItemIcon>
                      {isUploaded > 0 ? (
                        <Cancel sx={{ color: red[500] }} />
                      ) : (
                        <Check sx={{ color: green[500] }} />
                      )}
                    </ListItemIcon>
                    <ListItemText>{x.document}</ListItemText>
                  </ListItem>
                );
              })}
            </List>
          )}
        </Col>
      </Row>
      <Row className="m-2">
        <Col md={12}>
          <div className="d-grid" style={{ border: "1px solid #DEE2E6" }}>
            <TableContainer
              columns={document_columns}
              data={farmer_document_list}
              isGlobalFilter={true}
              showColumnFilters={true}
              isAddUserList={false}
              tableClass={"table-sm"}
              showPagination={true}
              initialState={{ pageSize: 20, pageIndex: 0 }}
              divClass={"table-responsive"}
            />
          </div>
        </Col>
        {/* <Col md={6}>
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={farmer_document_list.map((x) => {
              return { uri: `${keys().ServicePath}/${x.documentpath}` };
            })}
            theme={{
              primary: "#5296d8",
              secondary: "#ffffff",
              tertiary: "#5296d899",
              text_primary: "#ffffff",
              text_secondary: "#5296d8",
              text_tertiary: "#00000099",
              disableThemeScrollbar: false,
            }}
          />

          {fileViewer && (
            <FileViewer
              fileType={fileViewer?.filename?.split(".").pop()}
              filePath={`${keys().ServicePath}/${fileViewer?.documentpath}`}
            />
          )}
        </Col> */}
      </Row>
      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={delete_farmer_document}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
        centered={true}
      />
      {showCapturePopup && (
        <ImageCapturePopup
          onClose={() => setShowCapturePopup(false)}
          onCapture={handleCapture}
          showCapturePopup={showCapturePopup}
          onFilebase64={onFileBase64}
        />
      )}

      <ModalContainer
        modalState={showExitModal}
        onCloseModal={() => setShowExitModal(false)}
        loading={loading}
        title={"Confirm Exit"}
        onClick={onExitClick}
      >
        <Row className="m-2">
          <Col lg={12} md={12} sm={12}>
            {`Below are the mandatory list of documents which are pending to upload`}
          </Col>
          <Col lg={12} md={12} sm={12}>
            <List>
              {pendingDocs.map((x) => (
                <ListItem style={{ padding: 0 }}>
                  <ListItemIcon>
                    <Cancel sx={{ color: red[500] }} />
                  </ListItemIcon>
                  <ListItemText>{x.document}</ListItemText>
                </ListItem>
              ))}{" "}
            </List>
          </Col>
          <Col>{`Are you sure you want to exit without uploading ?`}</Col>
        </Row>
      </ModalContainer>
    </PageLayout>
  );
};

export default FarmerDocuments;

{
  /* <div
className="row m-4"
style={{ margin: "0 20px 20px 20px", border: "1px solid #DEE2E6" }}
>
<table className="table-sm table table-hover">
  <thead>
    <tr role="row">
      <th>{`Sr #`}</th>
      <th>{`List of documents`}</th>
      <th>{`View Document`}</th>
      <th>{`Verify`}</th>
      <th>{`Remarks`}</th>
    </tr>
  </thead>
  <tbody>
    {inputData?.panImg !== "No" && (
      <tr>
        <td className="text-center">1</td>
        <td>Pan Card</td>
        <td>
          <Link
            target="_blank"
            to={inputData?.panImg}
            className="align-center"
          >
            <Article fontSize="medium" color="info" />
          </Link>
        </td>
        <td>
          <Checkbox
            name="panStatus"
            color="success"
            size="small"
            checked={inputData?.panStatus === "True" ? true : false}
            onChange={(e) => {
              handleChangeCheckbox(e.target.name, e.target.checked);
            }}
          />
        </td>
        <td>
          <TextField
            name="panRemark"
            value={
              inputData?.panRemark === "null"
                ? ""
                : inputData?.panRemark
            }
            onChange={(e) => {
              handleChangeRemark(e.target.name, e.target.value);
            }}
            size="small"
            variant="standard"
            placeholder="Remarks"
            fullWidth
          ></TextField>
        </td>
      </tr>
    )}
    <tr>
      <td className="text-center">
        {inputData?.panImg !== "No" ? 2 : 1}
      </td>
      <td>Aadhar Card</td>
      <td>
        <Link
          target="_blank"
          to={inputData?.aadharImg}
          className="align-center"
        >
          <Article fontSize="medium" color="info" />
        </Link>
      </td>
      <td>
        <Checkbox
          name="aadharStatus"
          color="success"
          checked={inputData?.aadharStatus === "True" ? true : false}
          onChange={(e) => {
            handleChangeCheckbox(e.target.name, e.target.checked);
          }}
        />
      </td>
      <td>
        <TextField
          name="aadharRemark"
          value={
            inputData?.aadharRemark === "null"
              ? ""
              : inputData?.aadharRemark
          }
          onChange={(e) => {
            handleChangeRemark(e.target.name, e.target.value);
          }}
          size="small"
          variant="standard"
          placeholder="Remarks"
          fullWidth
        ></TextField>
      </td>
    </tr>
    <tr>
      <td className="text-center">
        {inputData?.panImg !== "No" ? 3 : 2}
      </td>
      <td>Cancelled Cheque</td>
      <td>
        <Link target="_blank" to={inputData?.cancelledChequeImg}>
          <Article fontSize="medium" color="info" />
        </Link>
      </td>
      <td>
        <Checkbox
          name="chequeStatus"
          color="success"
          checked={inputData?.chequeStatus === "True" ? true : false}
          onChange={(e) => {
            handleChangeCheckbox(e.target.name, e.target.checked);
          }}
        />
      </td>
      <td>
        <TextField
          name="chequeRemark"
          value={
            inputData?.chequeRemark === "null"
              ? ""
              : inputData?.chequeRemark
          }
          onChange={(e) => {
            handleChangeRemark(e.target.name, e.target.value);
          }}
          size="small"
          variant="standard"
          placeholder="Remarks"
          fullWidth
        ></TextField>
      </td>
    </tr>
  </tbody>
</table>
<div className="d-flex justify-content-end mb-3">
  <Button
    type="button"
    className="custom-btn-page-layout custom-btn-success text-white"
    disabled={loading}
    onClick={(e) => handleSubmit(e)}
    startIcon={<Checklist />}
  >
    {`Verify & Submit`}
  </Button>
</div>
</div> */
}
