import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import { toast } from "react-toastify";
import { commonActions, farmerActions } from "../../redux/actions";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Article, Remove, Verified } from "@mui/icons-material";
import { keys } from "../../config/keys";
import { ExcelIcon, ImageIcon, PdfIcon, WordIcon } from "../../Icons";
import { GridAction } from "../../common/useGridAction";
import DeleteModal from "../../Component/DeleteModal";
import { Button } from "@mui/material";

export const useFarmerHooks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    layout: { token },
    farmer_list,
  } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(0);
  const [singleData, setSingleData] = useState(false);
  const [emailCounter, setEmailCounter] = useState(0);

  const farmerDataByIdAPi = useCallback(
    async (farmerId, redirectLink, ...others) => {
      setLoading(true);
      let formData = new FormData();
      formData.append("farmerId", farmerId);
      console.log(...others);
      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/farmerdatabyid`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          navigate(redirectLink, {
            state: {
              ..._data?.farmerData[0],
              ownership: others.length > 0 ? others[0] : 0,
            },
          });

          setLoading(false);
          // setKitList(_data?.farmerData);
          // setTotalCount(_data?.paginationDetails?.count);
        } else {
          setLoading(false);

          toast.error(_data?.error);
        }
      } catch (error) {
        console.log(error);
      }
      // eslint-disable-next-line
    },
    []
  );

  const toDataUrl = (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  const viewAgreement = useCallback(
    async (farmerId, sendEmail = false, documentpath = "") => {
      setLoading(true);
      let formData = new FormData();
      formData.append("farmerId", farmerId);

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/farmerdatabyid`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          const rowData = _data?.farmerData[0];
          toDataUrl(rowData?.photo, function (myBase64) {
            localStorage.setItem("imageData", myBase64);
            // setImageBase64(myBase64)
          });

          let farmingDetailsArr = [];
          // eslint-disable-next-line
          rowData?.farmingDetails?.map((er) => {
            farmingDetailsArr?.push(er?.farmingProductDetails?.productName);
          });

          rowData.farmingDetailsProductName = farmingDetailsArr;

          let totalVigha = rowData?.landDetails?.reduce(
            (total, num) =>
              parseInt(total) + Math.round(num?.regVigha * 100) / 100,
            0
          );

          let t = {
            ...rowData,
            totalVigha,
            village: rowData?.villageDetails?.villageName,
            photourl: `${keys().ServicePath}/${documentpath}`,
          };
          localStorage.setItem("agreement", JSON.stringify(t));
          window.open(`/agreement/${farmerId}`, "_blank");

          // setTimeout(() => {
          //   let totalVigha = rowData?.landDetails?.reduce(
          //     (total, num) =>
          //       parseInt(total) + Math.round(num?.regVigha * 100) / 100,
          //     0
          //   );

          //   setSingleData({
          //     ...rowData,
          //     totalVigha,
          //     village: rowData?.villageDetails?.villageName,
          //     photourl: `${keys().ServicePath}/${documentpath}`,
          //   });

          //   navigate("/agreement", {
          //     state: {
          //       ...rowData,
          //       totalVigha,
          //       village: rowData?.villageDetails?.villageName,
          //       photourl: `${keys().ServicePath}/${documentpath}`,
          //     },
          //   });

          //   if (!sendEmail) setCounter(1);
          //   else if (sendEmail) setEmailCounter(1);
          // }, 1000);
          // setTimeout(() => {
          //   if (!sendEmail) setCounter(0);
          //   else if (sendEmail) setEmailCounter(0);
          //   setLoading(false);
          // }, 2000);

          setLoading(false);
          // setKitList(_data?.farmerData);
          // setTotalCount(_data?.paginationDetails?.count);
        } else {
          setLoading(false);

          toast.error(_data?.error);
        }
      } catch (error) {
        console.log(error);
      }
      // eslint-disable-next-line
    },
    []
  );

  return {
    farmerDataByIdAPi,
    loading,
    setLoading,
    viewAgreement,
    singleData,
    counter,
    emailCounter,
  };
};

const farmer_document_initialvalue = {
  farmerid: 0,
  farmerCode: "",
  selectedDocument: null,
  documentid: 0,
  documentpath: "",
  documenttype: "",
  documentsize: "",
  isverified: true,
  file: undefined,
  scannedimages: [],
  remarks: "",
};
export const useFarmerDocumentHooks = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const {
    layout: { token },
    document_master_list,
    farmer_document_list,
  } = useSelector((state) => state);
  const [formData, setFormData] = useState(farmer_document_initialvalue);
  const [fileViewer, setFileViewer] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(farmerDocumentValidationScheme),
  });

  const document_list = () => dispatch(commonActions.get_document_master());
  const _farmer_document_list = (farmerid) =>
    dispatch(farmerActions.get_farmers_document_list(farmerid));
  useEffect(() => {
    document_list();
  }, []);

  useEffect(() => {
    if (location?.state) {
      setFormData((prev) => ({
        ...prev,
        farmerid: location?.state?.id,
        farmerCode: location.state.farmerCode,
      }));
      _farmer_document_list(location?.state?.id);
    }
    // eslint-disable-next-line
  }, [location?.state]);

  const getIconColor = (status) => {
    if (status === 1) return "success";
    else if (status === 2) return "warning";
    else return "error";
  };
  const wordExtensions = ["doc", "docx"];
  const sheetExtensions = ["xls", "xlsx"];
  const imageExtensions = ["jpg", "jpeg", "bmp", "webp", "png", "gif", "ico"];
  const getIcon = (docName) => {
    const type = docName.split(".").pop().toLowerCase();
    if (wordExtensions.includes(type)) return <WordIcon fontSize="medium" />;
    else if (sheetExtensions.includes(type))
      return <ExcelIcon fontSize="medium" />;
    else if (imageExtensions.includes(type))
      return <ImageIcon fontSize="medium" />;
    else return <Article fontSize="medium" color="info" />;
  };

  const document_columns = useMemo(
    () => [
      {
        Header: "Document",
        accessor: "document",
      },
      {
        Header: "File Name",
        accessor: "filename",
      },
      {
        Header: "File Size",
        accessor: "documentsize",
      },
      {
        Header: "Remarks",
        accessor: "remarks",
      },
      {
        Header: "View File",
        accessor: "documentpath",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle",
        },
        Cell: (cellProps) => {
          return (
            <Link
              target="_blank"
              to={`${keys().ServicePath}/${
                cellProps.row.original.documentpath
              }`}
              className="align-center"
              title={cellProps.row.original.documentname}
              aria-label={cellProps.row.original.documentname}
            >
              {getIcon(cellProps.row.original.filename)}
            </Link>
            // <Button onClick={() => setFileViewer(cellProps.row.original)}>
            //   {getIcon(cellProps.row.original.filename)}
            // </Button>
          );
        },
      },
      {
        Header: "Verified",
        accessor: "isverified",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle",
        },
        Cell: (cellProps) => {
          return cellProps.row.original.isverified ? (
            <Verified
              fontSize="medium"
              color={getIconColor(cellProps.row.original.isverified)}
            />
          ) : (
            <Remove fontSize="medium" />
          );
        },
      },
      {
        ...GridAction({
          permission: { edit: false, print: false, delete: true },
          size: "medium",
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original.id);
          },
        }),
      },

      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  const onDocumentChange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      documentid: selectedOption?.id || 0,
      selectedDocument: selectedOption || null,
    }));
  };

  const onFileChange = (result) => {
    setFormData((prev) => ({
      ...prev,
      file: result,
      documentsize: result?.size,
      documenttype: result?.type,
    }));
  };

  const handleScannedImages = (result) => {
    setFormData((prev) => ({
      ...prev,
      scannedimages: result,
    }));
  };

  const onCancel = () => {
    setFormData({ ...farmer_document_initialvalue });
  };

  const handleChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmit = () => {
    dispatch(
      farmerActions.save_farmer_documents(formData, {
        onSuccess: () => {
          _farmer_document_list(formData.farmerid);
          setFormData((prev) => ({
            ...prev,
            selectedDocument: null,
            documentid: 0,
            file: undefined,
            remarks: "",
          }));
        },
      })
    );
    // dispatch(
    //   save_bag_issue(data, { onSuccess: (res) => navigate("/issuebag") })
    // );
  };

  const delete_farmer_document = () => {
    setDeleteLoading(true);
    dispatch(
      farmerActions.delete_farmers_document(deleteId, {
        onSuccess: () => {
          _farmer_document_list(formData.farmerid);
          setDeleteLoading(false);
          setDeleteModal(false);
        },
      })
    );
  };

  return {
    errors,
    onSubmit,
    register,
    formData,
    onCancel,
    fileViewer,
    deleteModal,
    handleSubmit,
    handleChange,
    handleScannedImages,
    onFileChange,
    deleteLoading,
    setDeleteModal,
    document_columns,
    onDocumentChange,
    farmer_document_list,
    document_master_list,
    delete_farmer_document,
  };
};

export const farmerDocumentValidationScheme = yup.object({
  selectedDocument: yup.string().required("Document is required"),
});
