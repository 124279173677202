import React, { useEffect, useState } from "react";
import { Label, Input, Row, Col, FormGroup } from "reactstrap";
import { toast } from "react-toastify";
import CustomModal from "../../Component/CustomModal";
import CountryOption from "../../hooks/CountryOption";
import Select from "react-select";
import StateOption from "../../hooks/StateOptions";
import DistrictOption from "../../hooks/DistrictOption";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import { useNavigate } from "react-router-dom";
import useBreakPoints from "../../hooks/useBreakPoints";
const EditTaluka = ({ editModal, onCloseModal, onSubmitModal, singleData }) => {
  const { token } = useSelector((state) => state.layout);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useBreakPoints();
  const [loading, setLoading] = useState(false);
  const [countryArray, setCountryArray] = useState([]);
  const { getCountryOption } = CountryOption();
  const { getStateOption } = StateOption();
  const { getDistrictOption } = DistrictOption();
  const [districtArray, setDistrictArray] = useState([]);
  const [stateArray, setStateArray] = useState();

  useEffect(() => {
    getCountryOption().then((res) => {
      setCountryArray(res);
    });
    getStateOption(singleData?.countryDetails?.id).then((res) => {
      setStateArray(res);
    });
    getDistrictOption(singleData?.stateDetails?.id).then((res) => {
      setDistrictArray(res);
    });
    // eslint-disable-next-line
  }, []);

  const [inputData, setInputData] = useState({
    country: {
      label: singleData?.countryDetails?.name,
      value: singleData?.countryDetails?.id,
    },
    state: {
      label: singleData?.stateDetails?.stateName,
      value: singleData?.stateDetails?.id,
    },
    dist: {
      label: singleData?.districtDetails?.districtName,
      value: singleData?.districtDetails?.id,
    },
    talukaName: singleData?.talukaName,
  });

  const [inputDataError, setInputDataError] = useState({
    country: false,
    state: false,
    dist: false,
    talukaName: false,
  });

  const nonNumericRegex = /^[A-Za-z]+$/;

  const handleChangeInput = (name, value) => {
    if (name === "talukaName") {
      if (nonNumericRegex.test(value) || value === "") {
        setInputData({ ...inputData, [name]: value });
      } else {
        toast.error("Type characters only");
      }
      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, talukaName: false });
      } else {
        setInputDataError({ ...inputDataError, talukaName: true });
      }
    } else {
      if (name === "country") {
        getStateOption(value.value).then((res) => {
          setStateArray(res);
        });
        setInputData({
          ...inputData,
          [name]: value,
          state: "",
          dist: "",
          talukaName: "",
        });

        if (value?.label?.length > 0) {
          setInputDataError({ ...inputDataError, country: false });
        } else {
          setInputDataError({ ...inputDataError, country: true });
        }
      } else if (name === "state") {
        getDistrictOption(value.value).then((res) => {
          setDistrictArray(res);
        });
        setInputData({ ...inputData, [name]: value, dist: "", talukaName: "" });
        if (value?.label?.length > 0) {
          setInputDataError({ ...inputDataError, state: false });
        } else {
          setInputDataError({ ...inputDataError, state: true });
        }
      } else if (name === "dist") {
        setInputData({ ...inputData, [name]: value, talukaName: "" });
        if (value?.label?.length > 0) {
          setInputDataError({ ...inputDataError, dist: false });
        } else {
          setInputDataError({ ...inputDataError, dist: true });
        }
      } else {
        setInputData({ ...inputData, [name]: value });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputData.country) {
      toast.error("County is Required!");
      setInputDataError({ ...inputDataError, country: true });
    } else if (!inputData.state) {
      toast.error("State is Required!");
      setInputDataError({ ...inputDataError, state: true });
    } else if (!inputData.dist) {
      toast.error("Dist. is Required!");
      setInputDataError({ ...inputDataError, dist: true });
    } else if (!inputData.talukaName) {
      toast.error("Taluka Name is Required!");
      setInputDataError({ ...inputDataError, talukaName: true });
    } else {
      setLoading(true);
      let formData = new FormData();

      formData.append("countryId", inputData.country.value);
      formData.append("stateId", inputData.state.value);
      formData.append("districtId", inputData.dist.value);
      formData.append("talukaName", inputData.talukaName);

      formData.append("id", singleData.id);

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}admin1/edittaluka`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setLoading(false);
          setInputData({
            countyCode: "",
            countryName: "",
          });
          onSubmitModal();
        } else {
          setLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  return (
    <CustomModal
      loading={loading}
      title={"Edit Taluka"}
      handleSubmit={handleSubmit}
      modalState={editModal}
      onCloseModal={onCloseModal}
      editState={true}
    >
      <FormGroup row className="g-3" style={{ margin: 20 }}>
        <Row className="g-3">
          <Col md={12} className={`d-flex  ${isMobile ? "flex-column" : ""}`}>
            <Col md={4}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Country <span className="required_span"> * </span>
              </Label>
            </Col>
            <Col sm={8}>
              <Select
                isMulti={false}
                onChange={(e) => {
                  handleChangeInput("country", e);
                }}
                isDisabled
                value={inputData?.country}
                options={countryArray}
                // menuPlacement="top"
                name="choices-single-default"
                id="countryAddCompany"
                className={`w-100 ${
                  inputDataError?.country ? "border border-danger" : ""
                }`}
              ></Select>
            </Col>
          </Col>
        </Row>
        <Row className="g-3">
          <Col md={12} className={`d-flex  ${isMobile ? "flex-column" : ""}`}>
            <Col md={4}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                State <span className="required_span"> * </span>
              </Label>
            </Col>
            <Col sm={8}>
              <Select
                isMulti={false}
                onChange={(e) => {
                  handleChangeInput("state", e);
                }}
                value={inputData?.state}
                isDisabled
                name="state"
                options={stateArray}
                // menuPlacement="top"

                className={`w-100 ${
                  inputDataError?.state ? "border border-danger" : ""
                }`}
              ></Select>
            </Col>
          </Col>
        </Row>

        <Row className="g-3">
          <Col md={12} className={`d-flex  ${isMobile ? "flex-column" : ""}`}>
            <Col md={4}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Dist <span className="required_span"> * </span>
              </Label>
            </Col>
            <Col sm={8}>
              <Select
                isMulti={false}
                onChange={(e) => {
                  handleChangeInput("dist", e);
                }}
                value={inputData?.dist}
                isDisabled
                name="dist"
                options={districtArray}
                // menuPlacement="top"

                className={`w-100 ${
                  inputDataError?.dist ? "border border-danger" : ""
                }`}
              ></Select>
            </Col>
          </Col>
        </Row>

        <Row className="g-3">
          <Col md={12} className={`d-flex  ${isMobile ? "flex-column" : ""}`}>
            <Col md={4}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Taluka Name <span className="required_span"> * </span>
              </Label>
            </Col>
            <Col sm={8} className={`${isMobile ? "" : "pl-5"}`}>
              <Input
                id="exampleEmail"
                name="talukaName"
                value={inputData?.talukaName}
                onChange={(e) => {
                  handleChangeInput(
                    e.target.name,
                    e.target.value.toUpperCase()
                  );
                }}
                className={`cust-input ${
                  inputDataError?.talukaName ? "border-danger " : ""
                } `}
                type="text"
              />
            </Col>
          </Col>
        </Row>
      </FormGroup>
    </CustomModal>
  );
};

export default EditTaluka;
