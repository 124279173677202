import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { publishingValidationSchema } from "./validationScheme";
import { yupResolver } from "@hookform/resolvers/yup";
import { commonActions, settingsAction } from "../../redux/actions";
import { usePermissionHooks } from "../../common/useCheckPermission";
import { GridAction } from "../../common/useGridAction";
import { toast } from "react-toastify";
import { keys } from "../../config/keys";
import dayjs from "dayjs";

const initialValues = {
  id: 0,
  title: "",
  description: "",
  content: "<p> Write Content Here </p>",
  file: undefined,
  documentsize: "",
  documenttype: "",
  startdatestring: dayjs(Date()).format(keys().dbDateFormat),
  startdate: dayjs(Date()),
  enddatestring: dayjs(Date()).format(keys().dbDateFormat),
  enddate: dayjs(Date()),
  starttimestring: dayjs(Date()).format(keys().dbTimeFormat),
  starttime: dayjs(Date()),
  endtimestring: dayjs(Date()).add(60, "minutes").format(keys().dbTimeFormat),
  endtime: dayjs(Date()).add(60, "minutes"),
  selectedeventtype: null,
  eventtypeid: 0,
};

export const usePlannerHooks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialValues);
  const location = useLocation();

  const { event_list, event_type_list } = useSelector((obj) => obj);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEventModal, setShowEventModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    // setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(publishingValidationSchema),
  });

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleEditorChange = (editor) =>
    setFormData((prev) => ({
      ...prev,
      content: editor.getData(),
    }));

  const onEventTypeChange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      eventtypeid: selectedOption?.id || 0,
      selectedeventtype: selectedOption || null,
    }));
  };

  const onFileChange = (result) => {
    console.log(result);
    setFormData((prev) => ({
      ...prev,
      file: result,
      documentsize: result?.size,
      documenttype: result?.type,
    }));
  };

  const onDateChange = (dateValue) => {
    setFormData((prev) => ({
      ...prev,
      startdate: dateValue,
      startdatestring: dayjs(dateValue).format(keys().dbDateFormat),
    }));
  };
  const onEndDateChange = (dateValue) => {
    setFormData((prev) => ({
      ...prev,
      enddate: dateValue,
      enddatestring: dayjs(dateValue).format(keys().dbDateFormat),
    }));
  };

  const onTimeChange = (timeValue) => {
    const et = dayjs(timeValue).add(60, "minutes");
    console.log(
      dayjs(timeValue).format(keys().dbTimeFormat),
      dayjs(et).format(keys().dbTimeFormat)
    );

    setFormData((prev) => ({
      ...prev,
      starttime: timeValue,
      starttimestring: dayjs(timeValue).format(keys().dbTimeFormat),
      endtime: et,
      endtimestring: dayjs(et).format(keys().dbTimeFormat),
    }));
  };

  const onSubmit = () => {
    dispatch(
      settingsAction.save_event(formData, {
        onSuccess: () => {
          setFormData((prev) => ({
            ...prev,
            ...initialValues,
          }));
          navigate("/eventlist");
        },
      })
    );
  };

  const _get_event_list = () => dispatch(settingsAction.get_event_list());
  const _get_event_type_list = () =>
    dispatch(commonActions.get_event_types_list({}));

  const handleSelectEvent = useCallback((event) => {
    setShowEventModal(true);
    setSelectedEvent(event);
  }, []);

  useEffect(() => {
    if (location?.state?.id > 0) {
      setFormData((prev) => ({ ...prev, ...location?.state?.data }));
    }
  }, [location?.state?.id]);

  useEffect(() => {
    _get_event_list();
    _get_event_type_list();
  }, []);

  // useEffect(() => {
  //   console.log(event_list);
  // }, [event_list]);

  return {
    formData,
    setFormData,
    handleChange,
    initialValues,
    register,
    handleSubmit,
    onSubmit,
    handleEditorChange,
    onFileChange,
    onDateChange,
    onEndDateChange,
    onTimeChange,
    errors,
    control,
    event_list,
    event_type_list,
    onEventTypeChange,
    handleSelectEvent,
    selectedEvent,
    setShowEventModal,
    showEventModal,
  };
};

export const usePlannerListHooks = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const permission = usePermissionHooks(location);
  const { event_list } = useSelector((obj) => obj);

  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEventModal, setShowEventModal] = useState(false);

  const column = useMemo(
    () => [
      {
        id: "sr",
        Header: "Sr. No.",
        Cell: (cellProps) => {
          return <span>{cellProps?.row?.index + 1}</span>;
        },
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Event Start",
        accessor: "startdate",
        Cell: ({ value }) => keys().shortDate(value),
      },
      {
        Header: "Event End",
        accessor: "enddate",
        Cell: ({ value }) => keys().shortDate(value),
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        ...GridAction({
          permission,
          onEditClick: (cellProps) => {
            navigate(`/eventplanner`, {
              state: {
                data: cellProps.row.original,
                id: cellProps.row.original.id,
              },
            });
          },
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original.id);
          },
        }),
      },
    ],
    // eslint-disable-next-line
    []
  );

  const onGridDelete = async () => {
    setDeleteLoading(true);
    if (deleteId > 0) {
      dispatch(
        settingsAction.delete_event(
          { id: deleteId },
          {
            onSuccess: () => {
              toast.success("Event Deleted Successfully!!!");
              _get_event_list();
            },
            onError: (err) =>
              toast.error("Something went wrong. Please try again."),
          }
        )
      );
      setDeleteLoading(false);
      setDeleteModal(false);
    }
  };

  const _get_event_list = () => dispatch(settingsAction.get_event_list());

  const handleSelectEvent = useCallback((event) => {
    setShowEventModal(true);
    setSelectedEvent(event);
  }, []);

  useEffect(() => {
    _get_event_list();
  }, []);

  return {
    event_list,
    column,
    permission,
    deleteModal,
    onGridDelete,
    deleteLoading,
    setDeleteModal,
    handleSelectEvent,
    selectedEvent,
    showEventModal,
    setShowEventModal,
  };
};
