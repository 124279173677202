/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { yieldEstimationValidationSchema } from "./validationSchema";
import {
  yieldConfigurationActions,
  employeeActions,
  farmerActions,
  yieldEstimationActions,
  landdetailActions
} from "../../redux/actions";
import { statusOption } from "./../../hooks/StaticData";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { keys } from "../../config/keys";
import { useLocation } from "react-router-dom";

const initialValues = {
  id: 0,
  technicianId: 0,
  selectedTechnician: null,
  farmerId: 0,
  selectedFarmer: null,
  landId: 0,
  selectedLand: null,
  yieldconfigId: 0,
  selectedYieldconfig: null,
  voucherno: "",
  lastvoucherno: "-",
  referenceno: "",
  invoicedatestring: dayjs(Date()).format(keys().dbDateFormat),
  invoicedate: dayjs(Date()),
  totalestimated: 0.0,
  rate: 0.0,
  isactive: 1,
  selStatus: statusOption[0] || null
};

export const useYieldEstimationHooks = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [formData, setFormData] = useState({
    ...initialValues,
    farmerId: location.state?.farmerId
  });
  const [rowData, setRowData] = useState([]);
  const [farmerRowData, setFarmerRowData] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [singleData, setSingleData] = useState();
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const {
    yieldConfigurationList,
    employeeDropdownList,
    farmerDropdownList,
    farmerLandList,
    yieldEstimationList,
    getLandList
  } = useSelector((obj) => obj);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(yieldEstimationValidationSchema)
  });

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onTechnicianchange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      technicianId: selectedOption?.value || 0,
      selectedTechnician: selectedOption || null
    }));
  };

  const onFarmerchange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      farmerId: selectedOption?.farmerid || 0,
      selectedFarmer: selectedOption || null
    }));
  };

  const onLandchange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      landId: selectedOption?.id || 0,
      selectedLand: selectedOption || null
    }));
  };

  const get_farmerDropdownList = (employeeid) =>
    dispatch(employeeActions.get_farmer_by_employee_and_unit(employeeid, 0));

  const onyieldconfigchange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      yieldconfigId: selectedOption?.value || 0,
      selectedYieldconfig: selectedOption || null
    }));
  };

  const ondropdownchange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      isactive: selectedOption?.value || 1,
      selStatus: selectedOption || null
    }));
  };

  const resetFormData = () => {
    setFormData(initialValues);
  };

  const onCancelClick = () => {
    setFormData(initialValues);
  };

  const onSubmit = async () => {
    if (formData.id > 0) {
      await dispatch(
        yieldEstimationActions.updateYieldEstimation({ ...formData }, () => {
          toast.success("Yield Estimation update successfully");
        })
      );
    } else {
      await dispatch(
        yieldEstimationActions.saveYieldEstimation({ ...formData }, () => {
          toast.success("Yield Estimation create successfully");
        })
      );
    }
    setFormData(initialValues);
    setDeleteModal(false);
    setEditModal(false);
    get_latest_voucher_number();
    //await dispatch(yieldEstimationActions.getYieldEstimationList());
  };

  const get_latest_voucher_number = () => {
    if (!singleData || singleData?.id <= 0) {
      dispatch(
        yieldEstimationActions.get_latest_voucher_no({
          onSuccess: (res) => {
            if (res.length > 0) {
              setFormData((prev) => ({ ...prev, ...res[0] }));
              reset({ ...formData, ...res[0] });
            }
          }
        })
      );
    }
  };
  useEffect(() => {
    if (location.state !== null) {
      setEditModal((prev) => !prev);
      setSingleData({ ...location.state });
    }
  }, [location.state]);

  useEffect(() => {
    dispatch(yieldConfigurationActions.getYieldConfiguration());
    dispatch(employeeActions.getEmployeeDropdownList());
    // dispatch(farmerActions.getFarmerDropdownList());
    if (location?.state === null) {
      get_latest_voucher_number();
      setDeleteModal(false);
      setEditModal(false);
    }
  }, []);

  useEffect(() => {
    if (yieldEstimationList.length > 0) {
      setRowData(yieldEstimationList);
    } else {
      setRowData([]);
    }
  }, [yieldEstimationList]);

  useEffect(() => {
    if (singleData?.id > 0) {
      let obj = {
        id: singleData?.id,
        technicianId: singleData?.technicianId,
        farmerId: singleData?.farmerId,
        landId: singleData?.landId,
        yieldconfigId: singleData?.yieldconfigId,
        voucherno: singleData?.voucherno,
        referenceno: singleData?.referenceno,
        invoicedatestring: dayjs(singleData?.invoicedate).format(
          keys().dbDateFormat
        ),
        invoicedate: dayjs(singleData?.invoicedate),
        totalestimated: singleData?.totalestimated,
        rate: singleData?.rate,
        isactive: singleData?.isactive,
        selStatus: statusOption[0] || null
      };
      setFormData((prev) => ({
        ...prev,
        ...obj,
        selectedTechnician: employeeDropdownList.filter(
          (obj) => singleData?.technicianId === obj.value
        )[0],
        selectedFarmer: farmerDropdownList.filter(
          (obj) => singleData?.farmerId === obj.value
        )[0],
        selectedYieldconfig: yieldConfigurationList.filter(
          (obj) => singleData?.yieldconfigId === obj.value
        )[0],
        selStatus: statusOption.filter(
          (obj) => singleData?.isactive === obj.value
        )[0]
      }));
    }
  }, [editModal, singleData]);

  useEffect(() => {
    if (formData?.farmerId > 0) {
      // dispatch(farmerActions.getFarmerLandList(formData?.farmerId || 0));
      dispatch(
        landdetailActions.getLandDetailListByFarmerId(formData?.farmerId)
      );

      dispatch(
        yieldEstimationActions.getYieldEstimationList(formData?.farmerId, {
          onSuccess: (res) => setFarmerRowData([...res])
        })
      );
    } else {
      setFarmerRowData([]);
      setFormData((prev) => ({
        ...prev,
        landId: 0,
        selectedLand: null
      }));
    }
  }, [formData?.farmerId]);

  useEffect(() => {
    if (formData.farmerId > 0 && formData.id > 0) {
      setFormData((prev) => ({
        ...prev,
        selectedLand: getLandList?.filter(
          (obj) => singleData?.landId === obj.id
        )[0]
      }));
    }
  }, [getLandList]);

  useEffect(() => {
    if (formData.technicianId > 0) {
      get_farmerDropdownList(formData.technicianId);
    }
  }, [formData.selectedTechnician, formData]);

  const onDateChange = (dateValue) => {
    setFormData((prev) => ({
      ...prev,
      invoicedate: dateValue,
      invoicedatestring: dayjs(dateValue).format("YYYY-MM-DD")
    }));
  };

  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    let formData = new FormData();
    formData.append("id", deleteId);
    formData.append("isDelete", "Yes");

    if (deleteId > 0) {
      await dispatch(yieldEstimationActions.deleteYieldEstimation(deleteId));
      await dispatch(yieldEstimationActions.getYieldEstimationList());
      toast.success("Yield Estimation delete successfully");
      setDeleteLoading(false);
      setDeleteModal(false);
    }
  };

  return {
    setFormData,
    formData,
    setDeleteModal,
    deleteModal,
    setEditModal,
    editModal,
    setDeleteId,
    deleteId,
    setDeleteLoading,
    deleteLoading,
    setSingleData,
    singleData,
    rowData,
    handleChange,
    register,
    handleSubmit,
    errors,
    resetFormData,
    control,
    onSubmit,
    ondropdownchange,
    handleDeleteRequest,
    employeeDropdownList,
    farmerDropdownList,
    farmerLandList,
    yieldConfigurationList,
    onDateChange,
    onTechnicianchange,
    onFarmerchange,
    onLandchange,
    onyieldconfigchange,
    onCancelClick,
    farmerRowData,
    getLandList
  };
};

export const useYieldEstimationListHooks = () => {
  const dispatch = useDispatch();

  const [rowData, setRowData] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const { yieldEstimationList } = useSelector((obj) => obj);

  useEffect(() => {
    dispatch(yieldEstimationActions.getYieldEstimationList());
  }, []);

  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    let formData = new FormData();
    formData.append("id", deleteId);
    formData.append("isDelete", "Yes");

    if (deleteId > 0) {
      dispatch(
        yieldEstimationActions.deleteYieldEstimation(deleteId, () => {
          dispatch(yieldEstimationActions.getYieldEstimationList());
        })
      );

      toast.success("Yield Estimation delete successfully");
      setDeleteLoading(false);
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    if (yieldEstimationList.length > 0) {
      setRowData(yieldEstimationList);
    } else {
      setRowData([]);
    }
  }, [yieldEstimationList]);

  return {
    setDeleteModal,
    deleteModal,
    setEditModal,
    editModal,
    setDeleteId,
    deleteId,
    setDeleteLoading,
    deleteLoading,
    rowData,
    handleDeleteRequest
  };
};
