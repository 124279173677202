import * as types from "../constTypes";

const initialState = [];

export const water_waste_list = (state = initialState, action) => {
  switch (action.type) {
    case types.WATER_WASTE_LIST:
      return action.payload || [];
    default:
      return state;
  }
};
