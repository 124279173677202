import React from "react";
import { useDashboardHooks } from "./useDashboardHooks";
import AdminDashboard from "./AdminDashboard";
import BagDashboard from "./BagDashboard";
import IncentiveDashboard from "./IncentiveDashboard";

const Dashboard = () => {
  const { user } = useDashboardHooks();
  return (
    <>
      {user?.roleid === 24 && <AdminDashboard />}
      {user?.roleid === 28 && <BagDashboard />}
      {(user?.roleid === 30 || user?.roleid === 29) && <IncentiveDashboard />}
    </>
  );
};

export default Dashboard;
