import React, {useEffect,useState } from "react";
import PageLayout from "../../Component/PageLayout";
import {
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import { ClipLoader } from "react-spinners";
import useBreakPoints from "../../hooks/useBreakPoints";
const EditRole = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {isMobile} = useBreakPoints()
  const { token } = useSelector((state) => state.layout);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [subopen, setSubOpen] = React.useState(false);
  const [inputData, setInputData] = useState({
    roleName: location?.state?.roleName,
    roleId: location?.state?.id,
    moduleData: location?.state?.permissions,
  });
  const [roleNameError, setRoleNameError] = useState(false);


  const handleChangeSwitch = (index, name, checked, j, submodule) => {
    if (submodule) {
      let arr = [...inputData?.moduleData];

      if (name === "permission" && !checked) {
        arr[index]["subModules"][j]["create"] = false;
        arr[index]["subModules"][j]["print"] = false;
        arr[index]["subModules"][j]["edit"] = false;
        arr[index]["subModules"][j]["delete"] = false;
        arr[index]["subModules"][j][name] = checked;
      } else {
        arr[index]["subModules"][j][name] = checked;
      }

      setInputData({ ...inputData, moduleData: arr });
    } else {
      let arr = [...inputData?.moduleData];

      if (name === "permission" && !checked) {
        arr[index]["create"] = false;
        arr[index]["print"] = false;
        arr[index]["edit"] = false;
        arr[index]["delete"] = false;
        arr[index][name] = checked;
      } else {
        arr[index][name] = checked;
      }

      setInputData({ ...inputData, moduleData: arr });
    }
  };

  const handleChangeModuleSwitch = (index, j, name, checked) => {
    let arr = [...inputData?.moduleData];

    if (name === "permission" && !checked) {
      arr[index]["moduleData"][j]["create"] = false;
      arr[index]["moduleData"][j]["print"] = false;
      arr[index]["moduleData"][j]["edit"] = false;
      arr[index]["moduleData"][j]["delete"] = false;
      arr[index]["moduleData"][j][name] = checked;
    } else {
      arr[index]["moduleData"][j][name] = checked;
    }

    setInputData({ ...inputData, moduleData: arr });
  };

  const handleChangeSubModuleSwitch = (index, j, k, name, checked) => {
    let arr = [...inputData?.moduleData];

    if (name === "permission" && !checked) {
      arr[index]["moduleData"][j]["subModuleData"][k]["create"] = false;
      arr[index]["moduleData"][j]["subModuleData"][k]["print"] = false;
      arr[index]["moduleData"][j]["subModuleData"][k]["edit"] = false;
      arr[index]["moduleData"][j]["subModuleData"][k]["delete"] = false;
      arr[index]["moduleData"][j]["subModuleData"][k][name] = checked;
    } else {
      arr[index]["moduleData"][j]["subModuleData"][k][name] = checked;
    }

    setInputData({ ...inputData, moduleData: arr });
  };
  const handleChangeInput = (name, value) => {
    if (name === "roleName") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length > 0) {
        setRoleNameError(false);
      } else {
        setRoleNameError(true);
      }
    } else {

      setInputData({ ...inputData, [name]: value });
    }
  };

  function RowColumn({ row, index }) {
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell component="th" scope="row">
            {row?.moduleData?.length > 0 && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen({ id: index, status: !open?.status })}
              >
                {open?.id === index && open?.status ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            )}
            {row.mainModuleName}
          </TableCell>
          {!row?.moduleData?.length > 0 && (
            <TableCell align="center">
              <FormGroup
                switch
                className="justify-content-center align-items-center d-flex"
              >
                <Input
                  type="switch"
                  checked={row?.permission}
                  name="permission"
                  onChange={(e) =>
                    handleChangeSwitch(index, e.target.name, e.target.checked)
                  }
                />
              </FormGroup>
            </TableCell>
          )}
          {!row?.moduleData?.length > 0 && (
            <TableCell align="center">
              <FormGroup
                switch
                className="justify-content-center align-items-center d-flex"
              >
                <Input
                  type="switch"
                  checked={row?.create}
                  disabled={!row?.permission}
                  name="create"
                  onChange={(e) =>
                    handleChangeSwitch(index, e.target.name, e.target.checked)
                  }
                />
              </FormGroup>
            </TableCell>
          )}
          {!row?.moduleData?.length > 0 && (
            <TableCell align="center">
              <FormGroup
                switch
                className="justify-content-center align-items-center d-flex"
              >
                <Input
                  type="switch"
                  checked={row?.edit}
                  disabled={!row?.permission}
                  name="edit"
                  onChange={(e) =>
                    handleChangeSwitch(index, e.target.name, e.target.checked)
                  }
                />
              </FormGroup>
            </TableCell>
          )}
          {!row?.moduleData?.length > 0 && (
            <TableCell align="center">
              <FormGroup
                switch
                className="justify-content-center align-items-center d-flex"
              >
                <Input
                  type="switch"
                  checked={row?.print}
                  disabled={!row?.permission}
                  name="print"
                  onChange={(e) =>
                    handleChangeSwitch(index, e.target.name, e.target.checked)
                  }
                />
              </FormGroup>
            </TableCell>
          )}
          {!row?.moduleData?.length > 0 && (
            <TableCell align="center">
              <FormGroup
                switch
                className="justify-content-center align-items-center d-flex"
              >
                <Input
                  type="switch"
                  checked={row?.delete}
                  disabled={!row?.permission}
                  name="delete"
                  onChange={(e) =>
                    handleChangeSwitch(index, e.target.name, e.target.checked)
                  }
                />
              </FormGroup>
            </TableCell>
          )}
        </TableRow>
        {row?.moduleData?.length > 0 && (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse
                in={open?.id === index && open?.status}
                timeout="auto"
                unmountOnExit
              >
                <Box sx={{ margin: 1 }}>
                  {/* <Typography variant="h6" gutterBottom component="div">
                    SubModule
                  </Typography> */}
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Module Name</TableCell>
                        <TableCell align="center">Permission</TableCell>
                        <TableCell align="center">Create</TableCell>
                        <TableCell align="center">Edit</TableCell>
                        <TableCell align="center">Print</TableCell>
                        <TableCell align="center">Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row?.moduleData?.map((historyRow, j) => (
                        <>
                          <TableRow key={j}>
                            {/* <TableCell component="th" scope="row">
                            {historyRow.moduleName}
                          </TableCell> */}
                          </TableRow>
                          {row?.moduleData?.length > 0 && (
                            <RowSubColumn
                              key={historyRow.moduleName}
                              row={historyRow}
                              index={index}
                              j={j}
                            />
                          )}
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  }

  function RowSubColumn({ row, index, j }) {
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell component="th" scope="row">
            {row?.subModuleData?.length > 0 && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() =>
                  setSubOpen({ id: index, status: !subopen?.status })
                }
              >
                {subopen?.id === index && subopen?.status ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            )}
            {row.moduleName}
          </TableCell>
          {!row?.subModuleData?.length > 0 && (
            <TableCell align="center">
              <FormGroup
                switch
                className="justify-content-center align-items-center d-flex"
              >
                <Input
                  type="switch"
                  checked={row?.permission}
                  name="permission"
                  onChange={(e) =>
                    handleChangeModuleSwitch(
                      index,
                      j,
                      e.target.name,
                      e.target.checked
                    )
                  }
                />
              </FormGroup>
            </TableCell>
          )}
          {!row?.subModuleData?.length > 0 && (
            <TableCell align="center">
              <FormGroup
                switch
                className="justify-content-center align-items-center d-flex"
              >
                <Input
                  type="switch"
                  checked={row?.create}
                  disabled={!row?.permission}
                  name="create"
                  onChange={(e) =>
                    handleChangeModuleSwitch(
                      index,
                      j,
                      e.target.name,
                      e.target.checked
                    )
                  }
                />
              </FormGroup>
            </TableCell>
          )}
          {!row?.subModuleData?.length > 0 && (
            <TableCell align="center">
              <FormGroup
                switch
                className="justify-content-center align-items-center d-flex"
              >
                <Input
                  type="switch"
                  checked={row?.edit}
                  disabled={!row?.permission}
                  name="edit"
                  onChange={(e) =>
                    handleChangeModuleSwitch(
                      index,
                      j,
                      e.target.name,
                      e.target.checked
                    )
                  }
                />
              </FormGroup>
            </TableCell>
          )}
          {!row?.subModuleData?.length > 0 && (
            <TableCell align="center">
              <FormGroup
                switch
                className="justify-content-center align-items-center d-flex"
              >
                <Input
                  type="switch"
                  checked={row?.print}
                  disabled={!row?.permission}
                  name="print"
                  onChange={(e) =>
                    handleChangeModuleSwitch(
                      index,
                      j,
                      e.target.name,
                      e.target.checked
                    )
                  }
                />
              </FormGroup>
            </TableCell>
          )}
          {!row?.subModuleData?.length > 0 && (
            <TableCell align="center">
              <FormGroup
                switch
                className="justify-content-center align-items-center d-flex"
              >
                <Input
                  type="switch"
                  checked={row?.delete}
                  disabled={!row?.permission}
                  name="delete"
                  onChange={(e) =>
                    handleChangeModuleSwitch(
                      index,
                      j,
                      e.target.name,
                      e.target.checked
                    )
                  }
                />
              </FormGroup>
            </TableCell>
          )}
        </TableRow>
        {row?.subModuleData?.length > 0 && (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse
                in={subopen?.id === index && subopen?.status}
                timeout="auto"
                unmountOnExit
              >
                <Box sx={{ margin: 1 }}>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Sub Module Name</TableCell>
                        <TableCell align="center">Permission</TableCell>
                        <TableCell align="center">Create</TableCell>
                        <TableCell align="center">Edit</TableCell>
                        <TableCell align="center">Print</TableCell>
                        <TableCell align="center">Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row?.subModuleData?.map((historyRow, k) => (
                        <TableRow key={k}>
                          <TableCell component="th" scope="row">
                            {historyRow.subModuleName}
                          </TableCell>
                          {row?.moduleData?.length > 0 ? (
                            <RowSubColumn
                              key={historyRow.moduleName}
                              row={historyRow}
                              index={index}
                              j={j}
                            />
                          ) : (
                            <>
                              <TableCell align="center">
                                <FormGroup
                                  switch
                                  className="justify-content-center align-items-center d-flex"
                                >
                                  <Input
                                    type="switch"
                                    checked={historyRow?.permission}
                                    name="permission"
                                    onChange={(e) =>
                                      handleChangeSubModuleSwitch(
                                        index,
                                        j,
                                        k,
                                        e.target.name,
                                        e.target.checked
                                      )
                                    }
                                  />
                                </FormGroup>
                              </TableCell>
                              <TableCell align="center">
                                <FormGroup
                                  switch
                                  className="justify-content-center align-items-center d-flex"
                                >
                                  <Input
                                    type="switch"
                                    checked={historyRow?.create}
                                    disabled={!historyRow?.permission}
                                    name="create"
                                    onChange={(e) =>
                                      handleChangeSubModuleSwitch(
                                        index,
                                        j,
                                        k,
                                        e.target.name,
                                        e.target.checked
                                      )
                                    }
                                  />
                                </FormGroup>
                              </TableCell>
                              <TableCell align="center">
                                <FormGroup
                                  switch
                                  className="justify-content-center align-items-center d-flex"
                                >
                                  <Input
                                    type="switch"
                                    checked={historyRow?.edit}
                                    disabled={!historyRow?.permission}
                                    name="edit"
                                    onChange={(e) =>
                                      handleChangeSubModuleSwitch(
                                        index,
                                        j,
                                        k,
                                        e.target.name,
                                        e.target.checked
                                      )
                                    }
                                  />
                                </FormGroup>
                              </TableCell>
                              <TableCell align="center">
                                <FormGroup
                                  switch
                                  className="justify-content-center align-items-center d-flex"
                                >
                                  <Input
                                    type="switch"
                                    checked={historyRow?.print}
                                    disabled={!historyRow?.permission}
                                    name="print"
                                    onChange={(e) =>
                                      handleChangeSubModuleSwitch(
                                        index,
                                        j,
                                        k,
                                        e.target.name,
                                        e.target.checked
                                      )
                                    }
                                  />
                                </FormGroup>
                              </TableCell>
                              <TableCell align="center">
                                <FormGroup
                                  switch
                                  className="justify-content-center align-items-center d-flex"
                                >
                                  <Input
                                    type="switch"
                                    checked={historyRow?.delete}
                                    disabled={!historyRow?.permission}
                                    name="delete"
                                    onChange={(e) =>
                                      handleChangeSubModuleSwitch(
                                        index,
                                        j,
                                        k,
                                        e.target.name,
                                        e.target.checked
                                      )
                                    }
                                  />
                                </FormGroup>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputData?.roleName) {
      toast.error("Role Name is Required!");
      setRoleNameError(true);
    } else {
      setLoading(true)
      let formData = new FormData();

      formData.append("roleName", inputData.roleName);
      formData.append("roleId", inputData.roleId);
      formData.append("permissionList", JSON.stringify(inputData.moduleData));

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}admin1/editpermissions`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setLoading(false);
          navigate("/rolemanagement");
        } else {
          setLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (location?.state?.permissions) {
      let arr = [];

      location?.state?.permissions?.forEach((er) => {
        if (er?.ModuleData?.length > 0) {
          let arr1 = [];

          er?.ModuleData?.forEach((ed) => {
            let arr4 = [];

            ed?.subModuleData?.forEach((qw) => {
              arr4.push({
                subModuleId: qw?.subModuleId,
                id: qw?.id,
                subModuleName: qw?.subModuleName,
                path: qw?.path,
                permission: qw?.access,
                create: qw?.create,
                edit: qw?.edit,
                print: qw?.print,
                delete: qw?.delete,
              });
            });

            arr1.push({
              moduleId: ed?.moduleId,
              id: ed?.id,
              moduleName: ed?.moduleName,
              subModuleData: arr4,
              permission: ed?.access,
              create: ed?.create,
              edit: ed?.edit,
              print: ed?.print,
              delete: ed?.delete,
            });
          });

          arr.push({
            mainModuleId: er?.mainModuleId,
            mainModuleName: er?.mainModuleName,
            moduleData: arr1,
            id: er?.id,
          });
        } else {
          arr.push({
            mainModuleId: er?.mainModuleId,
            id: er?.id,
            mainModuleName: er?.mainModuleName,
            permission: er?.access,
            create: er?.create,
            edit: er?.edit,
            print: er?.print,
            delete: er?.delete,
          });
        }
      });

      // Sort arr array by mainModuleId
      arr.sort((a, b) => a.mainModuleId - b.mainModuleId);

      setInputData({ ...inputData, moduleData: arr });
    }
    // eslint-disable-next-line
  }, [location?.state?.permissions]);

  return (
    <PageLayout
      title={"Edit Role"}
      backBtnTitle={"back"}
      backBtnTitleLink={"/rolemanagement"}
    >
      <FormGroup>
        <Row className="g-3" style={{ margin: 20 }}>
          <Col lg={6} md={6} className={`d-flex  ${isMobile ? "flex-column" : "" }`}>
            <Col md={5}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Role Name{" "} <span className="required_span"> * </span>
              </Label>
            </Col>
            <Col sm={7} className={`${isMobile ? "" : "pl-5"}`}>
              <Input
                id="exampleEmail"
                name="roleName"
                value={inputData?.roleName}
                onChange={(e) => {
                  handleChangeInput(e.target.name, e.target.value);
                }}
                className={`cust-input ${roleNameError ? "border-danger" : ""}`}
                type="text"
              />
            </Col>
          </Col>
        </Row>
      </FormGroup>

      {/* Table View */}
      <div
          className="d-grid"
          style={{ margin: "0 20px", border: "1px solid #DEE2E6" }}
        >
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {/* <TableCell /> */}
                <TableCell>Main Module Name</TableCell>
                <TableCell align="center">Permission</TableCell>
                <TableCell align="center">Create</TableCell>
                <TableCell align="center">Edit</TableCell>
                <TableCell align="center">Print</TableCell>
                <TableCell align="center">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inputData?.moduleData?.map((row, index) => (
                <RowColumn key={row.name} row={row} index={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Submit Btn */}

        <div className="mt-4 d-flex justify-content-end">
          <button
            type="button"
            className="custom-btn-page-layout btn text-white "
            onClick={(e) => handleSubmit(e)}
            disabled={loading}
          >
            {loading ? <ClipLoader color="#75D100" size={25} /> : "Submit"}
          </button>
        </div>
      </div>
    </PageLayout>
  );
};

export default EditRole;
