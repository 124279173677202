import React, { useCallback, useEffect, useMemo, useState } from "react";
// import useBreakPoints from "../../hooks/useBreakPoints";
import PageLayout from "../../../Component/PageLayout";
import { Col, Row } from "reactstrap";
import { logOutRequest } from "../../../store/reducers/layoutReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TableContainer from "../../../Component/TableContainer";
import DeleteModal from "../../../Component/DeleteModal";
import { Button, CircularProgress, TextField } from "@mui/material";
import EditPriceListGroup from "./EditPriceListGroup";
import { textFieldProps } from "../../../common/commonControlProps";
import { Save } from "@mui/icons-material";

const AddPriceListGroup = () => {
  const { permissions, token, loginDetails } = useSelector(
    (state) => state.layout
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let permissionsObj = {};
  // eslint-disable-next-line
  permissions?.filter((er) => {
    if (er?.ModuleData?.length > 0) {
      // eslint-disable-next-line
      let filtererdDarta = er?.ModuleData?.filter((ed) => {
        if (ed?.subModuleData?.length > 0) {
          // eslint-disable-next-line
          ed?.subModuleData?.map((qw) => {
            if (qw?.path === "/pricelist") {
              permissionsObj = qw;
            }
          });
        }
      });
      return filtererdDarta;
    }
  });

  let initialState = {};

  if (!permissionsObj?.edit && !permissionsObj?.delete) {
    initialState.hiddenColumns = ["Action"];
  }

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(5); // default limit
  const [offset, setOffset] = useState(0); // default offset
  const [counter, setCounter] = useState(0); // default offset
  const [deleteModal, setDeleteModal] = useState(false);
  const [priceListGroupList, setPriceListGroupList] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [singleData, setSingleData] = useState();
  let todayDate = new Date();
  const [inputData, setInputData] = useState({
    priceListName: "",
  });
  const [inputDataError, setInputDataError] = useState({
    priceListName: "",
  });

  const getPriceListGroupAPi = useCallback(async (limit, offset) => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/pricelistgroup?limit=${limit}&offset=${offset}&companyId=${loginDetails?.companyId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        setPriceListGroupList(_data?.priceGroupList);
        setTotalCount(_data?.paginationDetails?.count);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPriceListGroupAPi(limit, offset);
    // eslint-disable-next-line
  }, []);

  const handleChangeInput = (name, value) => {
    setInputData({ ...inputData, [name]: value });
    if (value?.length > 0) {
      setInputDataError({ ...inputDataError, [name]: false });
    } else {
      setInputDataError({ ...inputDataError, [name]: true });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputData.priceListName) {
      toast.error("Price List Name is Required!");
      setInputDataError({ ...inputDataError, priceListName: true });
    } else {
      setSubmitLoading(true);
      let formData = new FormData();
      formData.append("priceListName", inputData.priceListName);
      formData.append("financialYearId", "1");
      formData.append("cropyearId", "1");

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/addpricelistgroup`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setSubmitLoading(false);
          setInputData({
            priceListName: "",
          });
          getPriceListGroupAPi(limit, offset);
          setCounter(counter + 1);
        } else {
          setSubmitLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setSubmitLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  //   handleDelete Btn
  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    let formData = new FormData();
    formData.append("id", deleteId);
    formData.append("isDelete", "Yes");

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/deletepricelistgroup`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        getPriceListGroupAPi(limit, offset);
        setDeleteModal(false);
        toast?.success(_data?.message);
        setDeleteLoading(false);
      } else {
        setDeleteLoading(false);
        setDeleteModal(false);

        toast.error(_data?.message);
      }
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
    }
  };

  const column = useMemo(
    () => [
      {
        Header: "Price List Name",
        accessor: "priceListName",
        filterable: false,
      },
      {
        id: "Action",
        Header: () => (
          <span
            className="d-flex justify-content-center"
            style={{ textAlign: "center" }}
          >
            Action
          </span>
        ),
        Cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <div className="hstack gap-2">
                {/* Edit Button */}
                {permissionsObj?.edit && (
                  <button
                    className="border-0"
                    style={{ background: "transparent" }}
                    onClick={() => {
                      setEditModal(true);
                      setSingleData(cellProps.row.original);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_32_318)">
                        <path
                          d="M6.87549 2.5H2.50049C2.16897 2.5 1.85103 2.6317 1.6166 2.86612C1.38218 3.10054 1.25049 3.41848 1.25049 3.75V12.5C1.25049 12.8315 1.38218 13.1495 1.6166 13.3839C1.85103 13.6183 2.16897 13.75 2.50049 13.75H11.2505C11.582 13.75 11.9 13.6183 12.1344 13.3839C12.3688 13.1495 12.5005 12.8315 12.5005 12.5V8.125"
                          stroke="#3E7531"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.563 1.56249C11.8116 1.31385 12.1489 1.17416 12.5005 1.17416C12.8521 1.17416 13.1893 1.31385 13.438 1.56249C13.6866 1.81113 13.8263 2.14836 13.8263 2.49999C13.8263 2.85162 13.6866 3.18885 13.438 3.43749L7.50049 9.37499L5.00049 9.99999L5.62549 7.49999L11.563 1.56249Z"
                          stroke="#3E7531"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_32_318">
                          <rect
                            width="15"
                            height="15"
                            fill="white"
                            transform="translate(0.000488281)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                )}

                {/* Delete Button */}
                {permissionsObj?.delete && (
                  <button
                    className="border-0"
                    style={{ background: "transparent" }}
                    onClick={() => {
                      setDeleteModal(true);
                      setDeleteId(cellProps.row.original.id);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_32_330)">
                        <path
                          d="M6.63507 -0.0178833C5.50545 -0.0178833 4.59795 0.92077 4.59795 2.05558V2.30769H2.30814C1.99026 2.30769 1.73122 2.56673 1.73122 2.88462V3.46154H1.1543V4.61539H2.30814V13.2692C2.30814 14.2183 3.08987 15 4.03891 15H10.962C11.911 15 12.6928 14.2183 12.6928 13.2692V4.61539H13.8466V3.46154H13.2697V2.88462C13.2697 2.56673 13.0106 2.30769 12.6928 2.30769H10.403V2.055C10.403 0.920771 9.49545 -0.0178833 8.36584 -0.0178833H6.63507ZM6.63507 1.17173H8.36584C8.82968 1.17173 9.21334 1.55019 9.21334 2.055V2.30769H5.78699V2.055C5.78699 1.55019 6.17064 1.17231 6.63449 1.17231L6.63507 1.17173ZM3.46199 4.61539H6.41872C6.49026 4.62289 6.56122 4.63327 6.63507 4.63327H8.36584C8.43968 4.63327 8.51007 4.62289 8.58218 4.61539H11.5389V13.2692C11.5389 13.594 11.2868 13.8462 10.962 13.8462H4.03891C3.7141 13.8462 3.46199 13.594 3.46199 13.2692V4.61539ZM4.61584 5.76923V12.6923H5.76968V5.76923H4.61584ZM6.92353 5.76923V12.6923H8.07737V5.76923H6.92353ZM9.23122 5.76923V12.6923H10.3851V5.76923H9.23122Z"
                          fill="#EC0505"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_32_330">
                          <rect
                            width="15"
                            height="15"
                            fill="white"
                            transform="translate(0.000488281)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                )}
              </div>
            </div>
          );
        },
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  const onSubmitModal = () => {
    getPriceListGroupAPi(limit, offset);
    setEditModal(false);
  };

  return (
    <PageLayout title={"Farmers Management- Price List"} loading={loading}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row className="g-2 m-4">
          <Col lg={3} md={12} sm={12} className="d-flex">
            <TextField
              label="Price List Name *"
              name="priceListName"
              value={inputData?.priceListName}
              error={inputDataError?.priceListName}
              onChange={(e) => {
                handleChangeInput(e.target.name, e.target.value);
              }}
              type="text"
              {...textFieldProps}
            />
          </Col>
          <Col lg={2} md={2} sm={12}>
            <Button
              className="custom-btn-page-layout text-white"
              startIcon={
                submitLoading ? (
                  <CircularProgress size={15} color="secondary" />
                ) : (
                  <Save />
                )
              }
              fullWidth
              type="submit"
              disabled={submitLoading}
            >
              {` Save`}
            </Button>
          </Col>
        </Row>
      </form>

      <div className="mt-5">
        <Col md={12}>
          {loading ? (
            ""
          ) : (
            <div
              className="d-grid"
              style={{ margin: "0 20px", border: "1px solid #DEE2E6" }}
            >
              <TableContainer
                columns={column}
                data={priceListGroupList || []}
                isGlobalFilter={true}
                isAddUserList={false}
                tableClass={"table-sm"}
                divClass={"table-responsive"}
                showPagination={true}
                initialState={{ pageSize: 10, pageIndex: 0 }}
              />
            </div>
          )}
        </Col>
      </div>
      {editModal && (
        <EditPriceListGroup
          editModal={editModal}
          onCloseModal={() => setEditModal(false)}
          onSubmitModal={onSubmitModal}
          singleData={singleData}
        />
      )}

      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={handleDeleteRequest}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
    </PageLayout>
  );
};

export default AddPriceListGroup;
