import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageLayout from "../../../Component/PageLayout";

import CustomAutoComplete from "../../../Component/CustomAutoComplete";
import { Col, Input, InputGroup, InputGroupText, Row } from "reactstrap";
import { logOutRequest } from "../../../store/reducers/layoutReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import StateOption from "../../../hooks/StateOptions";
import DistrictOption from "../../../hooks/DistrictOption";
import TalukaOption from "../../../hooks/TalukaOption";
import VillageOption from "../../../hooks/VillageOption";
import { ClipLoader } from "react-spinners";
import TableContainer from "../../../Component/TableContainer";
import CustomPagination from "../../../Component/CustomPagination";
import DeleteModal from "../../../Component/DeleteModal";

const VillageWiseFarmerAllocation = () => {
  const { permissions, token, loginDetails } = useSelector(
    (state) => state.layout
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let permissionsObj = {};
  // eslint-disable-next-line
  permissions?.filter((er) => {
    if (er?.ModuleData?.length > 0) {
      // eslint-disable-next-line
      let filtererdDarta = er?.ModuleData?.filter((ed) => {
        if (ed?.subModuleData?.length > 0) {
          // eslint-disable-next-line
          ed?.subModuleData?.map((qw) => {
            if (qw?.path === "/farmerallocationfarmerwise") {
              permissionsObj = qw;
            }
          });
        }
      });
      return filtererdDarta;
    }
  });

  let initialState = {};

  if (!permissionsObj?.edit && !permissionsObj?.delete) {
    initialState.hiddenColumns = ["Action"];
  }

  const { getStateOption } = StateOption();
  const { getDistrictOption } = DistrictOption();
  const { getTalukaOption } = TalukaOption();
  const { getVillageOption } = VillageOption();

  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [unitOptions, setUnitOptions] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [farmerListMappingList, setFarmerMappingList] = useState([]);
  const [counter, setCounter] = useState(0); // default offset
  const [filteredData, setFilteredData] = useState([]);
  const [allocatedFarmerArr, setAllocatedFarmerArr] = useState([]);
  console.log('allocatedFarmerArr: ', allocatedFarmerArr);

  const [searchInput, setSearchInput] = useState({
    farmerName: "",
    villageName: "",
    checkBox: false,
  });
  const [inputData, setInputData] = useState({
    employeeId: "",
    unitId: "",
    stateId: "",
    distId: "",
    talukaId: "",
    villages: [],
  });
  const [inputDataError, setInputDataError] = useState({
    employeeId: false,
    unitId: false,
    stateId: false,
    distId: false,
    talukaId: false,
    villageId: false,
  });
  console.log("inputData: ", inputData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    let formData = new FormData();
    formData.append("allocationData", JSON.stringify(allocatedFarmerArr));
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/addfarmerallocation`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        toast?.success(_data?.message);
        setFarmerMappingList([])
        setAllocatedFarmerArr([])
        setFilteredData([])
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
        toast?.error(_data?.message);
      }
    } catch (error) {
      setSubmitLoading(false);
      toast?.error(error);
      console.log(error);
    }
  };
  const getUnitOptionsListAPi = useCallback(async () => {
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}company/mappedunits`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
      }

      if (_data?.status) {
        let arr = [];
        // eslint-disable-next-line
        console.log("_data: ", _data);
        _data?.unitNamesWithIds?.map((er) => {
          arr.push({ label: er?.name, value: er.id });
        });
        setUnitOptions(arr);
      } else {
        toast.error(_data?.error);
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, []);

  const getEmployeeOptionsListAPi = useCallback(async () => {
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}company/employeelist`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
      }

      if (_data?.status) {
        let arr = [];
        // eslint-disable-next-line
        _data?.employeeList?.map((er) => {
          arr.push({ label: er?.employeeName, value: er.id });
        });
        setEmployeeOptions(arr);
      } else {
        toast.error(_data?.error);
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, []);

  const getStateOptionsListAPi = useCallback(async (unitId) => {
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}company/mappedstates?unitId=${unitId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
      }

      if (_data?.status) {
        // eslint-disable-next-line
        const uniqueStates = new Set();
        const result = [];

        for (const item of _data?.unitRelatedRecords) {
          const stateName = item.state.stateName;
          if (!uniqueStates.has(stateName)) {
            uniqueStates.add(stateName);
            result.push({
              label: item?.state?.stateName,
              value: item?.state?.id,
            });
          }
        }
        console.log("result: ", result);

        setStateOptions(result);
      } else {
        toast.error(_data?.error);
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, []);

  const getDistrictOptionsListAPi = useCallback(
    async (stateId) => {
      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}company/mappeddistricts?unitId=${inputData?.unitId}&stateId=${stateId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
        }

        if (_data?.status) {
          // eslint-disable-next-line
          const uniqueStates = new Set();
          const result = [];

          for (const item of _data?.unitStateRelatedRecords) {
            const districtName = item.district.districtName;
            if (!uniqueStates.has(districtName)) {
              uniqueStates.add(districtName);
              result.push({
                label: item?.district?.districtName,
                value: item?.district?.id,
              });
            }
          }
          console.log("result: ", result);

          setDistrictArray(result);
        } else {
          toast.error(_data?.error);
        }
      } catch (error) {}
      // eslint-disable-next-line
    },
    [inputData]
  );
  const getTalukaOptionsListAPi = useCallback(
    async (districtId) => {
      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}company/mappedtaluka?unitId=${inputData?.unitId}&stateId=${inputData?.stateId}&districtId=${districtId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
        }

        if (_data?.status) {
          // eslint-disable-next-line
          const uniqueStates = new Set();
          const result = [];

          for (const item of _data?.unitStateDistrictRelatedRecords) {
            const talukaName = item.taluka.talukaName;
            if (!uniqueStates.has(talukaName)) {
              uniqueStates.add(talukaName);
              result.push({
                label: item?.taluka?.talukaName,
                value: item?.taluka?.id,
              });
            }
          }
          console.log("result: ", result);

          setTalukaArray(result);
        } else {
          toast.error(_data?.error);
        }
      } catch (error) {}
      // eslint-disable-next-line
    },
    [inputData]
  );
  const getVillageOptionsListAPi = useCallback(
    async (talukaId) => {
      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}company/mappedvillages?unitId=${inputData?.unitId}&stateId=${inputData?.stateId}&districtId=${inputData?.distId}&talukaId=${talukaId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
        }

        if (_data?.status) {
          // eslint-disable-next-line
          const uniqueStates = new Set();
          const result = [];

          for (const item of _data?.unitStateDistrictTalukaRelatedRecords) {
            const villageName = item.village.villageName;
            if (!uniqueStates.has(villageName)) {
              uniqueStates.add(villageName);
              result.push({
                label: item?.village?.villageName,
                value: item?.village?.id,
              });
            }
          }
          console.log("result: ", result);

          setVillageArray(result);
        } else {
          toast.error(_data?.error);
        }
      } catch (error) {}
      // eslint-disable-next-line
    },
    [inputData]
  );

  const [districtArray, setDistrictArray] = useState([]);
  console.log("districtArray: ", districtArray);
  const [talukaArray, setTalukaArray] = useState([]);
  const [villageArray, setVillageArray] = useState([]);

  const handleSearch = async (e) => {
    e.preventDefault();

    if (!inputData.employeeId) {
      toast.error("Field Technician Name is Required!");
      setInputDataError({ ...inputDataError, employeeId: true });
    } else if (!inputData.unitId) {
      toast.error("Loaction Name is Required!");
      setInputDataError({ ...inputDataError, unitId: true });
    } else if (!inputData.stateId) {
      toast.error("State is Required!");
      setInputDataError({ ...inputDataError, stateId: true });
    } else if (!inputData.distId) {
      toast.error("District is Required!");
      setInputDataError({ ...inputDataError, distId: true });
    } else if (!inputData.talukaId) {
      toast.error("Taluka is Required!");
      setInputDataError({ ...inputDataError, talukaId: true });
    } else if (inputData.villages?.length === 0) {
      toast.error("Village is Required!");
      setInputDataError({ ...inputDataError, villageId: true });
    } else {
      setSearchLoading(true);
      // let formData = new FormData();
      let arr = [];
      inputData.villages?.map((er) => {
        arr.push(er?.value);
      });

      // formData.append("companyUnitId", inputData.unitId);
      // formData.append("employeeId", inputData.employeeId);
      // formData.append("stateId", inputData.stateId);
      // formData.append("districtId", inputData.distId);
      // formData.append("talukaId", inputData.talukaId);
      // formData.append("villages", JSON.stringify(arr));

      try {
        const apiCall = await fetch(
          `${
            process.env.REACT_APP_API_URL
          }farmer/getfarmerallocationlist?unitId=${inputData?.unitId}&stateId=${
            inputData?.stateId
          }&districtId=${inputData?.distId}&talukaId=${
            inputData?.talukaId
          }&villageId=${JSON.stringify(inputData?.villages)}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            // body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setSearchLoading(false);
          let arr = [];
          _data?.farmerAllocationList?.map((er) => {
            let employee = employeeOptions?.find(
              (ed) => ed?.value === inputData?.employeeId
            );
            arr.push({ ...er, employee, allocatedStatus: false });
          });
          console.log("arr: ", arr);
          setFarmerMappingList(arr);
          setFilteredData(arr);
          setInputData({
            unitId: "",
            stateId: "",
            distId: "",
            talukaId: "",
            villages: [],
          });
          setDistrictArray([]);
          setTalukaArray([]);
          setVillageArray([]);
          setStateOptions([]);
          setCounter(counter + 1);
        } else {
          setSearchLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setSearchLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getUnitOptionsListAPi();

    getEmployeeOptionsListAPi();
    // eslint-disable-next-line
  }, [getUnitOptionsListAPi, getEmployeeOptionsListAPi]);

  const column = useMemo(
    () => [
      {
        id: "sr",
        Header: "Sr. No.",
        Cell: (cellProps) => {
          return <span>{cellProps?.row?.index + 1}</span>;
        },
      },
      {
        id: "farmer",
        Header: "Farmer Name",
        accessor: "name",
        filterable: false,
      },
      {
        id: "Village",
        Header: "Village Name",
        accessor: "village.villageName",
        filterable: false,
      },
      {
        id: "Technician",
        Header: "Technician Name",
        accessor: "employee.label",
        filterable: false,
      },

      {
        id: "CheckBox",
        Header: () => (
          <span
            className="d-flex justify-content-center"
            style={{ textAlign: "center" }}
          >
            Check Box
          </span>
        ),
        Cell: (cellProps) => {
          console.log('cellProps?.row?.original?.allocatedStatus: ', allocatedFarmerArr?.find((er) => er?.id === cellProps?.row?.original?.id));
          console.log('cellProps.row.index: ', cellProps.row.index);
          return (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <input
                type="checkbox"
                checked={allocatedFarmerArr?.find((er) => er?.id === cellProps?.row?.original?.id) ? true: false }
                onChange={(e) =>
                  handleCheckBox( e.target.checked, cellProps.row.original)
                }
              />
            </div>
          );
        },
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    [allocatedFarmerArr]
  );

  const filterColumn = () => {
    return (
      <tr
        style={{
          borderTop: "2px solid #3E7531",
          borderBottom: "2px solid #3E7531",
        }}
      >
        <th></th>
        <th>
          <input
            style={{ height: 30, border: "none" }}
            className="cust-input"
            value={searchInput?.farmerName}
            type="text"
            onChange={(e) => {
              setSearchInput({ ...searchInput, farmerName: e.target.value });
            }}
          />
        </th>
        <th>
          <input
            style={{ height: 30, border: "none" }}
            className="cust-input"
            value={searchInput?.villageName}
            type="text"
            onChange={(e) => {
              setSearchInput({ ...searchInput, villageName: e.target.value });
            }}
          />
        </th>
        <th></th>
        <th>
          <select
            value={searchInput?.checkBox}
            onChange={(e) => {
              setSearchInput({ ...searchInput, checkBox: e.target.value });
            }}
          >
            <option value={"true"}>True</option>
            <option value={"false"}>False</option>
          </select>
        </th>
      </tr>
    );
  };

  useEffect(() => {
    // Call the custom filter function whenever 'data' or 'searchInput' changes
    if (farmerListMappingList?.length > 0) {
      customFilter();
    }
  }, [ searchInput]);

  function customFilter() {
    // Implement your custom filter logic here
    const filteredData = farmerListMappingList.filter((item) => {
      console.log("item: ", item);
      // Check if each property in searchInput matches the corresponding item's property
      const farmerMatch = item.name
        .toLowerCase()
        .includes(searchInput.farmerName);
      const VillageMatch = item.village.villageName
        .toLowerCase()
        .includes(searchInput.villageName);
      // const CheckBoxMatch = item.allocatedStatus === (searchInput.checkBox);

      // Combine all criteria with '&&' to filter
      return VillageMatch && farmerMatch;
    });

    console.log('filteredData: ', filteredData);
    setFilteredData(filteredData);
  }

  const handleCheckBox = (checked, item) => {
   if(checked) {
    let arr = [...allocatedFarmerArr]
    arr.push(item)
    setAllocatedFarmerArr(arr)
   } else {
    let filtereddata = allocatedFarmerArr?.filter((er) => er?.id !== item?.id)

    setAllocatedFarmerArr(filtereddata)

   }
  }

  return (
    <PageLayout
      title={
        "Farmers Management- Farmer Allocation - Village Wise Farmer Allocation"
      }
      loading={loading}
    >
      <div className="" style={{ margin: 40 }}>
        <form onSubmit={(e) => handleSearch(e)}>
          <div>
            <Row className=" g-3 mt-2 ">
              <Col lg={3} md={12} sm={12} className="d-flex">
                <CustomAutoComplete
                  name="employeeId"
                  fullWidth
                  key={`employee-${employeeOptions?.length}-${counter}`}
                  label="Field Technician Name *"
                  value={
                    employeeOptions.map(
                      (product) => product?.label === inputData?.employeeId
                    )?.label
                  }
                  options={employeeOptions.map((product) => product.label)}
                  size="small"
                  getOptionLabel={(option) => option}
                  onChange={(event, option) => {
                    let selectedData = employeeOptions?.find(
                      (er) => er?.label === option
                    );
                    setInputData({
                      ...inputData,
                      employeeId: selectedData?.value,
                    });
                    setInputDataError({ ...inputDataError, employeeId: false });
                  }}
                  error={inputDataError?.employeeId}
                  renderOption={(props, option) => {
                    const { id, label } = employeeOptions.filter(
                      (product) => product.label === option
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label}
                      </li>
                    );
                  }}
                />
              </Col>
              <Col lg={3} md={12} sm={12} className="d-flex">
                <CustomAutoComplete
                  name="unitId"
                  fullWidth
                  key={`unit-${unitOptions?.length}-${counter}`}
                  label="Loaction Name *"
                  value={
                    unitOptions.map(
                      (product) => product?.label === inputData?.unitId
                    )?.label
                  }
                  options={unitOptions.map((product) => product.label)}
                  size="small"
                  getOptionLabel={(option) => option}
                  onChange={(event, option) => {
                    let selectedData = unitOptions?.find(
                      (er) => er?.label === option
                    );
                    getStateOptionsListAPi(selectedData?.value);
                    setInputData({
                      ...inputData,
                      unitId: selectedData?.value,
                      stateId: "",
                      distId: "",
                      talukaId: "",
                      villages: [],
                    });
                    setStateOptions([]);
                    setDistrictArray([]);
                    setTalukaArray([]);
                    setVillageArray([]);
                    setInputDataError({ ...inputDataError, unitId: false });
                  }}
                  error={inputDataError?.unitId}
                  renderOption={(props, option) => {
                    const { id, label } = unitOptions.filter(
                      (product) => product.label === option
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label}
                      </li>
                    );
                  }}
                />
              </Col>
              <Col lg={3} md={12} sm={12} className="d-flex">
                <CustomAutoComplete
                  name="stateId"
                  fullWidth
                  label="State *"
                  key={`state-${stateOptions?.length}-${counter}`}
                  value={
                    stateOptions.map(
                      (product) => product?.value === inputData?.stateId
                    )?.label
                  }
                  options={stateOptions.map((product) => product.label)}
                  size="small"
                  getOptionLabel={(option) => option}
                  onChange={(event, option) => {
                    let selectedData = stateOptions?.find(
                      (er) => er?.label === option
                    );
                    setInputData({
                      ...inputData,
                      stateId: selectedData?.value,
                      distId: "",
                      talukaId: "",
                      villages: [],
                    });
                    setDistrictArray([]);
                    setTalukaArray([]);
                    setVillageArray([]);
                    getDistrictOptionsListAPi(selectedData?.value);
                    setInputDataError({ ...inputDataError, stateId: false });
                  }}
                  error={inputDataError?.stateId}
                  renderOption={(props, option) => {
                    const { id, label } = stateOptions.filter(
                      (product) => product.label === option
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label}
                      </li>
                    );
                  }}
                />
              </Col>
              <Col lg={3} md={12} sm={12} className="d-flex">
                <CustomAutoComplete
                  name="distId"
                  fullWidth
                  key={`dist-${districtArray?.length}-${counter}`}
                  label="District *"
                  value={
                    districtArray.map(
                      (product) => product?.label === inputData?.distId
                    )?.label
                  }
                  options={districtArray.map((product) => product.label)}
                  size="small"
                  getOptionLabel={(option) => option}
                  onChange={(event, option) => {
                    let selectedData = districtArray?.find(
                      (er) => er?.label === option
                    );
                    setInputData({
                      ...inputData,
                      distId: selectedData?.value,
                      talukaId: "",
                      villages: [],
                    });
                    setTalukaArray([]);
                    setVillageArray([]);
                    getTalukaOptionsListAPi(selectedData?.value);
                    setInputDataError({ ...inputDataError, distId: false });
                  }}
                  error={inputDataError?.distId}
                  renderOption={(props, option) => {
                    const { id, label } = districtArray.filter(
                      (product) => product.label === option
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label}
                      </li>
                    );
                  }}
                />
              </Col>
            </Row>
          </div>
          <div>
            <Row className=" g-3 mt-2 ">
              <Col lg={3} md={12} sm={12} className="d-flex">
                <CustomAutoComplete
                  name="talukaId"
                  fullWidth
                  label="Taluka *"
                  key={`taluka-${talukaArray?.length}-${counter}`}
                  value={
                    talukaArray.map(
                      (product) => product?.label === inputData?.talukaId
                    )?.label
                  }
                  options={talukaArray.map((product) => product.label)}
                  size="small"
                  getOptionLabel={(option) => option}
                  onChange={(event, option) => {
                    let selectedData = talukaArray?.find(
                      (er) => er?.label === option
                    );
                    setInputData({
                      ...inputData,
                      talukaId: selectedData?.value,
                      villages: [],
                    });
                    setVillageArray([]);
                    getVillageOptionsListAPi(selectedData?.value);

                    setInputDataError({ ...inputDataError, talukaId: false });
                  }}
                  error={inputDataError?.talukaId}
                  renderOption={(props, option) => {
                    const { id, label } = talukaArray.filter(
                      (product) => product.label === option
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label}
                      </li>
                    );
                  }}
                />
              </Col>

              <Col lg={3} md={12} sm={12} className="d-flex">
                <CustomAutoComplete
                  name="VillageId"
                  fullWidth
                  multiple
                  label="Village *"
                  value={inputData?.villages}
                  options={villageArray}
                  size="small"
                  getOptionLabel={(option) => option?.label}
                  onChange={(event, option) => {
                    setInputData({ ...inputData, villages: option });
                    setInputDataError({ ...inputDataError, villageId: false });
                  }}
                  error={inputDataError?.villageId}
                  renderOption={(props, option) => {
                    const { id, label } = villageArray?.filter(
                      (product) => product.label === option?.label
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label}
                      </li>
                    );
                  }}
                />
              </Col>

              <Col lg={3} md={12} sm={12} className="d-flex">
                <button
                  type="submit"
                  disabled={searchLoading}
                  className="custom-btn-page-layout btn text-white"
                >
                  {searchLoading ? (
                    <ClipLoader color="#75D100" size={25} />
                  ) : (
                    "Search"
                  )}
                </button>
              </Col>
            </Row>
          </div>
        </form>

        <div className="mt-5">
          <Col md={12}>
            {loading ? (
              ""
            ) : (
              <>
                <div
              className="d-grid"
              style={{ margin: "0 20px", border: "1px solid #DEE2E6" }}
            >
                  <TableContainer
                    columns={column}
                    data={filteredData || []}
                    isGlobalFilter={false}
                    isAddUserList={false}
                    tableClass={"table-sm"}
                    divClass={"table-responsive"}
                    initialState={initialState}
                    filterColumn={filterColumn}
                    searchInput={searchInput}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    type="button"
                    disabled={submitLoading}
                    className="custom-btn-page-layout btn text-white"
                    onClick={(e) => handleSubmit(e)}
                  >
                    {submitLoading ? (
                      <ClipLoader color="#75D100" size={25} />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </>
            )}
          </Col>
        </div>
      </div>
    </PageLayout>
  );
};

export default VillageWiseFarmerAllocation;
