import { Col, Row } from "reactstrap";
import PageLayout from "../../Component/PageLayout";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { keys } from "../../config/keys";
import { textFieldProps } from "../../common/commonControlProps";
import { useState } from "react";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import { Download } from "@mui/icons-material";

const initialValue = {
  fromDate: dayjs(Date()),
  fromDateString: dayjs(Date()).format(keys().dbDateFormat),
  toDate: dayjs(Date()),
  toDateString: dayjs(Date()).format(keys().dbDateFormat),
};
const ExportToTally = () => {
  const [formData, setFormData] = useState(initialValue);
  const onDateChange = (name, dateValue) => {
    setFormData((prev) => ({
      ...prev,
      [name]: dateValue,
      [`${name}String`]: dayjs(dateValue).format("YYYY-MM-DD"),
    }));
  };

  return (
    <PageLayout title={"Export File"}>
      <Row className="m-4 g-2">
        <Col md={3} lg={3} sm={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From Date"
              format={keys().shortDateFormat}
              slotProps={{
                textField: {
                  ...textFieldProps,
                },
              }}
              onChange={(val) => onDateChange("fromDate", val)}
              value={formData?.fromDate}
              clearable
            />
          </LocalizationProvider>
        </Col>
        <Col md={3} lg={3} sm={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="To Date"
              format={keys().shortDateFormat}
              slotProps={{
                textField: {
                  ...textFieldProps,
                },
              }}
              onChange={(val) => onDateChange("toDate", val)}
              value={formData?.toDate}
              clearable
            />
          </LocalizationProvider>
        </Col>
        <Col md={2} lg={2} sm={12} className="d-flex align-items-end">
          <Button
            className="custom-btn-page-layout custom-btn-warning text-white "
            startIcon={<Download />}
            fullWidth
            type="button"
            //onClick={}
            //disabled={error[1].value || error[2].value || error[3].value}
          >
            {` Download File`}
          </Button>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default ExportToTally;
