import * as yup from "yup";

export const issueBagValidationSchema = yup.object({
  selectedFarmer: yup.string().required("Farmer is required"),
  bagstartno: yup.string().required("Bag Start number is required"),
  totalbagstoissue: yup
    .number()
    .required("No of bags to issue is required")
    .min(1, "Minimum no bags to issue is 1"),
});
