/* eslint-disable no-restricted-globals */
import axios from "../../common/axios";
import { toast } from "react-toastify";
import { keys } from "../../config/keys";
import { TEMPLATES_LIST } from "../constTypes";

export const save_templates = (data, callback) => async (dispatch) => {
  const url = data.id > 0 ? `update_template` : `save_template`;
  await axios
    .post(`${keys().ServicePath}/api/templates/${url}`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

const _dispatchActions = (type, data) => {
  return {
    type,
    payload: data,
  };
};
export const get_templates_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/templates/templates_list`)
    .then((res) => {
      dispatch(_dispatchActions(TEMPLATES_LIST, res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const get_templates_list_by_type = (type,callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/templates/templates_list/${type}`)
    .then((res) => {
      dispatch(_dispatchActions(TEMPLATES_LIST, res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};
