import React, { useState, useEffect, useMemo, useCallback } from "react";
import PageLayout from "../../Component/PageLayout";
import { Col, Row, FormGroup, Form } from "reactstrap";
import { Autocomplete, TextField } from "@mui/material";
import { Survey } from "survey-react-ui";
import { useSurveyHooks } from "./useSurveyHooks";
import { textFieldProps } from "../../common/commonControlProps";
import { useNavigate } from "react-router-dom";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import { Model } from "survey-react";
import { surveyJSON } from "./json";

const SuveyForm = () => {
  const navigate = useNavigate();

  const {
    register,
    errors,
    handleSubmit,
    onSurveyFormSubmit,
    onSurveyHandleChange,
    surveyList,
    surveyFormData,
    farmerDropdownList,
    onFarmerChange,
  } = useSurveyHooks();

  const [loading, setLoading] = useState(false);

  //    const onCompleteSurvey = (survey) => {
  //      // Access survey results
  //      const data = survey.data;
  //     //  setSurveyData(data);
  //      console.log("Survey results:", data);
  //    };

  return (
    <PageLayout title={"Survey Form"} loading={loading}>
      <Form onSubmit={handleSubmit(onSurveyFormSubmit)} className="m-4 g-2">
        <Row>
          <Col lg={6} md={6} sm={12}>
            <FormGroup>
              <Autocomplete
                id="farmerId"
                name="farmerId"
                size="small"
                options={farmerDropdownList}
                clearText={true}
                value={surveyFormData?.selectedFarmer}
                onChange={onFarmerChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...textFieldProps}
                    label="Select Farmer"
                  />
                )}
              />
            </FormGroup>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <FormGroup>
              <Autocomplete
                id="surveyId"
                name="surveyId"
                size="small"
                options={surveyList}
                clearText={true}
                value={surveyFormData?.selectedSurvey}
                onChange={onSurveyHandleChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...textFieldProps}
                    label="Select Survey  "
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            {surveyFormData.surveyId > 0 &&
              surveyFormData?.surveyModel !== null && (
                <Survey
                  model={surveyFormData?.surveyModel}
                  json={surveyJSON}
                  isPreview={false}
                />
                // <Survey json={surveyJSON} isPreview={false} />
                // <Survey.Survey
                //   // json={surveyFormData.surveyJSONData || {}}
                //   onC
                //   json={surveyFormData?.surveyJSONData}
                // />
              )}
          </Col>
        </Row>
      </Form>
    </PageLayout>
  );
};

export default SuveyForm;
