import React, { useMemo, useState } from "react";
import PageLayout from "../../Component/PageLayout";
import TableContainer from "../../Component/TableContainer";
import { useNavigate } from "react-router-dom";
import LoaderComponent from "../../Component/LoaderComponent";
import DeleteModal from "../../Component/DeleteModal";
import { usePlannerListHooks } from "./usePlannerHooks";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import { Col, Row } from "reactstrap";
import {
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CustomModal from "../../Component/CustomModal";
import { CalendarIcon } from "@mui/x-date-pickers";
import { keys } from "../../config/keys";
import { CalendarMonth, Description, Event } from "@mui/icons-material";

const PlannerList = () => {
  const navigate = useNavigate();
  const localizer = dayjsLocalizer(dayjs);
  const {
    column,
    permission,
    event_list,
    onGridDelete,
    setDeleteModal,
    deleteModal,
    deleteLoading,
    handleSelectEvent,
    showEventModal,
    setShowEventModal,
    selectedEvent,
  } = usePlannerListHooks();

  const [loading, setLoading] = useState(false);
  const [showgridview, setShowgridview] = useState(false);

  const CustomEvent = ({ event }) => {
    return (
      <span
        className="custom_rbc_event"
        style={{ backgroundColor: `${event.color}` }}
      >
        <strong>{event.title}</strong>
      </span>
    );
  };

  const { components } = useMemo(
    () => ({
      components: {
        event: CustomEvent,
      },
    }),
    []
  );

  return (
    <PageLayout
      title={"Yearly Calendar and Training Programs"}
      loading={loading}
      addBtn={permission?.create ? true : false}
      backBtnTitle={`Add New Event`}
      onClick={() => navigate("/eventplanner", { state: { id: 0 } })}
    >
      <Row className="m-4 g-2">
        <Col md={6} sm={12} lg={6}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Show Calendar View</Typography>
            <Switch
              onChange={(e) => setShowgridview(e.target.checked)}
              value={showgridview}
            />
            <Typography>Show Grid View</Typography>
          </Stack>
        </Col>
        {!showgridview ? (
          <Col md={12} lg={12} sm={12}>
            <Calendar
              components={components}
              localizer={localizer}
              events={event_list}
              startAccessor="start"
              endAccessor="end"
              onSelectEvent={handleSelectEvent}
              style={{ height: 500 }}
            />
          </Col>
        ) : (
          <Col md={12} lg={12} sm={12}>
            {loading ? (
              <LoaderComponent />
            ) : (
              <div className="d-grid" style={{ border: "1px solid #DEE2E6" }}>
                <TableContainer
                  columns={column}
                  data={event_list}
                  isGlobalFilter={true}
                  showColumnFilters={true}
                  isAddUserList={false}
                  tableClass={"table-sm"}
                  divClass={"table-responsive"}
                  showPagination={true}
                  initialState={{ pageSize: 10, pageIndex: 0 }}
                />
              </div>
            )}
          </Col>
        )}
      </Row>

      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={onGridDelete}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />

      {showEventModal && (
        <CustomModal
          title={selectedEvent?.title}
          modalState={showEventModal}
          onCloseModal={() => setShowEventModal(false)}
          customBtn={true}
          headerColor={selectedEvent?.color}
          showButton={false}
        >
          <Row className="g-2">
            <Col lg={6} sm={12}>
              <TextField
                label="Start Date"
                variant="standard"
                size="small"
                fullWidth
                value={dayjs(selectedEvent.starttime).format(
                  keys().dateTimeFormat
                )}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarMonth />
                    </InputAdornment>
                  ),
                }}
              />
            </Col>
            <Col lg={6} sm={12}>
              <TextField
                label="End Date"
                variant="standard"
                size="small"
                fullWidth
                value={dayjs(selectedEvent.endtime).format(
                  keys().dateTimeFormat
                )}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarMonth />
                    </InputAdornment>
                  ),
                }}
              />
            </Col>
            <Col lg={12} sm={12}>
              <TextField
                label="Event Type"
                variant="standard"
                size="small"
                fullWidth
                value={selectedEvent?.eventtype}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Event />
                    </InputAdornment>
                  ),
                }}
              />
            </Col>
            <Col lg={12} sm={12}>
              <TextField
                label="Description"
                multiline
                rows={3}
                variant="standard"
                size="small"
                fullWidth
                value={selectedEvent?.description}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Description />
                    </InputAdornment>
                  ),
                }}
              />
            </Col>
          </Row>
        </CustomModal>
      )}
    </PageLayout>
  );
};

export default PlannerList;
