/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { landDetailValidationSchema } from "./validationSchema";
import {
  yieldConfigurationActions,
  employeeActions,
  farmerActions,
  yieldEstimationActions,
  landdetailActions,
  productActions,
} from "../../redux/actions";
import { statusOption } from "./../../hooks/StaticData";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { usePermissionHooks } from "../../common/useCheckPermission";

const initialValues = {
  id: 0,
  farmerId: 0,
  selectedFarmer: null,
  surveynoId: 0,
  selectedSurveyno: null,
  landplotno: "",
  landname: "",
  actuallandsm: "",
  actuallandbigha: "",
  plantationlandsm: "",
  plantationlandbigha: "",
  selectedProduct: null,
  productid: 0,
};

export const useLandDetailHooks = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialValues);
  const [rowData, setRowData] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [singleData, setSingleData] = useState();
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();

  const {
    yieldConfigurationList,
    employeeDropdownList,
    farmerDropdownList,
    farmerLandList,
    getLandList,
    productlist,
  } = useSelector((obj) => obj);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(landDetailValidationSchema),
  });

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onFarmerchange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      farmerId: selectedOption?.value || 0,
      selectedFarmer: selectedOption || null,
    }));

    if (selectedOption !== null)
      dispatch(
        landdetailActions.getLandDetailListByFarmerId(selectedOption.value, {
          onSuccess: (res) => setRowData(res),
        })
      );
    else {
      setRowData([]);
    }
  };

  const onLandchange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      surveynoId: selectedOption?.value || 0,
      selectedSurveyno: selectedOption || null,
    }));
  };

  const onProductchange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      productid: selectedOption?.id || 0,
      selectedProduct: selectedOption || null,
    }));
  };

  const handleActualLandSMChange = (e) => {
    let actuallandbigha = 0;
    if (e.target.value > 0) {
      actuallandbigha = parseFloat(e.target.value / 2400).toFixed(2);
    }

    setFormData((prev) => ({
      ...prev,
      actuallandsm: e.target.value,
      actuallandbigha,
      plantationlandsm: e.target.value,
      plantationlandbigha: actuallandbigha,
    }));
  };

  const handleActualLandbighaChange = (e) => {
    let actuallandsm = 0;
    if (e.target.value > 0) {
      actuallandsm = e.target.value * 2400;
    }

    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
      actuallandsm,
      plantationlandsm: actuallandsm,
      plantationlandbigha: e.target.value,
    }));
  };

  const handlePlantationLandSMChange = (e) => {
    let plantationlandbigha = 0;
    if (e.target.value > 0) {
      plantationlandbigha = parseFloat(e.target.value / 2400).toFixed(2);
    }

    setFormData((prev) => ({
      ...prev,
      plantationlandsm: e.target.value,
      plantationlandbigha,
    }));
  };

  const handlePlantationLandbighaChange = (e) => {
    let plantationlandsm = 0;
    if (e.target.value > 0) {
      plantationlandsm = e.target.value * 2400;
    }

    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
      plantationlandsm,
    }));
  };

  const ondropdownchange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      isactive: selectedOption?.value || 1,
      selStatus: selectedOption || null,
    }));
  };

  const resetFormData = () => {
    setFormData(initialValues);
  };

  const onCancelClick = () => {
    setFormData(initialValues);
    setRowData([]);
  };

  const onSubmit = async () => {
    if (formData?.id > 0) {
      await dispatch(
        landdetailActions.updateLandDetail({ ...formData }, () => {
          toast.success("Land update successfully");
        })
      );
    } else {
      await dispatch(
        landdetailActions.saveLandDetail({ ...formData }, () => {
          toast.success("Land save successfully");
        })
      );
    }
    setFormData((prev) => ({
      ...prev,
      id: 0,
      landplotno: "",
      landname: "",
      actuallandsm: "",
      actuallandbigha: "",
      plantationlandsm: "",
      plantationlandbigha: "",
      farmerId: formData?.farmerId,
      selectedFarmer: farmerDropdownList.filter(
        (obj) => formData?.farmerId === obj.value
      )[0],
    }));
    dispatch(
      landdetailActions.getLandDetailListByFarmerId(formData?.farmerId, {
        onSuccess: (res) => setRowData(res),
      })
    );
    setDeleteModal(false);
    setEditModal(false);
  };

  useEffect(() => {
    console.log(state);
    if (state == null) {
      navigate("/farminglandlist");
      return;
    }
    setLoading(true);
    dispatch(
      farmerActions.getFarmerDropdownList({
        onSuccess: (farData) => {
          setLoading(false);
          if (state?.farmerId > 0) {
            let farm = farData.find((x) => x.value === state?.farmerId);
            setFormData((prev) => ({
              ...prev,
              farmerId: state?.farmerid,
              selectedFarmer: farm,
            }));
            dispatch(
              landdetailActions.getLandDetailListByFarmerId(state?.farmerId, {
                onSuccess: (res) => setRowData(res),
              })
            );
          }
        },
      })
    );
    dispatch(productActions.get_farming_product_list());
    setDeleteModal(false);
    setEditModal(false);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (getLandList.length > 0) {
      setRowData(getLandList);
    } else {
      setRowData([]);
    }
  }, [getLandList]);

  // useEffect(() => {
  //   if (farmerid > 0) {
  //   }
  // }, [farmerid, formData.farmerId]);

  useEffect(() => {
    if (editModal && singleData?.id > 0) {
      console.log(singleData);
      let obj = {
        id: singleData?.id,
        farmerId: singleData?.farmerId,
        surveynoId: singleData?.surveynoId,
        landplotno: singleData?.landplotno,
        landname: singleData?.landname,
        actuallandbigha: singleData?.actuallandbigha,
        actuallandsm: singleData?.actuallandsm,
        plantationlandbigha: singleData?.plantationlandbigha,
        plantationlandsm: singleData?.plantationlandsm,
        productid: singleData?.productid,
      };

      setFormData((prev) => ({
        ...prev,
        ...obj,
        selectedFarmer: farmerDropdownList.filter(
          (obj) => singleData?.farmerId === obj.value
        )[0],
        selectedSurveyno:
          singleData?.surveynoId > 0
            ? farmerLandList?.filter(
                (obj) => singleData?.surveynoId === obj.value
              )[0]
            : null,
        selectedProduct:
          singleData?.productid > 0
            ? productlist.filter((obj) => singleData?.productid === obj.id)[0]
            : null,
      }));
    }
  }, [editModal, singleData]);

  useEffect(() => {
    if (formData?.farmerId > 0) {
      dispatch(farmerActions.getFarmerLandList(formData?.farmerId || 0));
      setRowData(rowData.filter((obj) => obj.farmerId === formData.farmerId));
      if (formData.farmerId === 0) {
        setFormData((prev) => ({
          ...prev,
          surveynoId: 0,
          selectedSurveyno: null,
        }));
      }
    }
  }, [formData?.farmerId]);

  useEffect(() => {
    if (formData.farmerId > 0 && formData.id > 0) {
      setFormData((prev) => ({
        ...prev,
        selectedSurveyno: farmerLandList?.filter(
          (obj) => singleData?.surveynoId === obj.value
        )[0],
      }));
    }
  }, [farmerLandList]);

  const onGridDelete = (data) => {
    if (data.id > 0) {
      dispatch(landdetailActions.deleteLandDetail(data.id));
      dispatch(landdetailActions.getLandDetailListByFarmerId(data.farmerId));
      toast.success("Land detail delete successfully");
      setFormData(initialValues);
      setFormData((prev) => ({
        ...prev,
        farmerId: data?.farmerId,
        selectedFarmer: farmerDropdownList.filter(
          (obj) => singleData?.farmerId === obj.value
        )[0],
      }));
      setDeleteLoading(false);
      setDeleteModal(false);
    }
  };

  return {
    setFormData,
    formData,
    setDeleteModal,
    deleteModal,
    setEditModal,
    editModal,
    setDeleteId,
    deleteId,
    setDeleteLoading,
    deleteLoading,
    setSingleData,
    singleData,
    rowData,
    ondropdownchange,
    register,
    handleSubmit,
    errors,
    resetFormData,
    control,
    onSubmit,
    onGridDelete,
    employeeDropdownList,
    farmerDropdownList,
    farmerLandList,
    yieldConfigurationList,
    onFarmerchange,
    onLandchange,
    handleChange,
    handleActualLandSMChange,
    handleActualLandbighaChange,
    handlePlantationLandSMChange,
    handlePlantationLandbighaChange,
    onCancelClick,
    loading,
    setLoading,
    productlist,
    onProductchange,
  };
};

export const useFarmingLandListHooks = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { farmers_farming_land_list } = useSelector((obj) => obj);

  const permission = usePermissionHooks(location);

  useEffect(() => {
    dispatch(
      landdetailActions.getFarmersRegisteredLandList({
        onSuccess: () => {
          setLoading((prev) => !prev);
        },
      })
    );
  }, []);

  return { setLoading, loading, farmers_farming_land_list, permission };
};
