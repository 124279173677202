import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import PageLayout from "../../Component/PageLayout";
import {
  Button,
  FormControlLabel,
  InputAdornment,
  TextField,
} from "@mui/material";
import { textFieldProps } from "../../common/commonControlProps";
import { ErrorMessage } from "../../common/Error";
import {
  CalendarMonth,
  Cancel,
  Description,
  Event,
  Save,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { usePlannerHooks } from "./usePlannerHooks";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { keys } from "../../config/keys";
import { Calendar, momentLocalizer, dayjsLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import VirtualizeAutoComplete from "../../common/VirtualizedAutoComplete";
import CustomModal from "../../Component/CustomModal";

const EventPlanner = () => {
  const navigate = useNavigate();

  const localizer = dayjsLocalizer(dayjs);

  const events = [
    {
      id: 1,
      title: "Long Event",
      start: new Date(2024, 4, 28),
      end: new Date(2024, 4, 28),
    },
    {
      id: 1,
      title: "Event 1",
      start: new Date(2024, 4, 28, 13, 0, 0),
      end: new Date(2024, 4, 28),
    },
    {
      id: 1,
      title: "Event 2",
      start: new Date(2024, 4, 28, 15, 0, 0),
      end: new Date(2024, 4, 28, 16, 0, 0),
    },
    {
      id: 2,
      title: "DTS STARTS",
      start: new Date(2024, 4, 30),
      end: new Date(2024, 5, 1, 23, 59, 0),
    },
  ];

  const {
    handleChange,
    formData,
    errors,
    onDateChange,
    onEndDateChange,
    onTimeChange,
    register,
    handleSubmit,
    onSubmit,
    event_list,
    event_type_list,
    onEventTypeChange,
    handleSelectEvent,
    showEventModal,
    setShowEventModal,
    selectedEvent,
  } = usePlannerHooks();

  const CustomEvent = ({ event }) => {
    return (
      <span
        className="custom_rbc_event"
        style={{ backgroundColor: `${event.color}` }}
      >
        <strong>{event.title}</strong>
      </span>
    );
  };

  const { components, defaultDate } = useMemo(
    () => ({
      components: {
        event: CustomEvent,
      },
    }),
    []
  );

  return (
    <PageLayout title={"Yearly Event Planner"}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="m-4 g-2">
          <Col lg={2} md={2} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Event Date"
                format={keys().shortDateFormat}
                slotProps={{
                  textField: {
                    ...textFieldProps,
                  },
                }}
                onChange={onDateChange}
                value={formData?.startdate}
                clearable
              />
            </LocalizationProvider>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Event Ens Date"
                format={keys().shortDateFormat}
                slotProps={{
                  textField: {
                    ...textFieldProps,
                  },
                }}
                onChange={onEndDateChange}
                value={formData?.enddate}
                clearable
              />
            </LocalizationProvider>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Event time"
                value={formData.starttime}
                onChange={onTimeChange}
                slotProps={{
                  textField: { ...textFieldProps },
                }}
                clearable
              />
            </LocalizationProvider>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <VirtualizeAutoComplete
              name="eventtype"
              size="small"
              options={event_type_list}
              clearText={false}
              value={formData?.selectedeventtype}
              onChange={onEventTypeChange}
              renderInput={(params) => (
                <TextField
                  {...register("selectedeventtype")}
                  {...params}
                  label="Event Type"
                  error={errors?.selectedeventtype}
                  {...textFieldProps}
                />
              )}
            />
            {errors?.selectedeventtype && (
              <ErrorMessage message={errors?.selectedeventtype?.message} />
            )}
          </Col>
          <Col lg={8} md={8} sm={12}>
            <TextField
              {...register("title")}
              id="title"
              name="title"
              onChange={handleChange}
              value={formData.title}
              error={errors?.title}
              label={`Title`}
              {...textFieldProps}
            />
            {errors?.title && <ErrorMessage message={errors?.title?.message} />}
          </Col>
          <Col lg={12} md={12} sm={12}>
            <TextField
              multiline
              rows={2}
              {...register("description")}
              name="description"
              onChange={handleChange}
              value={formData.description}
              error={errors?.description}
              label={`Description`}
              title=""
              {...textFieldProps}
            />
            {errors?.description && (
              <ErrorMessage message={errors?.description?.message} />
            )}
          </Col>
          <Col lg={1} md={1} sm={12}>
            <Button
              className="custom-btn-page-layout text-white "
              startIcon={<Save />}
              fullWidth
              type="submit"
              size="small"
            >
              {` Save`}
            </Button>
          </Col>
          <Col lg={1} md={1} sm={12}>
            <Button
              type="button"
              className="custom-btn-page-layout custom-btn-danger "
              onClick={(e) => navigate("/eventlist")}
              startIcon={<Cancel />}
              size="small"
              fullWidth
            >
              Cancel
            </Button>
          </Col>
          <Col md={12} lg={12} sm={12}>
            <Calendar
              components={components}
              localizer={localizer}
              events={event_list}
              startAccessor="start"
              endAccessor="end"
              onSelectEvent={handleSelectEvent}
              style={{ height: 500 }}
            />
          </Col>
          {/* <Col lg={12} md={12} sm={12}>
            <CKEditor
              editor={Editor}
              data={formData.content}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                //console.log("Editor is ready to use!", editor.getData());
              }}
              onChange={(event, editor) => {
                console.log("event", event);
                console.log("event editor", editor.getData());
                console.log("event editor", editor.sourceElement);
                console.log("event editor", editor.data);
                handleEditorChange(editor);
              }}
              onBlur={(event, editor) => {
                //console.log("Blur.", editor.sourceElement);
              }}
              onFocus={(event, editor) => {
                // console.log("Focus.", editor.data);
              }}
            />
          </Col>
          <Col lg={3} md={3} sm={12}>
            <TextField
              disabled
              label={`File name`}
              helperText={"(Max size 150 KB)"}
              InputLabelProps={{ shrink: true }}
              value={(formData.file && formData.file?.name) || ""}
              {...textFieldProps}
            />
          </Col>
          <Col lg={2} md={2} sm={12} className="d-flex align-items-center">
            <FileToBase64 onChange={onFileChange} />
          </Col> */}
        </Row>
        {/* <Row className="m-4 g-2">
          <Col lg={1} md={1} sm={12}>
            <Button
              className="custom-btn-page-layout text-white "
              startIcon={<Save />}
              fullWidth
              type="submit"
              size="small"
            >
              {` Save`}
            </Button>
          </Col>
          <Col lg={1} md={1} sm={12}>
            <Button
              type="button"
              className="custom-btn-page-layout custom-btn-danger "
              onClick={(e) => navigate("/publishedlist")}
              startIcon={<Cancel />}
              size="small"
              fullWidth
            >
              Cancel
            </Button>
          </Col>
        </Row> */}
      </Form>
      {showEventModal && (
        <CustomModal
          title={selectedEvent?.title}
          modalState={showEventModal}
          onCloseModal={() => setShowEventModal(false)}
          customBtn={true}
          headerColor={selectedEvent?.color}
          showButton={false}
        >
          <Row className="g-2">
            <Col lg={6} sm={12}>
              <TextField
                label="Start Date"
                variant="standard"
                size="small"
                fullWidth
                value={dayjs(selectedEvent.starttime).format(
                  keys().dateTimeFormat
                )}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarMonth />
                    </InputAdornment>
                  ),
                }}
              />
            </Col>
            <Col lg={6} sm={12}>
              <TextField
                label="End Date"
                variant="standard"
                size="small"
                fullWidth
                value={dayjs(selectedEvent.endtime).format(
                  keys().dateTimeFormat
                )}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarMonth />
                    </InputAdornment>
                  ),
                }}
              />
            </Col>
            <Col lg={12} sm={12}>
              <TextField
                label="Event Type"
                variant="standard"
                size="small"
                fullWidth
                value={selectedEvent?.eventtype}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Event />
                    </InputAdornment>
                  ),
                }}
              />
            </Col>
            <Col lg={12} sm={12}>
              <TextField
                label="Description"
                multiline
                rows={3}
                variant="standard"
                size="small"
                fullWidth
                value={selectedEvent?.description}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Description />
                    </InputAdornment>
                  ),
                }}
              />
            </Col>
          </Row>
        </CustomModal>
      )}
    </PageLayout>
  );
};

export default EventPlanner;
