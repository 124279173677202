import React, { useCallback, useEffect, useState } from "react";
import PageLayout from "../../Component/PageLayout";
import {
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import { toast } from "react-toastify";
import { registrationTypeArray } from "../../hooks/StaticData";
import Select from "react-select";
import StateOption from "../../hooks/StateOptions";
import CountryOption from "../../hooks/CountryOption";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import useBreakPoints from "../../hooks/useBreakPoints";

const ViewVendorMaster = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useSelector((state) => state.layout);
  const { isTablet, isMobile } = useBreakPoints();
  const [step, setStep] = useState("step1");
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [countryArray, setCountryArray] = useState();
  // eslint-disable-next-line
  const [groupOptions, setgroupOptions] = useState([]);
  // eslint-disable-next-line
  const [stateArray, setStateArray] = useState();
  const [contactModalState, setContactModalState] = useState(false);
  const [editContactModalState, setEditContactModalState] = useState(false);
  const [viewContactModalState, setViewContactModalState] = useState(false);
  const [mailingAddressModalState, setMailingAdddressModalState] =
    useState(false);
  const [editMailingAddressModalState, setEditMailingAdddressModalState] =
    useState(false);
  const [viewMailingAddressModalState, setViewMailingAdddressModalState] =
    useState(false);
  const [mailingId, setMailingId] = useState();

  const { getStateOption } = StateOption();
  const { getCountryOption } = CountryOption();

  useEffect(() => {
    getCountryOption().then((res) => {
      setCountryArray(res);
    });
    getStateOption(location?.state?.mailingDetails?.[0]?.country?.id).then(
      (res) => {
        setStateArray(res);
      }
    );
    // eslint-disable-next-line
  }, []);

  const getGroupListAPi = useCallback(async () => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/grouplist`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];
        // eslint-disable-next-line
        _data?.groupList?.map((er) => {
          arr.push({ label: er?.groupName, value: er?.id });
        });

        setgroupOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getGroupListAPi();
  }, [getGroupListAPi]);

  const [inputData, setInputData] = useState({
    name: location?.state?.vendorName,
    vendorType: location?.state?.vendorType,
    description: location?.state?.description,
    // under: location?.state?.under,
    group: location?.state?.groupDetails?.groupName,
    defaultCreditPeriod: location?.state?.creditPeriod,
    specifyCreditLimit: location?.state?.creditLimit,
    maintainBalance:
      location?.state?.balanceBillByBill === "True" ? true : false,
    creditDaysVoucher:
      location?.state?.creditDaysCheck === "True" ? true : false,
    mailingName: location?.state?.name,
    addressLine1: location?.state?.mailingDetails?.[0]?.addressLine1,
    addressLine2: location?.state?.mailingDetails?.[0]?.addressLine2,
    addressLine3: location?.state?.mailingDetails?.[0]?.addressLine3,
    mobile: location?.state?.mailingDetails?.[0]?.mobile?.split("+91")[1],
    pincode: location?.state?.mailingDetails?.[0]?.pinCode,
    country: location?.state?.mailingDetails?.[0]?.country?.name,

    state: location?.state?.mailingDetails?.[0]?.state?.stateName,

    contactDetails:
      location?.state?.mailingDetails?.[0]?.provideContactDetails === "True"
        ? true
        : false,
    alterMailingDetails:
      location?.state?.mailingDetails?.[0]?.setMultiMailDetail === "True"
        ? true
        : false,
    contactPerson: location?.state?.mailingDetails?.[0]?.contactName,
    contactPhoneNo:
      location?.state?.mailingDetails?.[0]?.mailingPhone?.split("+91")[1],
    contactMobileNo:
      location?.state?.mailingDetails?.[0]?.mailingMobile?.split("+91")[1],
    contactEmail: location?.state?.mailingDetails?.[0]?.mailingEmail,
    CC: location?.state?.mailingDetails?.[0]?.cc,
    BCC: location?.state?.mailingDetails?.[0]?.bcc,
    website: location?.state?.mailingDetails?.[0]?.website,
    addressType: location?.state?.addressType,
    providebankDetails:
      location?.state?.bankDetails?.[0]?.provideBankDetails === "True"
        ? true
        : false,
    ignorePrefixies:
      location?.state?.taxDetails?.[0]?.ignorePrefixAndSuffix === "True"
        ? true
        : false,
    ledgerParty:
      location?.state?.taxDetails?.[0]?.isLedgerAsCommanParty === "True"
        ? true
        : false,
    assesseeOther:
      location?.state?.taxDetails?.[0]?.isOtherTerritory === "True"
        ? true
        : false,
    gstuin: location?.state?.taxDetails?.[0]?.gstinNo,
    registrationType: location?.state?.taxDetails?.[0]?.registrationType,
    panItNo: location?.state?.taxDetails?.[0]?.panNo,
    openingBalance: location?.state?.taxDetails?.[0]?.openingBalance,
  });

  const [mailingAddressDetails, setMailingAddressDetails] = useState(
    location?.state?.mailingDetails?.[0]?.multimailDetails?.length > 0
      ? JSON.parse(location?.state?.mailingDetails?.[0]?.multimailDetails)
      : []
  );

  const [bankDetails, setbankDetails] = useState([]);

  useEffect(() => {
    if (location?.state?.bankDetails) {
      let arr = [];
      // eslint-disable-next-line
      location?.state?.bankDetails?.map((er) => {
        arr.push({
          id: er?.id,
          provideBankDetails: er?.provideBankDetails,
          inFavourOf: er?.inFavourOf,
          accountNo: er?.accountNo,
          bankName: er?.bankName,
          transectionType: er?.transectionType,
          ifsCode: er?.ifsCode,
          swiftCode: er?.swiftCode,
          isDefault: er?.isDefault === "True" ? true : false,
        });
      });

      setbankDetails(arr);
    }
  }, [location?.state?.bankDetails]);

  const [singleMailingDetails, setSingleMailingDetails] = useState({
    addressType: "",
    address: "",
    country: "",
    state: "",
    pincode: "",
    contactPerson: "",
    phoneNo: "",
    mobileNo: "",
    faxNo: "",
    email: "",
    incomeTaxNo: "",
    salesTaxNo: "",
    cstNo: "",
    registrationType: "",
    GST: "",
  });

  const [singleContactData, setSingleContactData] = useState();

  // Accordian Toggle For Multiple land Registration
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  // handleChange Input
  const handleChangeInput = (name, value) => {
    setInputData({ ...inputData, [name]: value });
    if (name === "country") {
      getStateOption(value.value).then((res) => {
        setStateArray(res);
      });
    }

    if (name === "providebankDetails" && !value) {
      setbankDetails([]);
    } else if (name === "providebankDetails" && value) {
      setbankDetails([
        {
          inFavourOf: "",
          ifsCode: "",
          accountNo: "",
          swiftCode: "",
          bankName: "",
          transectionType: "",
        },
      ]);
    }
  };

  const handleNextBtn = (stepper) => {
    if (stepper === "step1") {
      if (!inputData?.name) {
        toast.error("Name is Required!");
      } else if (!inputData?.vendorType) {
        toast.error("Vendor Type is Required!");
      } else {
        setStep("step2");
      }
    }

    if (stepper === "step2") {
      if (!inputData?.name) {
        toast.error("Name is Required!");
      } else if (!inputData?.addressLine1) {
        toast.error("Address  is Required!");
      } else if (!inputData?.addressLine2) {
        toast.error("Address Line 2 is Required!");
      } else if (!inputData?.addressLine3) {
        toast.error("Address Line 3 is Required!");
      } else if (!inputData?.pincode) {
        toast.error("Pincode is Required!");
      } else if (
        inputData?.pincode?.length < 6 ||
        inputData?.pincode?.length > 6
      ) {
        toast.error("Pincode is not in Correct Format!");
      } else if (!inputData?.mobile) {
        toast.error("Mobile No is Required!");
      } else if (
        inputData?.mobile?.length < 10 ||
        inputData?.mobile?.length > 10
      ) {
        toast.error("Mobile No is not in Correct Format!");
      } else if (!inputData?.country) {
        toast.error("Country is Required!");
      } else if (!inputData?.state) {
        toast.error("State is Required!");
      } else {
        setStep("step3");
      }
    }

    if (stepper === "step3") {
      let error = false;
      // eslint-disable-next-line
      bankDetails?.map((er) => {
        if (!er?.bankName) {
          toast.error("Bank Name is Required!");
          error = true;
        } else if (!er?.ifsCode) {
          toast?.error("IFSC Code is Required!");
          error = true;
        } else if (!er?.accountNo) {
          toast?.error("Account NO is Required!");
          error = true;
        }
      });

      if (!error) {
        setStep("step4");
      }
    }
  };

  const onCloseModalContactDetails = () => {
    setInputData({
      ...inputData,
      contactDetails: false,
      contactPerson: "",
      contactPhoneNo: "",
      contactMobileNo: "",
      contactEmail: "",
      CC: "",
      BCC: "",
      website: "",
    });

    setContactModalState(false);
  };

  const onCloseModalMailingDetails = () => {
    setSingleMailingDetails({
      addressType: "",
      address: "",
      country: "",
      state: "",
      pincode: "",
      contactPerson: "",
      phoneNo: "",
      mobileNo: "",
      faxNo: "",
      email: "",
      incomeTaxNo: "",
      salesTaxNo: "",
      cstNo: "",
      registrationType: "",
      GST: "",
    });

    setMailingAdddressModalState(false);
  };

  const onCloseModalEditMailingDetails = () => {
    setSingleMailingDetails({
      addressType: "",
      address: "",
      country: "",
      state: "",
      pincode: "",
      contactPerson: "",
      phoneNo: "",
      mobileNo: "",
      faxNo: "",
      email: "",
      incomeTaxNo: "",
      salesTaxNo: "",
      cstNo: "",
      registrationType: "",
      GST: "",
    });

    setEditMailingAdddressModalState(false);
  };

  const handleAdd = () => {
    if (!inputData?.contactPerson) {
      toast.error("ContactPerson is Required ");
    } else {
      setInputData({ ...inputData, contactDetails: true });
      setContactModalState(false);
    }
  };

  const handleUpdate = () => {
    if (!singleContactData?.contactPerson) {
      toast.error("ContactPerson is Required ");
    } else {
      setInputData({
        ...inputData,
        contactDetails: true,
        contactPerson: singleContactData?.contactPerson,
        contactPhoneNo: singleContactData?.contactPhoneNo,
        contactMobileNo: singleContactData?.contactMobileNo,
        contactEmail: singleContactData?.contactEmail,
        CC: singleContactData?.CC,
        BCC: singleContactData?.BCC,
        website: singleContactData?.website,
      });
      setEditContactModalState(false);
    }
  };

  const onCloseModalEditContactDetails = () => {
    setEditContactModalState(false);
  };

  const onCloseModalViewContactDetails = () => {
    setViewContactModalState(false);
  };

  const handleAddMailingDetails = () => {
    setInputData({ ...inputData, alterMailingDetails: true });
    let arr = [...mailingAddressDetails];
    arr.unshift({
      id: arr.length + 1,
      addressType: singleMailingDetails.addressType,
      address: singleMailingDetails.address,
      country: singleMailingDetails.country,
      state: singleMailingDetails.state,
      pincode: singleMailingDetails.pincode,
      contactPerson: singleMailingDetails.contactPerson,
      phoneNo: singleMailingDetails.phoneNo,
      mobileNo: singleMailingDetails.mobileNo,
      faxNo: singleMailingDetails.faxNo,
      email: singleMailingDetails.email,
      incomeTaxNo: singleMailingDetails.incomeTaxNo,
      salesTaxNo: singleMailingDetails.salesTaxNo,
      cstNo: singleMailingDetails.cstNo,
      registrationType: singleMailingDetails.registrationType,
      GST: singleMailingDetails.GST,
    });
    setMailingAddressDetails(arr);
    setSingleMailingDetails({
      addressType: "",
      address: "",
      country: "",
      state: "",
      pincode: "",
      contactPerson: "",
      phoneNo: "",
      mobileNo: "",
      faxNo: "",
      email: "",
      incomeTaxNo: "",
      salesTaxNo: "",
      cstNo: "",
      registrationType: "",
      GST: "",
    });

    setMailingAdddressModalState(false);
  };

  const handleUpdateMailingDetails = () => {
    let arr = [...mailingAddressDetails];

    arr[mailingId] = singleMailingDetails;

    setMailingAddressDetails(arr);
    setSingleMailingDetails({
      addressType: "",
      address: "",
      country: "",
      state: "",
      pincode: "",
      contactPerson: "",
      phoneNo: "",
      mobileNo: "",
      faxNo: "",
      email: "",
      incomeTaxNo: "",
      salesTaxNo: "",
      cstNo: "",
      registrationType: "",
      GST: "",
    });

    setEditMailingAdddressModalState(false);
  };

  const onCloseModalViewMailingDetails = () => {
    setViewMailingAdddressModalState(false);
    setSingleMailingDetails({
      addressType: "",
      address: "",
      country: "",
      state: "",
      pincode: "",
      contactPerson: "",
      phoneNo: "",
      mobileNo: "",
      faxNo: "",
      email: "",
      incomeTaxNo: "",
      salesTaxNo: "",
      cstNo: "",
      registrationType: "",
      GST: "",
    });
  };
  return (
    <PageLayout title={"View vendor master"}>
      {/* Stepper View  */}
      <div className="farm-add-stepper">
        <div
          className={`${step === "step1" ? "farm-stepper-active" : ""} ${
            isTablet ? "step1-mobile-view" : "step1-farm-add"
          } `}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="24"
            viewBox="0 0 21 24"
            fill="none"
          >
            <g clipPath="url(#clip0_582_2379)">
              <path
                d="M10.5 12C12.0913 12 13.6174 11.3679 14.7426 10.2426C15.8679 9.11742 16.5 7.5913 16.5 6C16.5 4.4087 15.8679 2.88258 14.7426 1.75736C13.6174 0.632141 12.0913 0 10.5 0C8.9087 0 7.38258 0.632141 6.25736 1.75736C5.13214 2.88258 4.5 4.4087 4.5 6C4.5 7.5913 5.13214 9.11742 6.25736 10.2426C7.38258 11.3679 8.9087 12 10.5 12ZM8.35781 14.25C3.74063 14.25 0 17.9906 0 22.6078C0 23.3766 0.623438 24 1.39219 24H19.6078C20.3766 24 21 23.3766 21 22.6078C21 17.9906 17.2594 14.25 12.6422 14.25H8.35781Z"
                fill={step === "step1" ? "white" : "black"}
              />
            </g>
            <defs>
              <clipPath id="clip0_582_2379">
                <rect
                  width="21"
                  height="24"
                  fill={step === "step1" ? "white" : "black"}
                />
              </clipPath>
            </defs>
          </svg>
          {!isTablet && (
            <span
              className={`${step === "step1" ? "text-white" : "text-black"}`}
            >
              Vendor Details
            </span>
          )}
        </div>
        <div
          className={`${step === "step2" ? "farm-stepper-active" : ""}
          ${isTablet ? "step1-mobile-view" : " step2-farm-add"}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.60117 10.8C10.2377 10.8 10.8481 10.5471 11.2982 10.0971C11.7483 9.64697 12.0012 9.03652 12.0012 8.4C12.0012 7.76348 11.7483 7.15303 11.2982 6.70294C10.8481 6.25286 10.2377 6 9.60117 6C8.96465 6 8.3542 6.25286 7.90412 6.70294C7.45403 7.15303 7.20117 7.76348 7.20117 8.4C7.20117 9.03652 7.45403 9.64697 7.90412 10.0971C8.3542 10.5471 8.96465 10.8 9.60117 10.8Z"
              fill={step === "step2" ? "white" : "black"}
            />
            <path
              d="M8.03699 12.0983C8.14862 11.9479 8.29391 11.8257 8.46124 11.7416C8.62856 11.6574 8.81327 11.6135 9.00059 11.6135C9.1879 11.6135 9.37261 11.6574 9.53993 11.7416C9.70726 11.8257 9.85255 11.9479 9.96419 12.0983L12.2562 15.1848C12.3888 15.3634 12.4691 15.5753 12.4882 15.797C12.5073 16.0186 12.4644 16.2412 12.3643 16.4398C12.2642 16.6385 12.1109 16.8054 11.9214 16.922C11.732 17.0386 11.5138 17.1002 11.2914 17.1H6.70978C6.48744 17.1 6.26946 17.0382 6.08018 16.9215C5.89089 16.8049 5.73773 16.6379 5.6378 16.4393C5.53787 16.2407 5.49508 16.0182 5.51422 15.7967C5.53336 15.5751 5.61367 15.3633 5.74619 15.1848L8.03699 12.0983Z"
              fill={step === "step2" ? "white" : "black"}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.99983 13.8204L7.90063 15.3H10.099L8.99983 13.8204ZM9.96343 12.0983C9.8518 11.9479 9.7065 11.8257 9.53918 11.7416C9.37185 11.6574 9.18714 11.6135 8.99983 11.6135C8.81252 11.6135 8.62781 11.6574 8.46049 11.7416C8.29316 11.8257 8.14787 11.9479 8.03623 12.0983L5.74423 15.1848C5.61165 15.3634 5.53133 15.5753 5.51224 15.797C5.49315 16.0186 5.53604 16.2412 5.63612 16.4398C5.7362 16.6385 5.88953 16.8054 6.07899 16.922C6.26845 17.0386 6.48658 17.1002 6.70903 17.1H11.2906C11.513 17.1 11.731 17.0382 11.9202 16.9215C12.1095 16.8049 12.2627 16.6379 12.3626 16.4393C12.4626 16.2407 12.5053 16.0182 12.4862 15.7967C12.4671 15.5751 12.3868 15.3633 12.2542 15.1848L9.96223 12.0983H9.96343Z"
              fill={step === "step2" ? "white" : "black"}
            />
            <path
              d="M12.5931 10.9477C12.7057 10.8176 12.845 10.7133 13.0015 10.6417C13.1581 10.5702 13.3282 10.5332 13.5003 10.5332C13.6723 10.5332 13.8424 10.5702 13.999 10.6417C14.1555 10.7133 14.2948 10.8176 14.4075 10.9477L18.0159 15.1141C18.1666 15.288 18.2641 15.5015 18.2969 15.7293C18.3296 15.957 18.2962 16.1894 18.2006 16.3987C18.105 16.608 17.9512 16.7854 17.7576 16.9098C17.564 17.0341 17.3388 17.1002 17.1087 17.1001H9.89185C9.66175 17.1002 9.43648 17.0341 9.24287 16.9098C9.04927 16.7854 8.8955 16.608 8.7999 16.3987C8.70429 16.1894 8.67087 15.957 8.70363 15.7293C8.73639 15.5015 8.83394 15.288 8.98465 15.1141L12.5931 10.9477Z"
              fill={step === "step2" ? "white" : "black"}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.5003 12.6493L11.2047 15.3001H15.7959L13.5003 12.6493ZM14.4075 10.9477C14.2948 10.8176 14.1555 10.7133 13.999 10.6417C13.8424 10.5702 13.6723 10.5332 13.5003 10.5332C13.3282 10.5332 13.1581 10.5702 13.0015 10.6417C12.845 10.7133 12.7057 10.8176 12.5931 10.9477L8.98465 15.1141C8.83394 15.288 8.73639 15.5015 8.70363 15.7293C8.67087 15.957 8.70429 16.1894 8.7999 16.3987C8.8955 16.608 9.04927 16.7854 9.24287 16.9098C9.43648 17.0341 9.66175 17.1002 9.89185 17.1001H17.1087C17.3388 17.1002 17.564 17.0341 17.7576 16.9098C17.9512 16.7854 18.105 16.608 18.2006 16.3987C18.2962 16.1894 18.3296 15.957 18.2969 15.7293C18.2641 15.5015 18.1666 15.288 18.0159 15.1141L14.4075 10.9477Z"
              fill={step === "step2" ? "white" : "black"}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.20078 4.20005V19.8H19.8008V4.20005H4.20078ZM3.60078 1.80005C3.12339 1.80005 2.66555 1.98969 2.32799 2.32726C1.99042 2.66482 1.80078 3.12266 1.80078 3.60005V20.4C1.80078 20.8774 1.99042 21.3353 2.32799 21.6728C2.66555 22.0104 3.12339 22.2001 3.60078 22.2001H20.4008C20.8782 22.2001 21.336 22.0104 21.6736 21.6728C22.0111 21.3353 22.2008 20.8774 22.2008 20.4V3.60005C22.2008 3.12266 22.0111 2.66482 21.6736 2.32726C21.336 1.98969 20.8782 1.80005 20.4008 1.80005H3.60078Z"
              fill={step === "step2" ? "white" : "black"}
            />
          </svg>
          {!isTablet && (
            <span
              className={`${step === "step2" ? "text-white" : "text-black"}`}
            >
              Mailing Details
            </span>
          )}
        </div>
        <div
          className={`${step === "step3" ? "farm-stepper-active" : ""} ${
            isTablet ? "step1-mobile-view" : "step3-farm-add"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M10.968 2.32496C11.2676 2.10618 11.629 1.98828 12 1.98828C12.371 1.98828 12.7324 2.10618 13.032 2.32496L20.453 7.74096C21.43 8.45296 20.927 9.99796 19.719 10.001H4.28C3.072 9.99796 2.57 8.45296 3.546 7.74096L10.967 2.32496H10.968ZM13 6.24996C13 5.98474 12.8946 5.73039 12.7071 5.54285C12.5196 5.35532 12.2652 5.24996 12 5.24996C11.7348 5.24996 11.4804 5.35532 11.2929 5.54285C11.1054 5.73039 11 5.98474 11 6.24996C11 6.51518 11.1054 6.76953 11.2929 6.95707C11.4804 7.1446 11.7348 7.24996 12 7.24996C12.2652 7.24996 12.5196 7.1446 12.7071 6.95707C12.8946 6.76953 13 6.51518 13 6.24996ZM11.25 16H9.25V11H11.25V16ZM14.75 16H12.75V11H14.75V16ZM18.5 16H16.25V11H18.5V16ZM18.75 17H5.25C4.65326 17 4.08097 17.237 3.65901 17.659C3.23705 18.0809 3 18.6532 3 19.25V19.75C3 20.165 3.336 20.5 3.75 20.5H20.25C20.4489 20.5 20.6397 20.4209 20.7803 20.2803C20.921 20.1396 21 19.9489 21 19.75V19.25C21 18.6532 20.7629 18.0809 20.341 17.659C19.919 17.237 19.3467 17 18.75 17ZM7.75 16H5.5V11H7.75V16Z"
              fill={step === "step3" ? "white" : "black"}
            />
          </svg>
          {!isTablet && (
            <span
              className={`${step === "step3" ? "text-white" : "text-black"}`}
            >
              bank details
            </span>
          )}
        </div>
        <div
          className={`${step === "step4" ? "farm-stepper-active" : ""} ${
            isTablet ? "step1-mobile-view" : " step4-farm-add"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.60117 10.8C10.2377 10.8 10.8481 10.5471 11.2982 10.0971C11.7483 9.64697 12.0012 9.03652 12.0012 8.4C12.0012 7.76348 11.7483 7.15303 11.2982 6.70294C10.8481 6.25286 10.2377 6 9.60117 6C8.96465 6 8.3542 6.25286 7.90412 6.70294C7.45403 7.15303 7.20117 7.76348 7.20117 8.4C7.20117 9.03652 7.45403 9.64697 7.90412 10.0971C8.3542 10.5471 8.96465 10.8 9.60117 10.8Z"
              fill={step === "step4" ? "white" : "black"}
            />
            <path
              d="M8.03699 12.0983C8.14862 11.9479 8.29391 11.8257 8.46124 11.7416C8.62856 11.6574 8.81327 11.6135 9.00059 11.6135C9.1879 11.6135 9.37261 11.6574 9.53993 11.7416C9.70726 11.8257 9.85255 11.9479 9.96419 12.0983L12.2562 15.1848C12.3888 15.3634 12.4691 15.5753 12.4882 15.797C12.5073 16.0186 12.4644 16.2412 12.3643 16.4398C12.2642 16.6385 12.1109 16.8054 11.9214 16.922C11.732 17.0386 11.5138 17.1002 11.2914 17.1H6.70978C6.48744 17.1 6.26946 17.0382 6.08018 16.9215C5.89089 16.8049 5.73773 16.6379 5.6378 16.4393C5.53787 16.2407 5.49508 16.0182 5.51422 15.7967C5.53336 15.5751 5.61367 15.3633 5.74619 15.1848L8.03699 12.0983Z"
              fill={step === "step4" ? "white" : "black"}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.99983 13.8204L7.90063 15.3H10.099L8.99983 13.8204ZM9.96343 12.0983C9.8518 11.9479 9.7065 11.8257 9.53918 11.7416C9.37185 11.6574 9.18714 11.6135 8.99983 11.6135C8.81252 11.6135 8.62781 11.6574 8.46049 11.7416C8.29316 11.8257 8.14787 11.9479 8.03623 12.0983L5.74423 15.1848C5.61165 15.3634 5.53133 15.5753 5.51224 15.797C5.49315 16.0186 5.53604 16.2412 5.63612 16.4398C5.7362 16.6385 5.88953 16.8054 6.07899 16.922C6.26845 17.0386 6.48658 17.1002 6.70903 17.1H11.2906C11.513 17.1 11.731 17.0382 11.9202 16.9215C12.1095 16.8049 12.2627 16.6379 12.3626 16.4393C12.4626 16.2407 12.5053 16.0182 12.4862 15.7967C12.4671 15.5751 12.3868 15.3633 12.2542 15.1848L9.96223 12.0983H9.96343Z"
              fill={step === "step4" ? "white" : "black"}
            />
            <path
              d="M12.5931 10.9477C12.7057 10.8176 12.845 10.7133 13.0015 10.6417C13.1581 10.5702 13.3282 10.5332 13.5003 10.5332C13.6723 10.5332 13.8424 10.5702 13.999 10.6417C14.1555 10.7133 14.2948 10.8176 14.4075 10.9477L18.0159 15.1141C18.1666 15.288 18.2641 15.5015 18.2969 15.7293C18.3296 15.957 18.2962 16.1894 18.2006 16.3987C18.105 16.608 17.9512 16.7854 17.7576 16.9098C17.564 17.0341 17.3388 17.1002 17.1087 17.1001H9.89185C9.66175 17.1002 9.43648 17.0341 9.24287 16.9098C9.04927 16.7854 8.8955 16.608 8.7999 16.3987C8.70429 16.1894 8.67087 15.957 8.70363 15.7293C8.73639 15.5015 8.83394 15.288 8.98465 15.1141L12.5931 10.9477Z"
              fill={step === "step4" ? "white" : "black"}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.5003 12.6493L11.2047 15.3001H15.7959L13.5003 12.6493ZM14.4075 10.9477C14.2948 10.8176 14.1555 10.7133 13.999 10.6417C13.8424 10.5702 13.6723 10.5332 13.5003 10.5332C13.3282 10.5332 13.1581 10.5702 13.0015 10.6417C12.845 10.7133 12.7057 10.8176 12.5931 10.9477L8.98465 15.1141C8.83394 15.288 8.73639 15.5015 8.70363 15.7293C8.67087 15.957 8.70429 16.1894 8.7999 16.3987C8.8955 16.608 9.04927 16.7854 9.24287 16.9098C9.43648 17.0341 9.66175 17.1002 9.89185 17.1001H17.1087C17.3388 17.1002 17.564 17.0341 17.7576 16.9098C17.9512 16.7854 18.105 16.608 18.2006 16.3987C18.2962 16.1894 18.3296 15.957 18.2969 15.7293C18.2641 15.5015 18.1666 15.288 18.0159 15.1141L14.4075 10.9477Z"
              fill={step === "step4" ? "white" : "black"}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.20078 4.20005V19.8H19.8008V4.20005H4.20078ZM3.60078 1.80005C3.12339 1.80005 2.66555 1.98969 2.32799 2.32726C1.99042 2.66482 1.80078 3.12266 1.80078 3.60005V20.4C1.80078 20.8774 1.99042 21.3353 2.32799 21.6728C2.66555 22.0104 3.12339 22.2001 3.60078 22.2001H20.4008C20.8782 22.2001 21.336 22.0104 21.6736 21.6728C22.0111 21.3353 22.2008 20.8774 22.2008 20.4V3.60005C22.2008 3.12266 22.0111 2.66482 21.6736 2.32726C21.336 1.98969 20.8782 1.80005 20.4008 1.80005H3.60078Z"
              fill={step === "step4" ? "white" : "black"}
            />
          </svg>
          {!isTablet && (
            <span
              className={`${step === "step4" ? "text-white" : "text-black"}`}
            >
              Tax Registration
            </span>
          )}
        </div>
      </div>

      {step === "step1" && (
        <div className="mt-5 " style={{ margin: 40 }}>
          {/* productName and Part No */}
          <div>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Name <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.name}
                    name="name"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Group
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.group}
                    name="name"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Vendor Type <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.vendorType}
                    name="name"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* description and product Type */}
          <div className="" style={{ marginTop: 30 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={8}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col xl={2} md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Description
                  </Label>
                </Col>
                <Col xl={10} md={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.description}
                    name="description"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* description and product Type */}
          <div className="" style={{ marginTop: 30 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              {/* <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Under
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.under}
                    name="name"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col> */}

              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Default credit period
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.defaultCreditPeriod}
                    name="defaultCreditPeriod"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>

              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Specify Credit Limit
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.specifyCreditLimit}
                    name="specifyCreditLimit"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          <div className="" style={{ marginTop: 30 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      paddingRight: 10,
                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Maintain balances bill-by-bill
                  </Label>
                </Col>
                <Col md={8} className="align-items-center d-flex">
                  <FormGroup switch>
                    <Input
                      type="switch"
                      style={{ height: 24, width: 44 }}
                      checked={inputData?.maintainBalance}
                      name="maintainBalance"
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      paddingRight: 10,
                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Check for credit days during voucher entry
                  </Label>
                </Col>
                <Col md={8} className="align-items-center d-flex mx-2">
                  <FormGroup switch>
                    <Input
                      type="switch"
                      style={{ height: 24, width: 44 }}
                      checked={inputData?.creditDaysVoucher}
                      name="creditDaysVoucher"
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Col>
            </Row>
          </div>

          {/* Next Button View */}
          <div>
            <Col
              md={12}
              className="d-flex justify-content-end align-items-end mt-2"
            >
              <button
                type="button"
                className="custom-btn-page-layout btn text-white "
                onClick={() => handleNextBtn("step1")}
              >
                Next
                <svg
                  style={{ marginLeft: 5 }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M1 8C1 9.38447 1.41054 10.7378 2.17971 11.889C2.94888 13.0401 4.04213 13.9373 5.32122 14.4672C6.6003 14.997 8.00776 15.1356 9.36563 14.8655C10.7235 14.5954 11.9708 13.9287 12.9497 12.9497C13.9287 11.9708 14.5954 10.7235 14.8655 9.36563C15.1356 8.00776 14.997 6.6003 14.4672 5.32122C13.9373 4.04213 13.0401 2.94888 11.889 2.17971C10.7378 1.41054 9.38447 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8ZM4 7.5H10.075L7.285 4.6965L8 4L12 8L8 12L7.285 11.2865L10.075 8.5H4V7.5Z"
                    fill="white"
                  />
                </svg>
              </button>
            </Col>
          </div>
        </div>
      )}

      {step === "step2" && (
        <div className="mt-5 " style={{ margin: 40 }}>
          {/* name and addressLine1 */}
          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Name <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.name}
                    name="name"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Address <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.addressLine1}
                    name="addressLine1"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* addressLine2 , pincode and addressLine3 */}
          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Address Line 2 <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.addressLine2}
                    name="addressLine2"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Address Line 3 <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.addressLine3}
                    name="addressLine3"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Pincode <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.pincode}
                    name="pincode"
                    disabled
                    className="cust-input"
                    type="number"
                    maxLength={6}
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* mobile and country, State */}
          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Mobile <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.mobile}
                    name="mobile"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Country <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.country}
                    name="name"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    State <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.state}
                    name="name"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* table Section in Step2 */}
          <div className="col-md-6 mt-5" style={{ background: "white" }}>
            <table className="table  table-striped bd-white">
              <tr>
                <th
                  style={{
                    height: 40,
                    width: "20%",
                    backgroundColor: "#B3E9B9",
                  }}
                >
                  Address Description
                </th>
                <th
                  style={{
                    height: 40,
                    backgroundColor: "#B3E9B9",
                    width: "20%",
                  }}
                >
                  Active
                </th>
                <th
                  style={{
                    height: 40,
                    backgroundColor: "#B3E9B9",
                    width: "10%",
                  }}
                >
                  Action
                </th>
              </tr>
              {/* Providing Contact Details */}
              <tr>
                <td>Providing Contact Details</td>
                <td>
                  <div className="form-switch">
                    <Input
                      type="switch"
                      style={{
                        // border: "1px solid #0b5f18  ",
                        height: 24,
                        width: 44,
                      }}
                      checked={inputData?.contactDetails}
                      name="contactDetails"
                      disabled={inputData?.contactDetails}
                    />
                  </div>
                </td>
                <td>
                  {inputData?.contactDetails && (
                    <div className="justify-content-center d-flex align-items-center gap-2">
                      {/* View Button */}

                      <button
                        className="border-0"
                        style={{ background: "transparent" }}
                        onClick={() => {
                          setViewContactModalState(true);
                          setSingleContactData({
                            contactPerson: inputData?.contactPerson,
                            contactPhoneNo: inputData?.contactPhoneNo,
                            contactMobileNo: inputData?.contactMobileNo,
                            contactEmail: inputData?.contactEmail,
                            CC: inputData?.CC,
                            BCC: inputData?.BCC,
                            website: inputData?.website,
                          });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_32_322)">
                            <path
                              d="M3.12549 7.5C3.12549 7.5 4.71611 4.375 7.50049 4.375C10.2842 4.375 11.8755 7.5 11.8755 7.5C11.8755 7.5 10.2842 10.625 7.50049 10.625C4.71611 10.625 3.12549 7.5 3.12549 7.5Z"
                              stroke="#03A8DC"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.1255 10.625V11.875C13.1255 12.2065 12.9938 12.5245 12.7594 12.7589C12.525 12.9933 12.207 13.125 11.8755 13.125H3.12549C2.79397 13.125 2.47603 12.9933 2.2416 12.7589C2.00718 12.5245 1.87549 12.2065 1.87549 11.875V10.625M13.1255 4.375V3.125C13.1255 2.79348 12.9938 2.47554 12.7594 2.24112C12.525 2.0067 12.207 1.875 11.8755 1.875H3.12549C2.79397 1.875 2.47603 2.0067 2.2416 2.24112C2.00718 2.47554 1.87549 2.79348 1.87549 3.125V4.375M7.50049 8.125C7.66625 8.125 7.82522 8.05915 7.94243 7.94194C8.05964 7.82473 8.12549 7.66576 8.12549 7.5C8.12549 7.33424 8.05964 7.17527 7.94243 7.05806C7.82522 6.94085 7.66625 6.875 7.50049 6.875C7.33473 6.875 7.17576 6.94085 7.05855 7.05806C6.94134 7.17527 6.87549 7.33424 6.87549 7.5C6.87549 7.66576 6.94134 7.82473 7.05855 7.94194C7.17576 8.05915 7.33473 8.125 7.50049 8.125Z"
                              stroke="#03A8DC"
                              strokeWidth="1.8"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_32_322">
                              <rect
                                width="15"
                                height="15"
                                fill="white"
                                transform="translate(0.000488281)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  )}
                </td>
              </tr>

              {/* central tax  */}
              <tr>
                <td>Set/Alter Multiple mailing details</td>
                <td>
                  <div className="form-switch">
                    <Input
                      type="switch"
                      style={{
                        height: 24,
                        width: 44,
                      }}
                      disabled={inputData?.alterMailingDetails}
                      checked={inputData?.alterMailingDetails}
                      name="alterMailingDetails"
                    />
                  </div>
                </td>
                <td
                  style={{
                    padding: 5,
                  }}
                >
                  {inputData?.alterMailingDetails && (
                    <div className="justify-content-center d-flex align-items-center gap-2">
                      {/* edit Button */}
                      <button
                        className="border-0"
                        style={{ background: "transparent" }}
                        onClick={() => {
                          setStep("step5");
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_32_322)">
                            <path
                              d="M3.12549 7.5C3.12549 7.5 4.71611 4.375 7.50049 4.375C10.2842 4.375 11.8755 7.5 11.8755 7.5C11.8755 7.5 10.2842 10.625 7.50049 10.625C4.71611 10.625 3.12549 7.5 3.12549 7.5Z"
                              stroke="#03A8DC"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.1255 10.625V11.875C13.1255 12.2065 12.9938 12.5245 12.7594 12.7589C12.525 12.9933 12.207 13.125 11.8755 13.125H3.12549C2.79397 13.125 2.47603 12.9933 2.2416 12.7589C2.00718 12.5245 1.87549 12.2065 1.87549 11.875V10.625M13.1255 4.375V3.125C13.1255 2.79348 12.9938 2.47554 12.7594 2.24112C12.525 2.0067 12.207 1.875 11.8755 1.875H3.12549C2.79397 1.875 2.47603 2.0067 2.2416 2.24112C2.00718 2.47554 1.87549 2.79348 1.87549 3.125V4.375M7.50049 8.125C7.66625 8.125 7.82522 8.05915 7.94243 7.94194C8.05964 7.82473 8.12549 7.66576 8.12549 7.5C8.12549 7.33424 8.05964 7.17527 7.94243 7.05806C7.82522 6.94085 7.66625 6.875 7.50049 6.875C7.33473 6.875 7.17576 6.94085 7.05855 7.05806C6.94134 7.17527 6.87549 7.33424 6.87549 7.5C6.87549 7.66576 6.94134 7.82473 7.05855 7.94194C7.17576 8.05915 7.33473 8.125 7.50049 8.125Z"
                              stroke="#03A8DC"
                              strokeWidth="1.8"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_32_322">
                              <rect
                                width="15"
                                height="15"
                                fill="white"
                                transform="translate(0.000488281)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            </table>
          </div>

          {/* Next and Previous */}

          <div
            className="col-md-12 d-flex justify-content-end align-items-end"
            style={{ marginBottom: 20 }}
          >
            <button
              type="button"
              className="custom-btn-page-layout btn text-white "
              onClick={() => setStep("step1")}
            >
              {!isMobile && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  style={{ marginRight: 5 }}
                >
                  <path
                    d="M15 8C15 9.38447 14.5895 10.7378 13.8203 11.889C13.0511 13.0401 11.9579 13.9373 10.6788 14.4672C9.3997 14.997 7.99224 15.1356 6.63437 14.8655C5.2765 14.5954 4.02922 13.9287 3.05025 12.9497C2.07129 11.9708 1.4046 10.7235 1.1345 9.36563C0.864407 8.00776 1.00303 6.6003 1.53284 5.32122C2.06266 4.04213 2.95986 2.94888 4.11101 2.17971C5.26215 1.41054 6.61553 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8ZM12 7.5H5.925L8.715 4.6965L8 4L4 8L8 12L8.715 11.2865L5.925 8.5H12V7.5Z"
                    fill="white"
                  />
                </svg>
              )}
              Previous
            </button>
            <button
              type="button"
              className="custom-btn-page-layout btn text-white mx-4"
              onClick={() => handleNextBtn("step2")}
            >
              Next
              {!isMobile && (
                <svg
                  style={{ marginLeft: 5 }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M1 8C1 9.38447 1.41054 10.7378 2.17971 11.889C2.94888 13.0401 4.04213 13.9373 5.32122 14.4672C6.6003 14.997 8.00776 15.1356 9.36563 14.8655C10.7235 14.5954 11.9708 13.9287 12.9497 12.9497C13.9287 11.9708 14.5954 10.7235 14.8655 9.36563C15.1356 8.00776 14.997 6.6003 14.4672 5.32122C13.9373 4.04213 13.0401 2.94888 11.889 2.17971C10.7378 1.41054 9.38447 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8ZM4 7.5H10.075L7.285 4.6965L8 4L12 8L8 12L7.285 11.2865L10.075 8.5H4V7.5Z"
                    fill="white"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      )}

      {step === "step5" && (
        <div className="mt-5 " style={{ margin: 40 }}>
          <div className="d-flex justify-content-between align-items-center">
            <button
              className="custom-btn-page-layout btn text-white d-flex justify-content-center align-items-center"
              onClick={() => {
                setStep("step2");
              }}
            >
              <i className="fa fa-arrow-circle-left align-bottom me-3"></i> Back
            </button>
          </div>

          <div className="col-md-8" style={{ marginTop: 50 }}>
            <table className="table  table-striped bg-white">
              <tr style={{}}>
                <th
                  style={{
                    height: 40,
                    width: "5%",

                    backgroundColor: "#B3E9B9",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    height: 40,
                    backgroundColor: "#B3E9B9",
                    width: "20%",
                  }}
                >
                  Address Type
                </th>
                <th
                  style={{
                    height: 40,
                    backgroundColor: "#B3E9B9",
                    width: "20%",
                  }}
                >
                  Address Description
                </th>
                <th
                  style={{
                    height: 40,
                    backgroundColor: "#B3E9B9",

                    width: "10%",
                  }}
                >
                  Action
                </th>
              </tr>

              {mailingAddressDetails?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item?.addressType}</td>
                  <td>{item?.address}</td>
                  <td
                    style={{
                      padding: 5,
                    }}
                  >
                    <div className="justify-content-center d-flex align-items-center gap-2">
                      {/* View Button */}

                      <button
                        className="border-0"
                        style={{ background: "transparent" }}
                        onClick={() => {
                          setViewMailingAdddressModalState(true);
                          setSingleMailingDetails(item);
                          setMailingId(index);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_32_322)">
                            <path
                              d="M3.12549 7.5C3.12549 7.5 4.71611 4.375 7.50049 4.375C10.2842 4.375 11.8755 7.5 11.8755 7.5C11.8755 7.5 10.2842 10.625 7.50049 10.625C4.71611 10.625 3.12549 7.5 3.12549 7.5Z"
                              stroke="#03A8DC"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.1255 10.625V11.875C13.1255 12.2065 12.9938 12.5245 12.7594 12.7589C12.525 12.9933 12.207 13.125 11.8755 13.125H3.12549C2.79397 13.125 2.47603 12.9933 2.2416 12.7589C2.00718 12.5245 1.87549 12.2065 1.87549 11.875V10.625M13.1255 4.375V3.125C13.1255 2.79348 12.9938 2.47554 12.7594 2.24112C12.525 2.0067 12.207 1.875 11.8755 1.875H3.12549C2.79397 1.875 2.47603 2.0067 2.2416 2.24112C2.00718 2.47554 1.87549 2.79348 1.87549 3.125V4.375M7.50049 8.125C7.66625 8.125 7.82522 8.05915 7.94243 7.94194C8.05964 7.82473 8.12549 7.66576 8.12549 7.5C8.12549 7.33424 8.05964 7.17527 7.94243 7.05806C7.82522 6.94085 7.66625 6.875 7.50049 6.875C7.33473 6.875 7.17576 6.94085 7.05855 7.05806C6.94134 7.17527 6.87549 7.33424 6.87549 7.5C6.87549 7.66576 6.94134 7.82473 7.05855 7.94194C7.17576 8.05915 7.33473 8.125 7.50049 8.125Z"
                              stroke="#03A8DC"
                              strokeWidth="1.8"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_32_322">
                              <rect
                                width="15"
                                height="15"
                                fill="white"
                                transform="translate(0.000488281)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      )}

      {step === "step3" && (
        <div className="mt-5 " style={{ margin: 40 }}>
          <div>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={6}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col xl={3} md={6}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Provide Bank Details{" "}
                    <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col xl={9} md={6}>
                  <FormGroup switch>
                    <Input
                      type="switch"
                      style={{ height: 24, width: 44 }}
                      checked={inputData?.providebankDetails}
                      name="providebankDetails"
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Col>
            </Row>
          </div>

          {bankDetails?.length > 0 &&
            bankDetails?.map((item, index) => (
              <Accordion
                key={index}
                open={open}
                className={index === 0 ? "mt-4" : ""}
                toggle={toggle}
              >
                <fieldset
                  className="cus-add-req-sur border-none m-0 p-2"
                  style={{ border: "none" }}
                >
                  <AccordionItem>
                    <AccordionHeader targetId={`${index + 1}`}>
                      Bank No : {index + 1}
                    </AccordionHeader>
                    <AccordionBody accordionId={`${index + 1}`}>
                      <div>
                        <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
                          <Col
                            xl={4}
                            lg={6}
                            md={12}
                            sm={12}
                            className={`${
                              isMobile
                                ? ""
                                : "d-flex justify-content-center align-items-center"
                            }`}
                          >
                            <Col md={3}>
                              <Label
                                className="me-2"
                                style={{
                                  fontWeight: 600,

                                  fontSize: 14,
                                }}
                                for="exampleEmail"
                                md={12}
                              >
                                In Favor of
                              </Label>
                            </Col>
                            <Col md={9}>
                              <Input
                                id="exampleEmail"
                                value={item?.inFavourOf}
                                name="inFavourOf"
                                disabled
                                className="cust-input"
                                type="text"
                              />
                            </Col>
                          </Col>
                          <Col
                            xl={4}
                            lg={6}
                            md={12}
                            sm={12}
                            className={`${
                              isMobile
                                ? ""
                                : "d-flex justify-content-center align-items-center"
                            }`}
                          >
                            <Col md={3}>
                              <Label
                                className="me-2"
                                style={{
                                  fontWeight: 600,

                                  fontSize: 14,
                                }}
                                for="exampleEmail"
                                md={12}
                              >
                                IFSC Code :{" "}
                                <span className="required_span"> * </span>
                              </Label>
                            </Col>
                            <Col md={9}>
                              <Input
                                id="exampleEmail"
                                value={item?.ifsCode}
                                name="ifsCode"
                                disabled
                                className="cust-input"
                                type="text"
                              />
                            </Col>
                          </Col>
                        </Row>
                      </div>

                      <div style={{ marginTop: isMobile ? 10 : 40 }}>
                        <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
                          <Col
                            xl={4}
                            lg={6}
                            md={12}
                            sm={12}
                            className={`${
                              isMobile
                                ? ""
                                : "d-flex justify-content-center align-items-center"
                            }`}
                          >
                            <Col md={3}>
                              <Label
                                className="me-2"
                                style={{
                                  fontWeight: 600,

                                  fontSize: 14,
                                }}
                                for="exampleEmail"
                                md={12}
                              >
                                Ac No.
                                <span className="required_span"> * </span>
                              </Label>
                            </Col>
                            <Col md={9}>
                              <Input
                                id="exampleEmail"
                                value={item?.accountNo}
                                name="accountNo"
                                disabled
                                className="cust-input"
                                type="number"
                              />
                            </Col>
                          </Col>
                          <Col
                            xl={4}
                            lg={6}
                            md={12}
                            sm={12}
                            className={`${
                              isMobile
                                ? ""
                                : "d-flex justify-content-center align-items-center"
                            }`}
                          >
                            <Col md={3}>
                              <Label
                                className="me-2"
                                style={{
                                  fontWeight: 600,

                                  fontSize: 14,
                                }}
                                for="exampleEmail"
                                md={12}
                              >
                                SWIFT Code :{" "}
                              </Label>
                            </Col>
                            <Col md={9}>
                              <Input
                                id="exampleEmail"
                                value={item?.swiftCode}
                                name="swiftCode"
                                disabled
                                className="cust-input"
                                type="text"
                              />
                            </Col>
                          </Col>
                        </Row>
                      </div>

                      <div style={{ marginTop: isMobile ? 10 : 40 }}>
                        <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
                          <Col
                            xl={4}
                            lg={6}
                            md={12}
                            sm={12}
                            className={`${
                              isMobile
                                ? ""
                                : "d-flex justify-content-center align-items-center"
                            }`}
                          >
                            <Col md={3}>
                              <Label
                                className="me-2"
                                style={{
                                  fontWeight: 600,

                                  fontSize: 14,
                                }}
                                for="exampleEmail"
                                md={12}
                              >
                                Bank Name :{" "}
                                <span className="required_span"> * </span>
                              </Label>
                            </Col>
                            <Col md={9}>
                              <Input
                                id="exampleEmail"
                                value={item?.bankName}
                                name="bankName"
                                disabled
                                className="cust-input"
                                type="text"
                              />
                            </Col>
                          </Col>
                          <Col
                            xl={4}
                            lg={6}
                            md={12}
                            sm={12}
                            className={`${
                              isMobile
                                ? ""
                                : "d-flex justify-content-center align-items-center"
                            }`}
                          >
                            <Col md={3}>
                              <Label
                                className="me-2"
                                style={{
                                  fontWeight: 600,

                                  fontSize: 14,
                                }}
                                for="exampleEmail"
                                md={12}
                              >
                                Transaction Type
                              </Label>
                            </Col>
                            <Col md={9}>
                              <Input
                                id="exampleEmail"
                                value={item?.transectionType}
                                name="name"
                                disabled
                                className="cust-input"
                                type="text"
                              />
                            </Col>
                          </Col>
                        </Row>
                      </div>
                      <div style={{ marginTop: isMobile ? 10 : 40 }}>
                        <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
                          <Col
                            xl={4}
                            lg={6}
                            md={12}
                            sm={12}
                            className={`${
                              isMobile
                                ? ""
                                : "d-flex justify-content-center align-items-center"
                            }`}
                          >
                            <Col md={3}>
                              <Label
                                className="me-2"
                                style={{
                                  fontWeight: 600,

                                  fontSize: 14,
                                }}
                                for="exampleEmail"
                                md={12}
                              >
                                Set as Default :{" "}
                              </Label>
                            </Col>
                            <Col md={9}>
                              <FormGroup switch>
                                <Input
                                  type="switch"
                                  style={{ height: 24, width: 44 }}
                                  checked={item?.isDefault}
                                  name="isDefault"
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                          </Col>
                        </Row>
                      </div>
                    </AccordionBody>
                  </AccordionItem>
                </fieldset>
              </Accordion>
            ))}

          <div
            className="col-md-12 d-flex justify-content-end align-items-end"
            style={{ marginBottom: 20, marginTop: 20 }}
          >
            <button
              type="button"
              className="custom-btn-page-layout btn text-white "
              onClick={() => setStep("step2")}
            >
              {!isMobile && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  style={{ marginRight: 5 }}
                >
                  <path
                    d="M15 8C15 9.38447 14.5895 10.7378 13.8203 11.889C13.0511 13.0401 11.9579 13.9373 10.6788 14.4672C9.3997 14.997 7.99224 15.1356 6.63437 14.8655C5.2765 14.5954 4.02922 13.9287 3.05025 12.9497C2.07129 11.9708 1.4046 10.7235 1.1345 9.36563C0.864407 8.00776 1.00303 6.6003 1.53284 5.32122C2.06266 4.04213 2.95986 2.94888 4.11101 2.17971C5.26215 1.41054 6.61553 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8ZM12 7.5H5.925L8.715 4.6965L8 4L4 8L8 12L8.715 11.2865L5.925 8.5H12V7.5Z"
                    fill="white"
                  />
                </svg>
              )}
              Previous
            </button>
            <button
              type="button"
              className="custom-btn-page-layout btn text-white mx-4"
              onClick={() => handleNextBtn("step3")}
            >
              Next
              <svg
                style={{ marginLeft: 5 }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M1 8C1 9.38447 1.41054 10.7378 2.17971 11.889C2.94888 13.0401 4.04213 13.9373 5.32122 14.4672C6.6003 14.997 8.00776 15.1356 9.36563 14.8655C10.7235 14.5954 11.9708 13.9287 12.9497 12.9497C13.9287 11.9708 14.5954 10.7235 14.8655 9.36563C15.1356 8.00776 14.997 6.6003 14.4672 5.32122C13.9373 4.04213 13.0401 2.94888 11.889 2.17971C10.7378 1.41054 9.38447 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8ZM4 7.5H10.075L7.285 4.6965L8 4L12 8L8 12L7.285 11.2865L10.075 8.5H4V7.5Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      )}

      {step === "step4" && (
        <div className="mt-5 " style={{ margin: 40 }}>
          <div className="" style={{ marginTop: 30 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    PAN/IT No.
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.panItNo}
                    name="panItNo"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Registration Type
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.registrationType}
                    name="name"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>

              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    GST1N/UIN
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.gstuin}
                    name="gstuin"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          <div className="" style={{ marginTop: 30 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={6}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Assessee of Other Territory
                  </Label>
                </Col>
                <Col md={6} className="mx-2">
                  <FormGroup switch>
                    <Input
                      type="switch"
                      style={{ height: 24, width: 44 }}
                      checked={inputData?.assesseeOther}
                      name="assesseeOther"
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={6}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Use Ledger as common Party
                  </Label>
                </Col>
                <Col md={6} className="mx-2">
                  <FormGroup switch>
                    <Input
                      type="switch"
                      style={{ height: 24, width: 44 }}
                      checked={inputData?.ledgerParty}
                      name="ledgerParty"
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Col>

              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={6}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Ignore prefixes and suffixes in Doc No. for reconciliation
                  </Label>
                </Col>
                <Col md={6} className="mx-2">
                  <FormGroup switch>
                    <Input
                      type="switch"
                      style={{ height: 24, width: 44 }}
                      checked={inputData?.ignorePrefixies}
                      name="ignorePrefixies"
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Col>

              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }mt-4`}
              >
                <Col md={6}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Opening Balance
                  </Label>
                </Col>
                <Col md={6} className="mx-2">
                  <Input
                    id="exampleEmail"
                    value={inputData?.openingBalance}
                    name="openingBalance"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          <div
            className="col-md-12 d-flex justify-content-end align-items-end"
            style={{ marginBottom: 20, marginTop: 40 }}
          >
            <button
              type="button"
              className="custom-btn-page-layout btn text-white "
              onClick={() => setStep("step3")}
            >
              {!isMobile && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  style={{ marginRight: 5 }}
                >
                  <path
                    d="M15 8C15 9.38447 14.5895 10.7378 13.8203 11.889C13.0511 13.0401 11.9579 13.9373 10.6788 14.4672C9.3997 14.997 7.99224 15.1356 6.63437 14.8655C5.2765 14.5954 4.02922 13.9287 3.05025 12.9497C2.07129 11.9708 1.4046 10.7235 1.1345 9.36563C0.864407 8.00776 1.00303 6.6003 1.53284 5.32122C2.06266 4.04213 2.95986 2.94888 4.11101 2.17971C5.26215 1.41054 6.61553 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8ZM12 7.5H5.925L8.715 4.6965L8 4L4 8L8 12L8.715 11.2865L5.925 8.5H12V7.5Z"
                    fill="white"
                  />
                </svg>
              )}
              Previous
            </button>
            <button
              type="button"
              className="custom-btn-page-layout btn text-white mx-4"
              onClick={() => navigate("/vendormaster")}
              disabled={loading}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {contactModalState && (
        <ContactDetailModal
          modalState={contactModalState}
          onCloseModal={onCloseModalContactDetails}
          inputData={inputData}
          handleChangeInput={handleChangeInput}
          handleAdd={handleAdd}
          isMobile={isMobile}
        />
      )}

      {editContactModalState && (
        <EditContactDetailModal
          modalState={editContactModalState}
          onCloseModal={onCloseModalEditContactDetails}
          singleContactData={singleContactData}
          setSingleContactData={setSingleContactData}
          isMobile={isMobile}
          handleUpdate={handleUpdate}
        />
      )}

      {viewContactModalState && (
        <ViewContactDetailModal
          modalState={viewContactModalState}
          onCloseModal={onCloseModalViewContactDetails}
          isMobile={isMobile}
          singleContactData={singleContactData}
        />
      )}

      {mailingAddressModalState && (
        <MailingAddressDetailModal
          modalState={mailingAddressModalState}
          onCloseModal={onCloseModalMailingDetails}
          singleMailingDetails={singleMailingDetails}
          setSingleMailingDetails={setSingleMailingDetails}
          isMobile={isMobile}
          handleAddMailingDetails={handleAddMailingDetails}
        />
      )}

      {editMailingAddressModalState && (
        <EditMailingAddressDetailModal
          modalState={editMailingAddressModalState}
          onCloseModal={onCloseModalEditMailingDetails}
          singleMailingDetails={singleMailingDetails}
          setSingleMailingDetails={setSingleMailingDetails}
          isMobile={isMobile}
          handleUpdateMailingDetails={handleUpdateMailingDetails}
        />
      )}

      {viewMailingAddressModalState && (
        <ViewMailingAddressDetailModal
          modalState={viewMailingAddressModalState}
          onCloseModal={onCloseModalViewMailingDetails}
          isMobile={isMobile}
          singleMailingDetails={singleMailingDetails}
        />
      )}
    </PageLayout>
  );
};

const ContactDetailModal = ({
  modalState,
  onCloseModal,
  inputData,
  handleChangeInput,
  handleAdd,
  isMobile,
}) => {
  return (
    <Modal
      id="showModal"
      size="xl"
      isOpen={modalState}
      toggle={() => onCloseModal()}
      centered
    >
      <ModalBody className="p-3">
        <div>
          <div className="dashboard-header">
            <span>CONTACT DETAILS</span>
          </div>
          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Contact Person <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.contactPerson}
                    name="contactPerson"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Phone No
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.contactPhoneNo}
                    name="contactPhoneNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Mobile No
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.contactMobileNo}
                    name="contactMobileNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
            </Row>
          </div>
          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    E-mail
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.contactEmail}
                    name="contactEmail"
                    disabled
                    className="cust-input"
                    type="email"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    CC to (if any)
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.CC}
                    name="CC"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    BCC to (if any)
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.BCC}
                    name="BCC"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>
          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Website
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.website}
                    name="website"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>
        </div>
      </ModalBody>

      <div className="p-3">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="custom-btn-page-layout btn text-white "
            onClick={() => handleAdd()}
          >
            Add
          </button>
        </div>
      </div>
    </Modal>
  );
};

const EditContactDetailModal = ({
  modalState,
  onCloseModal,
  singleContactData,
  setSingleContactData,
  handleUpdate,
  isMobile,
}) => {
  return (
    <Modal
      id="showModal"
      size="xl"
      isOpen={modalState}
      toggle={() => onCloseModal()}
      centered
    >
      <ModalBody className="p-3">
        <div>
          <div className="dashboard-header">
            <span>CONTACT DETAILS</span>
          </div>
          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Contact Person <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleContactData?.contactPerson}
                    name="contactPerson"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Phone No
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleContactData?.contactPhoneNo}
                    name="contactPhoneNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Mobile No
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleContactData?.contactMobileNo}
                    name="contactMobileNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
            </Row>
          </div>
          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    E-mail
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleContactData?.contactEmail}
                    name="contactEmail"
                    disabled
                    className="cust-input"
                    type="email"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    CC to (if any)
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleContactData?.CC}
                    name="CC"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    BCC to (if any)
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleContactData?.BCC}
                    name="BCC"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>
          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Website
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleContactData?.website}
                    name="website"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>
        </div>
      </ModalBody>

      <div className="p-3">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="custom-btn-page-layout btn text-white "
            onClick={() => handleUpdate()}
          >
            Update
          </button>
        </div>
      </div>
    </Modal>
  );
};

const ViewContactDetailModal = ({
  modalState,
  onCloseModal,
  singleContactData,
  isMobile,
}) => {
  return (
    <Modal
      id="showModal"
      size="xl"
      isOpen={modalState}
      toggle={() => onCloseModal()}
      centered
    >
      <ModalBody className="p-3">
        <div>
          <div className="dashboard-header">
            <span>CONTACT DETAILS</span>
          </div>
          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Contact Person <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleContactData?.contactPerson}
                    name="contactPerson"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Phone No
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleContactData?.contactPhoneNo}
                    name="contactPhoneNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Mobile No
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleContactData?.contactMobileNo}
                    name="contactMobileNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
            </Row>
          </div>
          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    E-mail
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleContactData?.contactEmail}
                    name="contactEmail"
                    disabled
                    className="cust-input"
                    type="email"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    CC to (if any)
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleContactData?.CC}
                    name="CC"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    BCC to (if any)
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleContactData?.BCC}
                    name="BCC"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>
          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Website
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleContactData?.website}
                    name="website"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>
        </div>
      </ModalBody>

      <div className="p-3">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="custom-btn-page-layout btn text-white "
            onClick={() => onCloseModal()}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

const MailingAddressDetailModal = ({
  modalState,
  onCloseModal,
  singleMailingDetails,
  setSingleMailingDetails,
  handleAddMailingDetails,
  isMobile,
}) => {
  const [countryArray, setCountryArray] = useState();
  const [stateArray, setStateArray] = useState();
  const { getStateOption } = StateOption();
  const { getCountryOption } = CountryOption();
  useEffect(() => {
    getCountryOption().then((res) => {
      setCountryArray(res);
    });
    // eslint-disable-next-line
  }, []);

  const handleChangeInput = (name, value) => {
    setSingleMailingDetails({ ...singleMailingDetails, [name]: value });

    if (name === "country") {
      getStateOption(value.value).then((res) => {
        setStateArray(res);
      });
    }
  };

  return (
    <Modal
      id="showModal"
      size="xl"
      isOpen={modalState}
      toggle={() => onCloseModal()}
      centered
    >
      <ModalBody className="p-3">
        <div>
          <div className="dashboard-header">
            <span>mailing Address details</span>
          </div>
          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={1}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                  >
                    Address <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={11} style={{ paddingLeft: 25 }}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.address}
                    name="address"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Country <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={8}>
                  <Select
                    isMulti={false}
                    onChange={(e) => {
                      handleChangeInput("country", e);
                    }}
                    value={singleMailingDetails?.country}
                    options={countryArray}
                    // menuPlacement="top"
                    name="choices-single-default"
                    id="countryAddCompany"
                    classame="w-100"
                  ></Select>
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    State <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={9}>
                  <Select
                    isMulti={false}
                    onChange={(e) => {
                      handleChangeInput("state", e);
                    }}
                    value={singleMailingDetails?.state}
                    options={stateArray}
                    // menuPlacement="top"
                    name="choices-single-default"
                    id="countryAddCompany"
                    className="w-100"
                  ></Select>
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Pincode <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.pincode}
                    name="pincode"
                    disabled
                    className="cust-input"
                    type="number"
                    maxLength={6}
                  />
                </Col>
              </Col>
            </Row>
          </div>

          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Contact Person <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.contactPerson}
                    name="contactPerson"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Phone No.
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.phoneNo}
                    name="phoneNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Mobile No.
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.mobileNo}
                    name="mobileNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Fax No.
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.faxNo}
                    name="faxNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    E-mail
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.email}
                    name="email"
                    disabled
                    className="cust-input"
                    type="email"
                  />
                </Col>
              </Col>
            </Row>
          </div>
          <span
            style={{
              color: "#085581",
              fontWeight: 600,
              textTransform: "capitalize",
              fontSize: 20,
            }}
          >
            statutory and taxation information
          </span>

          <div style={{ marginTop: 20 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    PAN/Income Tax No.
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.incomeTaxNo}
                    name="incomeTaxNo"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    TIN/Sales Tax No.
                  </Label>
                </Col>
                <Col md={9} className="mx-2">
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.salesTaxNo}
                    name="salesTaxNo"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    CST No.
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.cstNo}
                    name="cstNo"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          <span
            style={{
              color: "#085581",
              fontWeight: 600,
              textTransform: "capitalize",
              fontSize: 20,
            }}
          >
            gst details
          </span>

          <div style={{ marginTop: 20 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Registration Type
                  </Label>
                </Col>
                <Col md={8}>
                  <Select
                    isMulti={false}
                    onChange={(e) => {
                      handleChangeInput("registrationType", e);
                    }}
                    value={singleMailingDetails?.registrationType}
                    options={registrationTypeArray}
                    // menuPlacement="top"
                    name="choices-single-default"
                    id="countryAddCompany"
                    className="w-100"
                  ></Select>
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    GSTIN/UIN
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.GST}
                    name="GST"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>
        </div>
      </ModalBody>

      <div className="p-3">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="custom-btn-page-layout btn text-white "
            onClick={() => handleAddMailingDetails()}
          >
            Add
          </button>
        </div>
      </div>
    </Modal>
  );
};

const EditMailingAddressDetailModal = ({
  modalState,
  onCloseModal,
  singleMailingDetails,
  setSingleMailingDetails,
  handleUpdateMailingDetails,
  isMobile,
}) => {
  const [countryArray, setCountryArray] = useState();
  const [stateArray, setStateArray] = useState();
  const { getStateOption } = StateOption();
  const { getCountryOption } = CountryOption();
  useEffect(() => {
    getCountryOption().then((res) => {
      setCountryArray(res);
    });
    // eslint-disable-next-line
  }, []);

  const handleChangeInput = (name, value) => {
    setSingleMailingDetails({ ...singleMailingDetails, [name]: value });

    if (name === "country") {
      getStateOption(value.value).then((res) => {
        setStateArray(res);
      });
    }
  };

  return (
    <Modal
      id="showModal"
      size="xl"
      isOpen={modalState}
      toggle={() => onCloseModal()}
      centered
    >
      <ModalBody className="p-3">
        <div>
          <div className="dashboard-header">
            <span>mailing Address details</span>
          </div>
          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={1}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                  >
                    Address <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={11} style={{ paddingLeft: 25 }}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.address}
                    name="address"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Country <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={8}>
                  <Select
                    isMulti={false}
                    onChange={(e) => {
                      handleChangeInput("country", e);
                    }}
                    value={singleMailingDetails?.country}
                    options={countryArray}
                    // menuPlacement="top"
                    name="choices-single-default"
                    id="countryAddCompany"
                    classame="w-100"
                  ></Select>
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    State <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={9}>
                  <Select
                    isMulti={false}
                    onChange={(e) => {
                      handleChangeInput("state", e);
                    }}
                    value={singleMailingDetails?.state}
                    options={stateArray}
                    // menuPlacement="top"
                    name="choices-single-default"
                    id="countryAddCompany"
                    className="w-100"
                  ></Select>
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Pincode <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.pincode}
                    name="pincode"
                    disabled
                    className="cust-input"
                    type="number"
                    maxLength={6}
                  />
                </Col>
              </Col>
            </Row>
          </div>

          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Contact Person <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.contactPerson}
                    name="contactPerson"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Phone No.
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.phoneNo}
                    name="phoneNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Mobile No.
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.mobileNo}
                    name="mobileNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Fax No.
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.faxNo}
                    name="faxNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    E-mail
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.email}
                    name="email"
                    disabled
                    className="cust-input"
                    type="email"
                  />
                </Col>
              </Col>
            </Row>
          </div>
          <span
            style={{
              color: "#085581",
              fontWeight: 600,
              textTransform: "capitalize",
              fontSize: 20,
            }}
          >
            statutory and taxation information
          </span>

          <div style={{ marginTop: 20 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    PAN/Income Tax No.
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.incomeTaxNo}
                    name="incomeTaxNo"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    TIN/Sales Tax No.
                  </Label>
                </Col>
                <Col md={9} className="mx-2">
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.salesTaxNo}
                    name="salesTaxNo"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    CST No.
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.cstNo}
                    name="cstNo"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          <span
            style={{
              color: "#085581",
              fontWeight: 600,
              textTransform: "capitalize",
              fontSize: 20,
            }}
          >
            gst details
          </span>

          <div style={{ marginTop: 20 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Registration Type
                  </Label>
                </Col>
                <Col md={8}>
                  <Select
                    isMulti={false}
                    onChange={(e) => {
                      handleChangeInput("registrationType", e);
                    }}
                    value={singleMailingDetails?.registrationType}
                    options={registrationTypeArray}
                    // menuPlacement="top"
                    name="choices-single-default"
                    id="countryAddCompany"
                    className="w-100"
                  ></Select>
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    GSTIN/UIN
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.GST}
                    name="GST"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>
        </div>
      </ModalBody>

      <div className="p-3">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="custom-btn-page-layout btn text-white "
            onClick={() => handleUpdateMailingDetails()}
          >
            Update
          </button>
        </div>
      </div>
    </Modal>
  );
};

const ViewMailingAddressDetailModal = ({
  modalState,
  onCloseModal,
  singleMailingDetails,
  isMobile,
}) => {
  return (
    <Modal
      id="showModal"
      size="xl"
      isOpen={modalState}
      toggle={() => onCloseModal()}
      centered
    >
      <ModalBody className="p-3">
        <div>
          <div className="dashboard-header">
            <span>mailing Address details</span>
          </div>
          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={1}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                  >
                    Address <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={11} style={{ paddingLeft: 25 }}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.address}
                    name="address"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Country <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.country?.label}
                    name="email"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    State <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.state?.label}
                    name="email"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Pincode <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.pincode}
                    name="pincode"
                    disabled
                    className="cust-input"
                    type="number"
                    maxLength={6}
                  />
                </Col>
              </Col>
            </Row>
          </div>

          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Contact Person <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.contactPerson}
                    name="contactPerson"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Phone No.
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.phoneNo}
                    name="phoneNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Mobile No.
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.mobileNo}
                    name="mobileNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          <div style={{ marginTop: 40 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Fax No.
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.faxNo}
                    name="faxNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    E-mail
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.email}
                    name="email"
                    disabled
                    className="cust-input"
                    type="email"
                  />
                </Col>
              </Col>
            </Row>
          </div>
          <span
            style={{
              color: "#085581",
              fontWeight: 600,
              textTransform: "capitalize",
              fontSize: 20,
            }}
          >
            statutory and taxation information
          </span>

          <div style={{ marginTop: 20 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    PAN/Income Tax No.
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.incomeTaxNo}
                    name="incomeTaxNo"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    TIN/Sales Tax No.
                  </Label>
                </Col>
                <Col md={9} className="mx-2">
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.salesTaxNo}
                    name="salesTaxNo"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    CST No.
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.cstNo}
                    name="cstNo"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          <span
            style={{
              color: "#085581",
              fontWeight: 600,
              textTransform: "capitalize",
              fontSize: 20,
            }}
          >
            gst details
          </span>

          <div style={{ marginTop: 20 }}>
            <Row className={`${isMobile ? "" : "mt-2 g-3"}`}>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={4}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Registration Type
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.registrationType?.label}
                    name="email"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${
                  isMobile
                    ? ""
                    : "d-flex justify-content-center align-items-center"
                }`}
              >
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,

                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    GSTIN/UIN
                  </Label>
                </Col>
                <Col md={9}>
                  <Input
                    id="exampleEmail"
                    value={singleMailingDetails?.GST}
                    name="GST"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>
        </div>
      </ModalBody>

      <div className="p-3">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="custom-btn-page-layout btn text-white "
            onClick={() => onCloseModal()}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ViewVendorMaster;
