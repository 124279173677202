import * as yup from "yup";

export const ngfarmerPricetValidationSchema = yup.object({
  farmerid: yup.string().nonNullable().required("Farmer is required"),
  overallprice: yup
    .number()
    .nullable()
    .typeError("Only numeric values are allowed "),
  avgprice: yup.number().typeError("Only numeric values are allowed "),
  sample1price: yup.number().typeError("Only numeric values are allowed "),
  sample2price: yup.number().typeError("Only numeric values are allowed "),
  sample3price: yup.number().typeError("Only numeric values are allowed "),
});
