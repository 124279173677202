import { Col, Form, Row } from "reactstrap";
import PageLayout from "../../Component/PageLayout";
import { Autocomplete, Button, TextField } from "@mui/material";
import { ErrorMessage } from "../../common/Error";
import { Cancel, Save } from "@mui/icons-material";
import TableContainer from "../../Component/TableContainer";
import DeleteModal from "../../Component/DeleteModal";
import { useSubModuleHooks } from "./useModulesHooks";
import { textFieldProps } from "../../common/commonControlProps";

const SubModule = () => {

    const { handleChange, handleSubmit, handleDeleteRequest, formData, column, main_modules_list, deleteModal, deleteLoading, setDeleteModal, onSubmit, register, errors, module_list, onDropDownChange, onDropDownChange1, sub_module_list } = useSubModuleHooks();

    return (
        <PageLayout title={"Sub Module Master"}>
            <Form className="m-4 g-2" onSubmit={handleSubmit(onSubmit)}>
                <Row className="g-2">
                    <Col lg={3} md={3} sm={12}>
                        <TextField
                            {...register("subModuleName")}
                            label="Sub Module Name"
                            name="subModuleName"
                            InputLabelProps={{ shrink: true }}
                            value={formData?.subModuleName}
                            onChange={handleChange}
                            required
                            {...textFieldProps}
                        />
                        {errors?.schemeTypeName && (
                            <ErrorMessage message={errors?.mainModuleName?.message} />
                        )}
                    </Col>
                    <Col lg={3} md={3} sm={12}>
                        <TextField
                            {...register("path")}
                            label="Path"
                            name="path"
                            InputLabelProps={{ shrink: true }}
                            value={formData?.path}
                            onChange={handleChange}
                            {...textFieldProps}
                        />
                        {errors?.schemeTypeName && (
                            <ErrorMessage message={errors?.mainModuleName?.message} />
                        )}
                    </Col>
                    <Col lg={3} md={3} sm={12}>
                        <Autocomplete
                            id="mainModuleId"
                            name="mainModuleId"
                            size="small"
                            options={main_modules_list}
                            clearText={true}
                            value={formData?.selectedMainModule}
                            error={errors?.mainModuleId}
                            onChange={onDropDownChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    {...textFieldProps}
                                    label="Main Module"
                                />
                            )}
                        />
                        {errors?.mainModuleId && (
                            <ErrorMessage message={errors?.mainModuleId?.message} />
                        )}
                    </Col>
                    <Col lg={3} md={3} sm={12}>
                        <Autocomplete
                            id="moduleId"
                            name="moduleId"
                            size="small"
                            options={module_list}
                            clearText={true}
                            value={formData?.selectedModule}
                            error={errors?.moduleId}
                            onChange={onDropDownChange1}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    {...textFieldProps}
                                    label="Module"
                                />
                            )}
                        />
                        {errors?.moduleId && (
                            <ErrorMessage message={errors?.moduleId?.message} />
                        )}
                    </Col>
                    <Col lg={2} md={2} sm={12}>
                        <TextField
                            {...register("priority")}
                            label="Priority"
                            name="priority"
                            InputLabelProps={{ shrink: true }}
                            value={formData?.priority}
                            onChange={handleChange}
                            type="number"
                            required
                            {...textFieldProps}
                        />
                        {errors?.priority && (
                            <ErrorMessage message={errors?.priority?.message} />
                        )}
                    </Col>


                </Row>
                <Row className="mt-2 g-2">
                    <Col lg={1} md={1} sm={12}>
                        <Button
                            className="custom-btn-page-layout text-white "
                            size="small"
                            startIcon={<Save />}
                            fullWidth
                            type="submit"
                        >
                            {` Save`}
                        </Button>
                    </Col>
                    <Col lg={1} md={1} sm={12}>
                        <Button
                            type="button"
                            size="small"
                            className="custom-btn-page-layout custom-btn-danger "
                            fullWidth
                            startIcon={<Cancel />}
                        >
                            Cancel
                        </Button>
                    </Col>

                </Row>

            </Form>

            <Col md={12}>

                <div
                    className="d-grid"
                    style={{ margin: "0 20px", border: "1px solid #DEE2E6" }}
                >
                    <TableContainer
                        columns={column}
                        data={sub_module_list || []}
                        isGlobalFilter={true}
                        showColumnFilters={true}
                        isAddUserList={false}
                        tableClass={"table-sm"}
                        showPagination={true}
                        initialState={{ pageSize: 20, pageIndex: 0 }}
                        divClass={"table-responsive"}
                        exportfileName="purchase list"
                        exports={{
                            excel: true,
                            pdf: true,
                            tally: false,

                        }}
                    />
                </div>
            </Col>
            <DeleteModal
                deleteModal={deleteModal}
                handleDeleteRequest={handleDeleteRequest}
                setDeleteModal={setDeleteModal}
                deleteLoading={deleteLoading}
            />
        </PageLayout>
    );
}

export default SubModule;