import { Col, Form, Row } from "reactstrap";
import PageLayout from "../../Component/PageLayout";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { keys } from "../../config/keys";
import { useWaterWasterManagementHooks } from "./useWaterWasteManagement";
import { textFieldProps } from "../../common/commonControlProps";
import { Button, TextField } from "@mui/material";
import { ErrorMessage } from "../../common/Error";
import { Cancel, Save, Update } from "@mui/icons-material";
import DeleteModal from "../../Component/DeleteModal";
import TableContainer from "../../Component/TableContainer";

const WaterWasteManagement = () => {
  const {
    formData,
    actions,
    form: { register, errors },
    form,
    water_waste_list,
    column,
    state,
  } = useWaterWasterManagementHooks();

  return (
    <PageLayout title={"Water Waste Management"}>
      <Form className="m-4" onSubmit={form.handleSubmit(actions.onSubmit)}>
        <Row className="g-2">
          <Col lg={2} md={2} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Data Reading Date"
                format={keys().shortDateFormat}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    variant: "standard",
                  },
                }}
                onChange={actions.onDateChange}
                value={formData?.readingdate}
                clearable
              />
            </LocalizationProvider>
          </Col>

          <Col lg={2} md={2} sm={12}>
            <TextField
              {...register("mainbore")}
              error={errors.mainbore}
              name="mainbore"
              value={formData?.mainbore}
              label="Main Bore"
              onChange={actions.handleChange}
              type="number"
              {...textFieldProps}
            />
            {errors?.mainbore && (
              <ErrorMessage message={errors?.mainbore?.message} />
            )}
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              {...register("sftnrtankin")}
              error={errors.sftnrtankin}
              name="sftnrtankin"
              value={formData?.sftnrtankin}
              label="SFTNR Tank In"
              type="number"
              onChange={actions.handleChange}
              {...textFieldProps}
            />
            {errors?.sftnrtankin && (
              <ErrorMessage message={errors?.sftnrtankin?.message} />
            )}
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              {...register("sftnrtankout")}
              error={errors.sftnrtankout}
              name="sftnrtankout"
              value={formData?.sftnrtankout}
              label="SFTNR Tank Out"
              type="number"
              onChange={actions.handleChange}
              {...textFieldProps}
            />
            {errors?.sftnrtankout && (
              <ErrorMessage message={errors?.sftnrtankout?.message} />
            )}
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              {...register("security")}
              error={errors.security}
              name="security"
              value={formData?.security}
              label="Security"
              type="number"
              onChange={actions.handleChange}
              {...textFieldProps}
            />
            {errors?.security && (
              <ErrorMessage message={errors?.security?.message} />
            )}
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              {...register("canteen")}
              error={errors.canteen}
              name="canteen"
              value={formData?.canteen}
              label="Canteen"
              type="number"
              onChange={actions.handleChange}
              {...textFieldProps}
            />
            {errors?.canteen && (
              <ErrorMessage message={errors?.canteen?.message} />
            )}
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              {...register("stpwash")}
              error={errors.stpwash}
              name="stpwash"
              value={formData?.stpwash}
              label="STP Wash"
              type="number"
              onChange={actions.handleChange}
              {...textFieldProps}
            />
            {errors?.stpwash && (
              <ErrorMessage message={errors?.stpwash?.message} />
            )}
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              {...register("chrtoi")}
              error={errors.chrtoi}
              name="chrtoi"
              value={formData?.chrtoi}
              label="CHR TOI"
              type="number"
              onChange={actions.handleChange}
              {...textFieldProps}
            />
            {errors?.chrtoi && (
              <ErrorMessage message={errors?.chrtoi?.message} />
            )}
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              {...register("chrsh")}
              error={errors.chrsh}
              name="chrsh"
              value={formData?.chrsh}
              label="CHR SH"
              type="number"
              onChange={actions.handleChange}
              {...textFieldProps}
            />
            {errors?.chrsh && <ErrorMessage message={errors?.chrsh?.message} />}
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              {...register("sftnrwash")}
              error={errors.sftnrwash}
              name="sftnrwash"
              value={formData?.sftnrwash}
              label="SFTNR Wash"
              type="number"
              onChange={actions.handleChange}
              {...textFieldProps}
            />
            {errors?.sftnrwash && (
              <ErrorMessage message={errors?.sftnrwash?.message} />
            )}
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              {...register("stpin")}
              error={errors.stpin}
              name="stpin"
              value={formData?.stpin}
              label="STP IN"
              type="number"
              onChange={actions.handleChange}
              {...textFieldProps}
            />
            {errors?.stpin && <ErrorMessage message={errors?.stpin?.message} />}
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              {...register("stpout")}
              error={errors.stpout}
              name="stpout"
              value={formData?.stpout}
              label="STP OUT"
              type="number"
              onChange={actions.handleChange}
              {...textFieldProps}
            />
            {errors?.stpout && (
              <ErrorMessage message={errors?.stpout?.message} />
            )}
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              {...register("totalizerreading")}
              error={errors.totalizerreading}
              name="totalizerreading"
              value={formData?.totalizerreading}
              label="Totalizer Reading"
              type="number"
              onChange={actions.handleChange}
              {...textFieldProps}
            />
            {errors?.totalizerreading && (
              <ErrorMessage message={errors?.totalizerreading?.message} />
            )}
          </Col>
          <Col lg={1} md={1} sm={12} className="d-flex align-items-end">
            <Button
              className="custom-btn-page-layout custom-btn-success"
              fullWidth
              type="submit"
              size="small"
              startIcon={formData.id > 0 ? <Update /> : <Save />}
            >
              {formData.id > 0 ? ` Update` : `  Save`}
            </Button>
          </Col>
          <Col lg={1} md={1} sm={12} className="d-flex align-items-end">
            <Button
              className="custom-btn-page-layout custom-btn-danger btn btn-sm text-white btn-block"
              type="button"
              onClick={actions.resetFormData}
              startIcon={<Cancel />}
              fullWidth
              size="small"
            >
              {` Cancel`}
            </Button>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col md={12}>
          <div
            className="d-grid"
            style={{ margin: "0px 20px", border: "1px solid #DEE2E6" }}
          >
            <TableContainer
              columns={column}
              data={water_waste_list || []}
              isGlobalFilter={true}
              showColumnFilters={true}
              isAddUserList={false}
              tableClass={"table-sm"}
              showPagination={true}
              initialState={{ pageSize: 10, pageIndex: 0 }}
              divClass={"table-responsive"}
              exportfileName="Water Readings List"
            />
          </div>
        </Col>

        <DeleteModal
          deleteModal={state.deleteModal}
          handleDeleteRequest={actions.onGridDelete}
          setDeleteModal={state.setDeleteModal}
          deleteLoading={state.deleteLoading}
        />
      </Row>
    </PageLayout>
  );
};

export default WaterWasteManagement;
