import * as types from "../constTypes";

const data = [];
// const initialState = {};

export const module_list = (state = data, action) => {
  switch (action.type) {
    case types.MODULE_LIST:
      return [...action.payload];
    default:
      return state;
  }
};

export const role_list = (state = data, action) => {
  switch (action.type) {
    case types.ROLE_LIST:
      return [...action.payload];
    default:
      return state;
  }
};
