// import { Actions } from ".";
import * as types from "../constTypes";

const purchaseorder = {
  purchase_order_list: [],
};

export const purchase = (state = purchaseorder, action) => {
  switch (action.type) {
    case types.PURCHASE_ORDER_LIST:
      return { ...state, purchase_order_list: action.payload };
    default:
      return state;
  }
};

const purchaseorder_list = [];
export const purchaseorderList = (
  state = purchaseorder_list,
  action
) => {
  switch (action.type) {
    case types.purchaseTypes.PURCHASEORDER_LIST:
      return action.payload;
    default:
      return state;
  }
};