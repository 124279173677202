import React, { useEffect, useMemo, useState } from "react";
import PageLayout from "../../Component/PageLayout";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Col,
  Input,
  Label,
  Row,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import TableContainer from "../../Component/TableContainer";
import useBreakPoints from "../../hooks/useBreakPoints";
import {
  TextField,
  Button as MuiButton,
  Typography,
  Link,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Attachment, ExpandLess, ExpandMore } from "@mui/icons-material";
const ViewFarmer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isTablet, isMobile, isLaptop } = useBreakPoints();
  const [step, setStep] = useState("step5");
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const [inputData, setInputData] = useState({
    farmerId: location?.state?.id,
    unit: location?.state?.unitDetails?.unitName,
    farmerName: location?.state?.name,
    farmerCode: location?.state?.farmerCode,
    primaryContactPerson: location?.state?.primaryContactPerson,
    farmerPhoto: {
      url: location?.state?.photo,
      name: location?.state?.photo?.split("/").at(-1).slice(5),
    },
    gender: location?.state?.gender,
    email: location?.state?.email,
    dob: location?.state?.dob,
    addressLine1: location?.state?.addressLine1,
    addressLine2: location?.state?.addressLine2,
    panNo: location?.state?.panNo,
    country: {
      label: location.state?.countryDetails?.name,
      value: location.state?.countryDetails?.id,
    },
    state: {
      label: location.state?.stateDetails?.stateName,
      value: location.state?.stateDetails?.id,
    },
    dist: {
      label: location.state?.districtDetails?.districtName,
      value: location.state?.districtDetails?.id,
    },
    taluka: {
      label: location.state?.talukaDetails?.talukaName,
      value: location.state?.talukaDetails?.id,
    },
    village: {
      label: location.state?.villageDetails?.villageName,
      value: location.state?.villageDetails?.id,
    },
    panCardImage: {
      url: location?.state?.panImg,
      name: location?.state?.panImg?.split("/").at(-1).slice(5),
    },
    aadharCardNo: location?.state?.aadharCardNo,
    pincode: location?.state?.pincode,
    phoneNo: location?.state?.phoneNo?.split("+91")[1],
    aadharCardImage: {
      url: location?.state?.aadharImg,
      name: location?.state?.aadharImg?.split("/").at(-1).slice(5),
    },
    mobileNo: location?.state?.mobileNo?.split("+91")[1],
    ifscCode: location?.state?.bankDetails?.[0]?.ifscCode,
    bankName: location?.state?.bankDetails?.[0]?.bankName,
    accountNo: location?.state?.bankDetails?.[0]?.accountNo,
    branchName: location?.state?.bankDetails?.[0]?.branchName,
    familyDetails: location?.state?.familyDetails,
    cancelCheque: {
      url: location?.state?.bankDetails?.[0]?.cancelledChequeImg,
      name: location?.state?.bankDetails?.[0]?.cancelledChequeImg
        ?.split("/")
        .at(-1)
        .slice(5),
    },
  });

  const [landDetails, setLandDetails] = useState([]);

  const [farmingDetails, setFarmingDetails] = useState([]);

  useEffect(() => {
    if (location?.state?.farmingDetails?.length > 0) {
      let arr = [];

      // eslint-disable-next-line
      location?.state?.farmingDetails?.map((er) => {
        arr.push({
          product: {
            label: er?.farmingProductDetails?.productName,
            value: er?.farmingProductDetails?.id,
          },
          category: {
            label: er?.farmingCategoryDetails?.categoryName,
            value: er?.farmingCategoryDetails?.id,
          },
          vigha: er?.vigha,
          totalVigha: er?.totalRegVigha,
          id: er.id,
        });
      });

      console.log(arr);
      setFarmingDetails(arr);
    }
  }, [location?.state?.farmingDetails]);

  useEffect(() => {
    if (location?.state?.landDetails) {
      let arr = [];
      // eslint-disable-next-line
      location?.state?.landDetails?.map((er) => {
        arr.push({
          landDetailsId: er?.id,
          country: er?.landCountryDetails?.name,

          state: er?.landStateDetails?.stateName,

          dist: er?.landDistrictDetails?.districtName,

          taluka: er?.landTalukaDetails?.talukaName,

          village: er?.landVillageDetails?.villageName,

          surveyNo: er?.surveyNo,
          totalLand: er?.totalLand,
          vigha: er?.vigha,
          regVigha: er?.regVigha,
          registeredLand: er?.registeredLand,
          ownerShipStatus: er?.ownerShipStatus === "True" ? true : false,
          landOwnerName: er?.landOwnerName,
          address: er?.address,
          guardianCountry: er?.guardianCountryDetails?.name,

          guardianState: er?.guardianStateDetails?.stateName,

          guardianDist: er?.guardianDistrictDetails?.districtName,

          guardianTaluka: er?.guardianTalukaDetails?.talukaName,

          guardianVillage: er?.guardianVillageDetails?.villageName,

          guardianPincode: er?.guardianPincode,
          guardianName: er?.guardianName,
          tenantDocument: {
            url: er?.tenantDocument,
            name: er?.tenantDocument?.split("/").at(-1).slice(5),
          },
          agreementDocument: {
            url: er?.agreementDocument,
            name: er?.agreementDocument?.split("/").at(-1).slice(5),
          },
          landDocument: {
            url: er?.landDocument,
            name: er?.landDocument?.split("/").at(-1).slice(5),
          },
        });
      });
      setLandDetails(arr);
    }
  }, [location?.state?.landDetails]);

  // Age Calculation Function
  function calculateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const now = new Date();

    let age = now.getFullYear() - dob.getFullYear();

    // Check if the birthday for the current year has already occurred
    // If not, subtract 1 from the age
    if (
      now.getMonth() < dob.getMonth() ||
      (now.getMonth() === dob.getMonth() && now.getDate() < dob.getDate())
    ) {
      age--;
    }
    return age;
  }

  useEffect(() => {
    if (location?.state?.familyDetails) {
      let arr = [];
      // eslint-disable-next-line
      location?.state?.familyDetails?.map((er, index) => {
        arr.push({
          id: index + 1,
          farmerFamilyDetailsId: er?.id,
          farmerId: inputData?.farmerId,
          name: er?.name,
          relation: er?.relation,
          gender: er?.gender,
          dob: er?.dob,
          age: calculateAge(er?.dob),
        });
      });

      setInputData({ ...inputData, familyDetails: arr });
    }
    // eslint-disable-next-line
  }, []);

  const handleNextBtn = (stepper) => {
    if (stepper === "step1") {
      setStep("step2");
    }

    if (stepper === "step2") {
      setStep("step3");
    }
    if (stepper === "step3") {
      setStep("step6");
    }
  };

  // step2 Table Column
  const column = useMemo(
    () => [
      {
        Header: "Farmer ID",
        accessor: "farmerId",
        filterable: false,
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
      },
      {
        Header: "Gender",
        accessor: "gender",
        filterable: false,
      },
      {
        Header: "Relation",
        accessor: "relation",
        filterable: false,
      },
      // {
      //   Header: "Age",
      //   accessor: "age",
      //   filterable: false,
      // },

      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    [inputData?.familyDetails]
  );

  const Farmingcolumn = useMemo(
    () => [
      {
        Header: "Name Of Product",
        Cell: (cellProps) => {
          return <span>{cellProps?.row?.original?.product?.label}</span>;
        },
      },
      {
        Header: "Category",
        Cell: (cellProps) => {
          return <span>{cellProps?.row?.original?.category?.label}</span>;
        },
      },
      {
        Header: "Size of Vigha",
        accessor: "vigha",
        filterable: false,
      },
      {
        Header: "Total Vigha",
        accessor: "totalVigha",
        filterable: false,
      },

      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    [farmingDetails]
  );

  return (
    <PageLayout
      title={"View Farmer"}
      subTitle={
        "Please provide information for farmer participating in this program"
      }
      backBtnTitle={"Back to Registration list"}
      onClick={() => navigate("/farmerRegistration")}
    >
      {/* Stepper View  */}
      <div className="farm-add-stepper">
        <div
          className={`${step === "step5" ? "farm-stepper-active" : ""}  ${
            isTablet ? "step1-farm-mobile-view" : "step1-farm-add"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M9.00001 2.25C8.40327 2.25 7.83098 2.48705 7.40902 2.90901C6.98706 3.33097 6.75001 3.90326 6.75001 4.5C6.75001 5.09674 6.98706 5.66903 7.40902 6.09099C7.83098 6.51295 8.40327 6.75 9.00001 6.75C9.59675 6.75 10.169 6.51295 10.591 6.09099C11.013 5.66903 11.25 5.09674 11.25 4.5C11.25 3.90326 11.013 3.33097 10.591 2.90901C10.169 2.48705 9.59675 2.25 9.00001 2.25ZM5.25001 4.5C5.25016 3.79048 5.45159 3.09555 5.83091 2.49594C6.21024 1.89633 6.75189 1.41663 7.39296 1.11257C8.03402 0.808505 8.7482 0.692549 9.45254 0.778169C10.1569 0.863789 10.8225 1.14747 11.372 1.59627C11.9216 2.04507 12.3325 2.64056 12.5571 3.31359C12.7818 3.98662 12.8108 4.70956 12.641 5.39846C12.4711 6.08735 12.1093 6.71392 11.5976 7.2054C11.0859 7.69688 10.4452 8.03309 9.75001 8.175V12.75H8.25001V8.175C7.40312 8.00184 6.64201 7.54153 6.09537 6.8719C5.54874 6.20227 5.25012 5.36441 5.25001 4.5ZM2.32876 8.25H6.00001V9.75H3.67126L3.08776 15H14.9123L14.3288 9.75H12V8.25H15.6713L16.5878 16.5H1.41226L2.32876 8.25Z"
              fill={step === "step5" ? "white" : "black"}
            />
          </svg>

          <span className={`${step === "step5" ? "text-white" : "text-black"}`}>
            {isTablet ? "" : "Land Details"}
          </span>
        </div>
        <div
          className={`${step === "step1" ? "farm-stepper-active" : ""} ${
            isTablet ? "step1-farm-mobile-view" : "step2-farm-add"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="24"
            viewBox="0 0 21 24"
            fill="none"
          >
            <g clipPath="url(#clip0_123_361)">
              <path
                d="M10.5 12C12.0913 12 13.6174 11.3679 14.7426 10.2426C15.8679 9.11742 16.5 7.5913 16.5 6C16.5 4.4087 15.8679 2.88258 14.7426 1.75736C13.6174 0.632141 12.0913 0 10.5 0C8.9087 0 7.38258 0.632141 6.25736 1.75736C5.13214 2.88258 4.5 4.4087 4.5 6C4.5 7.5913 5.13214 9.11742 6.25736 10.2426C7.38258 11.3679 8.9087 12 10.5 12ZM8.35781 14.25C3.74063 14.25 0 17.9906 0 22.6078C0 23.3766 0.623438 24 1.39219 24H19.6078C20.3766 24 21 23.3766 21 22.6078C21 17.9906 17.2594 14.25 12.6422 14.25H8.35781Z"
                fill={step === "step1" ? "white" : "black"}
              />
            </g>
            <defs>
              <clipPath id="clip0_123_361">
                <rect
                  width="21"
                  height="24"
                  fill={step === "step1" ? "white" : "black"}
                />
              </clipPath>
            </defs>
          </svg>

          <span className={`${step === "step1" ? "text-white" : "text-black"}`}>
            {isTablet ? "" : "Personal Details"}
          </span>
        </div>
        <div
          className={`${step === "step2" ? "farm-stepper-active" : ""} ${
            isTablet ? "step1-farm-mobile-view" : "step3-farm-add"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clipPath="url(#clip0_123_363)">
              <path
                d="M14.25 11.25C14.2498 11.8469 14.0125 12.4193 13.5903 12.8413C13.168 13.2633 12.5955 13.5002 11.9985 13.5C11.4016 13.4998 10.8292 13.2625 10.4072 12.8402C9.98528 12.418 9.74834 11.8454 9.74854 11.2485C9.74873 10.6516 9.98606 10.0792 10.4083 9.6572C10.8305 9.23524 11.4031 8.9983 12 8.9985C12.597 8.9987 13.1694 9.23602 13.5913 9.65826C14.0133 10.0805 14.2502 10.6531 14.25 11.25ZM21.405 6H17.595C16.9068 6 16.2467 6.2734 15.7601 6.76006C15.2734 7.24671 15 7.90676 15 8.595V13.5C15 13.8978 15.1581 14.2794 15.4394 14.5607C15.7207 14.842 16.1022 15 16.5 15V24H22.5V15C22.8979 15 23.2794 14.842 23.5607 14.5607C23.842 14.2794 24 13.8978 24 13.5V8.595C24 7.90676 23.7266 7.24671 23.24 6.76006C22.7533 6.2734 22.0933 6 21.405 6Z"
                fill={step === "step2" ? "white" : "black"}
              />
              <path
                d="M22.5 3.00005C22.509 3.39956 22.4382 3.79684 22.2915 4.16858C22.1449 4.54033 21.9255 4.87903 21.6462 5.16481C21.3669 5.45059 21.0333 5.67768 20.665 5.83276C20.2967 5.98784 19.9011 6.06777 19.5015 6.06787C19.1019 6.06797 18.7063 5.98823 18.3379 5.83334C17.9696 5.67845 17.6359 5.45152 17.3564 5.16588C17.0769 4.88024 16.8573 4.54165 16.7105 4.16998C16.5637 3.79831 16.4927 3.40106 16.5015 3.00155C16.5188 2.21771 16.8423 1.4718 17.4028 0.923492C17.9632 0.375189 18.716 0.0680672 19.5 0.0678712C20.284 0.0676751 21.037 0.374421 21.5977 0.922443C22.1584 1.47047 22.4823 2.21621 22.5 3.00005ZM6.405 7.50005H2.595C2.25412 7.50015 1.9166 7.56739 1.60171 7.69792C1.28681 7.82846 1.00072 8.01975 0.759747 8.26086C0.518779 8.50196 0.32766 8.78817 0.197303 9.10314C0.0669451 9.41811 -9.8383e-05 9.75567 1.0836e-07 10.0965C9.85998e-05 10.4374 0.0673372 10.7749 0.197877 11.0898C0.328416 11.4047 0.519701 11.6908 0.760808 11.9318C1.00192 12.1728 1.28812 12.3639 1.60309 12.4942C1.91806 12.6246 2.25562 12.6916 2.5965 12.6915C3.28494 12.6913 3.9451 12.4177 4.43175 11.9307C4.91841 11.4438 5.1917 10.7835 5.1915 10.095C5.1913 9.40661 4.91763 8.74645 4.43069 8.2598C3.94375 7.77314 3.28344 7.49985 2.595 7.50005C1.90676 7.50005 1.2464 7.7742 0.759747 8.26086C0.27309 8.74751 1.0836e-07 9.40831 1.0836e-07 10.0965L1.63061e-07 13.5C1.63061e-07 13.8979 0.158035 14.2794 0.43934 14.5607C0.720645 14.842 1.10218 15 1.5 15L1.63061e-07 19.5H1.5V24H7.5V19.5H9L7.5 15C7.89782 15 8.27936 14.842 8.56066 14.5607C8.84196 14.2794 9 13.8979 9 13.5V10.095C9 9.75427 8.93288 9.41682 8.80247 9.10198C8.67206 8.78714 8.48091 8.50107 8.23994 8.26011C7.99897 8.01914 7.7129 7.82799 7.39806 7.69758C7.08322 7.56717 6.74578 7.50005 6.405 7.50005Z"
                fill={step === "step2" ? "white" : "black"}
              />
              <path
                d="M7.49997 4.50005C7.509 4.89956 7.43813 5.29684 7.29151 5.66858C7.14489 6.04033 6.92548 6.37903 6.64616 6.66481C6.36684 6.95059 6.03324 7.17768 5.66495 7.33276C5.29666 7.48784 4.90109 7.56777 4.50148 7.56787C4.10187 7.56797 3.70627 7.48823 3.3379 7.33334C2.96953 7.17845 2.63582 6.95152 2.35635 6.66588C2.07689 6.38024 1.85731 6.04165 1.71051 5.66998C1.5637 5.29831 1.49263 4.90106 1.50147 4.50155C1.5188 3.71771 1.8423 2.9718 2.40272 2.42349C2.96315 1.87519 3.71595 1.56807 4.49998 1.56787C5.28402 1.56768 6.03697 1.87442 6.59767 2.42244C7.15836 2.97047 7.48224 3.71621 7.49997 4.50005ZM10.5 19.5V24H13.5V19.5C13.8978 19.5 14.2793 19.342 14.5606 19.0607C14.8419 18.7794 15 18.3979 15 18V15.69C15 15.1092 14.7692 14.5522 14.3585 14.1415C13.9478 13.7308 13.3908 13.5 12.81 13.5H11.19C10.6091 13.5 10.0521 13.7308 9.6414 14.1415C9.2307 14.5522 8.99997 15.1092 8.99997 15.69V18C8.99997 18.3979 9.158 18.7794 9.43931 19.0607C9.72061 19.342 10.1021 19.5 10.5 19.5Z"
                fill={step === "step2" ? "white" : "black"}
              />
            </g>
            <defs>
              <clipPath id="clip0_123_363">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span className={`${step === "step2" ? "text-white" : "text-black"}`}>
            {isTablet ? "" : "Family Details"}
          </span>
        </div>
        <div
          className={`${step === "step3" ? "farm-stepper-active" : ""}  ${
            isTablet ? "step1-farm-mobile-view" : "step4-farm-add"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M10.968 2.32496C11.2676 2.10618 11.629 1.98828 12 1.98828C12.371 1.98828 12.7324 2.10618 13.032 2.32496L20.453 7.74096C21.43 8.45296 20.927 9.99796 19.719 10.001H4.28C3.072 9.99796 2.57 8.45296 3.546 7.74096L10.967 2.32496H10.968ZM13 6.24996C13 5.98474 12.8946 5.73039 12.7071 5.54285C12.5196 5.35532 12.2652 5.24996 12 5.24996C11.7348 5.24996 11.4804 5.35532 11.2929 5.54285C11.1054 5.73039 11 5.98474 11 6.24996C11 6.51518 11.1054 6.76953 11.2929 6.95707C11.4804 7.1446 11.7348 7.24996 12 7.24996C12.2652 7.24996 12.5196 7.1446 12.7071 6.95707C12.8946 6.76953 13 6.51518 13 6.24996ZM11.25 16H9.25V11H11.25V16ZM14.75 16H12.75V11H14.75V16ZM18.5 16H16.25V11H18.5V16ZM18.75 17H5.25C4.65326 17 4.08097 17.237 3.65901 17.659C3.23705 18.0809 3 18.6532 3 19.25V19.75C3 20.165 3.336 20.5 3.75 20.5H20.25C20.4489 20.5 20.6397 20.4209 20.7803 20.2803C20.921 20.1396 21 19.9489 21 19.75V19.25C21 18.6532 20.7629 18.0809 20.341 17.659C19.919 17.237 19.3467 17 18.75 17ZM7.75 16H5.5V11H7.75V16Z"
              fill={step === "step3" ? "white" : "black"}
            />
          </svg>
          <span className={`${step === "step3" ? "text-white" : "text-black"}`}>
            {isTablet ? "" : "Bank Details"}
          </span>
        </div>

        <div
          className={`${step === "step6" ? "farm-stepper-active" : ""} ${
            isTablet ? "step1-farm-mobile-view" : "step5-farm-add"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M0.632812 17.3672L1.91074 5.75158C2.14727 9.5351 2.05439 13.9015 3.16076 16.1462C3.55468 13.1606 3.71296 10.2337 3.53208 7.39197C4.06733 9.91232 4.78164 12.3729 4.91963 15.0258C5.17961 13.0593 5.36495 11.1052 6.03752 9.06998C6.0108 11.3618 5.43424 13.461 6.4832 14.606C6.99061 12.8127 7.50245 11.0236 7.58921 8.80947C8.33699 10.9843 8.23405 13.5844 8.46629 16.0172C8.7622 13.6245 9.00348 6.95252 10.1154 4.83154C10.006 9.22537 9.97959 12.7044 11.0786 14.4724C12.1648 13.1128 12.2586 10.4492 12.2837 7.92143C13.0506 10.0923 13.5654 12.5656 13.6146 15.5974C13.9179 13.0678 14.2709 10.5381 15.1401 8.00861C14.938 11.1126 15.0949 13.5264 15.8265 14.8349C16.3103 12.882 16.7231 10.929 16.8503 8.97576C17.4978 11.886 17.3073 14.5863 17.4376 17.3672H0.632812Z"
              fill={step === "step6" ? "white" : "black"}
            />
          </svg>

          <span className={`${step === "step6" ? "text-white" : "text-black"}`}>
            {isTablet ? "" : "Farming Details"}
          </span>
        </div>
      </div>

      {step === "step1" && (
        <div className="mt-5" style={{ margin: 40 }}>
          {/* Farmer ID and Farmer Photo  */}
          <div>
            <Row className={`${isMobile ? "" : " g-3 mt-2"}`}>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Farmer ID
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.farmerId}
                    name="farmerId"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Unit Address{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.unit}
                    name="name"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Farmer Code
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.farmerCode}
                    name="farmerCode"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>
          {/* FarmerName , gender and dob */}
          <div>
            <Row className={`${isMobile ? "" : " g-3 mt-2"}`}>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Farmer Name
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.farmerName}
                    name="farmerName"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Gender
                  </Label>
                </Col>
                <Col sm={8} className="d-flex mt-2">
                  <div>
                    <Input
                      id="exampleEmail"
                      value={inputData?.gender}
                      style={{ marginRight: 5 }}
                      name="gender"
                      disabled
                      className="cust-input"
                      type="text"
                    />
                  </div>
                </Col>
              </Col>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    DOB
                  </Label>
                </Col>
                <Col sm={8}>
                  <Flatpickr
                    className="form-control"
                    options={{
                      dateFormat: "Y-m-d",
                      maxDate: new Date(),
                    }}
                    value={inputData?.dob}
                    disabled
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* address1 , address2 and panNo */}
          <div>
            <Row className={`${isMobile ? "" : " g-3 mt-2"}`}>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Address Line 1
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.addressLine1}
                    name="addressLine1"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Address Line 2
                  </Label>
                </Col>
                <Col sm={8} className="d-flex mt-2">
                  <Input
                    id="exampleEmail"
                    value={inputData?.addressLine2}
                    name="addressLine2"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    PAN No.
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.panNo}
                    name="panNo"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* Country , state and panImage */}
          <div>
            <Row className={`${isMobile ? "" : " g-3 mt-2"}`}>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Country
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.country.label}
                    name="panNo"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    State
                  </Label>
                </Col>
                <Col sm={8} className="d-flex mt-2">
                  <Input
                    id="exampleEmail"
                    value={inputData?.state.label}
                    name="panNo"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    PAN Card
                  </Label>
                </Col>
                <Col md={8}>
                  {" "}
                  <span
                    className="mx-3"
                    style={{ fontWeight: 500, fontSize: 10 }}
                  >
                    {inputData?.panCardImage
                      ? inputData?.panCardImage?.name
                      : ".jpg, .png, .jpeg upload (Max size 150 KB)"}
                  </span>
                </Col>
              </Col>
            </Row>
          </div>

          {/* dist , city and addharCardNo */}
          <div>
            <Row className={`${isMobile ? "" : " g-3 mt-2"}`}>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Dist
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.dist.label}
                    name="panNo"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Taluka
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.taluka.label}
                    name="panNo"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Village
                  </Label>
                </Col>
                <Col sm={8} className="d-flex mt-2">
                  <Input
                    id="exampleEmail"
                    value={inputData?.village.label}
                    name="panNo"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* pincode , phoneNo and addharImage */}
          <div>
            <Row className={`${isMobile ? "" : " g-3 mt-2"}`}>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Pincode
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.pincode}
                    name="pincode"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>

              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Mobile No.
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.mobileNo}
                    name="mobileNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Designated Successor
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.primaryContactPerson}
                    name="primaryContactPerson"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* Mobile No. */}
          <div>
            <Row className={`${isMobile ? "" : " g-3 mt-2"}`}>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Farmer Photo
                  </Label>
                </Col>
                <Col md={8}>
                  {" "}
                  <span
                    className="mx-3"
                    style={{ fontWeight: 500, fontSize: 10 }}
                  >
                    {location?.state?.photo && (
                      <img
                        src={inputData?.farmerPhoto?.url}
                        style={{ height: "100px", width: "100px" }}
                        alt="Captured"
                      />
                    )}
                  </span>
                </Col>
              </Col>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3 mb-0"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Alternative No.
                  </Label>
                </Col>
                <Col sm={8} className="d-flex mt-2">
                  <Input
                    id="exampleEmail"
                    value={inputData?.phoneNo}
                    name="phoneNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={3}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Farmer Email
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.email}
                    name="email"
                    disabled
                    className="cust-input"
                    type="email"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* Next Button View */}
          <div>
            <Col
              md={12}
              className="d-flex justify-content-end align-items-end mt-2"
            >
              <button
                type="button"
                className="custom-btn-page-layout btn text-white mx-3"
                onClick={() => setStep("step5")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  style={{ marginRight: 5 }}
                >
                  <path
                    d="M15 8C15 9.38447 14.5895 10.7378 13.8203 11.889C13.0511 13.0401 11.9579 13.9373 10.6788 14.4672C9.3997 14.997 7.99224 15.1356 6.63437 14.8655C5.2765 14.5954 4.02922 13.9287 3.05025 12.9497C2.07129 11.9708 1.4046 10.7235 1.1345 9.36563C0.864407 8.00776 1.00303 6.6003 1.53284 5.32122C2.06266 4.04213 2.95986 2.94888 4.11101 2.17971C5.26215 1.41054 6.61553 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8ZM12 7.5H5.925L8.715 4.6965L8 4L4 8L8 12L8.715 11.2865L5.925 8.5H12V7.5Z"
                    fill="white"
                  />
                </svg>
                Previous
              </button>

              <button
                type="button"
                className="custom-btn-page-layout btn text-white "
                onClick={() => handleNextBtn("step1")}
              >
                Next
                {!isMobile && (
                  <svg
                    style={{ marginLeft: 5 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M1 8C1 9.38447 1.41054 10.7378 2.17971 11.889C2.94888 13.0401 4.04213 13.9373 5.32122 14.4672C6.6003 14.997 8.00776 15.1356 9.36563 14.8655C10.7235 14.5954 11.9708 13.9287 12.9497 12.9497C13.9287 11.9708 14.5954 10.7235 14.8655 9.36563C15.1356 8.00776 14.997 6.6003 14.4672 5.32122C13.9373 4.04213 13.0401 2.94888 11.889 2.17971C10.7378 1.41054 9.38447 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8ZM4 7.5H10.075L7.285 4.6965L8 4L12 8L8 12L7.285 11.2865L10.075 8.5H4V7.5Z"
                      fill="white"
                    />
                  </svg>
                )}
              </button>
            </Col>
          </div>
        </div>
      )}

      {step === "step2" && (
        <div className="mt-5" style={{ margin: 40 }}>
          {/* Search and Table View */}
          <div className="" style={{ marginTop: isMobile ? 40 : 145 }}>
            <div className={`d-flex ${isLaptop ? "flex-column mt-3" : ""}`}>
              <div
                className="col-md-8"
                style={{ padding: "20px 0px 0px 20px" }}
              >
                <TableContainer
                  columns={column}
                  data={inputData?.familyDetails}
                  isGlobalFilter={false}
                  isAddUserList={false}
                  tableClass={"table-sm"}
                  divClass={"table-responsive"}
                />
              </div>
              <div
                className={`col-lg-4 col-md-12 col-sm-12 d-flex justify-content-end align-items-end
             }
             `}
                // style={{ marginBottom: 20 }}
              >
                <button
                  type="button"
                  className="custom-btn-page-layout btn text-white "
                  onClick={() => setStep("step1")}
                >
                  {!isMobile && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      style={{ marginRight: 5 }}
                    >
                      <path
                        d="M15 8C15 9.38447 14.5895 10.7378 13.8203 11.889C13.0511 13.0401 11.9579 13.9373 10.6788 14.4672C9.3997 14.997 7.99224 15.1356 6.63437 14.8655C5.2765 14.5954 4.02922 13.9287 3.05025 12.9497C2.07129 11.9708 1.4046 10.7235 1.1345 9.36563C0.864407 8.00776 1.00303 6.6003 1.53284 5.32122C2.06266 4.04213 2.95986 2.94888 4.11101 2.17971C5.26215 1.41054 6.61553 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8ZM12 7.5H5.925L8.715 4.6965L8 4L4 8L8 12L8.715 11.2865L5.925 8.5H12V7.5Z"
                        fill="white"
                      />
                    </svg>
                  )}
                  Previous
                </button>
                <button
                  type="button"
                  className="custom-btn-page-layout btn text-white mx-4"
                  onClick={() => handleNextBtn("step2")}
                >
                  Next
                  {!isMobile && (
                    <svg
                      style={{ marginLeft: 5 }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M1 8C1 9.38447 1.41054 10.7378 2.17971 11.889C2.94888 13.0401 4.04213 13.9373 5.32122 14.4672C6.6003 14.997 8.00776 15.1356 9.36563 14.8655C10.7235 14.5954 11.9708 13.9287 12.9497 12.9497C13.9287 11.9708 14.5954 10.7235 14.8655 9.36563C15.1356 8.00776 14.997 6.6003 14.4672 5.32122C13.9373 4.04213 13.0401 2.94888 11.889 2.17971C10.7378 1.41054 9.38447 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8ZM4 7.5H10.075L7.285 4.6965L8 4L12 8L8 12L7.285 11.2865L10.075 8.5H4V7.5Z"
                        fill="white"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className=""></div>
        </div>
      )}

      {step === "step3" && (
        <div className="mt-5" style={{ margin: 40 }}>
          {/* bankName and  ifsc  */}
          <div>
            <Row className={`${isMobile ? "" : " g-3 mt-2"}`}>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={4}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Bank Name
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.bankName}
                    name="bankName"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={4}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    IFSC Code
                  </Label>
                </Col>
                <Col sm={8} className="d-flex mt-2">
                  <Input
                    id="exampleEmail"
                    value={inputData?.ifscCode}
                    name="ifscCode"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* accountNo and  branchName  */}
          <div>
            <Row className={`${isMobile ? "" : " g-3 mt-2"}`}>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={4}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Account Number
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.accountNo}
                    name="accountNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={4}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Branch Name
                  </Label>
                </Col>
                <Col sm={8} className="d-flex mt-2">
                  <Input
                    id="exampleEmail"
                    value={inputData?.branchName}
                    name="branchName"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* Upload Options  */}
          <div>
            <Row className={`${isMobile ? "" : " g-3 mt-2"}`}>
              <Col
                lg={4}
                md={12}
                sm={12}
                className={`${isMobile ? "" : "d-flex"}`}
              >
                <Col md={4}>
                  <Label
                    className="me-3 "
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Upload Options
                  </Label>
                </Col>
                <Col md={8}>
                  {" "}
                  <span
                    className="mx-3"
                    style={{ fontWeight: 500, fontSize: 10 }}
                  >
                    {inputData?.cancelCheque
                      ? inputData?.cancelCheque?.name
                      : "Option to Upload Cancel Cheque or Bank Passbook Front Page"}
                  </span>
                </Col>
              </Col>
            </Row>
          </div>

          <div className="">
            <Col
              md={8}
              className="d-flex justify-content-end align-items-end mt-5"
            >
              <button
                type="button"
                className="custom-btn-page-layout btn text-white "
                onClick={() => setStep("step2")}
              >
                {!isMobile && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    style={{ marginRight: 5 }}
                  >
                    <path
                      d="M15 8C15 9.38447 14.5895 10.7378 13.8203 11.889C13.0511 13.0401 11.9579 13.9373 10.6788 14.4672C9.3997 14.997 7.99224 15.1356 6.63437 14.8655C5.2765 14.5954 4.02922 13.9287 3.05025 12.9497C2.07129 11.9708 1.4046 10.7235 1.1345 9.36563C0.864407 8.00776 1.00303 6.6003 1.53284 5.32122C2.06266 4.04213 2.95986 2.94888 4.11101 2.17971C5.26215 1.41054 6.61553 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8ZM12 7.5H5.925L8.715 4.6965L8 4L4 8L8 12L8.715 11.2865L5.925 8.5H12V7.5Z"
                      fill="white"
                    />
                  </svg>
                )}
                Previous
              </button>
              <button
                type="button"
                className="custom-btn-page-layout btn text-white mx-4"
                onClick={() => handleNextBtn("step3")}
              >
                Next
                {!isMobile && (
                  <svg
                    style={{ marginLeft: 5 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M1 8C1 9.38447 1.41054 10.7378 2.17971 11.889C2.94888 13.0401 4.04213 13.9373 5.32122 14.4672C6.6003 14.997 8.00776 15.1356 9.36563 14.8655C10.7235 14.5954 11.9708 13.9287 12.9497 12.9497C13.9287 11.9708 14.5954 10.7235 14.8655 9.36563C15.1356 8.00776 14.997 6.6003 14.4672 5.32122C13.9373 4.04213 13.0401 2.94888 11.889 2.17971C10.7378 1.41054 9.38447 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8ZM4 7.5H10.075L7.285 4.6965L8 4L12 8L8 12L7.285 11.2865L10.075 8.5H4V7.5Z"
                      fill="white"
                    />
                  </svg>
                )}
              </button>
            </Col>
          </div>
        </div>
      )}
      {step === "step5" && (
        <div className="m-4">
          <Row className={`g-3 mt-2`}>
            <Col lg={3} md={3} sm={12}>
              <TextField
                value={inputData?.aadharCardNo}
                label="Aadhar Card"
                size="small"
                variant="standard"
                fullWidth
                disabled
              />
            </Col>

            <Col lg={4} md={4} sm={12}>
              <Typography variant="subtitle2" gutterBottom>
                {`Aadhar Image`}
              </Typography>
              <Attachment fontSize="medium" color="success" />
              {inputData?.aadharCardImage ? (
                <Link target="_blank" href={inputData?.aadharCardImage?.url}>
                  {inputData?.aadharCardImage?.name}
                </Link>
              ) : (
                <Typography variant="subtitle2" gutterBottom>
                  {`Aadhar Image Not uploaded`}
                </Typography>
              )}
            </Col>
          </Row>
          {landDetails?.map((item, index) => (
            <Accordion
              key={index}
              open={open}
              className={index === 0 ? "mt-2" : ""}
              toggle={toggle}
            >
              <fieldset
                className="cus-add-req-sur border-none m-0 p-0"
                style={{ border: "none" }}
              >
                <AccordionItem>
                  <AccordionHeader targetId={`${index + 1}`}>
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div>
                        {`Land No : ${index + 1} [${`${item?.landOwnerName} - ${
                          item?.village?.label || item?.village
                        } - ${item?.surveyNo}`}]`}
                      </div>
                      <div>
                        {Number(index + 1) === Number(open) ? (
                          <IconButton className="p-1 text-white custom-btn-page-layout custom-btn-black text-white no-shadow rounded">
                            <ExpandMore />
                          </IconButton>
                        ) : (
                          <IconButton className="p-1 text-white custom-btn-page-layout custom-btn-black text-white no-shadow rounded">
                            <ExpandLess />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  </AccordionHeader>
                  <AccordionBody accordionId={`${index + 1}`}>
                    {/* State, Dist and Taluka  */}
                    <Row className="g-2 mt-2">
                      <Col lg={3} md={3} sm={12}>
                        <TextField
                          value={item?.village}
                          label="Village"
                          size="small"
                          variant="standard"
                          fullWidth
                          disabled
                        />
                      </Col>
                      <Col lg={3} md={3} sm={12}>
                        <TextField
                          value={item?.taluka}
                          label="Taluka"
                          size="small"
                          variant="standard"
                          fullWidth
                          disabled
                        />
                      </Col>
                      <Col lg={2} md={2} sm={12}>
                        <TextField
                          value={item?.dist}
                          label="District"
                          size="small"
                          variant="standard"
                          fullWidth
                          disabled
                        />
                      </Col>
                      <Col lg={2} md={2} sm={12}>
                        <TextField
                          value={item?.state}
                          label="State"
                          size="small"
                          variant="standard"
                          fullWidth
                          disabled
                        />
                      </Col>
                      <Col lg={2} md={2} sm={12}>
                        <TextField
                          value={item?.country}
                          label="State"
                          size="small"
                          variant="standard"
                          fullWidth
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row className=" g-2 mt-2">
                      <Col lg={2} md={12} sm={12} className="d-flex ">
                        <TextField
                          value={item?.surveyNo}
                          name="surveyNo"
                          label="Survey #"
                          size="small"
                          variant="standard"
                          fullWidth
                          disabled
                        />
                      </Col>
                      <Col lg={3} md={3} sm={12}>
                        <TextField
                          value={item?.totalLand}
                          name="totalLand"
                          label="Total Land in Document (In Sqm)"
                          disabled
                          size="small"
                          variant="standard"
                          fullWidth
                        />
                      </Col>
                      <Col lg={2} md={3} sm={12}>
                        <TextField
                          value={item?.vigha}
                          name="vigha"
                          label="Total Vigha"
                          disabled
                          size="small"
                          variant="standard"
                          fullWidth
                        />
                      </Col>

                      <Col lg={2} md={3} sm={12}>
                        <TextField
                          value={item?.regVigha}
                          name="regVigha"
                          label="Registered Vigha"
                          disabled
                          size="small"
                          variant="standard"
                          fullWidth
                        />
                      </Col>
                      <Col lg={3} md={3} sm={12}>
                        <TextField
                          value={item?.registeredLand}
                          name="registeredLand"
                          label="Total Registered Land (In Sqm)"
                          size="small"
                          variant="standard"
                          fullWidth
                          disabled
                        />
                      </Col>
                    </Row>

                    {/*  Ownership Status */}
                    <div
                      style={{}}
                      className={`mt-2 ${
                        isTablet
                          ? ""
                          : "d-flex justify-content-start align-items-center"
                      }`}
                    >
                      <Row>
                        <Col>
                          <FormControlLabel
                            value="start"
                            style={{ marginLeft: "unset" }}
                            control={
                              <Checkbox
                                checked={item?.ownerShipStatus}
                                name="ownerShipStatus"
                                color="success"
                                disabled
                              />
                            }
                            label="Ownership Status (Is Rented?) "
                            labelPlacement="start"
                          />
                        </Col>
                      </Row>
                    </div>

                    {/* LandOwnerName/ FarmerName  and Address  */}
                    <div className="pb-0">
                      <Row className=" g-2 mt-2">
                        <Col lg={4} md={12} sm={12}>
                          <TextField
                            value={
                              !item?.ownerShipStatus
                                ? item?.landOwnerName
                                : item?.guardianName
                            }
                            name={
                              !item?.ownerShipStatus
                                ? "landOwnerName"
                                : "guardianName"
                            }
                            label={`${
                              !item?.ownerShipStatus
                                ? "Land Owner Name"
                                : "Farmer Name"
                            }`}
                            disabled
                            size="small"
                            variant="standard"
                            fullWidth
                          />
                        </Col>
                        <Col lg={8} md={12} sm={12}>
                          <TextField
                            value={item?.address}
                            name="address"
                            label="Address"
                            disabled
                            size="small"
                            variant="standard"
                            fullWidth
                          />
                        </Col>
                      </Row>
                    </div>

                    {/* Farmer State  and Farmer Dist  */}
                    <Row className=" g-2 mt-2">
                      <Col lg={2} md={2} sm={12}>
                        <TextField
                          value={item?.guardianPincode}
                          name="guardianPincode"
                          label="Pincode"
                          disabled
                          size="small"
                          variant="standard"
                          fullWidth
                        />
                      </Col>
                      <Col lg={2} md={2} sm={12}>
                        <TextField
                          label="Village"
                          size="small"
                          value={item.guardianVillage}
                          disabled
                          variant="standard"
                          fullWidth
                        />
                      </Col>
                      <Col lg={2} md={2} sm={12}>
                        <TextField
                          id="guardianVillage"
                          name="guardianVillage"
                          label="Taluka"
                          size="small"
                          variant="standard"
                          value={item.guardianTaluka}
                          disabled
                        />
                      </Col>
                      <Col lg={2} md={2} sm={12}>
                        <TextField
                          label="District"
                          size="small"
                          value={item.guardianDist}
                          disabled
                          variant="standard"
                          fullWidth
                        />
                      </Col>
                      <Col lg={2} md={2} sm={12}>
                        <TextField
                          label="State"
                          size="small"
                          value={item.guardianState}
                          disabled
                          variant="standard"
                          fullWidth
                        />
                      </Col>
                      <Col lg={2} md={2} sm={12}>
                        <TextField
                          label="Country"
                          size="small"
                          value={item.guardianCountry}
                          disabled
                          variant="standard"
                          fullWidth
                        />
                      </Col>
                    </Row>

                    {/* Upload Document  */}

                    <Row className={`mt-2 g-3`}>
                      <Col lg={4} md={4} sm={12} className={``}>
                        <Typography variant="subtitle2" gutterBottom>
                          {`Land Document`}
                        </Typography>
                        <Attachment fontSize="medium" color="success" />
                        {item?.landDocument ? (
                          <Link target="_blank" href={item?.landDocument?.url}>
                            {item?.landDocument?.name}
                          </Link>
                        ) : (
                          <Typography variant="subtitle2" gutterBottom>
                            {`Land Not uploaded`}
                          </Typography>
                        )}
                      </Col>

                      <Col lg={3} md={12} sm={12} className={``}>
                        {item?.ownerShipStatus && (
                          <>
                            <Col md={4}>
                              <Label
                                className="me-3"
                                style={{ fontWeight: 500 }}
                                for="exampleEmail"
                                md={12}
                              >
                                Agreement Document :
                              </Label>
                            </Col>
                            <Col md={8}>
                              <span
                                className="mx-3"
                                style={{ fontWeight: 500, fontSize: 10 }}
                              >
                                {item?.agreementDocument
                                  ? item?.agreementDocument?.name
                                  : " Option to upload copy of 7/12 (PDF/image)"}
                              </span>
                            </Col>
                          </>
                        )}
                      </Col>
                      <Col
                        lg={4}
                        md={12}
                        sm={12}
                        className={`${
                          isMobile
                            ? ""
                            : "d-flex justify-content-center align-items-center"
                        }`}
                      >
                        {item?.ownerShipStatus && (
                          <>
                            <Col md={4}>
                              <Label
                                className="me-3"
                                style={{ fontWeight: 500 }}
                                for="exampleEmail"
                                md={12}
                              >
                                Tenant Document :
                              </Label>
                            </Col>
                            <Col md={8}>
                              <span
                                className="mx-3"
                                style={{ fontWeight: 500, fontSize: 10 }}
                              >
                                {item?.tenantDocument
                                  ? item?.tenantDocument?.name
                                  : "Photo of Tenant"}
                              </span>
                            </Col>
                          </>
                        )}
                      </Col>
                    </Row>
                  </AccordionBody>
                </AccordionItem>
              </fieldset>
            </Accordion>
          ))}

          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="custom-btn-page-layout btn text-white mx-4"
              onClick={(e) => setStep("step1")}
            >
              Next
            </button>
          </div>
        </div>
      )}
      {step === "step6" && (
        <div>
          <Row className={`${isMobile ? "" : " mt-2 g-3"}`}>
            <Col md={12}>
              {/* Table View */}
              <div
                className="d-grid"
                style={{ margin: "0 20px", border: "1px solid #DEE2E6" }}
              >
                <TableContainer
                  columns={Farmingcolumn}
                  data={farmingDetails || []}
                  isGlobalFilter={false}
                  isAddUserList={false}
                  tableClass={"table-sm"}
                  divClass={"table-responsive"}
                />
              </div>
            </Col>
          </Row>

          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="custom-btn-page-layout btn text-white mx-3"
              onClick={() => setStep("step3")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                style={{ marginRight: 5 }}
              >
                <path
                  d="M15 8C15 9.38447 14.5895 10.7378 13.8203 11.889C13.0511 13.0401 11.9579 13.9373 10.6788 14.4672C9.3997 14.997 7.99224 15.1356 6.63437 14.8655C5.2765 14.5954 4.02922 13.9287 3.05025 12.9497C2.07129 11.9708 1.4046 10.7235 1.1345 9.36563C0.864407 8.00776 1.00303 6.6003 1.53284 5.32122C2.06266 4.04213 2.95986 2.94888 4.11101 2.17971C5.26215 1.41054 6.61553 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8ZM12 7.5H5.925L8.715 4.6965L8 4L4 8L8 12L8.715 11.2865L5.925 8.5H12V7.5Z"
                  fill="white"
                />
              </svg>
              Previous
            </button>

            <button
              type="button"
              className="custom-btn-page-layout btn text-white mx-4"
              onClick={(e) => navigate("/farmerRegistration")}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </PageLayout>
  );
};

export default ViewFarmer;
