import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageLayout from "../../Component/PageLayout";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import TableContainer from "../../Component/TableContainer";
import { toast } from "react-toastify";
import DeleteModal from "../../Component/DeleteModal";
import CustomPagination from "../../Component/CustomPagination";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import useBreakPoints from "../../hooks/useBreakPoints";
import GlobalSearch from "../../common/GlobalSearch";
import {
  Check,
  Clear,
  ControlPoint,
  Delete,
  Edit,
  Remove,
  Send,
  Verified,
  Visibility,
} from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { get_farmer_list } from "../../redux/actions/farmer";
import { SelectColumnFilter } from "../../Component/filters";
import { useFarmerHooks } from "./useFarmerHooks";
import LoaderComponent from "../../Component/LoaderComponent";
import { farmerActions } from "../../redux/actions";

const FarmerRegistration = () => {
  const {
    layout: { permissions, token },
    farmer_list,
  } = useSelector((state) => state);
  const { isTablet } = useBreakPoints();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let permissionsObj = {};
  // eslint-disable-next-line
  permissions?.filter((er) => {
    if (er?.ModuleData?.length > 0) {
      // eslint-disable-next-line
      let filtererdDarta = er?.ModuleData?.filter((ed) => {
        if (ed?.subModuleData?.length > 0) {
          // eslint-disable-next-line
          ed?.subModuleData?.map((qw) => {
            if (qw?.path === "/farmerRegistration") {
              permissionsObj = qw;
            }
          });
        }
      });
      return filtererdDarta;
    }
  });

  const [farmerList, setFarmerList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(20); // default limit
  const [offset, setOffset] = useState(0); // default offset
  // const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const { loading, farmerDataByIdAPi, setLoading } = useFarmerHooks();

  // handleDelete Btn
  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    let formData = new FormData();
    formData.append("id", deleteId);
    formData.append("isDelete", "Yes");

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/deletefarmer`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        //getFarmerListAPi(limit, offset);
        dispatch(
          get_farmer_list({
            onSuccess: () => {
              setDeleteModal(false);
              toast?.success(_data?.message);
              setDeleteLoading(false);
            },
            onError: (e) => {
              setDeleteModal(false);
              toast?.error(e);
              setDeleteLoading(false);
            },
          })
        );
      } else {
        setDeleteLoading(false);
        setDeleteModal(false);

        toast.error(_data?.message);
      }
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
    }
  };

  const getIconColor = (status) => {
    if (status === 1) return "success";
    else if (status === 2) return "warning";
    else return "error";
  };
  const column = useMemo(
    () => [
      {
        Header: "Code",
        accessor: "farmerCode",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Mobile No",
        accessor: "mobileNo",
      },
      {
        Header: "Village",
        //accessor: "villageDetails.villageName",
        accessor: "villageName",
        Filter: SelectColumnFilter,
      },
      // {
      //   Header: "Taluka",
      //   accessor: "talukaName",
      //   Filter: SelectColumnFilter,
      // },
      // {
      //   Header: "District",
      //   accessor: "districtName",
      //   Filter: SelectColumnFilter,
      // },
      //******* */
      {
        Header: "Documents",
        filterable: false,
        columns: [
          {
            Header: "Submitted",
            accessor: "documentsubmitted",
            filterable: false,
            style: {
              "text-align": "center",
              "vertical-align": "middle",
            },
            Cell: (cellProps) =>
              cellProps.row.original.documentsubmitted ? (
                <Check fontSize="medium" color="success" />
              ) : (
                <Clear fontSize="medium" color="error" />
              ),
          },
          {
            Header: "Verified",
            accessor: "documentverified",
            filterable: false,
            style: {
              "text-align": "center",
              "vertical-align": "middle",
            },
            Cell: (cellProps) => {
              return cellProps.row.original.documentverified >= 0 ? (
                <Verified
                  fontSize="medium"
                  color={getIconColor(cellProps.row.original.documentverified)}
                />
              ) : (
                <Remove fontSize="medium" />
              );
            },
          },
        ],
      },
      {
        Header: "Kit Issued",
        accessor: "kitissued",
        filterable: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle",
        },
        Cell: (cellProps) =>
          cellProps.row.original.kitissued ? (
            <Check fontSize="medium" color="success" />
          ) : (
            <Remove fontSize="medium" />
          ),
      },
      {
        id: "Action",
        Header: "",
        style: {
          "text-align": "center",
          "vertical-align": "middle",
        },
        Cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-center align-items-center gap-2">
              {/* Edit Button */}
              {permissionsObj?.edit && (
                <IconButton
                  size="small"
                  color="success"
                  onClick={() => {
                    farmerDataByIdAPi(cellProps.row.original.id, "/editfarmer");
                  }}
                >
                  <Edit />
                </IconButton>
              )}
              {/* View Button  */}
              {permissionsObj?.print && (
                <IconButton
                  size="small"
                  color="info"
                  onClick={() =>
                    farmerDataByIdAPi(cellProps.row.original.id, "/viewfarmer")
                  }
                >
                  <Visibility />
                </IconButton>
              )}
              {/* Delete Button */}
              {permissionsObj?.delete && (
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => {
                    setDeleteModal(true);
                    setDeleteId(cellProps.row.original.id);
                  }}
                >
                  <Delete />
                </IconButton>
              )}
            </div>
          );
        },
      },
      //******* */
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );
  const getRowDetails = (row) => {
    if (row.original.verifiedfarmer === 1) {
      return "tr-lightgreen";
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      get_farmer_list({
        onSuccess: () => setLoading(false),
        onError: () => setLoading(false),
      })
    );
  }, []);

  const exportTally = () => {
    let obj = {
      farmer_list: farmer_list,
    };
    dispatch(
      farmerActions.import_tally_farmers(obj, {
        onSuccess: (res) => toast.success(res),
        onError: (e) => toast.error(e),
      })
    );
  };

  return (
    <PageLayout
      title={"Farmer registration"}
      loading={loading}
      addBtn={permissionsObj?.create ? true : false}
      backBtnTitle={"Add Farmer"}
      onClick={() => navigate("/addfarmer")}
    >
      {/* Search Button */}
      <div
        className={`d-flex justify-content-end align-items-center mx-4 my-2 ${
          isTablet ? "flex-column gap-2" : ""
        }`}
      >
        {/* <div className="d-flex justify-content-center align-items-center gap-2">
          <Button
            className="custom-btn-page-layout custom-btn-warning"
            variant="contained"
            startIcon={<Send />}
          >
            {`Export`}
          </Button>

          {permissionsObj?.create && (
            <Button
              className="custom-btn-page-layout"
              variant="contained"
              startIcon={<ControlPoint />}
              onClick={() => navigate("/addfarmer")}
            >
              {`Add Farmer`}
            </Button>
          )}
        </div> */}
      </div>

      {/* Table View */}

      {loading ? (
        <LoaderComponent />
      ) : (
        <div
          className="d-grid"
          style={{ margin: "0 20px 20px 20px", border: "1px solid #DEE2E6" }}
        >
          <TableContainer
            columns={column}
            data={farmer_list}
            isGlobalFilter={true}
            showColumnFilters={true}
            isAddUserList={false}
            tableClass={"table-sm"}
            divClass={"table-responsive"}
            showPagination={true}
            initialState={{ pageSize: 10, pageIndex: 0 }}
            rowClass={getRowDetails}
            exports={{
              excel: true,
              pdf: true,
              tally: true,
              onTallyClick: () => exportTally(),
            }}
          />
        </div>
      )}

      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={handleDeleteRequest}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
    </PageLayout>
  );
};

export default FarmerRegistration;
