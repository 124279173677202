import { IconButton, Typography } from "@mui/material";
import PageLayout from "../../Component/PageLayout";
import { QrCodeScanner, Scanner } from "@mui/icons-material";
import { Col, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";

const BagDashboard = () => {
  const navigate = useNavigate();
  return (
    <PageLayout>
      <Row className="m-2 g-2">
        <Col lg={4} md={4} sm={12} xs={12}>
          <IconButton
            className="custom-btn-page-layout custom-btn-warning d-flex flex-column w-100"
            size="large"
            onClick={() => navigate("/bagupdatescanner")}
            // disabled={error.noofbags.value || error.productweight.value}
          >
            <QrCodeScanner fontSize="large" />
            <Typography>{`Scan`}</Typography>
          </IconButton>
        </Col>
        {/* <Col lg={4} md={4} sm={4} xs={6}>
          <IconButton
            className="custom-btn-page-layout custom-btn-primary d-flex flex-column w-100"
            size="large"
            // onClick={onAddClick}
            // disabled={error.noofbags.value || error.productweight.value}
          >
            <QrCodeScanner fontSize="large" />
            <Typography>{`Update Bags`}</Typography>
          </IconButton>
        </Col> */}
      </Row>
    </PageLayout>
  );
};
export default BagDashboard;
