import { Col, Form, Row } from "reactstrap";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { keys } from "../../config/keys";
import PageLayout from "../../Component/PageLayout";
import { textFieldProps } from "../../common/commonControlProps";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import VirtualizeAutoComplete from "../../common/VirtualizedAutoComplete";
import { useNavigate } from "react-router-dom";
import { useFarmerPurchaseHooks } from "./useFarmerPurchaseHooks";
import { ErrorMessage } from "../../common/Error";
import TableContainer from "../../Component/TableContainer";
import {
  Add,
  Agriculture,
  Cancel,
  Face2,
  Print,
  Save,
} from "@mui/icons-material";
import dayjs from "dayjs";
import Gatepass from "./GatePass";

export const FarmerPurchase = () => {
  const navigate = useNavigate();

  const {
    error,
    formData,
    handleSubmit,
    onSubmit,
    farmerDropdownList,
    handleChange,
    handleCheckChange,
    onFarmerChange,
    onDateChange,
    errors,
    farmerYieldData,
    column,
    register,
    producttype,
    onAddClick,
    handleBlur,
    onCancelClick,
    onProductTypeChange,
    purchasedetailscolumn,
    previous_purchases_by_farmer,
    previous_purchase_columns,
    gatepassComponentRef,
    onSubmitandPrint,
    onAddFarmer,
    multifarmerdata,
    handleChangeUpperCase,
  } = useFarmerPurchaseHooks();
  return (
    <PageLayout
      title={"Add New Gatepass"}
      backBtnTitle={`BAck to List`}
      onClick={() => navigate("/farmerpurchases/gatepasslist")}
    >
      <Form className="m-4 g-2" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={12} md={4} sm={12}>
            <RadioGroup
              row
              name="bookingtype"
              value={formData.bookingtype}
              onChange={handleChange}
            >
              <FormControlLabel
                value="1"
                control={<Radio color="success" size="small" />}
                label="Show only today's booking"
              />
              <FormControlLabel
                value="0"
                control={<Radio color="warning" size="small" />}
                label="Show previous booking's"
              />
            </RadioGroup>
          </Col>
        </Row>
        <Divider />
        <Row className="mt-2 g-2">
          <Col lg={2} md={4} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Purchase Date"
                format={keys().shortDateFormat}
                slotProps={{
                  textField: {
                    ...textFieldProps,
                  },
                }}
                onChange={onDateChange}
                value={formData?.purchasedate}
                clearable
              />
            </LocalizationProvider>
          </Col>
          <Col lg={2} md={4} sm={12}>
            <TextField
              name="voucherno"
              value={formData?.voucherno}
              label="Voucher No"
              onChange={handleChange}
              {...textFieldProps}
            />
            <FormHelperText>{`Last Voucher # - ${formData?.lastvoucherno}`}</FormHelperText>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col lg={2} md={2} sm={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="multiplefarmer"
                  color="success"
                  size="small"
                  checked={formData.multiplefarmer}
                  onChange={handleCheckChange}
                />
              }
              label="Multiple Farmer"
              labelPlacement="end"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <VirtualizeAutoComplete
              name="selectedFarmer"
              size="small"
              options={farmerDropdownList}
              getOptionDisabled={(option) => option.disabled === 1}
              clearText={false}
              value={formData?.selectedFarmer}
              onChange={onFarmerChange}
              renderInput={(params) => (
                <TextField
                  {...register("selectedFarmer")}
                  {...params}
                  label="Farmer Code"
                  {...textFieldProps}
                  error={errors?.selectedFarmer}
                />
              )}
            />
            {errors?.selectedFarmer && (
              <ErrorMessage message={errors?.selectedFarmer?.message} />
            )}
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              label={`No Of Bags Issued`}
              {...textFieldProps}
              InputLabelProps={{ shrink: true }}
              disabled
              value={formData.bagdetails.noofbagsissued}
            />
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              label={`Estimated Bags to be bought`}
              {...textFieldProps}
              InputLabelProps={{ shrink: true }}
              disabled
              value={formData?.selectedFarmer?.noofbags}
            />
          </Col>
          {/* {formData.farmerid > 0 && (
            <Col lg={12} sm={12}>
              {`${keys().shortDate(
                formData.selectedFarmer.appointmentdate
              )} ${keys().displayTime(
                formData.selectedFarmer.appointmenttime
              )}`}
            </Col>
          )} */}
          {formData.farmerid > 0 && (
            <Col lg={12} sm={12}>
              <TableContainer
                columns={column}
                data={farmerYieldData}
                isGlobalFilter={false}
                tableClass={"table-sm"}
                showPagination={false}
                showFooter
                initialState={{ pageSize: 20, pageIndex: 0 }}
                divClass={"table-responsive"}
                exports={{ excel: false, pdf: false }}
              />
            </Col>
          )}
        </Row>
        <Row className="mt-3">
          <Col lg={2} md={2} sm={12}>
            <TextField
              label={`Vehicle No`}
              {...register("vehicleno")}
              name="vehicleno"
              {...textFieldProps}
              value={formData.vehicleno}
              onChange={handleChangeUpperCase}
              error={errors?.vehicleno}
              inputProps={{ style: { textTransform: "uppercase" } }}
            />
            {errors?.vehicleno && (
              <ErrorMessage message={errors?.vehicleno?.message} />
            )}
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              label={`Gross Weight`}
              {...register("vehicleweight")}
              variant="standard"
              size="small"
              fullWidth
              // disabled
              name="vehicleweight"
              value={formData.vehicleweight}
              onChange={handleChange}
              error={errors?.vehicleweight}
              type="number"
              className="fw-bolder fs-5 text-danger test"
            />
            {errors?.vehicleweight && (
              <ErrorMessage message={errors?.vehicleweight?.message} />
            )}
          </Col>
          <Col lg={3} md={3} sm={12}>
            <Paper className="weighbridge">{formData.vehicleweight}</Paper>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col lg={2} md={2} sm={12}>
            <VirtualizeAutoComplete
              name="selectedproducttype"
              size="small"
              options={producttype}
              getOptionDisabled={(option) =>
                formData.purchasedetails.filter(
                  (x) => x.producttypeid === option.value
                ).length > 0
              }
              clearText={false}
              value={formData?.selectedproducttype}
              onChange={onProductTypeChange}
              renderInput={(params) => (
                <TextField
                  {...register("selectedproducttype")}
                  {...params}
                  label="Type"
                  {...textFieldProps}
                />
              )}
            />
          </Col>
          {/* <Col lg={2} md={2} sm={12}>
            <TextField
              label={`Product Weight`}
              {...textFieldProps}
              value={formData.weight}
              name="weight"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              error={error.productweight.value}
            />
          </Col> */}
          <Col lg={1} md={1} sm={12}>
            <TextField
              label={`No Of Bags`}
              {...textFieldProps}
              name="noofbags"
              value={formData.noofbags}
              type="number"
              onChange={handleChange}
              error={error.noofbags.value}
            />
          </Col>
          {/* <Col lg={1} md={1} sm={12}>
            <TextField
              label={`Kasor`}
              {...textFieldProps}
              value={formData.kasor}
              name="kasor"
              type="number"
              onChange={handleChange}
            />
          </Col>
          <Col lg={1} md={1} sm={12}>
            <TextField
              label={`Incentive`}
              {...textFieldProps}
              value={formData.incentive}
              name="incentive"
              type="number"
              onChange={handleChange}
            />
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              label={`Net Weight`}
              name="netweight"
              {...textFieldProps}
              value={formData.netweight}
              type="number"
              onChange={handleChange}
            />
          </Col> */}
          <Col lg={1} md={1} sm={12} className="d-flex align-self-end">
            <IconButton
              className="custom-btn-page-layout custom-btn-primary"
              size="small"
              onClick={onAddClick}
              disabled={error.noofbags.value || error.productweight.value}
            >
              <Add fontSize="inherit" />
            </IconButton>
          </Col>
          {(error.noofbags.value || error.productweight.value) && (
            <Col lg={12} md={12} sm={12}>
              <ul class="list-group list-group-flush">
                {error.noofbags.value && (
                  <li className="list-group-item p-0">
                    <ErrorMessage message={error.noofbags.message} />
                  </li>
                )}
                {error.productweight.value && (
                  <li className="list-group-item p-0">
                    <ErrorMessage message={error.productweight.message} />
                  </li>
                )}
              </ul>
            </Col>
          )}

          {formData.purchasedetails.length > 0 && (
            <Col lg={12} md={12} sm={12}>
              <TableContainer
                columns={purchasedetailscolumn}
                data={formData.purchasedetails}
                isGlobalFilter={false}
                tableClass={"table-sm"}
                showPagination={false}
                showFooter={false}
                initialState={{ pageSize: 20, pageIndex: 0 }}
                divClass={"table-responsive"}
                exports={{ excel: false, pdf: false }}
              />
            </Col>
          )}
        </Row>
        {formData.multiplefarmer && (
          <Row className="mt-2">
            <Col lg={2} md={2} sm={12}>
              <Button
                className="custom-btn-page-layout text-white "
                startIcon={<Agriculture />}
                fullWidth
                type="button"
                onClick={onAddFarmer}
              >
                {` Add Another Farmer`}
              </Button>
            </Col>
            {multifarmerdata.length > 0 && (
              <table className="table table-sm table-bordered">
                <tbody>
                  {multifarmerdata.map((x) => (
                    <tr>
                      <td>{x?.voucherno}</td>
                      <td>{x?.selectedFarmer?.farmer}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Row>
        )}
        <Row className="mt-2 g-2">
          <Col lg={1} md={1} sm={12}>
            <Button
              className="custom-btn-page-layout text-white "
              startIcon={<Save />}
              fullWidth
              type="submit"
            >
              {` Save`}
            </Button>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <Button
              className="custom-btn-page-layout custom-btn-warning text-white "
              startIcon={<Print />}
              fullWidth
              type="button"
              onClick={onSubmitandPrint}
            >
              {` Save & Print`}
            </Button>
          </Col>
          <Col lg={1} md={1} sm={12}>
            <Button
              type="button"
              className="custom-btn-page-layout custom-btn-danger "
              startIcon={<Cancel />}
              onClick={onCancelClick}
            >
              Cancel
            </Button>
          </Col>
        </Row>
        {formData.farmerid > 0 && (
          <Row className="mt-2 g-2">
            <Col lg={12} md={12} sm={12}>
              <Typography variant="button">{`PREVIOUS PURCHASES`}</Typography>
              <hr />
              <TableContainer
                columns={previous_purchase_columns}
                data={previous_purchases_by_farmer}
                isGlobalFilter={false}
                tableClass={"table-sm"}
                showPagination={false}
                showFooter={false}
                initialState={{ pageSize: 20, pageIndex: 0 }}
                divClass={"table-responsive"}
                exports={{ excel: false, pdf: false }}
              />
            </Col>
          </Row>
        )}
      </Form>
      <div className="d-none">
        <Gatepass
          ref={gatepassComponentRef}
          data={formData}
          multifarmer={formData.multiplefarmer}
          multifarmerdata={multifarmerdata}
        />
      </div>
    </PageLayout>
  );
};

export default FarmerPurchase;
