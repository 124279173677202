import React, { useMemo, useState, useCallback, useEffect } from "react";
import PageLayout from "../../Component/PageLayout";
import { Col, Input, InputGroup, InputGroupText, Row } from "reactstrap";
import TableContainer from "../../Component/TableContainer";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Component/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import DownloadFarmerAgreement from "./DownloadFarmerAgreement";
import EmailFarmerAgreement from "./EmailFarmerAgreement";
import { get_farmer_list } from "../../redux/actions/farmer";
import {
  Article,
  AttachEmail,
  Check,
  CheckBox,
  Delete,
  Remove,
  Verified,
} from "@mui/icons-material";
import { Checkbox, IconButton } from "@mui/material";
import { useFarmerHooks } from "./useFarmerHooks";
import { get_templates_list_by_type } from "../../redux/actions/templates";
import TemplateComponent from "react-mustache-template-component";

const FinalVerification = () => {
  const {
    layout: { permissions, token },
    farmer_list,
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let permissionsObj = {};
  // eslint-disable-next-line
  permissions?.filter((er) => {
    if (er?.ModuleData?.length > 0) {
      // eslint-disable-next-line
      let filtererdDarta = er?.ModuleData?.filter((ed) => {
        if (ed?.subModuleData?.length > 0) {
          // eslint-disable-next-line
          ed?.subModuleData?.map((qw) => {
            if (qw?.path === "/finalverification") {
              permissionsObj = qw;
            }
          });
        }
      });
      return filtererdDarta;
    }
  });

  let initialState = {};
  if (
    !permissionsObj?.edit &&
    !permissionsObj?.delete &&
    !permissionsObj?.print
  ) {
    initialState.hiddenColumns = ["Action"];
  }
  const [verificationList, setVerificationList] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(5); // default limit
  const [offset, setOffset] = useState(0); // default offset
  const [deleteLoading, setDeleteLoading] = useState(false);
  //const [singleData, setSingleData] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  //const [counter, setCounter] = useState(0);
  const [searchInput, setSearchInput] = useState("");

  const [template, setTemplate] = useState("");

  const {
    loading,
    setLoading,
    viewAgreement,
    singleData,
    counter,
    emailCounter,
  } = useFarmerHooks();

  useEffect(() => {
    setLoading(true);
    dispatch(
      get_farmer_list({
        onSuccess: () => setLoading(false),
        onError: () => setLoading(false),
      })
    );

    dispatch(
      get_templates_list_by_type("agreement", {
        onSuccess: (res) =>
          setTemplate(res.length > 0 ? res[0].templatecontent : ""),
      })
    );
  }, []);

  const handleVerifyBtn = async (data) => {
    setLoading(true);
    let formData = new FormData();

    formData.append("farmerId", data?.id);
    formData.append("finalStatus", "True");

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/addfarmerverification`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        dispatch(get_farmer_list());
        //getVerificationListAPi(limit, offset);
      } else {
        setLoading(false);
        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getIconColor = (status) => {
    if (status === 1) return "success";
    else if (status === 2) return "warning";
    else return "error";
  };

  const column = useMemo(
    () => [
      {
        Header: "Code",
        accessor: "farmerCode",
        filterable: false,
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
      },
      {
        Header: "Village",
        accessor: "villageName",
        filterable: false,
      },
      {
        Header: "Doc Verified?",
        accessor: "documentverified",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle",
        },
        Cell: (cellProps) => {
          return cellProps.row.original.documentverified >= 0 ? (
            <Verified
              fontSize="medium"
              color={getIconColor(cellProps.row.original.documentverified)}
            />
          ) : (
            <Remove fontSize="medium" />
          );
        },
      },
      {
        Header: "Kit Issued?",
        accessor: "kitissued",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle",
        },
        Cell: (cellProps) =>
          cellProps.row.original.kitissued ? (
            <Verified fontSize="medium" color="success" />
          ) : (
            <Remove fontSize="medium" />
          ),
      },
      {
        Header: "View Agreement",
        style: {
          "text-align": "center",
          "vertical-align": "middle",
        },
        Cell: (cellProps) => {
          return (
            cellProps?.row?.original?.verifiedfarmer === 1 && (
              <IconButton
                size="medium"
                color="success"
                onClick={() => {
                  viewAgreement(
                    cellProps?.row?.original?.id,
                    false,
                    cellProps?.row?.original?.documentpath
                  );
                  setLoading(true);
                  // let t= {
                  //   cellProps?.row?.original?.id,
                  //   false,
                  //   cellProps?.row?.original?.documentpath
                  // }
                  // localStorage.setItem("agreement",)
                  // window.open(
                  //   `/agreement/${cellProps?.row?.original?.id}`,
                  //   "_blank"
                  // );
                }}
              >
                <Article />
              </IconButton>
            )
          );
        },
      },

      {
        id: "Email",
        Header: "Email",
        style: {
          "text-align": "center",
          "vertical-align": "middle",
        },
        Cell: (cellProps) => {
          return (
            cellProps?.row?.original?.verifiedfarmer === 1 &&
            cellProps?.row?.original?.email && (
              <IconButton
                size="medium"
                color="warning"
                onClick={() => {
                  viewAgreement(
                    cellProps?.row?.original?.id,
                    true,
                    cellProps?.row?.original?.documentpath
                  );
                  setLoading(true);
                }}
              >
                <AttachEmail />
              </IconButton>
            )
          );
        },
      },
      {
        Header: "Verify",
        style: {
          "text-align": "center",
          "vertical-align": "middle",
        },
        Cell: (cellProps) => {
          return (
            <>
              {cellProps?.row?.original?.verifiedfarmer === 1 && (
                <CheckBox fontSize="medium" color="success" />
              )}
              {cellProps?.row?.original?.kitissued === 1 &&
                cellProps?.row?.original?.verifiedfarmer === 0 && (
                  <Checkbox
                    checked={
                      cellProps?.row?.original?.verifiedfarmer === 1
                        ? true
                        : false
                    }
                    name="userName"
                    color="success"
                    onChange={(e) => handleVerifyBtn(cellProps?.row?.original)}
                  />
                )}
            </>
          );
        },
      },
      {
        id: "Action",
        style: {
          "text-align": "center",
          "vertical-align": "middle",
        },
        Header: "Action",
        Cell: (cellProps) => {
          return (
            cellProps?.row?.original?.verifiedfarmer === 1 && (
              <IconButton
                size="medium"
                color="error"
                onClick={() => {
                  setDeleteModal(true);
                  setDeleteId(cellProps.row.original.id);
                }}
              >
                <Delete />
              </IconButton>
            )
          );
        },
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    let formData = new FormData();
    formData.append("farmerId", deleteId);
    formData.append("isDelete", "Yes");

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/deletefarmerverification`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        // getVerificationListAPi(limit, offset);
        dispatch(get_farmer_list());
        setDeleteModal(false);
        toast?.success(_data?.message);
        setDeleteLoading(false);
      } else {
        setDeleteLoading(false);
        setDeleteModal(false);

        toast.error(_data?.message);
      }
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
    }
  };

  return (
    <PageLayout title={"Final Verification"} loading={loading}>
      <div className="m-4">
        <Row>
          <Col lg={12} sm={12}>
            {!loading && (
              <div className="d-grid" style={{ border: "1px solid #DEE2E6" }}>
                <TableContainer
                  columns={column}
                  data={farmer_list}
                  isGlobalFilter={true}
                  isAddUserList={false}
                  showColumnFilters
                  showPagination
                  tableClass={"table-sm"}
                  divClass={"table-responsive"}
                />
              </div>
            )}
          </Col>
          {/* {
            <Col lg={12} sm={12}>
              <TemplateComponent
                template={template}
                data={{ ...singleData }}
              />
            </Col>
          } */}
        </Row>
      </div>

      {counter === 1 ? (
        <>
          <DownloadFarmerAgreement
            selectedPdfRow={singleData}
            setMainLoader={setLoading}
          />
        </>
      ) : null}
      {emailCounter === 1 ? (
        <>
          <EmailFarmerAgreement
            selectedPdfRow={singleData}
            setMainLoader={setLoading}
          />
        </>
      ) : null}

      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={handleDeleteRequest}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
    </PageLayout>
  );
};

export default FinalVerification;
