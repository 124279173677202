import React, { useState, useEffect, useMemo } from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import PageLayout from "../../Component/PageLayout";
import TableContainer from "../../Component/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import LoaderComponent from "../../Component/LoaderComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { GridAction } from "../../common/useGridAction";
import { useSurveyHooks } from "./useSurveyHooks";
import { Box, Modal } from "@mui/material";
import DesignSurvey from "./DesignSurvey";
import { usePermissionHooks } from "../../common/useCheckPermission";
import DeleteModal from "../../Component/DeleteModal";
import { surveyActions } from "../../redux/actions";
import { toast } from "react-toastify";

const SurveyList = () => {
  const { rowData } = useSurveyHooks();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [viewData, setViewData] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const permission = usePermissionHooks(location);

  const column = useMemo(
    () => [
      {
        id: "sr",
        Header: "Sr. No.",
        Cell: (cellProps) => {
          return <span>{cellProps?.row?.index + 1}</span>;
        },
      },
      {
        Header: "Survey Title",
        accessor: "title",
      },
      {
        Header: "Start Date",
        accessor: "startDate",
      },
      {
        Header: "End Date",
        accessor: "endDate",
      },
      {
        Header: "WEF Date",
        accessor: "WefDate",
      },
      {
        ...GridAction({
          permission,
          onEditClick: (cellProps) => {
            navigate(`/survey`, {
              state: {
                id: cellProps.row.original.id,
                data: cellProps.row.original,
              },
            });
          },
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original.id);
          },
          onViewClick: (cellProps) => {
            setViewData(JSON.parse(cellProps.row.original.surveyjsondata));
            setOpenModal(true);
          },
        }),
      },
    ],
    // eslint-disable-next-line
    []
  );

  const onGridDelete = async () => {
    setDeleteLoading(true);
    if (deleteId > 0) {
      dispatch(
        surveyActions.deleteSurvey(deleteId, {
          onSuccess: () => {
            toast.success("Survey Deleted Successfully!!!");
            dispatch(surveyActions.getSurveyMaster());
          },
          onError: (err) =>
            toast.error("Something went wrong. Please try again."),
        })
      );
      setDeleteLoading(false);
      setDeleteModal(false);
    }
  };

  return (
    <PageLayout
      title={"Survey List"}
      loading={loading}
      addBtn={permission?.create ? true : false}
      backBtnTitle={`Add New Survey`}
      onClick={() => navigate("/survey", { state: { id: 0 } })}
    >
      {loading ? (
        <LoaderComponent />
      ) : (
        <div
          className="d-grid"
          style={{ margin: "10px 20px", border: "1px solid #DEE2E6" }}
        >
          <TableContainer
            columns={column}
            data={rowData}
            isGlobalFilter={true}
            showColumnFilters={true}
            isAddUserList={false}
            tableClass={"table-sm"}
            divClass={"table-responsive"}
            showPagination={true}
            initialState={{ pageSize: 10, pageIndex: 0 }}
          />
        </div>
      )}
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ padding: 4 }}>
          <DesignSurvey
            jsonObj={viewData}
            options={{ showDesignerTab: false }}
            onClose={() => setOpenModal(false)}
          />
        </Box>
      </Modal>

      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={onGridDelete}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
    </PageLayout>
  );
};

export default SurveyList;
