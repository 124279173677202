// import { Actions } from ".";
import * as types from "../constTypes";

const product_list = [];
const bag_list = [];
const group_list = [];

export const products = (state = product_list, action) => {
  switch (action.type) {
    case types.PRODUCT_LIST:
      return [...action.payload] || [];
    default:
      return state;
  }
};

export const product_group = (state = group_list, action) => {
  switch (action.type) {
    case types.PRODUCT_GROUP_CATEGORY_LIST:
      return [...action.payload] || [];
    default:
      return state;
  }
};

export const bags_list = (state = bag_list, action) => {
  switch (action.type) {
    case types.BAG_MASTER_LIST:
      return action.payload || [];
    default:
      return state;
  }
};
