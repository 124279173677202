import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageLayout from "../../../Component/PageLayout";
import { TextField } from "@mui/material";
import { Col, Input, Label, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import Select from "react-select";
import { useIssueMaterialHooks } from "./useIssueMaterialHooks";
import useBreakPoints from "../../../hooks/useBreakPoints";
import { logOutRequest } from "../../../store/reducers/layoutReducer";

const AddIssueMaterial = () => {
  const { isTablet, isMobile } = useBreakPoints();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { farmerDropdownList } = useIssueMaterialHooks();
  const { token, loginDetails } = useSelector((state) => state.layout);

  const [loading, setLoading] = useState(false);
  const [productOptions, setProductOptions] = useState([]);

  const [inputData, setInputData] = useState({
    issueDate: "",
    voucherNo: "",
    farmer: "",
    farmerAddressLine1: "",
    farmerAddressLine2: "",
    farmerState: "",
    farmerDistrict: "",
    farmerTaluka: "",
    farmerVillage: "",
    farmerPincode: "",
    farmerSurveyNos: "",
    remark: "",
  });
  const [productData, setProductData] = useState([
    {
      product: "",
      partNo: "",
      qty: "",
      rate: "",
      basicAmount: "",
      gstStatus: "",
      cgst: "",
      igst: "",
      sgst: "",
      taxablecgstAmount: "",
      taxablesgstAmount: "",
      taxableigstAmount: "",
      taxableAmount: "",
    },
  ]);

  const [inputDataError, setInputDataError] = useState({
    issueDate: false,
    voucherNo: false,
    farmer: false,
    farmerAddressLine1: false,
    farmerAddressLine2: false,
    farmerState: false,
    farmerDistrict: false,
    farmerTaluka: false,
    farmerVillage: false,
    farmerPincode: false,
    farmerSurveyNos: false,
    remark: false,
  });
  const [productDataError, setProductDataError] = useState([
    {
      product: false,
      partNo: false,
      qty: false,
      rate: false,
      basicAmount: false,
    },
  ]);

  const handleChangeInput = (name, value) => {
    if (name === "farmer") {
      setInputData({
        ...inputData,
        [name]: value,
        farmerAddressLine1: value?.addressLine1,
        farmerAddressLine2: value?.addressLine3
          ? `${value?.addressLine2}, ${value?.addressLine3}`
          : value?.addressLine2,
        farmerState: value?.stateName,
        farmerDistrict: value?.districtName,
        farmerTaluka: value?.talukaName,
        farmerVillage: value?.villageName,
        farmerPincode: value?.pincode,
        farmerSurveyNos: value?.surveyNos,
      });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, farmer: false });
      } else {
        setInputDataError({ ...inputDataError, farmer: true });
      }
    } else if (name === "remark") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, remark: false });
      } else {
        setInputDataError({ ...inputDataError, remark: true });
      }
    } else if (name === "voucherNo") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, voucherNo: false });
      } else {
        setInputDataError({ ...inputDataError, voucherNo: true });
      }
    } else {
      setInputData({ ...inputData, [name]: value });
    }
  };

  const handleChangeProduct = (index, name, value) => {
    let arr = [...productData];
    let arr2 = [...productDataError];
    if (name === "product") {
      arr[index][name] = value;
      arr[index]["partNo"] = value?.value?.productCode;
      arr[index]["rate"] = "";
      arr[index]["gstStatus"] = value?.value?.gstStatus;
      arr[index]["qty"] = "";
      arr[index]["basicAmount"] = "";
      if (arr[index]["gstStatus"] === "Yes") {
        arr[index]["cgst"] = value?.value?.cgst || 0;
        arr[index]["sgst"] = value?.value?.sgst || 0;
        arr[index]["igst"] = value?.value?.igst || 0;
      } else {
        arr[index]["cgst"] = 0;
        arr[index]["igst"] = 0;
        arr[index]["sgst"] = 0;
      }
      if (value?.label?.length > 0) {
        arr2[index]["product"] = false;
      } else {
        arr2[index]["product"] = true;
      }
    } else if (name === "rate") {
      arr[index][name] = value;
      arr[index]["basicAmount"] = +value * arr[index]["qty"];
      if (inputData?.vendorState === "GUJARAT") {
        arr[index]["igst"] = 0;
        arr[index]["taxableigstAmount"] = 0;
        arr[index]["taxablecgstAmount"] =
          (arr[index]["basicAmount"] * arr[index]["cgst"]) / 100;
        arr[index]["taxablesgstAmount"] =
          (arr[index]["basicAmount"] * arr[index]["sgst"]) / 100;

        arr[index]["taxableAmount"] =
          arr[index]["basicAmount"] +
          arr[index]["taxablecgstAmount"] +
          arr[index]["taxablesgstAmount"];
      } else {
        arr[index]["cgst"] = 0;
        arr[index]["sgst"] = 0;
        arr[index]["taxablecgstAmount"] = 0;
        arr[index]["taxablesgstAmount"] = 0;
        arr[index]["taxableigstAmount"] =
          (arr[index]["basicAmount"] * arr[index]["igst"]) / 100;
        arr[index]["taxableAmount"] =
          arr[index]["basicAmount"] + arr[index]["taxableigstAmount"];
      }
      if (value?.length > 0) {
        arr2[index]["rate"] = false;
      } else {
        arr2[index]["rate"] = true;
      }
    } else if (name === "qty") {
      arr[index][name] = value;
      arr[index]["basicAmount"] = +value * arr[index]["rate"];

      if (inputData?.vendorState === "GUJARAT") {
        arr[index]["igst"] = 0;
        arr[index]["taxableigstAmount"] = 0;
        arr[index]["taxablecgstAmount"] =
          (arr[index]["basicAmount"] * arr[index]["cgst"]) / 100;
        arr[index]["taxablesgstAmount"] =
          (arr[index]["basicAmount"] * arr[index]["sgst"]) / 100;

        arr[index]["taxableAmount"] =
          arr[index]["basicAmount"] +
          arr[index]["taxablecgstAmount"] +
          arr[index]["taxablesgstAmount"];
      } else {
        arr[index]["cgst"] = 0;
        arr[index]["sgst"] = 0;
        arr[index]["taxablecgstAmount"] = 0;
        arr[index]["taxablesgstAmount"] = 0;
        arr[index]["taxableigstAmount"] =
          (arr[index]["basicAmount"] * arr[index]["igst"]) / 100;
        arr[index]["taxableAmount"] =
          arr[index]["basicAmount"] + arr[index]["taxableigstAmount"];
      }

      if (value?.length > 0) {
        arr2[index]["qty"] = false;
      } else {
        arr2[index]["qty"] = true;
      }
    } else {
      setProductData({ ...productData, [name]: value });
    }

    setProductData(arr);
    setProductDataError(arr2);
  };

  const getAllProductListAPi = useCallback(async () => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/productdrop`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];
        // eslint-disable-next-line
        _data?.productList?.map((er) => {
          arr.push({ label: er?.productName, value: er });
        });
        setProductOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAllProductListAPi();
  }, [getAllProductListAPi]);

  return (
    <PageLayout title={"Add issue material"}>
      <div className="" style={{ margin: "10px 40px 40px 40px" }}>
        <form
        //    onSubmit={(e) => handleSubmit(e)}
        >
          <div>
            <Row className=" g-3 mt-2 ">
              <Col lg={9} md={12} sm={12} className="">
                <Col lg={12} md={12} sm={12}>
                  {/* <TextField
                    size="small"
                    fullWidth
                    label="Name of the Farmer"
                  /> */}
                  <Select
                    isMulti={false}
                    onChange={(e) => {
                      handleChangeInput("farmer", e);
                    }}
                    value={inputData?.farmer}
                    options={farmerDropdownList}
                    menuPortalTarget={document.body}
                    name="choices-single-default"
                    placeholder="Select Farmer"
                    id="farmer"
                    className={`w-100 z-3 ${
                      inputDataError?.farmer ? "border border-danger " : ""
                    }`}
                  ></Select>
                </Col>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className={`mt-4 d-flex ${
                    isTablet ? "flex-column gap-2" : ""
                  }`}
                >
                  <Col
                    lg={4}
                    md={12}
                    sm={12}
                    style={{ paddingRight: isTablet ? 0 : 5 }}
                  >
                    <TextField
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label="Address Line1"
                      value={inputData.farmerAddressLine1}
                      disabled
                    />
                  </Col>
                  <Col
                    lg={4}
                    md={12}
                    sm={12}
                    style={{
                      paddingLeft: isTablet ? 0 : 5,
                      paddingRight: isTablet ? 0 : 5,
                    }}
                  >
                    <TextField
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label="Address Line2"
                      value={inputData.farmerAddressLine2}
                      disabled
                    />
                  </Col>
                  <Col
                    lg={4}
                    md={12}
                    sm={12}
                    style={{ paddingLeft: isTablet ? 0 : 5 }}
                  >
                    <TextField
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label="State"
                      value={inputData.farmerState}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className={`mt-4 d-flex ${
                    isTablet ? "flex-column gap-2" : ""
                  }`}
                >
                  <Col
                    lg={3}
                    md={12}
                    sm={12}
                    style={{ paddingRight: isTablet ? 0 : 5 }}
                  >
                    <TextField
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label="District"
                      value={inputData.farmerDistrict}
                      disabled
                    />
                  </Col>
                  <Col
                    lg={3}
                    md={12}
                    sm={12}
                    style={{
                      paddingLeft: isTablet ? 0 : 5,
                      paddingRight: isTablet ? 0 : 5,
                    }}
                  >
                    <TextField
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label="Taluka"
                      value={inputData.farmerTaluka}
                      disabled
                    />
                  </Col>
                  <Col
                    lg={3}
                    md={12}
                    sm={12}
                    style={{ paddingLeft: isTablet ? 0 : 5 }}
                  >
                    <TextField
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label="Village"
                      value={inputData.farmerVillage}
                      disabled
                    />
                  </Col>
                  <Col
                    lg={3}
                    md={12}
                    sm={12}
                    style={{ paddingLeft: isTablet ? 0 : 5 }}
                  >
                    <TextField
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label="Pincode"
                      value={inputData.farmerPincode}
                      disabled
                    />
                  </Col>
                </Col>
              </Col>
              <Col
                lg={3}
                md={12}
                sm={12}
                style={{
                  background: "#F4F4F4",
                  borderRadius: 5,
                  marginTop: isTablet ? 10 : -30,
                }}
                className=""
              >
                <Col lg={12} md={12} sm={12} className="mt-3">
                  {/* <TextField size="small" fullWidth label="Date " /> */}
                  <Flatpickr
                    className={`form-control ${
                      inputDataError?.issueDate ? "border border-danger " : ""
                    }`}
                    placeholder="Date"
                    options={{
                      dateFormat: "Y-m-d",
                      minDate: new Date().setMonth(new Date().getMonth() - 1),
                      maxDate: new Date(),
                    }}
                    value={inputData?.issueDate}
                    onChange={(date, dateStr) => {
                      setInputData({ ...inputData, issueDate: dateStr });
                      if (dateStr?.length > 0) {
                        setInputDataError({
                          ...inputDataError,
                          issueDate: false,
                        });
                      } else {
                        setInputDataError({
                          ...inputDataError,
                          issueDate: true,
                        });
                      }
                    }}
                  />
                </Col>
                <Col lg={12} md={12} sm={12} className="mt-3">
                  <TextField
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    id="voucherNo"
                    label="Voucher Number"
                    name="voucherNo"
                    value={inputData?.voucherNo}
                    error={inputDataError?.voucherNo}
                    onChange={(e) => {
                      handleChangeInput(e.target.name, e.target.value);
                    }}
                  />
                </Col>
                <Col lg={12} md={12} sm={12} className="mt-3">
                  <TextField
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Land Detail (SurveyNos)"
                    value={inputData.farmerSurveyNos}
                    disabled
                  />
                </Col>
                <Col lg={12} md={12} sm={12} className="mt-3 mb-2">
                  <label>With Barcode</label>
                </Col>
              </Col>
            </Row>
          </div>

          <div className="table-responsive">
            <table
              style={{ minWidth: 778 }}
              className="mt-4 table table-bordered "
            >
              <tr>
                <th
                  style={{
                    textAlign: "left",
                    paddingLeft: 18,
                    height: 40,
                    backgroundColor: "#B3E9B9",
                    border: "1px solid transparent  ",
                  }}
                >
                  Barcode
                </th>
                <th
                  style={{
                    textAlign: "left",
                    height: 40,
                    backgroundColor: "#B3E9B9",
                    border: "1px solid transparent  ",
                  }}
                >
                  Product Name
                </th>
                <th
                  style={{
                    textAlign: "left",
                    height: 40,
                    backgroundColor: "#B3E9B9",
                    border: "1px solid transparent  ",
                  }}
                >
                  Product Code
                </th>
                {/* <th
                  style={{
                    textAlign: "left",
                    height: 40,
                    backgroundColor: "#B3E9B9",
                    border: "1px solid transparent  ",
                  }}
                >
                  Available Qty
                </th> */}
                <th
                  style={{
                    textAlign: "left",
                    height: 40,
                    backgroundColor: "#B3E9B9",
                    border: "1px solid transparent  ",
                  }}
                >
                  Issued Qty
                </th>
                <th
                  style={{
                    textAlign: "left",
                    height: 40,
                    backgroundColor: "#B3E9B9",
                    border: "1px solid transparent  ",
                  }}
                >
                  Rate
                </th>
                <th
                  style={{
                    textAlign: "left",
                    height: 40,
                    backgroundColor: "#B3E9B9",
                    border: "1px solid transparent  ",
                  }}
                >
                  Basic Amount
                </th>
                <th
                  style={{
                    textAlign: "left",
                    height: 40,
                    backgroundColor: "#B3E9B9",
                    border: "1px solid transparent  ",
                  }}
                ></th>
              </tr>

              {productData?.map((item, i) => (
                <tr>
                  <td
                    style={{
                      paddingLeft: 15,
                      paddingRight: 15,
                      width: "12%",
                    }}
                  >
                    <Input
                      variant="filled"
                      size="small"
                      className=" border mt-2 mb-2"
                      placeholder="Barcode"
                    />
                  </td>
                  <td
                    style={{
                      paddingRight: 15,
                    }}
                  >
                    {/* <Input
                      variant="filled"
                      size="small"
                      className=" border mt-2 mb-2"
                      placeholder="Product Name"
                    /> */}
                    <Select
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      isMulti={false}
                      onChange={(e) => {
                        handleChangeProduct(i, "product", e);
                      }}
                      value={item?.product}
                      options={productOptions}
                      name="choices-single-default"
                      id="product"
                      className={`w-100 z-3 ${
                        productDataError[i]?.product
                          ? "border border-danger"
                          : ""
                      }`}
                    ></Select>
                  </td>
                  <td
                    style={{
                      paddingRight: 15,
                      width: "12%",
                    }}
                  >
                    <Input
                      variant="filled"
                      size="small"
                      className=" border mt-2 mb-2"
                      placeholder="Product Code"
                      InputLabelProps={{ shrink: true }}
                      value={item?.partNo}
                      disabled
                      fullWidth
                      label="Part Code"
                    />
                  </td>
                  {/* <td
                    style={{
                      paddingRight: 15,
                      width: "12%",
                    }}
                  >
                    <Input
                      variant="filled"
                      size="small"
                      className=" border mt-2 mb-2"
                      placeholder="Available Qty"
                      InputLabelProps={{ shrink: true }}
                      value={item?.availableQty}
                      disabled
                      fullWidth
                      label="Available Qty"
                      type="number"
                    />
                  </td> */}
                  <td
                    style={{
                      paddingRight: 15,
                      width: "12%",
                    }}
                  >
                    <Input
                      variant="filled"
                      size="small"
                      placeholder="Issued Qty"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label="Issued Qty"
                      value={item?.qty}
                      className=" border mt-2 mb-2"
                      // className={`cust-input ${
                      //   productDataError[i]?.qty ? "border border-danger" : ""
                      // }`}
                      onChange={(e) => {
                        handleChangeProduct(i, "qty", e.target.value);
                      }}
                      type="number"
                    />
                  </td>
                  <td
                    style={{
                      paddingRight: 15,
                      width: "12%",
                    }}
                  >
                    <Input
                      variant="filled"
                      size="small"
                      className=" border mt-2 mb-2"
                      placeholder="Rate"
                      value={item?.rate}
                      onChange={(e) => {
                        handleChangeProduct(i, "rate", e.target.value);
                      }}
                      disabled
                    />
                  </td>
                  <td
                    style={{
                      paddingRight: 15,
                      width: "12%",
                    }}
                  >
                    <Input
                      variant="filled"
                      size="small"
                      className=" border mt-2 mb-2"
                      placeholder="Basic Amount"
                      value={item?.basicAmount}
                      disabled
                    />
                  </td>
                  <td>
                    <button
                      type="button"
                      className="custom-btn-page-layout btn text-white"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M7.99967 2.66659C10.9403 2.66659 13.333 5.05925 13.333 7.99992C13.333 10.9406 10.9403 13.3333 7.99967 13.3333C5.05901 13.3333 2.66634 10.9406 2.66634 7.99992C2.66634 5.05925 5.05901 2.66659 7.99967 2.66659ZM7.99967 1.33325C4.31767 1.33325 1.33301 4.31792 1.33301 7.99992C1.33301 11.6819 4.31767 14.6666 7.99967 14.6666C11.6817 14.6666 14.6663 11.6819 14.6663 7.99992C14.6663 4.31792 11.6817 1.33325 7.99967 1.33325ZM11.333 7.33325H8.66634V4.66658H7.33301V7.33325H4.66634V8.66658H7.33301V11.3333H8.66634V8.66658H11.333V7.33325Z"
                          fill="white"
                        />
                      </svg>{" "}
                    </button>
                  </td>
                </tr>
              ))}
            </table>
          </div>

          <div
            className={`d-flex justify-content-between align-items-center w-100 ${
              isTablet ? "flex-column gap-4" : ""
            }`}
          >
            <textarea
              className="mt-4"
              minLength={4}
              style={{ width: isMobile ? "auto" : 410, height: 142 }}
              placeholder="Enter Narration"
              name="remark"
              onChange={(e) => {
                handleChangeInput(e.target.name, e.target.value);
              }}
              value={inputData.remark}
            ></textarea>
            <div>
              <div
                style={{
                  background: "#F4F4F4",
                  borderRadius: 5,
                  padding: 20,
                }}
              >
                <Col lg={12} md={12} sm={12} className="mt-1 d-flex">
                  <Col md={4}>
                    <Label
                      className="me-3"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      Basic Amount <span className="required_span"> * </span>
                    </Label>
                  </Col>
                  <Col sm={8}>
                    <Input
                      id="exampleEmail"
                      name="addressLine1"
                      className={`cust-input `}
                      type="text"
                    />
                  </Col>
                </Col>
                <Col lg={12} md={12} sm={12} className="mt-1 d-flex">
                  <Col md={4}>
                    <Label
                      className="me-3"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      Discount <span className="required_span"> * </span>
                    </Label>
                  </Col>
                  <Col sm={8}>
                    <Input
                      id="exampleEmail"
                      name="addressLine1"
                      className={`cust-input `}
                      type="text"
                    />
                  </Col>
                </Col>
                <Col lg={12} md={12} sm={12} className="mt-1 d-flex">
                  <Col md={4}>
                    <Label
                      className="me-3"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      CGST <span className="required_span"> * </span>
                    </Label>
                  </Col>
                  <Col sm={8}>
                    <Input
                      id="exampleEmail"
                      name="addressLine1"
                      className={`cust-input `}
                      type="text"
                    />
                  </Col>
                </Col>
                <Col lg={12} md={12} sm={12} className="mt-1 d-flex">
                  <Col md={4}>
                    <Label
                      className="me-3"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      IGST <span className="required_span"> * </span>
                    </Label>
                  </Col>
                  <Col sm={8}>
                    <Input
                      id="exampleEmail"
                      name="addressLine1"
                      className={`cust-input `}
                      type="text"
                    />
                  </Col>
                </Col>
                <Col lg={12} md={12} sm={12} className="mt-1 d-flex">
                  <Col md={4}>
                    <Label
                      className="me-3"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      SGST <span className="required_span"> * </span>
                    </Label>
                  </Col>
                  <Col sm={8}>
                    <Input
                      id="exampleEmail"
                      name="addressLine1"
                      className={`cust-input `}
                      type="text"
                    />
                  </Col>
                </Col>
                <Col lg={12} md={12} sm={12} className="mt-1 d-flex">
                  <Col md={4}>
                    <Label
                      className="me-3"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      Total Amount <span className="required_span"> * </span>
                    </Label>
                  </Col>
                  <Col sm={8}>
                    <Input
                      id="exampleEmail"
                      name="addressLine1"
                      className={`cust-input `}
                      type="text"
                    />
                  </Col>
                </Col>
              </div>

              <div className="d-flex gap-2 mt-4">
                <button
                  type="button"
                  className="custom-btn-page-layout btn text-white"
                  disabled={loading}
                >
                  {loading ? (
                    <ClipLoader color="#75D100" size={25} />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <path
                        d="M10.625 1.875H3.125C2.79348 1.875 2.47554 2.0067 2.24112 2.24112C2.0067 2.47554 1.875 2.79348 1.875 3.125V11.875C1.875 12.2065 2.0067 12.5245 2.24112 12.7589C2.47554 12.9933 2.79348 13.125 3.125 13.125H11.875C12.5625 13.125 13.125 12.5625 13.125 11.875V4.375L10.625 1.875ZM7.5 11.875C6.4625 11.875 5.625 11.0375 5.625 10C5.625 8.9625 6.4625 8.125 7.5 8.125C8.5375 8.125 9.375 8.9625 9.375 10C9.375 11.0375 8.5375 11.875 7.5 11.875ZM9.375 5.625H3.125V3.125H9.375V5.625Z"
                        fill="white"
                      />
                    </svg>
                  )}
                  <span style={{ marginLeft: 10 }}>Save</span>
                </button>
                {/* <button
                  type="button"
                  className="custom-btn-page-layout btn text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M11.875 5H3.125C2.0875 5 1.25 5.8375 1.25 6.875V10.625H3.75V13.125H11.25V10.625H13.75V6.875C13.75 5.8375 12.9125 5 11.875 5ZM10 11.875H5V8.75H10V11.875ZM11.875 7.5C11.5312 7.5 11.25 7.21875 11.25 6.875C11.25 6.53125 11.5312 6.25 11.875 6.25C12.2188 6.25 12.5 6.53125 12.5 6.875C12.5 7.21875 12.2188 7.5 11.875 7.5ZM11.25 1.875H3.75V4.375H11.25V1.875Z"
                      fill="white"
                    />
                  </svg>
                  <span style={{ marginLeft: 10 }}>Print</span>
                </button>
                <button
                  type="button"
                  className="custom-btn-page-layout btn text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M12.5 5L7.5 8.125L2.5 5V3.75L7.5 6.875L12.5 3.75M12.5 2.5H2.5C1.80625 2.5 1.25 3.05625 1.25 3.75V11.25C1.25 11.5815 1.3817 11.8995 1.61612 12.1339C1.85054 12.3683 2.16848 12.5 2.5 12.5H12.5C12.8315 12.5 13.1495 12.3683 13.3839 12.1339C13.6183 11.8995 13.75 11.5815 13.75 11.25V3.75C13.75 3.41848 13.6183 3.10054 13.3839 2.86612C13.1495 2.6317 12.8315 2.5 12.5 2.5Z"
                      fill="white"
                    />
                  </svg>

                  <span style={{ marginLeft: 10 }}>Mail</span>
                </button> */}
              </div>
            </div>
          </div>
        </form>
      </div>
    </PageLayout>
  );
};

export default AddIssueMaterial;
