import React, { useState, useCallback, useEffect } from "react";
import PageLayout from "../../../Component/PageLayout";
import { TextField } from "@mui/material";
import { Col, Input, Label, Row } from "reactstrap";
import useBreakPoints from "../../../hooks/useBreakPoints";
import Select from "react-select";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../../store/reducers/layoutReducer";
import StateOption from "../../../hooks/StateOptions";
import CountryOption from "../../../hooks/CountryOption";
import Flatpickr from "react-flatpickr";
import { ClipLoader } from "react-spinners";
import { purchaseActions } from "../../../redux/actions";

const EditPurchaseOrder = () => {
  const { isTablet, isMobile } = useBreakPoints();
  const location = useLocation();
  // console.log("location: ", location.state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, loginDetails } = useSelector((state) => state.layout);
  const [loading, setLoading] = useState(false);
  const [countryArray, setCountryArray] = useState([]);
  const [stateArray, setStateArray] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [priceListGroupoptions, setPriceListGroupOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [productGstData, setProductGstData] = useState([]);
  const [inputData, setInputData] = useState({
    purchaseOrderId: location?.state?.id,
    vendor: {
      label: location?.state?.vendorDetails?.vendorName,
      value: location?.state?.vendorDetails?.id,
    },
    vendorAddressLine1: location?.state?.vendorDetails?.vendorAddressLine1,
    vendorAddressLine2: location?.state?.vendorDetails?.addressLine3
      ? `${location?.state?.vendorDetails?.addressLine2},${location?.state?.vendorDetails?.addressLine3}`
      : location?.state?.vendorDetails?.addressLine2,
    vendorCountry: location?.state?.vendorDetails?.vendorCountry,
    vendorState: location?.state?.vendorDetails?.vendorState,
    vendorPincode: location?.state?.vendorDetails?.vendorPincode,
    vendorEmail: location?.state?.vendorDetails?.vendorEmail,
    vendorMobile: location?.state?.vendorDetails?.vendorMobile,
    vendorGst: location?.state?.vendorDetails?.vendorGstinNo,
    unit: {
      label: location?.state?.unitDetails?.unitName,
      value: location?.state?.unitDetails?.id,
    },
    unitAddressLine1: location?.state?.unitDetails?.addressLine1,
    unitAddressLine2: location?.state?.unitDetails?.addressLine3
      ? `${location?.state?.unitDetails?.addressLine2},${location?.state?.unitDetails?.addressLine3}`
      : location?.state?.unitDetails?.addressLine2,
    unitCountry: location?.state?.unitDetails?.unitCountry,
    unitState: location?.state?.unitDetails?.unitName,
    unitDistrict: location?.state?.unitDetails?.unitState,
    unitTaluka: location?.state?.unitDetails?.unitTaluka,
    unitVillage: location?.state?.unitDetails?.unitVillage,
    unitPincode: location?.state?.unitDetails?.pincode,
    paymentTerms: location?.state?.paymentTerms,
    dueDate: location?.state?.dueDate,
    priceList: {
      label: location?.state?.priceListDetails?.priceListName,
      value: location?.state?.priceListDetails?.id,
    },
    deliveryTerms: location?.state?.deliveryTerms,
    basicAmount: location?.state?.basicAmount,
    cgst: location?.state?.cgst,
    igst: location?.state?.igst,
    sgst: location?.state?.sgst,
    totalAmount: location?.state?.totalAmount,
    remark: location?.state?.remarks,
    orderDate: location?.state?.orderDate,
    voucherNo: location?.state?.voucherNo,
  });
  // console.log("inputData: ", inputData);

  const [productData, setProductData] = useState(
    JSON.parse(location?.state?.productData)
  );
  // console.log("productData: ", productData);

  const [inputDataError, setInputDataError] = useState({
    vendor: false,
    vendorAddressLine1: false,
    vendorAddressLine2: false,
    vendorCountry: false,
    vendorState: false,
    vendorPincode: false,
    vendorEmail: false,
    vendorMobile: false,
    vendorGst: false,
    unit: false,
    unitAddressLine1: false,
    unitAddressLine2: false,
    unitCountry: false,
    unitState: false,
    unitDistrict: false,
    unitTaluka: false,
    unitVillage: false,
    unitPincode: false,
    paymentTerms: false,
    dueDate: false,
    priceList: false,
    deliveryTerms: false,
    basicAmount: false,
    cgst: false,
    igst: false,
    sgst: false,
    totalAmount: false,
    remark: false,
    orderDate: false,
    voucherNo: false,
  });

  const [productDataError, setProductDataError] = useState([
    {
      product: false,
      partNo: false,
      qty: false,
      rate: false,
      basicAmount: false,
    },
  ]);
  // console.log("productDataError: ", productDataError);

  const { getStateOption } = StateOption();
  const { getCountryOption } = CountryOption();

  const getVendorListAPi = useCallback(async () => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/purchaseordervendordroplist`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];
        // eslint-disable-next-line
        _data?.purchaseVendorDropList?.map((er) => {
          arr.push({ label: er?.vendorName, value: er });
        });

        setVendorOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  const getUnitOptionsListAPi = useCallback(async () => {
    setLoading(true);
    try {
      let formData = new FormData();

      formData.append("companyId", loginDetails?.companyId);
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}company/unitsbycompany`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];

        // eslint-disable-next-line
        _data?.unitsData[0]?.unitdetails?.map((er) => {
          arr.push({ label: er?.unitName, value: er });
        });
        setUnitOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  const getPriceListGroupAPi = useCallback(async () => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/pricelistgroup`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];
        // eslint-disable-next-line
        _data?.priceGroupList?.map((er) => {
          arr.push({ label: er?.priceListName, value: er?.id });
        });

        setPriceListGroupOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  const getProductListAPi = useCallback(async (priceListGroupId) => {
    let formData = new FormData();
    formData.append("priceListGroupId", priceListGroupId);
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/pricelistbypricelistgroup`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];
        // eslint-disable-next-line
        _data?.priceListData?.map((er) => {
          arr.push({ label: er?.productDetails?.productName, value: er });
        });

        setProductOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getVendorListAPi();
    getUnitOptionsListAPi();
    getPriceListGroupAPi();
    getCountryOption().then((res) => {
      setCountryArray(res);
    });
  }, [getVendorListAPi, getUnitOptionsListAPi, getPriceListGroupAPi]);

  const getCheckOptions = (productoptions) => {
    let productArr = [];
    // eslint-disable-next-line
    productoptions?.map((er) => {
      let check = {};
      if (inputData?.priceList?.label == "UnAssigned") {
        check = productData?.find(
          (ed) => ed?.product?.value?.id === er?.value?.id
        );
      } else {
        check = productData?.find(
          (ed) =>
            ed?.product?.value?.productDetails?.id ===
            er?.value?.productDetails?.id
        );
      }
      if (!check) {
        productArr?.push(er);
      }
    });
    return productArr;
  };

  const handleDeleteProductDetails = (index) => {
    // console.log("index: ", index);
    let arr = [...productData];
    arr.splice(index, 1);
    setProductData(arr);

    let arr5 = [...productDataError];
    arr5.splice(index, 1);
    setProductDataError(arr5);
  };

  const handleChangeInput = (name, value) => {
    if (name === "vendor") {
      setInputData({
        ...inputData,
        [name]: value,
        vendorAddressLine1: value?.value?.mailingDetails[0]?.addressLine1,
        vendorAddressLine2: value?.value?.mailingDetails[0]?.addressLine3
          ? `${value?.value?.mailingDetails[0]?.addressLine2}, ${value?.value?.mailingDetails[0]?.addressLine3}`
          : value?.value?.mailingDetails[0]?.addressLine2,
        vendorCountry: value?.value?.mailingDetails[0]?.country?.name,
        vendorState: value?.value?.mailingDetails[0]?.state?.stateName,
        vendorPincode: value?.value?.mailingDetails[0]?.pinCode,
        vendorGst: value?.value?.taxDetails[0]?.gstinNo,
        vendorMobile: value?.value?.mailingDetails[0]?.mobile,
        vendorEmail: value?.value?.mailingDetails[0]?.mailingEmail,
      });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, vendor: false });
      } else {
        setInputDataError({ ...inputDataError, vendor: true });
      }
    } else if (name === "vendorAddressLine1") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, vendorAddressLine1: false });
      } else {
        setInputDataError({ ...inputDataError, vendorAddressLine1: true });
      }
    } else if (name === "vendorAddressLine2") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, vendorAddressLine2: false });
      } else {
        setInputDataError({ ...inputDataError, vendorAddressLine2: true });
      }
    } else if (name === "vendorCountry") {
      getStateOption(value.value).then((res) => {
        setStateArray(res);
      });
      setInputData({ ...inputData, [name]: value, vendorState: "" });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, vendorCountry: false });
      } else {
        setInputDataError({ ...inputDataError, vendorCountry: true });
      }
    } else if (name === "vendorState") {
      setInputData({ ...inputData, [name]: value });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, vendorState: false });
      } else {
        setInputDataError({ ...inputDataError, vendorState: true });
      }
    } else if (name === "vendorPincode") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, vendorPincode: false });
      } else {
        setInputDataError({ ...inputDataError, vendorPincode: true });
      }
    } else if (name === "vendorGst") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, vendorGst: false });
      } else {
        setInputDataError({ ...inputDataError, vendorGst: true });
      }
    } else if (name === "vendorMobile") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, vendorMobile: false });
      } else {
        setInputDataError({ ...inputDataError, vendorMobile: true });
      }
    } else if (name === "vendorEmail") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, vendorEmail: false });
      } else {
        setInputDataError({ ...inputDataError, vendorEmail: true });
      }
    } else if (name === "unit") {
      setInputData({
        ...inputData,
        [name]: value,
        unitAddressLine1: value?.value?.addressLine1,
        unitAddressLine2: value?.value?.addressLine3
          ? `${value?.value?.addressLine2}, ${value?.value?.addressLine3}`
          : value?.value?.addressLine2,
        unitCountry: value?.value?.country?.name,
        unitState: value?.value?.state?.stateName,
        unitDistrict: value?.value?.district?.districtName,
        unitTaluka: value?.value?.taluka?.talukaName,
        unitVillage: value?.value?.village?.villageName,
        unitPincode: value?.value?.pincode,
      });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, unit: false });
      } else {
        setInputDataError({ ...inputDataError, unit: true });
      }
    } else if (name === "priceList") {
      setInputData({ ...inputData, [name]: value });
      getProductListAPi(value?.value);
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, priceList: false });
      } else {
        setInputDataError({ ...inputDataError, priceList: true });
      }
    } else if (name === "deliveryTerms") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, deliveryTerms: false });
      } else {
        setInputDataError({ ...inputDataError, deliveryTerms: true });
      }
    } else if (name === "paymentTerms") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, paymentTerms: false });
      } else {
        setInputDataError({ ...inputDataError, paymentTerms: true });
      }
    } else if (name === "remark") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, remark: false });
      } else {
        setInputDataError({ ...inputDataError, remark: true });
      }
    } else if (name === "voucherNo") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, voucherNo: false });
      } else {
        setInputDataError({ ...inputDataError, voucherNo: true });
      }
    } else {
      setInputData({ ...inputData, [name]: value });
    }
  };

  const handleChangeProduct = (index, name, value) => {
    let arr = [...productData];
    let arr2 = [...productDataError];
    if (name === "product") {
      // console.log("value123", value);
      arr[index][name] = value;
      arr[index]["partNo"] = value?.value?.productDetails?.partNo;
      arr[index]["rate"] = value?.value?.ratePerUnit;
      arr[index]["gstStatus"] = value?.value?.productDetails?.gstStatus;
      arr[index]["qty"] = "";
      arr[index]["basicAmount"] = "";

      if (arr[index]["gstStatus"] === "Yes") {
        arr[index]["cgst"] = value?.value?.productDetails?.cgst || 0;
        arr[index]["sgst"] = value?.value?.productDetails?.sgst || 0;
        arr[index]["igst"] = value?.value?.productDetails?.igst || 0;
      } else {
        arr[index]["cgst"] = 0;
        arr[index]["igst"] = 0;
        arr[index]["sgst"] = 0;
      }

      if (value?.label?.length > 0) {
        arr2[index]["product"] = false;
      } else {
        arr2[index]["product"] = true;
      }
    } else if (name === "rate") {
      arr[index][name] = value;
      arr[index]["basicAmount"] = +value * arr[index]["qty"];
      if (inputData?.vendorState === "GUJARAT") {
        arr[index]["igst"] = 0;
        arr[index]["taxableigstAmount"] = 0;
        arr[index]["taxablecgstAmount"] =
          (arr[index]["basicAmount"] * arr[index]["cgst"]) / 100;
        arr[index]["taxablesgstAmount"] =
          (arr[index]["basicAmount"] * arr[index]["sgst"]) / 100;

        arr[index]["taxableAmount"] =
          arr[index]["basicAmount"] +
          arr[index]["taxablecgstAmount"] +
          arr[index]["taxablesgstAmount"];
      } else {
        arr[index]["cgst"] = 0;
        arr[index]["sgst"] = 0;
        arr[index]["taxablecgstAmount"] = 0;
        arr[index]["taxablesgstAmount"] = 0;
        arr[index]["taxableigstAmount"] =
          (arr[index]["basicAmount"] * arr[index]["igst"]) / 100;
        arr[index]["taxableAmount"] =
          arr[index]["basicAmount"] + arr[index]["taxableigstAmount"];
      }
      if (value?.length > 0) {
        arr2[index]["rate"] = false;
      } else {
        arr2[index]["rate"] = true;
      }
    } else if (name === "qty") {
      arr[index][name] = value;
      arr[index]["basicAmount"] = +value * arr[index]["rate"];

      if (inputData?.vendorState === "GUJARAT") {
        arr[index]["igst"] = 0;
        arr[index]["taxableigstAmount"] = 0;
        arr[index]["taxablecgstAmount"] =
          (arr[index]["basicAmount"] * arr[index]["cgst"]) / 100;
        arr[index]["taxablesgstAmount"] =
          (arr[index]["basicAmount"] * arr[index]["sgst"]) / 100;

        arr[index]["taxableAmount"] =
          arr[index]["basicAmount"] +
          arr[index]["taxablecgstAmount"] +
          arr[index]["taxablesgstAmount"];
      } else {
        arr[index]["cgst"] = 0;
        arr[index]["sgst"] = 0;
        arr[index]["taxablecgstAmount"] = 0;
        arr[index]["taxablesgstAmount"] = 0;
        arr[index]["taxableigstAmount"] =
          (arr[index]["basicAmount"] * arr[index]["igst"]) / 100;
        arr[index]["taxableAmount"] =
          arr[index]["basicAmount"] + arr[index]["taxableigstAmount"];
      }

      if (value?.length > 0) {
        arr2[index]["qty"] = false;
      } else {
        arr2[index]["qty"] = true;
      }
    } else {
      setProductData({ ...productData, [name]: value });
    }

    setProductData(arr);
    setProductDataError(arr2);
  };

  function getSum(total, num) {
    return total + num?.basicAmount;
  }
  function getcgstSum(total, num) {
    return total + num?.taxablecgstAmount;
  }
  function getsgstSum(total, num) {
    return total + num?.taxablesgstAmount;
  }
  function getigstSum(total, num) {
    return total + num?.taxableigstAmount;
  }
  function getTotalSum(total, num) {
    return total + num?.taxableAmount;
  }

  useEffect(() => {
    if (productData) {
      let subTotal = productData?.reduce(getSum, 0);
      // console.log("subTotal: ", subTotal);
      let totalcgst = productData?.reduce(getcgstSum, 0);
      // console.log("totalcgst: ", totalcgst);
      let totalsgst = productData?.reduce(getsgstSum, 0);
      // console.log("totalsgst: ", totalsgst);
      let totaligst = productData?.reduce(getigstSum, 0);
      // console.log("totaligst: ", totaligst);
      let total = productData?.reduce(getTotalSum, 0);
      // console.log("total: ", total);

      setInputData({
        ...inputData,
        basicAmount: subTotal,
        cgst: totalcgst,
        sgst: totalsgst,
        igst: totaligst,
        totalAmount: total,
      });
    }
    // eslint-disable-next-line
  }, [productData]);

  useEffect(() => {
    if (location?.state?.productData) {
      if (location?.state?.productData?.length > 0) {
        let arr5 = [];
        JSON.parse(location?.state?.productData)?.map((er) => {
          arr5.push({
            product: false,
            partNo: false,
            qty: false,
            rate: false,
            basicAmount: false,
          });
        });
        setProductDataError(arr5);
      }
    }
    // eslint-disable-next-line
  }, [location?.state?.productData]);

  useEffect(() => {
    if (location?.state?.priceListDetails?.id) {
      getProductListAPi(location?.state?.priceListDetails?.id);
    }
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    if (productData?.length) {
      let arr = [];

      productData?.map((er) => {
        if (inputData?.vendorState === "GUJARAT") {
          let Check = arr?.find((qw) => qw?.cgst === er?.cgst);

          if (Check) {
            arr?.map((qt) => {
              if (qt?.cgst === er?.cgst) {
                qt.taxablecgstAmount = (
                  parseFloat(qt?.taxablecgstAmount) +
                  parseFloat(er?.taxablecgstAmount)
                ).toFixed(2);
                qt.basicAmount = (
                  parseFloat(qt?.basicAmount) + parseFloat(er?.basicAmount)
                ).toFixed(2);
                qt.taxablesgstAmount = (
                  parseFloat(qt?.taxablesgstAmount) +
                  parseFloat(er?.taxablesgstAmount)
                ).toFixed(2);
                qt.taxableAmount = (
                  parseFloat(qt?.taxableAmount) + parseFloat(er?.taxableAmount)
                ).toFixed(2);
              }

              return qt;
            });
          } else {
            arr.push({
              basicAmount: parseFloat(er?.basicAmount || 0).toFixed(2) || "",
              cgst: er?.cgst,
              sgst: er?.sgst,
              igst: er?.igst,
              taxablesgstAmount: parseFloat(er?.taxablesgstAmount || 0).toFixed(2) || "",
              taxablecgstAmount: parseFloat(er?.taxablecgstAmount || 0).toFixed(2) || "",
              taxableigstAmount: parseFloat(er?.taxableigstAmount || 0).toFixed(2) || "",
              taxableAmount: parseFloat(er?.taxableAmount || 0).toFixed(2) || "",
            });
          }
        } else {
          let Check = arr?.find((qw) => qw?.igst === er?.igst);

          if (Check) {
            arr?.map((qt) => {
              if (qt?.igst === er?.igst) {
                qt.taxableigstAmount = (
                  parseFloat(qt?.taxableigstAmount) +
                  parseFloat(er?.taxableigstAmount)
                ).toFixed(2);
                qt.basicAmount = (
                  parseFloat(qt?.basicAmount) + parseFloat(er?.basicAmount)
                ).toFixed(2);
                qt.taxableAmount = (
                  parseFloat(qt?.taxableAmount) + parseFloat(er?.taxableAmount)
                ).toFixed(2);
              }

              return qt;
            });
          } else {
            arr.push({
              basicAmount: parseFloat(er?.basicAmount || 0).toFixed(2) || "",
              cgst: er?.cgst,
              sgst: er?.sgst,
              igst: er?.igst,
              taxablesgstAmount: parseFloat(er?.taxablesgstAmount || 0).toFixed(2) || "",
              taxablecgstAmount: parseFloat(er?.taxablecgstAmount || 0).toFixed(2) || "",
              taxableigstAmount: parseFloat(er?.taxableigstAmount || 0).toFixed(2) || "",
              taxableAmount: parseFloat(er?.taxableAmount || 0).toFixed(2) || "",
            });
          }
        }
      });

      // console.log("arr: ", arr);
      setProductGstData(arr);
    }
    // eslint-disable-next-line
  }, [productData]);


  const handleSubmit_Old = async (e) => {
    e.preventDefault();
    let errorState = false;
    console.log("error: ", !errorState);

    productData?.map((er, index) => {
      if (!er?.product) {
        toast.error("Product is Required!");
        let arr = [...productDataError];
        arr[index]["product"] = true;
        setProductDataError(arr);
        errorState = true;
      } else if (!er?.qty) {
        toast.error("Quantity is Required!");
        let arr = [...productDataError];
        arr[index]["qty"] = true;
        setProductDataError(arr);
        errorState = true;
      }
    });

    if (!inputData.vendor) {
      toast.error("Vendor is Required!");
      setInputDataError({ ...inputDataError, vendor: true });
    } else if (!inputData.unit) {
      toast.error("Unit is Required!");
      setInputDataError({ ...inputDataError, unit: true });
    } else if (!inputData.orderDate) {
      toast.error("Order Date is Required!");
      setInputDataError({ ...inputDataError, orderDate: true });
    } else if (!inputData.voucherNo) {
      toast.error("Voucher No. is Required!");
      setInputDataError({ ...inputDataError, voucherNo: true });
    } else if (!inputData.dueDate) {
      toast.error("Order Due Date is Required!");
      setInputDataError({ ...inputDataError, dueDate: true });
    } else if (!inputData.priceList) {
      toast.error("Selection Of Price List is Required!");
      setInputDataError({ ...inputDataError, priceList: true });
    } else if (!inputData.paymentTerms) {
      toast.error("Payment Terms is Required!");
      setInputDataError({ ...inputDataError, paymentTerms: true });
    } else if (!inputData.deliveryTerms) {
      toast.error("Delivery Terms is Required!");
      setInputDataError({ ...inputDataError, deliveryTerms: true });
      // } else if (productData.length > 0) {
      //   productData?.map((er, i) => {
      //     if (!er?.product) {
      //       toast.error("Product is Required!");
      //       let arr = [...productDataError];
      //       arr[i]["product"] = true;
      //       setProductDataError(arr)
      //       errorState = true;
      //     } else if (!er?.qty) {
      //       toast.error("Quantity is Required!");
      //       let arr = [...productDataError];
      //       arr[i]["qty"] = true;
      //       setProductDataError(arr)
      //       errorState = true;
      //     } else {
      //         errorState = false
      //     }
      //   });
      // } else if (!errorState) {
    } else if (!errorState) {
      console.log("error: xcvxcv", errorState);

      setLoading(true);
      let formData = new FormData();
      formData.append("id", inputData?.purchaseOrderId);
      formData.append("vendorId", inputData?.vendor?.value);
      formData.append("unitId", inputData?.unit?.value);
      formData.append("orderDate", inputData?.orderDate);
      formData.append("voucherNo", inputData?.voucherNo);
      formData.append("paymentTerms", inputData?.paymentTerms);
      formData.append("dueDate", inputData?.dueDate);
      formData.append("priceListGroupId", inputData?.priceList?.value);
      formData.append("deliveryTerms", inputData?.deliveryTerms);
      formData.append("productData", JSON.stringify(productData));
      formData.append("basicAmount", inputData?.basicAmount);
      formData.append("cgst", inputData?.cgst);
      formData.append("sgst", inputData?.sgst);
      formData.append("igst", inputData?.igst);
      formData.append("totalAmount", inputData?.totalAmount);
      formData.append("remarks", inputData?.remark);
      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/editpurchaseorder`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setLoading(false);
          navigate("/purchaseorderlist");
        } else {
          setLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errorState = false;
    // console.log("error: ", !errorState);

    productData?.map((er, index) => {
      if (!er?.product) {
        toast.error("Product is Required!");
        let arr = [...productDataError];
        arr[index]["product"] = true;
        setProductDataError(arr);
        errorState = true;
      } else if (!er?.qty) {
        toast.error("Quantity is Required!");
        let arr = [...productDataError];
        arr[index]["qty"] = true;
        setProductDataError(arr);
        errorState = true;
      }
    });

    if (!inputData.vendor) {
      toast.error("Vendor is Required!");
      setInputDataError({ ...inputDataError, vendor: true });
    } else if (!inputData.unit) {
      toast.error("Unit is Required!");
      setInputDataError({ ...inputDataError, unit: true });
    } else if (!inputData.orderDate) {
      toast.error("Order Date is Required!");
      setInputDataError({ ...inputDataError, orderDate: true });
    } else if (!inputData.voucherNo) {
      toast.error("Voucher No. is Required!");
      setInputDataError({ ...inputDataError, voucherNo: true });
    } else if (!inputData.dueDate) {
      toast.error("Order Due Date is Required!");
      setInputDataError({ ...inputDataError, dueDate: true });
    } else if (!inputData.priceList) {
      toast.error("Selection Of Price List is Required!");
      setInputDataError({ ...inputDataError, priceList: true });
    } else if (!inputData.paymentTerms) {
      toast.error("Payment Terms is Required!");
      setInputDataError({ ...inputDataError, paymentTerms: true });
    } else if (!inputData.deliveryTerms) {
      toast.error("Delivery Terms is Required!");
      setInputDataError({ ...inputDataError, deliveryTerms: true });
    } else if (!errorState) {
      // console.log("error: xcvxcv", errorState);

      setLoading(true);
      let formData = new FormData();
      formData.append("id", inputData?.purchaseOrderId);
      formData.append("vendorId", inputData?.vendor?.value);
      formData.append("unitId", inputData?.unit?.value);
      formData.append("orderDate", inputData?.orderDate);
      formData.append("voucherNo", inputData?.voucherNo);
      formData.append("paymentTerms", inputData?.paymentTerms);
      formData.append("dueDate", inputData?.dueDate);
      formData.append("priceListGroupId", inputData?.priceList?.value);
      formData.append("deliveryTerms", inputData?.deliveryTerms);
      formData.append("productData", JSON.stringify(productData));
      formData.append("basicAmount", inputData?.basicAmount);
      formData.append("cgst", inputData?.cgst);
      formData.append("sgst", inputData?.sgst);
      formData.append("igst", inputData?.igst);
      formData.append("totalAmount", inputData?.totalAmount);
      formData.append("remarks", inputData?.remark);
      try {
        dispatch(
          purchaseActions.savePurchaseOrder(formData, {
            onSuccess: () => {
              toast.success("Data Updated Successfully.");
              setLoading(false);
              navigate("/purchaseorderlist");
            },
            onError: (err) => {
              setLoading(false);
              console.log(err);
              toast.error("Something went wrong. Please try again.");
            },
          })
        );
      } catch (error) {
        setLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  return (
    <PageLayout title={"EDIT PURCHASE ORDER"}>
      <div className="" style={{ margin: "10px 40px 40px 40px" }}>
        <form>
          <div>
            <Row className="g-3 ">
              <Col lg={6} md={6} sm={12} className=""></Col>
              <Col lg={6} md={6} sm={12} className="">
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className={`mt-4 d-flex ${
                    isTablet ? "flex-column gap-2" : ""
                  }`}
                >
                  <Col
                    lg={6}
                    md={12}
                    sm={12}
                    style={{
                      paddingLeft: isTablet ? 0 : 5,
                      paddingRight: isTablet ? 0 : 5,
                    }}
                  >
                    <Flatpickr
                      className={`form-control ${
                        inputDataError?.orderDate ? "border border-danger " : ""
                      }`}
                      placeholder="Select Order Date"
                      options={{
                        dateFormat: "Y-m-d",
                        minDate: new Date().setMonth(new Date().getMonth() - 1),
                        maxDate: new Date(),
                      }}
                      value={inputData?.orderDate}
                      disabled
                      //   onChange={(date, dateStr) => {
                      //     setInputData({ ...inputData, orderDate: dateStr });
                      //     if (dateStr?.length > 0) {
                      //       setInputDataError({
                      //         ...inputDataError,
                      //         orderDate: false,
                      //       });
                      //     } else {
                      //       setInputDataError({
                      //         ...inputDataError,
                      //         orderDate: true,
                      //       });
                      //     }
                      //   }}
                    />
                  </Col>
                  <Col
                    lg={6}
                    md={12}
                    sm={12}
                    style={{
                      paddingLeft: isTablet ? 0 : 5,
                      paddingRight: isTablet ? 0 : 5,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Voucher No."
                      fullWidth
                      name="voucherNo"
                      size="small"
                      value={inputData?.voucherNo}
                      error={inputDataError?.voucherNo}
                      //   onChange={(e) => {
                      //     handleChangeInput(e.target.name, e.target.value);
                      //   }}
                      variant="outlined"
                      type="text"
                      disabled
                    />
                  </Col>
                </Col>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="6">
                <p>
                  <b>BILLING ADDRESS</b>
                </p>
              </Col>
              <Col md="6">
                <p>
                  <b>SHIPPING ADDRESS</b>
                </p>
              </Col>
            </Row>
            <Row className=" g-3 mt-1 ">
              <Col lg={6} md={12} sm={12} className="">
                <Col lg={12} md={12} sm={12}>
                  <Select
                    isMulti={false}
                    // onChange={(e) => {
                    //   handleChangeInput("vendor", e);
                    // }}
                    value={inputData?.vendor}
                    options={vendorOptions}
                    // menuPlacement="top"
                    name="choices-single-default"
                    placeholder="Select Vendor"
                    id="countryAddCompany"
                    className={`w-100 ${
                      inputDataError?.vendor ? "border border-danger " : ""
                    }`}
                    isDisabled
                  ></Select>
                </Col>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className={`mt-4 d-flex ${
                    isTablet ? "flex-column gap-2" : ""
                  }`}
                >
                  <Col
                    lg={6}
                    md={12}
                    sm={12}
                    style={{ paddingRight: isTablet ? 0 : 5 }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Address Line1 *"
                      fullWidth
                      name="vendorAddressLine1"
                      size="small"
                      value={inputData?.vendorAddressLine1}
                      error={inputDataError?.vendorAddressLine1}
                      onChange={(e) => {
                        handleChangeInput(e.target.name, e.target.value);
                      }}
                      variant="outlined"
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col
                    lg={6}
                    md={12}
                    sm={12}
                    style={{
                      paddingLeft: isTablet ? 0 : 5,
                      paddingRight: isTablet ? 0 : 5,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Address Line2 *"
                      fullWidth
                      name="vendorAddressLine2"
                      size="small"
                      value={inputData?.vendorAddressLine2}
                      error={inputDataError?.vendorAddressLine2}
                      onChange={(e) => {
                        handleChangeInput(e.target.name, e.target.value);
                      }}
                      variant="outlined"
                      type="text"
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className={`mt-4 d-flex ${
                    isTablet ? "flex-column gap-2" : ""
                  }`}
                >
                  <Col
                    lg={6}
                    md={12}
                    sm={12}
                    style={{ paddingRight: isTablet ? 0 : 5 }}
                  >
                    {/* <Select
                      isMulti={false}
                      onChange={(e) => {
                        handleChangeInput("vendorCountry", e);
                      }}
                      value={inputData?.vendorCountry}
                      options={countryArray}
                      // menuPlacement="top"
                      name="choices-single-default"
                      id="countryAddCompany"
                      className={`w-100 ${
                        inputDataError?.vendorCountry
                          ? "border border-danger"
                          : ""
                      }`}
                    ></Select> */}
                    <TextField
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      value={inputData?.vendorCountry}
                      disabled
                      fullWidth
                      label="Country"
                    />
                  </Col>
                  <Col
                    lg={6}
                    md={12}
                    sm={12}
                    style={{
                      paddingLeft: isTablet ? 0 : 5,
                      paddingRight: isTablet ? 0 : 5,
                    }}
                  >
                    {/* <Select
                      isMulti={false}
                      onChange={(e) => {
                        handleChangeInput("vendorState", e);
                      }}
                      value={inputData?.vendorState}
                      name="vendorState"
                      options={stateArray}
                      // menuPlacement="top"

                      className={`w-100 ${
                        inputDataError?.vendorState
                          ? "border border-danger"
                          : ""
                      }`}
                    ></Select> */}
                    <TextField
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      value={inputData?.vendorState}
                      disabled
                      fullWidth
                      label="State"
                    />
                  </Col>
                </Col>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className={`mt-4 d-flex ${
                    isTablet ? "flex-column gap-2" : ""
                  }`}
                >
                  <Col
                    lg={6}
                    md={12}
                    sm={12}
                    style={{ paddingRight: isTablet ? 0 : 5 }}
                  >
                    <TextField
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      value={inputData?.vendorPincode}
                      disabled
                      fullWidth
                      label="Pincode"
                    />
                  </Col>
                  <Col
                    lg={6}
                    md={12}
                    sm={12}
                    style={{ paddingRight: isTablet ? 0 : 5 }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="GSTIN No *"
                      fullWidth
                      name="vendorGst"
                      size="small"
                      value={inputData?.vendorGst}
                      error={inputDataError?.vendorGst}
                      onChange={(e) => {
                        handleChangeInput(e.target.name, e.target.value);
                      }}
                      variant="outlined"
                      type="text"
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className={`mt-4 d-flex ${
                    isTablet ? "flex-column gap-2" : ""
                  }`}
                >
                  <Col
                    lg={6}
                    md={12}
                    sm={12}
                    style={{ paddingRight: isTablet ? 0 : 5 }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Mobile No *"
                      fullWidth
                      name="vendorMobile"
                      size="small"
                      value={inputData?.vendorMobile}
                      error={inputDataError?.vendorMobile}
                      onChange={(e) => {
                        handleChangeInput(e.target.name, e.target.value);
                      }}
                      variant="outlined"
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col
                    lg={6}
                    md={12}
                    sm={12}
                    style={{ paddingRight: isTablet ? 0 : 5 }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="E mail Address *"
                      fullWidth
                      name="vendorEmail"
                      size="small"
                      value={inputData?.vendorEmail}
                      error={inputDataError?.vendorEmail}
                      onChange={(e) => {
                        handleChangeInput(e.target.name, e.target.value);
                      }}
                      variant="outlined"
                      type="text"
                      disabled
                    />
                  </Col>
                </Col>
              </Col>
              <Col lg={6} md={12} sm={12} className="">
                <Col lg={12} md={12} sm={12}>
                  <Select
                    isMulti={false}
                    // onChange={(e) => {
                    //   handleChangeInput("unit", e);
                    // }}
                    value={inputData?.unit}
                    options={unitOptions}
                    // menuPlacement="top"
                    name="choices-single-default"
                    id="countryAddCompany"
                    className={`w-100 ${
                      inputDataError?.unit ? "border border-danger" : ""
                    }`}
                    isDisabled
                  ></Select>
                </Col>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className={`mt-4 d-flex ${
                    isTablet ? "flex-column gap-2" : ""
                  }`}
                >
                  <Col
                    lg={6}
                    md={12}
                    sm={12}
                    style={{ paddingRight: isTablet ? 0 : 5 }}
                  >
                    <TextField
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      value={inputData.unitAddressLine1}
                      disabled
                      fullWidth
                      label="Address Line1 "
                    />
                  </Col>
                  <Col
                    lg={6}
                    md={12}
                    sm={12}
                    style={{
                      paddingLeft: isTablet ? 0 : 5,
                      paddingRight: isTablet ? 0 : 5,
                    }}
                  >
                    <TextField
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      value={inputData?.unitAddressLine2}
                      disabled
                      fullWidth
                      label="Address Line2"
                    />
                  </Col>
                </Col>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className={`mt-4 d-flex ${
                    isTablet ? "flex-column gap-2" : ""
                  }`}
                >
                  <Col
                    lg={3}
                    md={12}
                    sm={12}
                    style={{ paddingLeft: isTablet ? 0 : 5 }}
                  >
                    <TextField
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      value={inputData?.unitCountry}
                      disabled
                      fullWidth
                      label="Country"
                    />
                  </Col>
                  <Col
                    lg={3}
                    md={12}
                    sm={12}
                    style={{ paddingLeft: isTablet ? 0 : 5 }}
                  >
                    <TextField
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      value={inputData?.unitState}
                      disabled
                      fullWidth
                      label="State"
                    />
                  </Col>
                  <Col
                    lg={3}
                    md={12}
                    sm={12}
                    style={{ paddingLeft: isTablet ? 0 : 5 }}
                  >
                    <TextField
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      value={inputData?.unitDistrict}
                      disabled
                      fullWidth
                      label="District"
                    />
                  </Col>
                  <Col
                    lg={3}
                    md={12}
                    sm={12}
                    style={{ paddingLeft: isTablet ? 0 : 5 }}
                  >
                    <TextField
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      value={inputData?.unitTaluka}
                      disabled
                      fullWidth
                      label="Taluka"
                    />
                  </Col>
                </Col>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className={`mt-4 d-flex ${
                    isTablet ? "flex-column gap-2" : ""
                  }`}
                >
                  <Col
                    lg={4}
                    md={12}
                    sm={12}
                    style={{ paddingLeft: isTablet ? 0 : 5 }}
                  >
                    <TextField
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      value={inputData?.unitVillage}
                      disabled
                      fullWidth
                      label="Village"
                    />
                  </Col>
                  <Col
                    lg={4}
                    md={12}
                    sm={12}
                    style={{ paddingLeft: isTablet ? 0 : 5 }}
                  >
                    <TextField
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      value={inputData?.unitPincode}
                      disabled
                      fullWidth
                      label="Pincode"
                    />
                  </Col>
                  <Col
                    lg={4}
                    md={12}
                    sm={12}
                    style={{ paddingLeft: isTablet ? 0 : 5 }}
                  >
                    <Flatpickr
                      className={`form-control ${
                        inputDataError?.dueDate ? "border border-danger " : ""
                      }`}
                      placeholder="Select Due Date"
                      options={{
                        dateFormat: "Y-m-d",
                      }}
                      value={inputData?.dueDate}
                      onChange={(date, dateStr) => {
                        setInputData({ ...inputData, dueDate: dateStr });
                        if (dateStr?.length > 0) {
                          setInputDataError({
                            ...inputDataError,
                            dueDate: false,
                          });
                        } else {
                          setInputDataError({
                            ...inputDataError,
                            dueDate: true,
                          });
                        }
                      }}
                    />
                  </Col>
                </Col>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className={`mt-4 d-flex ${
                    isTablet ? "flex-column gap-2" : ""
                  }`}
                >
                  <Col
                    lg={6}
                    md={12}
                    sm={12}
                    style={{ paddingLeft: isTablet ? 0 : 5 }}
                  >
                    <Select
                      isMulti={false}
                      //   onChange={(e) => {
                      //     handleChangeInput("priceList", e);
                      //   }}
                      value={inputData?.priceList}
                      options={priceListGroupoptions}
                      placeholder="Select Price List"
                      // menuPlacement="top"
                      name="choices-single-default"
                      id="countryAddCompany"
                      className={`w-100 ${
                        inputDataError?.priceList ? "border border-danger " : ""
                      }`}
                      isDisabled
                    ></Select>
                  </Col>
                  <Col
                    lg={6}
                    md={12}
                    sm={12}
                    style={{ paddingLeft: isTablet ? 0 : 5 }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Payment Terms*"
                      fullWidth
                      name="paymentTerms"
                      size="small"
                      value={inputData?.paymentTerms}
                      error={inputDataError?.paymentTerms}
                      //   onChange={(e) => {
                      //     handleChangeInput(e.target.name, e.target.value);
                      //   }}
                      variant="outlined"
                      type="text"
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className={`mt-4 d-flex ${
                    isTablet ? "flex-column gap-2" : ""
                  }`}
                >
                  <Col
                    lg={6}
                    md={12}
                    sm={12}
                    style={{ paddingLeft: isTablet ? 0 : 5 }}
                  >
                    <textarea
                      fullWidth
                      minLength={1}
                      style={{ width: "100%" }}
                      placeholder="Enter Terms Of Delivery"
                      name="deliveryTerms"
                      value={inputData?.deliveryTerms}
                      error={inputDataError?.deliveryTerms}
                      onChange={(e) => {
                        handleChangeInput(e.target.name, e.target.value);
                      }}
                      className={`w-100 ${
                        inputDataError?.deliveryTerms
                          ? "border border-danger "
                          : ""
                      }`}
                    />
                  </Col>
                </Col>
              </Col>
            </Row>
          </div>

          <>
            <div className="table-responsive" style={{ zIndex: 5 }}>
              <table
                style={{ minWidth: 778 }}
                className="mt-4 table table-bordered "
              >
                <tr>
                  <th
                    style={{
                      textAlign: "center",
                      paddingLeft: 18,
                      height: 40,
                      backgroundColor: "#B3E9B9",
                      border: "1px solid transparent ",
                      width: 40,
                    }}
                  >
                    Product Name
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      paddingLeft: 18,
                      height: 20,
                      backgroundColor: "#B3E9B9",
                      border: "1px solid transparent  ",
                      width: 20,
                    }}
                  >
                    Part No.
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      paddingLeft: 18,
                      height: 20,
                      backgroundColor: "#B3E9B9",
                      border: "1px solid transparent  ",
                      width: 10,
                    }}
                  >
                    Qty
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      paddingLeft: 18,
                      height: 40,
                      backgroundColor: "#B3E9B9",
                      border: "1px solid transparent  ",
                      width: 10,
                    }}
                  >
                    Rate
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      paddingLeft: 18,
                      height: 40,
                      backgroundColor: "#B3E9B9",
                      border: "1px solid transparent  ",
                      width: 10,
                    }}
                  >
                    Basic Amount
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      paddingLeft: 18,
                      height: 10,
                      backgroundColor: "#B3E9B9",
                      border: "1px solid transparent  ",
                      width: 10,
                    }}
                  >
                    Add
                  </th>
                </tr>
                {productData?.map((item, i) => (
                  <tr>
                    <td
                      style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        textAlign: "center",
                      }}
                    >
                      <Select
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        isMulti={false}
                        onChange={(e) => {
                          handleChangeProduct(i, "product", e);
                        }}
                        isDisabled={item?.product?.label?.length > 0}
                        value={item?.product}
                        options={getCheckOptions(productOptions)}
                        // menuPlacement="top"
                        name="choices-single-default"
                        id="countryAddCompany"
                        className={`w-100 ${
                          productDataError[i]?.product
                            ? "border border-danger"
                            : ""
                        }`}
                      ></Select>
                    </td>
                    <td
                      style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        textAlign: "center",
                      }}
                    >
                      <Input
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={item?.partNo}
                        disabled
                        fullWidth
                        label="Part No"
                      />
                    </td>
                    <td
                      style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        textAlign: "center",
                      }}
                    >
                      <Input
                        value={item?.qty}
                        className={`cust-input ${
                          productDataError[i]?.qty ? "border border-danger" : ""
                        }`}
                        onChange={(e) => {
                          handleChangeProduct(i, "qty", e.target.value);
                        }}
                        type="number"
                        placeholder="Qty"
                      />
                    </td>
                    <td
                      style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        textAlign: "center",
                      }}
                    >
                      <Input
                        variant="filled"
                        size="small"
                        className=" border mt-2 mb-2"
                        placeholder="Rate"
                        value={item?.rate}
                        onChange={(e) => {
                          handleChangeProduct(i, "rate", e.target.value);
                        }}
                        disabled={inputData?.priceList?.label !== "UnAssigned"}
                      />
                    </td>
                    <td
                      style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        textAlign: "center",
                      }}
                    >
                      <Input
                        variant="filled"
                        size="small"
                        className=" border mt-2 mb-2"
                        placeholder="Basic Amount"
                        value={item?.basicAmount}
                        disabled
                      />
                    </td>
                    <td
                      style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        textAlign: "center",
                      }}
                    >
                      <button
                        type="button"
                        className="custom-btn-page-layout btn text-white"
                        disabled={
                          productOptions?.length === productData?.length
                        }
                        onClick={() => {
                          setProductData([
                            ...productData,
                            {
                              indexId: productData?.length + 1,
                              product: "",
                              partNo: "",
                              qty: "",
                              rate: "",
                              basicAmount: "",
                            },
                          ]);
                          setProductDataError([
                            ...productDataError,
                            {
                              product: false,
                              partNo: false,
                              qty: false,
                              rate: false,
                              basicAmount: false,
                            },
                          ]);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M7.99967 2.66659C10.9403 2.66659 13.333 5.05925 13.333 7.99992C13.333 10.9406 10.9403 13.3333 7.99967 13.3333C5.05901 13.3333 2.66634 10.9406 2.66634 7.99992C2.66634 5.05925 5.05901 2.66659 7.99967 2.66659ZM7.99967 1.33325C4.31767 1.33325 1.33301 4.31792 1.33301 7.99992C1.33301 11.6819 4.31767 14.6666 7.99967 14.6666C11.6817 14.6666 14.6663 11.6819 14.6663 7.99992C14.6663 4.31792 11.6817 1.33325 7.99967 1.33325ZM11.333 7.33325H8.66634V4.66658H7.33301V7.33325H4.66634V8.66658H7.33301V11.3333H8.66634V8.66658H11.333V7.33325Z"
                            fill="white"
                          />
                        </svg>{" "}
                      </button>
                      {productData?.length > 1 && (
                        <button
                          type="button"
                          className="custom-btn-page-layout btn text-white mx-1"
                          onClick={() => {
                            handleDeleteProductDetails(i);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_32_330)">
                              <path
                                d="M6.63507 -0.0178833C5.50545 -0.0178833 4.59795 0.92077 4.59795 2.05558V2.30769H2.30814C1.99026 2.30769 1.73122 2.56673 1.73122 2.88462V3.46154H1.1543V4.61539H2.30814V13.2692C2.30814 14.2183 3.08987 15 4.03891 15H10.962C11.911 15 12.6928 14.2183 12.6928 13.2692V4.61539H13.8466V3.46154H13.2697V2.88462C13.2697 2.56673 13.0106 2.30769 12.6928 2.30769H10.403V2.055C10.403 0.920771 9.49545 -0.0178833 8.36584 -0.0178833H6.63507ZM6.63507 1.17173H8.36584C8.82968 1.17173 9.21334 1.55019 9.21334 2.055V2.30769H5.78699V2.055C5.78699 1.55019 6.17064 1.17231 6.63449 1.17231L6.63507 1.17173ZM3.46199 4.61539H6.41872C6.49026 4.62289 6.56122 4.63327 6.63507 4.63327H8.36584C8.43968 4.63327 8.51007 4.62289 8.58218 4.61539H11.5389V13.2692C11.5389 13.594 11.2868 13.8462 10.962 13.8462H4.03891C3.7141 13.8462 3.46199 13.594 3.46199 13.2692V4.61539ZM4.61584 5.76923V12.6923H5.76968V5.76923H4.61584ZM6.92353 5.76923V12.6923H8.07737V5.76923H6.92353ZM9.23122 5.76923V12.6923H10.3851V5.76923H9.23122Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_32_330">
                                <rect
                                  width="15"
                                  height="15"
                                  fill="white"
                                  transform="translate(0.000488281)"
                                />
                              </clipPath>
                            </defs>
                          </svg>{" "}
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </table>
            </div>
            <div
              className={`d-flex justify-content-between align-items-center w-100 ${
                isTablet ? "flex-column gap-4" : ""
              }`}
            >
              <textarea
                className="mt-0"
                minLength={4}
                name="remark"
                style={{ width: isMobile ? "auto" : 410, height: 142 }}
                placeholder="Enter Remarks"
                onChange={(e) => {
                  handleChangeInput(e.target.name, e.target.value);
                }}
                value={inputData.remark}
              ></textarea>
              <div>
                <div
                  style={{
                    background: "#F4F4F4",
                    borderRadius: 5,
                    padding: 20,
                  }}
                >
                  <Col lg={12} md={12} sm={12} className="mt-1 d-flex">
                    <Col md={4}>
                      <Label
                        className="me-3"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Basic Amount <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Input
                        id="exampleEmail"
                        name="addressLine1"
                        className={`cust-input `}
                        type="text"
                        value={inputData.basicAmount}
                        disabled
                      />
                    </Col>
                  </Col>
                  {inputData?.vendorState === "GUJARAT" && (
                    <Col lg={12} md={12} sm={12} className="mt-1 d-flex">
                      <Col md={4}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          CGST <span className="required_span"> * </span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Input
                          id="exampleEmail"
                          name="addressLine1"
                          className={`cust-input `}
                          type="text"
                          value={inputData?.cgst}
                          disabled
                        />
                      </Col>
                    </Col>
                  )}
                  {inputData?.vendorState === "GUJARAT" && (
                    <Col lg={12} md={12} sm={12} className="mt-1 d-flex">
                      <Col md={4}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          SGST <span className="required_span"> * </span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Input
                          id="exampleEmail"
                          name="addressLine1"
                          className={`cust-input `}
                          type="text"
                          value={inputData?.sgst}
                          disabled
                        />
                      </Col>
                    </Col>
                  )}
                  {inputData?.vendorState !== "GUJARAT" && (
                    <Col lg={12} md={12} sm={12} className="mt-1 d-flex">
                      <Col md={4}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          IGST <span className="required_span"> * </span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Input
                          id="exampleEmail"
                          name="addressLine1"
                          className={`cust-input `}
                          type="text"
                          value={inputData?.igst}
                          disabled
                        />
                      </Col>
                    </Col>
                  )}
                  <Col lg={12} md={12} sm={12} className="mt-1 d-flex">
                    <Col md={4}>
                      <Label
                        className="me-3"
                        style={{ fontWeight: 500 }}
                        for="exampleEmail"
                        md={12}
                      >
                        Total Amount <span className="required_span"> * </span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Input
                        id="exampleEmail"
                        name="addressLine1"
                        className={`cust-input `}
                        type="text"
                        value={parseFloat(inputData?.totalAmount)?.toFixed(2)}
                        disabled
                      />
                    </Col>
                  </Col>
                </div>
              </div>
            </div>
            <div className="d-flex w-100 justify-content-between align-items-center">
              <div
                className="table-responsive"
                style={{
                  zIndex: 5,
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <table
                  style={{ minWidth: 778 }}
                  className="mt-4 table table-bordered "
                >
                  <tr>
                    <th
                    rowSpan={2}
                      style={{
                        textAlign: "center",
                        paddingLeft: 18,
                        height: 40,
                        backgroundColor: "#B3E9B9",
                        border: "1px solid black ",
                        width: 40,
                      }}
                    >
                      Taxable Value
                    </th>
                    {inputData?.vendorState === "GUJARAT" ? (
                      <>
                        <th
                          colSpan={2}
                          style={{
                            textAlign: "center",
                            paddingLeft: 18,
                            height: 20,
                            backgroundColor: "#B3E9B9",
                            border: "1px solid black",
                            width: 20,
                          }}
                        >
                          CGST
                        </th>
                        <th
                          colSpan={2}
                          style={{
                            textAlign: "center",
                            paddingLeft: 18,
                            height: 20,
                            backgroundColor: "#B3E9B9",
                            border: "1px solid black",
                            width: 10,
                          }}
                        >
                          SGST
                        </th>
                      </>
                    ) : (
                      <th
                        colSpan={2}
                        style={{
                          textAlign: "center",
                          paddingLeft: 18,
                          height: 20,
                          backgroundColor: "#B3E9B9",
                          border: "1px solid black",
                          width: 20,
                        }}
                      >
                        IGST
                      </th>
                    )}
                    <th
                    rowSpan={2}
                      style={{
                        textAlign: "center",
                        paddingLeft: 18,
                        height: 40,
                        backgroundColor: "#B3E9B9",
                        border: "1px solid black",
                        width: 10,
                      }}
                    >
                      Total Tax Amount
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        textAlign: "center",
                        paddingLeft: 18,
                        height: 40,
                        backgroundColor: "#B3E9B9",
                        border: "1px solid black",
                        width: 10,
                      }}
                    >
                      Rate
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        paddingLeft: 18,
                        height: 40,
                        backgroundColor: "#B3E9B9",
                        border: "1px solid black",
                        width: 10,
                      }}
                    >
                      Amount
                    </th>
                    {inputData?.vendorState === "GUJARAT" && (<>  <th
                      style={{
                        textAlign: "center",
                        paddingLeft: 18,
                        height: 40,
                        backgroundColor: "#B3E9B9",
                        border: "1px solid black",
                        width: 10,
                      }}
                    >
                      Rate
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        paddingLeft: 18,
                        height: 40,
                        backgroundColor: "#B3E9B9",
                        border: "1px solid black",
                        width: 10,
                      }}
                    >
                      Amount
                    </th> </>)}
                  </tr>
                  {productGstData?.map((item, i) => (
                    <tr>
                      <td
                        style={{
                          paddingLeft: 15,
                          paddingRight: 15,
                          textAlign: "center",
                          border: "1px solid black",
                        }}
                      >
                        <Input
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          value={item?.basicAmount || ""}
                          disabled
                          fullWidth
                        />
                      </td>
                      {inputData?.vendorState === "GUJARAT" ? (
                        <>
                          <td
                            style={{
                              paddingLeft: 15,
                              paddingRight: 15,
                              textAlign: "center",
                              border: "1px solid black",
                            }}
                          >
                            <Input
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              value={item?.cgst || ""}
                              disabled
                              fullWidth
                            />
                          </td>
                          <td
                            style={{
                              paddingLeft: 15,
                              paddingRight: 15,
                              textAlign: "center",
                              border: "1px solid black",
                            }}
                          >
                            <Input
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              value={item?.taxablecgstAmount|| ""}
                              disabled
                              fullWidth
                            />
                          </td>
                          <td
                            style={{
                              paddingLeft: 15,
                              paddingRight: 15,
                              textAlign: "center",
                              border: "1px solid black",
                            }}
                          >
                            <Input
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              value={item?.sgst|| ""}
                              disabled
                              fullWidth
                            />
                          </td>
                          <td
                            style={{
                              paddingLeft: 15,
                              paddingRight: 15,
                              textAlign: "center",
                              border: "1px solid black",
                            }}
                          >
                            <Input
                              size="small"
                              value={item?.taxablesgstAmount || ""}
                              variant="outlined"
                              disabled
                            />
                          </td>
                        </>
                      ) : (
                        <>
                          <td
                            style={{
                              paddingLeft: 15,
                              paddingRight: 15,
                              textAlign: "center",
                              border: "1px solid black",
                            }}
                          >
                            <Input
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              value={item?.igst || ""}
                              disabled
                              fullWidth
                            />
                          </td>
                          <td
                            style={{
                              paddingLeft: 15,
                              paddingRight: 15,
                              textAlign: "center",
                              border: "1px solid black",
                            }}
                          >
                            <Input
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              value={item?.taxableigstAmount || 0}
                              disabled
                              fullWidth
                            />
                          </td>
                        </>
                      )}
                      <td
                        style={{
                          paddingLeft: 15,
                          paddingRight: 15,
                          textAlign: "center",
                          border: "1px solid black",
                        }}
                      >
                        <Input
                          variant="filled"
                          size="small"
                          className=" border mt-2 mb-2"
                          value={parseFloat(item?.taxableAmount || "")?.toFixed(2) || ""}
                          disabled
                        />
                      </td>
                    </tr>
                  ))}
                </table>
              </div>

              <div
                className="d-flex gap-2 mt-4"
                style={{ justifyContent: "flex-end" }}
              ></div>
            </div>
          </>
        </form>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 10,
          }}
        >
          <div>
            <button
              type="button"
              className="custom-btn-page-layout btn text-white mx-4"
              onClick={(e) => navigate("/purchaseorderlist")}
            >
              Cancel
            </button>
          </div>
          <button
            type="button"
            className="custom-btn-page-layout btn text-white"
            onClick={(e) => handleSubmit(e)}
            disabled={loading}
          >
            {loading ? (
              <ClipLoader color="#75D100" size={25} />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M10.625 1.875H3.125C2.79348 1.875 2.47554 2.0067 2.24112 2.24112C2.0067 2.47554 1.875 2.79348 1.875 3.125V11.875C1.875 12.2065 2.0067 12.5245 2.24112 12.7589C2.47554 12.9933 2.79348 13.125 3.125 13.125H11.875C12.5625 13.125 13.125 12.5625 13.125 11.875V4.375L10.625 1.875ZM7.5 11.875C6.4625 11.875 5.625 11.0375 5.625 10C5.625 8.9625 6.4625 8.125 7.5 8.125C8.5375 8.125 9.375 8.9625 9.375 10C9.375 11.0375 8.5375 11.875 7.5 11.875ZM9.375 5.625H3.125V3.125H9.375V5.625Z"
                  fill="white"
                />
              </svg>
            )}
            <span style={{ marginLeft: 10 }}>Save</span>
          </button>
        </div>
      </div>
    </PageLayout>
  );
};

export default EditPurchaseOrder;
