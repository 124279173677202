import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageLayout from "../../Component/PageLayout";
import { Col, Input, Label, Row, InputGroup, InputGroupText } from "reactstrap";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import TableContainer from "../../Component/TableContainer";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import PPEIcentive from "./PPEIcentive";
import CPAIcentive from "./CPAIcentive";
import SchoolRcIncentive from "./SchoolRcIncentive";
import CustomPagination from "../../Component/CustomPagination";
import DeleteModal from "../../Component/DeleteModal";
import DripIncentive from "./DripIncentive";
import ViewIncentiveScheme from "./ViewIncentiveScheme";
import SolarIncentive from "./SolarIncentive";
import GManureScheme from "./GManureScheme";
import PPEScheme from "./PPEScheme";
import BioFertilizerAmount from "./BioFertilizerAmount";
import useBreakPoints from "../../hooks/useBreakPoints";
import OtherIncentive from "./Others";
const IncentiveSchemeTypeList = ({
  showClose = false,
  onCloseClick = null
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMobile, isTablet } = useBreakPoints();
  const { token, permissions } = useSelector((state) => state.layout);

  let permissionsObj = {};
  // eslint-disable-next-line
  permissions?.filter((er) => {
    if (er?.ModuleData?.length > 0) {
      // eslint-disable-next-line
      let filtererdDarta = er?.ModuleData?.filter((ed) => {
        if (ed?.path === "/incentiveschemelist") {
          permissionsObj = ed;
        }
      });
      return filtererdDarta;
    }
  });
  const [loading, setLoading] = useState(false);
  const [schemeNameError, setSchemeNameError] = useState(false);
  const [schemeTypeError, setSchemeTypeError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [singleIncentive, setSingleIncentive] = useState("");
  const [icentiveSchemeTypeOptions, setIcentiveSchemeTypeOptions] = useState(
    []
  );
  const [totalCount, setTotalCount] = useState(0);
  const [configList, setConfigList] = useState([]);
  const [limit, setLimit] = useState(15); // default limit
  const [offset, setOffset] = useState(0); // default offset
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  const [searchInput, setSearchInput] = useState("");

  let todayDate = new Date();
  // console.log(
  //   "todayDate: ",
  //   todayDate.getFullYear() +
  //     "-" +
  //     (todayDate.getMonth() + 1) +
  //     "-" +
  //     todayDate.getDate()
  // );
  const [inputData, setInputData] = useState({
    schemeName: "",
    schemeType: "",
    applicableDate:
      todayDate.getFullYear() +
      "-" +
      (todayDate.getMonth() + 1) +
      "-" +
      todayDate.getDate()
  });

  const getIncentiveSchemeTypeListAPi = useCallback(async () => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/incentiveschemetypedrop`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json"
          }
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        let arr = [];
        // eslint-disable-next-line
        _data?.incentiveSchemeTypeList?.map((er) => {
          arr.push({ label: er?.schemeTypeName, value: er?.id });
        });

        setIcentiveSchemeTypeOptions(arr);
      } else {
        toast.error(_data?.error);
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  // Get Country List
  const getConfigListAPi = useCallback(async (limit, offset) => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/getincentiveconfiguration?limit=${limit}&offset=${offset}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json"
          }
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        setConfigList(_data?.incentiveCopnfigurationDetails);
        setTotalCount(_data?.paginationDetails?.count);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getIncentiveSchemeTypeListAPi();
    getConfigListAPi(limit, offset);
    // eslint-disable-next-line
  }, [getIncentiveSchemeTypeListAPi, getConfigListAPi]);

  const column = useMemo(
    () => [
      {
        Header: "Scheme Name",
        accessor: "schemeName",
        filterable: false
      },
      {
        Header: "Scheme Type",
        accessor: "schemeTypeDetails.schemeTypeName",
        filterable: false
      },
      {
        Header: "Applicable From",
        accessor: "applicableFrom",
        filterable: false
      },
      {
        Header: "Status",
        accessor: "isActive",
        filterable: false
      },
      {
        id: "Action",
        Header: () => (
          <span
            className="d-flex justify-content-center"
            style={{ textAlign: "center" }}
          >
            Action
          </span>
        ),
        Cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <div className="hstack gap-2">
                {/* View Button  */}
                <button
                  className="border-0"
                  style={{ background: "transparent" }}
                  onClick={() => {
                    setSingleIncentive(cellProps.row.original);
                    setShowModal(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_32_322)">
                      <path
                        d="M3.12549 7.5C3.12549 7.5 4.71611 4.375 7.50049 4.375C10.2842 4.375 11.8755 7.5 11.8755 7.5C11.8755 7.5 10.2842 10.625 7.50049 10.625C4.71611 10.625 3.12549 7.5 3.12549 7.5Z"
                        stroke="#03A8DC"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.1255 10.625V11.875C13.1255 12.2065 12.9938 12.5245 12.7594 12.7589C12.525 12.9933 12.207 13.125 11.8755 13.125H3.12549C2.79397 13.125 2.47603 12.9933 2.2416 12.7589C2.00718 12.5245 1.87549 12.2065 1.87549 11.875V10.625M13.1255 4.375V3.125C13.1255 2.79348 12.9938 2.47554 12.7594 2.24112C12.525 2.0067 12.207 1.875 11.8755 1.875H3.12549C2.79397 1.875 2.47603 2.0067 2.2416 2.24112C2.00718 2.47554 1.87549 2.79348 1.87549 3.125V4.375M7.50049 8.125C7.66625 8.125 7.82522 8.05915 7.94243 7.94194C8.05964 7.82473 8.12549 7.66576 8.12549 7.5C8.12549 7.33424 8.05964 7.17527 7.94243 7.05806C7.82522 6.94085 7.66625 6.875 7.50049 6.875C7.33473 6.875 7.17576 6.94085 7.05855 7.05806C6.94134 7.17527 6.87549 7.33424 6.87549 7.5C6.87549 7.66576 6.94134 7.82473 7.05855 7.94194C7.17576 8.05915 7.33473 8.125 7.50049 8.125Z"
                        stroke="#03A8DC"
                        strokeWidth="1.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_32_322">
                        <rect
                          width="15"
                          height="15"
                          fill="white"
                          transform="translate(0.000488281)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                {/* Delete Button */}
                {permissionsObj?.delete && (
                  <button
                    className="border-0"
                    style={{ background: "transparent" }}
                    onClick={() => {
                      setDeleteModal(true);
                      setDeleteId(cellProps.row.original.id);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_32_330)">
                        <path
                          d="M6.63507 -0.0178833C5.50545 -0.0178833 4.59795 0.92077 4.59795 2.05558V2.30769H2.30814C1.99026 2.30769 1.73122 2.56673 1.73122 2.88462V3.46154H1.1543V4.61539H2.30814V13.2692C2.30814 14.2183 3.08987 15 4.03891 15H10.962C11.911 15 12.6928 14.2183 12.6928 13.2692V4.61539H13.8466V3.46154H13.2697V2.88462C13.2697 2.56673 13.0106 2.30769 12.6928 2.30769H10.403V2.055C10.403 0.920771 9.49545 -0.0178833 8.36584 -0.0178833H6.63507ZM6.63507 1.17173H8.36584C8.82968 1.17173 9.21334 1.55019 9.21334 2.055V2.30769H5.78699V2.055C5.78699 1.55019 6.17064 1.17231 6.63449 1.17231L6.63507 1.17173ZM3.46199 4.61539H6.41872C6.49026 4.62289 6.56122 4.63327 6.63507 4.63327H8.36584C8.43968 4.63327 8.51007 4.62289 8.58218 4.61539H11.5389V13.2692C11.5389 13.594 11.2868 13.8462 10.962 13.8462H4.03891C3.7141 13.8462 3.46199 13.594 3.46199 13.2692V4.61539ZM4.61584 5.76923V12.6923H5.76968V5.76923H4.61584ZM6.92353 5.76923V12.6923H8.07737V5.76923H6.92353ZM9.23122 5.76923V12.6923H10.3851V5.76923H9.23122Z"
                          fill="#EC0505"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_32_330">
                          <rect
                            width="15"
                            height="15"
                            fill="white"
                            transform="translate(0.000488281)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                )}
              </div>
            </div>
          );
        }
      }
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  const handleChangeInput = (name, value) => {
    if (name === "schemeName") {
      setInputData({ ...inputData, [name]: value });
      setSchemeNameError(value.length < 0);
    }
    if (name === "schemeType") {
      setInputData({ ...inputData, [name]: value });
      setSchemeTypeError(value.length < 0);
    } else {
      setInputData({ ...inputData, [name]: value });
    }
  };

  const handleConditionnBtn = () => {
    if (!inputData?.schemeName) {
      toast.error("Scheme Name is Required!");
      setSchemeNameError(true);
    } else if (!inputData?.schemeType) {
      setSchemeNameError(false);
      setSchemeTypeError(true);
      toast.error("Scheme Type is Required!");
    } else {
      setSchemeTypeError(false);
      setShowModal(true);
    }
  };

  // handleDelete Btn
  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    let formData = new FormData();
    formData.append("id", deleteId);
    formData.append("isDelete", "Yes");

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/deleteincentiveconfiguration`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: formData
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        getConfigListAPi(limit, offset);
        setDeleteModal(false);
        toast?.success(_data?.message);
        setDeleteLoading(false);
      } else {
        setDeleteLoading(false);
        setDeleteModal(false);

        toast.error(_data?.message);
      }
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
    }
  };

  const handleSubmitModal = () => {
    getConfigListAPi(limit, offset);
  };

  const onChangeSearch = async (value) => {
    setLoading(true);
    setSearchInput(value);
    let formData = new FormData();

    formData.append("schemeName", value);

    try {
      const apiCall = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }farmer/filterincentive?limit=${5}&offset=${0}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: formData
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        setConfigList(_data?.filteredIncentiveList);
        setTotalCount(_data?.filteredIncentiveList?.count);
      } else {
        setLoading(false);
        toast?.error(_data?.message);
      }
    } catch (error) {
      setLoading(false);
      toast?.error(error);
      console.log(error);
    }
  };

  return (
    <PageLayout
      title={"Incentive Scheme Configuration"}
      loading={loading}
      backBtnTitle={showClose ? "Close" : ""}
      onClick={() => (onCloseClick !== null ? onCloseClick() : null)}
    >
      {permissionsObj?.create && (
        <Row className=" mt-2 g-3" style={{ margin: 20 }}>
          <Col
            lg={6}
            md={12}
            sm={12}
            className={`d-flex  ${isMobile ? "flex-column" : ""}`}
          >
            <Col lg={6} md={6}>
              <Label
                className="me-2"
                style={{ fontWeight: 500 }}
                for="exampleEmail"
                md={12}
              >
                Scheme Name <span className="required_span"> * </span>
              </Label>
            </Col>
            <Col lg={6} md={6} sm={8} className="">
              <Input
                id="exampleEmail"
                name="schemeName"
                value={inputData?.schemeName}
                onChange={(e) => {
                  handleChangeInput(e.target.name, e.target.value);
                }}
                className={`cust-input ${
                  schemeNameError ? "border-danger" : ""
                }`}
                type="text"
                error={schemeNameError}
              />
            </Col>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            className={`d-flex  ${isMobile ? "flex-column" : ""}`}
          >
            <Col lg={6} md={6}>
              <Label
                className="me-2"
                style={{ fontWeight: 500 }}
                for="exampleEmail"
                md={12}
              >
                Scheme Type <span className="required_span"> * </span>
              </Label>
            </Col>
            <Col lg={6} md={6} className="">
              <Select
                isMulti={false}
                onChange={(e) => {
                  handleChangeInput("schemeType", e);
                }}
                value={inputData?.schemeType}
                options={icentiveSchemeTypeOptions}
                // menuPlacement="top"
                name="choices-single-default"
                id="countryAddCompany"
                className={`w-100 ${
                  schemeTypeError ? " border border-danger" : ""
                }`}
              ></Select>
            </Col>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            className={`d-flex  ${isMobile ? "flex-column" : ""}`}
          >
            <Col lg={6} md={6}>
              <Label
                className="me-2"
                style={{ fontWeight: 500 }}
                for="exampleEmail"
                md={12}
              >
                Applicable From <span className="required_span"> * </span>
              </Label>
            </Col>
            <Col lg={6} md={6} className="">
              <Flatpickr
                className="form-control"
                options={{
                  dateFormat: "Y-m-d",
                  maxDate: new Date()
                }}
                disabled
                value={inputData?.applicableDate}
                onChange={(date, dateStr) => {
                  setInputData({ ...inputData, applicableDate: dateStr });
                }}
              />
            </Col>
          </Col>
          {isTablet && <Col md={6} className="mt-3"></Col>}
          <Col lg={2} md={6} sm={12}>
            <button
              type="button"
              onClick={() => handleConditionnBtn()}
              className="custom-btn-page-layout btn text-white"
            >
              Conditionals
            </button>
          </Col>
        </Row>
      )}

      <Col md={12}>
        <div className="" style={{ margin: 20 }}>
          <InputGroup className="search-inp">
            <Input
              value={searchInput}
              onChange={(e) => {
                onChangeSearch(e.target.value);
              }}
              placeholder="Search For Scheme Name"
            />
            <InputGroupText>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M15.1987 13.8333L10.9037 9.53833C11.6977 8.33158 12.0157 6.87314 11.7962 5.44537C11.5766 4.01761 10.8351 2.72208 9.71524 1.80963C8.59536 0.897181 7.17674 0.432709 5.73406 0.506144C4.29138 0.579579 2.92726 1.1857 1.90581 2.20715C0.884365 3.22859 0.278248 4.59271 0.204813 6.03539C0.131379 7.47807 0.595851 8.89669 1.5083 10.0166C2.42075 11.1365 3.71628 11.878 5.14404 12.0975C6.57181 12.317 8.03025 11.999 9.237 11.205L13.532 15.5L15.1987 13.8333ZM1.86534 6.33333C1.86534 4.03584 3.7345 2.16667 6.032 2.16667C8.3295 2.16667 10.1987 4.03584 10.1987 6.33333C10.1987 8.63083 8.3295 10.5 6.032 10.5C3.7345 10.5 1.86534 8.63083 1.86534 6.33333Z"
                  fill="#999999"
                />
              </svg>
            </InputGroupText>
          </InputGroup>
        </div>
        {loading ? (
          ""
        ) : (
          <div
            className="d-grid"
            style={{ margin: "0 20px", border: "1px solid #DEE2E6" }}
          >
            <TableContainer
              columns={column}
              data={configList}
              isGlobalFilter={false}
              isAddUserList={false}
              tableClass={"table-sm"}
              divClass={"table-responsive"}
            />
            <CustomPagination
              apiCallFunction={getConfigListAPi}
              rowDetails={configList}
              totalCount={totalCount}
              limit={limit}
              offset={offset}
              setLimit={setLimit}
              setOffset={setOffset}
            />
          </div>
        )}
      </Col>
      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={handleDeleteRequest}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />

      {singleIncentive && showModal && (
        <ViewIncentiveScheme
          setSingleIncentive={setSingleIncentive}
          singleIncentive={singleIncentive}
          setShowModal={setShowModal}
        />
      )}
      {inputData?.schemeType?.value > 9 && showModal && (
        <OtherIncentive
          inputData={inputData}
          getConfigListAPi={handleSubmitModal}
          setInputData={setInputData}
          setShowModal={setShowModal}
          header={inputData?.schemeType.label}
        />
      )}
      {(inputData?.schemeType?.value === 1 ||
        inputData?.schemeType?.value === 9) &&
        showModal && (
          <PPEIcentive
            inputData={inputData}
            getConfigListAPi={handleSubmitModal}
            setInputData={setInputData}
            setShowModal={setShowModal}
          />
        )}
      {inputData?.schemeType?.value === 2 && showModal && (
        <CPAIcentive
          inputData={inputData}
          getConfigListAPi={handleSubmitModal}
          setInputData={setInputData}
          setShowModal={setShowModal}
        />
      )}
      {inputData?.schemeType?.value === 3 && showModal && (
        <SchoolRcIncentive
          inputData={inputData}
          getConfigListAPi={handleSubmitModal}
          setInputData={setInputData}
          setShowModal={setShowModal}
        />
      )}
      {inputData?.schemeType?.value === 4 && showModal && (
        <DripIncentive
          inputData={inputData}
          getConfigListAPi={handleSubmitModal}
          setInputData={setInputData}
          setShowModal={setShowModal}
        />
      )}
      {inputData?.schemeType?.value === 5 && showModal && (
        <SolarIncentive
          inputData={inputData}
          getConfigListAPi={handleSubmitModal}
          setInputData={setInputData}
          setShowModal={setShowModal}
        />
      )}
      {inputData?.schemeType?.value === 6 && showModal && (
        <GManureScheme
          inputData={inputData}
          getConfigListAPi={handleSubmitModal}
          setInputData={setInputData}
          setShowModal={setShowModal}
        />
      )}
      {inputData?.schemeType?.value === 7 && showModal && (
        <PPEScheme
          inputData={inputData}
          getConfigListAPi={handleSubmitModal}
          setInputData={setInputData}
          setShowModal={setShowModal}
        />
      )}
      {inputData?.schemeType?.value === 8 && showModal && (
        <BioFertilizerAmount
          inputData={inputData}
          getConfigListAPi={handleSubmitModal}
          setInputData={setInputData}
          setShowModal={setShowModal}
        />
      )}
    </PageLayout>
  );
};

export default IncentiveSchemeTypeList;
