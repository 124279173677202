import React from "react";
import { ClipLoader } from "react-spinners";
import Modal from "react-bootstrap/Modal";
import { Button, CircularProgress, styled } from "@mui/material";
import { Cancel, Delete } from "@mui/icons-material";

const CustomDeleteModal = styled(Modal)({
  "& .modal-content": { borderRadius: "0.2rem" },
  "& .modal-footer": {
    padding: "0.3rem",
  },
  "& .modal-header": {
    padding: "0.8rem",
    borderRadius: 0,
    background: "#ed213a",
    background: "-webkit-linear-gradient(to right, #93291e, #ed213a)",
    background: "linear-gradient(to right, #93291e, #ed213a)",
    color: "#fff",
  },
});

const DeleteModal = ({
  setDeleteModal,
  handleDeleteRequest,
  deleteModal,
  deleteLoading,
  centered = false,
}) => {
  return (
    <CustomDeleteModal
      show={deleteModal}
      onHide={() => setDeleteModal(false)}
      backdrop="static"
      keyboard={false}
      centered={centered}
      aria-labelledby={`contained-modal-title`}
    >
      <Modal.Header className="otp-modal-header" closeButton>
        <h5 className="modal-title" id="exampleModalLabel">
          Confirm Delete
        </h5>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body otp-modal-body d-flex justify-content-start align-items-center">
          <img
            src={require("../assests/Images/Bin.gif")}
            alt="Delete"
            height={50}
          />
          <h6 className="mb-0">Are you sure you want to delete this record?</h6>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end align-items-center w-100 gap-3">
          <Button
            className="custom-btn-page-layout custom-btn-grey"
            data-dismiss="modal"
            aria-label="CLose"
            startIcon={<Cancel />}
            onClick={() => setDeleteModal(false)}
          >
            {`Cancel`}
          </Button>
          <Button
            className="custom-btn-page-layout custom-btn-red text-white"
            data-dismiss="modal"
            aria-label="Close"
            disabled={deleteLoading}
            startIcon={
              deleteLoading ? (
                <CircularProgress size={15} color="secondary" />
              ) : (
                <Delete />
              )
            }
            onClick={() => handleDeleteRequest()}
          >
            {`Delete`}
          </Button>
        </div>
      </Modal.Footer>
    </CustomDeleteModal>
  );
};

export default DeleteModal;
