import React, {useEffect,useState } from "react";
import PageLayout from "../../Component/PageLayout";
import {
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// eslint-disable-next-line
import { useLocation, useNavigate } from "react-router-dom";

const ViewRole = () => {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const [subopen, setSubOpen] = React.useState(false);
  const [inputData, setInputData] = useState({
    roleName: location?.state?.roleName,
    roleId: location?.state?.id,
    moduleData: location?.state?.permissions,
  });

 function RowColumn({ row, index }) {
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell component="th" scope="row">
            {row?.moduleData?.length > 0 && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen({ id: index, status: !open?.status })}
              >
                {open?.id === index && open?.status ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            )}
            {row.mainModuleName}
          </TableCell>
          {!row?.moduleData?.length > 0 && (
            <TableCell align="center">
              <FormGroup
                switch
                className="justify-content-center align-items-center d-flex"
              >
                <Input
                  type="switch"
                  checked={row?.permission}
                  name="permission"
                 disabled
                />
              </FormGroup>
            </TableCell>
          )}
          {!row?.moduleData?.length > 0 && (
            <TableCell align="center">
              <FormGroup
                switch
                className="justify-content-center align-items-center d-flex"
              >
                <Input
                  type="switch"
                  checked={row?.create}
                  
                  name="create"
                 disabled
                />
              </FormGroup>
            </TableCell>
          )}
          {!row?.moduleData?.length > 0 && (
            <TableCell align="center">
              <FormGroup
                switch
                className="justify-content-center align-items-center d-flex"
              >
                <Input
                  type="switch"
                  checked={row?.edit}
                  
                  name="edit"
                 disabled
                />
              </FormGroup>
            </TableCell>
          )}
          {!row?.moduleData?.length > 0 && (
            <TableCell align="center">
              <FormGroup
                switch
                className="justify-content-center align-items-center d-flex"
              >
                <Input
                  type="switch"
                  checked={row?.print}
                  
                  name="print"
                 disabled
                />
              </FormGroup>
            </TableCell>
          )}
          {!row?.moduleData?.length > 0 && (
            <TableCell align="center">
              <FormGroup
                switch
                className="justify-content-center align-items-center d-flex"
              >
                <Input
                  type="switch"
                  checked={row?.delete}
                  
                  name="delete"
                 disabled
                />
              </FormGroup>
            </TableCell>
          )}
        </TableRow>
        {row?.moduleData?.length > 0 && (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse
                in={open?.id === index && open?.status}
                timeout="auto"
                unmountOnExit
              >
                <Box sx={{ margin: 1 }}>
                  {/* <Typography variant="h6" gutterBottom component="div">
                    SubModule
                  </Typography> */}
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Module Name</TableCell>
                        <TableCell align="center">Permission</TableCell>
                        <TableCell align="center">Create</TableCell>
                        <TableCell align="center">Edit</TableCell>
                        <TableCell align="center">Print</TableCell>
                        <TableCell align="center">Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row?.moduleData?.map((historyRow, j) => (
                        <>
                          <TableRow key={j}>
                            {/* <TableCell component="th" scope="row">
                            {historyRow.moduleName}
                          </TableCell> */}
                          </TableRow>
                          {row?.moduleData?.length > 0 && (
                            <RowSubColumn
                              key={historyRow.moduleName}
                              row={historyRow}
                              index={index}
                              j={j}
                            />
                          )}
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  }

  function RowSubColumn({ row, index, j }) {
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell component="th" scope="row">
            {row?.subModuleData?.length > 0 && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() =>
                  setSubOpen({ id: index, status: !subopen?.status })
                }
              >
                {subopen?.id === index && subopen?.status ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            )}
            {row.moduleName}
          </TableCell>
          {!row?.subModuleData?.length > 0 && (
            <TableCell align="center">
              <FormGroup
                switch
                className="justify-content-center align-items-center d-flex"
              >
                <Input
                  type="switch"
                  checked={row?.permission}
                  name="permission"
                  disabled
                />
              </FormGroup>
            </TableCell>
          )}
          {!row?.subModuleData?.length > 0 && (
            <TableCell align="center">
              <FormGroup
                switch
                className="justify-content-center align-items-center d-flex"
              >
                <Input
                  type="switch"
                  checked={row?.create}
                  
                  name="create"
                  disabled
                />
              </FormGroup>
            </TableCell>
          )}
          {!row?.subModuleData?.length > 0 && (
            <TableCell align="center">
              <FormGroup
                switch
                className="justify-content-center align-items-center d-flex"
              >
                <Input
                  type="switch"
                  checked={row?.edit}
                  
                  name="edit"
                  disabled
                />
              </FormGroup>
            </TableCell>
          )}
          {!row?.subModuleData?.length > 0 && (
            <TableCell align="center">
              <FormGroup
                switch
                className="justify-content-center align-items-center d-flex"
              >
                <Input
                  type="switch"
                  checked={row?.print}
                  
                  name="print"
                  disabled
                />
              </FormGroup>
            </TableCell>
          )}
          {!row?.subModuleData?.length > 0 && (
            <TableCell align="center">
              <FormGroup
                switch
                className="justify-content-center align-items-center d-flex"
              >
                <Input
                  type="switch"
                  checked={row?.delete}
                  
                  name="delete"
                  disabled
                />
              </FormGroup>
            </TableCell>
          )}
        </TableRow>
        {row?.subModuleData?.length > 0 && (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse
                in={subopen?.id === index && subopen?.status}
                timeout="auto"
                unmountOnExit
              >
                <Box sx={{ margin: 1 }}>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Sub Module Name</TableCell>
                        <TableCell align="center">Permission</TableCell>
                        <TableCell align="center">Create</TableCell>
                        <TableCell align="center">Edit</TableCell>
                        <TableCell align="center">Print</TableCell>
                        <TableCell align="center">Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row?.subModuleData?.map((historyRow, k) => (
                        <TableRow key={k}>
                          <TableCell component="th" scope="row">
                            {historyRow.subModuleName}
                          </TableCell>
                          {row?.moduleData?.length > 0 ? (
                            <RowSubColumn
                              key={historyRow.moduleName}
                              row={historyRow}
                              index={index}
                              j={j}
                            />
                          ) : (
                            <>
                              <TableCell align="center">
                                <FormGroup
                                  switch
                                  className="justify-content-center align-items-center d-flex"
                                >
                                  <Input
                                    type="switch"
                                    checked={historyRow?.permission}
                                    name="permission"
                                    disabled
                                  />
                                </FormGroup>
                              </TableCell>
                              <TableCell align="center">
                                <FormGroup
                                  switch
                                  className="justify-content-center align-items-center d-flex"
                                >
                                  <Input
                                    type="switch"
                                    checked={historyRow?.create}
                                    
                                    name="create"
                                    disabled
                                  />
                                </FormGroup>
                              </TableCell>
                              <TableCell align="center">
                                <FormGroup
                                  switch
                                  className="justify-content-center align-items-center d-flex"
                                >
                                  <Input
                                    type="switch"
                                    checked={historyRow?.edit}
                                    
                                    name="edit"
                                    disabled
                                  />
                                </FormGroup>
                              </TableCell>
                              <TableCell align="center">
                                <FormGroup
                                  switch
                                  className="justify-content-center align-items-center d-flex"
                                >
                                  <Input
                                    type="switch"
                                    checked={historyRow?.print}
                                    
                                    name="print"
                                    disabled
                                  />
                                </FormGroup>
                              </TableCell>
                              <TableCell align="center">
                                <FormGroup
                                  switch
                                  className="justify-content-center align-items-center d-flex"
                                >
                                  <Input
                                    type="switch"
                                    checked={historyRow?.delete}
                                    
                                    name="delete"
                                    disabled
                                  />
                                </FormGroup>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  }


    useEffect(() => {
    if (location?.state?.permissions) {
      let arr = [];

      location?.state?.permissions?.forEach((er) => {
        if (er?.ModuleData?.length > 0) {
          let arr1 = [];

          er?.ModuleData?.forEach((ed) => {
            let arr4 = [];

            ed?.subModuleData?.forEach((qw) => {
              arr4.push({
                subModuleId: qw?.subModuleId,
                id: qw?.id,
                subModuleName: qw?.subModuleName,
                path: qw?.path,
                permission: qw?.access,
                create: qw?.create,
                edit: qw?.edit,
                print: qw?.print,
                delete: qw?.delete,
              });
            });

            arr1.push({
              moduleId: ed?.moduleId,
              id: ed?.id,
              moduleName: ed?.moduleName,
              subModuleData: arr4,
              permission: ed?.access,
              create: ed?.create,
              edit: ed?.edit,
              print: ed?.print,
              delete: ed?.delete,
            });
          });

          arr.push({
            mainModuleId: er?.mainModuleId,
            mainModuleName: er?.mainModuleName,
            moduleData: arr1,
            id: er?.id,
          });
        } else {
          arr.push({
            mainModuleId: er?.mainModuleId,
            id: er?.id,
            mainModuleName: er?.mainModuleName,
            permission: er?.access,
            create: er?.create,
            edit: er?.edit,
            print: er?.print,
            delete: er?.delete,
          });
        }
      });

      // Sort arr array by mainModuleId
      arr.sort((a, b) => a.mainModuleId - b.mainModuleId);

      setInputData({ ...inputData, moduleData: arr });
    }
    // eslint-disable-next-line
  }, [location?.state?.permissions]);

  return (
    <PageLayout title={"View Role"} backBtnTitle={"back"} backBtnTitleLink={"/rolemanagement"}>
      <FormGroup>
        <Row className="g-3" style={{ margin: 20 }}>
          <Col lg={6} md={6} className="d-flex">
            <Col md={3}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Role Name{" "}
              </Label>
            </Col>
            <Col sm={8} className="pl-5">
              <Input
                id="exampleEmail"
                name="roleName"
                disabled
                className="cust-input"
                type="text"
                value={inputData?.roleName}
              />
            </Col>
          </Col>
        </Row>
      </FormGroup>

      {/* Table View */}
      <div
          className="d-grid"
          style={{ margin: "0 20px", border: "1px solid #DEE2E6" }}
        >
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {/* <TableCell /> */}
                <TableCell>Main Module Name</TableCell>
                <TableCell align="center">Permission</TableCell>
                <TableCell align="center">Create</TableCell>
                <TableCell align="center">Edit</TableCell>
                <TableCell align="center">Print</TableCell>
                <TableCell align="center">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inputData?.moduleData?.map((row, index) => (
                <RowColumn key={row.name} row={row} index={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      </div>
    </PageLayout>
  );
};

export default ViewRole;
