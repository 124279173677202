import axios from "../../common/axios";
import { toast } from "react-toastify";
import { keys } from "../../config/keys";
import {
  BAG_MASTER_LIST,
  PRODUCT_GROUP_CATEGORY_LIST,
  PRODUCT_LIST,
} from "../constTypes";

const _get_product_list = (data) => {
  return {
    type: PRODUCT_LIST,
    payload: data,
  };
};

export const get_product_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/product/get_product_list`)
    .then((res) => {
      dispatch(_get_product_list(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

const _get_product_group_list = (data) => {
  return {
    type: PRODUCT_GROUP_CATEGORY_LIST,
    payload: data,
  };
};

export const get_plant_group_category = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/product/get_plant_group_category`)
    .then((res) => {
      dispatch(_get_product_group_list(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const get_bags_barcode_details = (id, callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/product/get_bags_barcode_details/${id}`)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const save_bags_barcode_details =
  (data, callback) => async (dispatch) => {
    await axios
      .post(`${keys().ServicePath}/api/product/save_bags_barcode_details`, data)
      .then((res) => {
        if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const save_variety_master = (data, callback) => async (dispatch) => {
  const url =
    data.id > 0
      ? `${keys().ServicePath}/api/product/update_variety_master`
      : `${keys().ServicePath}/api/product/save_variety_master`;

  await axios
    .post(url, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const delete_variety_master = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys().ServicePath}/api/product/delete_variety_master`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

const _get_bags_list = (data) => {
  return {
    type: BAG_MASTER_LIST,
    payload: data,
  };
};
export const get_bag_master_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/product/get_bag_master_list`)
    .then((res) => {
      dispatch(_get_bags_list(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const get_farming_product_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/product/get_farming_product_list`)
    .then((res) => {
      dispatch(_get_product_list(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};
