import React, { useEffect, useState } from "react";
import { Label, Input, Row, Col, FormGroup } from "reactstrap";
import { toast } from "react-toastify";
import CustomModal from "../../Component/CustomModal";
import CountryOption from "../../hooks/CountryOption";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import { useNavigate } from "react-router-dom";
import useBreakPoints from "../../hooks/useBreakPoints";
const EditState = ({ editModal, onCloseModal, onSubmitModal, singleData }) => {
  const { token } = useSelector((state) => state.layout);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useBreakPoints();
  const [loading, setLoading] = useState(false);
  const [countryArray, setCountryArray] = useState([]);
  const { getCountryOption } = CountryOption();

  useEffect(() => {
    getCountryOption().then((res) => {
      setCountryArray(res);
    });
    // eslint-disable-next-line
  }, []);

  const [inputData, setInputData] = useState({
    country: {
      label: singleData?.countryDetails?.name,
      value: singleData?.countryDetails?.id,
    },
    state: singleData?.stateName,
  });

  const [inputDataError, setInputDataError] = useState({
    country: false,
    state: false,
  });

  const nonNumericRegex = /^[A-Za-z]+$/;

  // handle OnChange Input
  const handleChangeInput = (name, value) => {
    if (name === "state") {
      if (nonNumericRegex.test(value) || value === "") {
        setInputData({ ...inputData, [name]: value });

        if (value?.length > 0) {
          setInputDataError({ ...inputDataError, state: false });
        } else {
          setInputDataError({ ...inputDataError, state: true });
        }
      } else {
        toast.error("Type characters only");
      }
    } else {
      if (name === "country") {
        setInputData({ ...inputData, [name]: value, state: "" });
        if (value?.label?.length > 0) {
          setInputDataError({ ...inputDataError, country: false });
        } else {
          setInputDataError({ ...inputDataError, country: true });
        }
      } else {
        setInputData({ ...inputData, [name]: value });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputData?.country?.label) {
      toast.error("Country is Required!");
      setInputDataError({ ...inputDataError, country: true });
    } else if (!inputData?.state) {
      toast.error("State is Required!");
      setInputDataError({ ...inputDataError, state: true });
    } else {
      setLoading(true);
      let formData = new FormData();

      formData.append("stateName", inputData.state);
      formData.append("countryId", inputData.country?.value);
      formData.append("id", singleData.id);

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}admin1/editstate`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setLoading(false);
          setInputData({
            countyCode: "",
            countryName: "",
          });
          onSubmitModal();
        } else {
          setLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  return (
    <CustomModal
      loading={loading}
      title={"Edit State"}
      handleSubmit={handleSubmit}
      modalState={editModal}
      onCloseModal={onCloseModal}
      editState={true}
    >
      {/* Country View */}
      <div>
        <FormGroup row className="form-grp-mb">
          <Row className="">
            <Col
              lg={12}
              md={12}
              sm={12}
              className={`d-flex g-3 ${isMobile ? "flex-column" : ""}`}
            >
              <Col md={4}>
                <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                  Country <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col sm={8} className={`${isMobile ? "" : "pl-4"}`}>
                <Select
                  isMulti={false}
                  onChange={(e) => {
                    handleChangeInput("country", e);
                  }}
                  isDisabled
                  value={inputData?.country}
                  name="country"
                  options={countryArray}
                  // menuPlacement="top"

                  className={`w-100 ${
                    inputDataError?.country ? "border border-danger" : ""
                  }`}
                ></Select>
              </Col>
            </Col>
          </Row>
        </FormGroup>
      </div>

      {/* State View  */}
      <div>
        <FormGroup row className="form-grp-mb">
          <Row className=" align-items-center">
            <Col
              lg={12}
              md={12}
              sm={12}
              className={`d-flex g-3 ${isMobile ? "flex-column" : ""}`}
            >
              <Col md={4}>
                <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                  State <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col sm={8} className={`${isMobile ? "" : "pl-4"}`}>
                <Input
                  id="exampleEmail"
                  value={inputData?.state}
                  name="state"
                  onChange={(e) =>
                    handleChangeInput(
                      e.target.name,
                      e.target.value.toUpperCase()
                    )
                  }
                  className={`cust-input ${
                    inputDataError?.state ? "border-danger " : ""
                  } `}
                  type="text"
                />
              </Col>
            </Col>
          </Row>
        </FormGroup>
      </div>
    </CustomModal>
  );
};

export default EditState;
