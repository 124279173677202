import React, { useEffect, useState } from "react";
import {
  Label,
  Input,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
import { toast } from "react-toastify";
import CustomModal from "../../Component/CustomModal";
import CountryOption from "../../hooks/CountryOption";
import Select from "react-select";
import StateOption from "../../hooks/StateOptions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import useBreakPoints from "../../hooks/useBreakPoints";
const EditDistrict = ({
  editModal,
  onCloseModal,
  onSubmitModal,
  singleData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useBreakPoints()
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.layout);

  const [countryArray, setCountryArray] = useState([]);
  const { getCountryOption } = CountryOption();
  const { getStateOption } = StateOption();
  const [stateArray, setStateArray] = useState([]);
  const [inputDataError, setInputDataError] = useState({
    country: false,
    state: false,
    distName: false,
  });
  useEffect(() => {
    getCountryOption().then((res) => {
      setCountryArray(res);
    });
    getStateOption(singleData?.countryDetails?.id).then((res) => {
      setStateArray(res);
    });
    // eslint-disable-next-line
  }, []);

  const [inputData, setInputData] = useState({
    country: {
      label: singleData?.countryDetails?.name,
      value: singleData?.countryDetails?.id,
    },
    state: {
      label: singleData?.stateDetails?.stateName,
      value: singleData?.stateDetails?.id,
    },
    distName: singleData?.districtName,
  });

  const nonNumericRegex = /^[A-Za-z]+$/;

  const handleChangeInput = (name, value) => {
    if (name === "distName") {
      if (nonNumericRegex.test(value) || value === "") {
        setInputData({ ...inputData, [name]: value });
      } else {
        toast.error("Type characters only");
      }

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, distName: false });
      } else {
        setInputDataError({ ...inputDataError, distName: true });
      }
    } else {
      if (name === "country") {
        getStateOption(value.value).then((res) => {
          setStateArray(res);
        });
        setInputData({ ...inputData, [name]: value, state: "", distName: "" });
        if (value?.label?.length > 0) {
          setInputDataError({ ...inputDataError, country: false });
        } else {
          setInputDataError({ ...inputDataError, country: true });
        }
      } else if (name === "state") {
        setInputData({ ...inputData, [name]: value, distName: "" });
        if (value?.label?.length > 0) {
          setInputDataError({ ...inputDataError, state: false });
        } else {
          setInputDataError({ ...inputDataError, state: true });
        }
      } else {
        setInputData({ ...inputData, [name]: value });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   
    if (!inputData.country) {
      toast.error("County is Required!");
      setInputDataError({ ...inputDataError, country: true });
    } else if (!inputData.state) {
      toast.error("State is Required!");
      setInputDataError({ ...inputDataError, state: true });
    } else if (!inputData.distName) {
      setInputDataError({ ...inputDataError, distName: true });

      toast.error("Dist. is Required!");
    } else {
      setLoading(true);
      let formData = new FormData();

      formData.append("countryId", inputData.country.value);
      formData.append("stateId", inputData.state.value);
      formData.append("districtName", inputData.distName);

      formData.append("id", singleData.id);

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}admin1/editdistrict`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setLoading(false);
          setInputData({
            countyCode: "",
            countryName: "",
          });
          onSubmitModal();
        } else {
          setLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  return (
    <CustomModal
      loading={loading}
      title={"Edit District"}
      handleSubmit={handleSubmit}
      modalState={editModal}
      onCloseModal={onCloseModal}
      editState={true}
    >
      <FormGroup>
        <Row className="g-3" style={{ margin: 20 }}>
          <Col md={12} className={`d-flex  ${isMobile ? "flex-column" : ""}`}>
            <Col md={3}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                County <span className="required_span"> * </span>
              </Label>
            </Col>
            <Col sm={9} className={`${isMobile ? "" : "pl-5"}`}>
              <Select
                isMulti={false}
                onChange={(e) => {
                  handleChangeInput("country", e);
                }}
                value={inputData?.country}
                options={countryArray}
                isDisabled
                // menuPlacement="top"
                name="choices-single-default"
                id="countryAddCompany"
                className={`w-100 ${
                  inputDataError?.country ? "border border-danger" : ""
                }`}
              ></Select>
            </Col>
          </Col>
        </Row>
        <Row className="g-3" style={{ margin: 20 }}>
          <Col md={12} className={`d-flex mx-0  ${isMobile ? "flex-column" : ""}`}>
            <Col md={3}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                State <span className="required_span"> * </span>
              </Label>
            </Col>
            <Col sm={9}>
              <Select
                isMulti={false}
                onChange={(e) => {
                  handleChangeInput("state", e);
                }}
                value={inputData?.state}
                isDisabled
                name="state"
                options={stateArray}
                // menuPlacement="top"

                className={`w-100 ${
                  inputDataError?.state ? "border border-danger" : ""
                }`}
              ></Select>
            </Col>
          </Col>
        </Row>

        <Row className="g-3" style={{ margin: 20 }}>
          <Col md={12} className={`d-flex  ${isMobile ? "flex-column" : ""}`}>
            <Col md={3}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Dist. Name <span className="required_span"> * </span>
              </Label>
            </Col>
            <Col sm={9} className={`${isMobile ? "" : "pl-5"}`}>
              <Input
                id="exampleEmail"
                name="distName"
                value={inputData?.distName}
                onChange={(e) => {
                  handleChangeInput(e.target.name, e.target.value.toUpperCase());
                }}
                className={`w-100 ${
                  inputDataError?.distName ? "border border-danger" : ""
                }`}
                type="text"
              />
            </Col>
          </Col>
        </Row>
      </FormGroup>
    </CustomModal>
  );
};

export default EditDistrict;
