import PageLayout from "../../Component/PageLayout";
import { Col, Form, Row } from "reactstrap";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Divider,
  FormHelperText,
  TextField,
} from "@mui/material";
import { useBagMaster } from "./useBagMaster";
import TableContainer from "../../Component/TableContainer";
import { textFieldProps } from "../../common/commonControlProps";
import { green, indigo, orange, pink } from "@mui/material/colors";
import { Cancel, QrCode } from "@mui/icons-material";
import LoaderComponent from "../../Component/LoaderComponent";
import { useNavigate } from "react-router-dom";

const BagMaster = () => {
  const navigate = useNavigate();
  const {
    loading,
    products,
    onDropDownChange,
    handleChange,
    formData,
    bagBarcodedetails,
    onSubmit,
    barlistColumn,
    bags,
    onCancelClick,
    showForm,
    setShowForm,
  } = useBagMaster();

  return (
    <PageLayout
      title={"Bag Master [Barcode Generation]"}
      loading={loading}
      addBtn={!showForm}
      backBtnTitle={showForm ? "Back to List" : "Generate Barcode"}
      onClick={() => setShowForm(!showForm)}
      showPrint
      printTitle="Barcode"
      onPrintClick={() => {
        const win = window.open("/printbarcode", "_blank");
        if (win != null) {
          win.focus();
        }
      }}
    >
      {showForm && (
        <Form className="m-4" onSubmit={onSubmit}>
          <Row>
            <Col md={4} sm={12}>
              <Autocomplete
                name="productid"
                size="small"
                options={products}
                value={formData.selectedProduct}
                onChange={onDropDownChange}
                getOptionDisabled={(options) =>
                  options.currentstock === null || options.currentstock <= 0
                }
                renderInput={(params) => (
                  <TextField {...params} {...textFieldProps} label="Product" />
                )}
              />
              <FormHelperText sx={{ fontWeight: "bolder", color: orange[900] }}>
                {`Current Stock: ${
                  formData.selectedProduct?.currentstock || "-"
                }`}
              </FormHelperText>
              <FormHelperText sx={{ fontWeight: "bolder", color: green[900] }}>
                {`Barcode Generated upto bag no: ${
                  bagBarcodedetails.length > 0
                    ? bagBarcodedetails[0].totalbarcodegenerated
                    : "-"
                }`}
              </FormHelperText>
            </Col>
            <Col md={2} sm={12}>
              <TextField
                name="height"
                onChange={handleChange}
                value={formData.height}
                label={`Height`}
                title=""
                {...textFieldProps}
              />
            </Col>
            <Col md={2} sm={12}>
              <TextField
                name="width"
                onChange={handleChange}
                value={formData.height}
                label={`Width`}
                title=""
                {...textFieldProps}
              />
            </Col>
            <Col md={2} sm={12}>
              <TextField
                name="weight"
                onChange={handleChange}
                value={formData.height}
                label={`Bag Weight (in grams)`}
                placeholder="Enter weight in grams"
                title=""
                {...textFieldProps}
              />
            </Col>
            <Col md={2} sm={12}>
              <TextField
                name="capacity"
                onChange={handleChange}
                value={formData.capacity}
                label={`Bag Capacity (in Kg)`}
                placeholder="Enter capacity in KG"
                title=""
                {...textFieldProps}
              />
            </Col>
          </Row>
          {formData.selectedProduct !== null && (
            <Card className="mt-2">
              <CardContent>
                <div
                  class="h6 pb-2 mb-2 border-bottom border-secondary border-2 fw-bold"
                  style={{ color: indigo[500] }}
                >
                  {`Generate Barcode`}
                </div>
                <Row>
                  <Col md={3} sm={12}>
                    <TextField
                      name="startno"
                      onChange={handleChange}
                      value={formData.startno}
                      label={`Bag Start #`}
                      placeholder="Start No"
                      title=""
                      {...textFieldProps}
                    />
                    <FormHelperText>
                      {`Last Bag No. of barcode generation: ${
                        bagBarcodedetails.length > 0
                          ? bagBarcodedetails[0].lastbagno
                          : `-`
                      }`}
                    </FormHelperText>
                  </Col>

                  <Col md={3} sm={12}>
                    <TextField
                      name="totalbags"
                      onChange={handleChange}
                      value={formData.totalbags}
                      label={`Total No of Bags`}
                      placeholder="Total bags"
                      title=""
                      helperText="No of bags should be smaller than pending value"
                      {...textFieldProps}
                      error={
                        formData.totalbags >
                        (bagBarcodedetails.length > 0 &&
                          bagBarcodedetails[0].pendingbarcodetogenerate)
                          ? true
                          : false
                      }
                    />
                  </Col>
                  <Col
                    md={3}
                    sm={12}
                    className="d-inline-flex align-self-center"
                  >
                    <Button
                      type="submit"
                      className="custom-btn-page-layout custom-btn-success"
                      fullWidth
                      startIcon={<QrCode />}
                    >{`Generate Barcode`}</Button>
                  </Col>
                  <Col
                    md={3}
                    sm={12}
                    className="d-inline-flex align-self-center"
                  >
                    <Button
                      type="button"
                      className="custom-btn-page-layout custom-btn-danger"
                      fullWidth
                      startIcon={<Cancel />}
                      onClick={onCancelClick}
                    >{`Cancel`}</Button>
                  </Col>
                </Row>
              </CardContent>
            </Card>
          )}
        </Form>
      )}
      {loading ? (
        <LoaderComponent />
      ) : (
        <div
          className="d-grid"
          style={{ margin: "10px 20px", border: "1px solid #DEE2E6" }}
        >
          <TableContainer
            columns={barlistColumn}
            data={Array.isArray(bags) ? [...bags] : []}
            isGlobalFilter={true}
            showColumnFilters={true}
            isAddUserList={false}
            tableClass={"table-sm"}
            divClass={"table-responsive"}
            showPagination={true}
            initialState={{ pageSize: 10, pageIndex: 0 }}
          />
        </div>
      )}
    </PageLayout>
  );
};

export default BagMaster;
