import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageLayout from "../../Component/PageLayout";
import { Input, InputGroup, InputGroupText, Col } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import TableContainer from "../../Component/TableContainer";
import { toast } from "react-toastify";
import DeleteModal from "../../Component/DeleteModal";
import CustomPagination from "../../Component/CustomPagination";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import { get_farmer_list } from "../../redux/actions/farmer";
import {
  Cancel,
  Check,
  Clear,
  Delete,
  Edit,
  Remove,
  Verified
} from "@mui/icons-material";
import { useFarmerHooks } from "./useFarmerHooks";
import {
  Button,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  styled
} from "@mui/material";
import { red } from "@mui/material/colors";

const DocumentVerification = () => {
  const {
    layout: { permissions, token },
    farmer_list
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [farmerList, setFarmerList] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(5); // default limit
  const [offset, setOffset] = useState(0); // default offset
  //const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  const { loading, farmerDataByIdAPi, setLoading } = useFarmerHooks();

  useEffect(() => {
    setLoading(true);
    dispatch(
      get_farmer_list({
        onSuccess: () => setLoading(false),
        onError: () => setLoading(false)
      })
    );
  }, []);

  // handleDelete Btn
  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    let formData = new FormData();
    formData.append("id", deleteId);
    formData.append("isDelete", "Yes");

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/deletefarmer`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: formData
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        // getFarmerListAPi(limit, offset);
        dispatch(get_farmer_list());
        setDeleteModal(false);
        toast?.success(_data?.message);
        setDeleteLoading(false);
      } else {
        setDeleteLoading(false);
        setDeleteModal(false);

        toast.error(_data?.message);
      }
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
    }
  };

  const getIconColor = (status) => {
    if (status === 1) return "success";
    else if (status === 2) return "warning";
    else return "error";
  };

  const column = useMemo(
    () => [
      {
        Header: "Farmer Code",
        accessor: "farmerCode",
        filterable: false
      },
      {
        Header: "Farmer Name",
        accessor: "name",
        filterable: false
      },
      {
        Header: "Mobile No",
        accessor: "mobileNo",
        filterable: false
      },

      {
        Header: "Village",
        accessor: "villageName",
        filterable: false
      },
      {
        Header: "Document Status",

        columns: [
          {
            Header: "Pending Docs",
            accessor: "pendingdocs",
            Filter: false,
            style: {
              "text-align": "center",
              "vertical-align": "middle"
            },
            // Cell: (cellProps) =>
            //   cellProps.row.original.documentsubmitted ? (
            //     <Check fontSize="medium" color="success" />
            //   ) : (
            //     <Clear fontSize="medium" color="error" />
            //   ),
            Cell: (cellProps) => {
              return cellProps.value > 0 ? (
                <Tooltip
                  title={
                    <List>
                      {cellProps.row.original.pendingdocuments
                        .split(",")
                        ?.map((x) => (
                          <ListItem style={{ padding: 0 }}>
                            <ListItemIcon>
                              <Cancel sx={{ color: red[500] }} />
                            </ListItemIcon>
                            <ListItemText>{x}</ListItemText>
                          </ListItem>
                        ))}
                    </List>
                  }
                  placement="left"
                >
                  {/* <Button variant="text" size="small">
                    <Typography variant="body1">{cellProps.value}</Typography>{" "}
                  </Button> */}
                  <Chip
                    label={cellProps.value}
                    variant="filled"
                    color={cellProps.value > 2 ? "error" : "warning"}
                    size="small"
                  />
                </Tooltip>
              ) : (
                <Remove fontSize="medium" />
              );
            }
          },
          {
            Header: "Submitted",
            accessor: "documentsubmitted",
            Filter: false,
            style: {
              "text-align": "center",
              "vertical-align": "middle"
            },
            Cell: (cellProps) =>
              cellProps.row.original.documentsubmitted ? (
                <Check fontSize="medium" color="success" />
              ) : (
                <Clear fontSize="medium" color="error" />
              )
          },
          {
            Header: "Verified",
            accessor: "documentverified",
            Filter: false,
            style: {
              "text-align": "center",
              "vertical-align": "middle"
            },
            Cell: (cellProps) => {
              return cellProps.row.original.documentverified >= 0 ? (
                <Verified
                  fontSize="medium"
                  color={getIconColor(cellProps.row.original.documentverified)}
                />
              ) : (
                <Remove fontSize="medium" />
              );
            }
          }
        ]
      },
      {
        id: "Action",
        Header: "",
        style: {
          "text-align": "center",
          "vertical-align": "middle"
        },
        Cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  farmerDataByIdAPi(
                    cellProps.row.original.id,
                    "/farmerdocuments",
                    cellProps.row.original.ownership
                  );
                }}
              >
                <Edit />
              </IconButton>

              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  setDeleteModal(true);
                  setDeleteId(cellProps.row.original.id);
                }}
              >
                <Delete />
              </IconButton>
            </div>
          );
        }
      }
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <PageLayout title={"Document Verification"} loading={loading}>
      <div className="mx-2 my-4">
        {loading ? (
          ""
        ) : (
          <div
            className="d-grid"
            style={{
              margin: "0 20px 20px 20px",
              border: "1px solid #DEE2E6"
            }}
          >
            <TableContainer
              columns={column}
              data={farmer_list}
              isGlobalFilter={true}
              isAddUserList={false}
              showColumnFilters={true}
              showPagination={true}
              tableClass={"table-sm"}
              divClass={"table-responsive"}
            />

            {/* <CustomPagination
                apiCallFunction={getFarmerListAPi}
                rowDetails={farmerList}
                totalCount={totalCount}
                limit={limit}
                offset={offset}
                setLimit={setLimit}
                setOffset={setOffset}
              /> */}
          </div>
        )}

        <DeleteModal
          deleteModal={deleteModal}
          handleDeleteRequest={handleDeleteRequest}
          setDeleteModal={setDeleteModal}
          deleteLoading={deleteLoading}
        />
        {/* </Col> */}
      </div>
    </PageLayout>
  );
};

export default DocumentVerification;
