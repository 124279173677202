import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { farmerAllocationValidationSchema } from "./validationSchema";
import dayjs from "dayjs";
import { keys } from "../../../config/keys";
import {
  farmerActions,
  employeeActions,
  commonActions,
} from "../../../redux/actions";
import { usePermissionHooks } from "../../../common/useCheckPermission";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const initialValues = {
  id: 0,
  voucherno: "",
  voucher: "",
  invoicenumber: "",
  invoicedatestring: dayjs(Date()).format(keys().dbDateFormat),
  invoicedate: dayjs(Date()),
  vendorid: 0,
  selectedvendor: null,
  selectedPO: null,
  gstno: "",
  purchaseOrderId: "",
  podetails: [],
  lastinvoicenumber: "",
};

export const useFarmerAllocationListHooks = (id = 0) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { employee_farmer_list } = useSelector((obj) => obj);

  const permission = usePermissionHooks(location);

  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const {
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(farmerAllocationValidationSchema),
  });

  const get_employee_farmer_allocation_list = () =>
    dispatch(employeeActions.get_employee_farmer_list({}));

  const onGridDelete = async () => {
    setDeleteLoading(true);
    if (deleteId > 0) {
      dispatch(
        employeeActions.delete_employee_farmer_village_allocation(
          { id: deleteId },
          {
            onSuccess: () => {
              toast.success("Farmer Allocation Deleted Successfully!!!");
              get_employee_farmer_allocation_list();
            },
            onError: (err) =>
              toast.error("Something went wrong. Please try again."),
          }
        )
      );
      setDeleteLoading(false);
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    get_employee_farmer_allocation_list();
  }, []);

  return {
    permission,
    deleteModal,
    onGridDelete,
    deleteLoading,
    setDeleteModal,
    setDeleteId,
    employee_farmer_list,
  };
};

const initialValuesFarmerAllocation = {
  id: 0,
  employeeid: 0,
  selectedemployee: null,
  unitid: 0,
  selectedunit: null,
  selectedRows: {},
  selectedRowsArray: [],
};

export const useFarmerAllocationHooks = (id = 0) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialValuesFarmerAllocation);
  const location = useLocation();

  const {
    farmer_allocation_list,
    geo_location_list,
    employeeDropdownList,
    companyunitlist,
    farmerDropdownList,
  } = useSelector((obj) => obj);

  const permission = usePermissionHooks(location);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(farmerAllocationValidationSchema),
  });

  const ondropdownchange1 = (e, selectedOption, id_name, value_name) => {
    setFormData((prev) => ({
      ...prev,
      [id_name]:
        selectedOption.length > 0 ? selectedOption.map((x) => x.id) : [],
      [value_name]: selectedOption || [],
    }));
  };

  const ondropdownchange = (e, selectedOption, id_name, value_name) => {
    setFormData((prev) => ({
      ...prev,
      [id_name]: selectedOption?.id || 0,
      [value_name]: selectedOption || null,
    }));
  };

  const onRowSelection = (selectedRow) => {
    console.log("Don't do this..", selectedRow);
    setFormData((prev) => ({
      ...prev,
      selectedRows: { ...selectedRow, ...prev.selectedRows },
      selectedRowsArray: Object.keys(selectedRow),
    }));
  };

  const get_farmerDropdownList = () =>
    dispatch(farmerActions.getFarmerDropdownList());

  const get_technician_list = () =>
    dispatch(employeeActions.getEmployeeDropdownList());

  const get_companyunitList = () =>
    dispatch(commonActions.get_company_unit_list());

  const get_farmer_allocation_by_id = (id = 0) =>
    dispatch(
      employeeActions.get_employee_farmer_list_by_id(id, {
        onSuccess: async (res) => {
          const { id, employeeid, companyunitid } = res.master[0];

          let selectedRow = {};
          await res.tran.map((x) => {
            return (selectedRow[x.farmerid] = true);
          });
          setFormData((prev) => ({
            ...prev,
            id,
            employeeid,
            unitid: companyunitid,
            selectedemployee: employeeDropdownList?.find(
              (x) => x.id === employeeid
            ),
            selectedunit: companyunitlist.find((x) => x.id == companyunitid),
            selectedRows: { ...selectedRow },
            selectedRowsArray: [...Object.keys(selectedRow)],
          }));
        },
      })
    );

  useEffect(() => {
    get_technician_list();
    get_companyunitList();
    get_farmerDropdownList();
  }, []);

  useEffect(() => {
    if (location.state.id > 0 && employeeDropdownList.length > 0) {
      get_farmer_allocation_by_id(location.state.id);
    }
  }, [location, employeeDropdownList]);

  const onSubmit = () => {
    const d = {
      id: formData.id,
      employeeid: formData.employeeid,
      companyunitid: formData.unitid,
      farmerids: formData.selectedRowsArray,
      farmerids1: formData.selectedRowsArray.join(","),
    };

    dispatch(
      employeeActions.save_employee_farmer_village_allocation(d, {
        onSuccess: (res) => {
          toast(`Allocation Successfully ${d.id > 0 ? `Updated` : `Inserted`}`);
          navigate("/farmerallocationlist");
        },
        onError: (e) => toast(e.toString()),
      })
    );
  };

  return {
    formData,
    setFormData,
    initialValues,
    register,
    handleSubmit,
    errors,
    control,
    onSubmit,
    ondropdownchange,
    ondropdownchange1,
    geo_location_list,
    permission,
    // get_farmer_allocation,
    farmer_allocation_list,
    employeeDropdownList,
    farmerDropdownList,
    companyunitlist,
    onRowSelection,
  };
};
