export const textFieldProps = {
  variant: "standard",
  size: "small",
  fullWidth: true,
};

export const containedTextFieldProps = {
  variant: "contained",
  size: "small",
  fullWidth: true,
};
