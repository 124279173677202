import { toast } from "react-toastify";
import axios from "../../common/axios";
import { keys } from "../../config/keys";
import {
  DASH_AVERAGE_YIELD_ESTIMATED,
  DASH_BAGS_ISSUED,
  DASH_FARMER_DISTRICT_WISE,
  DASH_FARMER_TALUKA_WISE,
  DASH_FARMER_VILLAGE_WISE,
  DASH_VARIETY_WISE_LAND_SIZE,
  DASH_VILLAGE_WISE_VARIETY,
  DASH_VILLAGE_WISE_VARIETY_DRILL_DATA,
  DASH_VILLAGE_WISE_YIELD_ESTIMATION,
  DASH_YIELD_ESTIMATED,
} from "../constTypes";

const _dashboard = (type, payload) => {
  return {
    type,
    payload,
  };
};
export const get_farmers_village_taluka_wise =
  (callback) => async (dispatch) => {
    await axios
      .get(
        `${keys().ServicePath}/api/dashboard/get_farmers_village_taluka_wise`
      )
      .then((res) => {
        dispatch(_dashboard(DASH_FARMER_DISTRICT_WISE, res.data.rows[0]));
        dispatch(_dashboard(DASH_FARMER_TALUKA_WISE, res.data.rows[1]));
        dispatch(_dashboard(DASH_FARMER_VILLAGE_WISE, res.data.rows[2]));
        if (callback?.onSuccess) callback.onSuccess(res.data.rows);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const get_bags_issued = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/dashboard/get_bags_issued`)
    .then((res) => {
      dispatch(_dashboard(DASH_BAGS_ISSUED, res.data.rows));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const get_yield_estimated = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/dashboard/get_yield_estimation`)
    .then((res) => {
      dispatch(_dashboard(DASH_YIELD_ESTIMATED, res.data.rows));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const get_average_yield_estimated = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/dashboard/get_average_yield_estimation`)
    .then((res) => {
      dispatch(_dashboard(DASH_AVERAGE_YIELD_ESTIMATED, res.data.rows));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const get_variety_wise_land_size = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/dashboard/get_variety_wise_land_size`)
    .then((res) => {
      dispatch(_dashboard(DASH_VARIETY_WISE_LAND_SIZE, res.data.rows));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const get_village_wise_variety = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/dashboard/get_variety_village_wise`)
    .then((res) => {
      const masterData = res.data.rows[0];
      const drillDown = res.data.rows[1];
      const drillData = masterData.map((x) => {
        return {
          ...x,
          id: x.name,
          data: drillDown.filter((y) => y.villageName === x.name),
        };
      });

      dispatch(_dashboard(DASH_VILLAGE_WISE_VARIETY, res.data.rows[0]));
      dispatch(_dashboard(DASH_VILLAGE_WISE_VARIETY_DRILL_DATA, drillData));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const get_variety_wise_yield_estimation =
  (callback) => async (dispatch) => {
    await axios
      .get(
        `${keys().ServicePath}/api/dashboard/get_variety_wise_yield_estimation`
      )
      .then((res) => {
        dispatch(_dashboard(DASH_VILLAGE_WISE_YIELD_ESTIMATION, res.data.rows));
        if (callback?.onSuccess) callback.onSuccess(res.data.rows);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };
