import React, { useCallback, useEffect, useMemo, useState } from "react";
// import useBreakPoints from "../../hooks/useBreakPoints";
import PageLayout from "../../../Component/PageLayout";

import CustomAutoComplete from "../../../Component/CustomAutoComplete";
import { Col, Input, InputGroup, InputGroupText, Row, Label } from "reactstrap";
import { logOutRequest } from "../../../store/reducers/layoutReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import TableContainer from "../../../Component/TableContainer";
import CustomPagination from "../../../Component/CustomPagination";
import DeleteModal from "../../../Component/DeleteModal";
// import Select from "react-select";
import { TextField, Autocomplete } from "@mui/material";
import Flatpickr from "react-flatpickr";
import EditPriceList from "./EditPriceList";
// const { isMobile } = useBreakPoints();

import { usePriceListHooks } from "./usePriceListHooks";

const AddPriceList = () => {
  const { permissions, token, loginDetails } = useSelector(
    (state) => state.layout
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pricelistgroupDropdownList } = usePriceListHooks();

  let permissionsObj = {};
  // eslint-disable-next-line
  permissions?.filter((er) => {
    if (er?.ModuleData?.length > 0) {
      // eslint-disable-next-line
      let filtererdDarta = er?.ModuleData?.filter((ed) => {
        if (ed?.subModuleData?.length > 0) {
          // eslint-disable-next-line
          ed?.subModuleData?.map((qw) => {
            if (qw?.path === "/pricelist") {
              permissionsObj = qw;
            }
          });
        }
      });
      return filtererdDarta;
    }
  });

  let initialState = {};

  if (!permissionsObj?.edit && !permissionsObj?.delete) {
    initialState.hiddenColumns = ["Action"];
  }

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [unitOptions, setUnitOptions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(5); // default limit
  const [offset, setOffset] = useState(0); // default offset
  const [counter, setCounter] = useState(0); // default offset
  const [deleteModal, setDeleteModal] = useState(false);
  const [productOptions, setProductOptions] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [singleData, setSingleData] = useState();
  let todayDate = new Date();
  const [inputData, setInputData] = useState({
    selectedPricelist: null,
    priceListGroupId: "",
    priceListName: "",
    unitId: "",
    productId: "",
    ratePerUnit: "",
    productName: "",
    unitOfMeasure: "",
    effectiveDate:
      todayDate.getFullYear() +
      "-" +
      (todayDate.getMonth() + 1) +
      "-" +
      todayDate.getDate(),
    vendorId: "",
  });
  const [inputDataError, setInputDataError] = useState({
    priceListName: "",
    unitId: "",
    productId: "",
    ratePerUnit: "",
    effectiveDate: "",
    vendorId: "",
  });
  // console.log("inputData: ", inputData);

  const getUnitOptionsListAPi = useCallback(async () => {
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("companyId", loginDetails?.companyId);

      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}company/unitsbycompany`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];
        // eslint-disable-next-line
        _data?.unitsData[0]?.unitdetails?.map((er) => {
          arr.push({ label: er?.unitName, value: er.id });
        });
        setUnitOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, []);

  const getProductListAPi = useCallback(async () => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/productdrop`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];
        // eslint-disable-next-line
        _data?.productList?.map((er) => {
          arr.push({ label: er?.productCode, value: er });
        });
        setProductOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  const getVendorListAPi = useCallback(async () => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/vendordrop`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];
        // eslint-disable-next-line
        _data?.vendorDropList?.map((er) => {
          arr.push({ label: er?.vendorName, value: er?.id });
        });

        setVendorOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  const getPriceListAPi = useCallback(async (limit, offset) => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/pricelist?limit=${limit}&offset=${offset}&companyId=${loginDetails?.companyId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        setPriceList(_data?.priceList);
        setTotalCount(_data?.paginationDetails?.count);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUnitOptionsListAPi();
    getProductListAPi();
    getVendorListAPi();
    getPriceListAPi(limit, offset);
    // eslint-disable-next-line
  }, [getUnitOptionsListAPi, getProductListAPi, getVendorListAPi]);

  const handleChangeInput = (name, value) => {
    setInputData({ ...inputData, [name]: value });
    if (value?.length > 0) {
      setInputDataError({ ...inputDataError, [name]: false });
    } else {
      setInputDataError({ ...inputDataError, [name]: true });
    }
  };

  const handleChangePricelistGroup = (e, selectedOption) => {
    // console.log("selectedOption:", selectedOption)
    setInputData({
      ...inputData,
      ["priceListName"]: selectedOption?.priceListName || "",
      ["priceListGroupId"]: selectedOption?.id || "",
      ["selectedPricelist"]: selectedOption,
    });
    if (selectedOption?.id > 0) {
      setInputDataError({ ...inputDataError, ["priceListName"]: false });
    } else {
      setInputDataError({ ...inputDataError, ["priceListName"]: true });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputData.priceListName) {
      toast.error("Price List Name is Required!");
      setInputDataError({ ...inputDataError, priceListName: true });
    } else if (!inputData.unitId) {
      toast.error("Loaction Name is Required!");
      setInputDataError({ ...inputDataError, unitId: true });
    } else if (!inputData.productId) {
      toast.error("Product Code is Required!");
      setInputDataError({ ...inputDataError, productId: true });
    } else if (!inputData.ratePerUnit) {
      toast.error("Rate Per Unit is Required!");
      setInputDataError({ ...inputDataError, ratePerUnit: true });
    } else if (!inputData.effectiveDate) {
      toast.error("Effective Date is Required!");
      setInputDataError({ ...inputDataError, effectiveDate: true });
    } else {
      setSubmitLoading(true);
      let formData = new FormData();
      formData.append("priceListGroupId", inputData.priceListGroupId);
      formData.append("priceListName", inputData.priceListName);
      formData.append("unitId", inputData.unitId);
      formData.append("productId", inputData.productId);
      formData.append("ratePerUnit", inputData.ratePerUnit);
      formData.append("effectiveDate", inputData.effectiveDate);
      if (inputData.vendorId) {
        formData.append("vendorId", inputData.vendorId);
      }
      formData.append("financialYearId", "1");
      formData.append("cropyearId", "1");

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/addpricelist`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setSubmitLoading(false);
          setInputData({
            priceListName: "",
            unitId: "",
            productId: "",
            ratePerUnit: "",
            effectiveDate:
              todayDate.getFullYear() +
              "-" +
              (todayDate.getMonth() + 1) +
              "-" +
              todayDate.getDate(),
            vendorId: "",
            unitOfMeasure: "",
            productName: "",
          });
          getPriceListAPi(limit, offset);
          setCounter(counter + 1);
        } else {
          setSubmitLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setSubmitLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  //   handleDelete Btn
  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    let formData = new FormData();
    formData.append("id", deleteId);
    formData.append("isDelete", "Yes");

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/deletepricelist`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        getPriceListAPi(limit, offset);
        setDeleteModal(false);
        toast?.success(_data?.message);
        setDeleteLoading(false);
      } else {
        setDeleteLoading(false);
        setDeleteModal(false);

        toast.error(_data?.message);
      }
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
    }
  };

  const column = useMemo(
    () => [
      {
        Header: "Price List Name",
        accessor: "priceListName",
        filterable: false,
      },
      {
        Header: "Location Name",
        accessor: "unitDetails.unitName",
        filterable: false,
      },
      {
        Header: "Product Code",
        accessor: "productDetails.partNo",
        filterable: false,
      },
      {
        Header: "UOM",
        accessor: "productDetails.unitOfMeasure",
        filterable: false,
      },
      {
        Header: "Rate",
        accessor: "ratePerUnit",
        filterable: false,
      },
      {
        Header: "Effective Date",
        accessor: "effectiveDate",
        filterable: false,
      },
      {
        Header: "Vendor name",
        accessor: "vendorDetails.vendorName",
        filterable: false,
      },

      {
        id: "Action",
        Header: () => (
          <span
            className="d-flex justify-content-center"
            style={{ textAlign: "center" }}
          >
            Action
          </span>
        ),
        Cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <div className="hstack gap-2">
                {/* Edit Button */}
                {permissionsObj?.edit && (
                  <button
                    className="border-0"
                    style={{ background: "transparent" }}
                    onClick={() => {
                      setEditModal(true);
                      setSingleData(cellProps.row.original);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_32_318)">
                        <path
                          d="M6.87549 2.5H2.50049C2.16897 2.5 1.85103 2.6317 1.6166 2.86612C1.38218 3.10054 1.25049 3.41848 1.25049 3.75V12.5C1.25049 12.8315 1.38218 13.1495 1.6166 13.3839C1.85103 13.6183 2.16897 13.75 2.50049 13.75H11.2505C11.582 13.75 11.9 13.6183 12.1344 13.3839C12.3688 13.1495 12.5005 12.8315 12.5005 12.5V8.125"
                          stroke="#3E7531"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.563 1.56249C11.8116 1.31385 12.1489 1.17416 12.5005 1.17416C12.8521 1.17416 13.1893 1.31385 13.438 1.56249C13.6866 1.81113 13.8263 2.14836 13.8263 2.49999C13.8263 2.85162 13.6866 3.18885 13.438 3.43749L7.50049 9.37499L5.00049 9.99999L5.62549 7.49999L11.563 1.56249Z"
                          stroke="#3E7531"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_32_318">
                          <rect
                            width="15"
                            height="15"
                            fill="white"
                            transform="translate(0.000488281)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                )}

                {/* Delete Button */}
                {permissionsObj?.delete && (
                  <button
                    className="border-0"
                    style={{ background: "transparent" }}
                    onClick={() => {
                      setDeleteModal(true);
                      setDeleteId(cellProps.row.original.id);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_32_330)">
                        <path
                          d="M6.63507 -0.0178833C5.50545 -0.0178833 4.59795 0.92077 4.59795 2.05558V2.30769H2.30814C1.99026 2.30769 1.73122 2.56673 1.73122 2.88462V3.46154H1.1543V4.61539H2.30814V13.2692C2.30814 14.2183 3.08987 15 4.03891 15H10.962C11.911 15 12.6928 14.2183 12.6928 13.2692V4.61539H13.8466V3.46154H13.2697V2.88462C13.2697 2.56673 13.0106 2.30769 12.6928 2.30769H10.403V2.055C10.403 0.920771 9.49545 -0.0178833 8.36584 -0.0178833H6.63507ZM6.63507 1.17173H8.36584C8.82968 1.17173 9.21334 1.55019 9.21334 2.055V2.30769H5.78699V2.055C5.78699 1.55019 6.17064 1.17231 6.63449 1.17231L6.63507 1.17173ZM3.46199 4.61539H6.41872C6.49026 4.62289 6.56122 4.63327 6.63507 4.63327H8.36584C8.43968 4.63327 8.51007 4.62289 8.58218 4.61539H11.5389V13.2692C11.5389 13.594 11.2868 13.8462 10.962 13.8462H4.03891C3.7141 13.8462 3.46199 13.594 3.46199 13.2692V4.61539ZM4.61584 5.76923V12.6923H5.76968V5.76923H4.61584ZM6.92353 5.76923V12.6923H8.07737V5.76923H6.92353ZM9.23122 5.76923V12.6923H10.3851V5.76923H9.23122Z"
                          fill="#EC0505"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_32_330">
                          <rect
                            width="15"
                            height="15"
                            fill="white"
                            transform="translate(0.000488281)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                )}
              </div>
            </div>
          );
        },
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  const onSubmitModal = () => {
    getPriceListAPi(limit, offset);
    setEditModal(false);
  };

  return (
    <PageLayout title={"Farmers Management- Price List"} loading={loading}>
      <div className="" style={{ margin: 40 }}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div>
            <Row className=" g-3 mt-2 ">
              <Col lg={3} md={12} sm={12}>
                {/* <TextField
                  id="outlined-basic"
                  label="Price List Name *"
                  fullWidth
                  name="priceListName"
                  size="small"
                  value={inputData?.priceListName}
                  error={inputDataError?.priceListName}
                  onChange={(e) => {
                    handleChangeInput(e.target.name, e.target.value);
                  }}
                  variant="outlined"
                  type="text"
                /> */}
                <Autocomplete
                  id="pricelistgroupid"
                  name="pricelistgroupid"
                  size="small"
                  options={pricelistgroupDropdownList}
                  value={inputData?.selectedPricelist}
                  onChange={(e, selectedOption) => {
                    handleChangePricelistGroup(e, selectedOption);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Pricelist Group *"
                      size="small"
                    />
                  )}
                />
              </Col>
              <Col lg={3} md={12} sm={12} className="d-flex">
                <CustomAutoComplete
                  name="unitId"
                  fullWidth
                  key={`unit-${unitOptions?.length}-${counter}`}
                  label="Loaction Name *"
                  value={
                    unitOptions.map(
                      (product) => product?.label === inputData?.unitId
                    )?.label
                  }
                  options={unitOptions.map((product) => product.label)}
                  size="small"
                  getOptionLabel={(option) => option}
                  onChange={(event, option) => {
                    let selectedData = unitOptions?.find(
                      (er) => er?.label === option
                    );
                    setInputData({ ...inputData, unitId: selectedData?.value });
                    setInputDataError({ ...inputDataError, unitId: false });
                  }}
                  error={inputDataError?.unitId}
                  renderOption={(props, option) => {
                    const { id, label } = unitOptions.filter(
                      (product) => product.label === option
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label}
                      </li>
                    );
                  }}
                />
              </Col>
              <Col lg={3} md={12} sm={12} className="d-flex">
                <CustomAutoComplete
                  name="productId"
                  fullWidth
                  key={`product-${productOptions?.length}-${counter}`}
                  label="Product Code *"
                  value={
                    productOptions.map(
                      (product) => product?.label === inputData?.productId
                    )?.label
                  }
                  options={productOptions.map((product) => product.label)}
                  size="small"
                  getOptionLabel={(option) => option}
                  onChange={(event, option) => {
                    let selectedData = productOptions?.find(
                      (er) => er?.label === option
                    );

                    setInputData({
                      ...inputData,
                      productId: selectedData?.value?.id,
                      productName: selectedData?.value?.productName,
                      unitOfMeasure: selectedData?.value?.unitOfMeasure,
                    });
                    setInputDataError({ ...inputDataError, productId: false });
                  }}
                  error={inputDataError?.productId}
                  renderOption={(props, option) => {
                    const { id, label } = productOptions.filter(
                      (product) => product.label === option
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label}
                      </li>
                    );
                  }}
                />
              </Col>
              <Col lg={3} md={12} sm={12} className="d-flex">
                <TextField
                  id="outlined-basic"
                  label="Product Name *"
                  fullWidth
                  disabled
                  name="productName"
                  size="small"
                  value={inputData?.productName}
                  variant="outlined"
                />
              </Col>
            </Row>
          </div>
          <div>
            <Row className=" g-3 mt-2 ">
              <Col lg={3} md={12} sm={12} className="d-flex">
                <TextField
                  id="outlined-basic"
                  label="Unit Of Measure"
                  fullWidth
                  disabled
                  size="small"
                  value={inputData?.unitOfMeasure}
                  variant="outlined"
                />
              </Col>
              <Col lg={3} md={12} sm={12} className="d-flex">
                <TextField
                  id="outlined-basic"
                  label="Rate Per Unit *"
                  fullWidth
                  name="ratePerUnit"
                  size="small"
                  value={inputData?.ratePerUnit}
                  error={inputDataError?.ratePerUnit}
                  onChange={(e) => {
                    handleChangeInput(e.target.name, e.target.value);
                  }}
                  variant="outlined"
                  type="number"
                />
              </Col>
              <Col lg={3} md={12} sm={12} className="d-flex">
                <Flatpickr
                  className="form-control"
                  options={{
                    dateFormat: "Y-m-d",
                    minDate: new Date().fp_incr(-3),
                  }}
                  value={inputData?.effectiveDate}
                  onChange={(date, dateStr) => {
                    setInputData({ ...inputData, effectiveDate: dateStr });
                  }}
                />
              </Col>
              <Col lg={3} md={12} sm={12} className="d-flex">
                <CustomAutoComplete
                  name="vendorId"
                  fullWidth
                  key={`vendor-${vendorOptions?.length}-${counter}`}
                  label="Vendor Name"
                  value={
                    vendorOptions.map(
                      (product) => product?.label === inputData?.vendorId
                    )?.label
                  }
                  options={vendorOptions.map((product) => product.label)}
                  size="small"
                  getOptionLabel={(option) => option}
                  onChange={(event, option) => {
                    let selectedData = vendorOptions?.find(
                      (er) => er?.label === option
                    );
                    setInputData({
                      ...inputData,
                      vendorId: selectedData?.value,
                    });
                    // setInputDataError({ ...inputDataError, vendorId: false });
                  }}
                  //   error={inputDataError?.unitId}
                  renderOption={(props, option) => {
                    const { id, label } = vendorOptions.filter(
                      (product) => product.label === option
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label}
                      </li>
                    );
                  }}
                />
              </Col>
            </Row>
          </div>
          <div className="d-flex justify-content-end">
            <Row className=" g-3 mt-2 ">
              <Col lg={12} md={12} sm={12}>
                <button
                  type="submit"
                  disabled={submitLoading}
                  className="custom-btn-page-layout btn text-white"
                >
                  {submitLoading ? (
                    <ClipLoader color="#75D100" size={25} />
                  ) : (
                    "Create Price List"
                  )}
                </button>
              </Col>
            </Row>
          </div>
        </form>

        <div className="mt-5">
          <Col md={12}>
            {loading ? (
              ""
            ) : (
              <div className="d-grid" style={{ border: "1px solid #DEE2E6" }}>
                <TableContainer
                  columns={column}
                  data={priceList || []}
                  isGlobalFilter={false}
                  isAddUserList={false}
                  tableClass={"table-sm"}
                  divClass={"table-responsive"}
                  initialState={initialState}
                />
                <CustomPagination
                  apiCallFunction={getPriceListAPi}
                  rowDetails={priceList}
                  totalCount={totalCount}
                  limit={limit}
                  offset={offset}
                  setLimit={setLimit}
                  setOffset={setOffset}
                />
              </div>
            )}
          </Col>
        </div>
        {editModal && (
          <EditPriceList
            editModal={editModal}
            onCloseModal={() => setEditModal(false)}
            onSubmitModal={onSubmitModal}
            singleData={singleData}
          />
        )}

        <DeleteModal
          deleteModal={deleteModal}
          handleDeleteRequest={handleDeleteRequest}
          setDeleteModal={setDeleteModal}
          deleteLoading={deleteLoading}
        />
      </div>
    </PageLayout>
  );
};

export default AddPriceList;
