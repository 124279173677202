import React, { useState } from "react";
import PageLayout from "../../Component/PageLayout";
import TableContainer from "../../Component/TableContainer";
import { useNavigate } from "react-router-dom";
import LoaderComponent from "../../Component/LoaderComponent";
import DeleteModal from "../../Component/DeleteModal";
import { usePublishedListHooks } from "./usePublishingHooks";

const PublishedDocumentList = () => {
  const navigate = useNavigate();
  const {
    column,
    permission,
    published_documents,
    onGridDelete,
    setDeleteModal,
    deleteModal,
    deleteLoading,
  } = usePublishedListHooks();

  const [loading, setLoading] = useState(false);

  return (
    <PageLayout
      title={"Published List"}
      loading={loading}
      addBtn={permission?.create ? true : false}
      backBtnTitle={`Publish New Document`}
      onClick={() => navigate("/publishing", { state: { id: 0 } })}
    >
      {loading ? (
        <LoaderComponent />
      ) : (
        <div
          className="d-grid"
          style={{ margin: "10px 20px", border: "1px solid #DEE2E6" }}
        >
          <TableContainer
            columns={column}
            data={published_documents}
            isGlobalFilter={true}
            showColumnFilters={true}
            isAddUserList={false}
            tableClass={"table-sm"}
            divClass={"table-responsive"}
            showPagination={true}
            initialState={{ pageSize: 10, pageIndex: 0 }}
          />
        </div>
      )}

      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={onGridDelete}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
    </PageLayout>
  );
};

export default PublishedDocumentList;
