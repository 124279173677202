/* eslint-disable no-restricted-globals */
import axios from "../../common/axios";
import { toast } from "react-toastify";
import { keys } from "../../config/keys";
import { FARMER_SURVEY_LIST, SURVEY_LIST } from "../constTypes";
import { _dispatchActions } from "./common";

const _surveyList = (data) => {
  return {
    type: SURVEY_LIST,
    payload: data,
  };
};

export const getSurveyMaster = () => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/survey`)
    .then((res) => {
      dispatch(_surveyList(res.data.data[1]));
    })
    .catch((e) => {
      toast.error(e.toString());
    });
};

export const saveSurvey = (data, onSuccess, onError) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${keys().ServicePath}/api/survey/save`,
    data: data,
    headers: { "Content-Type": "application/json" },
  })
    .then((res) => {
      if (onSuccess) onSuccess(res);
    })
    .catch((e) => {
      toast.error(e.toString());
      if (onError) onError(e);
    });
};

export const saveSurveyData =
  (data, onSuccess, onError) => async (dispatch) => {
    await axios({
      method: "post",
      url: `${keys().ServicePath}/api/survey/surveydata/save`,
      data: data,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (onSuccess) onSuccess(res);
      })
      .catch((e) => {
        toast.error(e.toString());
        if (onError) onError(e);
      });
  };

export const updateSurvey = (data, onSuccess, onError) => async (dispatch) => {
  await axios({
    method: "patch",
    url: `${keys().ServicePath}/api/survey`,
    data: data,
    headers: { "Content-Type": "application/json" },
  })
    .then((res) => {
      if (onSuccess) onSuccess(res);
    })
    .catch((e) => {
      toast.error(e.toString());
      if (onError) onError(e);
    });
};

export const deleteSurvey =
  (id, { onSuccess, onError }) =>
  async (dispatch) => {
    await axios({
      method: "delete",
      url: `${keys().ServicePath}/api/survey/${id}`,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (onSuccess) onSuccess(res);
      })
      .catch((e) => {
        toast.error(e.toString());
        if (onError) onError(e);
      });
  };

export const delete_farmer_survey =
  (data, { onSuccess, onError }) =>
  async (dispatch) => {
    await axios({
      method: "post",
      url: `${keys().ServicePath}/api/survey/delete_farmer_survey`,
      data,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (onSuccess) onSuccess(res);
      })
      .catch((e) => {
        toast.error(e.toString());
        if (onError) onError(e);
      });
  };

export const get_farmer_survey_list =
  ({ onSuccess, onError }) =>
  async (dispatch) => {
    await axios({
      method: "get",
      url: `${keys().ServicePath}/api/survey/get_farmer_survey_list`,
    })
      .then((res) => {
        dispatch(_dispatchActions(FARMER_SURVEY_LIST, res.data.data[1]));
        if (onSuccess) onSuccess(res);
      })
      .catch((e) => {
        toast.error(e.toString());
        if (onError) onError(e);
      });
  };
