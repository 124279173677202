import axios from "../../common/axios";
import { toast } from "react-toastify";
import { keys } from "../../config/keys";
import {
  INCENTIVETYPE_DROPDOWN_LIST,
  INCENTIVE_DROPDOWN_LIST,
  FARMER_INCENTIVE_DETAIL_LIST,
  INCENTIVETYPE_LIST,
} from "../constTypes/incentive";

const _incentivetypeDropdownList = (data) => {
  return {
    type: INCENTIVETYPE_DROPDOWN_LIST,
    payload: data,
  };
};

const _incentivetypeList = (data) => {
  return {
    type: INCENTIVETYPE_LIST,
    payload: data,
  };
};
const _incentiveDropdownList = (data) => {
  return {
    type: INCENTIVE_DROPDOWN_LIST,
    payload: data,
  };
};

const _farmerIncentiveDetailList = (data) => {
  return {
    type: FARMER_INCENTIVE_DETAIL_LIST,
    payload: data,
  };
};

export const getIncentivetypeDropdownList = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/incentive/getincentivetypeDropdownList`)
    .then((res) => {
      dispatch(_incentivetypeDropdownList(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const getIncentivetypeList = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/incentive/getincentivetypeList`)
    .then((res) => {
      console.log(res);
      dispatch(_incentivetypeList(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const getIncentiveDropdownList = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/incentive/getincentiveDropdownList`)
    .then((res) => {
      dispatch(_incentiveDropdownList(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const getFarmerIncentiveDetailList = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/incentive/getfarmerincentivedetails`)
    .then((res) => {
      dispatch(_farmerIncentiveDetailList(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const saveFarmerIncentiveDetails = (data, callback) => async () => {
  await axios({
    method: "post",
    url: `${keys().ServicePath}/api/incentive/save`,
    data: data,
    headers: { "Content-Type": "application/json" },
  })
    .then((res) => {
      // if (onSuccess) onSuccess(res);
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      // toast.error(e.toString());
      // if (onError) onError(e);
      if (callback?.onError) callback?.onError(e);
    });
};

export const updateFarmerIncentiveDetails =
  (data, onSuccess, onError) => async (dispatch) => {
    await axios({
      method: "patch",
      url: `${keys().ServicePath}/api/incentive/update`,
      data: data,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        toast.success("Updated successfully!!!");
        if (onSuccess) onSuccess(res);
      })
      .catch((e) => {
        toast.error(e.toString());
        if (onError) onError(e);
      });
  };

// export const deleteFarmerIncentiveDetails =
//   (id, onSuccess, onError) => async (dispatch) => {
//     await axios
//       .delete(`/api/incentive/${id}`)
//       .then((res) => {
//         if (onSuccess) onSuccess(res);
//       })
//       .catch((err) => {
//         if (onError) onError(err);
//       });
//   };

export const deleteFarmerIncentiveDetails = (data, callback) => async () => {
  await axios({
    method: "post",
    url: `${keys().ServicePath}/api/incentive/deleteFarmerIncentiveDetails`,
    data,
  })
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};


export const saveIncentivetype = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys().ServicePath}/api/incentive/saveincentivetype`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const delete_incentive_type = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys().ServicePath}/api/incentive/delete_incentive_type`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};
