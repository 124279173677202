import { Delete, Edit, Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { PdfIcon } from "../Icons";

export const GridAction = (props) => {
  const {
    permission,
    onEditClick = () => {},
    onViewClick = () => {},
    onDeleteClick = () => {},
    onPrintPDFClick = () => {},
    size = "small",
  } = props;

  const action = {
    id: "Action",
    Header: "",
    filterable: false,
    Cell: (cellProps) => {
      return (
        <div className="d-flex justify-content-center align-items-center gap-2">
          {(permission?.edit === true || permission?.edit === 1) && (
            <IconButton
              size={size}
              color="success"
              onClick={() => onEditClick(cellProps)}
            >
              <Edit fontSize={size} />
            </IconButton>
          )}
          {(permission?.print === true || permission?.print === 1) && (
            <IconButton
              size={size}
              color="info"
              onClick={() => onViewClick(cellProps)}
            >
              <Visibility fontSize={size} />
            </IconButton>
          )}
          {(permission?.printpdf === true || permission?.printpdf === 1) && (
            <IconButton
              size={size}
              color="error"
              onClick={() => onPrintPDFClick(cellProps)}
            >
              <PdfIcon fontSize={size} />
            </IconButton>
          )}
          {(permission?.delete === true || permission?.delete === 1) && (
            <IconButton
              size={size}
              color="error"
              onClick={() => onDeleteClick(cellProps)}
            >
              <Delete fontSize={size} />
            </IconButton>
          )}
        </div>
      );
    },
  };

  return action;
};
