import React, { useState, useCallback, useEffect } from "react";
import PageLayout from "../../Component/PageLayout";
import useBreakPoints from "../../hooks/useBreakPoints";
import { toast } from "react-toastify";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/material";

const FarmerDashboard = () => {
  const { token, permissions } = useSelector((state) => state.layout);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { isTablet } = useBreakPoints();
  const [dashboardData, setDashboardData] = useState([]);

  let permissionsfinalObj = {};
  let permissionsFarmerRegistrationObj = {};
  let permissionsDocumentVerificationObj = {};
  // eslint-disable-next-line
  let permissionsLandRegistrationObj = {};
  let permissionsKitIssueObj = {};
  // eslint-disable-next-line
  permissions?.filter((er) => {
    if (er?.ModuleData?.length > 0) {
      // eslint-disable-next-line
      let filtererdDarta = er?.ModuleData?.filter((ed) => {
        if (ed?.subModuleData?.length > 0) {
          // eslint-disable-next-line
          ed?.subModuleData?.map((qw) => {
            if (qw?.path === "/finalverification") {
              permissionsfinalObj = qw;
            }
            if (qw?.path === "/farmerRegistration") {
              permissionsFarmerRegistrationObj = qw;
            }
            if (qw?.path === "/documentverification") {
              permissionsDocumentVerificationObj = qw;
            }
            if (qw?.path === "/kitissue") {
              permissionsKitIssueObj = qw;
            }
            if (qw?.path === "/landregistration") {
              permissionsLandRegistrationObj = qw;
            }
          });
        }
      });
      return filtererdDarta;
    }
  });
  const getDashboardCountAPi = useCallback(async () => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/farmerdashboard`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);

        let data = _data?.dasboardCounts;
        let docVerificationPer = Number(
          (data.documentVerification / data.registeredFarmer) * 100
        ).toFixed(2);
        let kitPer = Number(
          (data.kitIssue / data.registeredFarmer) * 100
        ).toFixed(2);
        let finalVerifyPer = Number(
          (data.finalVerification / data.registeredFarmer) * 100
        ).toFixed(2);

        setDashboardData({
          ...data,
          docVerificationPer,
          kitPer,
          finalVerifyPer,
        });
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getDashboardCountAPi();
  }, [getDashboardCountAPi]);

  const applyCss = (val) => {
    let css = "text-body-tertiary ";
    let background = "";
    if (val > 0 && val < 25) {
      css = "text-danger";
      background = "bg-danger";
    } else if (val > 25 && val < 50) {
      css = "text-warning";
      background = "bg-warning";
    } else if (val > 50 && val < 75) {
      css = "text-primary";
      background = "bg-primary";
    } else if (val > 75) {
      css = "text-success";
      background = "bg-success";
    }
    return { css, background };
  };

  return (
    <PageLayout title={"Farmer master"} loading={loading}>
      {loading ? (
        ""
      ) : (
        <div
          className={`d-flex  ${
            isTablet ? "justify-content-center" : "justify-content-evenly"
          } mb-3 mt-5 gap-4`}
          style={{
            //flex: "1 1 272px",
            //flexFlow: "row wrap",
            display: "flex",
            justifyContent: "space-evenly",
            margin: isTablet ? "" : "20px 20px 20px 20px ",
          }}
        >
          {permissionsFarmerRegistrationObj?.access && (
            <Link to={"/farmerRegistration"}>
              <div className="farm-dash-circle">
                <span>{dashboardData.registeredFarmer}</span>
                <div className="dash-label">
                  <span>Farmer Registration</span>
                </div>
                <div
                  className="progress"
                  role="progressbar"
                  aria-label="Registered Farmer"
                  aria-valuenow="99"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-success text-bg-warning"
                    style={{ width: "99%" }}
                  ></div>
                </div>
                <small
                  className={`font-monospace text-end ${applyCss(100)}`}
                >{`Ongoing registrations..`}</small>
              </div>
            </Link>
          )}

          {permissionsDocumentVerificationObj?.access && (
            <Link to={"/documentverification"}>
              <div className="farm-dash-circle">
                <span>{dashboardData.documentVerification}</span>
                <div className="dash-label">
                  <span>{`Document Verification`}</span>
                </div>

                <div
                  className="progress"
                  role="progressbar"
                  aria-label="Animated striped example"
                  aria-valuenow={dashboardData.docVerificationPer}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    className={`progress-bar progress-bar-striped progress-bar-animated ${
                      applyCss(dashboardData.docVerificationPer).background
                    } `}
                    style={{
                      width: `${dashboardData.docVerificationPer}%`,
                    }}
                  >{`${dashboardData.docVerificationPer}%`}</div>
                </div>
                <small
                  className={`font-monospace text-end ${
                    applyCss(dashboardData.docVerificationPer).css
                  }`}
                >{`${dashboardData.docVerificationPer}% completed`}</small>
              </div>
            </Link>
          )}

          {/* {permissionsLandRegistrationObj?.access && (
            <Link to={"/landregistration"}>
              <div className="farm-dash-circle">
                <span>{dashboardData.landRegistration}</span>
                <div>
                  <span>Land Registration</span>
                </div>
              </div>
            </Link>
          )} */}

          {permissionsKitIssueObj?.access && (
            <Link to={"/kitissue"}>
              <div className="farm-dash-circle">
                <span>{dashboardData.kitIssue}</span>
                <div className="dash-label">
                  <span>KIT Issue</span>
                </div>
                <div
                  className="progress"
                  role="progressbar"
                  aria-label="Animated striped example"
                  aria-valuenow={dashboardData.kitPer}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    className={`progress-bar progress-bar-striped progress-bar-animated ${
                      applyCss(dashboardData.kitPer).background
                    }`}
                    style={{
                      width: `${dashboardData.kitPer}%`,
                    }}
                  >{`${dashboardData.kitPer}%`}</div>
                </div>
                <small
                  className={`font-monospace text-end  ${
                    applyCss(dashboardData.kitPer).css
                  }`}
                >{`${dashboardData.kitPer}% completed`}</small>
              </div>
            </Link>
          )}

          {permissionsfinalObj?.access && (
            <Link to={"/finalverification"}>
              <div className="farm-dash-circle">
                <span>{dashboardData.finalVerification}</span>
                <div className="dash-label">
                  <span>Final Verification</span>
                </div>
                <div
                  className="progress"
                  role="progressbar"
                  aria-label="Animated striped example"
                  aria-valuenow={dashboardData.finalVerifyPer}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    className={`progress-bar progress-bar-striped progress-bar-animated ${
                      applyCss(dashboardData.finalVerifyPer).background
                    }`}
                    style={{
                      width: `${dashboardData.finalVerifyPer}%`,
                    }}
                  >{`${dashboardData.finalVerifyPer}%`}</div>
                </div>
                <small
                  className={`font-monospace text-end ${
                    applyCss(dashboardData.finalVerifyPer).css
                  } `}
                >{`${dashboardData.finalVerifyPer}% completed`}</small>
              </div>
            </Link>
          )}
        </div>
      )}
    </PageLayout>
  );
};

export default FarmerDashboard;
