import { Input, InputGroup, InputGroupText } from "reactstrap";

const GlobalSearch = (props) => {
  const {
    placeholderValue = "Global search",
    searchInput,
    onChangeSearch = () => {},
  } = props;
  return (
    <div className="" style={{ margin: 20 }}>
      <InputGroup className="search-inp">
        <Input
          value={searchInput}
          onChange={onChangeSearch}
          placeholder={placeholderValue}
        />
        <InputGroupText className="btn btn-secondary d-grid ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M15.1987 13.8333L10.9037 9.53833C11.6977 8.33158 12.0157 6.87314 11.7962 5.44537C11.5766 4.01761 10.8351 2.72208 9.71524 1.80963C8.59536 0.897181 7.17674 0.432709 5.73406 0.506144C4.29138 0.579579 2.92726 1.1857 1.90581 2.20715C0.884365 3.22859 0.278248 4.59271 0.204813 6.03539C0.131379 7.47807 0.595851 8.89669 1.5083 10.0166C2.42075 11.1365 3.71628 11.878 5.14404 12.0975C6.57181 12.317 8.03025 11.999 9.237 11.205L13.532 15.5L15.1987 13.8333ZM1.86534 6.33333C1.86534 4.03584 3.7345 2.16667 6.032 2.16667C8.3295 2.16667 10.1987 4.03584 10.1987 6.33333C10.1987 8.63083 8.3295 10.5 6.032 10.5C3.7345 10.5 1.86534 8.63083 1.86534 6.33333Z"
              fill="#fff"
            />
          </svg>
        </InputGroupText>
      </InputGroup>
    </div>
  );
};

export default GlobalSearch;
