/* eslint-disable no-restricted-globals */
import axios from "../../common/axios";
import { toast } from "react-toastify";
import {
  YIELD_CONFIGURATION_LIST,
} from "../constTypes/yieldConfiguration";
import { keys } from "../../config/keys";


const _yieldConfigurationList = (data) => {
  return {
    type: YIELD_CONFIGURATION_LIST,
    payload: data,
  };
};

export const getYieldConfiguration = () => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/yieldconfiguration`)
    .then((res) => {
      dispatch(_yieldConfigurationList(res.data.data[1]));
    })
    .catch((e) => {
      toast.error(e.toString());
    });
};

export const saveYieldConfiguration =
  (data, onSuccess, onError) => async (dispatch) => {
    await axios({
      method: "post",
      url: `${keys().ServicePath}/api/yieldconfiguration/save`,
      data: data,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (onSuccess) onSuccess(res);
      })
      .catch((e) => {
        toast.error(e.toString());
        if (onError) onError(e);
      });
  };


export const updateYieldConfiguration =
  (data, onSuccess, onError) =>
  async (dispatch) => {
    await axios({
      method: "patch",
      url: `${keys().ServicePath}/api/yieldconfiguration`,
      data: data,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (onSuccess) onSuccess(res);
      })
      .catch((e) => {
        toast.error(e.toString());
        if (onError) onError(e);
      });
  };


  export const deleteYieldConfiguration =
  (id, onSuccess, onError) => async (dispatch) => {
    await axios
      .delete(`/api/yieldconfiguration/${id}`)
      .then((res) => {
        if (onSuccess) onSuccess(res);
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  