import { Col, Form, Row } from "reactstrap";
import PageLayout from "../../Component/PageLayout";
import { Autocomplete, Box, Button, Modal, TextField } from "@mui/material";
import { useVarietyMasterHooks } from "./useVarietyMasterHooks";
import { textFieldProps } from "../../common/commonControlProps";
import { Book, Cancel, Save } from "@mui/icons-material";
import TableContainer from "../../Component/TableContainer";
import DeleteModal from "../../Component/DeleteModal";
import { useState } from "react";
import ViewIncentiveScheme from "../IncentiveScheme/ViewIncentiveScheme";
import IncentiveSchemeTypeList from "../IncentiveScheme/IncentiveSchemeList";

const Variety = () => {
  const {
    product_group,
    column,
    products,
    formData,
    handleChange,
    handleSubmit,
    onSubmit,
    onDropDownChange,
    delete_Variety,
    setDeleteLoading,
    setDeleteModal,
    deleteId,
    deleteLoading,
    deleteModal
  } = useVarietyMasterHooks();

  const onGridDelete = async () => {
    setDeleteLoading(true);
    if (deleteId > 0) {
      delete_Variety(deleteId);
      setDeleteLoading(false);
      setDeleteModal(false);
    }
  };

  const [openIncentiveModal, setOpenIncentiveModal] = useState(false);

  return (
    <PageLayout title={`Variety Master`}>
      <Form className="m-4 g-2" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={2} md={4} sm={12}>
            <Autocomplete
              size="small"
              name="selectedgroup"
              options={product_group}
              onChange={onDropDownChange}
              value={formData?.selectedgroup}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Variety"
                  // error={inputDataError?.group}
                  required
                  {...textFieldProps}
                />
              )}
            />
          </Col>
          <Col lg={2} md={4} sm={12}>
            <TextField
              label="Sub Variety"
              name="productname"
              InputLabelProps={{ shrink: true }}
              value={formData?.productname}
              onChange={handleChange}
              required
              {...textFieldProps}
            />
          </Col>
          <Col lg={2} md={4} sm={12}>
            <TextField
              label="Base Price"
              name="ratePerUnit"
              InputLabelProps={{ shrink: true }}
              value={formData?.ratePerUnit}
              onChange={handleChange}
              required
              {...textFieldProps}
            />
          </Col>
        </Row>
        <Row className="mt-2 g-2">
          <Col lg={1} md={1} sm={12}>
            <Button
              className="custom-btn-page-layout text-white "
              size="small"
              startIcon={<Save />}
              fullWidth
              type="submit"
            >
              {` Save`}
            </Button>
          </Col>
          <Col lg={1} md={1} sm={12}>
            <Button
              type="button"
              size="small"
              className="custom-btn-page-layout custom-btn-danger "
              fullWidth
              startIcon={<Cancel />}
            >
              Cancel
            </Button>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <Button
              type="button"
              size="small"
              className="custom-btn-page-layout custom-btn-danger "
              fullWidth
              startIcon={<Book />}
              onClick={() => setOpenIncentiveModal(true)}
            >
              {`Add Incentive Schemes`}
            </Button>
          </Col>
        </Row>
      </Form>
      {openIncentiveModal && (
        <IncentiveSchemeTypeList
          showClose
          onCloseClick={() => setOpenIncentiveModal(false)}
        />
      )}

      <Row className="m-2">
        <Col md={12}>
          <div className="d-grid" style={{ border: "1px solid #DEE2E6" }}>
            <TableContainer
              columns={column}
              data={products}
              isGlobalFilter={true}
              showColumnFilters={true}
              isAddUserList={false}
              tableClass={"table-sm"}
              showPagination={true}
              initialState={{ pageSize: 20, pageIndex: 0 }}
              divClass={"table-responsive"}
            />
          </div>
        </Col>
      </Row>

      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={onGridDelete}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />

      {/* <Modal
        open={openIncentiveModal}
        onClose={() => setOpenIncentiveModal(false)}
        style={{ backgroundColor: "#fff" }}
      >
        <Box sx={{ ...style }}>
          <IncentiveSchemeTypeList
            showClose
            onCloseClick={() => setOpenIncentiveModal(false)}
          />
        </Box>
      </Modal> */}
    </PageLayout>
  );
};


export default Variety;
