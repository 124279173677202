import React, { useEffect, useMemo, useState } from "react";
import PageLayout from "../../../Component/PageLayout";

import { Col, Form, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import TableContainer from "../../../Component/TableContainer";
import { textFieldProps } from "../../../common/commonControlProps";
import { useFarmerAllocationHooks } from "./useFarmerAllocationHooks";
import VirtualizeAutoComplete from "../../../common/VirtualizedAutoComplete";
import { Button, TextField } from "@mui/material";
import { SelectColumnFilter } from "../../../Component/filters";
import { Cancel, Save } from "@mui/icons-material";

const FarmerAllocation = () => {
  const navigate = useNavigate();

  const {
    formData,
    employeeDropdownList,
    companyunitlist,
    geo_location_list,
    ondropdownchange,
    ondropdownchange1,
    farmerDropdownList,
    onRowSelection,
    handleSubmit,
    onSubmit,
  } = useFarmerAllocationHooks(0);

  const column = useMemo(
    () => [
      {
        id: "sr",
        Header: "Sr.#",
        Cell: (cellProps) => {
          return <span>{cellProps?.row?.index + 1}</span>;
        },
        style: { width: 25 },
      },
      {
        id: "farmerCode",
        Header: "Farmer Code",
        accessor: "farmerCode",
        style: { width: 80 },
      },
      {
        id: "name",
        Header: "Farmer Name",
        accessor: "name",
      },
      {
        Header: "District",
        accessor: "districtName",
        Filter: SelectColumnFilter,
        style: { width: 170 },
      },
      {
        Header: "Taluka",
        accessor: "talukaName",
        Filter: SelectColumnFilter,
        style: { width: 170 },
      },
      {
        Header: "Village",
        accessor: "villageName",
        Filter: SelectColumnFilter,
        style: { width: 170 },
      },
      // {
      //   id: "Technician",
      //   Header: "Technician Name",
      //   accessor: "employee.label",
      //   filterable: false,
      // },

      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <PageLayout
      title={"Manage Farmer Allocation"}
      backBtnTitle={`Back Farmer Allocation List`}
      onClick={() => navigate("/farmerallocationlist", { state: { id: 0 } })}
    >
      <Form className="m-4" onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-2">
          <Col lg={3} sm={12} md={3}>
            <VirtualizeAutoComplete
              fullWidth
              options={employeeDropdownList}
              value={formData.selectedemployee}
              onChange={(e, opt) =>
                ondropdownchange(e, opt, "employeeid", "selectedemployee")
              }
              disabled={formData.id > 0}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...textFieldProps}
                  label="Field Technician"
                />
              )}
            />
          </Col>
          <Col lg={3} md={12} sm={12}>
            <VirtualizeAutoComplete
              fullWidth
              options={companyunitlist}
              value={formData.selectedunit}
              onChange={(e, opt) =>
                ondropdownchange(e, opt, "unitid", "selectedunit")
              }
              disabled={formData.id > 0}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...textFieldProps}
                  label="Select Unit"
                />
              )}
            />
          </Col>

          <Col lg={1} md={1} sm={12}>
            <Button
              className="custom-btn-page-layout text-white "
              startIcon={<Save />}
              fullWidth
              type="submit"
            >
              {` Save`}
            </Button>
          </Col>
          <Col lg={1} md={1} sm={12}>
            <Button
              type="button"
              className="custom-btn-page-layout custom-btn-danger "
              onClick={(e) => navigate("/farmerallocationlist")}
              startIcon={<Cancel />}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>

      <Row className="mt-1">
        <Col md={12}>
          <div
            className="d-grid"
            style={{ margin: "0 20px", border: "1px solid #DEE2E6" }}
          >
            <TableContainer
              columns={column}
              data={farmerDropdownList}
              isGlobalFilter={true}
              isAddUserList={false}
              showColumnFilters={true}
              showPagination={true}
              //selectedRows={formData.selectedRows}
              showRowSelection
              initialState={{
                pageSize: 20,
                pageIndex: 0,
                selectedRowIds: formData.selectedRows,
              }}
              onRowSelectionChange={onRowSelection}
              tableClass={"table-sm"}
              divClass={"table-responsive"}
            />
          </div>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default FarmerAllocation;
