/* eslint-disable no-restricted-globals */
import axios from "../../common/axios";
import { toast } from "react-toastify";
import {
  EMPLOYEE_DETAIL_LIST,
  EMPLOYEE_DROPDOWN_LIST,
  EMPLOYEE_FARMER_LIST,
  EMPLOYEE_FARMER_LIST_BY_ID,
  FARMER_SLOT_BOOKING_LIST,
} from "../constTypes/employee";
import { keys } from "../../config/keys";
import { FARMER_DROPDOWN_LIST } from "../constTypes/farmer";

const _employeeDetailList = (data) => {
  return {
    type: EMPLOYEE_DETAIL_LIST,
    payload: data,
  };
};

export const getEmployeeDetailList = () => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/employee`)
    .then((res) => {
      dispatch(_employeeDetailList(res.data.rows[1]));
    })
    .catch((e) => {
      toast.error(e.toString());
    });
};

const _employeeDropDownList = (data) => {
  return {
    type: EMPLOYEE_DROPDOWN_LIST,
    payload: data,
  };
};
export const getEmployeeDropdownList = () => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/employee/employee_dropwdown_list`)
    .then((res) => {
      dispatch(_employeeDropDownList(res.data.rows[1]));
    })
    .catch((e) => {
      toast.error(e.toString());
    });
};

export const save_employee_farmer_village_allocation =
  (data, { onSuccess, onError }) =>
  async (dispatch) => {
    const url =
      data.id > 0
        ? `${keys().ServicePath}/api/employee/update_employee_farmer_allocation`
        : `${keys().ServicePath}/api/employee/employee_farmer_allocation`;
    await axios
      .post(url, data)
      .then((res) => {
        if (onSuccess) onSuccess(res);
      })
      .catch((e) => {
        toast.error(e.toString());
        if (onError) onError(e);
      });
  };

const _get_employee_farmer_list = (data) => {
  return {
    type: EMPLOYEE_FARMER_LIST,
    payload: data,
  };
};
export const get_employee_farmer_list =
  ({ onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .get(`${keys().ServicePath}/api/employee/employee_farmer_list`)
      .then((res) => {
        dispatch(_get_employee_farmer_list(res.data.rows[1]));
        if (onSuccess) onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        toast.error(e.toString());
        if (onError) onError(e.toString());
      });
  };

const _get_employee_farmer_list_by_id = (data) => {
  return {
    type: EMPLOYEE_FARMER_LIST_BY_ID,
    payload: data,
  };
};
export const get_employee_farmer_list_by_id =
  (id, { onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .get(`${keys().ServicePath}/api/employee/${id}`)
      .then((res) => {
        dispatch(
          _get_employee_farmer_list_by_id({
            master: res.data.rows[1],
            tran: res.data.rows[2],
          })
        );
        if (onSuccess)
          onSuccess({
            master: res.data.rows[1],
            tran: res.data.rows[2],
          });
      })
      .catch((e) => {
        toast.error(e.toString());
        if (onError) onError(e.toString());
      });
  };

const _dispatch_to_redux = (type, payload) => {
  return {
    type,
    payload,
  };
};
export const get_farmer_slot_booking_list =
  ({ onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .get(`${keys().ServicePath}/api/employee/farmer_slot_booking_list`)
      .then((res) => {
        dispatch(
          _dispatch_to_redux(FARMER_SLOT_BOOKING_LIST, res.data.rows[1])
        );
        if (onSuccess) onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        toast.error(e.toString());
        if (onError) onError(e.toString());
      });
  };

export const get_farmer_by_employee_and_unit =
  (employeeid, companyunitid, callback) => async (dispatch) => {
    await axios
      .get(
        `${
          keys().ServicePath
        }/api/employee/employee_farmer_dropdown_list/${employeeid}/${companyunitid}`
      )
      .then((res) => {
        dispatch(_dispatch_to_redux(FARMER_DROPDOWN_LIST, res.data.rows[1]));
        if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const save_farmer_purchase_appointment =
  (data, callback) => async (dispatch) => {
    await axios
      .post(
        `${keys().ServicePath}/api/employee/save_farmer_purchase_appointment`,
        data
      )
      .then((res) => {
        if (callback?.onSuccess) callback.onSuccess(res);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const delete_booking_appointment =
  (data, callback) => async (dispatch) => {
    await axios
      .post(
        `${keys().ServicePath}/api/employee/delete_farmer_purchase_appointment`,
        { ...data }
      )
      .then((res) => {
        if (callback?.onSuccess) callback.onSuccess(res);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const delete_employee_farmer_village_allocation =
  (data, callback) => async (dispatch) => {
    await axios
      .post(
        `${keys().ServicePath}/api/employee/delete_employee_farmer_allotment`,
        { ...data }
      )
      .then((res) => {
        if (callback?.onSuccess) callback.onSuccess(res);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };
