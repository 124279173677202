import axios from "axios";
import { toast } from "react-toastify";
import { keys } from "../../config/keys";
import { _dispatchActions } from "./common";
import { EVENT_LIST, PUBLISHED_DOCUMENTS } from "../constTypes";
import dayjs from "dayjs";

export const save_publishing_documents =
  (data, callback) => async (dispatch) => {
    let url =
      data.id > 0 ? "update_publishing_documents" : "save_publishing_documents";
    await axios
      .post(`${keys().ServicePath}/api/settings/${url}`, data)
      .then((res) => {
        if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);

        toast.error(e.toString());
      });
  };

export const get_published_documents = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/settings/get_published_documents`)
    .then((res) => {
      dispatch(_dispatchActions(PUBLISHED_DOCUMENTS, res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const delete_published_documents =
  (data, callback) => async (dispatch) => {
    await axios
      .post(
        `${keys().ServicePath}/api/settings/delete_published_documents`,
        data
      )
      .then((res) => {
        if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const save_event = (data, callback) => async (dispatch) => {
  let url = data.id > 0 ? "update_event" : "save_event";
  await axios
    .post(`${keys().ServicePath}/api/settings/${url}`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);

      toast.error(e.toString());
    });
};

export const get_event_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/settings/get_event_list`)
    .then((res) => {
      const t = res.data.rows[1].map((x) => {
        let s = dayjs(x.starttime);
        let s1 = dayjs(x.end);
        return {
          ...x,
          start: new Date(
            s.year(),
            s.month(),
            s.date(),
            s.hour(),
            s.minute(),
            s.second()
          ),
          end: new Date(s1.year(), s1.month(), s1.date()),
        };
      });
      // dispatch(_dispatchActions(EVENT_LIST, res.data.rows[1]));

      dispatch(_dispatchActions(EVENT_LIST, t));
      if (callback?.onSuccess) callback.onSuccess(t);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);

      toast.error(e.toString());
    });
};

export const delete_event = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys().ServicePath}/api/settings/delete_event`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};
