import React, { useState, useEffect } from "react";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import { surveyJSON } from "./json.js";
import { Serializer } from "survey-core";
import { registerColorPicker } from "./ColorPicker";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import PageLayout from "../../Component/PageLayout";
import { Row } from "reactstrap";

import { useLocation, useNavigate } from "react-router-dom";
import { Cancel, Save } from "@mui/icons-material";

registerColorPicker();
addBackgroundColorProperty();

export const SurveyCreatorWidget = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [surveyData, setSurveyData] = useState(surveyJSON);
  const [edit, setEdit] = useState(true);
  const options = {
    showLogicTab: false,
    isAutoSave: true,
    showJSONEditorTab: true,
  };

  const creator = new SurveyCreator(options);

  useEffect(() => {
    if (surveyData.id === 0) {
      creator.JSON = surveyData.jSONObj;
    } else {
      creator.JSON = surveyJSON;
    }
  }, [surveyData]);

  // useEffect(() => {
  //   setSurveyData(location.state.formData);
  // }, []);

  return (
    <PageLayout
      title={"Design Your Survey"}
      backBtnTitle={`SAve design`}
      onClick={() =>
        navigate("/survey", {
          state: { jsondata: creator.JSON },
        })
      }
    >
      <div>
        <Row>
          <SurveyCreatorComponent
            creator={creator}
            // onComplete={onCompleteSurvey}
          />
        </Row>
      </div>
    </PageLayout>
  );
};
export default SurveyCreatorWidget;

function addBackgroundColorProperty() {
  Serializer.addProperty("survey", {
    name: "backgroundColor",
    displayName: "Background color",
    type: "color",
    category: "general",
    visibleIndex: 3,
    onSetValue: (survey, value) => {
      survey.setPropertyValue("backgroundColor", value);
      applyBackground(value);
    },
  });
}

function applyBackground(color) {
  setTimeout(() => {
    const surveyEl = document.getElementsByClassName("sd-root-modern")[0];
    if (!!surveyEl) {
      surveyEl.style.setProperty("--background", color);
    }
  }, 50);
}

function handleActiveTabChange(sender, options) {
  if (options.tabName === "test" || options.tabName === "designer") {
    applyBackground(sender.survey.backgroundColor);
  }
}
