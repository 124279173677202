import { Col, Form, Row } from "reactstrap";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { keys } from "../../config/keys";
import PageLayout from "../../Component/PageLayout";
import { textFieldProps } from "../../common/commonControlProps";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Rating,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import VirtualizeAutoComplete from "../../common/VirtualizedAutoComplete";
import { useNavigate } from "react-router-dom";
import { useFarmerPurchaseHooks } from "./useFarmerPurchaseHooks";
import { ErrorMessage } from "../../common/Error";
import TableContainer from "../../Component/TableContainer";
import { Add, Cancel, GetApp, Print, Save, Star } from "@mui/icons-material";
import dayjs from "dayjs";
import Gatepass from "./GatePass";
import { useFarmerPurchaseHooks_1 } from "./useFarmerPurchaseHooks_1";
import PurchaseBill from "./PurchaseBill";
import { useState } from "react";

const _incentiveParams = [
  {
    label: "Thickness",
    name: "thickness",
    disabled: false,
  },
  {
    label: "Color",
    name: "color",
    disabled: false,
  },
  {
    label: "Moisture",
    name: "moisture",
    disabled: true,
  },
  {
    label: "Size",
    name: "size",
    disabled: true,
  },
  {
    label: "Clean",
    name: "clean",
    disabled: true,
  },
];

export const NGPurchase = () => {
  const navigate = useNavigate();

  const {
    error,
    formData,
    handleSubmit,
    onSubmit,
    farmerDropdownList,
    handleChange,
    onFarmerChange,
    onDateChange,
    errors,
    farmerYieldData,
    column,
    register,
    producttype,
    onAddClick,
    handleBlur,
    onCancelClick,
    onProductTypeChange,
    purchasedetailscolumn,
    previous_purchases_by_farmer,
    previous_purchase_columns,
    gatepassComponentRef,
    onPrintGatePass,
    onSubmitandPrint,
    fetch_details,
    previousProduct,
    currentProduct,
    handleCurrentProductDetailsChange,
    onAdd,
    handleIncentiveChange,
    onproductkasorchange,
    onAddAdmin,
    onEmptyandPrint,
  } = useFarmerPurchaseHooks_1();

  const [incentiveParams, setIncentiveParams] = useState(_incentiveParams);
  const StyledRating = styled(Rating)(({ theme }) => ({
    "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
      color: theme.palette.action.disabled,
    },
  }));

  const customIcons = {
    1: {
      icon: <Star color="error" />,
      value: 5,
    },
    2: {
      icon: <Star color="error" />,
      value: 10,
    },
    3: {
      icon: <Star color="warning" />,
      value: 15,
    },
    4: {
      icon: <Star color="success" />,
      value: 20,
    },
    5: {
      icon: <Star color="success" />,
      value: 30,
    },
  };

  function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

  return (
    <PageLayout
      title={"Purchase Details (North Gujarat)"}
      backBtnTitle={`BAck to List`}
      onClick={() => navigate("/farmerpurchases")}
    >
      <Form className="m-4 g-2" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={2} md={2} sm={12}>
            <TextField
              name="id_voucherno"
              value={formData?.id_voucherno}
              label="Voucher No"
              onChange={handleChange}
              {...textFieldProps}
            />
          </Col>
          <Col lg={2} md={2} sm={12}>
            <Button
              className="custom-btn-page-layout text-white "
              startIcon={<GetApp />}
              fullWidth
              type="button"
              onClick={fetch_details}
            >
              {` Get Details`}
            </Button>
          </Col>
        </Row>
        <Row className="mt-2 g-2">
          <Col lg={2} md={4} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Purchase Date"
                format={keys().shortDateFormat}
                slotProps={{
                  textField: {
                    ...textFieldProps,
                  },
                }}
                disabled
                onChange={onDateChange}
                value={formData?.purchasedate}
                clearable
              />
            </LocalizationProvider>
          </Col>
          <Col lg={2} md={4} sm={12}>
            <TextField
              name="purchaseno"
              value={formData?.voucherno}
              label="Voucher No"
              onChange={handleChange}
              disabled
              {...textFieldProps}
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <TextField
              name="name"
              value={`${formData.farmercode} - ${formData?.name}`}
              label="Farmer"
              disabled
              {...textFieldProps}
              InputLabelProps={{ shrink: true }}
            />
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              label={`Vehicle No`}
              disabled
              name="vehicleno"
              {...textFieldProps}
              value={formData.vehicleno}
              onChange={handleChange}
              error={errors?.vehicleno}
            />
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              label={`Vehicle Weight`}
              {...textFieldProps}
              disabled
              name="vehicleweight"
              value={formData.vehicleweight}
              className="fw-bolder fs-5 text-danger test"
            />
          </Col>

          {formData.farmerid > 0 && (
            <Col lg={12} sm={12}>
              <TableContainer
                columns={column}
                data={farmerYieldData}
                isGlobalFilter={false}
                tableClass={"table-sm"}
                showPagination={false}
                showFooter
                initialState={{ pageSize: 20, pageIndex: 0 }}
                divClass={"table-responsive"}
                exports={{ excel: false, pdf: false }}
              />
            </Col>
          )}
        </Row>
        <Row className="g-2">
          <Col lg={3} md={3} sm={12}>
            <Paper className="weighbridge">{currentProduct?.weight}</Paper>
          </Col>
        </Row>
        <Row className="mt-2 g-2">
          <Col lg={2} md={2} sm={12}>
            <VirtualizeAutoComplete
              name="selectedproducttype"
              size="small"
              options={formData.purchasedetails}
              // getOptionDisabled={(option) => {
              //   return (
              //     formData.verifiedproduct.filter(
              //       (x) => x.producttypeid === option.producttypeid
              //     ).length > 0
              //   );
              // }}
              clearText={false}
              value={formData?.selectedproducttype}
              onChange={onProductTypeChange}
              renderInput={(params) => (
                <TextField
                  {...register("selectedproducttype")}
                  {...params}
                  label="Type"
                  {...textFieldProps}
                />
              )}
            />
          </Col>

          <Col lg={1} md={1} sm={12}>
            <TextField
              label={`Weight`}
              name="weight"
              {...textFieldProps}
              InputLabelProps={{ shrink: true }}
              // disabled
              // value={
              //   formData.verifiedproduct.length <= 0
              //     ? formData.vehicleweight
              //     : currentProduct?.weight
              // }
              value={
                currentProduct?.weight < 0
                  ? formData.vehicleweight
                  : currentProduct?.weight
              }
              onChange={handleCurrentProductDetailsChange}
            />
          </Col>
          {currentProduct?.producttypeid !== 4 && (
            <>
              <Col lg={1} md={1} sm={12}>
                <TextField
                  label={`No of Bags`}
                  {...textFieldProps}
                  value={formData?.selectedproducttype?.noofbags}
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </Col>
              <Col lg={1} md={1} sm={12}>
                <TextField
                  label={`Actual Bags`}
                  name="actualbags"
                  type="number"
                  {...textFieldProps}
                  value={currentProduct?.actualbags}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleCurrentProductDetailsChange}
                />
              </Col>
              <Col lg={1} md={1} sm={12}>
                <TextField
                  label={`Bag weight (grams)`}
                  {...textFieldProps}
                  InputLabelProps={{ shrink: true }}
                  value={currentProduct?.bagweight}
                  disabled
                />
              </Col>
              <Col lg={1} md={1} sm={12}>
                <TextField
                  label={`Total Bag weight (kg)`}
                  {...textFieldProps}
                  InputLabelProps={{ shrink: true }}
                  value={currentProduct?.totalbagweight}
                  disabled
                />
              </Col>
              <Col lg={1} md={1} sm={12}>
                <TextField
                  label={`Kasor/bag (grams)`}
                  name="kasorperbag"
                  {...textFieldProps}
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  value={currentProduct?.kasorperbag}
                  onChange={handleCurrentProductDetailsChange}
                />
              </Col>

              <Col lg={1} md={1} sm={12}>
                <TextField
                  label={`Incentive/Kg`}
                  name="incentiveperkg"
                  {...textFieldProps}
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  value={currentProduct?.incentiveperkg}
                  onChange={handleCurrentProductDetailsChange}
                  disabled={currentProduct?.producttypeid !== 1}
                />
              </Col>
              <Col lg={1} md={1} sm={12}>
                <TextField
                  label={`Net Weight`}
                  {...textFieldProps}
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  value={currentProduct?.netweight}
                  disabled
                />
              </Col>
              <Col lg={3} md={3} sm={12}>
                <TextField
                  label={`Remarks for Kasor`}
                  name="kasorremarks"
                  {...textFieldProps}
                  InputLabelProps={{ shrink: true }}
                  value={currentProduct?.kasorremarks}
                  onChange={handleCurrentProductDetailsChange}
                />
              </Col>
              <Col lg={3} md={3} sm={12}>
                <TextField
                  label={`Remarks for Incentive`}
                  name="incentiveremarks"
                  {...textFieldProps}
                  InputLabelProps={{ shrink: true }}
                  value={currentProduct?.incentiveremarks}
                  onChange={handleCurrentProductDetailsChange}
                />
              </Col>
            </>
          )}
          {
            <Col md={6} lg={6} sm={12}>
              {formData?.producttype
                ?.find((x) => x.value === currentProduct?.producttypeid)
                ?.details.map((x) => (
                  <Row>
                    <Col lg={3} md={3} sm={6}>
                      <Typography variant="body2">{x.label}</Typography>
                    </Col>
                    <Col lg={3} md={3} sm={6}>
                      <TextField
                        name={x.name}
                        {...textFieldProps}
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        value={currentProduct[x.name] || 0}
                        onChange={handleCurrentProductDetailsChange}
                      />
                    </Col>
                  </Row>
                ))}
            </Col>
          }
          {currentProduct?.producttypeid === 1 && (
            <Col lg={6} sm={12} md={6}>
              <FormControlLabel
                label={`Incentive Parameters`}
                labelPlacement="top"
                control={
                  <div className="w-100 d-flex flex-column">
                    {formData.incentiveparams.map((item) => (
                      <FormControlLabel
                        label={item.label}
                        labelPlacement="end"
                        control={
                          <StyledRating
                            disabled={item.disabled}
                            name={item.name}
                            value={item.star}
                            IconContainerComponent={IconContainer}
                            onChange={(e, value) => {
                              handleIncentiveChange(
                                e,
                                customIcons[value].value,
                                value
                              );
                              console.log(
                                e.target.name,
                                customIcons[value].value
                              );
                            }}
                            getLabelText={(value) => customIcons[value].value}
                          />
                        }
                      />
                    ))}
                  </div>
                }
              ></FormControlLabel>
            </Col>
          )}
          <Col lg={2} md={2} sm={12} className="d-flex align-items-center">
            <Button
              className="custom-btn-page-layout text-white "
              size="small"
              startIcon={<Save />}
              fullWidth
              type="button"
              disabled={currentProduct?.noofbags <= 0 ? true : false}
              // onClick={onAdd}
              onClick={onAddAdmin}
            >
              {`Add`}
            </Button>
          </Col>

          {formData.purchasedetails.length > 0 && (
            <Col lg={12} md={12} sm={12}>
              <TableContainer
                columns={purchasedetailscolumn}
                // data={formData.purchasedetails}
                data={formData?.verifiedproduct?.filter(
                  (x) => x.producttypeid !== 4
                )}
                isGlobalFilter={false}
                tableClass={"table-sm"}
                showPagination={false}
                showFooter={true}
                initialState={{ pageSize: 20, pageIndex: 0 }}
                divClass={"table-responsive"}
                exports={{ excel: false, pdf: false }}
              />
            </Col>
          )}
          {Object.values(error).filter((x) => x.value).length > 0 && (
            <ul className="list-group list-group-flush">
              {Object.values(error)
                .filter((x) => x.value)
                .map((x) => (
                  <li class="list-group-item text-danger" key={Math.random()}>
                    {x.message}
                  </li>
                ))}
            </ul>
          )}
        </Row>
        <Row className="mt-2 g-2">
          {/* <Col lg={2} md={2} sm={12}>
            <Button
              className="custom-btn-page-layout text-white "
              startIcon={<Save />}
              fullWidth
              type="submit"
              disabled={error[1].value || error[2].value || error[3].value}
            >
              {` Save`}
            </Button>
          </Col> */}
          <Col lg={2} md={2} sm={12}>
            <Button
              className="custom-btn-page-layout custom-btn-warning text-white "
              startIcon={<Print />}
              fullWidth
              type="button"
              onClick={onEmptyandPrint}
              disabled={error[1].value || error[2].value || error[3].value}
            >
              {` Empty & Print`}
            </Button>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <Button
              type="button"
              className="custom-btn-page-layout custom-btn-danger "
              startIcon={<Cancel />}
              onClick={onCancelClick}
              fullWidth
            >
              Cancel
            </Button>
          </Col>
        </Row>
        {formData.farmerid > 0 && (
          <Row className="mt-2 g-2">
            <Col lg={12} md={12} sm={12}>
              <Typography variant="button">{`PREVIOUS PURCHASES`}</Typography>
              <hr />
              <TableContainer
                columns={previous_purchase_columns}
                data={previous_purchases_by_farmer}
                isGlobalFilter={false}
                tableClass={"table-sm"}
                showPagination={false}
                showFooter={false}
                initialState={{ pageSize: 20, pageIndex: 0 }}
                divClass={"table-responsive"}
                exports={{ excel: false, pdf: false }}
              />
            </Col>
          </Row>
        )}
      </Form>
      <div className="d-none">
        <PurchaseBill ref={gatepassComponentRef} data={formData} />
      </div>
    </PageLayout>
  );
};

export default NGPurchase;
