import { Button } from "@mui/material";
import PurchaseBill from "./PurchaseBill";
import { Cancel, Print } from "@mui/icons-material";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useLocation } from "react-router-dom";
import Gatepass from "./GatePass";
import { Col, Row } from "reactstrap";

const PrintPage = (props) => {
  const location = useLocation();
  //  console.log(props);
  //   const { data = null } = location.state;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      if (props.onPrint) props.onPrint();
    },
  });

  return (
    <div
      className="d-grid"
      style={{ margin: "10px 10px 0px 0px", border: "1px solid #DEE2E6" }}
    >
      <Row className="p-2">
        <Col md={3} sm={12} lg={3}>
          <Button
            className="custom-btn-page-layout custom-btn-warning text-white "
            startIcon={<Print />}
            fullWidth
            type="button"
            size="small"
            onClick={handlePrint}
          >
            {` Print`}
          </Button>
        </Col>
        {props.onCancel && (
          <Col md={3} sm={12} lg={3}>
            <Button
              className="custom-btn-page-layout custom-btn-danger text-white "
              startIcon={<Cancel />}
              fullWidth
              type="button"
              size="small"
              onClick={props.onCancel}
            >
              {` Cancel`}
            </Button>
          </Col>
        )}
      </Row>

      {props?.bill && Object.keys(props?.data).length > 0 && (
        <PurchaseBill ref={componentRef} data={props?.data} />
      )}
      {props?.gatepass && Object.keys(props?.data).length > 0 && (
        <Gatepass ref={componentRef} data={props?.data} />
      )}
    </div>
  );
};

export default PrintPage;
