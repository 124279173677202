// import { Actions } from ".";
import * as types from "../constTypes";

const initialState = [];

export const bag_issue_list = (state = initialState, action) => {
  switch (action.type) {
    case types.BAG_ISSUE_LIST:
      return action.payload || [];
    default:
      return state;
  }
};

export const bag_issue_list_farmer = (state = initialState, action) => {
  switch (action.type) {
    case types.BAG_ISSUE_LIST_FARMER:
      return action.payload || [];
    default:
      return state;
  }
};
