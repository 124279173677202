/* eslint-disable no-restricted-globals */
import axios from "../../common/axios";
import { toast } from "react-toastify";
import { farmerTypes } from "../constTypes";
import { keys } from "../../config/keys";
import { YIELDESTIMATION_LIST } from "../constTypes/yieldEstimation";
import {
  FARMER_INCENTIVES,
  FARMER_TRANSPORTATION,
  FARMER_UNACCOUNTBAGS,
  PREVIOUS_PURCHASES_FROM_FARMER,
  PURCHASES_FROM_FARMER,
} from "../constTypes/farmer";

const _get_farmer_purchase_list = (type, data) => {
  return {
    type,
    payload: data,
  };
};

export const get_farmer_purchase_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/farmerpurchase`)
    .then((res) => {
      dispatch(
        _get_farmer_purchase_list(
          farmerTypes.FARMER_PURCHASE_LIST,
          res.data.rows[1]
        )
      );
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      toast.error(e.toString());
      if (callback?.onError) callback.onError(e.toString());
    });
};

export const get_farmers_yield_estimation =
  (farmerid = 0, callback) =>
  async (dispatch) => {
    const url = `${
      keys().ServicePath
    }/api/farmerpurchase/get_farmers_yield_estimation/${farmerid}`;
    await axios
      .get(url)
      .then((res) => {
        dispatch(
          _get_farmer_purchase_list(YIELDESTIMATION_LIST, res.data.rows[1])
        );
        if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const save_farmer_purchase = (data, callback) => async (dispatch) => {
  const url = `${keys().ServicePath}/api/farmerpurchase/`;
  await axios
    .post(url, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const save_farmer_purchase_multi =
  (data, callback) => async (dispatch) => {
    const url = `${keys().ServicePath}/api/farmerpurchase/multi`;
    await axios
      .post(url, data)
      .then((res) => {
        if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const import_tally_farmer_purchase =
  (data, callback) => async (dispatch) => {
    const url = `${
      keys().ServicePath
    }/api/farmerpurchase/importTallyfarmerpurchase/`;
    await axios
      .post(url, data)
      .then((res) => {
        if (callback?.onSuccess) callback.onSuccess(res.data);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e?.response?.data?.toString());
      });
  };

export const get_previous_purchases_from_farmer =
  (farmerid = 0, callback) =>
  async (dispatch) => {
    const url = `${
      keys().ServicePath
    }/api/farmerpurchase/previous_purchase/${farmerid}`;
    await axios
      .get(url)
      .then((res) => {
        dispatch(
          _get_farmer_purchase_list(
            PREVIOUS_PURCHASES_FROM_FARMER,
            res.data.rows[1]
          )
        );
        if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const delete_farmer_purchase = (data, callback) => async (dispatch) => {
  const url = `${keys().ServicePath}/api/farmerpurchase/delete_farmer_purchase`;
  await axios
    .post(url, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const delete_gate_pass = (data, callback) => async (dispatch) => {
  const url = `${keys().ServicePath}/api/farmerpurchase/delete_farmer_gatepass`;
  await axios
    .post(url, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const delete_farmer_invoice = (data, callback) => async (dispatch) => {
  const url = `${keys().ServicePath}/api/farmerpurchase/delete_farmer_invoice`;
  await axios
    .post(url, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const get_farmer_purchase_by_id = (id, callback) => async (dispatch) => {
  const url = `${keys().ServicePath}/api/farmerpurchase/${id}`;
  await axios
    .get(url)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const update_purchase_transaction =
  (data, callback) => async (dispatch) => {
    const url = `${
      keys().ServicePath
    }/api/farmerpurchase/update_purchase_transaction`;
    await axios
      .post(url, data)
      .then((res) => {
        if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const update_purchase_transaction_admin =
  (data, callback) => async (dispatch) => {
    const url = `${
      keys().ServicePath
    }/api/farmerpurchase/update_purchase_transaction_admin`;
    await axios
      .post(url, data)
      .then((res) => {
        if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const update_purchase_transaction_incentive =
  (data, callback) => async (dispatch) => {
    const url = `${
      keys().ServicePath
    }/api/farmerpurchase/update_purchase_transaction_incentive`;
    await axios
      .post(url, data)
      .then((res) => {
        if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const update_weight_transaction =
  (data, callback) => async (dispatch) => {
    const url = `${
      keys().ServicePath
    }/api/farmerpurchase/update_weight_transaction`;
    await axios
      .post(url, data)
      .then((res) => {
        if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const update_weight_transaction_admin =
  (data, callback) => async (dispatch) => {
    const url = `${
      keys().ServicePath
    }/api/farmerpurchase/update_weight_transaction_admin`;
    await axios
      .post(url, data)
      .then((res) => {
        if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const update_emptyvehicle_transaction =
  (data, callback) => async (dispatch) => {
    const url = `${
      keys().ServicePath
    }/api/farmerpurchase/update_emptyvehicle_transaction`;
    await axios
      .post(url, data)
      .then((res) => {
        if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const get_invoice_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/farmerpurchase/get_invoice_list`)
    .then((res) => {
      dispatch(
        _get_farmer_purchase_list(
          farmerTypes.FARMER_INVOICE_LIST,
          res.data.rows[1]
        )
      );
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      toast.error(e.toString());
      if (callback?.onError) callback.onError(e.toString());
    });
};

export const get_purchases_from_farmer =
  (farmerid = 0, callback) =>
  async (dispatch) => {
    const url = `${
      keys().ServicePath
    }/api/farmerpurchase/purchase_details/${farmerid}`;
    await axios
      .get(url)
      .then((res) => {
        dispatch(
          _get_farmer_purchase_list(PURCHASES_FROM_FARMER, res.data.rows[1])
        );
        dispatch(
          _get_farmer_purchase_list(FARMER_TRANSPORTATION, res.data.rows[2])
        );
        dispatch(
          _get_farmer_purchase_list(FARMER_UNACCOUNTBAGS, res.data.rows[3])
        );
        if (callback?.onSuccess) callback.onSuccess(res.data.rows);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const get_purchases_from_farmer_ng_price =
  (farmerid = 0, price = 0, callback) =>
  async (dispatch) => {
    const url = `${
      keys().ServicePath
    }/api/farmerpurchase/purchase_details/${farmerid}/${price}`;
    await axios
      .get(url)
      .then((res) => {
        dispatch(
          _get_farmer_purchase_list(PURCHASES_FROM_FARMER, res.data.rows[1])
        );
        dispatch(
          _get_farmer_purchase_list(FARMER_TRANSPORTATION, res.data.rows[2])
        );
        dispatch(
          _get_farmer_purchase_list(FARMER_UNACCOUNTBAGS, res.data.rows[3])
        );
        if (callback?.onSuccess) callback.onSuccess(res.data.rows);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const get_farmer_incentives =
  (farmerid = 0, callback) =>
  async (dispatch) => {
    const url = `${
      keys().ServicePath
    }/api/farmerpurchase/farmer_incentives/${farmerid}`;
    await axios
      .get(url)
      .then((res) => {
        dispatch(
          _get_farmer_purchase_list(FARMER_INCENTIVES, res.data.rows[1])
        );
        if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const update_farmer_intransit = (data, callback) => async (dispatch) => {
  const url = `${
    keys().ServicePath
  }/api/farmerpurchase/update_farmer_intransit`;
  await axios
    .post(url, { ...data })
    .then((res) => {
      console.log(res.data.rows);
      // dispatch(_get_farmer_purchase_list(FARMER_INCENTIVES, res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const insert_invoice = (data, callback) => async (dispatch) => {
  const url = `${keys().ServicePath}/api/purchase/insert_invoice`;
  await axios
    .post(url, { ...data })
    .then((res) => {
      console.log(res.data.rows);
      // dispatch(_get_farmer_purchase_list(FARMER_INCENTIVES, res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};
