import React, { useState } from "react";
import { Label, Input, Row, Col, FormGroup } from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../Component/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import { typeArray, uqcArray } from "../../hooks/StaticData";
import Select from "react-select";
import useBreakPoints from "../../hooks/useBreakPoints";
const EditUnit = ({ editModal, onCloseModal, onSubmitModal, singleData }) => {
  const { token } = useSelector((state) => state.layout);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { isMobile } = useBreakPoints();
  const [inputData, setInputData] = useState({
    type: typeArray?.find((er) => er?.value === singleData?.type),
    symbol: singleData?.symbol,
    formalName: singleData?.formalName,
    uqcCode: uqcArray?.find((er) => er?.label === singleData?.uqcCode),
    decimal: singleData?.decimals,
  });

  const [inputDataError, setInputDataError] = useState({
    type: false,
    symbol: false,
    uqcCode: false,
    decimal: false,
  });

  const handleChangeInput = (name, value) => {
    if (name === "type") {
      setInputData({ ...inputData, [name]: value });

      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, type: false });
      } else {
        setInputDataError({ ...inputDataError, type: true });
      }
    } else if (name === "symbol") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, symbol: false });
      } else {
        setInputDataError({ ...inputDataError, symbol: true });
      }
    } else if (name === "uqcCode") {
      setInputData({ ...inputData, [name]: value });

      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, uqcCode: false });
      } else {
        setInputDataError({ ...inputDataError, uqcCode: true });
      }
    } else if (name === "decimal") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length < 5 && value?.length > 0) {
        setInputDataError({ ...inputDataError, decimal: false });
      } else {
        setInputDataError({ ...inputDataError, decimal: true });
      }
    } else {
      setInputData({ ...inputData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputData.type) {
      toast.error("Type is Required!");
      setInputDataError({ ...inputDataError, type: true });
    } else if (!inputData.symbol) {
      toast.error("Sysmbol is Required!");
      setInputDataError({ ...inputDataError, symbol: true });
    } else if (!inputData.uqcCode) {
      toast.error("UQC Code is Required!");
      setInputDataError({ ...inputDataError, uqcCode: true });
    } else if (!inputData?.decimal) {
      toast.error("Decimal is Required!");
      setInputDataError({ ...inputDataError, decimal: true });
    } else {
      setLoading(true);
      let formData = new FormData();

      formData.append("type", inputData.type.value);
      formData.append("symbol", inputData.symbol);
      formData.append("formalName", inputData.formalName);
      formData.append("uqcCode", inputData.uqcCode.value);
      formData.append("decimals", inputData.decimal);
      formData.append("id", singleData.id);

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/editunit`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setLoading(false);
          setInputData({
            type: { label: "Primary", value: "primary" },
            symbol: "",
            formalName: "",
            uqcCode: "",
            decimal: "",
          });
          onSubmitModal();
        } else {
          setLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  return (
    <CustomModal
      loading={loading}
      title={"Edit Unit"}
      handleSubmit={handleSubmit}
      modalState={editModal}
      onCloseModal={onCloseModal}
      editState={true}
    >
      <FormGroup row>
        <Row className="g-3" style={{ margin: 5 }}>
          <Col
            md={10}
            className={`d-flex mx-0  ${isMobile ? "flex-column" : ""}`}
          >
            <Col md={4}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Type <span className="required_span"> * </span>
              </Label>
            </Col>
            <Col sm={8}>
              <Select
                isMulti={false}
                onChange={(e) => {
                  handleChangeInput("under", e);
                }}
                value={inputData?.type}
                name="type"
                options={typeArray}
                // menuPlacement="top"

                className={`w-100 ${
                  inputDataError?.type ? "border border-danger" : ""
                }`}
              ></Select>
            </Col>
          </Col>
        </Row>
        <Row className="g-3" style={{ margin: 5 }}>
          <Col md={10} className={`d-flex  ${isMobile ? "flex-column" : ""}`}>
            <Col md={4}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Symbol{" "}
              </Label>
            </Col>
            <Col sm={8} className={`${isMobile ? "" : "pl-5"}`}>
              <Input
                id="exampleEmail"
                name="symbol"
                value={inputData?.symbol}
                onChange={(e) => {
                  handleChangeInput(
                    e.target.name,
                    e.target.value.toUpperCase()
                  );
                }}
                className={`cust-input ${
                  inputDataError?.symbol ? "border-danger" : ""
                }`}
                type="text"
              />
            </Col>
          </Col>
        </Row>
        <Row className="g-3" style={{ margin: 5 }}>
          <Col md={10} className={`d-flex  ${isMobile ? "flex-column" : ""}`}>
            <Col md={4}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Formal Name{" "}
              </Label>
            </Col>
            <Col sm={8} className={`${isMobile ? "" : "pl-5"}`}>
              <Input
                id="exampleEmail"
                name="formalName"
                value={inputData?.formalName}
                onChange={(e) => {
                  handleChangeInput(
                    e.target.name,
                    e.target.value.toUpperCase()
                  );
                }}
                className="cust-input"
                type="text"
              />
            </Col>
          </Col>
        </Row>
        <Row className="g-3" style={{ margin: 5 }}>
          <Col md={10} className={`d-flex  ${isMobile ? "flex-column" : ""}`}>
            <Col md={4}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Unit Quantity Code(UQC){" "}
              </Label>
            </Col>
            <Col sm={8} className={`${isMobile ? "" : "pl-5"}`}>
              <Select
                isMulti={false}
                onChange={(e) => {
                  handleChangeInput("uqcCode", e);
                }}
                value={inputData?.uqcCode}
                options={uqcArray}
                // menuPlacement="top"
                name="choices-single-default"
                id="countryAddCompany"
                className={`w-100 ${
                  inputDataError?.uqcCode ? "border border-danger" : ""
                }`}
              ></Select>
            </Col>
          </Col>
        </Row>
        <Row className="g-3" style={{ margin: 5 }}>
          <Col md={10} className={`d-flex  ${isMobile ? "flex-column" : ""}`}>
            <Col md={4}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Number of Decimal places{" "}
              </Label>
            </Col>
            <Col sm={8} className={`${isMobile ? "" : "pl-5"}`}>
              <Input
                id="exampleEmail"
                name="decimal"
                value={inputData?.decimal}
                onChange={(e) => {
                  handleChangeInput(
                    e.target.name,
                    e.target.value.toUpperCase()
                  );
                }}
                className={`cust-input ${
                  inputDataError?.decimal ? "border-danger" : ""
                }`}
                type="text"
              />
            </Col>
          </Col>
        </Row>
      </FormGroup>
    </CustomModal>
  );
};

export default EditUnit;
