import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageLayout from "../../Component/PageLayout";
import TableContainer from "../../Component/TableContainer";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Component/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import useBreakPoints from "../../hooks/useBreakPoints";
import * as actions from "../../redux/actions";
// import { ReactTabulator } from "react-tabulator";
import "react-tabulator/css/tabulator.css";
import "../../assests/css/tabulator.css";

import { IconButton } from "@mui/material";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import LoaderComponent from "../../Component/LoaderComponent";
import { GridAction } from "../../common/useGridAction";

const ProductList = () => {
  const { permissions, token } = useSelector((state) => state.layout);
  const { isMobile } = useBreakPoints();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let permissionsObj = {};
  // eslint-disable-next-line
  permissions?.filter((er) => {
    if (er?.ModuleData?.length > 0) {
      // eslint-disable-next-line
      let filtererdDarta = er?.ModuleData?.filter((ed) => {
        if (ed?.subModuleData?.length > 0) {
          // eslint-disable-next-line
          ed?.subModuleData?.map((qw) => {
            if (qw?.path === "/productmaster") {
              permissionsObj = qw;
            }
          });
        }
      });
      return filtererdDarta;
    }
  });

  let initialState = {};

  if (
    !permissionsObj?.edit &&
    !permissionsObj?.delete &&
    !permissionsObj?.print
  ) {
    initialState.hiddenColumns = ["Action"];
  }

  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  const getProductListAPi = useCallback(async (productid, redirectLink) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("id", productid);

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/productbyid`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();
      console.log(_data);
      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");
        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        navigate(redirectLink, { state: _data?.productData[0] });
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
    // getProductListAPi(limit, offset);

    dispatch(
      actions.productActions.get_product_list({
        onSuccess: (res) => {
          setLoading(false);
          setProductList(res);
        },
        onError: (err) => toast.error(err),
      })
    );
    // eslint-disable-next-line
  }, []);

  const column = useMemo(
    () => [
      {
        Header: "Product Code",
        accessor: "partNo",
      },
      {
        Header: "Product Name",
        accessor: "productName",
      },
      {
        Header: "Product Type",
        accessor: "typeName",
      },
      {
        Header: "Product Group",
        accessor: "groupName",
      },
      {
        Header: "Current Stock",
        accessor: "currentstock",
        style:{textAlign:"center"}
      },
      {
        ...GridAction({
          permission: permissionsObj,
          onEditClick: (cellProps) => {
            getProductListAPi(cellProps.row.original.id, "/editproductmaster");
          },
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original.id);
          },
          onViewClick: (cellProps) =>
            getProductListAPi(cellProps.row.original.id, "/viewproductmaster"),
        }),
      },
     
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  // handleDelete Btn
  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    let formData = new FormData();
    formData.append("id", deleteId);
    formData.append("isDelete", "Yes");

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/deleteproduct`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        //getProductListAPi(limit, offset);
        dispatch(actions.productActions.get_product_list());
        setDeleteModal(false);
        toast?.success(_data?.message);
        setDeleteLoading(false);
      } else {
        setDeleteLoading(false);
        setDeleteModal(false);

        toast.error(_data?.message);
      }
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
    }
  };

  const printIcon = function (cell, formatterParams, onRendered) {
    //plain text value
    return "<div><i class='fa fa-edit' style='color:green;font-size: 17px'></i> <i class='fa fa-eye' style='color:blue;font-size: 17px'></i> <i class='fa fa-trash' style='color:red;font-size: 17px'></i> </div>";
  };

  const column1 = useMemo(
    () => [
      {
        title: "Product Code",
        field: "partNo",
      },
      {
        title: "Product Name",
        field: "productName",
      },
      {
        title: "Product Type",
        field: "typeDetails.typeName",
      },
      {
        title: "Product Group",
        field: "groupDetails.groupName",
      },
      {
        formatter: printIcon,
        hozAlign: "center",
        cellClick: function (e, cell) {
          const t = cell.getRow().getData();
          console.log("Printing row data for: ", cell.getRow().getData());
        },
      },

      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <PageLayout
      title={"Product Master"}
      loading={loading}
      addBtn={permissionsObj?.create ? true : false}
      backBtnTitle={`ADD PRODUCT`}
      onClick={() => navigate("/addproductmaster")}
    >
      {loading ? (
        <LoaderComponent></LoaderComponent>
      ) : (
        <div
          className="d-grid"
          style={{ margin: "10px 20px", border: "1px solid #DEE2E6" }}
        >
          {/* <ReactTabulator
            //className="tabulator-theme"
            data={productList}
            columns={column1}
            layout={"fitColumns"}
            tooltipsHeader={false}
            pagination={true}
          /> */}
          <TableContainer
            columns={column}
            data={productList || []}
            isGlobalFilter={true}
            showColumnFilters
            showPagination
            isAddUserList={false}
            tableClass={"table-sm"}
            divClass={"table-responsive"}
            initialState={initialState}
          />
        </div>
      )}

      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={handleDeleteRequest}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
    </PageLayout>
  );
};

export default ProductList;
