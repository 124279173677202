import React, { useState } from "react";
import PageLayout from "../../Component/PageLayout";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  Label,
} from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useBreakPoints from "../../hooks/useBreakPoints";
const ViewCompany = () => {
  const location = useLocation();
  const { isLaptop } = useBreakPoints();
  // eslint-disable-next-line
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [inputData, setInputData] = useState({
    companyName: location?.state?.companyName,
    companyLogo: location?.state?.logo,
    aliasName: location?.state?.aliasName,
    companyCode: location?.state?.companyCode,
    addressLine1: location?.state?.addressLine1,
    addressLine2: location?.state?.addressLine2,
    addressLine3: location?.state?.addressLine3,
    country: location.state?.countryDetails?.name,

    state: location.state?.stateDetails?.stateName,

    dist: location.state?.districtDetails?.districtName,

    taluka: location.state?.talukaDetails?.talukaName,

    village: location.state?.villageDetails?.villageName,

    pincode: location?.state?.pincode,
    mobileNo: location?.state?.mobileNo,
    phoneNo: location?.state?.phoneNo,
    faxNo: location?.state?.faxNo,
    website: location?.state?.website,
    currency: location?.state?.currency,
    financialYear: location.state?.financialYearDetails?.year,

    gstNo: location?.state?.gstNo,
    panNo: location?.state?.panNo,
    cinNo: location?.state?.cinNo,
    TanNo: location?.state?.tanNo,
    msmeNo: location?.state?.msmeNo,
    iecCode: location?.state?.iecCode,
    gstDocument: location?.state?.gstCertificate,
    cinDocument: location?.state?.cinCertificate,
    msmeDocument: location?.state?.msmeCertificate,
    panDocument: location?.state?.panImg,
    tanDocument: location?.state?.tanCertificate,
    iecDocument: location?.state?.iecdocument,
    email: location?.state?.email,
    unitDetail: location?.state?.unitdetails,
  });

  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <PageLayout title={location?.state?.companyName}>
      <div className={`${isLaptop ? "" : "d-flex"}`}>
        <Col
          xl={8}
          lg={11}
          md={11}
          sm={11}
          style={{
            padding: isLaptop ? 0 : 20,
            margin: isLaptop ? 20 : 0,
          }}
        >
          <Accordion open={open} toggle={toggle}>
            <AccordionItem style={{ background: "#D9D9D9" }}>
              <AccordionHeader style={{ background: "#8DC893" }} targetId="1">
                Main Unit
              </AccordionHeader>
              <AccordionBody accordionId="1">
                <div className="d-flex">
                  <Col md={4}>
                    <Label style={{ fontWeight: 700 }} className="me-2">
                      Alias Name :{" "}
                    </Label>{" "}
                    {inputData?.aliasName}
                  </Col>
                  <Col md={4}>
                    <Label style={{ fontWeight: 700 }} className="me-2">
                      State :{" "}
                    </Label>{" "}
                    {inputData?.state}
                  </Col>
                  <Col md={4}>
                    <Label style={{ fontWeight: 700 }} className="me-2">
                      Phone No. :{" "}
                    </Label>{" "}
                    {inputData?.phoneNo}
                  </Col>
                </div>

                <div className="d-flex ">
                  <Col md={4}>
                    <Label style={{ fontWeight: 700 }} className="me-2">
                      Address :{" "}
                    </Label>{" "}
                    {inputData?.addressLine1 +
                      inputData?.addressLine2 +
                      inputData?.addressLine3}
                  </Col>
                  <Col md={4}>
                    <Label style={{ fontWeight: 700 }} className="me-2">
                      Country :{" "}
                    </Label>{" "}
                    {inputData?.country}
                  </Col>
                  <Col md={4}>
                    <Label style={{ fontWeight: 700 }} className="me-2">
                      Fax No. :{" "}
                    </Label>{" "}
                    {inputData?.faxNo}
                  </Col>
                </div>

                <div className="d-flex ">
                  <Col md={4}>
                    <Label style={{ fontWeight: 700 }} className="me-2">
                      Village :{" "}
                    </Label>{" "}
                    {inputData?.village}
                  </Col>
                  <Col md={4}>
                    <Label style={{ fontWeight: 700 }} className="me-2">
                      Pincode :{" "}
                    </Label>{" "}
                    {inputData?.pincode}
                  </Col>
                  <Col md={4}>
                    <Label style={{ fontWeight: 700 }} className="me-2">
                      Website :{" "}
                    </Label>{" "}
                    {inputData?.website}
                  </Col>
                </div>

                <div className="d-flex ">
                  <Col md={4}>
                    <Label style={{ fontWeight: 700 }} className="me-2">
                      Dist :{" "}
                    </Label>{" "}
                    {inputData?.dist}
                  </Col>
                  <Col md={4}>
                    <Label style={{ fontWeight: 700 }} className="me-2">
                      mobileNo :{" "}
                    </Label>{" "}
                    {inputData?.mobileNo}
                  </Col>
                </div>
              </AccordionBody>
            </AccordionItem>
            {inputData?.unitDetail?.map((item, index) => (
              <AccordionItem key={index} style={{ background: "#D9D9D9" }}>
                <AccordionHeader
                  style={{ background: "#8DC893" }}
                  targetId={`${index + 2}`}
                >
                  Unit {index + 1}
                </AccordionHeader>
                <AccordionBody accordionId={`${index + 2}`}>
                  <div className="d-flex">
                    <Col md={4}>
                      <Label style={{ fontWeight: 700 }} className="me-2">
                        Alias Name :{" "}
                      </Label>{" "}
                      {item?.aliasName}
                    </Col>
                    <Col md={4}>
                      <Label style={{ fontWeight: 700 }} className="me-2">
                        State :{" "}
                      </Label>{" "}
                      {item?.state?.stateName}
                    </Col>
                    <Col md={4}>
                      <Label style={{ fontWeight: 700 }} className="me-2">
                        Phone No. :{" "}
                      </Label>{" "}
                      {item?.phoneNo}
                    </Col>
                  </div>

                  <div className="d-flex ">
                    <Col md={4}>
                      <Label style={{ fontWeight: 700 }} className="me-2">
                        Address :{" "}
                      </Label>{" "}
                      {item?.addressLine1 +
                        item?.addressLine2 +
                        item?.addressLine3}
                    </Col>
                    <Col md={4}>
                      <Label style={{ fontWeight: 700 }} className="me-2">
                        Country :{" "}
                      </Label>{" "}
                      {item?.country?.name}
                    </Col>
                    <Col md={4}>
                      <Label style={{ fontWeight: 700 }} className="me-2">
                        Fax No. :{" "}
                      </Label>{" "}
                      {inputData?.faxNo}
                    </Col>
                  </div>

                  <div className="d-flex ">
                    <Col md={4}>
                      <Label style={{ fontWeight: 700 }} className="me-2">
                        Village :{" "}
                      </Label>{" "}
                      {item?.village?.villageName}
                    </Col>
                    <Col md={4}>
                      <Label style={{ fontWeight: 700 }} className="me-2">
                        Pincode :{" "}
                      </Label>{" "}
                      {item?.pincode}
                    </Col>
                    <Col md={4}>
                      <Label style={{ fontWeight: 700 }} className="me-2">
                        Website :{" "}
                      </Label>{" "}
                      {item?.website}
                    </Col>
                  </div>

                  <div className="d-flex ">
                    <Col md={4}>
                      <Label style={{ fontWeight: 700 }} className="me-2">
                        Dist :{" "}
                      </Label>{" "}
                      {item?.dist}
                    </Col>
                    <Col md={4}>
                      <Label style={{ fontWeight: 700 }} className="me-2">
                        mobileNo :{" "}
                      </Label>{" "}
                      {item?.mobileNo}
                    </Col>
                  </div>
                </AccordionBody>
              </AccordionItem>
            ))}
          </Accordion>

          <div
            className="mt-5"
            style={{
              padding: 10,
              backgroundColor: "#8DC893",
              borderRadius: "10px 10px 0px 0px",
            }}
          >
            <span style={{ fontSize: 16, fontWeight: 600 }}>
              View Uploaded Documents
            </span>
          </div>
          <div
            style={{
              background: "#D9D9D9",
              padding: 10,
              flex: "1 1 272px",
              flexFlow: "row wrap",
            }}
            className={`d-flex gap-4`}
          >
            <Link
              style={{
                borderRadius: 8,
                border: "1px solid #666",
                background: "#FFF",
                width: "64px",
                flexShrink: 0,
                padding: 5,
              }}
              to={inputData?.panDocument}
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="52"
                height="65"
                viewBox="0 0 52 65"
                fill="none"
              >
                <path
                  d="M44.9167 64.1667C46.6185 64.1667 48.2506 63.4906 49.4539 62.2873C50.6573 61.0839 51.3333 59.4518 51.3333 57.75V19.25L32.0833 0H6.41667C4.71486 0 3.08276 0.676039 1.8794 1.8794C0.676039 3.08276 0 4.71486 0 6.41667V57.75C0 59.4518 0.676039 61.0839 1.8794 62.2873C3.08276 63.4906 4.71486 64.1667 6.41667 64.1667H44.9167ZM28.875 6.41667L44.9167 22.4583H28.875V6.41667ZM9.625 19.25H19.25V25.6667H9.625V19.25ZM9.625 32.0833H41.7083V38.5H9.625V32.0833ZM9.625 44.9167H41.7083V51.3333H9.625V44.9167Z"
                  fill="#888888"
                />
              </svg>
            </Link>
            <Link
              style={{
                borderRadius: 8,
                border: "1px solid #666",
                background: "#FFF",
                width: "64px",
                flexShrink: 0,
                padding: 5,
              }}
              to={inputData?.tanDocument}
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="52"
                height="65"
                viewBox="0 0 52 65"
                fill="none"
              >
                <path
                  d="M44.9167 64.1667C46.6185 64.1667 48.2506 63.4906 49.4539 62.2873C50.6573 61.0839 51.3333 59.4518 51.3333 57.75V19.25L32.0833 0H6.41667C4.71486 0 3.08276 0.676039 1.8794 1.8794C0.676039 3.08276 0 4.71486 0 6.41667V57.75C0 59.4518 0.676039 61.0839 1.8794 62.2873C3.08276 63.4906 4.71486 64.1667 6.41667 64.1667H44.9167ZM28.875 6.41667L44.9167 22.4583H28.875V6.41667ZM9.625 19.25H19.25V25.6667H9.625V19.25ZM9.625 32.0833H41.7083V38.5H9.625V32.0833ZM9.625 44.9167H41.7083V51.3333H9.625V44.9167Z"
                  fill="#888888"
                />
              </svg>
            </Link>
            <Link
              style={{
                borderRadius: 8,
                border: "1px solid #666",
                background: "#FFF",
                width: "64px",
                flexShrink: 0,
                padding: 5,
              }}
              to={inputData?.gstDocument}
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="52"
                height="65"
                viewBox="0 0 52 65"
                fill="none"
              >
                <path
                  d="M44.9167 64.1667C46.6185 64.1667 48.2506 63.4906 49.4539 62.2873C50.6573 61.0839 51.3333 59.4518 51.3333 57.75V19.25L32.0833 0H6.41667C4.71486 0 3.08276 0.676039 1.8794 1.8794C0.676039 3.08276 0 4.71486 0 6.41667V57.75C0 59.4518 0.676039 61.0839 1.8794 62.2873C3.08276 63.4906 4.71486 64.1667 6.41667 64.1667H44.9167ZM28.875 6.41667L44.9167 22.4583H28.875V6.41667ZM9.625 19.25H19.25V25.6667H9.625V19.25ZM9.625 32.0833H41.7083V38.5H9.625V32.0833ZM9.625 44.9167H41.7083V51.3333H9.625V44.9167Z"
                  fill="#888888"
                />
              </svg>
            </Link>
            <Link
              style={{
                borderRadius: 8,
                border: "1px solid #666",
                background: "#FFF",
                width: "64px",
                flexShrink: 0,
                padding: 5,
              }}
              to={inputData?.cinDocument}
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="52"
                height="65"
                viewBox="0 0 52 65"
                fill="none"
              >
                <path
                  d="M44.9167 64.1667C46.6185 64.1667 48.2506 63.4906 49.4539 62.2873C50.6573 61.0839 51.3333 59.4518 51.3333 57.75V19.25L32.0833 0H6.41667C4.71486 0 3.08276 0.676039 1.8794 1.8794C0.676039 3.08276 0 4.71486 0 6.41667V57.75C0 59.4518 0.676039 61.0839 1.8794 62.2873C3.08276 63.4906 4.71486 64.1667 6.41667 64.1667H44.9167ZM28.875 6.41667L44.9167 22.4583H28.875V6.41667ZM9.625 19.25H19.25V25.6667H9.625V19.25ZM9.625 32.0833H41.7083V38.5H9.625V32.0833ZM9.625 44.9167H41.7083V51.3333H9.625V44.9167Z"
                  fill="#888888"
                />
              </svg>
            </Link>
            <Link
              style={{
                borderRadius: 8,
                border: "1px solid #666",
                background: "#FFF",
                width: "64px",
                flexShrink: 0,
                padding: 5,
              }}
              to={inputData?.iecDocument}
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="52"
                height="65"
                viewBox="0 0 52 65"
                fill="none"
              >
                <path
                  d="M44.9167 64.1667C46.6185 64.1667 48.2506 63.4906 49.4539 62.2873C50.6573 61.0839 51.3333 59.4518 51.3333 57.75V19.25L32.0833 0H6.41667C4.71486 0 3.08276 0.676039 1.8794 1.8794C0.676039 3.08276 0 4.71486 0 6.41667V57.75C0 59.4518 0.676039 61.0839 1.8794 62.2873C3.08276 63.4906 4.71486 64.1667 6.41667 64.1667H44.9167ZM28.875 6.41667L44.9167 22.4583H28.875V6.41667ZM9.625 19.25H19.25V25.6667H9.625V19.25ZM9.625 32.0833H41.7083V38.5H9.625V32.0833ZM9.625 44.9167H41.7083V51.3333H9.625V44.9167Z"
                  fill="#888888"
                />
              </svg>
            </Link>
            <Link
              style={{
                borderRadius: 8,
                border: "1px solid #666",
                background: "#FFF",
                width: "64px",
                flexShrink: 0,
                padding: 5,
              }}
              to={inputData?.msmeDocument}
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="52"
                height="65"
                viewBox="0 0 52 65"
                fill="none"
              >
                <path
                  d="M44.9167 64.1667C46.6185 64.1667 48.2506 63.4906 49.4539 62.2873C50.6573 61.0839 51.3333 59.4518 51.3333 57.75V19.25L32.0833 0H6.41667C4.71486 0 3.08276 0.676039 1.8794 1.8794C0.676039 3.08276 0 4.71486 0 6.41667V57.75C0 59.4518 0.676039 61.0839 1.8794 62.2873C3.08276 63.4906 4.71486 64.1667 6.41667 64.1667H44.9167ZM28.875 6.41667L44.9167 22.4583H28.875V6.41667ZM9.625 19.25H19.25V25.6667H9.625V19.25ZM9.625 32.0833H41.7083V38.5H9.625V32.0833ZM9.625 44.9167H41.7083V51.3333H9.625V44.9167Z"
                  fill="#888888"
                />
              </svg>
            </Link>
          </div>
        </Col>

        <Col
          xl={4}
          lg={11}
          md={11}
          sm={11}
          style={{
            // padding: isLaptop ? 0 : 20,
            margin: isLaptop ? 20 : 0,
          }}
        >
          <div className="d-flex justify-content-center align-items-center flex-column ">
            <img
              src={inputData?.companyLogo}
              alt="companylogo"
              height={135}
              width={185}
            />

            <table
              className="table table-bordered "
              style={{ margin: "40px 0px 15px 15px", width: "80%" }}
            >
              <tr style={{ height: 40 }}>
                <th style={{ border: "1px solid #0b5f18  " }}>
                  {" "}
                  Company Code{" "}
                </th>
                <td style={{ border: "1px solid #0b5f18  " }}>
                  {inputData?.companyCode}
                </td>
              </tr>
              <tr style={{ height: 40 }}>
                <th style={{ border: "1px solid #0b5f18  " }}> Currency </th>
                <td style={{ border: "1px solid #0b5f18  " }}>
                  {" "}
                  {inputData?.currency}
                </td>
              </tr>
              <tr style={{ height: 40 }}>
                <th style={{ border: "1px solid #0b5f18  " }}>
                  {" "}
                  Financial Year{" "}
                </th>
                <td style={{ border: "1px solid #0b5f18  " }}>
                  {" "}
                  {inputData?.financialYear}
                </td>
              </tr>
              <tr style={{ height: 40 }}>
                <th style={{ border: "1px solid #0b5f18  " }}> GST No.</th>
                <td style={{ border: "1px solid #0b5f18  " }}>
                  {inputData?.gstNo}
                </td>
              </tr>
              <tr style={{ height: 40 }}>
                <th style={{ border: "1px solid #0b5f18  " }}> PAN No.</th>
                <td style={{ border: "1px solid #0b5f18  " }}>
                  {" "}
                  {inputData?.panNo}
                </td>
              </tr>
              <tr style={{ height: 40 }}>
                <th style={{ border: "1px solid #0b5f18  " }}> CIN No.</th>
                <td style={{ border: "1px solid #0b5f18  " }}>
                  {inputData?.cinNo}
                </td>
              </tr>
              <tr style={{ height: 40 }}>
                <th style={{ border: "1px solid #0b5f18  " }}> TAN No. </th>
                <td style={{ border: "1px solid #0b5f18  " }}>
                  {" "}
                  {inputData?.TanNo}
                </td>
              </tr>
              <tr style={{ height: 40 }}>
                <th style={{ border: "1px solid #0b5f18  " }}> MSME Number </th>
                <td style={{ border: "1px solid #0b5f18  " }}>
                  {inputData?.msmeNo}
                </td>
              </tr>
              <tr style={{ height: 40 }}>
                <th style={{ border: "1px solid #0b5f18  " }}> IEC Code </th>
                <td style={{ border: "1px solid #0b5f18  " }}>
                  {" "}
                  {inputData?.iecCode}
                </td>
              </tr>
            </table>
          </div>
        </Col>
      </div>
    </PageLayout>
  );
};

export default ViewCompany;
