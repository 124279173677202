import React, { useState, useEffect } from "react";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import { surveyJSON } from "./json.js";
import { Serializer } from "survey-core";
import { registerColorPicker } from "./ColorPicker";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import PageLayout from "../../Component/PageLayout";
import { Col, Row } from "reactstrap";

import { useLocation, useNavigate } from "react-router-dom";
import { Cancel, Close, DesignServices, Save } from "@mui/icons-material";
import { Button } from "@mui/material";

registerColorPicker();
addBackgroundColorProperty();

const defaultOptions = {
  showLogicTab: false,
  isAutoSave: true,
  showJSONEditorTab: true,
  showDesignerTab: true,
  showPreviewTab: true,
};
export const DesignSurvey = ({
  id = 0,
  onUpdateDesign,
  onClose,
  jsonObj = surveyJSON,
  options,
}) => {
  const [surveyData, setSurveyData] = useState(surveyJSON);
  const [creator] = useState(
    new SurveyCreator({ ...defaultOptions, ...options })
  );
  const [edit, setEdit] = useState(true);

  useEffect(() => {
    // console.log(creator);
    // console.log("jsonObj", jsonObj);
    // console.log("creator.text", creator.text);
    // console.log("creator.JSON,", creator.JSON);
    if (creator) {
      creator.JSON = jsonObj;
    }
  }, [creator]);

  return (
    <Row>
      {onUpdateDesign && (
        <Col md={2} lg={2}>
          <Button
            className="custom-btn-page-layout custom-btn-warning btn btn-sm text-white btn-block"
            type="button"
            onClick={() => onUpdateDesign(creator.JSON)}
            fullWidth
            startIcon={<DesignServices />}
            size="small"
          >
            {`Finish Survey Design`}
          </Button>
        </Col>
      )}

      {onClose && (
        <Col md={2} lg={2}>
          <Button
            className="custom-btn-page-layout custom-btn-warning btn btn-sm text-white btn-block"
            type="button"
            onClick={() => onClose()}
            fullWidth
            startIcon={<Close />}
            size="small"
          >
            {`Close`}
          </Button>
        </Col>
      )}

      <Col md={12} lg={12} sm={12} style={{ height: "90vh" }}>
        <SurveyCreatorComponent
          creator={creator}
          // onComplete={onCompleteSurvey}
        />
      </Col>
    </Row>
  );
};

export default DesignSurvey;

function addBackgroundColorProperty() {
  Serializer.addProperty("survey", {
    name: "backgroundColor",
    displayName: "Background color",
    type: "color",
    category: "general",
    visibleIndex: 3,
    onSetValue: (survey, value) => {
      survey.setPropertyValue("backgroundColor", value);
      applyBackground(value);
    },
  });
}

function applyBackground(color) {
  setTimeout(() => {
    const surveyEl = document.getElementsByClassName("sd-root-modern")[0];
    if (!!surveyEl) {
      surveyEl.style.setProperty("--background", color);
    }
  }, 50);
}

function handleActiveTabChange(sender, options) {
  if (options.tabName === "test" || options.tabName === "designer") {
    applyBackground(sender.survey.backgroundColor);
  }
}
