import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { mainModuleValidationSchema, moduleValidationSchema, subModuleValidationSchema } from "./validationSchema";
import { get_main_modules_list, get_modules_list, get_sub_modules_list } from "../../redux/actions/modules";
import { toast } from "react-toastify";
import { GridAction } from "../../common/useGridAction";
import { moduleActions } from "../../redux/actions";


const mainModuleInitialValues = {
    id: 0,
    mainModuleName: '',
    path: '',
    priority: 1
}

const moduleInitialValues = {
    id: 0,
    moduleName: '',
    mainModuleId: 0,
    selectedMainModule: null,
    path: '',
    priority: 1
}

const subModuleInitialValues = {
    id: 0,
    subModuleName: '',
    mainModuleId: 0,
    selectedMainModule: null,
    moduleId: 0,
    selectedModule: null,
    path: '',
    priority: 1
}
export const useMainModuleHooks = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const [formData, setFormData] = useState({
        ...mainModuleInitialValues,
    });
    const [rowData, setRowData] = useState([]);
    const [farmerRowData, setFarmerRowData] = useState([]);
    const [editModal, setEditModal] = useState(false);
    const [singleData, setSingleData] = useState();
    const [deleteId, setDeleteId] = useState();
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const { main_modules_list } = useSelector((obj) => obj);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset
    } = useForm({
        mode: "all",
        reValidateMode: "onChange",
        resolver: yupResolver(mainModuleValidationSchema)
    });

    const handleChange = (e) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const _get_modules_list = () => dispatch(get_main_modules_list());

    const handleDeleteRequest = async () => {
        setDeleteLoading(true);
        let formData = new FormData();
        formData.append("id", deleteId);
        formData.append("isDelete", "Yes");

        if (deleteId > 0) {
            dispatch(
                moduleActions.delete_main_module({ id: deleteId }, {
                    onSuccess: () => {
                        _get_modules_list();
                        toast.success("Main Module deleted successfully");
                    },
                    onError: (err) => { }
                })
            );

        }
        setDeleteLoading(false);
        setDeleteModal(false);
    };

    useEffect(() => {
        _get_modules_list();
    }, [])

    const column = useMemo(
        () => [
            {
                Header: "Module name",
                accessor: "mainModuleName",
                filterable: false,
            },
            {
                Header: "Path",
                accessor: "path",
                filterable: false,
            },
            {
                Header: "Priority",
                accessor: "priority",
                filterable: false,
            },
            {
                ...GridAction({
                    permission: { edit: true, delete: true },
                    onEditClick: (cellProps) => {
                        setFormData({ ...cellProps.row.original })
                    },
                    onDeleteClick: (cellProps) => {
                        setDeleteModal(true);
                        setDeleteId(cellProps.row.original.id);
                    },
                }),
            },
            // eslint-disable-next-line
        ],
        // eslint-disable-next-line
        []
    );

    const onSubmit = () => {
        dispatch(moduleActions.save_main_module(formData, {
            onSuccess: (res) => {
                setFormData({ ...mainModuleInitialValues });
                _get_modules_list();
                toast.success("Module Saved Successfully");
            }, onError: (err) => toast.error(err)
        }))
    }

    return {
        setDeleteModal,
        deleteModal,
        setEditModal,
        editModal,
        setDeleteId,
        deleteId,
        setDeleteLoading,
        deleteLoading,
        rowData,
        formData,
        handleDeleteRequest,
        handleChange,
        register,
        handleSubmit,
        column,
        main_modules_list,
        onSubmit,
        register, errors
    }
}

export const useModuleHooks = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const [formData, setFormData] = useState({
        ...moduleInitialValues,
    });
    const [rowData, setRowData] = useState([]);
    const [farmerRowData, setFarmerRowData] = useState([]);
    const [editModal, setEditModal] = useState(false);
    const [singleData, setSingleData] = useState();
    const [deleteId, setDeleteId] = useState();
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const { main_modules_list, module_list } = useSelector((obj) => obj);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset
    } = useForm({
        mode: "all",
        reValidateMode: "onChange",
        resolver: yupResolver(moduleValidationSchema)
    });

    const handleChange = (e) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };


    const onDropDownChange = (e, selectedOption) => {
        setFormData((prev) => ({
            ...prev,
            mainModuleId: selectedOption?.value || 1,
            selectedMainModule: selectedOption || null
        }));
    };

    const _get_modules_list = () => dispatch(get_main_modules_list());
    const _modules_list = () => dispatch(get_modules_list());

    const handleDeleteRequest = async () => {
        setDeleteLoading(true);
        let formData = new FormData();
        formData.append("id", deleteId);
        formData.append("isDelete", "Yes");

        if (deleteId > 0) {
            dispatch(
                moduleActions.delete_module({ id: deleteId }, {
                    onSuccess: () => {
                        _get_modules_list();
                        _modules_list();
                        toast.success("Module deleted successfully");
                    },
                    onError: (err) => { }
                })
            );

        }
        setDeleteLoading(false);
        setDeleteModal(false);
    };

    useEffect(() => {
        _get_modules_list();
        _modules_list();
    }, [])

    const column = useMemo(
        () => [
            {
                Header: "Module name",
                accessor: "moduleName",
                filterable: false,
            },
            {
                Header: "Main Module name",
                accessor: "mainModuleName",
                filterable: false,
            },
            {
                Header: "Path",
                accessor: "path",
                filterable: false,
            },
            {
                Header: "Priority",
                accessor: "priority",
                filterable: false,
            },
            {
                ...GridAction({
                    permission: { edit: true, delete: true },
                    onEditClick: (cellProps) => {
                        const selectedMainModule = main_modules_list.find(x => x.value === cellProps.row.original.mainModuleId);
                        setFormData({ ...cellProps.row.original, selectedMainModule })
                    },
                    onDeleteClick: (cellProps) => {
                        setDeleteModal(true);
                        setDeleteId(cellProps.row.original.id);
                    },
                }),
            },
            // eslint-disable-next-line
        ],
        // eslint-disable-next-line
        []
    );

    const onSubmit = () => {
        dispatch(moduleActions.save_module(formData, {
            onSuccess: (res) => {
                setFormData({ ...moduleInitialValues });
                _get_modules_list();
                _modules_list();
                toast.success("Module Saved Successfully");
            }, onError: (err) => toast.error(err)
        }))
    }

    return {
        setDeleteModal,
        deleteModal,
        setEditModal,
        editModal,
        setDeleteId,
        deleteId,
        setDeleteLoading,
        deleteLoading,
        rowData,
        formData,
        handleDeleteRequest,
        handleChange,
        register,
        handleSubmit,
        column,
        main_modules_list,
        onSubmit,
        register, errors, module_list, onDropDownChange
    }
}

export const useSubModuleHooks = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const [formData, setFormData] = useState({
        ...subModuleInitialValues,
    });
    const [rowData, setRowData] = useState([]);
    const [farmerRowData, setFarmerRowData] = useState([]);
    const [editModal, setEditModal] = useState(false);
    const [singleData, setSingleData] = useState();
    const [deleteId, setDeleteId] = useState();
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const { main_modules_list, module_list, sub_module_list } = useSelector((obj) => obj);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset
    } = useForm({
        mode: "all",
        reValidateMode: "onChange",
        resolver: yupResolver(subModuleValidationSchema)
    });

    const handleChange = (e) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };


    const onDropDownChange = (e, selectedOption) => {
        setFormData((prev) => ({
            ...prev,
            mainModuleId: selectedOption?.value || 1,
            selectedMainModule: selectedOption || null
        }));
    };

    const onDropDownChange1 = (e, selectedOption) => {
        setFormData((prev) => ({
            ...prev,
            moduleId: selectedOption?.value || 1,
            selectedModule: selectedOption || null
        }));
    };

    const _get_modules_list = () => dispatch(get_main_modules_list());
    const _modules_list = () => dispatch(get_modules_list());
    const _sub_modules_list = () => dispatch(get_sub_modules_list());

    const handleDeleteRequest = async () => {
        setDeleteLoading(true);

        if (deleteId > 0) {
            dispatch(
                moduleActions.delete_sub_module({ id: deleteId }, {
                    onSuccess: () => {
                        _get_modules_list();
                        _modules_list();
                        _sub_modules_list();
                        toast.success("Sub Module deleted successfully");
                    },
                    onError: (err) => { }
                })
            );

        }
        setDeleteLoading(false);
        setDeleteModal(false);
    };

    useEffect(() => {
        _get_modules_list();
        _modules_list();
        _sub_modules_list();
    }, [])

    const column = useMemo(
        () => [
            {
                Header: "Sub Module name",
                accessor: "subModuleName",
                filterable: false,
            },
            {
                Header: "Module name",
                accessor: "moduleName",
                filterable: false,
            },
            {
                Header: "Main Module name",
                accessor: "mainModuleName",
                filterable: false,
            },
            {
                Header: "Path",
                accessor: "path",
                filterable: false,
            },
            {
                Header: "Priority",
                accessor: "priority",
                filterable: false,
            },
            {
                ...GridAction({
                    permission: { edit: true, delete: true },
                    onEditClick: (cellProps) => {
                        const selectedMainModule = main_modules_list.find(x => x.value === cellProps.row.original.mainModuleId);
                        const selectedModule = module_list.find(x => x.value === cellProps.row.original.moduleId);
                        setFormData({ ...cellProps.row.original, selectedMainModule, selectedModule })
                    },
                    onDeleteClick: (cellProps) => {
                        setDeleteModal(true);
                        setDeleteId(cellProps.row.original.id);
                    },
                }),
            },
            // eslint-disable-next-line
        ],
        // eslint-disable-next-line
        []
    );

    const onSubmit = () => {

        console.log(formData);
        dispatch(moduleActions.save_sub_module(formData, {
            onSuccess: (res) => {
                setFormData({ ...moduleInitialValues });
                _get_modules_list();
                _modules_list();
                _sub_modules_list();
                toast.success("Module Saved Successfully");
            }, onError: (err) => toast.error(err)
        }))
    }

    return {
        setDeleteModal,
        deleteModal,
        setEditModal,
        editModal,
        setDeleteId,
        deleteId,
        setDeleteLoading,
        deleteLoading,
        rowData,
        formData,
        handleDeleteRequest,
        handleChange,
        register,
        handleSubmit,
        column,
        main_modules_list,
        onSubmit,
        register, errors, module_list, onDropDownChange, onDropDownChange1, sub_module_list
    }
}