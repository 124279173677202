import axios from "../../common/axios";
import { toast } from "react-toastify";
import { keys } from "../../config/keys";
import {
  FARMER_ALLOCATION_LIST,
  FARMER_DOCUMENT_LIST,
  FARMER_DROPDOWN_LIST,
  FARMER_LAND_LIST,
  FARMER_LIST,
} from "../constTypes/farmer";

const _farmerDropdownList = (data) => {
  return {
    type: FARMER_DROPDOWN_LIST,
    payload: data,
  };
};

export const getFarmerDropdownList = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/farmer/getfarmerDropdownList`)
    .then((res) => {
      dispatch(_farmerDropdownList(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const getYieldEstimtedFarmerDropdownList =
  (callback) => async (dispatch) => {
    await axios
      .get(
        `${keys().ServicePath}/api/farmer/getYieldEstimatedfarmerDropdownList`
      )
      .then((res) => {
        dispatch(_farmerDropdownList(res.data.rows[1]));
        if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const get_farmer_by_purchases = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/farmer/get_farmer_by_purchases`)
    .then((res) => {
      dispatch(_farmerDropdownList(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const get_farmer_by_booking = (today, callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/farmer/get_farmer_by_bookings/${today}`)
    .then((res) => {
      dispatch(_farmerDropdownList(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

const _farmerLandList = (data) => {
  return {
    type: FARMER_LAND_LIST,
    payload: data,
  };
};

export const getFarmerLandList = (id, callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/farmer/getfarmerlandlist/${id}`)
    .then((res) => {
      dispatch(_farmerLandList(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

const _farmer_list = (data) => {
  return {
    type: FARMER_LIST,
    payload: data,
  };
};

export const get_farmer_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/farmer/get_farmer_list/0/0`)
    .then((res) => {
      dispatch(_farmer_list(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const save_farmer_documents = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys().ServicePath}/api/farmer/save_farmer_documents`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const import_tally_farmers =
  (data, callback) => async (dispatch) => {
    const url = `${
      keys().ServicePath
    }/api/farmer/importTallyfarmer`;
    await axios
      .post(url, data)
      .then((res) => {
        if (callback?.onSuccess) callback.onSuccess(res.data);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e?.response?.data?.toString());
      });
  };

  
const _farmer_document_list = (data) => {
  return {
    type: FARMER_DOCUMENT_LIST,
    payload: data,
  };
};

export const get_farmers_document_list =
  (farmerid, callback) => async (dispatch) => {
    await axios
      .get(
        `${keys().ServicePath}/api/farmer/get_farmers_document_list/${farmerid}`
      )
      .then((res) => {
        dispatch(_farmer_document_list(res.data.rows[1]));
        if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const delete_farmers_document = (id, callback) => async (dispatch) => {
  await axios
    .delete(`${keys().ServicePath}/api/farmer/farmer_documents/${id}`)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

const _farmer_allocation_list = (data) => {
  return {
    type: FARMER_ALLOCATION_LIST,
    payload: data,
  };
};

export const get_farmer_allocation_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/farmer/get_farmer_allocation_list`)
    .then((res) => {
      dispatch(_farmer_allocation_list(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};
