import React, { useEffect, useState } from "react";
import { Form, Input, InputGroup, InputGroupText } from "reactstrap";

import UserIdVector from "../../assests/Vector/UserId-vector.png";
import LockVector from "../../assests/Vector/lock-vector.png";
import EyeVector from "../../assests/Vector/eye-vector.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { tokenRequest } from "../../store/reducers/layoutReducer";
import { ClipLoader } from "react-spinners";
import { getToken } from "../../hooks/utils";
import Footer from "../../Layout/Footer";
import {
  setAuthorizationToken,
  setTokenToLocalStorage,
} from "../../common/axios";
import axios from "axios";
import Axios from "../../common/axios";
import { login } from "../../redux/actions/auth";
import LoginIcon from "@mui/icons-material/Login";
import "./login.css";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { textFieldProps } from "../../common/commonControlProps";
import {
  Password,
  Person,
  VerifiedUser,
  Visibility,
  VisibilityOff,
  Warehouse,
} from "@mui/icons-material";
import { commonActions } from "../../redux/actions";
import VirtualizeAutoComplete from "../../common/VirtualizedAutoComplete";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  // inputState for Field
  const [inputData, setInputData] = useState({
    userId: "",
    password: "",
    showPassword: false,
    rememberMe: false,
    selectedUnit: null,
    companyunitid: 0,
  });

  const { companyunitlist } = useSelector((obj) => obj);
  // onChange Input Form
  const handleChangeInput = (name, value) => {
    setInputData({ ...inputData, [name]: value });
  };

  // Final Submit Function for Login
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check Validation
    if (!inputData?.userId) {
      toast.error("UserId is Required!");
    } else if (!inputData?.password) {
      toast.error("password is Required!");
    }
    // else if (inputData?.selectedUnit === null) {
    //   toast.error("Please select unit");
    // }
    else {
      setLoading(true);
      let formData = new FormData();

      dispatch(
        login(
          { ...inputData, companyunitid: inputData?.selectedUnit?.id },
          {
            onSuccess: async (data) => {
              if (data.status === false) {
                toast.warning(data.message);
                setLoading(false);
                return;
              }

              formData.append("details", JSON.stringify(data.user));
              formData.append("permission", data?.permissions);
              formData.append("username", inputData?.userId);
              formData.append("password", inputData?.password);
              formData.append("id", data.user.id);
              const apiCall = await fetch(
                `${process.env.REACT_APP_API_URL}user/login`,
                {
                  method: "POST",
                  headers: {},
                  body: formData,
                }
              );
              const _data = await apiCall.json();

              dispatch(
                tokenRequest({
                  token: _data.access,
                  role: "admin",
                  details: data.user,
                  permissions: data?.permissions,
                })
              );

              navigate("/");
              // }

              setAuthorizationToken({ ...data.user, ...data.access });
              setTokenToLocalStorage(JSON.stringify({ ...data.user }));
              axios.defaults.headers.common["token"] = JSON.stringify({
                ...data.user,
              });
              Axios.defaults.headers.common["token"] = JSON.stringify({
                ...data.user,
              });

              axios.defaults.headers.common["newtoken"] = JSON.stringify({
                ...data.user,
              });
              Axios.defaults.headers.common["newtoken"] = JSON.stringify({
                ...data.user,
              });
              setLoading(false);
              setLoading(false);
            },
          }
        )
      );

      return;
      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}user/login`,
          {
            method: "POST",
            headers: {
              // Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();
        /*
           const apiNewCall = await fetch(
             `http://localhost:2024/api/auth/login`,
             {
               method: "POST",
               headers: {
                 // Authorization: `Bearer ${token}`,
               },
               body: formData,
             }
           );
            const _Newdata = await apiNewCall.json();
            console.log("_newdata", _Newdata)
          */

        if (_data?.status) {
          toast?.success(_data?.message);

          dispatch(
            tokenRequest({
              token: _data.access,
              role: "admin",
              details: _data.user,
              permissions: _data?.permissions,
            })
          );
          // if (_data.user?.roleId === 19) {
          //   navigate("/farmerdashboard");
          // } else {
          navigate("/");
          // }

          setAuthorizationToken({ ..._data.user, ..._data.access });
          axios.defaults.headers.common["token"] = JSON.stringify({
            ..._data.user,
          });
          Axios.defaults.headers.common["token"] = JSON.stringify({
            ..._data.user,
          });
          setLoading(false);
          setLoading(false);
        } else {
          setLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  const get_companyunitList = () =>
    dispatch(commonActions.get_company_unit_list());

  useEffect(() => {
    // get_companyunitList();
  }, []);

  useEffect(() => {
    let token = getToken();
    if (token) {
      navigate("/");
    } else {
      localStorage.clear();
    }
  }, [navigate]);

  return (
    <>
      <div className="login-container d-none d-sm-block">
        <div
          className="container d-flex flex-column justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="d-flex align-items-center p-3 login-form-div">
            <div className="row">
              <div className="col col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
                <img
                  src={require("../../assests/Images/Nisol Logo 3-Recovered.png")}
                  alt="logo"
                />
              </div>
              <div className="col col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
                <div className="d-flex justify-content-center flex-column align-items-start">
                  {/* <h6
                  className="text-uppercase mb-0 pb-0"
                  style={{ fontWeight: 700 }}
                >
                  Welcome to
                </h6>
                <h2
                  className="text-uppercase mt-0 pt-0"
                  style={{ fontWeight: 700, color: "#3E7531" }}
                >
                  Nisol ERP
                </h2> */}

                  <h6
                    className="text-uppercase mt-3 pb-0"
                    style={{ fontWeight: 700 }}
                  >
                    Login
                  </h6>
                  <Form className="w-100" onSubmit={(e) => handleSubmit(e)}>
                    <div className="w-100 mt-2 userId-input">
                      <TextField
                        {...textFieldProps}
                        name="userId"
                        value={inputData?.userId}
                        onChange={(e) =>
                          handleChangeInput(e.target.name, e.target.value)
                        }
                        label="Username"
                        required
                        // error={!inputData?.userId ? true : false}
                        // helperText={!inputData?.userId && "Please enter username"}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Person />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>

                    <div className="w-100 mt-3 password-input">
                      <TextField
                        {...textFieldProps}
                        type={inputData?.showPassword ? "text" : "password"}
                        name="password"
                        value={inputData?.password}
                        onChange={(e) =>
                          handleChangeInput(e.target.name, e.target.value)
                        }
                        required
                        // error={!inputData?.password ? true : false}
                        // helperText={
                        //   !inputData?.password && "Please enter password"
                        // }
                        label="Password"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Password />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setInputData({
                                    ...inputData,
                                    showPassword: !inputData?.showPassword,
                                  })
                                }
                              >
                                {inputData?.showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    {/* <div className="w-100 mt-3">
                      <VirtualizeAutoComplete
                        fullWidth
                        options={companyunitlist}
                        value={inputData.selectedUnit}
                        onChange={(e, opt) =>
                          handleChangeInput("selectedUnit", opt)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...textFieldProps}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Warehouse />
                                </InputAdornment>
                              ),
                            }}
                            label="Select Unit"
                          />
                        )}
                      />
                    </div> */}

                    <div className="mt-2">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="rememberMe"
                            color="warning"
                            size="small"
                            checked={inputData?.rememberMe}
                            onChange={(e) =>
                              handleChangeInput(e.target.name, e.target.checked)
                            }
                            //style={{ fontSize: 12, fontWeight: 400 }}
                          />
                        }
                        fontSize={"small"}
                        label="Remember me"
                      />
                    </div>

                    <div>
                      <Link
                        to={"/forgetPassword"}
                        style={{
                          textDecoration: "none",
                          color: "#085581",
                          fontSize: 12,
                          fontWeight: 400,
                        }}
                      >
                        Forgot your password ?
                      </Link>
                    </div>

                    <div className="mt-4">
                      <Button
                        className="custom-btn-page-layout custom-btn-login"
                        variant="contained"
                        startIcon={
                          loading ? (
                            <CircularProgress size={15} color="secondary" />
                          ) : (
                            <LoginIcon />
                          )
                        }
                        onClick={handleSubmit}
                        disabled={loading}
                        fullWidth
                        // color="warning"
                        type="submit"
                      >
                        {`Log In`}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ position: "fixed", bottom: 0, textAlign: "center" }}
            className="d-sm-none d-md-block"
          >
            <Footer />
          </div>
        </div>
      </div>
      <div
        className="login-container d-block d-sm-none d-flex"
        style={{ height: "100vh" }}
      >
        <div className="sm-login-container">
          <img
            src={require("../../assests/Images/Nisol Logo 3-Recovered.png")}
            alt="logo"
            className="img-fluid"
          />
          <Form className="w-100" onSubmit={(e) => handleSubmit(e)}>
            <div className="w-100 mt-2 userId-input">
              <TextField
                {...textFieldProps}
                name="userId"
                value={inputData?.userId}
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                label="Username"
                required
                // error={!inputData?.userId ? true : false}
                // helperText={!inputData?.userId && "Please enter username"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="w-100 mt-3 password-input">
              <TextField
                {...textFieldProps}
                type={inputData?.showPassword ? "text" : "password"}
                name="password"
                value={inputData?.password}
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                required
                // error={!inputData?.password ? true : false}
                // helperText={
                //   !inputData?.password && "Please enter password"
                // }
                label="Password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Password />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setInputData({
                            ...inputData,
                            showPassword: !inputData?.showPassword,
                          })
                        }
                      >
                        {inputData?.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="w-100 mt-3">
              <VirtualizeAutoComplete
                fullWidth
                options={companyunitlist}
                value={inputData.selectedUnit}
                onChange={(e, opt) => handleChangeInput("selectedUnit", opt)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...textFieldProps}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Warehouse />
                        </InputAdornment>
                      ),
                    }}
                    label="Select Unit"
                  />
                )}
              />
            </div>

            <div className="mt-2">
              <FormControlLabel
                control={
                  <Checkbox
                    name="rememberMe"
                    color="warning"
                    size="small"
                    checked={inputData?.rememberMe}
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.checked)
                    }
                    //style={{ fontSize: 12, fontWeight: 400 }}
                  />
                }
                fontSize={"small"}
                label="Remember me"
              />
            </div>

            <div>
              <Link
                to={"/forgetPassword"}
                style={{
                  textDecoration: "none",
                  color: "#085581",
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                Forgot your password ?
              </Link>
            </div>

            <div className="mt-4">
              <Button
                className="custom-btn-page-layout custom-btn-login"
                variant="contained"
                startIcon={
                  loading ? (
                    <CircularProgress size={15} color="secondary" />
                  ) : (
                    <LoginIcon />
                  )
                }
                onClick={handleSubmit}
                disabled={loading}
                fullWidth
                // color="warning"
                type="submit"
              >
                {`Log In`}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
