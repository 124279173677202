/* eslint-disable no-restricted-globals */
import axios from "../../common/axios";
import { toast } from "react-toastify";
import { keys } from "../../config/keys";
import { PURCHASEORDER_LIST } from "../constTypes/purchase";
import { PURCHASE_ORDER_LIST } from "../constTypes";

const _poList = (data) => {
  return {
    type: PURCHASE_ORDER_LIST,
    payload: data,
  };
};

export const getPOList = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/purchase`)
    .then((res) => {
      dispatch(_poList(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

const _purchaseorderList = (data) => {
  return {
    type: PURCHASEORDER_LIST,
    payload: data,
  };
};
export const getPurchaseOrderList = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/purchase/getPurchaseorderList`)
    .then((res) => {
      dispatch(_purchaseorderList(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const savePurchaseOrder = (data, callback) => async () => {
  await axios({
    method: "post",
    url: `${keys().ServicePath}/api/purchase/savePurchaseOrder`,
    data: data,
    headers: { "Content-Type": "application/json" },
  })
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};
