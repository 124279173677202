import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "../hooks/utils";
import Layout from "../Layout";

function ProtectedRoute(props) {
  const [isAuth, setIsAuth] = useState(false);
  const navigate = useNavigate();

  const validateToken = useCallback(async () => {
    let token = getToken();

    if (token) {
      setIsAuth(true);
      return;
    } else {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    validateToken();
  }, [validateToken]);

  if (isAuth === null) return null;

  return <Layout />;
}

export default ProtectedRoute;
