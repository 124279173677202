/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import PageLayout from "../../Component/PageLayout";
import { Col } from "reactstrap";
import { useYieldEstimationListHooks } from "./useYieldEstimationHooks";
import TableContainer from "../../Component/TableContainer";
import DeleteModal from "../../Component/DeleteModal";
import { GridAction } from "../../common/useGridAction";
import { usePermissionHooks } from "../../common/useCheckPermission";
import { useLocation, useNavigate } from "react-router-dom";

const YieldEstimationList = () => {
  const navigate = useNavigate();
  const {
    rowData,
    handleDeleteRequest,
    setDeleteModal,
    deleteModal,
    deleteLoading,
    setDeleteId,
  } = useYieldEstimationListHooks();
  const location = useLocation();
  const permission = usePermissionHooks(location);

  const [loading] = useState(false);

  const column = useMemo(
    () => [
      // {
      //   Header: "Voucher No.",
      //   accessor: "voucherno",
      //   filterable: false,
      // },
      {
        Header: "Date",
        accessor: "invoiceDate",
        filterable: false,
      },
      {
        Header: "Technician Name",
        accessor: "employeeName",
        filterable: false,
        width: 250,
      },
      {
        Header: "Farmer Code",
        accessor: "farmerCode",
        filterable: false,
      },
      {
        Header: "Farmer Name",
        accessor: "farmername",
        filterable: false,
      },
      // {
      //   Header: "Land No",
      //   accessor: "surveyNo",
      //   filterable: false,
      // },
      {
        Header: "Total Estimation",
        accessor: "totalestimated",
        filterable: false,
      },
      // {
      //   Header: "Rate",
      //   accessor: "rate",
      //   filterable: false,
      // },
      {
        Header: "Dakru",
        accessor: "dakru",
        filterable: false,
      },
      {
        Header: "Gariyu",
        accessor: "gariyu",
        filterable: false,
      },
      {
        Header: "Bhukho",
        accessor: "bhukho",
        filterable: false,
      },
      // {
      //   Header: "Status",
      //   accessor: "isStatus",
      // },
      {
        ...GridAction({
          permission,
          onEditClick: (cellProps) => {
            navigate("/yieldestimation", { state: cellProps.row.original });
          },
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original.id);
          },
        }),
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <PageLayout
      title={"Yield Estimation"}
      loading={loading}
      addBtn={permission?.create ? true : false}
      backBtnTitle={`Add Yield Estimation`}
      onClick={() => navigate("/yieldestimation")}
    >
      <Col md={12}>
        <div
          className="d-grid"
          style={{ margin: "20px 20px", border: "1px solid #DEE2E6" }}
        >
          <TableContainer
            columns={column}
            data={rowData}
            isGlobalFilter={true}
            showColumnFilters={true}
            isAddUserList={false}
            tableClass={"table-sm"}
            showPagination={true}
            initialState={{ pageSize: 20, pageIndex: 0 }}
            divClass={"table-responsive"}
          />
        </div>
      </Col>

      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={handleDeleteRequest}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
    </PageLayout>
  );
};

export default YieldEstimationList;
