/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PageLayout from "../../Component/PageLayout";
import TableContainer from "../../Component/TableContainer";
import { useNavigate, Link } from "react-router-dom";
import DeleteModal from "../../Component/DeleteModal";
import { useDocumentCreatorListHooks } from "./useDocumentCreatorHooks";
import { Col, Row } from "reactstrap";

const TemplateList = () => {
  const navigate = useNavigate();
  const {
    templates_list,
    loading,
    column,
    onGridDelete,
    setDeleteModal,
    deleteModal,
    deleteLoading,
    permission,
  } = useDocumentCreatorListHooks();

  return (
    <PageLayout
      title={"Document Template List"}
      loading={loading}
      addBtn={permission?.create ? true : false}
      //addBtn={true}
      backBtnTitle={`Add New Template`}
      onClick={() => navigate("/documentcreator")}
    >
      <Row>
        <Col md={12}>
          <div
            className="d-grid"
            style={{ margin: "10px 10px", border: "1px solid #DEE2E6" }}
          >
            <TableContainer
              columns={column}
              data={templates_list}
              isGlobalFilter={true}
              showColumnFilters={true}
              isAddUserList={false}
              tableClass={"table-sm"}
              showPagination={true}
              initialState={{ pageSize: 20, pageIndex: 0 }}
              divClass={"table-responsive"}
              exportfileName="Bags Issue List"
            />
          </div>
        </Col>
      </Row>
      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={onGridDelete}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
    </PageLayout>
  );
};

export default TemplateList;
