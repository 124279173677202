/* eslint-disable no-restricted-globals */
import axios from "../../common/axios";
import { toast } from "react-toastify";
import { BAG_ISSUE_LIST, BAG_ISSUE_LIST_FARMER } from "../constTypes";
import { keys } from "../../config/keys";

const _bag_issue_list = (type, data) => {
  return {
    type,
    payload: data,
  };
};

export const get_bag_issue_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/bagissue`)
    .then((res) => {
      dispatch(_bag_issue_list(BAG_ISSUE_LIST, res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      toast.error(e.toString());
      if (callback?.onError) callback.onError(e.toString());
    });
};

export const get_bag_issue_list_to_farmer =
  (farmerid, callback) => async (dispatch) => {
    await axios
      .get(`${keys().ServicePath}/api/bagissue/farmer/${farmerid}`)
      .then((res) => {
        dispatch(_bag_issue_list(BAG_ISSUE_LIST_FARMER, res.data.rows[1]));
        if (callback?.onSuccess) callback.onSuccess(res.data.rows);
      })
      .catch((e) => {
        toast.error(e.toString());
        if (callback?.onError) callback.onError(e.toString());
      });
  };

export const get_bag_issued_to_farmer_summary =
  (farmerid, callback) => async (dispatch) => {
    await axios
      .get(`${keys().ServicePath}/api/bagissue/farmersummary/${farmerid}`)
      .then((res) => {
        dispatch(_bag_issue_list(BAG_ISSUE_LIST_FARMER, res.data.rows[1]));
        if (callback?.onSuccess) callback.onSuccess(res.data.rows);
      })
      .catch((e) => {
        toast.error(e.toString());
        if (callback?.onError) callback.onError(e.toString());
      });
  };

export const get_available_bags_in_stock =
  (productid = 0, callback) =>
  async (dispatch) => {
    const url = `${
      keys().ServicePath
    }/api/bagissue/get_available_bags_in_stock${
      productid > 0 && `/${productid}`
    }`;
    await axios
      .get(url)
      .then((res) => {
        if (callback?.onSuccess) callback.onSuccess(res.data.rows);
      })
      .catch((e) => {
        toast.error(e.toString());
        if (callback?.onError) callback.onError(e.toString());
      });
  };

export const save_bag_issue = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys().ServicePath}/api/bagissue`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      toast.error(e.toString());
      if (callback?.onError) callback.onError(e.toString());
    });
};
