import React, { useEffect } from "react";
import html2pdf from "html2pdf.js";
import ReactToPrint from "react-to-print";
import { Button } from "@mui/material";
import { Print } from "@mui/icons-material";

const DownloadFarmerAgreement = React.forwardRef((props, ref) => {
  const { selectedPdfRow, download = true, showPrint = false } = props;

  const pStyle = {
    borderRight: "1px solid black",
    margin: 0,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 17,
    paddingRight: 17,
  };
  // let totalVigha = selectedPdfRow?.lands?.reduce(
  //   (total, num) => parseInt(total) + Math.round(num?.regVigha * 100) / 100,
  //   0
  // );

  let totalVigha = selectedPdfRow?.landDetails?.reduce(
    (total, num) => Number(total) + Number(num?.regVigha),
    0
  );

  let _ppeKit = selectedPdfRow?.kitDetails?.filter(
    (er) => er?.product.productName === "PPE KIT"
  );
  let _bioFer = selectedPdfRow?.kitDetails?.filter(
    (er) => er?.product.productName === "Bio Fertilizer"
  );
  const handleDownload = () => {
    const element = document.getElementById("agreementPdf");
    const options = {
      filename: `${selectedPdfRow?.farmerCode}.pdf`,
      //image: { type: 'jpeg', quality: 0.20 },
      html2canvas: { scale: 2, useCORS: true },
    };

    html2pdf().set(options).from(element).save();
    // setCounter(0);
  };

  useEffect(() => {
    //console.log(selectedPdfRow);

    if (download) handleDownload();
    // eslint-disable-next-line
  }, []);

  const printcss = `@media print {
  @page {
      size: A4;
      margin: 0pt;
    }
  }`;

  return (
    <>
      <style>{printcss}</style>
      <div style={{ display: "block" }} ref={ref}>
        {/* {showPrint && (
        <ReactToPrint
          trigger={() => (
            <Button
              className="custom-btn-page-layout"
              variant="contained"
              fullWidth
              startIcon={<Print />}
            >
              {" "}
              {`Print`}
            </Button>
          )}
          content={() => this.componentRef}
        />
      )} */}

        <div
          id="agreementPdf"
          style={{ margin: 20, borderColor: "#174B76", fontSize: "12px" }}
        >
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-10 tm-80">
                        <h5 style={{ textAlign: "center" }}>
                          NISOL MANUFACTURING COMPANY PRIVATE LIMITED
                        </h5>
                        <h6 style={{ textAlign: "center" }}>
                          {selectedPdfRow?.unitDetails?.unitName}
                        </h6>
                        <div
                          className="guj-text"
                          style={{ textAlign: "center" }}
                        >
                          <h6>નીસોલના નીકોટીન પાક માટે બાંયધરી પત્રક</h6>
                        </div>
                        <div
                          className="guj-text"
                          style={{ textAlign: "center" }}
                        >
                          <h6>સીઝન ૨૦૨૪-૨૦૨૫</h6>
                        </div>
                      </div>
                      <div className="col-md-2 border">
                        <img
                          src={selectedPdfRow.photourl}
                          alt="Profile"
                          //  height={120}
                          width={120}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="row" style={{ marginTop: "30px" }}>
                      <table
                        width="100%"
                        border="1"
                        cellSpacing="0"
                        cellPadding="0"
                      >
                        <tbody>
                          <tr>
                            <td>
                              <table
                                width="100%"
                                border="1"
                                cellSpacing="0"
                                cellPadding="0"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        padding: 5,
                                      }}
                                    >
                                      <p>
                                        ખેડૂતનું નામ : {selectedPdfRow?.name}
                                      </p>
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        padding: 5,
                                      }}
                                    >
                                      <p>
                                        વિધા : {Number(totalVigha).toFixed(3)}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table
                                width="100%"
                                border="1"
                                cellSpacing="0"
                                cellPadding="0"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        paddingTop: "10px",
                                        paddingLeft: "10px",
                                        borderRight: "1px solid black",
                                        width: "350px",
                                      }}
                                    >
                                      <p>
                                        ગામ :{" "}
                                        {
                                          selectedPdfRow?.villageDetails
                                            ?.villageName
                                        }
                                      </p>
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        paddingTop: "10px",
                                        paddingRight: "60px",
                                        paddingLeft: "10px",
                                      }}
                                    >
                                      <p>કંપની સાથે જોડાણ</p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table
                                width="100%"
                                border="1"
                                cellSpacing="0"
                                cellPadding="0"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        paddingTop: "10px",
                                        paddingLeft: "10px",
                                        borderRight: "1px solid black",
                                        width: "350px",
                                      }}
                                    >
                                      <p>
                                        સંપર્ક નંબર : {selectedPdfRow?.mobileNo}
                                      </p>
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        display: "flex",
                                        height: 45,
                                      }}
                                    >
                                      <p
                                        style={{
                                          ...pStyle,
                                        }}
                                      >
                                        1
                                      </p>

                                      {1 <=
                                      Number(
                                        selectedPdfRow?.noOfActiveYears
                                      ) ? (
                                        <p
                                          style={{
                                            ...pStyle,
                                          }}
                                        >
                                          &#10003;
                                        </p>
                                      ) : (
                                        <p
                                          style={{
                                            ...pStyle,
                                          }}
                                        ></p>
                                      )}

                                      <p
                                        style={{
                                          ...pStyle,
                                        }}
                                      >
                                        2
                                      </p>
                                      {2 <=
                                      Number(
                                        selectedPdfRow?.noOfActiveYears
                                      ) ? (
                                        <p
                                          style={{
                                            ...pStyle,
                                          }}
                                        >
                                          &#10003;
                                        </p>
                                      ) : (
                                        <p
                                          style={{
                                            ...pStyle,
                                          }}
                                        ></p>
                                      )}
                                      <p
                                        style={{
                                          ...pStyle,
                                        }}
                                      >
                                        3
                                      </p>
                                      {3 <=
                                      Number(
                                        selectedPdfRow?.noOfActiveYears
                                      ) ? (
                                        <p
                                          style={{
                                            ...pStyle,
                                          }}
                                        >
                                          &#10003;
                                        </p>
                                      ) : (
                                        <p
                                          style={{
                                            ...pStyle,
                                          }}
                                        ></p>
                                      )}
                                      <p
                                        style={{
                                          ...pStyle,
                                        }}
                                      >
                                        4
                                      </p>
                                      {4 <=
                                      Number(
                                        selectedPdfRow?.noOfActiveYears
                                      ) ? (
                                        <p
                                          style={{
                                            ...pStyle,
                                          }}
                                        >
                                          &#10003;
                                        </p>
                                      ) : (
                                        <p
                                          style={{
                                            ...pStyle,
                                          }}
                                        ></p>
                                      )}
                                      <p
                                        style={{
                                          ...pStyle,
                                        }}
                                      >
                                        5
                                      </p>
                                      {5 <=
                                      Number(
                                        selectedPdfRow?.noOfActiveYears
                                      ) ? (
                                        <p
                                          style={{
                                            ...pStyle,
                                          }}
                                        >
                                          &#10003;
                                        </p>
                                      ) : (
                                        <p
                                          style={{
                                            ...pStyle,
                                          }}
                                        ></p>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table
                                width="100%"
                                border="1"
                                cellSpacing="0"
                                cellPadding="0"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        paddingTop: "10px",
                                        paddingLeft: "10px",
                                        borderRight: "1px solid black",
                                        width: "350px",
                                      }}
                                    >
                                      <p
                                        style={{
                                          display: "flex",
                                          paddingBottom: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        <span
                                          style={{
                                            paddingBottom: 0,
                                            marginBottom: 0,
                                          }}
                                        >
                                          {" "}
                                          તમાકુની જાત :{" "}
                                        </span>
                                        <ul>
                                          {selectedPdfRow?.farmingDetails?.map(
                                            (er, index) => {
                                              return (
                                                <li
                                                  style={{
                                                    paddingBottom: 0,
                                                    marginBottom: 0,
                                                    listStyle: "none",
                                                  }}
                                                >
                                                  {`(${index + 1}) ${
                                                    er?.farmingProductDetails
                                                      ?.productName
                                                  } - ${er?.vigha} વીઘા `}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </p>
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        paddingTop: "10px",
                                        paddingRight: "60px",
                                        paddingLeft: "10px",
                                      }}
                                    >
                                      <p>
                                        ખેડૂત ક્રમાંક :{" "}
                                        {selectedPdfRow?.farmerCode}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="container">
                    <div className="row" style={{ marginTop: "20px" }}>
                      <p className="tm-40 bm-10">
                        ૧. કંપની ફક્ત આ દસ્તાવેજમાં ઉલ્લેખ કરેલ જમીનમાં થયેલ બધી
                        ઉપજ (ભૂકો) ખરીદશે. ખેડૂતે ફક્ત કંપનીને જ અગાઉ નક્કી કરેલ
                        જમીનની બધી ઉપજ વેચવાની રહશે જો ખેડૂત નોધણી કરાવેલ જમીન
                        કરતા ઓછી જમીનનો માલ આપશે તો કંપની તે માલ લેશે નહિ. ફક્ત
                        નોંધણી કરાવેલ ખેડૂત દ્વારા ઉત્પાદિત નિકોટીન પાક અને
                        નીસોલના કર્મચારી દ્વારા મોનીટર કરેલ પાક કંપનીને વેચવાનો
                        રહેશે.
                      </p>
                      <p className="bm-10">
                        2. ખેડૂતોને તેમની અંતિમ ઉપજને ભુકા સ્વરૂપમાં ૮૦:૩:૧૭
                        (ફોલ: ગારિયું: ડોખરું) ના પ્રમાણમાં અલગ અલગ કંપની દ્રારા
                        આપવામાં આવેલ બેગમાં ભરવાની સલાહ આપવામાં આવે છે. કાપણીની
                        અને ભૂકો પાડવાની રીત, બેગ ભરવાની અને બેગને પકડવાની રીત
                        સારી રીતે પાસબૂકમાં જણાવેલ છે. જે ઉપજ પાસબૂકમાં જણાવેલ
                        ધારાધોરણ મુજબ નહીં હોય તો કંપનીને અસ્વીકાર કરવાનો અધિકાર
                        રહેશે. આ અસ્વીકાર કરેલ માલ પાછો લઇ જવાનો અને સાફ કરી ફરી
                        જણાવેલ સમયે ખરીદ કેન્દ્ર પર લાવવાની જવાબદારી ખેડૂતની
                        રહેશે. આ વધારાનો માલ લાવવા લઇ જવાનો તેમજ મજુરીનો ખર્ચો
                        કંપની આપશે નહીં.
                      </p>
                      <div className="bm-10">
                        ૩. અંતિમ ઉપજ (ભૂકો)નુ વજન ઇલેક્ટ્રોનિક વજનવાળા કાંટા પર
                        કરવામાં આવશે અને કુલ ઉપજના વજન માંથી ખાલી બેગનું વજનને
                        બાદ કરવામાં આવશે અને ચુકવણી માટે ઉપજનુ નેટ વજન ધ્યાનમાં
                        લેવામાં આવશે. પ્રોત્સાહન: જો ખેડૂત ભલામણ કરેલ સારી ખેત
                        પદ્ધતિને અનુસરશે તો મૂળ ભાવ ઉપરાંત નીચે મુજબના
                        પ્રોત્સાહન આપવામાં આવશે.
                        <ul>
                          <li>
                            ખેતરમાં લીલો પડવાશ કરવો અને તે ખેતરનો સર્વે કંપનીના
                            ફીલ્ડ ટેકનિશિયન દ્વારા કરાવવા પર (વીઘા ઉપર, જુના
                            ખેડૂત માટે)
                          </li>
                          <li>
                            પર્યાવરણનું સૌરક્ષણ કરવા માટે સિંચાઇમાં ટપક પદ્ધતિ,
                            ફુવારા પદ્ધતિ અને સોલાર પંપ અપનાવા પર (વીઘા પર)
                          </li>
                          <li>
                            તમાકુની ખૂટણી અને પીલા કાઢતી વખતે તેમજ જંતુનાશક
                            દવાનો છંટકાવ કરતી વખતે તથા ભૂકો પાડતી વખતે PPE કીટ
                            નો ઉપયોગ કરી અને વપરાયેલ PPE કંપનીને પરત આપવા પર.
                          </li>
                          <li>
                            ખેડૂત અથવા ભાગિયાના ૧૫ વર્ષથી નીચેના બાળકનો નિશાળ
                            જવાની હાજરીનો પુરાવો (૭૦ ટકા) આપવા પર.
                          </li>
                          <li>
                            કંપનીએ ભલામણ કરેલી દવાની ખાલી બોટલ ત્રણ વાર પાણી વડે
                            ધોઈ, કાણુ પાડીને દવાના બીલ સાથે કંપનીને આપવા પર.
                          </li>
                          <li>
                            જૈવિક ખાતર (NPK Bio fertilizer) ના ઉપયોગ અને વપરાયેલ
                            ખાલી બોટલ ત્રણ વાર પાણી વડે ધોઈ, કાણુ પાડીને દવાના
                            બીલ સાથે કંપનીને આપવા પર (વીઘા ઉપર)
                          </li>
                          <li>
                            નીસોલ કંપની સાથે સતત ત્રણ વર્ષથી જોડાયેલા ખેડૂતોને
                          </li>
                          <li>ભુકાની ગુણવતા પર</li>
                          નોધ- આ પ્રોત્સાહકની રકમ ફક્ત ચાલુ વર્ષ માટે જ છે.
                        </ul>
                      </div>
                      <p className="bm-10">
                        ૪. નીસોલનો SNP પ્રોજેક્ટનો હેતુ ખેડૂતના બેંકના ખાતામાં
                        સીધું ચૂકવાનું કરી અને તેની સાથે રહી તેનું જીવન ધોરણ
                        સુધારવાનો છે. આમ કરવાથી ખેડૂત અને કંપની વચ્ચેથી વચેટિયા
                        દૂર થશે. આ ફાયદાઓનો ખેડૂતને સીધો લાભ મળશે. ખેડૂત ભૂકો
                        બેગમાં ભરતી વખતે ધ્યાન રાખે. તેમાં વધારાની કોઈપણ
                        ચીજવસ્તુ (જેમકે લોખંડ પ્લાસ્ટિક, પત્થર, ઈંટ) જેવી અન્ય
                        કોઈપણ વસ્તુ ચલાવી લેવામાં આવશે નહી. કારણકે તે સીધો
                        વપરાશમાં લેવાનો છે. આ માટે અમારી ટીમ તમેને હંમેશા મદદમાં
                        રહેશે.
                      </p>

                      <p className="bm-10">
                        ૫. ખેડૂતે નીસોલના પ્રતિનિધિઓ અથવા ત્રીજા પક્ષને પાક સાથે
                        સંબંધિત તમામ ઉત્પાદન, નાણાકીય અને અન્ય રેકોર્ડનો ઉપયોગ
                        કરવા પરવાનગી આપવાની રહેશે અને નીસોલના પ્રતિનિધિઓ અથવા
                        નામાંકિત ત્રીજા પક્ષોને, ખેતરની તપાસ કરવા અને જોવા કરવા
                        અને પાક, પર્યાવરણ અને મજૂરને લગતા કામની નોંધ કરવા માટે
                        પરવાનગી આપવાની રહેશે. ફાર્મ મોનીટરીંગ વખતે ભેગી કરેલી
                        માહિતીનો ઉપયોગ ખેડૂતના સુધારા માટે અને ટકાઉ નિકોટીન
                        પ્રોગ્રામ માટે કરવામાં આવશે.
                        <b>
                          <u>
                            ધરુવાડિયું અને તમાકુ રોપાયા પછી નોધણી કરાવેલ બધા
                            ખેતર રૂબરૂમાં ફીલ્ડ ટેકનિશિયનને બતાવવા અને જયારે
                            જરૂર પડે ત્યારે ખેતરમાં હાજર રહેવું. ખેડૂતે કંપનીના
                            ફીલ્ડ ટેકનિશિયન સાથે સભ્યતાપૂર્ણ વર્તન કરવું અને સાથ
                            સહકાર આપવો.
                          </u>
                        </b>
                        <br />
                      </p>

                      <p className="bm-10">
                        ૬. ખેડૂતે કંપનીને અને તેના સંલગ્ન સંસ્થાને અધિકાર આપવો
                        પડશે જે ભેગી કરેલી માહિતીનો ઉપયોગ આંકડાકીય અને/ સંસોધન
                        માટે અને/ અનુપાલનની સમિક્ષા કરે જે કાનૂની નિયમ અને
                        ખરીદદારની માર્ગદર્શિકા પ્રમાણે બાળ મજુરી રોકવા અને
                        અવ્યવહારુ મજુર પ્રથા, વ્યવસાયિક સ્વસ્થ અને સલામતી,
                        પર્યાવરણનું રક્ષણ અને સારી ખેત પદ્ધતિ વગેરે માટે જરૂરી
                        છે. જો કાયદા માટે જરૂરી હશે તો કંપનીને આ માહિતીનો બહારની
                        Compliance અને Sustainability Audits માટે જાહેરાત કરવાનો
                        અધિકાર રહેશે.
                      </p>
                      <p className="bm-10">
                        ૭. જો ખેડૂત કંપનીના પ્રતિનિધિ અથવા કંપનીએ નિયુક્ત કરેલા
                        ત્રીજા પક્ષકારને સાથ સહકાર આપશે નહી અથવા તેઓની સાથે
                        ગેરવર્તણુક કરશે તો કંપનીને જેતે સમયે ખેડૂતની નોધણી રદ
                        કરવાનો અધિકાર રહેશે.
                      </p>
                      <div className="bm-10">
                        ૮. ખેતરમાં નીચે જણાવેલી કામોની સખત મનાઈ છે જે સ્થાનિક
                        અને રાષ્ટ્રીય કાયદાની વિરૂધ્ધ છે તેમજ સમાજના હિત માટે
                        હાનીકારક છે. જો કોઈ ખેડૂત નીચે જણાવેલા પ્રવુતિમાં સામેલ
                        થશે તો કંપનીને યોગ્ય નિર્ણય લેવાનો અધિકાર રહેશે જે આ
                        કરારને રદ કરવા સુધી મર્યાદિત નથી.
                        <div className="row">
                          <div className="col-lg-6">
                            <ul>
                              <li>બાળ મજૂરનો ઉપયોગ</li>
                              <li>
                                પાક ઉત્પાદનમાં બિન-અધિકૃત કેમિક્લનો ઉપયોગ કરવો
                              </li>
                              <li>માનવ અધિકારનું ઉલ્લંઘન</li>
                              <li>
                                અસલામત અથવા જોખમી કામ કરવાની પરિસ્થિતિ
                                <ol>PPE વગર લીલી તમાકુમાં કામ કરવુ</ol>
                                <ol>કેમીકલનો ખોટી રીતે ઉપયોગ કરવો</ol>
                                <ol>લાંબા કામના કલાકો</ol>
                              </li>
                            </ul>
                          </div>
                          <div className="col-lg-6">
                            <ul>
                              <li>બળજબરી થી મજુરી કરાવવી</li>
                              <li>
                                સગર્ભા સ્ત્રી/ સ્તનપાન કરવાતી મહિલા કેમિકલ જોડે
                                કામ કરતી
                              </li>
                              <li>મજુરને બરોબર ચુકવણી ના કરવી</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <p className="bm-10">
                        ૯. જો ખેડૂત ખરાબ માલ લાવશે અથવા બીજા કોઈ ખેડૂતનો માલ
                        લાવશે તો કંપનીને તે માલ અસ્વીકાર કરવાનો અધિકાર રહેશે.
                      </p>
                      <p className="bm-10">
                        ૧૦. કંપનીના પ્રતિનિધિ દ્વારા ખેડૂત અથવા ખેડૂતના
                        પ્રતિનિધિની હાજરીમાં ફેબ્રુવારીના ત્રીજા અઠવાડિયાથી
                        ભુકા/ માલ ના ઉતારનો અંદાજ કાઢવામાં આવશે. ખેડૂત અથવા
                        ખેડૂતના પ્રતિનિધિએ ઉતારના અંદાજીત મણના ડોક્યુમેન્ટ ઉપર
                        સહી કરવાની રહેશે. જો ખેડૂત અંદાજીત ઉતારા કરતા અલગ/ વધારે
                        માલ લાવશે તો કંપની જરૂરી પગલા લેશે.
                      </p>
                      <p className="bm-10">
                        ૧૧. ખેડૂતે ફરજીયાત PPE કીટનો ઉપયોગ કરવાનો રહેશે. કંપની
                        જયારે ખેડૂત માલ લઈને આવશે ત્યારે બીલની રકમ સાથે PPE કીટ
                        અને જૈવિક ખાતરના પૈસા ભરપાઈ કરશે. કોઈ કારણસર ખેડૂત કે
                        કંપની દ્વારા કેન્સલ કરેલા વીઘાના PPE કીટ અને જૈવિક
                        ખાતરના પૈસા કંપની ભરપાઈ કરશે નહી. ભરપાઈની રકમ માટે જેટલા
                        વીઘાનો માલ કંપનીને આપવામાં આવ્યો હશે તેટલા જ વીઘાની
                        ગણતરી કરવામાં આવશે.
                      </p>
                      <p className="bm-10">
                        ૧૨. SNP ખેડૂત તરીકે લાયક બનવા માટે ખેડૂતે SNP ના ધોરણો
                        (નિયમો) નું પાલન કરવાનું રહેશે (ઓછામાં ઓછી લાયકાત માટે).
                        જો ખેડૂત આવું નહી કરે તો કંપની જરૂરી પગલા લેશે.
                      </p>
                      <p className="bm-10">
                        ૧૩. ખેડૂતને ધ્યાન પૂર્વક બાંયધરી પત્રક વાંચવાની સલાહ
                        આપવામાં આવે છે. જો તેને આ ડોકચુમેન્ટને લગતા કોઈ પ્રશ્ન
                        હોય તો સહી કરતા પહેલા તે કંપનીના પ્રતિનિધિ જોડે ચર્ચા
                        કરી શકે છે.
                      </p>
                      <p className="bm-10">
                        ૧૪. આ બાંયધરી પત્રક ફક્ત ચાલુ વર્ષના પાક પૂરતું મર્યાદિત
                        છે. આવતા વર્ષે નીસોલ નિકોટીને પાક કરવો કે નહીં તે
                        ખેડૂતની મરજી પર રહેશે.
                      </p>

                      <p style={{ textAlign: "left" }}>
                        ઉપરોક્ત મુદ્દાઓ મે વાંચ્યા છે, સમજ્યા છે અને હું સંમત
                        છું.
                      </p>
                      <br />
                      <br />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="d-flex justify-content-between">
                          <p>
                            <strong>
                              નિસોલ મેન્યુફેક્ચરિંગ કંપની પ્રાઇવેટ લિમિટેડ
                            </strong>
                          </p>
                          <p>
                            <strong>ખેડૂતની સહી અને તારીખ</strong>
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <p className="bm-10">ફોર્મ ભરનારનું નામ (FT): Admin</p>
                        <p className="bm-10">
                          ફોર્મ ચેક કરનાર નું નામ (FT/ FO): Admin{" "}
                        </p>
                        <p className="bm-10">
                          PPE Kit Bill No. :{" "}
                          {_ppeKit.map((x, idx) => {
                            return (
                              <li style={{ listStyle: "none" }}>{`(${
                                idx + 1
                              }) Inv.#:${x.invoiceNo}(Rs. ${Number(
                                x.amount
                              ).toFixed(2)})`}</li>
                            );
                          })}
                        </p>
                        <p className="bm-10">
                          Bio Fertilizer Bill No. :{" "}
                          {_bioFer.map((x, idx) => {
                            return (
                              <li style={{ listStyle: "none" }}>{`(${
                                idx + 1
                              }) Inv.#:${x.invoiceNo}(Rs. ${Number(
                                x.amount
                              ).toFixed(2)})`}</li>
                            );
                          })}
                        </p>
                      </div>
                    </div>

                    {/* <div className="container">
            <div className="row">
              <div className="col-12">
                <strong>
                  <u>મુખ્ય ખેડૂત માટે જરૂરી પુરાવા: </u>
                </strong>
              </div>
              <div className="col-12">
                <ul>
                  <li>પાસપોર્ટ સાઈઝ ફોટો - ૧ નંગ</li>
                  <li>
                    ઓળખ નો પુરાવો ની ઝેરોક્ષ્ (આધાર કાર્ડ, ચુંટણી કાર્ડ અથવા
                    બીજો કોઈ ફોટા સાથે નો પુરાવો)
                  </li>
                  <li>૭/૧૨ જમીન ની નવી નકલ</li>
                  <li>બેંક ના ખાતાની વિગત (નવા ખેડૂત માટે)</li>
                </ul>
                <br />
              </div>
              <div className="col-12">
                <strong>
                  <u>
                    ઘિરો અથવા દાણે લીધેલ જમીન ખેડતા ખેડૂત માટે જરૂરી પુરાવા:{" "}
                  </u>
                </strong>
              </div>
              <div className="col-12">
                <ul>
                  <li>પાસપોર્ટ સાઈઝ ફોટો - ૧ નંગ</li>
                  <li>
                    ઓળખ નો પુરાવો ની ઝેરોક્ષ્ (આધાર કાર્ડ, ચુંટણી કાર્ડ અથવા
                    બીજો કોઈ ફોટા સાથે નો પુરાવો)
                  </li>
                  <li>
                    ઘિરો અથવા દાણે લીધેલ જમીનના નોટેરી કરેલ દસ્તાવેજની નકલ તથા
                    જેતે જમીન ની ૭/૧૨ જમીન ની નકલ
                  </li>
                  <li>બેંક ના ખાતાની વિગત (નવા ખેડૂત માટે)</li>
                </ul>
              </div>
            </div>
          </div> */}
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div className="container">
                    <div className="row">
                      <div className="col-4">{`Revision: 05`}</div>
                      <div className="col-4">{`Doc No: Agri/027/05.00`}</div>
                      <div className="col-4">{`Effective: 10/06/2024`}</div>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
});

export default DownloadFarmerAgreement;
