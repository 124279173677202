import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import {
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import TableContainer from "../../Component/TableContainer";
import { toast } from "react-toastify";
import Select from "react-select";

import StateOption from "../../hooks/StateOptions";
import CountryOption from "../../hooks/CountryOption";
import DistrictOption from "../../hooks/DistrictOption";
// eslint-disable-next-line
import { useLocation, useNavigate } from "react-router-dom";
import TalukaOption from "../../hooks/TalukaOption";
import VillageOption from "../../hooks/VillageOption";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import { ClipLoader } from "react-spinners";
import useBreakPoints from "../../hooks/useBreakPoints";
import ImageCapturePopup from "./ImageCapturePopup";
import CheckLandAvalibility from "../Farmer/CheckLandAvailibility";
const genderArray = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
];

const panRegex = /^([A-Z]{5}[0-9]{4}[A-Z]{1})$/;

const FetchFarmer = ({
  updateState,
  fetchDetails,
  setUpdateState,
  aadharCardNo,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // eslint-disable-next-line
  const { isTablet, isMobile } = useBreakPoints();
  const { token, loginDetails } = useSelector((state) => state.layout);

  const [step, setStep] = useState("step5");
  console.log("step: ", step);
  const [loading, setLoading] = useState(false);
  const [editState, setEditState] = useState(false);
  // eslint-disable-next-line
  const [familyInputDataId, setFamilyInputDataId] = useState();
  const [addFarmerFamily, setAddFarmerFamily] = useState([]);
  const [editFarmerFamily, setEditFarmerFamily] = useState([]);
  const [deleteFarmerFamily, setDeleteFarmerFamily] = useState([]);
  const [countryArray, setCountryArray] = useState([]);
  const [stateArray, setStateArray] = useState([]);
  const [districtArray, setDistrictArray] = useState([]);
  const [talukaArray, setTalukaArray] = useState([]);
  const [villageArray, setVillageArray] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  // LandDetails
  const [guardianStateArray, setGuardianStateArray] = useState([]);
  const [guardianDistrictArray, setGuardianDistrictArray] = useState([]);
  const [guardianTalukaArray, setGuardianTalukaArray] = useState([]);
  const [guardianVillageArray, setGuardianVillageArray] = useState([]);
  const [showCapturePopup, setShowCapturePopup] = useState(false);
  const [availibilityModal, setAvailibilityModal] = useState(false);
  const [availibilityData, setAvailibilityData] = useState(false);
  const [availibilityDataIndex, setAvailibilityDataIndex] = useState();
  const [availibilityLoading, setAvailibilityLoading] = useState(false);
  const [checkAvailabilty, setCheckAvailabilty] = useState([]);

  const getUnitOptionsListAPi = useCallback(async () => {
    setLoading(true);
    try {
      let formData = new FormData();

      formData.append("companyId", loginDetails?.companyId);
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}company/unitsbycompany`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];
        // eslint-disable-next-line
        _data?.unitsData[0]?.unitdetails?.map((er) => {
          arr.push({ label: er?.unitName, value: er.id });
        });

        setUnitOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUnitOptionsListAPi();
  }, [getUnitOptionsListAPi]);

  const handleCapture = (imageData) => {
    handleImageChange("farmerPhoto", imageData);
  };
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  // const { getStateOption } = StateOption();
  // const { getCountryOption } = CountryOption();
  // const { getDistrictOption } = DistrictOption();
  // const { getTalukaOption } = TalukaOption();
  // const { getVillageOption } = VillageOption();

  const [inputData, setInputData] = useState({
    farmerId: fetchDetails?.id,
    farmerName: fetchDetails?.name,
    farmerCode: "",
    primaryContactPerson: fetchDetails?.primaryContactPerson,
    farmerPhoto: {
      url: fetchDetails?.photo,
      name: fetchDetails?.photo?.split("/").at(-1).slice(5),
    },
    gender: fetchDetails?.gender,
    email: fetchDetails?.email,
    dob: fetchDetails?.dob,
    addressLine1: fetchDetails?.addressLine1,
    addressLine2: fetchDetails?.addressLine2,
    panNo: fetchDetails?.panNo,
    country: {
      label: fetchDetails?.countryDetails?.name,
      value: fetchDetails?.countryDetails?.id,
    },
    state: {
      label: fetchDetails?.stateDetails?.stateName,
      value: fetchDetails?.stateDetails?.id,
    },
    dist: {
      label: fetchDetails?.districtDetails?.districtName,
      value: fetchDetails?.districtDetails?.id,
    },
    taluka: {
      label: fetchDetails?.talukaDetails?.talukaName,
      value: fetchDetails?.talukaDetails?.id,
    },
    village: {
      label: fetchDetails?.villageDetails?.villageName,
      value: fetchDetails?.villageDetails?.id,
    },
    panCardImage: {
      url: fetchDetails?.panImg,
      name: fetchDetails?.panImg?.split("/").at(-1).slice(5),
    },
    aadharCardNo: fetchDetails?.aadharCardNo,
    pincode: fetchDetails?.pincode,
    phoneNo: fetchDetails?.phoneNo?.split("+91")[1],
    aadharCardImage: {
      url: fetchDetails?.aadharImg,
      name: fetchDetails?.aadharImg?.split("/").at(-1).slice(5),
    },
    mobileNo: fetchDetails?.mobileNo?.split("+91")[1],
    ifscCode: fetchDetails?.bankDetails?.[0]?.ifscCode,
    bankName: fetchDetails?.bankDetails?.[0]?.bankName,
    accountNo: fetchDetails?.bankDetails?.[0]?.accountNo,
    branchName: fetchDetails?.bankDetails?.[0]?.branchName,
    familyDetails: fetchDetails?.familyDetails,
    cancelCheque: {
      url: fetchDetails?.bankDetails?.[0]?.cancelledChequeImg,
      name: fetchDetails?.bankDetails?.[0]?.cancelledChequeImg
        ?.split("/")
        .at(-1)
        .slice(5),
    },
    bankDetailsId: fetchDetails?.bankDetails?.[0]?.id,
  });

  const [inputDataError, setInputDataError] = useState({
    farmerName: false,
    unit: false,
    farmerPhoto: false,
    farmerCode: false,
    gender: false,
    dob: false,
    addressLine1: false,
    addressLine2: false,
    addressLine3: false,
    primaryContactPerson: false,

    country: false,
    state: false,
    dist: false,
    taluka: false,
    aadharCardNo: false,
    pincode: false,
    aadharCardImage: false,
    mobileNo: false,
    ifscCode: false,
    bankName: false,
    accountNo: false,
    branchName: false,
    cancelCheque: false,
    village: false,
    familyName: false,
    familyRelation: false,
    familyGender: false,
    // familyDob: false,
  });

  const [landDetails, setLandDetails] = useState([]);
  console.log("landDetails: ", landDetails);

  const [landDetailsError, setLandDetailsError] = useState([]);

  useEffect(() => {
    if (fetchDetails?.familyDetails) {
      let arr = [];
      // eslint-disable-next-line
      fetchDetails?.familyDetails?.map((er, index) => {
        if (er?.dob) {
          arr.push({
            id: index + 1,
            farmerFamilyDetailsId: er?.id,
            farmerId: inputData?.farmerId,
            name: er?.name,
            relation: er?.relation,
            gender: er?.gender,
            dob: er?.dob,
            age: calculateAge(er?.dob),
          });
        } else {
          arr.push({
            id: index + 1,
            farmerFamilyDetailsId: er?.id,
            farmerId: inputData?.farmerId,
            name: er?.name,
            relation: er?.relation,
            gender: er?.gender,
          });
        }
      });

      setInputData({ ...inputData, familyDetails: arr });
    }
    // eslint-disable-next-line
  }, []);

  const [familyInputData, setFamilyInputData] = useState({
    name: "",
    relation: "",
    gender: "",
    dob: "",
  });

  const [singleFarmingDetails, setSingleFarmingDetails] = useState({
    product: "",
    category: "",
    vigha: "",
    totalVigha: "",
  });
  const [singleFarmingIndex, setSingleFarmingIndex] = useState();
  const [editFarmingDetails, setEditFarmingDetails] = useState(false);

  const [singleFarmingDetailsError, setSingleFarmingDetailsError] = useState({
    product: false,
    category: false,
    vigha: false,
    totalVigha: false,
  });

  const [farmingDetails, setFarmingDetails] = useState([]);

  const [productOptions, setProductOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const { getStateOption } = StateOption();
  const { getCountryOption } = CountryOption();
  const { getDistrictOption } = DistrictOption();
  const { getTalukaOption } = TalukaOption();
  const { getVillageOption } = VillageOption();

  useEffect(() => {
    getCountryOption().then((res) => {
      setCountryArray(res);
    });
    getStateOption(fetchDetails?.countryDetails?.id).then((res) => {
      setStateArray(res);
    });
    getDistrictOption(fetchDetails?.stateDetails?.id).then((res) => {
      setDistrictArray(res);
    });
    getTalukaOption(fetchDetails?.districtDetails?.id).then((res) => {
      setTalukaArray(res);
    });
    getVillageOption(fetchDetails?.talukaDetails?.id).then((res) => {
      setTalukaArray(res);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (landDetails?.length === 0 && inputData) {
      getStateOption(inputData?.country?.value).then((res) => {
        setGuardianStateArray(res);
      });

      getDistrictOption(inputData?.state?.value).then((res) => {
        setGuardianDistrictArray(res);
      });

      getTalukaOption(inputData?.dist?.value).then((res) => {
        setGuardianTalukaArray(res);
      });

      getVillageOption(inputData?.taluka?.value).then((res) => {
        setGuardianVillageArray(res);
      });

      setLandDetails([
        {
          indexId: 1,
          country: "",
          state: "",
          dist: "",
          taluka: "",
          village: "",
          surveyNo: "",
          totalLand: "",
          registeredLand: "",
          vigha: "",
          regVigha: "",
          countryArray: countryArray,
          stateArray: [],
          districtArray: [],
          villageArray: [],
          talukaArray: [],
          ownerShipStatus: false,
          landOwnerName: fetchDetails?.name,
          address:
            fetchDetails?.addressLine1 + " " + fetchDetails?.addressLine1,
          guardianCountry: {
            label: fetchDetails?.countryDetails?.name,
            value: fetchDetails?.countryDetails?.id,
          },
          guardianState: {
            label: fetchDetails?.stateDetails?.stateName,
            value: fetchDetails?.stateDetails?.id,
          },
          guardianDist: {
            label: fetchDetails?.districtDetails?.districtName,
            value: fetchDetails?.districtDetails?.id,
          },
          guardianTaluka: {
            label: fetchDetails?.talukaDetails?.talukaName,
            value: fetchDetails?.talukaDetails?.id,
          },
          guardianVillage: {
            label: fetchDetails?.villageDetails?.villageName,
            value: fetchDetails?.villageDetails?.id,
          },
          guardianPincode: fetchDetails?.pincode,
          guardianName: "",
          tenantDocument: "",
          agreementDocument: "",
          landDocument: "",
        },
      ]);

      setLandDetailsError([
        ...landDetailsError,
        {
          country: false,
          state: false,
          dist: false,
          taluka: false,
          village: false,
          surveyNo: false,
          totalLand: false,
          registeredLand: false,
          vigha: false,
          regVigha: false,
          ownerShipStatus: false,
          landOwnerName: false,
          address: false,
          guardianCountry: false,
          guardianState: false,
          guardianDist: false,
          guardianTaluka: false,
          guardianVillage: false,
          guardianPincode: false,
          guardianName: false,
          tenantDocument: false,
          agreementDocument: false,
          landDocument: false,
        },
      ]);

      setCheckAvailabilty([...checkAvailabilty, { check: false }]);
    }
  }, [landDetails, inputData]);

  // handleChange Input
  const handleChangeInput = (name, value) => {
    if (name === "farmerName") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, farmerName: false });
      } else {
        setInputDataError({ ...inputDataError, farmerName: true });
      }
    } else if (name === "farmerCode") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, farmerCode: false });
      } else {
        setInputDataError({ ...inputDataError, farmerCode: true });
      }
    } else if (name === "primaryContactPerson") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, primaryContactPerson: false });
      } else {
        setInputDataError({ ...inputDataError, primaryContactPerson: true });
      }
    } else if (name === "unit") {
      setInputData({ ...inputData, [name]: value });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, unit: false });
      } else {
        setInputDataError({ ...inputDataError, unit: true });
      }
    } else if (name === "gender") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, gender: false });
      } else {
        setInputDataError({ ...inputDataError, gender: true });
      }
    } else if (name === "dob") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, dob: false });
      } else {
        setInputDataError({ ...inputDataError, dob: true });
      }
    } else if (name === "addressLine1") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, addressLine1: false });
      } else {
        setInputDataError({ ...inputDataError, addressLine1: true });
      }
    } else if (name === "addressLine2") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, addressLine2: false });
      } else {
        setInputDataError({ ...inputDataError, addressLine2: true });
      }
    } else if (name === "addressLine3") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, addressLine3: false });
      } else {
        setInputDataError({ ...inputDataError, addressLine3: true });
      }
    } else if (name === "panNo") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0 && panRegex.test(value)) {
        setInputDataError({ ...inputDataError, panNo: false });
      } else {
        setInputDataError({ ...inputDataError, panNo: true });
      }
    } else if (name === "village") {
      setInputData({ ...inputData, [name]: value });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, village: false });
      } else {
        setInputDataError({ ...inputDataError, village: true });
      }
    } else if (name === "aadharCardNo") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length === 12) {
        setInputDataError({ ...inputDataError, aadharCardNo: false });
      } else {
        setInputDataError({ ...inputDataError, aadharCardNo: true });
      }
    } else if (name === "pincode") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length === 6) {
        setInputDataError({ ...inputDataError, pincode: false });
      } else {
        setInputDataError({ ...inputDataError, pincode: true });
      }
    } else if (name === "mobileNo") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length === 10) {
        setInputDataError({ ...inputDataError, mobileNo: false });
      } else {
        setInputDataError({ ...inputDataError, mobileNo: true });
      }
    } else if (name === "bankName") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, bankName: false });
      } else {
        setInputDataError({ ...inputDataError, bankName: true });
      }
    } else if (name === "ifscCode") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0 && validateIFSCCode(value)) {
        setInputDataError({ ...inputDataError, ifscCode: false });
      } else {
        setInputDataError({ ...inputDataError, ifscCode: true });
      }
    } else if (name === "accountNo") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, accountNo: false });
      } else {
        setInputDataError({ ...inputDataError, accountNo: true });
      }
    } else if (name === "branchName") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, branchName: false });
      } else {
        setInputDataError({ ...inputDataError, branchName: true });
      }
    }

    if (name === "country") {
      getStateOption(value.value).then((res) => {
        setStateArray(res);
      });
      setInputData({
        ...inputData,
        [name]: value,
        state: "",
        dist: "",
        taluka: "",
        village: "",
      });

      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, country: false });
      } else {
        setInputDataError({ ...inputDataError, country: true });
      }
    } else if (name === "state") {
      getDistrictOption(value.value).then((res) => {
        setDistrictArray(res);
      });
      setInputData({
        ...inputData,
        [name]: value,
        dist: "",
        taluka: "",
        village: "",
      });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, state: false });
      } else {
        setInputDataError({ ...inputDataError, state: true });
      }
    } else if (name === "dist") {
      getTalukaOption(value.value).then((res) => {
        setTalukaArray(res);
      });
      setInputData({ ...inputData, [name]: value, taluka: "", village: "" });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, dist: false });
      } else {
        setInputDataError({ ...inputDataError, dist: true });
      }
    } else if (name === "taluka") {
      getVillageOption(value.value).then((res) => {
        setVillageArray(res);
      });
      setInputData({ ...inputData, [name]: value, village: "" });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, taluka: false });
      } else {
        setInputDataError({ ...inputDataError, taluka: true });
      }
    } else {
      setInputData({ ...inputData, [name]: value });
    }
  };
  // handleChange Family Details Input
  const handleChangFamilyDetailsInput = (name, value) => {
    if (name === "name") {
      setFamilyInputData({ ...familyInputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, familyName: false });
      } else {
        setInputDataError({ ...inputDataError, familyName: true });
      }
    } else if (name === "relation") {
      setFamilyInputData({ ...familyInputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, familyRelation: false });
      } else {
        setInputDataError({ ...inputDataError, familyRelation: true });
      }
    } else if (name === "gender") {
      setFamilyInputData({ ...familyInputData, [name]: value });

      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, familyGender: false });
      } else {
        setInputDataError({ ...inputDataError, familyGender: true });
      }
    }
  };

  // Age Calculation Function
  function calculateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const now = new Date();

    let age = now.getFullYear() - dob.getFullYear();

    // Check if the birthday for the current year has already occurred
    // If not, subtract 1 from the age
    if (
      now.getMonth() < dob.getMonth() ||
      (now.getMonth() === dob.getMonth() && now.getDate() < dob.getDate())
    ) {
      age--;
    }
    return age;
  }

  // handle Add Btn
  const handleAddIconStep2 = () => {
    if (!familyInputData?.name) {
      setInputDataError({ ...inputDataError, familyName: true });
      toast.error("Name is Required!");
    } else if (!familyInputData?.relation) {
      setInputDataError({ ...inputDataError, familyRelation: true });
      toast.error("Relation  is Required!");
    } else if (!familyInputData?.gender) {
      setInputDataError({ ...inputDataError, familyGender: true });
      toast.error("Gender is Required!");
    }
    // else if (!familyInputData?.dob) {
    //   setInputDataError({ ...inputDataError, familyDob: true });
    //   toast.error("dob is Required!");
    // }
    else {
      if (editState) {
        let arr2 = [...editFarmerFamily];
        let filteredData = inputData?.familyDetails?.map((er) => {
          if (er?.id === familyInputData.id) {
            let newData;
            if (familyInputData?.dob) {
              newData = {
                id: familyInputData?.id,
                farmerId: inputData?.farmerId,
                name: familyInputData?.name,
                relation: familyInputData?.relation,
                gender: familyInputData?.gender?.value,
                dob: familyInputData?.dob,
                age: calculateAge(familyInputData?.dob),
              };
            } else {
              newData = {
                id: familyInputData?.id,
                farmerId: inputData?.farmerId,
                name: familyInputData?.name,
                relation: familyInputData?.relation,
                gender: familyInputData?.gender?.value,
              };
            }
            return {
              ...er,
              ...newData,
            };
          }
          return er;
        });
        // eslint-disable-next-line
        inputData?.familyDetails?.map((er) => {
          if (er?.id === familyInputData.id) {
            if (er?.dob) {
              arr2.push({
                id: familyInputData?.id,
                farmerId: inputData?.farmerId,
                farmerFamilyDetailsId: er?.farmerFamilyDetailsId,
                name: familyInputData?.name,
                relation: familyInputData?.relation,
                gender: familyInputData?.gender?.value,
                dob: familyInputData?.dob,
                age: calculateAge(familyInputData?.dob),
              });
            } else {
              arr2.push({
                id: familyInputData?.id,
                farmerId: inputData?.farmerId,
                farmerFamilyDetailsId: er?.farmerFamilyDetailsId,
                name: familyInputData?.name,
                relation: familyInputData?.relation,
                gender: familyInputData?.gender?.value,
              });
            }
          }
        });

        setEditFarmerFamily(arr2);
        setInputData({ ...inputData, familyDetails: filteredData });
        setFamilyInputData({ name: "", relation: "", gender: "", dob: "" });
        setEditState(false);
      } else {
        let arr = [...inputData?.familyDetails];
        let arr2 = [...addFarmerFamily];
        if (familyInputData?.dob) {
          arr.push({
            id: inputData?.familyDetails?.length + 1,
            farmerId: inputData?.farmerId,
            name: familyInputData?.name,
            relation: familyInputData?.relation,
            gender: familyInputData?.gender?.value,
            dob: familyInputData?.dob,
            age: calculateAge(familyInputData?.dob),
          });
          arr2.push({
            id: inputData?.familyDetails?.length + 1,
            farmerId: inputData?.farmerId,
            name: familyInputData?.name,
            relation: familyInputData?.relation,
            gender: familyInputData?.gender?.value,
            dob: familyInputData?.dob,
            age: calculateAge(familyInputData?.dob),
          });
        } else {
          arr.push({
            id: inputData?.familyDetails?.length + 1,
            farmerId: inputData?.farmerId,
            name: familyInputData?.name,
            relation: familyInputData?.relation,
            gender: familyInputData?.gender?.value,
          });
          arr2.push({
            id: inputData?.familyDetails?.length + 1,
            farmerId: inputData?.farmerId,
            name: familyInputData?.name,
            relation: familyInputData?.relation,
            gender: familyInputData?.gender?.value,
          });
        }
        setAddFarmerFamily(arr2);
        setInputData({ ...inputData, familyDetails: arr });
        setFamilyInputData({ name: "", relation: "", gender: "", dob: "" });
      }
    }
  };

  const handleDeleteIconStep2 = (index) => {
    let arr = [...inputData?.familyDetails];
    let arr2 = [...deleteFarmerFamily];

    arr2.push(arr[index]);
    arr.splice(index, 1);
    setDeleteFarmerFamily(arr2);
    setInputData({ ...inputData, familyDetails: arr });
  };

  // Farmer Upload Photo
  const farmerPhotoImage = useRef(null);
  const panCardPhotoImage = useRef(null);
  const aadharCardPhotoImage = useRef(null);
  const cancelChequeImage = useRef(null);

  // Image HandleChange
  // eslint-disable-next-line
  const handlefarmerPhotoImageUploadBtn = () =>
    farmerPhotoImage.current.click();
  const handlepanCardPhotoImageUploadBtn = () =>
    panCardPhotoImage.current.click();
  const handleaadharCardPhotoImageUploadBtn = () =>
    aadharCardPhotoImage.current.click();
  const handlecancelChequeImageUploadBtn = () =>
    cancelChequeImage.current.click();

  //   HandleChange Farmer Photo
  const handleImageChange = async (name, value) => {
    if (value) {
      const allowedTypes = ["image/jpeg", "image/png"];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!allowedTypes.includes(value.type)) {
        toast.error("Invalid file type. Please select a JPEG, PNG.");
      } else if (value.size > maxSize) {
        toast.error("File size exceeds the limit of 5MB.");
      } else {
        if (name === "farmerPhoto") {
          setInputDataError({ ...inputDataError, farmerPhoto: false });
        } else if (name === "aadharCardImage") {
          setInputDataError({ ...inputDataError, aadharCardImage: false });
        } else if (name === "cancelCheque") {
          setInputDataError({ ...inputDataError, cancelCheque: false });
        }

        const formData = new FormData();
        formData.append("media", value);

        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/uploadmedia`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          setInputData({
            ...inputData,
            [name]: { url: _data.uploadedURL, name: _data?.filename },
          });
        } else {
          toast.error(_data?.error);
        }

        return { url: "https://httpbin.org/post" };
      }
    }
  };
  // handleNext Btn
  const handleNextBtn = (stepper) => {
    if (stepper === "step1") {
      if (!inputData?.farmerName) {
        toast.error("Farmer Name is Required!");
        setInputDataError({ ...inputDataError, farmerName: true });
      } else if (!inputData.farmerCode) {
        setInputDataError({ ...inputDataError, farmerCode: true });
        toast.error("Farmer Code Is required!");
      } else if (!inputData.unit) {
        setInputDataError({ ...inputDataError, unit: true });
        toast.error("Unit Is required!");
      }
      // else if (!inputData.farmerPhoto) {
      //   setInputDataError({ ...inputDataError, farmerPhoto: true });
      //   toast.error("Farmer Photo is Required!");
      // }
      else if (!inputData?.gender) {
        setInputDataError({ ...inputDataError, gender: true });

        toast.error("Gender is Required!");
      } else if (!inputData?.dob) {
        setInputDataError({ ...inputDataError, dob: true });

        toast.error("DOB is Required!");
      } else if (!inputData?.addressLine1) {
        setInputDataError({ ...inputDataError, addressLine1: true });

        toast.error("Address Line 1 is Required!");
      } else if (!inputData?.addressLine2) {
        setInputDataError({ ...inputDataError, addressLine2: true });

        toast.error("Address Line 2 is Required!");
      }
      // else if (!inputData?.panNo) {
      //   setInputDataError({ ...inputDataError, panNo: true });

      //   toast.error("Pan No  is Required!");
      // }
      else if (
        inputData?.panNo?.length > 0 &&
        !panRegex.test(inputData?.panNo)
      ) {
        setInputDataError({ ...inputDataError, panNo: true });

        toast.error("PAN No is not in Correct Format!");
      } else if (!inputData?.country) {
        setInputDataError({ ...inputDataError, country: true });

        toast.error("Country is Required!");
      } else if (!inputData?.state) {
        setInputDataError({ ...inputDataError, state: true });

        toast.error("State is Required!");
      }
      // else if (!inputData?.panCardImage) {
      //   setInputDataError({ ...inputDataError, panCardImage: true });

      //   toast.error("PanCard Image is Required!");
      // }
      else if (!inputData?.dist) {
        setInputDataError({ ...inputDataError, dist: true });

        toast.error("Dist is Required!");
      } else if (!inputData?.taluka) {
        setInputDataError({ ...inputDataError, taluka: true });

        toast.error("taluka is Required!");
      } else if (!inputData?.village) {
        setInputDataError({ ...inputDataError, village: true });

        toast.error("Village is Required!");
      } else if (!inputData?.pincode) {
        toast.error("Pincode is Required!");
        setInputDataError({ ...inputDataError, pincode: true });
      } else if (
        inputData?.pincode?.length < 6 ||
        inputData?.pincode?.length > 6
      ) {
        toast.error("Pincode is not in Correct Format!");
        setInputDataError({ ...inputDataError, pincode: true });
      } else if (
        (inputData?.phoneNo && inputData?.phoneNo?.length < 10) ||
        inputData?.phoneNo?.length > 10
      ) {
        toast.error("phone No is not in Correct Format!");
      } else if (!inputData?.mobileNo) {
        setInputDataError({ ...inputDataError, mobileNo: true });

        toast.error("Mobile No is Required!");
      } else if (
        (inputData?.mobileNo && inputData?.mobileNo?.length < 10) ||
        inputData?.mobileNo?.length > 10
      ) {
        setInputDataError({ ...inputDataError, mobileNo: true });

        toast.error("Mobile No is not in Correct Format!");
      } else if (!inputData?.primaryContactPerson) {
        toast.error("Designated Successor is Required!");
        setInputDataError({ ...inputDataError, primaryContactPerson: true });
      } else {
        setStep("step2");
      }
    }

    if (stepper === "step2") {
      // Condition Added Based on Dicussion on 16-09-2023 by Dweep Sir
      if (inputData?.familyDetails?.length <= 0) {
        toast.error("minimum of one Family Detail is Required");
      } else {
        setStep("step3");
      }
    }

    if (stepper === "step3") {
      if (!inputData?.bankName) {
        setInputDataError({ ...inputDataError, bankName: true });
        toast.error("Bank Name is Required!");
      } else if (!inputData?.ifscCode) {
        setInputDataError({ ...inputDataError, ifscCode: true });
        toast.error("IFSC Code   is Required!");
      } else if (!validateIFSCCode(inputData?.ifscCode)) {
        setInputDataError({ ...inputDataError, ifscCode: true });
        toast.error("IFSC Code is not in Correct Format!");
      } else if (!inputData?.accountNo) {
        setInputDataError({ ...inputDataError, accountNo: true });
        toast.error("Account No is Required!");
      } else if (!inputData?.branchName) {
        setInputDataError({ ...inputDataError, branchName: true });
        toast.error("Branch Name is Required!");
      } else if (!inputData?.cancelCheque) {
        setInputDataError({ ...inputDataError, cancelCheque: true });
        toast.error("Cancel Cheque is Required!");
      } else {
        setStep("step6");
      }
    }

    if (stepper === "step5") {
      let error = false;

      // eslint-disable-next-line
      landDetails?.map((er, index) => {
        console.log("checkAvailabilty[index]?.check: ", checkAvailabilty);
        if (!er?.country) {
          toast.error("Country is Required!");
          let arr = [...landDetailsError];
          arr[index]["country"] = true;
          setLandDetailsError(arr);
          error = true;
        } else if (!er?.state) {
          toast?.error("State is Required!");
          let arr = [...landDetailsError];
          arr[index]["state"] = true;
          setLandDetailsError(arr);
          error = true;
        } else if (!er?.dist) {
          toast?.error("District is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["dist"] = true;
          setLandDetailsError(arr);
        } else if (!er?.taluka) {
          toast?.error("Taluka is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["taluka"] = true;
          setLandDetailsError(arr);
        } else if (!er?.village) {
          toast?.error("village is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["village"] = true;
          setLandDetailsError(arr);
        } else if (!er?.address) {
          toast?.error("address is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["address"] = true;
          setLandDetailsError(arr);
        } else if (!er?.surveyNo) {
          toast?.error("surveyNo is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["surveyNo"] = true;
          setLandDetailsError(arr);
        } else if (!er?.totalLand) {
          toast?.error("Total Land is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["totalLand"] = true;
          setLandDetailsError(arr);
        } else if (!er?.vigha) {
          toast?.error("Vigha is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["vigha"] = true;
          setLandDetailsError(arr);
        } else if (!er?.registeredLand) {
          toast?.error("Registered Land is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["registeredLand"] = true;
          setLandDetailsError(arr);
        } else if (!er?.regVigha) {
          toast?.error("Register Vigha is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["regVigha"] = true;
          setLandDetailsError(arr);
        } else if (!er?.address) {
          toast?.error("Country is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["address"] = true;
          setLandDetailsError(arr);
        } else if (!er?.guardianCountry) {
          toast?.error("Country is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["guardianCountry"] = true;
          setLandDetailsError(arr);
        } else if (!er?.guardianState) {
          toast?.error("State is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["guardianState"] = true;
          setLandDetailsError(arr);
        } else if (!er?.guardianDist) {
          toast?.error("District is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["guardianDist"] = true;
          setLandDetailsError(arr);
        } else if (!er?.guardianTaluka) {
          toast?.error("Taluka is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["guardianTaluka"] = true;
          setLandDetailsError(arr);
        } else if (!er?.guardianVillage) {
          toast?.error("village is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["guardianVillage"] = true;
          setLandDetailsError(arr);
        } else if (!er?.guardianPincode) {
          toast.error("Pincode is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["guardianPincode"] = true;
          setLandDetailsError(arr);
        } else if (
          er?.guardianPincode?.length < 6 ||
          er?.guardianPincode?.length > 6
        ) {
          toast.error("Pincode is not in Correct Format!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["guardianPincode"] = true;
          setLandDetailsError(arr);
        } else if (!er?.landDocument) {
          toast?.error("LandDocument is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["landDocument"] = true;
          setLandDetailsError(arr);
        } else if (!er?.tenantDocument && er?.ownerShipStatus) {
          toast?.error("Tenant Document is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["tenantDocument"] = true;
          setLandDetailsError(arr);
        } else if (!er?.agreementDocument && er?.ownerShipStatus) {
          toast?.error("Agreement Document is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["agreementDocument"] = true;
          setLandDetailsError(arr);
        } else if (!checkAvailabilty[index]?.check) {
          toast?.error("Please Check Land Availabilty");
          error = true;
        }
      });

      if (landDetails?.length <= 0) {
        toast.error("Minimum one land is Required");
      } else if (!inputData?.aadharCardNo) {
        setInputDataError({ ...inputDataError, aadharCardNo: true });

        toast.error("AadharCard No is Required!");
      } else if (
        (inputData?.aadharCardNo && inputData?.aadharCardNo?.length < 12) ||
        inputData?.aadharCardNo?.length > 12
      ) {
        toast.error("AadharCard No is not in Correct Format!");
        setInputDataError({ ...inputDataError, aadharCardNo: true });
      } else if (!inputData?.aadharCardImage) {
        toast.error("AadharCard Image is Required!");
        setInputDataError({ ...inputDataError, aadharCardImage: true });
      } else if (!error) {
        setStep("step1");

        let totalVigha = landDetails?.reduce(
          (acc, curr) => acc + parseInt(curr?.regVigha),
          0
        );
        console.log("totalVigha: ", totalVigha);

        setSingleFarmingDetails({
          ...singleFarmingDetails,
          totalVigha: totalVigha,
        });
      }
    }
  };
  const validateIFSCCode = (code) => {
    // Regular expression pattern for IFSC code validation
    const ifscPattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;

    return ifscPattern.test(code);
  };

  // step2 Table Column
  const column = useMemo(
    () => [
      {
        Header: "Farmer ID",
        accessor: "farmerId",
        filterable: false,
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
      },
      {
        Header: "Gender",
        accessor: "gender",
        filterable: false,
      },
      {
        Header: "Relation",
        accessor: "relation",
        filterable: false,
      },
      // {
      //   Header: "Age",
      //   accessor: "age",
      //   filterable: false,
      // },
      {
        id: "Action",
        Header: () => (
          <span
            className="d-flex justify-content-center"
            style={{ textAlign: "center" }}
          >
            Action
          </span>
        ),
        Cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <div className="hstack gap-2">
                {/* Edit Button */}
                <button
                  className="border-0"
                  style={{ background: "transparent" }}
                  onClick={() =>
                    handleEditStateFamilyDetails(
                      cellProps.row.original,
                      cellProps.row.index
                    )
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_32_318)">
                      <path
                        d="M6.87549 2.5H2.50049C2.16897 2.5 1.85103 2.6317 1.6166 2.86612C1.38218 3.10054 1.25049 3.41848 1.25049 3.75V12.5C1.25049 12.8315 1.38218 13.1495 1.6166 13.3839C1.85103 13.6183 2.16897 13.75 2.50049 13.75H11.2505C11.582 13.75 11.9 13.6183 12.1344 13.3839C12.3688 13.1495 12.5005 12.8315 12.5005 12.5V8.125"
                        stroke="#3E7531"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.563 1.56249C11.8116 1.31385 12.1489 1.17416 12.5005 1.17416C12.8521 1.17416 13.1893 1.31385 13.438 1.56249C13.6866 1.81113 13.8263 2.14836 13.8263 2.49999C13.8263 2.85162 13.6866 3.18885 13.438 3.43749L7.50049 9.37499L5.00049 9.99999L5.62549 7.49999L11.563 1.56249Z"
                        stroke="#3E7531"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_32_318">
                        <rect
                          width="15"
                          height="15"
                          fill="white"
                          transform="translate(0.000488281)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>

                {/* Delete Button */}
                <button
                  className="border-0"
                  style={{ background: "transparent" }}
                  onClick={() => handleDeleteIconStep2(cellProps.row.index)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_32_330)">
                      <path
                        d="M6.63507 -0.0178833C5.50545 -0.0178833 4.59795 0.92077 4.59795 2.05558V2.30769H2.30814C1.99026 2.30769 1.73122 2.56673 1.73122 2.88462V3.46154H1.1543V4.61539H2.30814V13.2692C2.30814 14.2183 3.08987 15 4.03891 15H10.962C11.911 15 12.6928 14.2183 12.6928 13.2692V4.61539H13.8466V3.46154H13.2697V2.88462C13.2697 2.56673 13.0106 2.30769 12.6928 2.30769H10.403V2.055C10.403 0.920771 9.49545 -0.0178833 8.36584 -0.0178833H6.63507ZM6.63507 1.17173H8.36584C8.82968 1.17173 9.21334 1.55019 9.21334 2.055V2.30769H5.78699V2.055C5.78699 1.55019 6.17064 1.17231 6.63449 1.17231L6.63507 1.17173ZM3.46199 4.61539H6.41872C6.49026 4.62289 6.56122 4.63327 6.63507 4.63327H8.36584C8.43968 4.63327 8.51007 4.62289 8.58218 4.61539H11.5389V13.2692C11.5389 13.594 11.2868 13.8462 10.962 13.8462H4.03891C3.7141 13.8462 3.46199 13.594 3.46199 13.2692V4.61539ZM4.61584 5.76923V12.6923H5.76968V5.76923H4.61584ZM6.92353 5.76923V12.6923H8.07737V5.76923H6.92353ZM9.23122 5.76923V12.6923H10.3851V5.76923H9.23122Z"
                        fill="#EC0505"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_32_330">
                        <rect
                          width="15"
                          height="15"
                          fill="white"
                          transform="translate(0.000488281)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>
          );
        },
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    [inputData?.familyDetails]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (landDetails?.length <= 0) {
      toast.error("Minimum one land is Required");
    } else if (farmingDetails?.length <= 0) {
      toast.error("Minimum Farming Details is Required");
    } else {
      setLoading(true);
      let formData = new FormData();

      formData.append("farmerAadharNo", aadharCardNo);
      formData.append("id", fetchDetails?.id);
      // formData.append("companyId", "1");
      // formData.append("unitId", "1");
      // formData.append("financialYearId", "1");
      formData.append("name", inputData?.farmerName);
      formData.append("farmerCode", inputData?.farmerCode);
      formData.append("primaryContactPerson", inputData?.primaryContactPerson);
      formData.append("unitId", inputData.unit.value);
      formData.append("email", inputData?.email);
      if (inputData.phoneNo?.length > 0) {
        formData.append("phoneNo", `+91${inputData?.phoneNo}`);
      }
      if (inputData.mobileNo?.length > 0) {
        formData.append("mobileNo", `+91${inputData?.mobileNo}`);
      }
      formData.append("gender", inputData?.gender);
      formData.append("dob", inputData?.dob);
      formData.append("addressLine1", inputData?.addressLine1);
      formData.append("addressLine2", inputData?.addressLine2);
      formData.append("addressLine3", inputData?.addressLine3);
      formData.append("countryId", inputData?.country?.value);
      formData.append("stateId", inputData?.state?.value);
      formData.append("districtId", inputData?.dist?.value);
      formData.append("talukaId", inputData?.taluka.value);
      formData.append("villageId", inputData?.village.value);
      formData.append("pincode", inputData?.pincode);
      formData.append("aadharCardNo", inputData?.aadharCardNo);
      formData.append("panNo", inputData?.panNo);

      if (inputData?.farmerPhoto.url) {
        formData.append("photo", inputData?.farmerPhoto.url);
      }
      if (inputData?.panCardImage.url) {
        formData.append("panImg", inputData?.panCardImage.url);
      }
      formData.append("aadharImg", inputData?.aadharCardImage.url);
      formData.append("bankName", inputData?.bankName);
      formData.append("accountNo", inputData?.accountNo);
      formData.append("ifscCode", inputData?.ifscCode);
      formData.append("branchName", inputData?.branchName);
      formData.append("bankDetailsId", inputData?.bankDetailsId);
      formData.append("cancelledChequeImg", inputData?.cancelCheque.url);
      formData.append("familyData", JSON.stringify(inputData?.familyDetails));
      formData.append("newData", JSON.stringify(addFarmerFamily));
      formData.append("editFamilyData", JSON.stringify(editFarmerFamily));
      formData.append("deleteFamilyData", JSON.stringify(deleteFarmerFamily));

      formData.append("landData", JSON.stringify(landDetails));
      formData.append("farmingData", JSON.stringify(farmingDetails));

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/updatefarmerdatabyaadhar`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }
        // dispatch(logOutRequest());
        // toast.error("Session Expired.");

        if (_data?.status) {
          toast?.success(_data?.message);
          setLoading(false);
          navigate("/farmerdashboard");
        } else {
          setLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  const handleEditStateFamilyDetails = (data, index) => {
    setFamilyInputData({
      name: data.name,
      dob: data.dob,
      gender: genderArray?.find((er) => er?.value === data.gender),
      relation: data.relation,
      id: data?.id,
    });
    setFamilyInputDataId(index);
    setEditState(true);
  };

  // LAnd Details handleChange Input
  const handleChangeLandDetailsInput = (index, name, value) => {
    console.log("index: ", index);
    let arr = [...landDetails];

    if (name === "surveyNo") {
      arr[index][name] = value;
      let arr9 = [...checkAvailabilty];
      arr9[index]["check"] = false;
      setCheckAvailabilty(arr9);
      let arr5 = [...landDetailsError];
      if (value?.length > 0) {
        arr5[index]["surveyNo"] = false;
      } else {
        arr5[index]["surveyNo"] = true;
      }
      setLandDetailsError(arr5);
    } else if (name === "regVigha") {
      if (arr[index]["remainLandVigha"]) {
        if (parseFloat(value) <= parseFloat(arr[index]["remainLandVigha"])) {
          console.log(
            'arr[index]["remainLandVigha"]: ',
            arr[index]["remainLandVigha"]
          );
          arr[index][name] = value;
          arr[index]["registeredLand"] = +parseFloat(value * 2400).toFixed(2);

          let arr5 = [...landDetailsError];
          if (value?.length > 0) {
            arr5[index]["regVigha"] = false;
          } else {
            arr5[index]["regVigha"] = true;
          }
          setLandDetailsError(arr5);
        } else {
          if (value === "") {
            arr[index][name] = value;
            arr[index]["registeredLand"] = +parseFloat(value * 2400).toFixed(2);
            let arr5 = [...landDetailsError];
            if (value?.length > 0) {
              arr5[index]["regVigha"] = false;
            } else {
              arr5[index]["regVigha"] = true;
            }
            setLandDetailsError(arr5);
          }
        }
      } else {
        if (parseFloat(value) <= parseFloat(arr[index]["vigha"])) {
          arr[index][name] = value;
          arr[index]["registeredLand"] = +parseFloat(value * 2400).toFixed(2);

          let arr5 = [...landDetailsError];
          if (value?.length > 0) {
            arr5[index]["regVigha"] = false;
          } else {
            arr5[index]["regVigha"] = true;
          }
          setLandDetailsError(arr5);
        } else {
          if (value === "") {
            arr[index][name] = value;
            arr[index]["registeredLand"] = +parseFloat(value * 2400).toFixed(2);

            let arr5 = [...landDetailsError];
            if (value?.length > 0) {
              arr5[index]["regVigha"] = false;
            } else {
              arr5[index]["regVigha"] = true;
            }
            setLandDetailsError(arr5);
          }
        }
      }
    } else if (name === "totalLand") {
      arr[index][name] = value;
      arr[index]["vigha"] = +parseFloat(value / 2400).toFixed(2);
      let arr5 = [...landDetailsError];
      if (value?.length > 0) {
        arr5[index]["totalLand"] = false;
      } else {
        arr5[index]["totalLand"] = true;
      }
      setLandDetailsError(arr5);
    } else if (name === "vigha") {
      arr[index][name] = value;
      let arr5 = [...landDetailsError];
      if (value?.length > 0) {
        arr5[index]["vigha"] = false;
      } else {
        arr5[index]["vigha"] = true;
      }
      setLandDetailsError(arr5);
    } else if (name === "guardianPincode") {
      arr[index][name] = value;
      let arr5 = [...landDetailsError];
      if (value?.length === 6) {
        arr5[index]["guardianPincode"] = false;
      } else {
        arr5[index]["guardianPincode"] = true;
      }
      setLandDetailsError(arr5);
    } else if (name === "address") {
      arr[index][name] = value;
      let arr5 = [...landDetailsError];
      if (value?.length > 0) {
        arr5[index]["address"] = false;
      } else {
        arr5[index]["address"] = true;
      }
      setLandDetailsError(arr5);
    } else {
      arr[index][name] = value;
    }

    if (name === "country") {
      getStateOption(value.value)
        .then((res) => {
          const updatedLandDetails = [...landDetails];
          updatedLandDetails[index][name] = value;
          updatedLandDetails[index]["state"] = "";
          updatedLandDetails[index]["dist"] = "";
          updatedLandDetails[index]["taluka"] = "";
          updatedLandDetails[index]["village"] = "";
          updatedLandDetails[index] = {
            ...updatedLandDetails[index],
            stateArray: res,
          };

          setLandDetails(updatedLandDetails);
        })

        .catch((error) => {
          console.error("Error fetching state options:", error);
        });

      let arr5 = [...landDetailsError];
      if (value?.label?.length > 0) {
        arr5[index]["country"] = false;
      } else {
        arr5[index]["country"] = true;
      }
      setLandDetailsError(arr5);
    } else if (name === "state") {
      getDistrictOption(value.value)
        .then((res) => {
          const updatedLandDetails = [...landDetails];
          updatedLandDetails[index][name] = value;
          updatedLandDetails[index]["dist"] = "";
          updatedLandDetails[index]["taluka"] = "";
          updatedLandDetails[index]["village"] = "";
          updatedLandDetails[index] = {
            ...updatedLandDetails[index],
            districtArray: res,
          };

          setLandDetails(updatedLandDetails);
        })
        .catch((error) => {
          console.error("Error fetching state options:", error);
        });
      let arr5 = [...landDetailsError];
      if (value?.label?.length > 0) {
        arr5[index]["state"] = false;
      } else {
        arr5[index]["state"] = true;
      }
      setLandDetailsError(arr5);
    } else if (name === "dist") {
      getTalukaOption(value.value)
        .then((res) => {
          const updatedLandDetails = [...landDetails];
          updatedLandDetails[index][name] = value;
          updatedLandDetails[index]["taluka"] = "";
          updatedLandDetails[index]["village"] = "";
          updatedLandDetails[index] = {
            ...updatedLandDetails[index],
            talukaArray: res,
          };

          setLandDetails(updatedLandDetails);
        })
        .catch((error) => {
          console.error("Error fetching state options:", error);
        });
      let arr5 = [...landDetailsError];
      if (value?.label?.length > 0) {
        arr5[index]["dist"] = false;
      } else {
        arr5[index]["dist"] = true;
      }
      setLandDetailsError(arr5);
    } else if (name === "taluka") {
      getVillageOption(value.value)
        .then((res) => {
          const updatedLandDetails = [...landDetails];
          updatedLandDetails[index][name] = value;

          updatedLandDetails[index]["village"] = "";
          updatedLandDetails[index] = {
            ...updatedLandDetails[index],
            villageArray: res,
          };

          setLandDetails(updatedLandDetails);
        })
        .catch((error) => {
          console.error("Error fetching state options:", error);
        });
      let arr5 = [...landDetailsError];
      if (value?.label?.length > 0) {
        arr5[index]["taluka"] = false;
      } else {
        arr5[index]["taluka"] = true;
      }
      setLandDetailsError(arr5);
    } else if (name === "village") {
      let arr5 = [...landDetailsError];
      let arr9 = [...checkAvailabilty];
      arr9[index]["check"] = false;
      setCheckAvailabilty(arr9);

      if (value?.label?.length > 0) {
        arr5[index]["village"] = false;
      } else {
        arr5[index]["village"] = true;
      }
      setLandDetailsError(arr5);
    }

    if (name === "guardianCountry") {
      getStateOption(value.value).then((res) => {
        setGuardianStateArray(res);
      });
      let arr5 = [...landDetailsError];
      if (value?.label?.length > 0) {
        arr5[index]["guardianCountry"] = false;
      } else {
        arr5[index]["guardianCountry"] = true;
      }
      setLandDetailsError(arr5);
    }
    if (name === "guardianState") {
      getDistrictOption(value.value).then((res) => {
        setGuardianDistrictArray(res);
      });
      let arr5 = [...landDetailsError];
      if (value?.label?.length > 0) {
        arr5[index]["guardianState"] = false;
      } else {
        arr5[index]["guardianState"] = true;
      }
      setLandDetailsError(arr5);
    }
    if (name === "guardianDist") {
      getTalukaOption(value.value).then((res) => {
        setGuardianTalukaArray(res);
      });

      let arr5 = [...landDetailsError];
      if (value?.label?.length > 0) {
        arr5[index]["guardianDist"] = false;
      } else {
        arr5[index]["guardianDist"] = true;
      }
      setLandDetailsError(arr5);
    }
    if (name === "guardianTaluka") {
      getVillageOption(value.value).then((res) => {
        setGuardianVillageArray(res);
      });
      let arr5 = [...landDetailsError];
      if (value?.label?.length > 0) {
        arr5[index]["guardianTaluka"] = false;
      } else {
        arr5[index]["guardianTaluka"] = true;
      }
      setLandDetailsError(arr5);
    }

    if (name === "ownerShipStatus") {
      if (value) {
        arr[index]["guardianCountry"] = "";
        arr[index]["guardianState"] = "";
        arr[index]["guardianDist"] = "";
        arr[index]["guardianTaluka"] = "";
        arr[index]["guardianVillage"] = "";
        arr[index]["address"] = "";
      } else {
        arr[index]["guardianCountry"] = inputData?.country;
        arr[index]["guardianState"] = inputData?.state;
        arr[index]["guardianDist"] = inputData?.dist;
        arr[index]["guardianTaluka"] = inputData?.taluka;
        arr[index]["guardianVillage"] = inputData?.village;
        arr[index]["address"] =
          inputData?.addressLine1 +
          inputData?.addressLine2 +
          inputData?.addressLine3;
      }
    }

    setLandDetails(arr);
  };
  const handleDeleteLandDetails = (index) => {
    let arr = [...landDetails];

    arr.splice(index, 1);
    setLandDetails(arr);

    let arr5 = [...landDetailsError];
    arr5.splice(index, 1);
    setLandDetailsError(arr5);

    let arr6 = [...checkAvailabilty];
    arr6.splice(index, 1);
    setCheckAvailabilty(arr6);
  };

  useEffect(() => {
    getCountryOption().then((res) => {
      setCountryArray(res);
    });
    // eslint-disable-next-line
  }, []);

  const onCloseAvailibilityModal = () => {
    setAvailibilityModal(false);
  };

  const handleCheckAvailabilty = async (landItem, index) => {
    let checkVillage = landDetails?.find(
      (er, i) => i !== index && er?.village?.value === landItem?.village?.value
    );
    let checkSurveyNo = landDetails?.find(
      (er, i) => i !== index && er?.surveyNo === landItem?.surveyNo
    );
    if (checkVillage) {
      if (checkSurveyNo) {
        toast.error(
          "Land Already Registration. Please Check Village and Survey No "
        );
      } else {
        if (landItem?.village?.value && landItem?.surveyNo) {
          setAvailibilityLoading(true);
          let formData = new FormData();
          formData.append("villageId", landItem?.village.value);
          formData.append("surveyNo", landItem?.surveyNo);

          try {
            const apiCall = await fetch(
              `${process.env.REACT_APP_API_URL}farmer/checklandavability`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                body: formData,
              }
            );
            const _data = await apiCall.json();

            if (_data?.code === "token_not_valid") {
              dispatch(logOutRequest());
              toast.error("Session Expired.");
              setAvailibilityLoading(false);
              navigate("/login");
              setLoading(false);
            }
            if (_data?.status) {
              setAvailibilityModal(true);
              setAvailibilityData(_data);
              setAvailibilityDataIndex(index);
              setAvailibilityLoading(false);
            } else {
              setAvailibilityLoading(false);

              // setDeleteLoading(false);
              setAvailibilityModal(false);
              toast.error(_data?.message);
            }
          } catch (error) {
            console.log(error);
            setAvailibilityLoading(false);

            // setDeleteLoading(false);
          }
        } else {
          toast.error(
            "Please give input for VillageId and Survey No for check Land Availability"
          );
        }
      }
    } else {
      if (landItem?.village?.value && landItem?.surveyNo) {
        setAvailibilityLoading(true);
        let formData = new FormData();
        formData.append("villageId", landItem?.village.value);
        formData.append("surveyNo", landItem?.surveyNo);

        try {
          const apiCall = await fetch(
            `${process.env.REACT_APP_API_URL}farmer/checklandavability`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          );
          const _data = await apiCall.json();

          if (_data?.code === "token_not_valid") {
            dispatch(logOutRequest());
            toast.error("Session Expired.");
            setAvailibilityLoading(false);
            navigate("/login");
            setLoading(false);
          }
          if (_data?.status) {
            setAvailibilityModal(true);
            setAvailibilityData(_data);
            setAvailibilityDataIndex(index);
            setAvailibilityLoading(false);
          } else {
            setAvailibilityLoading(false);

            // setDeleteLoading(false);
            setAvailibilityModal(false);
            toast.error(_data?.message);
          }
        } catch (error) {
          console.log(error);
          setAvailibilityLoading(false);

          // setDeleteLoading(false);
        }
      } else {
        toast.error(
          "Please give input for VillageId and Survey No for check Land Availability"
        );
      }
    }
  };

  // Farming Details 02-10-2023

  const getProductListAPi = useCallback(async () => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/productdrop`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];
        // eslint-disable-next-line
        _data?.productList?.map((er) => {
          if (er?.typeName === "RAW MATERIAL") {
            arr.push({ label: er?.productName, value: er });
          }
        });
        setProductOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  // const getCategoryListAPi = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const apiCall = await fetch(
  //       `${process.env.REACT_APP_API_URL}farmer/categorylist`,
  //       {
  //         method: "GET",
  //         headers: {
  //           Authorization: `Bearer ${token}`,

  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     const _data = await apiCall.json();

  //     if (_data?.code === "token_not_valid") {
  //       dispatch(logOutRequest());
  //       toast.error("Session Expired.");

  //       navigate("/login");
  //       setLoading(false);
  //     }

  //     if (_data?.status) {
  //       setLoading(false);
  //       let arr = [];
  //       // eslint-disable-next-line
  //       _data?.categoryList?.map((er) => {
  //         arr.push({ label: er?.categoryName, value: er?.id });
  //       });
  //       setCategoryOptions(arr);
  //     } else {
  //       setLoading(false);

  //       toast.error(_data?.error);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    getProductListAPi();
    // getCategoryListAPi();
  }, [
    getProductListAPi,
    // getCategoryListAPi
  ]);

  const getCheckOptions = (productOptions) => {
    let arr = [];
    // eslint-disable-next-line
    productOptions?.map((er) => {
      let check = farmingDetails?.find(
        (ed) => ed?.product.value?.id === er?.value?.id
      );

      if (!check) {
        arr?.push(er);
      }
    });
    return arr;
  };

  // const getCheckCategoryOptions = (categoryOptions) => {
  //   let arr = [];
  //   // eslint-disable-next-line
  //   categoryOptions?.map((er) => {
  //     let check = farmingDetails?.find(
  //       (ed) => ed?.category.value === er?.value
  //     );

  //     if (!check) {
  //       arr?.push(er);
  //     }
  //   });
  //   return arr;
  // };

  const handleOnChangeFarmingDetails = (name, value) => {
    if (name === "vigha") {
      if (farmingDetails?.length === 0) {
        if (parseInt(value) <= parseInt(singleFarmingDetails?.totalVigha)) {
          setSingleFarmingDetails({ ...singleFarmingDetails, [name]: value });
          setSingleFarmingDetailsError({
            ...singleFarmingDetailsError,
            [name]: false,
          });
        } else {
          if (value === "") {
            setSingleFarmingDetails({ ...singleFarmingDetails, [name]: value });
            setSingleFarmingDetailsError({
              ...singleFarmingDetailsError,
              [name]: true,
            });
          }
        }
      } else {
        if (editFarmingDetails) {
          let totalfarmingDetails = [...farmingDetails];
          totalfarmingDetails.splice(singleFarmingIndex, 1);

          let totalRegisterLand = totalfarmingDetails?.reduce(
            (acc, curr) => acc + parseInt(curr?.vigha),
            0
          );

          let remainingland =
            singleFarmingDetails?.totalVigha - totalRegisterLand;

          if (parseInt(value) <= parseInt(remainingland)) {
            setSingleFarmingDetails({ ...singleFarmingDetails, [name]: value });
            setSingleFarmingDetailsError({
              ...singleFarmingDetailsError,
              [name]: false,
            });
          } else {
            if (value === "") {
              setSingleFarmingDetails({
                ...singleFarmingDetails,
                [name]: value,
              });
              setSingleFarmingDetailsError({
                ...singleFarmingDetailsError,
                [name]: true,
              });
            }
          }
        } else {
          let totalRegisterLand = farmingDetails?.reduce(
            (acc, curr) => acc + parseInt(curr?.vigha),
            0
          );

          let remainingland =
            singleFarmingDetails?.totalVigha - totalRegisterLand;

          if (parseInt(value) <= parseInt(remainingland)) {
            setSingleFarmingDetails({ ...singleFarmingDetails, [name]: value });
            setSingleFarmingDetailsError({
              ...singleFarmingDetailsError,
              [name]: false,
            });
          } else {
            if (value === "") {
              setSingleFarmingDetails({
                ...singleFarmingDetails,
                [name]: value,
              });
              setSingleFarmingDetailsError({
                ...singleFarmingDetailsError,
                [name]: true,
              });
            }
          }
        }
      }
    } else if (name === "product") {
      console.log("value: ", value);
      setSingleFarmingDetails({
        ...singleFarmingDetails,
        [name]: value,
        category: value?.value?.categoryId,
      });
      setSingleFarmingDetailsError({
        ...singleFarmingDetailsError,
        [name]: false,
      });
    } else {
      setSingleFarmingDetails({ ...singleFarmingDetails, [name]: value });
      setSingleFarmingDetailsError({
        ...singleFarmingDetailsError,
        [name]: false,
      });
    }
  };

  const handleAddFarmingDetails = () => {
    if (!singleFarmingDetails?.product) {
      setSingleFarmingDetailsError({
        ...singleFarmingDetailsError,
        product: true,
      });
      toast.error("product Name is Required!");
      // } else if (!singleFarmingDetails?.category) {
      //   setSingleFarmingDetailsError({
      //     ...singleFarmingDetailsError,
      //     category: true,
      //   });
      //   toast.error("Category is Required!");
    } else if (!singleFarmingDetails?.vigha) {
      setSingleFarmingDetailsError({
        ...singleFarmingDetailsError,
        vigha: true,
      });
      toast.error("Vigha is Required!");
    } else {
      if (editFarmingDetails) {
        let arr = [...farmingDetails];
        arr[singleFarmingIndex].product = singleFarmingDetails.product;
        arr[singleFarmingIndex].category = singleFarmingDetails.category;
        arr[singleFarmingIndex].vigha = singleFarmingDetails.vigha;
        console.log("arr: ", arr);
        setFarmingDetails(arr);

        setSingleFarmingDetails({
          ...singleFarmingDetails,
          product: "",
          category: "",
          vigha: "",
        });
        setSingleFarmingIndex("");
        setEditFarmingDetails(false);
      } else {
        let arr = [...farmingDetails];
        arr.push(singleFarmingDetails);
        setFarmingDetails(arr);

        setSingleFarmingDetails({
          ...singleFarmingDetails,
          product: "",
          category: "",
          vigha: "",
        });
      }
    }
  };

  const handleEditStateFarmingDetails = (data, index) => {
    setSingleFarmingDetails(data);
    setSingleFarmingIndex(index);
    setEditFarmingDetails(true);
  };

  const handleDeleteFarmingDetails = (index) => {
    console.log("index: ", index);
    console.log("farmingDetails: ", farmingDetails);
    let arr = [...farmingDetails];
    console.log("arr : ", arr);
    arr.splice(index, 1);
    console.log("arr: ", arr);
    setFarmingDetails(arr);
  };

  const Farmingcolumn = useMemo(
    () => [
      {
        Header: "Name Of Product",
        Cell: (cellProps) => {
          return <span>{cellProps?.row?.original?.product?.label}</span>;
        },
      },
      {
        Header: "Category",
        Cell: (cellProps) => {
          return (
            <span>
              {cellProps?.row?.original?.product?.value?.categoryName}
            </span>
          );
        },
      },
      {
        Header: "Size of Vigha",
        accessor: "vigha",
        filterable: false,
      },
      {
        Header: "Total Vigha",
        accessor: "totalVigha",
        filterable: false,
      },

      {
        id: "Action",
        Header: () => (
          <span
            className="d-flex justify-content-center"
            style={{ textAlign: "center" }}
          >
            Action
          </span>
        ),
        Cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <div className="hstack gap-2">
                {/* Edit Button */}
                <button
                  className="border-0"
                  style={{ background: "transparent" }}
                  onClick={() =>
                    handleEditStateFarmingDetails(
                      cellProps.row.original,
                      cellProps.row.index
                    )
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_32_318)">
                      <path
                        d="M6.87549 2.5H2.50049C2.16897 2.5 1.85103 2.6317 1.6166 2.86612C1.38218 3.10054 1.25049 3.41848 1.25049 3.75V12.5C1.25049 12.8315 1.38218 13.1495 1.6166 13.3839C1.85103 13.6183 2.16897 13.75 2.50049 13.75H11.2505C11.582 13.75 11.9 13.6183 12.1344 13.3839C12.3688 13.1495 12.5005 12.8315 12.5005 12.5V8.125"
                        stroke="#3E7531"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.563 1.56249C11.8116 1.31385 12.1489 1.17416 12.5005 1.17416C12.8521 1.17416 13.1893 1.31385 13.438 1.56249C13.6866 1.81113 13.8263 2.14836 13.8263 2.49999C13.8263 2.85162 13.6866 3.18885 13.438 3.43749L7.50049 9.37499L5.00049 9.99999L5.62549 7.49999L11.563 1.56249Z"
                        stroke="#3E7531"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_32_318">
                        <rect
                          width="15"
                          height="15"
                          fill="white"
                          transform="translate(0.000488281)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>

                {/* Delete Button */}
                <button
                  className="border-0"
                  style={{ background: "transparent" }}
                  onClick={() =>
                    handleDeleteFarmingDetails(cellProps.row.index)
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_32_330)">
                      <path
                        d="M6.63507 -0.0178833C5.50545 -0.0178833 4.59795 0.92077 4.59795 2.05558V2.30769H2.30814C1.99026 2.30769 1.73122 2.56673 1.73122 2.88462V3.46154H1.1543V4.61539H2.30814V13.2692C2.30814 14.2183 3.08987 15 4.03891 15H10.962C11.911 15 12.6928 14.2183 12.6928 13.2692V4.61539H13.8466V3.46154H13.2697V2.88462C13.2697 2.56673 13.0106 2.30769 12.6928 2.30769H10.403V2.055C10.403 0.920771 9.49545 -0.0178833 8.36584 -0.0178833H6.63507ZM6.63507 1.17173H8.36584C8.82968 1.17173 9.21334 1.55019 9.21334 2.055V2.30769H5.78699V2.055C5.78699 1.55019 6.17064 1.17231 6.63449 1.17231L6.63507 1.17173ZM3.46199 4.61539H6.41872C6.49026 4.62289 6.56122 4.63327 6.63507 4.63327H8.36584C8.43968 4.63327 8.51007 4.62289 8.58218 4.61539H11.5389V13.2692C11.5389 13.594 11.2868 13.8462 10.962 13.8462H4.03891C3.7141 13.8462 3.46199 13.594 3.46199 13.2692V4.61539ZM4.61584 5.76923V12.6923H5.76968V5.76923H4.61584ZM6.92353 5.76923V12.6923H8.07737V5.76923H6.92353ZM9.23122 5.76923V12.6923H10.3851V5.76923H9.23122Z"
                        fill="#EC0505"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_32_330">
                        <rect
                          width="15"
                          height="15"
                          fill="white"
                          transform="translate(0.000488281)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>
          );
        },
      },

      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    [farmingDetails]
  );

  return (
    <div>
      {/* Stepper View  */}
      <div className="farm-add-stepper">
        <div
          className={`${step === "step5" ? "farm-stepper-active" : ""}  ${
            isTablet ? "step1-farm-mobile-view" : "step1-farm-add"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M9.00001 2.25C8.40327 2.25 7.83098 2.48705 7.40902 2.90901C6.98706 3.33097 6.75001 3.90326 6.75001 4.5C6.75001 5.09674 6.98706 5.66903 7.40902 6.09099C7.83098 6.51295 8.40327 6.75 9.00001 6.75C9.59675 6.75 10.169 6.51295 10.591 6.09099C11.013 5.66903 11.25 5.09674 11.25 4.5C11.25 3.90326 11.013 3.33097 10.591 2.90901C10.169 2.48705 9.59675 2.25 9.00001 2.25ZM5.25001 4.5C5.25016 3.79048 5.45159 3.09555 5.83091 2.49594C6.21024 1.89633 6.75189 1.41663 7.39296 1.11257C8.03402 0.808505 8.7482 0.692549 9.45254 0.778169C10.1569 0.863789 10.8225 1.14747 11.372 1.59627C11.9216 2.04507 12.3325 2.64056 12.5571 3.31359C12.7818 3.98662 12.8108 4.70956 12.641 5.39846C12.4711 6.08735 12.1093 6.71392 11.5976 7.2054C11.0859 7.69688 10.4452 8.03309 9.75001 8.175V12.75H8.25001V8.175C7.40312 8.00184 6.64201 7.54153 6.09537 6.8719C5.54874 6.20227 5.25012 5.36441 5.25001 4.5ZM2.32876 8.25H6.00001V9.75H3.67126L3.08776 15H14.9123L14.3288 9.75H12V8.25H15.6713L16.5878 16.5H1.41226L2.32876 8.25Z"
              fill={step === "step5" ? "white" : "black"}
            />
          </svg>

          <span className={`${step === "step5" ? "text-white" : "text-black"}`}>
            {isTablet ? "" : "Land Details"}
          </span>
        </div>
        <div
          className={`${step === "step1" ? "farm-stepper-active" : ""} ${
            isTablet ? "step1-farm-mobile-view" : "step2-farm-add"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="24"
            viewBox="0 0 21 24"
            fill="none"
          >
            <g clipPath="url(#clip0_123_361)">
              <path
                d="M10.5 12C12.0913 12 13.6174 11.3679 14.7426 10.2426C15.8679 9.11742 16.5 7.5913 16.5 6C16.5 4.4087 15.8679 2.88258 14.7426 1.75736C13.6174 0.632141 12.0913 0 10.5 0C8.9087 0 7.38258 0.632141 6.25736 1.75736C5.13214 2.88258 4.5 4.4087 4.5 6C4.5 7.5913 5.13214 9.11742 6.25736 10.2426C7.38258 11.3679 8.9087 12 10.5 12ZM8.35781 14.25C3.74063 14.25 0 17.9906 0 22.6078C0 23.3766 0.623438 24 1.39219 24H19.6078C20.3766 24 21 23.3766 21 22.6078C21 17.9906 17.2594 14.25 12.6422 14.25H8.35781Z"
                fill={step === "step1" ? "white" : "black"}
              />
            </g>
            <defs>
              <clipPath id="clip0_123_361">
                <rect
                  width="21"
                  height="24"
                  fill={step === "step1" ? "white" : "black"}
                />
              </clipPath>
            </defs>
          </svg>

          <span className={`${step === "step1" ? "text-white" : "text-black"}`}>
            {isTablet ? "" : "Personal Details"}
          </span>
        </div>
        <div
          className={`${step === "step2" ? "farm-stepper-active" : ""} ${
            isTablet ? "step1-farm-mobile-view" : "step3-farm-add"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clipPath="url(#clip0_123_363)">
              <path
                d="M14.25 11.25C14.2498 11.8469 14.0125 12.4193 13.5903 12.8413C13.168 13.2633 12.5955 13.5002 11.9985 13.5C11.4016 13.4998 10.8292 13.2625 10.4072 12.8402C9.98528 12.418 9.74834 11.8454 9.74854 11.2485C9.74873 10.6516 9.98606 10.0792 10.4083 9.6572C10.8305 9.23524 11.4031 8.9983 12 8.9985C12.597 8.9987 13.1694 9.23602 13.5913 9.65826C14.0133 10.0805 14.2502 10.6531 14.25 11.25ZM21.405 6H17.595C16.9068 6 16.2467 6.2734 15.7601 6.76006C15.2734 7.24671 15 7.90676 15 8.595V13.5C15 13.8978 15.1581 14.2794 15.4394 14.5607C15.7207 14.842 16.1022 15 16.5 15V24H22.5V15C22.8979 15 23.2794 14.842 23.5607 14.5607C23.842 14.2794 24 13.8978 24 13.5V8.595C24 7.90676 23.7266 7.24671 23.24 6.76006C22.7533 6.2734 22.0933 6 21.405 6Z"
                fill={step === "step2" ? "white" : "black"}
              />
              <path
                d="M22.5 3.00005C22.509 3.39956 22.4382 3.79684 22.2915 4.16858C22.1449 4.54033 21.9255 4.87903 21.6462 5.16481C21.3669 5.45059 21.0333 5.67768 20.665 5.83276C20.2967 5.98784 19.9011 6.06777 19.5015 6.06787C19.1019 6.06797 18.7063 5.98823 18.3379 5.83334C17.9696 5.67845 17.6359 5.45152 17.3564 5.16588C17.0769 4.88024 16.8573 4.54165 16.7105 4.16998C16.5637 3.79831 16.4927 3.40106 16.5015 3.00155C16.5188 2.21771 16.8423 1.4718 17.4028 0.923492C17.9632 0.375189 18.716 0.0680672 19.5 0.0678712C20.284 0.0676751 21.037 0.374421 21.5977 0.922443C22.1584 1.47047 22.4823 2.21621 22.5 3.00005ZM6.405 7.50005H2.595C2.25412 7.50015 1.9166 7.56739 1.60171 7.69792C1.28681 7.82846 1.00072 8.01975 0.759747 8.26086C0.518779 8.50196 0.32766 8.78817 0.197303 9.10314C0.0669451 9.41811 -9.8383e-05 9.75567 1.0836e-07 10.0965C9.85998e-05 10.4374 0.0673372 10.7749 0.197877 11.0898C0.328416 11.4047 0.519701 11.6908 0.760808 11.9318C1.00192 12.1728 1.28812 12.3639 1.60309 12.4942C1.91806 12.6246 2.25562 12.6916 2.5965 12.6915C3.28494 12.6913 3.9451 12.4177 4.43175 11.9307C4.91841 11.4438 5.1917 10.7835 5.1915 10.095C5.1913 9.40661 4.91763 8.74645 4.43069 8.2598C3.94375 7.77314 3.28344 7.49985 2.595 7.50005C1.90676 7.50005 1.2464 7.7742 0.759747 8.26086C0.27309 8.74751 1.0836e-07 9.40831 1.0836e-07 10.0965L1.63061e-07 13.5C1.63061e-07 13.8979 0.158035 14.2794 0.43934 14.5607C0.720645 14.842 1.10218 15 1.5 15L1.63061e-07 19.5H1.5V24H7.5V19.5H9L7.5 15C7.89782 15 8.27936 14.842 8.56066 14.5607C8.84196 14.2794 9 13.8979 9 13.5V10.095C9 9.75427 8.93288 9.41682 8.80247 9.10198C8.67206 8.78714 8.48091 8.50107 8.23994 8.26011C7.99897 8.01914 7.7129 7.82799 7.39806 7.69758C7.08322 7.56717 6.74578 7.50005 6.405 7.50005Z"
                fill={step === "step2" ? "white" : "black"}
              />
              <path
                d="M7.49997 4.50005C7.509 4.89956 7.43813 5.29684 7.29151 5.66858C7.14489 6.04033 6.92548 6.37903 6.64616 6.66481C6.36684 6.95059 6.03324 7.17768 5.66495 7.33276C5.29666 7.48784 4.90109 7.56777 4.50148 7.56787C4.10187 7.56797 3.70627 7.48823 3.3379 7.33334C2.96953 7.17845 2.63582 6.95152 2.35635 6.66588C2.07689 6.38024 1.85731 6.04165 1.71051 5.66998C1.5637 5.29831 1.49263 4.90106 1.50147 4.50155C1.5188 3.71771 1.8423 2.9718 2.40272 2.42349C2.96315 1.87519 3.71595 1.56807 4.49998 1.56787C5.28402 1.56768 6.03697 1.87442 6.59767 2.42244C7.15836 2.97047 7.48224 3.71621 7.49997 4.50005ZM10.5 19.5V24H13.5V19.5C13.8978 19.5 14.2793 19.342 14.5606 19.0607C14.8419 18.7794 15 18.3979 15 18V15.69C15 15.1092 14.7692 14.5522 14.3585 14.1415C13.9478 13.7308 13.3908 13.5 12.81 13.5H11.19C10.6091 13.5 10.0521 13.7308 9.6414 14.1415C9.2307 14.5522 8.99997 15.1092 8.99997 15.69V18C8.99997 18.3979 9.158 18.7794 9.43931 19.0607C9.72061 19.342 10.1021 19.5 10.5 19.5Z"
                fill={step === "step2" ? "white" : "black"}
              />
            </g>
            <defs>
              <clipPath id="clip0_123_363">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span className={`${step === "step2" ? "text-white" : "text-black"}`}>
            {isTablet ? "" : "Family Details"}
          </span>
        </div>
        <div
          className={`${step === "step3" ? "farm-stepper-active" : ""}  ${
            isTablet ? "step1-farm-mobile-view" : "step4-farm-add"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M10.968 2.32496C11.2676 2.10618 11.629 1.98828 12 1.98828C12.371 1.98828 12.7324 2.10618 13.032 2.32496L20.453 7.74096C21.43 8.45296 20.927 9.99796 19.719 10.001H4.28C3.072 9.99796 2.57 8.45296 3.546 7.74096L10.967 2.32496H10.968ZM13 6.24996C13 5.98474 12.8946 5.73039 12.7071 5.54285C12.5196 5.35532 12.2652 5.24996 12 5.24996C11.7348 5.24996 11.4804 5.35532 11.2929 5.54285C11.1054 5.73039 11 5.98474 11 6.24996C11 6.51518 11.1054 6.76953 11.2929 6.95707C11.4804 7.1446 11.7348 7.24996 12 7.24996C12.2652 7.24996 12.5196 7.1446 12.7071 6.95707C12.8946 6.76953 13 6.51518 13 6.24996ZM11.25 16H9.25V11H11.25V16ZM14.75 16H12.75V11H14.75V16ZM18.5 16H16.25V11H18.5V16ZM18.75 17H5.25C4.65326 17 4.08097 17.237 3.65901 17.659C3.23705 18.0809 3 18.6532 3 19.25V19.75C3 20.165 3.336 20.5 3.75 20.5H20.25C20.4489 20.5 20.6397 20.4209 20.7803 20.2803C20.921 20.1396 21 19.9489 21 19.75V19.25C21 18.6532 20.7629 18.0809 20.341 17.659C19.919 17.237 19.3467 17 18.75 17ZM7.75 16H5.5V11H7.75V16Z"
              fill={step === "step3" ? "white" : "black"}
            />
          </svg>
          <span className={`${step === "step3" ? "text-white" : "text-black"}`}>
            {isTablet ? "" : "Bank Details"}
          </span>
        </div>

        <div
          className={`${step === "step6" ? "farm-stepper-active" : ""} ${
            isTablet ? "step1-farm-mobile-view" : "step5-farm-add"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M0.632812 17.3672L1.91074 5.75158C2.14727 9.5351 2.05439 13.9015 3.16076 16.1462C3.55468 13.1606 3.71296 10.2337 3.53208 7.39197C4.06733 9.91232 4.78164 12.3729 4.91963 15.0258C5.17961 13.0593 5.36495 11.1052 6.03752 9.06998C6.0108 11.3618 5.43424 13.461 6.4832 14.606C6.99061 12.8127 7.50245 11.0236 7.58921 8.80947C8.33699 10.9843 8.23405 13.5844 8.46629 16.0172C8.7622 13.6245 9.00348 6.95252 10.1154 4.83154C10.006 9.22537 9.97959 12.7044 11.0786 14.4724C12.1648 13.1128 12.2586 10.4492 12.2837 7.92143C13.0506 10.0923 13.5654 12.5656 13.6146 15.5974C13.9179 13.0678 14.2709 10.5381 15.1401 8.00861C14.938 11.1126 15.0949 13.5264 15.8265 14.8349C16.3103 12.882 16.7231 10.929 16.8503 8.97576C17.4978 11.886 17.3073 14.5863 17.4376 17.3672H0.632812Z"
              fill={step === "step6" ? "white" : "black"}
            />
          </svg>

          <span className={`${step === "step6" ? "text-white" : "text-black"}`}>
            {isTablet ? "" : "Farming Details"}
          </span>
        </div>
      </div>

      {step === "step1" && (
        <div className="mt-5" style={{ margin: 40 }}>
          <div>
            <Row className="g-3 mt-1">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Unit Address <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col sm={8}>
                  <Select
                    isMulti={false}
                    onChange={(e) => {
                      handleChangeInput("unit", e);
                    }}
                    value={inputData?.unit}
                    options={unitOptions}
                    // menuPlacement="top"
                    isDisabled={
                      location?.state?.verifyStatus === "True" ? true : false
                    }
                    name="choices-single-default"
                    id="countryAddCompany"
                    className={`w-100 ${
                      inputDataError?.unit ? "border border-danger" : ""
                    }`}
                  ></Select>
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Farmer Code <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.farmerCode}
                    name="farmerCode"
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.value)
                    }
                    className={`cust-input ${
                      inputDataError?.farmerCode ? "border-danger" : ""
                    }`}
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>
          {/* Farmer ID and Farmer Photo  */}
          <div>
            <Row className=" g-3 mt-2">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Farmer ID
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.farmerId}
                    name="farmerId"
                    disabled
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.value)
                    }
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Farmer Name <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.farmerName}
                    name="farmerName"
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.value)
                    }
                    className={`cust-input ${
                      inputDataError?.farmerName ? "border-danger" : ""
                    }`}
                    type="text"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Farmer Photo
                    {/* <span className="required_span"> * </span> */}
                  </Label>
                </Col>
                <Col sm={9}>
                  {location?.state?.photo ? (
                    <div style={{ display: "flex", columnGap: 6 }}>
                      <img
                        src={inputData?.farmerPhoto?.url}
                        style={{ height: "100px", width: "100px" }}
                        alt="Captured"
                      />
                      <button
                        style={{
                          height: "30px",
                          width: "80px",
                          marginTop: "30px",
                        }}
                        onClick={() => setShowCapturePopup(true)}
                      >
                        change
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      onClick={() => setShowCapturePopup(true)}
                      className={`${
                        inputDataError?.farmerPhoto
                          ? "border border-danger"
                          : ""
                      }`}
                      style={{
                        border: "none",
                        color: "white",
                        padding: 8,
                        background:
                          "linear-gradient(180deg, #C1C1C1 0%, #6D6D6D 100%)",
                        borderRadius: 5,
                      }}
                    >
                      Upload
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        style={{ height: 16, width: 16, marginLeft: 2 }}
                      >
                        <path
                          d="M4 12.6667H12"
                          stroke="white"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8 10H9.33333V8H11L8 5M8 10H6.66667V8H5L8 5"
                          stroke="white"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  )}

                  <input
                    style={{ display: "none" }}
                    type="file"
                    name="farmerPhoto"
                    ref={farmerPhotoImage}
                    onChange={(e) =>
                      handleImageChange(e.target.name, e.target.files[0])
                    }
                  />

                  {/* <span
                    className="mx-3"
                    style={{ fontWeight: 500, fontSize: 10 }}
                  >
                    {inputData?.farmerPhoto
                      ? inputData?.farmerPhoto?.name
                      : ".jpg, .png, .jpeg upload (Max size 150 KB)"}
                  </span> */}
                </Col>
              </Col>
            </Row>
          </div>
          {/* FarmerName , gender and dob */}
          <div>
            <Row className=" g-3 mt-2">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Farmer Email
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.email}
                    name="email"
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.value)
                    }
                    className="cust-input"
                    type="email"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Gender <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col sm={8} className="d-flex mt-2">
                  <div>
                    <Input
                      id="exampleEmail"
                      checked={inputData?.gender === "male"}
                      style={{ marginRight: 5 }}
                      name="gender"
                      onChange={(e) => handleChangeInput(e.target.name, "male")}
                      className={`cust-input ${
                        inputDataError?.gender ? "border-danger" : ""
                      }`}
                      type="radio"
                    />
                    Male
                  </div>
                  <div style={{ marginLeft: 10 }}>
                    <Input
                      id="exampleEmail"
                      checked={inputData?.gender === "female"}
                      style={{ marginRight: 5 }}
                      name="gender"
                      onChange={(e) =>
                        handleChangeInput(e.target.name, "female")
                      }
                      className={`cust-input ${
                        inputDataError?.gender ? "border-danger" : ""
                      }`}
                      type="radio"
                    />
                    female
                  </div>
                  <div style={{ marginLeft: 10 }}>
                    <Input
                      id="exampleEmail"
                      checked={inputData?.gender === "other"}
                      style={{ marginRight: 5 }}
                      name="gender"
                      onChange={(e) =>
                        handleChangeInput(e.target.name, "other")
                      }
                      className={`cust-input ${
                        inputDataError?.gender ? "border-danger" : ""
                      }`}
                      type="radio"
                    />
                    other
                  </div>
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    DOB <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col sm={8}>
                  <Flatpickr
                    className="form-control"
                    options={{
                      dateFormat: "Y-m-d",
                      maxDate: new Date(),
                    }}
                    value={inputData?.dob}
                    onChange={(date, dateStr) => {
                      let age = calculateAge(dateStr);
                      if (age >= 18) {
                        setInputData({ ...inputData, dob: dateStr });
                        setInputDataError({ ...inputDataError, dob: false });
                      } else {
                        toast.error("Age is not at least 18 years old");
                      }
                    }}
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* address1 , address2 and panNo */}
          <div>
            <Row className=" g-3 mt-2">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Address Line 1 <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.addressLine1}
                    name="addressLine1"
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.value)
                    }
                    className={`cust-input ${
                      inputDataError?.addressLine1 ? "border-danger" : ""
                    }`}
                    type="text"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Address Line 2 <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col sm={8} className="d-flex ">
                  <Input
                    id="exampleEmail"
                    value={inputData?.addressLine2}
                    name="addressLine2"
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.value)
                    }
                    className={`cust-input ${
                      inputDataError?.addressLine2 ? "border-danger" : ""
                    }`}
                    type="text"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    PAN No.
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.panNo}
                    name="panNo"
                    onChange={(e) =>
                      handleChangeInput(
                        e.target.name,
                        e.target.value.toUpperCase()
                      )
                    }
                    className={`cust-input ${
                      inputDataError?.panNo ? "border-danger" : ""
                    }`}
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* Country , state and panImage */}
          <div>
            <Row className=" g-3 mt-2">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Country <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col sm={8}>
                  <Select
                    isMulti={false}
                    onChange={(e) => {
                      handleChangeInput("country", e);
                    }}
                    value={inputData?.country}
                    options={countryArray}
                    // menuPlacement="top"
                    name="choices-single-default"
                    id="countryAddCompany"
                    className={`w-100 ${
                      inputDataError?.country ? " border border-danger" : ""
                    }`}
                  ></Select>
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    State <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col sm={8} className="d-flex mt-2">
                  <Select
                    isMulti={false}
                    onChange={(e) => {
                      handleChangeInput("state", e);
                    }}
                    value={inputData?.state}
                    options={stateArray}
                    // menuPlacement="top"
                    name="choices-single-default"
                    id="countryAddCompany"
                    className={`w-100 ${
                      inputDataError?.state ? " border border-danger" : ""
                    }`}
                  ></Select>
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    PAN Card
                  </Label>
                </Col>
                <Col sm={9}>
                  <button
                    type="button"
                    onClick={handlepanCardPhotoImageUploadBtn}
                    className={`${
                      inputDataError?.panCardImage ? "border border-danger" : ""
                    }`}
                    style={{
                      border: "none",
                      color: "white",
                      padding: 8,
                      background:
                        "linear-gradient(180deg, #C1C1C1 0%, #6D6D6D 100%)",
                      borderRadius: 5,
                    }}
                  >
                    Upload
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      style={{ height: 16, width: 16, marginLeft: 2 }}
                    >
                      <path
                        d="M4 12.6667H12"
                        stroke="white"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 10H9.33333V8H11L8 5M8 10H6.66667V8H5L8 5"
                        stroke="white"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>

                  <input
                    style={{ display: "none" }}
                    type="file"
                    accept="image/png, image/jpeg , image/jpg"
                    name="panCardImage"
                    ref={panCardPhotoImage}
                    onChange={(e) =>
                      handleImageChange(e.target.name, e.target.files[0])
                    }
                  />

                  <span
                    className="mx-3"
                    style={{ fontWeight: 500, fontSize: 10 }}
                  >
                    {inputData?.panCardImage
                      ? inputData?.panCardImage?.name
                      : ".jpg, .png, .jpeg upload (Max size 150 KB)"}
                  </span>
                </Col>
              </Col>
            </Row>
          </div>

          {/* dist , city and addharCardNo */}
          <div>
            <Row className=" g-3 mt-2">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Dist <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col sm={8}>
                  <Select
                    isMulti={false}
                    onChange={(e) => {
                      handleChangeInput("dist", e);
                    }}
                    value={inputData?.dist}
                    name="dist"
                    options={districtArray}
                    // menuPlacement="top"

                    className={`w-100 ${
                      inputDataError?.dist ? " border border-danger" : ""
                    }`}
                  ></Select>
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Taluka <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={8}>
                  <Select
                    isMulti={false}
                    onChange={(e) => {
                      handleChangeInput("taluka", e);
                    }}
                    value={inputData?.taluka}
                    name="taluka"
                    options={talukaArray}
                    // menuPlacement="top"

                    className={`w-100 ${
                      inputDataError?.taluka ? " border border-danger" : ""
                    }`}
                  ></Select>
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Village <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col sm={8} className="d-flex mt-2">
                  <Select
                    isMulti={false}
                    onChange={(e) => {
                      handleChangeInput("village", e);
                    }}
                    value={inputData?.village}
                    name="village"
                    options={villageArray}
                    // menuPlacement="top"

                    className={`w-100 ${
                      inputDataError?.village ? " border border-danger" : ""
                    }`}
                  ></Select>
                </Col>
              </Col>
            </Row>
          </div>

          {/* pincode , phoneNo and addharImage */}
          <div>
            <Row className=" g-3 mt-2">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Pincode <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.pincode}
                    name="pincode"
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.value)
                    }
                    className={`cust-input ${
                      inputDataError?.pincode ? "border-danger" : ""
                    }`}
                    type="number"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Mobile No. <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.mobileNo}
                    name="mobileNo"
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.value)
                    }
                    className={`cust-input ${
                      inputDataError?.mobileNo ? "border-danger" : ""
                    }`}
                    type="number"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Designated Successor{" "}
                    <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.primaryContactPerson}
                    name="primaryContactPerson"
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.value)
                    }
                    className={`cust-input ${
                      inputDataError?.primaryContactPerson
                        ? "border-danger"
                        : ""
                    }`}
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* Mobile No. */}
          <div>
            <Row className=" g-3 mt-2">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3 mb-0"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Alternative No.
                  </Label>
                </Col>
                <Col sm={8} className="d-flex mt-2">
                  <Input
                    id="exampleEmail"
                    value={inputData?.phoneNo}
                    name="phoneNo"
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.value)
                    }
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* Next Button View */}
          <div>
            <Col
              md={12}
              className="d-flex justify-content-end align-items-end mt-2"
            >
              <button
                type="button"
                className="custom-btn-page-layout btn text-white mx-3"
                onClick={() => setStep("step5")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  style={{ marginRight: 5 }}
                >
                  <path
                    d="M15 8C15 9.38447 14.5895 10.7378 13.8203 11.889C13.0511 13.0401 11.9579 13.9373 10.6788 14.4672C9.3997 14.997 7.99224 15.1356 6.63437 14.8655C5.2765 14.5954 4.02922 13.9287 3.05025 12.9497C2.07129 11.9708 1.4046 10.7235 1.1345 9.36563C0.864407 8.00776 1.00303 6.6003 1.53284 5.32122C2.06266 4.04213 2.95986 2.94888 4.11101 2.17971C5.26215 1.41054 6.61553 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8ZM12 7.5H5.925L8.715 4.6965L8 4L4 8L8 12L8.715 11.2865L5.925 8.5H12V7.5Z"
                    fill="white"
                  />
                </svg>
                Previous
              </button>
              <button
                type="button"
                className="custom-btn-page-layout btn text-white "
                onClick={() => handleNextBtn("step1")}
              >
                Next
                <svg
                  style={{ marginLeft: 5 }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M1 8C1 9.38447 1.41054 10.7378 2.17971 11.889C2.94888 13.0401 4.04213 13.9373 5.32122 14.4672C6.6003 14.997 8.00776 15.1356 9.36563 14.8655C10.7235 14.5954 11.9708 13.9287 12.9497 12.9497C13.9287 11.9708 14.5954 10.7235 14.8655 9.36563C15.1356 8.00776 14.997 6.6003 14.4672 5.32122C13.9373 4.04213 13.0401 2.94888 11.889 2.17971C10.7378 1.41054 9.38447 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8ZM4 7.5H10.075L7.285 4.6965L8 4L12 8L8 12L7.285 11.2865L10.075 8.5H4V7.5Z"
                    fill="white"
                  />
                </svg>
              </button>
            </Col>
          </div>
        </div>
      )}

      {step === "step2" && (
        <div className="mt-5" style={{ margin: 40 }}>
          {/* <div className="step2-farm-div"> */}
          <Row className=" g-3 mt-2justify-content-center align-items-center mt-3">
            <Col
              lg={4}
              md={12}
              sm={12}
              className="d-flex align-items-center justify-content-center"
            >
              <Col md={3}>
                <Label
                  className="me-3"
                  style={{ fontWeight: 500 }}
                  for="exampleEmail"
                  md={12}
                >
                  Name <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col md={9}>
                <Input
                  id="exampleEmail"
                  value={familyInputData?.name}
                  name="name"
                  onChange={(e) =>
                    handleChangFamilyDetailsInput(e.target.name, e.target.value)
                  }
                  className={`cust-input ${
                    inputDataError?.familyName ? "border-danger" : ""
                  }`}
                  type="text"
                />
              </Col>
            </Col>
            <Col
              lg={3}
              md={12}
              sm={12}
              className="d-flex align-items-center justify-content-center"
            >
              <Col md={4}>
                <Label
                  className="me-3"
                  style={{ fontWeight: 500 }}
                  for="exampleEmail"
                  md={12}
                >
                  Relation <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col sm={8} className="d-flex mt-2">
                <Input
                  id="exampleEmail"
                  value={familyInputData?.relation}
                  name="relation"
                  onChange={(e) =>
                    handleChangFamilyDetailsInput(e.target.name, e.target.value)
                  }
                  className={`cust-input ${
                    inputDataError?.familyRelation ? "border-danger" : ""
                  }`}
                  type="text"
                />
              </Col>
            </Col>
            <Col
              lg={2}
              md={12}
              sm={12}
              className="d-flex align-items-center justify-content-center"
            >
              <Col md={4}>
                <Label
                  className="me-3"
                  style={{ fontWeight: 500 }}
                  for="exampleEmail"
                  md={12}
                >
                  Gender <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col sm={7} style={{ marginLeft: 10 }} className=" d-flex mt-2">
                <Select
                  isMulti={false}
                  onChange={(e) => {
                    handleChangFamilyDetailsInput("gender", e);
                  }}
                  value={familyInputData?.gender}
                  options={genderArray}
                  // menuPlacement="top"
                  name="choices-single-default"
                  id="countryAddCompany"
                  className={`w-100 ${
                    inputDataError?.familyGender ? "border border-danger" : ""
                  }`}
                ></Select>
              </Col>
            </Col>
            <Col
              lg={2}
              md={12}
              sm={12}
              className="d-flex align-items-center justify-content-center"
            >
              <Col md={4}>
                <Label
                  className="me-3"
                  style={{ fontWeight: 500 }}
                  for="exampleEmail"
                  md={12}
                >
                  DOB
                </Label>
              </Col>
              <Col sm={8} className="d-flex mt-2">
                <Flatpickr
                  className={`form-control ${
                    inputDataError?.familyDob ? "border border-danger" : ""
                  }`}
                  options={{
                    dateFormat: "Y-m-d",
                    maxDate: new Date(),
                  }}
                  value={familyInputData?.dob}
                  onChange={(date, dateStr) => {
                    setFamilyInputData({
                      ...familyInputData,
                      dob: dateStr,
                    });
                  }}
                />
              </Col>
            </Col>
            <Col lg={1} md={6} sm={12} className="d-flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                onClick={() => {
                  handleAddIconStep2();
                }}
              >
                <g clipPath="url(#clip0_209_176)">
                  <path
                    d="M11 9.5V5.5H9V9.5H5V11.5H9V15.5H11V11.5H15V9.5H11ZM10 20.5C7.34784 20.5 4.8043 19.4464 2.92893 17.5711C1.05357 15.6957 0 13.1522 0 10.5C0 7.84784 1.05357 5.3043 2.92893 3.42893C4.8043 1.55357 7.34784 0.5 10 0.5C12.6522 0.5 15.1957 1.55357 17.0711 3.42893C18.9464 5.3043 20 7.84784 20 10.5C20 13.1522 18.9464 15.6957 17.0711 17.5711C15.1957 19.4464 12.6522 20.5 10 20.5Z"
                    fill="#08447C"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_209_176">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </Col>
          </Row>

          {/* </div> */}
          {/* Search and Table View */}
          <div className="" style={{ marginTop: 145 }}>
            <div className="" style={{ margin: 20 }}>
              <InputGroup className="search-inp">
                <Input placeholder="Search" />
                <InputGroupText>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M15.1987 13.8333L10.9037 9.53833C11.6977 8.33158 12.0157 6.87314 11.7962 5.44537C11.5766 4.01761 10.8351 2.72208 9.71524 1.80963C8.59536 0.897181 7.17674 0.432709 5.73406 0.506144C4.29138 0.579579 2.92726 1.1857 1.90581 2.20715C0.884365 3.22859 0.278248 4.59271 0.204813 6.03539C0.131379 7.47807 0.595851 8.89669 1.5083 10.0166C2.42075 11.1365 3.71628 11.878 5.14404 12.0975C6.57181 12.317 8.03025 11.999 9.237 11.205L13.532 15.5L15.1987 13.8333ZM1.86534 6.33333C1.86534 4.03584 3.7345 2.16667 6.032 2.16667C8.3295 2.16667 10.1987 4.03584 10.1987 6.33333C10.1987 8.63083 8.3295 10.5 6.032 10.5C3.7345 10.5 1.86534 8.63083 1.86534 6.33333Z"
                      fill="#999999"
                    />
                  </svg>
                </InputGroupText>
              </InputGroup>
            </div>
            <div className={`d-flex ${isTablet ? "flex-column mt-3" : ""}`}>
              <div
                className="d-grid"
                style={{ margin: "0 20px", border: "1px solid #DEE2E6" }}
              >
                <TableContainer
                  columns={column}
                  data={inputData?.familyDetails}
                  isGlobalFilter={false}
                  isAddUserList={false}
                  tableClass={"table-sm"}
                  divClass={"table-responsive"}
                />
              </div>
              <div
                className={`col-md-4 d-flex ${
                  isTablet ? "justify-content-center" : "justify-content-end"
                } ${isTablet ? "align-items-center" : "align-items-end"}  ${
                  isTablet ? "flex-column gap-2" : ""
                }`}
                style={{ marginBottom: 20 }}
              >
                <button
                  type="button"
                  className="custom-btn-page-layout btn text-white "
                  onClick={() => setStep("step1")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    style={{ marginRight: 5 }}
                  >
                    <path
                      d="M15 8C15 9.38447 14.5895 10.7378 13.8203 11.889C13.0511 13.0401 11.9579 13.9373 10.6788 14.4672C9.3997 14.997 7.99224 15.1356 6.63437 14.8655C5.2765 14.5954 4.02922 13.9287 3.05025 12.9497C2.07129 11.9708 1.4046 10.7235 1.1345 9.36563C0.864407 8.00776 1.00303 6.6003 1.53284 5.32122C2.06266 4.04213 2.95986 2.94888 4.11101 2.17971C5.26215 1.41054 6.61553 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8ZM12 7.5H5.925L8.715 4.6965L8 4L4 8L8 12L8.715 11.2865L5.925 8.5H12V7.5Z"
                      fill="white"
                    />
                  </svg>
                  Previous
                </button>
                <button
                  type="button"
                  className="custom-btn-page-layout btn text-white mx-4"
                  onClick={() => handleNextBtn("step2")}
                >
                  Next
                  <svg
                    style={{ marginLeft: 5 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M1 8C1 9.38447 1.41054 10.7378 2.17971 11.889C2.94888 13.0401 4.04213 13.9373 5.32122 14.4672C6.6003 14.997 8.00776 15.1356 9.36563 14.8655C10.7235 14.5954 11.9708 13.9287 12.9497 12.9497C13.9287 11.9708 14.5954 10.7235 14.8655 9.36563C15.1356 8.00776 14.997 6.6003 14.4672 5.32122C13.9373 4.04213 13.0401 2.94888 11.889 2.17971C10.7378 1.41054 9.38447 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8ZM4 7.5H10.075L7.285 4.6965L8 4L12 8L8 12L7.285 11.2865L10.075 8.5H4V7.5Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className=""></div>
        </div>
      )}

      {step === "step3" && (
        <div className="mt-5" style={{ margin: 40 }}>
          {/* bankName and  ifsc  */}
          <div>
            <Row className=" g-3 mt-2">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Bank Name <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.bankName}
                    name="bankName"
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.value)
                    }
                    className={`cust-input ${
                      inputDataError?.bankName ? "border-danger" : ""
                    }`}
                    type="text"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    IFSC Code <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col sm={8} className="d-flex mt-2">
                  <Input
                    id="exampleEmail"
                    value={inputData?.ifscCode}
                    name="ifscCode"
                    onChange={(e) =>
                      handleChangeInput(
                        e.target.name,
                        e.target.value.toUpperCase()
                      )
                    }
                    className={`cust-input ${
                      inputDataError?.ifscCode ? "border-danger" : ""
                    }`}
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* accountNo and  branchName  */}
          <div>
            <Row className=" g-3 mt-2">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Account Number <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.accountNo}
                    name="accountNo"
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.value)
                    }
                    className={`cust-input ${
                      inputDataError?.accountNo ? "border-danger" : ""
                    }`}
                    type="number"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Branch Name <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col sm={8} className="d-flex mt-2">
                  <Input
                    id="exampleEmail"
                    value={inputData?.branchName}
                    name="branchName"
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.value)
                    }
                    className={`cust-input ${
                      inputDataError?.branchName ? "border-danger" : ""
                    }`}
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* Upload Options  */}
          <div>
            <Row className=" g-3 mt-2">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Upload Options <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col sm={8}>
                  <button
                    type="button"
                    onClick={handlecancelChequeImageUploadBtn}
                    className={` ${
                      inputDataError?.cancelCheque
                        ? " border border-danger"
                        : ""
                    }`}
                    style={{
                      border: "none",
                      color: "white",
                      padding: 8,
                      background:
                        "linear-gradient(180deg, #C1C1C1 0%, #6D6D6D 100%)",
                      borderRadius: 5,
                    }}
                  >
                    Upload
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      style={{ height: 16, width: 16, marginLeft: 2 }}
                    >
                      <path
                        d="M4 12.6667H12"
                        stroke="white"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 10H9.33333V8H11L8 5M8 10H6.66667V8H5L8 5"
                        stroke="white"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>

                  <input
                    style={{ display: "none" }}
                    type="file"
                    accept="image/png, image/jpeg , image/jpg"
                    name="cancelCheque"
                    ref={cancelChequeImage}
                    onChange={(e) =>
                      handleImageChange(e.target.name, e.target.files[0])
                    }
                  />

                  <span
                    className="mx-3"
                    style={{ fontWeight: 500, fontSize: 10 }}
                  >
                    {inputData?.cancelCheque
                      ? inputData?.cancelCheque?.name
                      : "Option to Upload Cancel Cheque or Bank Passbook Front Page"}
                  </span>
                </Col>
              </Col>
            </Row>
          </div>

          <div className="">
            <Col
              md={8}
              className="d-flex justify-content-end align-items-end mt-5"
            >
              <button
                type="button"
                className="custom-btn-page-layout btn text-white "
                onClick={() => setStep("step2")}
              >
                {!isMobile && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    style={{ marginRight: 5 }}
                  >
                    <path
                      d="M15 8C15 9.38447 14.5895 10.7378 13.8203 11.889C13.0511 13.0401 11.9579 13.9373 10.6788 14.4672C9.3997 14.997 7.99224 15.1356 6.63437 14.8655C5.2765 14.5954 4.02922 13.9287 3.05025 12.9497C2.07129 11.9708 1.4046 10.7235 1.1345 9.36563C0.864407 8.00776 1.00303 6.6003 1.53284 5.32122C2.06266 4.04213 2.95986 2.94888 4.11101 2.17971C5.26215 1.41054 6.61553 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8ZM12 7.5H5.925L8.715 4.6965L8 4L4 8L8 12L8.715 11.2865L5.925 8.5H12V7.5Z"
                      fill="white"
                    />
                  </svg>
                )}
                Previous
              </button>
              <button
                type="button"
                className="custom-btn-page-layout btn text-white mx-4"
                onClick={() => handleNextBtn("step3")}
              >
                Next
                <svg
                  style={{ marginLeft: 5 }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M1 8C1 9.38447 1.41054 10.7378 2.17971 11.889C2.94888 13.0401 4.04213 13.9373 5.32122 14.4672C6.6003 14.997 8.00776 15.1356 9.36563 14.8655C10.7235 14.5954 11.9708 13.9287 12.9497 12.9497C13.9287 11.9708 14.5954 10.7235 14.8655 9.36563C15.1356 8.00776 14.997 6.6003 14.4672 5.32122C13.9373 4.04213 13.0401 2.94888 11.889 2.17971C10.7378 1.41054 9.38447 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8ZM4 7.5H10.075L7.285 4.6965L8 4L12 8L8 12L7.285 11.2865L10.075 8.5H4V7.5Z"
                    fill="white"
                  />
                </svg>
              </button>
            </Col>
          </div>
        </div>
      )}

      {step === "step5" && (
        <div className="mt-5" style={{ margin: 40 }}>
          <Row className=" g-3 mt-2 ">
            <Col lg={4} md={12} sm={12} className="d-flex">
              <Col md={4}>
                <Label
                  className="me-3"
                  style={{ fontWeight: 500 }}
                  for="exampleEmail"
                  md={12}
                >
                  Aadhar Card <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col sm={8}>
                <Input
                  id="exampleEmail"
                  value={inputData?.aadharCardNo}
                  name="aadharCardNo"
                  onChange={(e) =>
                    handleChangeInput(e.target.name, e.target.value)
                  }
                  className={`cust-input ${
                    inputDataError?.aadharCardNo ? "border-danger" : ""
                  }`}
                  type="number"
                />
              </Col>
            </Col>

            <Col lg={4} md={12} sm={12} className="d-flex">
              <Col md={4}>
                <Label
                  className="me-3"
                  style={{ fontWeight: 500 }}
                  for="exampleEmail"
                  md={12}
                >
                  Aadhar Card Image <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col sm={8}>
                <button
                  type="button"
                  onClick={handleaadharCardPhotoImageUploadBtn}
                  className={`${
                    inputDataError?.aadharCardImage
                      ? "border border-danger"
                      : ""
                  }`}
                  style={{
                    border: "none",
                    color: "white",
                    padding: 8,
                    background:
                      "linear-gradient(180deg, #C1C1C1 0%, #6D6D6D 100%)",
                    borderRadius: 5,
                  }}
                >
                  Upload
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    style={{ height: 16, width: 16, marginLeft: 2 }}
                  >
                    <path
                      d="M4 12.6667H12"
                      stroke="white"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 10H9.33333V8H11L8 5M8 10H6.66667V8H5L8 5"
                      stroke="white"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>

                <input
                  style={{ display: "none" }}
                  type="file"
                  accept="image/png, image/jpeg , image/jpg"
                  name="aadharCardImage"
                  ref={aadharCardPhotoImage}
                  onChange={(e) =>
                    handleImageChange(e.target.name, e.target.files[0])
                  }
                />

                <span
                  className="mx-3"
                  style={{ fontWeight: 500, fontSize: 10 }}
                >
                  {inputData?.aadharCardImage
                    ? inputData?.aadharCardImage?.name
                    : ".jpg, .png, .jpeg upload (Max size 150 KB)"}
                </span>
              </Col>
            </Col>
          </Row>
          <div
            className={`d-flex justify-content-end align-items-center w-100 ${
              isTablet ? "flex-column gap-2" : ""
            }`}
          >
            <button
              type="button"
              className="custom-btn-page-layout btn text-white mx-3"
              onClick={() => {
                setLandDetails([
                  ...landDetails,
                  {
                    indexId: landDetails?.length + 1,
                    country: "",
                    state: "",
                    dist: "",
                    taluka: "",
                    village: "",
                    surveyNo: "",
                    totalLand: "",
                    registeredLand: "",
                    vigha: "",
                    regVigha: "",
                    countryArray: countryArray,
                    stateArray: [],
                    districtArray: [],
                    villageArray: [],
                    talukaArray: [],
                    ownerShipStatus: false,
                    landOwnerName: inputData?.farmerName,
                    address:
                      inputData?.addressLine1 +
                      inputData?.addressLine2 +
                      inputData?.addressLine3,
                    guardianCountry: inputData?.country,
                    guardianState: inputData?.state,
                    guardianDist: inputData?.dist,
                    guardianTaluka: inputData?.taluka,
                    guardianVillage: inputData?.village,
                    guardianPincode: inputData?.pincode,
                    guardianName: "",
                    tenantDocument: "",
                    agreementDocument: "",
                    landDocument: "",
                  },
                ]);
                setCheckAvailabilty([...checkAvailabilty, { check: false }]);

                setLandDetailsError([
                  ...landDetailsError,
                  {
                    country: false,
                    state: false,
                    dist: false,
                    taluka: false,
                    village: false,
                    surveyNo: false,
                    totalLand: false,
                    registeredLand: false,
                    vigha: false,
                    regVigha: false,
                    ownerShipStatus: false,
                    landOwnerName: false,
                    address: false,
                    guardianCountry: false,
                    guardianState: false,
                    guardianDist: false,
                    guardianTaluka: false,
                    guardianVillage: false,
                    guardianPincode: false,
                    guardianName: false,
                    tenantDocument: false,
                    agreementDocument: false,
                    landDocument: false,
                  },
                ]);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className="me-2"
              >
                <path
                  d="M8.00016 2.66659C10.9408 2.66659 13.3335 5.05925 13.3335 7.99992C13.3335 10.9406 10.9408 13.3333 8.00016 13.3333C5.0595 13.3333 2.66683 10.9406 2.66683 7.99992C2.66683 5.05925 5.0595 2.66659 8.00016 2.66659ZM8.00016 1.33325C4.31816 1.33325 1.3335 4.31792 1.3335 7.99992C1.3335 11.6819 4.31816 14.6666 8.00016 14.6666C11.6822 14.6666 14.6668 11.6819 14.6668 7.99992C14.6668 4.31792 11.6822 1.33325 8.00016 1.33325ZM11.3335 7.33325H8.66683V4.66658H7.3335V7.33325H4.66683V8.66658H7.3335V11.3333H8.66683V8.66658H11.3335V7.33325Z"
                  fill="white"
                />
              </svg>
              Add New Land
            </button>
          </div>
          {landDetails?.map((landItem, landIndex) => (
            <Accordion
              key={landIndex}
              open={open}
              className={landIndex === 0 ? "mt-4" : ""}
              toggle={toggle}
            >
              <fieldset
                className="cus-add-req-sur border-none m-0 p-2"
                style={{ border: "none" }}
              >
                <AccordionItem>
                  <AccordionHeader targetId={`${landIndex + 1}`}>
                    Land No : {landIndex + 1} [{landItem?.landOwnerName}{" "}
                    {landItem?.village?.label} - {landItem?.surveyNo}]
                  </AccordionHeader>
                  <AccordionBody accordionId={`${landIndex + 1}`}>
                    <div className="w-100 d-flex  justify-content-end align-items-center">
                      <div
                        className="flex-shrink-0"
                        style={{ marginRight: 30 }}
                      >
                        {landDetails?.length > 1 && (
                          <button
                            type="button"
                            className="custom-btn-page-layout btn text-white mx-3 mb-3"
                            onClick={() => {
                              handleDeleteLandDetails(landIndex);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              className="me-2 mb-1"
                            >
                              <g clipPath="url(#clip0_217_1693)">
                                <path
                                  d="M6.63458 -0.0178223C5.50496 -0.0178223 4.59746 0.920832 4.59746 2.05564V2.30775H2.30765C1.98977 2.30775 1.73073 2.56679 1.73073 2.88468V3.4616H1.15381V4.61545H2.30765V13.2693C2.30765 14.2183 3.08939 15.0001 4.03842 15.0001H10.9615C11.9105 15.0001 12.6923 14.2183 12.6923 13.2693V4.61545H13.8461V3.4616H13.2692V2.88468C13.2692 2.56679 13.0102 2.30775 12.6923 2.30775H10.4025V2.05506C10.4025 0.920832 9.49496 -0.0178223 8.36535 -0.0178223H6.63458ZM6.63458 1.17179H8.36535C8.82919 1.17179 9.21285 1.55025 9.21285 2.05506V2.30775H5.7865V2.05506C5.7865 1.55025 6.17015 1.17237 6.634 1.17237L6.63458 1.17179ZM3.4615 4.61545H6.41823C6.48977 4.62295 6.56073 4.63333 6.63458 4.63333H8.36535C8.43919 4.63333 8.50958 4.62295 8.58169 4.61545H11.5384V13.2693C11.5384 13.5941 11.2863 13.8462 10.9615 13.8462H4.03842C3.71362 13.8462 3.4615 13.5941 3.4615 13.2693V4.61545ZM4.61535 5.76929V12.6924H5.76919V5.76929H4.61535ZM6.92304 5.76929V12.6924H8.07689V5.76929H6.92304ZM9.23073 5.76929V12.6924H10.3846V5.76929H9.23073Z"
                                  fill="#fff"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_217_1693">
                                  <rect width="15" height="15" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mt-1">Delete</span>
                          </button>
                        )}
                      </div>
                    </div>

                    {/* State, Dist and Taluka  */}
                    <div>
                      <Row className=" g-2 mt-2">
                        <Col lg={3} md={12} sm={12} className="d-flex ">
                          <Col md={3}>
                            <Label
                              className="me-3"
                              style={{ fontWeight: 500 }}
                              for="exampleEmail"
                              md={12}
                            >
                              Country<span className="required_span">*</span>
                            </Label>
                          </Col>
                          <Col sm={8}>
                            <Select
                              isMulti={false}
                              onChange={(e) => {
                                handleChangeLandDetailsInput(
                                  landIndex,
                                  "country",
                                  e
                                );
                              }}
                              value={landItem?.country}
                              options={countryArray}
                              // menuPlacement="top"
                              name="choices-single-default"
                              id="countryAddCompany"
                              className={`w-100 ${
                                landDetailsError[landIndex]["country"]
                                  ? "border border-danger"
                                  : ""
                              }`}
                            ></Select>
                          </Col>
                        </Col>
                        <Col lg={3} md={12} sm={12} className="d-flex ">
                          <Col md={3}>
                            <Label
                              className="me-3"
                              style={{ fontWeight: 500 }}
                              for="exampleEmail"
                              md={12}
                            >
                              State<span className="required_span">*</span>
                            </Label>
                          </Col>
                          <Col sm={8}>
                            <Select
                              isMulti={false}
                              onChange={(e) => {
                                handleChangeLandDetailsInput(
                                  landIndex,
                                  "state",
                                  e
                                );
                              }}
                              value={landItem?.state}
                              options={landItem?.stateArray}
                              // menuPlacement="top"
                              name="choices-single-default"
                              id="countryAddCompany"
                              className={`w-100 ${
                                landDetailsError[landIndex]["state"]
                                  ? "border border-danger"
                                  : ""
                              }`}
                            ></Select>
                          </Col>
                        </Col>
                        <Col lg={3} md={12} sm={12} className="d-flex ">
                          <Col md={3}>
                            <Label
                              className="me-3"
                              style={{ fontWeight: 500 }}
                              for="exampleEmail"
                              md={12}
                            >
                              Dist<span className="required_span">*</span>
                            </Label>
                          </Col>
                          <Col sm={8} className="d-flex ">
                            <Select
                              isMulti={false}
                              onChange={(e) => {
                                handleChangeLandDetailsInput(
                                  landIndex,
                                  "dist",
                                  e
                                );
                              }}
                              value={landItem?.dist}
                              name="dist"
                              options={landItem?.districtArray}
                              className={`w-100 ${
                                landDetailsError[landIndex]["dist"]
                                  ? "border border-danger"
                                  : ""
                              }`}
                            ></Select>
                          </Col>
                        </Col>
                        <Col lg={3} md={12} sm={12} className="d-flex ">
                          <Col md={3}>
                            <Label
                              className="me-3"
                              style={{ fontWeight: 500 }}
                              for="exampleEmail"
                              md={12}
                            >
                              Taluka<span className="required_span">*</span>
                            </Label>
                          </Col>

                          <Col sm={8}>
                            <Select
                              isMulti={false}
                              onChange={(e) => {
                                handleChangeLandDetailsInput(
                                  landIndex,
                                  "taluka",
                                  e
                                );
                              }}
                              value={landItem?.taluka}
                              name="taluka"
                              options={landItem?.talukaArray}
                              // menuPlacement="top"

                              className={`w-100 ${
                                landDetailsError[landIndex]["taluka"]
                                  ? "border border-danger"
                                  : ""
                              }`}
                            ></Select>
                          </Col>
                        </Col>
                      </Row>
                    </div>

                    {/* village,surveyNo and totalLand  */}
                    <div>
                      <Row className=" g-2 mt-2">
                        <Col lg={3} md={12} sm={12} className="d-flex ">
                          <Col md={3}>
                            <Label
                              className="me-3"
                              style={{ fontWeight: 500 }}
                              for="exampleEmail"
                              md={12}
                            >
                              Village<span className="required_span">*</span>
                            </Label>
                          </Col>
                          <Col md={8}>
                            <Select
                              isMulti={false}
                              onChange={(e) => {
                                handleChangeLandDetailsInput(
                                  landIndex,
                                  "village",
                                  e
                                );
                              }}
                              value={landItem?.village}
                              options={landItem?.villageArray}
                              // menuPlacement="top"
                              name="choices-single-default"
                              id="countryAddCompany"
                              className={`w-100 ${
                                landDetailsError[landIndex]["village"]
                                  ? "border border-danger"
                                  : ""
                              }`}
                            ></Select>
                          </Col>
                        </Col>
                        <Col lg={3} md={12} sm={12} className="d-flex ">
                          <Col md={3}>
                            <Label
                              className="me-3"
                              style={{ fontWeight: 500 }}
                              for="exampleEmail"
                              md={12}
                            >
                              Survey No.<span className="required_span">*</span>
                            </Label>
                          </Col>
                          <Col md={8} className="d-flex ">
                            <Input
                              id="exampleEmail"
                              value={landItem?.surveyNo}
                              name="surveyNo"
                              onChange={(e) =>
                                handleChangeLandDetailsInput(
                                  landIndex,
                                  e.target.name,
                                  e.target.value
                                )
                              }
                              className={`cust-input ${
                                landDetailsError[landIndex]["surveyNo"]
                                  ? "border-danger"
                                  : ""
                              }`}
                              type="text"
                            />
                          </Col>
                        </Col>
                        <Col lg={6}>
                          <button
                            type="button"
                            className="custom-btn-page-layout btn text-white mx-3"
                            onClick={() => {
                              handleCheckAvailabilty(landItem, landIndex);
                            }}
                          >
                            {availibilityLoading ? (
                              <ClipLoader color="#75D100" size={25} />
                            ) : (
                              "Check Land Availability"
                            )}
                          </button>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row className=" g-2 mt-2">
                        <Col lg={4} md={4} sm={12} className="d-flex ">
                          <Col md={6}>
                            <Label
                              className="me-3"
                              style={{ fontWeight: 500 }}
                              for="exampleEmail"
                              md={12}
                            >
                              Total Land in Document (In Sqm)
                              <span className="required_span">*</span>
                            </Label>
                          </Col>

                          <Col md={6}>
                            <Input
                              id="exampleEmail"
                              value={landItem?.totalLand}
                              name="totalLand"
                              disabled={
                                landItem?.remainLandVigha ? true : false
                              }
                              onChange={(e) =>
                                handleChangeLandDetailsInput(
                                  landIndex,
                                  e.target.name,
                                  e.target.value
                                )
                              }
                              className={`cust-input ${
                                landDetailsError[landIndex]["totalLand"]
                                  ? "border-danger"
                                  : ""
                              }`}
                              type="number"
                            />
                          </Col>
                        </Col>
                        <Col lg={4} md={4} sm={12} className="d-flex ">
                          <Col md={3}>
                            <Label
                              className="me-3"
                              style={{ fontWeight: 500 }}
                              for="exampleEmail"
                              md={12}
                            >
                              Total Vigha{" "}
                              <span className="required_span">*</span>
                            </Label>
                          </Col>
                          <Col md={8}>
                            <Input
                              id="exampleEmail"
                              value={landItem?.vigha}
                              name="vigha"
                              onChange={(e) =>
                                handleChangeLandDetailsInput(
                                  landIndex,
                                  e.target.name,
                                  e.target.value
                                )
                              }
                              disabled
                              className={`cust-input ${
                                landDetailsError[landIndex]["vigha"]
                                  ? "border-danger"
                                  : ""
                              }`}
                              type="number"
                            />
                          </Col>
                        </Col>
                        <Col lg={4} md={4} sm={12} className="d-flex ">
                          <Col md={6}>
                            <Label
                              className="me-3"
                              style={{ fontWeight: 500 }}
                              for="exampleEmail"
                              md={12}
                            >
                              Registered Vigha{" "}
                              <span className="required_span">*</span>
                            </Label>
                          </Col>

                          <Col md={6}>
                            <Input
                              id="exampleEmail"
                              value={landItem?.regVigha}
                              name="regVigha"
                              onChange={(e) =>
                                handleChangeLandDetailsInput(
                                  landIndex,
                                  e.target.name,
                                  e.target.value
                                )
                              }
                              className={`cust-input ${
                                landDetailsError[landIndex]["totalLand"]
                                  ? "border-danger"
                                  : ""
                              }`}
                              type="number"
                            />
                          </Col>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row className=" g-2 mt-2">
                        <Col lg={4} md={4} sm={12} className="d-flex ">
                          <Col md={7}>
                            <Label
                              className="me-3"
                              style={{ fontWeight: 500 }}
                              for="exampleEmail"
                              md={12}
                            >
                              Total Registered Land (In Sqm)
                              <span className="required_span">*</span>
                            </Label>
                          </Col>
                          <Col md={5}>
                            <Input
                              id="exampleEmail"
                              value={landItem?.registeredLand}
                              name="registeredLand"
                              onChange={(e) =>
                                handleChangeLandDetailsInput(
                                  landIndex,
                                  e.target.name,
                                  e.target.value
                                )
                              }
                              disabled
                              className={`cust-input ${
                                landDetailsError[landIndex]["registeredLand"]
                                  ? "border-danger"
                                  : ""
                              }`}
                              type="number"
                            />
                          </Col>
                        </Col>
                      </Row>
                    </div>

                    {checkAvailabilty[landIndex]?.check && (
                      <>
                        {/*  Ownership Status */}

                        <div
                          style={{}}
                          className={`mt-4 ${
                            isTablet
                              ? ""
                              : "d-flex justify-content-start align-items-center"
                          }`}
                        >
                          <Label className="me-4" style={{ fontWeight: 500 }}>
                            Ownership Status :
                          </Label>
                          <div>
                            <Label style={{ fontWeight: 500 }} className="me-2">
                              Is it Rented ?{" "}
                            </Label>
                            <Input
                              id="exampleEmail"
                              checked={landItem?.ownerShipStatus}
                              name="ownerShipStatus"
                              onChange={(e) =>
                                handleChangeLandDetailsInput(
                                  landIndex,
                                  e.target.name,
                                  e.target.checked
                                )
                              }
                              className="cust-input "
                              type="checkbox"
                            />
                          </div>
                        </div>

                        {/* LandOwnerName/ FarmerName  and Address  */}
                        <div style={{}} className="pb-0">
                          <Row className=" g-2 mt-2">
                            <Col lg={4} md={12} sm={12} className="d-flex ">
                              <Col md={4}>
                                <Label
                                  className="me-3"
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  {!landItem?.ownerShipStatus
                                    ? "Land Owner Name"
                                    : "Farmer Name"}
                                  <span className="required_span">*</span>
                                </Label>
                              </Col>
                              <Col md={8}>
                                <Input
                                  id="exampleEmail"
                                  value={
                                    !landItem?.ownerShipStatus
                                      ? landItem?.landOwnerName
                                      : landItem?.guardianName
                                  }
                                  name={
                                    !landItem?.ownerShipStatus
                                      ? "landOwnerName"
                                      : "guardianName"
                                  }
                                  onChange={(e) =>
                                    handleChangeLandDetailsInput(
                                      landIndex,
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  className="cust-input"
                                  type="text"
                                />
                              </Col>
                            </Col>
                            <Col lg={8} md={12} sm={12} className="d-flex ">
                              <Col md={1}>
                                <Label
                                  className="me-3"
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  Address
                                  <span className="required_span">*</span>
                                </Label>
                              </Col>
                              <Col md={8} className="d-flex mt-2">
                                <Input
                                  id="exampleEmail"
                                  value={landItem?.address}
                                  name="address"
                                  onChange={(e) =>
                                    handleChangeLandDetailsInput(
                                      landIndex,
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  className={`cust-input ${
                                    landDetailsError[landIndex]["address"]
                                      ? "border-danger"
                                      : ""
                                  }`}
                                  type="text"
                                />
                              </Col>
                            </Col>
                          </Row>
                        </div>
                        {/* Farmer State  and Farmer Dist  */}
                        <div style={{}}>
                          <Row className=" g-2 mt-2">
                            <Col lg={4} md={12} sm={12} className="d-flex ">
                              <Col md={3}>
                                <Label
                                  className="me-3"
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  Country
                                  <span className="required_span">*</span>
                                </Label>
                              </Col>
                              <Col sm={8}>
                                <Select
                                  isMulti={false}
                                  onChange={(e) => {
                                    handleChangeLandDetailsInput(
                                      landIndex,
                                      "guardianCountry",
                                      e
                                    );
                                  }}
                                  value={landItem?.guardianCountry}
                                  options={countryArray}
                                  // menuPlacement="top"
                                  name="choices-single-default"
                                  id="countryAddCompany"
                                  className={`w-100 ${
                                    landDetailsError[landIndex][
                                      "guardianCountry"
                                    ]
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                ></Select>
                              </Col>
                            </Col>
                            <Col lg={4} md={12} sm={12} className="d-flex ">
                              <Col md={3}>
                                <Label
                                  className="me-3"
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  State<span className="required_span">*</span>
                                </Label>
                              </Col>
                              <Col md={8}>
                                <Select
                                  isMulti={false}
                                  onChange={(e) => {
                                    handleChangeLandDetailsInput(
                                      landIndex,
                                      "guardianState",
                                      e
                                    );
                                  }}
                                  value={landItem?.guardianState}
                                  options={guardianStateArray}
                                  // menuPlacement="top"
                                  name="choices-single-default"
                                  id="stateAddCompany"
                                  className={`w-100 ${
                                    landDetailsError[landIndex]["guardianState"]
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                ></Select>
                              </Col>
                            </Col>
                            <Col lg={4} md={12} sm={12} className="d-flex ">
                              <Col md={3}>
                                <Label
                                  className="me-3"
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  Dist<span className="required_span">*</span>
                                </Label>
                              </Col>
                              <Col md={9} className="d-flex mt-2">
                                <Select
                                  isMulti={false}
                                  onChange={(e) => {
                                    handleChangeLandDetailsInput(
                                      landIndex,
                                      "guardianDist",
                                      e
                                    );
                                  }}
                                  value={landItem?.guardianDist}
                                  options={guardianDistrictArray}
                                  // menuPlacement="top"
                                  name="choices-single-default"
                                  id="stateAddCompany"
                                  className={`w-100 ${
                                    landDetailsError[landIndex]["guardianDist"]
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                ></Select>
                              </Col>
                            </Col>
                          </Row>
                        </div>

                        {/* Farmer Village, Farmer Taluka   and pincode  */}
                        <div style={{}}>
                          <Row className=" g-2 mt-2">
                            <Col lg={4} md={12} sm={12} className="d-flex ">
                              <Col md={3}>
                                <Label
                                  className="me-3"
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  Taluka<span className="required_span">*</span>
                                </Label>
                              </Col>
                              <Col md={8} className="d-flex mt-2">
                                <Select
                                  isMulti={false}
                                  onChange={(e) => {
                                    handleChangeLandDetailsInput(
                                      landIndex,
                                      "guardianTaluka",
                                      e
                                    );
                                  }}
                                  value={landItem?.guardianTaluka}
                                  options={guardianTalukaArray}
                                  // menuPlacement="top"
                                  name="choices-single-default"
                                  id="stateAddCompany"
                                  className={`w-100 ${
                                    landDetailsError[landIndex][
                                      "guardianTaluka"
                                    ]
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                ></Select>
                              </Col>
                            </Col>
                            <Col lg={4} md={12} sm={12} className="d-flex ">
                              <Col md={3}>
                                <Label
                                  className="me-3"
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  Village
                                  <span className="required_span">*</span>
                                </Label>
                              </Col>
                              <Col md={8}>
                                <Select
                                  isMulti={false}
                                  onChange={(e) => {
                                    handleChangeLandDetailsInput(
                                      landIndex,
                                      "guardianVillage",
                                      e
                                    );
                                  }}
                                  value={landItem?.guardianVillage}
                                  options={guardianVillageArray}
                                  // menuPlacement="top"
                                  name="choices-single-default"
                                  id="stateAddCompany"
                                  className={`w-100 ${
                                    landDetailsError[landIndex][
                                      "guardianVillage"
                                    ]
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                ></Select>
                              </Col>
                            </Col>

                            <Col lg={4} md={12} sm={12} className="d-flex ">
                              <Col md={3}>
                                <Label
                                  className="me-3"
                                  style={{ fontWeight: 500 }}
                                  for="exampleEmail"
                                  md={12}
                                >
                                  Pincode{" "}
                                  <span className="required_span">*</span>
                                </Label>
                              </Col>
                              <Col md={9} className="d-flex mt-2">
                                <Input
                                  id="exampleEmail"
                                  value={landItem?.guardianPincode}
                                  name="guardianPincode"
                                  onChange={(e) =>
                                    handleChangeLandDetailsInput(
                                      landIndex,
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  className={`cust-input ${
                                    landDetailsError[landIndex][
                                      "guardianPincode"
                                    ]
                                      ? "border-danger"
                                      : ""
                                  }`}
                                  type="number"
                                  maxLength={6}
                                />
                              </Col>
                            </Col>
                          </Row>
                        </div>

                        {/* Upload Document  */}
                        <div style={{}}>
                          <Row className=" g-2 mt-2">
                            <Col lg={4} md={12} sm={12} className="d-flex ">
                              <LandDocumentComponent
                                index={landIndex}
                                landDetails={landDetails}
                                landDetailsError={landDetailsError}
                                setLandDetailsError={setLandDetailsError}
                                setLandDetails={setLandDetails}
                                setLoading={setLoading}
                              />
                            </Col>

                            <Col lg={4} md={12} sm={12} className="d-flex ">
                              {landItem?.ownerShipStatus && (
                                <>
                                  <AgreementComponent
                                    index={landIndex}
                                    landDetails={landDetails}
                                    landDetailsError={landDetailsError}
                                    setLandDetailsError={setLandDetailsError}
                                    setLandDetails={setLandDetails}
                                    setLoading={setLoading}
                                  />
                                </>
                              )}
                            </Col>
                            <Col lg={4} md={12} sm={12} className="d-flex ">
                              {landItem?.ownerShipStatus && (
                                <>
                                  <TenetComponent
                                    index={landIndex}
                                    landDetails={landDetails}
                                    landDetailsError={landDetailsError}
                                    setLandDetailsError={setLandDetailsError}
                                    setLandDetails={setLandDetails}
                                    setLoading={setLoading}
                                  />
                                </>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                  </AccordionBody>
                </AccordionItem>
              </fieldset>
            </Accordion>
          ))}

          <div className="d-flex justify-content-end">
            {!checkAvailabilty?.find((er) => er?.check === false) && (
              <button
                type="button"
                className="custom-btn-page-layout btn text-white mx-3"
                onClick={(e) => {
                  handleNextBtn("step5");
                }}
              >
                Next
              </button>
            )}
          </div>
        </div>
      )}

      {step === "step6" && (
        <div>
          <div className="mt-5" style={{ margin: 40 }}>
            <div>
              <Row className={`${isMobile ? "" : " mt-2 g-3"}`}>
                <Col
                  lg={5}
                  md={12}
                  sm={12}
                  className={`${isMobile ? "" : "d-flex"}`}
                >
                  <Col md={3}>
                    <Label
                      className="me-3"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      Variety <span className="required_span">*</span>
                    </Label>
                  </Col>
                  <Col sm={8}>
                    <Select
                      isMulti={false}
                      onChange={(e) => {
                        handleOnChangeFarmingDetails("product", e);
                      }}
                      value={singleFarmingDetails?.product}
                      options={getCheckOptions(productOptions)}
                      // menuPlacement="top"
                      name="choices-single-default"
                      id="countryAddCompany"
                      className={`w-100 ${
                        singleFarmingDetailsError?.product
                          ? "border border-danger "
                          : ""
                      }`}
                    ></Select>
                  </Col>
                </Col>
                <Col
                  lg={5}
                  md={12}
                  sm={12}
                  className={`${isMobile ? "" : "d-flex"}`}
                >
                  <Col md={3}>
                    <Label
                      className="me-3"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      Sub Variety <span className="required_span">*</span>
                    </Label>
                  </Col>
                  <Col sm={8}>
                    <Input
                      type="text"
                      value={
                        singleFarmingDetails?.product?.value?.categoryName || ""
                      }
                      disabled
                    />
                  </Col>
                </Col>
              </Row>
            </div>
            <div>
              <Row className={`${isMobile ? "" : " mt-2 g-3"}`}>
                <Col
                  lg={5}
                  md={12}
                  sm={12}
                  className={`${isMobile ? "" : "d-flex"}`}
                >
                  <Col md={3}>
                    <Label
                      className="me-3"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      Vigha <span className="required_span">*</span>
                    </Label>
                  </Col>
                  <Col sm={8}>
                    <Input
                      id="exampleEmail"
                      value={singleFarmingDetails?.vigha}
                      name="vigha"
                      onChange={(e) =>
                        handleOnChangeFarmingDetails(
                          e.target.name,
                          e.target.value
                        )
                      }
                      className={`cust-input ${
                        singleFarmingDetailsError?.vigha ? "border-danger" : ""
                      }`}
                      type="number"
                    />
                  </Col>
                </Col>
                <Col
                  lg={5}
                  md={12}
                  sm={12}
                  className={`${isMobile ? "" : "d-flex"}`}
                >
                  <Col md={3}>
                    <Label
                      className="me-3"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      Total Vigha<span className="required_span">*</span>
                    </Label>
                  </Col>
                  <Col sm={8}>
                    <Input
                      id="exampleEmail"
                      value={singleFarmingDetails?.totalVigha}
                      name="amount"
                      disabled
                      className={`cust-input ${
                        inputDataError?.amount ? "border-danger" : ""
                      }`}
                      type="number"
                    />
                  </Col>
                </Col>
                <Col
                  lg={2}
                  md={12}
                  sm={12}
                  className={`${isMobile ? "" : "d-flex"}`}
                >
                  <button
                    type="button"
                    className="custom-btn-page-layout btn text-white mx-3 me-5"
                    onClick={(e) => handleAddFarmingDetails()}
                  >
                    {editFarmingDetails ? "Update" : "Add"}
                  </button>
                </Col>
              </Row>
            </div>
          </div>

          <Row className={`${isMobile ? "" : " mt-2 g-3"}`}>
            <Col md={12}>
              {/* Table View */}
              <div
                className="d-grid"
                style={{ margin: "0 20px", border: "1px solid #DEE2E6" }}
              >
                <TableContainer
                  columns={Farmingcolumn}
                  data={farmingDetails || []}
                  isGlobalFilter={false}
                  isAddUserList={false}
                  tableClass={"table-sm"}
                  divClass={"table-responsive"}
                />
              </div>
            </Col>
          </Row>

          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="custom-btn-page-layout btn text-white mx-3"
              onClick={() => setStep("step3")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                style={{ marginRight: 5 }}
              >
                <path
                  d="M15 8C15 9.38447 14.5895 10.7378 13.8203 11.889C13.0511 13.0401 11.9579 13.9373 10.6788 14.4672C9.3997 14.997 7.99224 15.1356 6.63437 14.8655C5.2765 14.5954 4.02922 13.9287 3.05025 12.9497C2.07129 11.9708 1.4046 10.7235 1.1345 9.36563C0.864407 8.00776 1.00303 6.6003 1.53284 5.32122C2.06266 4.04213 2.95986 2.94888 4.11101 2.17971C5.26215 1.41054 6.61553 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8ZM12 7.5H5.925L8.715 4.6965L8 4L4 8L8 12L8.715 11.2865L5.925 8.5H12V7.5Z"
                  fill="white"
                />
              </svg>
              Previous
            </button>

            <button
              type="button"
              className="custom-btn-page-layout btn text-white mx-3"
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {loading ? <ClipLoader color="#75D100" size={25} /> : "Submit"}
            </button>
          </div>
        </div>
      )}

      {showCapturePopup && (
        <ImageCapturePopup
          onClose={() => setShowCapturePopup(false)}
          onCapture={handleCapture}
          showCapturePopup={showCapturePopup}
        />
      )}
      <CheckLandAvalibility
        editModal={availibilityModal}
        landDetailsError={landDetailsError}
        setLandDetailsError={setLandDetailsError}
        checkAvailabilty={checkAvailabilty}
        setCheckAvailabilty={setCheckAvailabilty}
        onCloseModal={onCloseAvailibilityModal}
        availibilityDataIndex={availibilityDataIndex}
        availibilityData={availibilityData}
        landDetails={landDetails}
        setLandDetails={setLandDetails}
      />
    </div>
  );
};

export default FetchFarmer;

const LandDocumentComponent = ({
  index,
  landDetails,
  landDetailsError,
  setLandDetailsError,
  setLandDetails,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.layout);

  const landDocumentImage = useRef(null);
  const handlelandDocumentImageUploadBtn = () =>
    landDocumentImage.current.click();

  const handleImageLandDetailsChange = async (name, value) => {
    if (value) {
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!allowedTypes.includes(value.type)) {
        toast.error("Invalid file type. Please select a JPEG, PNG.");
      } else if (value.size > maxSize) {
        toast.error("File size exceeds the limit of 5MB.");
      } else {
        if (name === "landDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["landDocument"] = false;

          setLandDetailsError(arr5);
        } else if (name === "tenantDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["tenantDocument"] = false;
          setLandDetailsError(arr5);
        } else if (name === "agreementDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["agreementDocument"] = false;
          setLandDetailsError(arr5);
        }
        const formData = new FormData();
        formData.append("media", value);

        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/uploadmedia`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          let arr = [...landDetails];
          arr[index][name] = { url: _data.uploadedURL, name: _data?.filename };

          setLandDetails(arr);
        } else {
          toast.error(_data?.error);
        }

        return { url: "https://httpbin.org/post" };
      }
    }
  };

  return (
    <div>
      <button
        type="button"
        onClick={handlelandDocumentImageUploadBtn}
        className={` ${
          landDetailsError[index]["landDocument"] ? "border border-danger" : ""
        }`}
        style={{
          border: "none",
          color: "white",
          padding: 8,
          background: "linear-gradient(180deg, #C1C1C1 0%, #6D6D6D 100%)",
          borderRadius: 5,
        }}
      >
        Upload
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          style={{ height: 16, width: 16, marginLeft: 2 }}
        >
          <path
            d="M4 12.6667H12"
            stroke="white"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 10H9.33333V8H11L8 5M8 10H6.66667V8H5L8 5"
            stroke="white"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>

      <input
        type="file"
        style={{ display: "none" }}
        name="landDocument"
        ref={landDocumentImage}
        accept="image/png, image/jpeg , image/jpg,application/pdf "
        onChange={(e) =>
          handleImageLandDetailsChange(e.target.name, e.target.files[0])
        }
      />

      <span className="mx-3" style={{ fontWeight: 500, fontSize: 10 }}>
        {landDetails[index]?.landDocument
          ? landDetails[index]?.landDocument?.name
          : " Upload Agreement between owner and Tenant. (PDF/image)"}
      </span>
    </div>
  );
};

const AgreementComponent = ({
  index,
  landDetails,
  landDetailsError,
  setLandDetailsError,
  setLandDetails,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.layout);

  const agreementImage = useRef(null);
  const handleagreementImageUploadBtn = () => agreementImage.current.click();

  const handleImageLandDetailsChange = async (name, value) => {
    if (value) {
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!allowedTypes.includes(value.type)) {
        toast.error("Invalid file type. Please select a JPEG, PNG.");
      } else if (value.size > maxSize) {
        toast.error("File size exceeds the limit of 5MB.");
      } else {
        if (name === "landDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["landDocument"] = false;

          setLandDetailsError(arr5);
        } else if (name === "tenantDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["tenantDocument"] = false;
          setLandDetailsError(arr5);
        } else if (name === "agreementDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["agreementDocument"] = false;
          setLandDetailsError(arr5);
        }
        const formData = new FormData();
        formData.append("media", value);

        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/uploadmedia`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          let arr = [...landDetails];
          arr[index][name] = { url: _data.uploadedURL, name: _data?.filename };

          setLandDetails(arr);
        } else {
          toast.error(_data?.error);
        }

        return { url: "https://httpbin.org/post" };
      }
    }
  };

  return (
    <div>
      <button
        type="button"
        onClick={handleagreementImageUploadBtn}
        className={` ${
          landDetailsError[index]["agreementDocument"]
            ? "border border-danger"
            : ""
        }`}
        style={{
          border: "none",
          color: "white",
          padding: 8,
          background: "linear-gradient(180deg, #C1C1C1 0%, #6D6D6D 100%)",
          borderRadius: 5,
        }}
      >
        Upload
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          style={{
            height: 16,
            width: 16,
            marginLeft: 2,
          }}
        >
          <path
            d="M4 12.6667H12"
            stroke="white"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 10H9.33333V8H11L8 5M8 10H6.66667V8H5L8 5"
            stroke="white"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>

      <input
        style={{ display: "none" }}
        type="file"
        accept="image/png, image/jpeg , image/jpg,application/pdf "
        name="agreementDocument"
        ref={agreementImage}
        onChange={(e) =>
          handleImageLandDetailsChange(e.target.name, e.target.files[0])
        }
      />

      <span className="mx-3" style={{ fontWeight: 500, fontSize: 10 }}>
        {landDetails[index]?.agreementDocument
          ? landDetails[index]?.agreementDocument?.name
          : " Option to upload copy of 7/12 (PDF/image)"}
      </span>
    </div>
  );
};

const TenetComponent = ({
  index,
  landDetails,
  landDetailsError,
  setLandDetailsError,
  setLandDetails,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.layout);

  const tenetImage = useRef(null);
  const handletenetImageUploadBtn = () => tenetImage.current.click();

  const handleImageLandDetailsChange = async (name, value) => {
    if (value) {
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!allowedTypes.includes(value.type)) {
        toast.error("Invalid file type. Please select a JPEG, PNG.");
      } else if (value.size > maxSize) {
        toast.error("File size exceeds the limit of 5MB.");
      } else {
        if (name === "landDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["landDocument"] = false;

          setLandDetailsError(arr5);
        } else if (name === "tenantDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["tenantDocument"] = false;
          setLandDetailsError(arr5);
        } else if (name === "agreementDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["agreementDocument"] = false;
          setLandDetailsError(arr5);
        }
        const formData = new FormData();
        formData.append("media", value);

        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/uploadmedia`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          let arr = [...landDetails];
          arr[index][name] = { url: _data.uploadedURL, name: _data?.filename };

          setLandDetails(arr);
        } else {
          toast.error(_data?.error);
        }

        return { url: "https://httpbin.org/post" };
      }
    }
  };

  return (
    <div>
      <button
        type="button"
        onClick={handletenetImageUploadBtn}
        className={` ${
          landDetailsError[index]["tenantDocument"]
            ? "border border-danger"
            : ""
        }`}
        style={{
          border: "none",
          color: "white",
          padding: 8,
          background: "linear-gradient(180deg, #C1C1C1 0%, #6D6D6D 100%)",
          borderRadius: 5,
        }}
      >
        Upload
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          style={{
            height: 16,
            width: 16,
            marginLeft: 2,
          }}
        >
          <path
            d="M4 12.6667H12"
            stroke="white"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 10H9.33333V8H11L8 5M8 10H6.66667V8H5L8 5"
            stroke="white"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>

      <input
        style={{ display: "none" }}
        type="file"
        accept="image/png, image/jpeg , image/jpg,application/pdf "
        name="tenantDocument"
        ref={tenetImage}
        onChange={(e) =>
          handleImageLandDetailsChange(e.target.name, e.target.files[0])
        }
      />

      <span className="mx-3" style={{ fontWeight: 500, fontSize: 10 }}>
        {landDetails[index]?.tenantDocument
          ? landDetails[index]?.tenantDocument?.name
          : "Photo of Tenant"}
      </span>
    </div>
  );
};
