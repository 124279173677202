import React, { useState } from "react";
import PageLayout from "../../Component/PageLayout";
import { Col, Row, Input, Label } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import useBreakPoints from "../../hooks/useBreakPoints";

const ViewEmployee = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useBreakPoints();

  const [step, setStep] = useState("step1");

  // eslint-disable-next-line
  const [inputData, setInputData] = useState({
    name: location?.state?.employeeName,
    // unit: location?.state?.unit?.unitName,
    addressLine1: location?.state?.addressLine1,
    addressLine2: location?.state?.addressLine2,
    addressLine3: location?.state?.addressLine3,
    country: location?.state?.country?.name,
    state: location?.state?.state?.stateName,
    dist: location?.state?.district?.districtName,
    taluka: location?.state?.taluka?.talukaName,
    village: location?.state?.village?.villageName,
    pincode: location?.state?.pincode,
    mobileNo: location?.state?.mobileNo?.split("+91")[1],
    email: location?.state?.email,
    roleType: location?.state?.role?.roleName,
    department: location?.state?.department?.departmentName,
    designation: location?.state?.designation?.designation,
    panNo: location?.state?.panNo,
    aadharCardNo: location?.state?.aadharCardNo,
    photo: {
      url: location?.state?.photo,
      name: location?.state?.photo?.split("/").at(-1).slice(5),
    },
    bankName: location?.state?.bankDetails?.[0]?.bankName,
    accountNo: location?.state?.bankDetails?.[0]?.accountNo,
    branchName: location?.state?.bankDetails?.[0]?.branchName,
    ifscCode: location?.state?.bankDetails?.[0]?.ifscCode,
    cancelCheque: {
      url: location?.state?.bankDetails?.[0]?.cancelledChequeImg,
      name: location?.state?.bankDetails?.[0]?.cancelledChequeImg
        ?.split("/")
        .at(-1),
    },
    status: location?.state?.bankDetails?.[0]?.status,
    userName: location?.state?.userDetails?.[0]?.userName,
    password: location?.state?.userDetails?.[0]?.password,
  });

  // handleNext Btn
  const handleNextBtn = (stepper) => {
    if (stepper === "step1") {
      setStep("step2");
    }

    if (stepper === "step2") {
      setStep("step3");
    }
  };

  return (
    <PageLayout
      title={"View employee"}
      backBtnTitle={"Back to Employee List"}
      onClick={() => navigate("/employeeList")}
    >
      {/* Stepper View  */}
      <div className="farm-add-stepper">
        <div
          className={`${
            step === "step1" ? "farm-stepper-active" : ""
          } step1-farm-add`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="24"
            viewBox="0 0 21 24"
            fill="none"
          >
            <g clipPath="url(#clip0_123_361)">
              <path
                d="M10.5 12C12.0913 12 13.6174 11.3679 14.7426 10.2426C15.8679 9.11742 16.5 7.5913 16.5 6C16.5 4.4087 15.8679 2.88258 14.7426 1.75736C13.6174 0.632141 12.0913 0 10.5 0C8.9087 0 7.38258 0.632141 6.25736 1.75736C5.13214 2.88258 4.5 4.4087 4.5 6C4.5 7.5913 5.13214 9.11742 6.25736 10.2426C7.38258 11.3679 8.9087 12 10.5 12ZM8.35781 14.25C3.74063 14.25 0 17.9906 0 22.6078C0 23.3766 0.623438 24 1.39219 24H19.6078C20.3766 24 21 23.3766 21 22.6078C21 17.9906 17.2594 14.25 12.6422 14.25H8.35781Z"
                fill={step === "step1" ? "white" : "black"}
              />
            </g>
            <defs>
              <clipPath id="clip0_123_361">
                <rect
                  width="21"
                  height="24"
                  fill={step === "step1" ? "white" : "black"}
                />
              </clipPath>
            </defs>
          </svg>

          <span className={`${step === "step1" ? "text-white" : "text-black"}`}>
            {isMobile ? "" : "Personal Details"}
          </span>
        </div>
        <div
          className={`${
            step === "step2" ? "farm-stepper-active" : ""
          } step2-farm-add`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.59971 10.8C10.2362 10.8 10.8467 10.5471 11.2968 10.0971C11.7469 9.64697 11.9997 9.03652 11.9997 8.4C11.9997 7.76348 11.7469 7.15303 11.2968 6.70294C10.8467 6.25286 10.2362 6 9.59971 6C8.96319 6 8.35274 6.25286 7.90265 6.70294C7.45256 7.15303 7.19971 7.76348 7.19971 8.4C7.19971 9.03652 7.45256 9.64697 7.90265 10.0971C8.35274 10.5471 8.96319 10.8 9.59971 10.8Z"
              fill={step === "step2" ? "white" : "black"}
            />
            <path
              d="M8.03601 12.0983C8.14764 11.9479 8.29294 11.8257 8.46026 11.7416C8.62759 11.6574 8.8123 11.6135 8.99961 11.6135C9.18692 11.6135 9.37163 11.6574 9.53896 11.7416C9.70628 11.8257 9.85158 11.9479 9.96321 12.0983L12.2552 15.1848C12.3878 15.3634 12.4681 15.5753 12.4872 15.797C12.5063 16.0186 12.4634 16.2412 12.3633 16.4398C12.2632 16.6385 12.1099 16.8054 11.9205 16.922C11.731 17.0386 11.5129 17.1002 11.2904 17.1H6.70881C6.48646 17.1 6.26849 17.0382 6.0792 16.9215C5.88991 16.8049 5.73676 16.6379 5.63682 16.4393C5.53689 16.2407 5.4941 16.0182 5.51324 15.7967C5.53238 15.5751 5.61269 15.3633 5.74521 15.1848L8.03601 12.0983Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.99983 13.8204L7.90063 15.3H10.099L8.99983 13.8204ZM9.96343 12.0983C9.8518 11.9479 9.7065 11.8257 9.53918 11.7416C9.37185 11.6574 9.18714 11.6135 8.99983 11.6135C8.81252 11.6135 8.62781 11.6574 8.46049 11.7416C8.29316 11.8257 8.14787 11.9479 8.03623 12.0983L5.74423 15.1848C5.61165 15.3634 5.53133 15.5753 5.51224 15.797C5.49315 16.0186 5.53604 16.2412 5.63612 16.4398C5.7362 16.6385 5.88953 16.8054 6.07899 16.922C6.26845 17.0386 6.48658 17.1002 6.70903 17.1H11.2906C11.513 17.1 11.731 17.0382 11.9202 16.9215C12.1095 16.8049 12.2627 16.6379 12.3626 16.4393C12.4626 16.2407 12.5053 16.0182 12.4862 15.7967C12.4671 15.5751 12.3868 15.3633 12.2542 15.1848L9.96223 12.0983H9.96343Z"
              fill={step === "step2" ? "white" : "black"}
            />
            <path
              d="M12.5926 10.9477C12.7052 10.8176 12.8445 10.7133 13.0011 10.6417C13.1576 10.5702 13.3277 10.5332 13.4998 10.5332C13.6719 10.5332 13.8419 10.5702 13.9985 10.6417C14.155 10.7133 14.2943 10.8176 14.407 10.9477L18.0154 15.1141C18.1661 15.288 18.2636 15.5015 18.2964 15.7293C18.3291 15.957 18.2957 16.1894 18.2001 16.3987C18.1045 16.608 17.9507 16.7854 17.7571 16.9098C17.5635 17.0341 17.3383 17.1002 17.1082 17.1001H9.89136C9.66126 17.1002 9.43599 17.0341 9.24238 16.9098C9.04878 16.7854 8.89501 16.608 8.79941 16.3987C8.7038 16.1894 8.67038 15.957 8.70314 15.7293C8.7359 15.5015 8.83345 15.288 8.98416 15.1141L12.5926 10.9477Z"
              fill={step === "step2" ? "white" : "black"}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.4998 12.6493L11.2042 15.3001H15.7954L13.4998 12.6493ZM14.407 10.9477C14.2943 10.8176 14.155 10.7133 13.9985 10.6417C13.8419 10.5702 13.6719 10.5332 13.4998 10.5332C13.3277 10.5332 13.1576 10.5702 13.0011 10.6417C12.8445 10.7133 12.7052 10.8176 12.5926 10.9477L8.98416 15.1141C8.83345 15.288 8.7359 15.5015 8.70314 15.7293C8.67038 15.957 8.7038 16.1894 8.79941 16.3987C8.89501 16.608 9.04878 16.7854 9.24238 16.9098C9.43599 17.0341 9.66126 17.1002 9.89136 17.1001H17.1082C17.3383 17.1002 17.5635 17.0341 17.7571 16.9098C17.9507 16.7854 18.1045 16.608 18.2001 16.3987C18.2957 16.1894 18.3291 15.957 18.2964 15.7293C18.2636 15.5015 18.1661 15.288 18.0154 15.1141L14.407 10.9477Z"
              fill={step === "step2" ? "white" : "black"}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.1998 4.20005V19.8H19.7998V4.20005H4.1998ZM3.5998 1.80005C3.12242 1.80005 2.66458 1.98969 2.32701 2.32726C1.98945 2.66482 1.7998 3.12266 1.7998 3.60005V20.4C1.7998 20.8774 1.98945 21.3353 2.32701 21.6728C2.66458 22.0104 3.12242 22.2001 3.5998 22.2001H20.3998C20.8772 22.2001 21.335 22.0104 21.6726 21.6728C22.0102 21.3353 22.1998 20.8774 22.1998 20.4V3.60005C22.1998 3.12266 22.0102 2.66482 21.6726 2.32726C21.335 1.98969 20.8772 1.80005 20.3998 1.80005H3.5998Z"
              fill={step === "step2" ? "white" : "black"}
            />
          </svg>
          <span className={`${step === "step2" ? "text-white" : "text-black"}`}>
            {isMobile ? "" : "Photo & details"}
          </span>
        </div>
        <div
          className={`${
            step === "step3" ? "farm-stepper-active" : ""
          } step3-farm-add`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M10.968 2.32496C11.2676 2.10618 11.629 1.98828 12 1.98828C12.371 1.98828 12.7324 2.10618 13.032 2.32496L20.453 7.74096C21.43 8.45296 20.927 9.99796 19.719 10.001H4.28C3.072 9.99796 2.57 8.45296 3.546 7.74096L10.967 2.32496H10.968ZM13 6.24996C13 5.98474 12.8946 5.73039 12.7071 5.54285C12.5196 5.35532 12.2652 5.24996 12 5.24996C11.7348 5.24996 11.4804 5.35532 11.2929 5.54285C11.1054 5.73039 11 5.98474 11 6.24996C11 6.51518 11.1054 6.76953 11.2929 6.95707C11.4804 7.1446 11.7348 7.24996 12 7.24996C12.2652 7.24996 12.5196 7.1446 12.7071 6.95707C12.8946 6.76953 13 6.51518 13 6.24996ZM11.25 16H9.25V11H11.25V16ZM14.75 16H12.75V11H14.75V16ZM18.5 16H16.25V11H18.5V16ZM18.75 17H5.25C4.65326 17 4.08097 17.237 3.65901 17.659C3.23705 18.0809 3 18.6532 3 19.25V19.75C3 20.165 3.336 20.5 3.75 20.5H20.25C20.4489 20.5 20.6397 20.4209 20.7803 20.2803C20.921 20.1396 21 19.9489 21 19.75V19.25C21 18.6532 20.7629 18.0809 20.341 17.659C19.919 17.237 19.3467 17 18.75 17ZM7.75 16H5.5V11H7.75V16Z"
              fill={step === "step3" ? "white" : "black"}
            />
          </svg>
          <span className={`${step === "step3" ? "text-white" : "text-black"}`}>
            {isMobile ? "" : " Bank Details"}
          </span>
        </div>
      </div>

      {/* Step1 */}

      {step === "step1" && (
        <div className="mt-5 gap-4 d-flex flex-column" style={{ margin: 40 }}>
          {/* Name  */}
          <div>
            <Row className="g-3 mt-1">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Name
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.name}
                    name="name"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              {/* <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label className="me-3" style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                    Unit Address
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.unit}
                    name="name"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col> */}
            </Row>
          </div>
          {/* AddressLine  */}
          <div>
            <Row className="g-3 mt-1">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Address Line 1<span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.addressLine1}
                    name="addressLine1"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Address Line 2<span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.addressLine2}
                    name="addressLine2"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Address Line 3<span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.addressLine3}
                    name="addressLine3"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>
          {/* Country, State, Dist  */}
          <div>
            <Row className="g-3 mt-1">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Country
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.country}
                    name="name"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    State
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.state}
                    name="name"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Dist
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.dist}
                    name="name"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>
          {/* City, Taluka ,Village  */}
          <div>
            <Row className="g-3 mt-1">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Taluka
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.taluka}
                    name="name"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Village
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.village}
                    name="name"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Pincode
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.pincode}
                    name="pincode"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* pincode, mobileNo, email  */}
          <div>
            <Row className="g-3 mt-1">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Mobile No
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.mobileNo}
                    name="mobileNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    E-mail Address
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.email}
                    name="email"
                    disabled
                    className="cust-input"
                    type="email"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* Next Button View */}
          <div>
            <Col
              md={12}
              className="d-flex justify-content-end align-items-end mt-2"
            >
              <button
                type="button"
                className="custom-btn-page-layout btn text-white "
                onClick={() => handleNextBtn("step1")}
              >
                Next
                {!isMobile && (
                  <svg
                    style={{ marginLeft: 5 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M1 8C1 9.38447 1.41054 10.7378 2.17971 11.889C2.94888 13.0401 4.04213 13.9373 5.32122 14.4672C6.6003 14.997 8.00776 15.1356 9.36563 14.8655C10.7235 14.5954 11.9708 13.9287 12.9497 12.9497C13.9287 11.9708 14.5954 10.7235 14.8655 9.36563C15.1356 8.00776 14.997 6.6003 14.4672 5.32122C13.9373 4.04213 13.0401 2.94888 11.889 2.17971C10.7378 1.41054 9.38447 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8ZM4 7.5H10.075L7.285 4.6965L8 4L12 8L8 12L7.285 11.2865L10.075 8.5H4V7.5Z"
                      fill="white"
                    />
                  </svg>
                )}
              </button>
            </Col>
          </div>
        </div>
      )}

      {/* step2 */}

      {step === "step2" && (
        <div className="mt-5 gap-4 d-flex flex-column" style={{ margin: 40 }}>
          {/* Role Type , Department and Designation  */}

          {/* Country, State, Dist  */}
          <div>
            <Row className="g-3 mt-1">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Role Type
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.roleType}
                    name="name"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Department
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.department}
                    name="name"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Designation
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.designation}
                    name="name"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>
          {/* PanNo ,AadharNo  */}
          <div>
            <Row className="g-3 mt-1">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    PAN No.
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.panNo}
                    name="panNo"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Aadhar card No.
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.aadharCardNo}
                    name="aadharCardNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          {/* photo Cheque  */}
          <div>
            <Row className="g-3 mt-1">
              <Col lg={6} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Passport Size Photo
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <span className="mx-3 " style={{ fontWeight: 500 }}>
                  {inputData?.photo
                    ? inputData?.photo?.name
                    : ".jpg, .jpeg, .png etc."}
                </span>
              </Col>
            </Row>
          </div>

          {/*  Button View */}
          <div className="">
            <Col
              md={12}
              className="d-flex justify-content-end align-items-end mt-5"
            >
              <button
                type="button"
                className="custom-btn-page-layout btn text-white "
                onClick={() => setStep("step1")}
              >
                {!isMobile && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    style={{ marginRight: 5 }}
                  >
                    <path
                      d="M15 8C15 9.38447 14.5895 10.7378 13.8203 11.889C13.0511 13.0401 11.9579 13.9373 10.6788 14.4672C9.3997 14.997 7.99224 15.1356 6.63437 14.8655C5.2765 14.5954 4.02922 13.9287 3.05025 12.9497C2.07129 11.9708 1.4046 10.7235 1.1345 9.36563C0.864407 8.00776 1.00303 6.6003 1.53284 5.32122C2.06266 4.04213 2.95986 2.94888 4.11101 2.17971C5.26215 1.41054 6.61553 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8ZM12 7.5H5.925L8.715 4.6965L8 4L4 8L8 12L8.715 11.2865L5.925 8.5H12V7.5Z"
                      fill="white"
                    />
                  </svg>
                )}
                Previous
              </button>
              <button
                type="button"
                className="custom-btn-page-layout btn text-white mx-4"
                onClick={() => handleNextBtn("step2")}
              >
                Next
                {!isMobile && (
                  <svg
                    style={{ marginLeft: 5 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M1 8C1 9.38447 1.41054 10.7378 2.17971 11.889C2.94888 13.0401 4.04213 13.9373 5.32122 14.4672C6.6003 14.997 8.00776 15.1356 9.36563 14.8655C10.7235 14.5954 11.9708 13.9287 12.9497 12.9497C13.9287 11.9708 14.5954 10.7235 14.8655 9.36563C15.1356 8.00776 14.997 6.6003 14.4672 5.32122C13.9373 4.04213 13.0401 2.94888 11.889 2.17971C10.7378 1.41054 9.38447 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8ZM4 7.5H10.075L7.285 4.6965L8 4L12 8L8 12L7.285 11.2865L10.075 8.5H4V7.5Z"
                      fill="white"
                    />
                  </svg>
                )}
              </button>
            </Col>
          </div>
        </div>
      )}

      {/* step3 */}
      {step === "step3" && (
        <div className="mt-5 gap-4 d-flex flex-column" style={{ margin: 40 }}>
          {/* Role Type , Department and Designation  */}

          {/* bankName ,accountno, branchName  */}
          <div>
            <Row className="g-3 mt-1">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Bank Name
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.bankName}
                    name="bankName"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Account No
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.accountNo}
                    name="accountNo"
                    disabled
                    className="cust-input"
                    type="number"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Branch Name
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.branchName}
                    name="branchName"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>
          {/* Ifsc Code ,Cancel Cheque  */}
          <div>
            <Row className="g-3 mt-1">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    IFSC code
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.ifscCode}
                    name="ifscCode"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex ">
                <Label
                  className="me-3"
                  style={{ fontWeight: 500 }}
                  for="exampleEmail"
                  md={4}
                >
                  Cancel Cheque
                  <span className="required_span"> * </span>{" "}
                </Label>
                <span className="mx-3" style={{ fontWeight: 500 }}>
                  {inputData?.cancelCheque
                    ? inputData?.cancelCheque?.name
                    : " .jpg, .jpeg, .png etc."}
                </span>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex"></Col>
            </Row>
          </div>

          {/* Status */}
          <div>
            <Row className="g-3 mt-1">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Status
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <div className="d-flex">
                    <div
                      className={`${
                        inputData?.status === "active"
                          ? "active-emp-status"
                          : ""
                      } emp-step-3`}
                      style={{ cursor: "pointer" }}
                      disabled
                    >
                      <span>Active</span>
                    </div>
                    <div
                      className={`${
                        inputData?.status === "inactive"
                          ? "active-emp-status"
                          : ""
                      } emp-step-3-inactive`}
                      style={{ cursor: "pointer" }}
                      disabled
                    >
                      <span>Inactive</span>
                    </div>
                  </div>
                </Col>
              </Col>
            </Row>
          </div>
          {/* userName and Password */}
          <div>
            <Row className="g-3 mt-1">
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    User Name
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.userName}
                    name="userName"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
              <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Password
                    <span className="required_span"> * </span>{" "}
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.password}
                    name="password"
                    disabled
                    className="cust-input"
                    type="text"
                  />
                </Col>
              </Col>
            </Row>
          </div>

          <div className="">
            <Col
              md={8}
              className="d-flex justify-content-end align-items-end mt-5"
            >
              <button
                type="button"
                className="custom-btn-page-layout btn text-white "
                onClick={() => setStep("step2")}
              >
                {!isMobile && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    style={{ marginRight: 5 }}
                  >
                    <path
                      d="M15 8C15 9.38447 14.5895 10.7378 13.8203 11.889C13.0511 13.0401 11.9579 13.9373 10.6788 14.4672C9.3997 14.997 7.99224 15.1356 6.63437 14.8655C5.2765 14.5954 4.02922 13.9287 3.05025 12.9497C2.07129 11.9708 1.4046 10.7235 1.1345 9.36563C0.864407 8.00776 1.00303 6.6003 1.53284 5.32122C2.06266 4.04213 2.95986 2.94888 4.11101 2.17971C5.26215 1.41054 6.61553 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8ZM12 7.5H5.925L8.715 4.6965L8 4L4 8L8 12L8.715 11.2865L5.925 8.5H12V7.5Z"
                      fill="white"
                    />
                  </svg>
                )}
                Previous
              </button>
              <button
                type="button"
                className="custom-btn-page-layout btn text-white mx-4"
                onClick={(e) => navigate("/employeelist")}
              >
                Cancel
              </button>
            </Col>
          </div>
        </div>
      )}
    </PageLayout>
  );
};

export default ViewEmployee;
