import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { confirmAlert } from "react-confirm-alert";
import dayjs from "dayjs";
import { commonActions, employeeActions } from "../../redux/actions";
import { usePermissionHooks } from "../../common/useCheckPermission";
import { keys } from "../../config/keys";
import { appointmentValidationSchema } from "./validationScheme";
import { toast } from "react-toastify";
import { GridAction } from "../../common/useGridAction";

export const useSlotBookingListHooks = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { farmer_slot_booking_list } = useSelector((obj) => obj);

  const permission = usePermissionHooks(location);

  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const column = useMemo(
    () => [
      {
        id: "sr",
        Header: "Sr. No.",
        Cell: (cellProps) => {
          return <span>{cellProps?.row?.index + 1}</span>;
        },
      },
      {
        Header: "Technician Name",
        accessor: "employeename",
      },
      {
        Header: "Unit",
        accessor: "unitname",
      },
      {
        Header: "Farmer Code",
        accessor: "farmercode",
      },
      {
        Header: "Farmer Name",
        accessor: "farmername",
      },
      {
        Header: "Appointment Date",
        accessor: "appointmentdate",
        Cell: ({ value }) => keys().shortDate(value),
      },
      {
        Header: "Appointment Time",
        accessor: "appointmenttime",
        Cell: ({ value }) => keys().displayTime(value),
      },
      {
        ...GridAction({
          permission,
          onEditClick: (cellProps) => {
            navigate(`/purchaseappointment`, {
              state: { id: cellProps.row.original.id },
            });
          },
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original.id);
          },
        }),
      },
    ],
    // eslint-disable-next-line
    []
  );

  const get_slot_booking_list = () =>
    dispatch(employeeActions.get_farmer_slot_booking_list({}));

  const onGridDelete = async () => {
    setDeleteLoading(true);
    if (deleteId > 0) {
      dispatch(
        employeeActions.delete_booking_appointment(
          { id: deleteId },
          {
            onSuccess: () => {
              toast.success("Booking Appointment Deleted Successfully!!!");
              get_slot_booking_list();
            },
            onError: (err) =>
              toast.error("Something went wrong. Please try again."),
          }
        )
      );
      setDeleteLoading(false);
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    get_slot_booking_list();
  }, []);

  return {
    column,
    permission,
    deleteModal,
    onGridDelete,
    deleteLoading,
    setDeleteModal,
    farmer_slot_booking_list,
  };
};

const initialValuesAppointment = {
  id: 0,
  employeeid: 0,
  selectedemployee: null,
  companyunitid: 0,
  selectedunit: null,
  farmerid: 0,
  selectedfarmer: null,
  appointmentdatestring: dayjs(Date()).format(keys().dbDateFormat),
  appointmentdate: dayjs(Date()),
  appointmenttimestring: dayjs(Date()).format(keys().dbTimeFormat),
  appointmenttime: dayjs(Date()),
  noofbags: 0,
};

export const useAppointmentHooks = (id = 0) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialValuesAppointment);
  const location = useLocation();

  const { employeeDropdownList, companyunitlist, farmerDropdownList } =
    useSelector((obj) => obj);

  const permission = usePermissionHooks(location);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(appointmentValidationSchema),
  });

  const ondropdownchange = (e, selectedOption, id_name, value_name) => {
    setFormData((prev) => ({
      ...prev,
      [id_name]: selectedOption?.id || 0,
      [value_name]: selectedOption || null,
    }));
  };

  const onfarmerdropdownchange = (e, selectedOption, id_name, value_name) => {
    setFormData((prev) => ({
      ...prev,
      [id_name]: selectedOption?.farmerid || 0,
      [value_name]: selectedOption || null,
    }));
  };

  const handleChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const get_farmerDropdownList = (employeeid, companyunitid) =>
    dispatch(
      employeeActions.get_farmer_by_employee_and_unit(employeeid, companyunitid)
    );

  const get_technician_list = () =>
    dispatch(employeeActions.getEmployeeDropdownList());

  const get_companyunitList = () =>
    dispatch(commonActions.get_company_unit_list());

  const onDateChange = (dateValue) => {
    setFormData((prev) => ({
      ...prev,
      appointmentdate: dateValue,
      appointmentdatestring: dayjs(dateValue).format(keys().dbDateFormat),
    }));
  };

  const onTimeChange = (timeValue) => {
    setFormData((prev) => ({
      ...prev,
      appointmenttime: timeValue,
      appointmenttimestring: dayjs(timeValue).format(keys().dbTimeFormat),
    }));
  };

  useEffect(() => {
    get_technician_list();
    get_companyunitList();
    //get_farmerDropdownList();
  }, []);

  useEffect(() => {
    if (formData.employeeid > 0 && formData.companyunitid > 0)
      get_farmerDropdownList(formData.employeeid, formData.companyunitid);
  }, [formData.employeeid, formData.companyunitid]);

  useEffect(() => {
    if (location.state.id > 0 && employeeDropdownList.length > 0) {
      //get_farmer_allocation_by_id(location.state.id);
    }
  }, [location, employeeDropdownList]);

  const onSubmit = () => {
    const {
      selectedemployee,
      selectedfarmer,
      selectedunit,
      appointmentdate,
      appointmenttime,
      ...others
    } = formData;

    dispatch(
      employeeActions.save_farmer_purchase_appointment(others, {
        onSuccess: (res) => {
          toast.success(
            `Appointment Successfully ${formData.id > 0 ? `Updated` : `Booked`}`
          );
          navigate("/purchaseappointmentlist");
        },
      })
    );
  };

  return {
    errors,
    control,
    onSubmit,
    formData,
    register,
    permission,
    setFormData,
    onDateChange,
    handleSubmit,
    companyunitlist,
    ondropdownchange,
    onfarmerdropdownchange,
    onTimeChange,
    handleChange,
    farmerDropdownList,
    employeeDropdownList,
  };
};
