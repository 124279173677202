import { EVENT_LIST, PUBLISHED_DOCUMENTS } from "../constTypes";

const _published_documents = [];
export const published_documents = (state = _published_documents, action) => {
  switch (action.type) {
    case PUBLISHED_DOCUMENTS:
      return [...action.payload];
    default:
      return state;
  }
};

export const event_list = (state = [], action) => {
  switch (action.type) {
    case EVENT_LIST:
      return [...action.payload];
    default:
      return state;
  }
};
