import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Col, Form, FormGroup, Row } from "reactstrap";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Autocomplete, Paper, Typography, Link, Button } from "@mui/material";
import { Attachment, Cancel, Save } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { confirmAlert } from "react-confirm-alert";
import FileBase64 from "react-file-base64";

import PageLayout from "../../../Component/PageLayout";
import TableContainer from "../../../Component/TableContainer";
import { useIncentiveSchemeUpdateHooks } from "./useIncentiveSchemeUpdateHooks";
import { ErrorMessage } from "../../../common/Error";
import { keys } from "../../../config/keys";

import { createTheme, styled } from "@mui/material/styles";
import { textFieldProps } from "../../../common/commonControlProps";

const FarmerDetails = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  padding: "5px 10px",
  lineHeight: "60px",
}));
const darkTheme = createTheme({ palette: { mode: "dark" } });

const IncentiveSchemeUpdate = () => {
  const {
    register,
    formData,
    rowData,
    handleChange,
    errors,
    resetFormData,
    handleSubmit,
    onSubmit,
    onDateChange,
    onfarmerdropdownchange,
    onincentivedropdownchange,
    onGridEdit,
    onGridDelete,
    onFarmerPhotoChange,
    onUsedKitPhotoChange,
    onInvoiceCopyChange,
    farmerDropdownList,
    incentiveDropdownList,
  } = useIncentiveSchemeUpdateHooks();

  const { token, permissions } = useSelector((state) => state.layout);
  let permissionsObj = {};
  // eslint-disable-next-line
  permissions?.filter((er) => {
    if (er?.ModuleData?.length > 0) {
      // eslint-disable-next-line
      let filtererdDarta = er?.ModuleData?.filter((ed) => {
        if (ed?.path === "/incentiveschemelist") {
          permissionsObj = ed;
        }
      });
      return filtererdDarta;
    }
  });

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [singleIncentive, setSingleIncentive] = useState("");

  const onDeleteClick = (data) => {
    confirmAlert({
      title: "Delete Confirmation",
      message: `Are you sure to delete this?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            onGridDelete(data);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const column = useMemo(
    () => [
      {
        Header: "Sr No",
        accessor: "SrNo",
        filterable: false,
      },
      {
        Header: "Date",
        accessor: "applicabledateStr",
        filterable: false,
      },
      {
        Header: "Voucher No",
        accessor: "voucherno",
        filterable: false,
      },
      {
        Header: "Farmer Name",
        accessor: "farmername",
        filterable: false,
      },
      {
        Header: "Incentive Scheme",
        accessor: "schemeName",
        filterable: false,
      },
      {
        Header: "Scheme Type",
        accessor: "schemeTypeName",
        filterable: false,
      },
      {
        Header: "Rate",
        accessor: "rate",
        filterable: false,
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,
      },
      {
        id: "Action",
        Header: () => (
          <span
            className="d-flex justify-content-center"
            style={{ textAlign: "center" }}
          >
            Action
          </span>
        ),
        Cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <div className="hstack gap-2">
                {/* View Button  */}
                {/* <button
                  className="border-0"
                  style={{ background: "transparent" }}
                  onClick={() => {
                    setSingleIncentive(cellProps.row.original);
                    setShowModal(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_32_322)">
                      <path
                        d="M3.12549 7.5C3.12549 7.5 4.71611 4.375 7.50049 4.375C10.2842 4.375 11.8755 7.5 11.8755 7.5C11.8755 7.5 10.2842 10.625 7.50049 10.625C4.71611 10.625 3.12549 7.5 3.12549 7.5Z"
                        stroke="#03A8DC"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.1255 10.625V11.875C13.1255 12.2065 12.9938 12.5245 12.7594 12.7589C12.525 12.9933 12.207 13.125 11.8755 13.125H3.12549C2.79397 13.125 2.47603 12.9933 2.2416 12.7589C2.00718 12.5245 1.87549 12.2065 1.87549 11.875V10.625M13.1255 4.375V3.125C13.1255 2.79348 12.9938 2.47554 12.7594 2.24112C12.525 2.0067 12.207 1.875 11.8755 1.875H3.12549C2.79397 1.875 2.47603 2.0067 2.2416 2.24112C2.00718 2.47554 1.87549 2.79348 1.87549 3.125V4.375M7.50049 8.125C7.66625 8.125 7.82522 8.05915 7.94243 7.94194C8.05964 7.82473 8.12549 7.66576 8.12549 7.5C8.12549 7.33424 8.05964 7.17527 7.94243 7.05806C7.82522 6.94085 7.66625 6.875 7.50049 6.875C7.33473 6.875 7.17576 6.94085 7.05855 7.05806C6.94134 7.17527 6.87549 7.33424 6.87549 7.5C6.87549 7.66576 6.94134 7.82473 7.05855 7.94194C7.17576 8.05915 7.33473 8.125 7.50049 8.125Z"
                        stroke="#03A8DC"
                        strokeWidth="1.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_32_322">
                        <rect
                          width="15"
                          height="15"
                          fill="white"
                          transform="translate(0.000488281)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button> */}

                {/* Edit Button */}
                {permissionsObj?.edit && (
                  <button
                    className="border-0"
                    style={{ background: "transparent" }}
                    onClick={() => {
                      onGridEdit(cellProps.row.original);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_32_318)">
                        <path
                          d="M6.87549 2.5H2.50049C2.16897 2.5 1.85103 2.6317 1.6166 2.86612C1.38218 3.10054 1.25049 3.41848 1.25049 3.75V12.5C1.25049 12.8315 1.38218 13.1495 1.6166 13.3839C1.85103 13.6183 2.16897 13.75 2.50049 13.75H11.2505C11.582 13.75 11.9 13.6183 12.1344 13.3839C12.3688 13.1495 12.5005 12.8315 12.5005 12.5V8.125"
                          stroke="#3E7531"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.563 1.56249C11.8116 1.31385 12.1489 1.17416 12.5005 1.17416C12.8521 1.17416 13.1893 1.31385 13.438 1.56249C13.6866 1.81113 13.8263 2.14836 13.8263 2.49999C13.8263 2.85162 13.6866 3.18885 13.438 3.43749L7.50049 9.37499L5.00049 9.99999L5.62549 7.49999L11.563 1.56249Z"
                          stroke="#3E7531"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_32_318">
                          <rect
                            width="15"
                            height="15"
                            fill="white"
                            transform="translate(0.000488281)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                )}

                {/* Delete Button */}
                {permissionsObj?.delete && (
                  <button
                    className="border-0"
                    style={{ background: "transparent" }}
                    onClick={() => {
                      onDeleteClick(cellProps.row.original);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_32_330)">
                        <path
                          d="M6.63507 -0.0178833C5.50545 -0.0178833 4.59795 0.92077 4.59795 2.05558V2.30769H2.30814C1.99026 2.30769 1.73122 2.56673 1.73122 2.88462V3.46154H1.1543V4.61539H2.30814V13.2692C2.30814 14.2183 3.08987 15 4.03891 15H10.962C11.911 15 12.6928 14.2183 12.6928 13.2692V4.61539H13.8466V3.46154H13.2697V2.88462C13.2697 2.56673 13.0106 2.30769 12.6928 2.30769H10.403V2.055C10.403 0.920771 9.49545 -0.0178833 8.36584 -0.0178833H6.63507ZM6.63507 1.17173H8.36584C8.82968 1.17173 9.21334 1.55019 9.21334 2.055V2.30769H5.78699V2.055C5.78699 1.55019 6.17064 1.17231 6.63449 1.17231L6.63507 1.17173ZM3.46199 4.61539H6.41872C6.49026 4.62289 6.56122 4.63327 6.63507 4.63327H8.36584C8.43968 4.63327 8.51007 4.62289 8.58218 4.61539H11.5389V13.2692C11.5389 13.594 11.2868 13.8462 10.962 13.8462H4.03891C3.7141 13.8462 3.46199 13.594 3.46199 13.2692V4.61539ZM4.61584 5.76923V12.6923H5.76968V5.76923H4.61584ZM6.92353 5.76923V12.6923H8.07737V5.76923H6.92353ZM9.23122 5.76923V12.6923H10.3851V5.76923H9.23122Z"
                          fill="#EC0505"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_32_330">
                          <rect
                            width="15"
                            height="15"
                            fill="white"
                            transform="translate(0.000488281)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                )}
              </div>
            </div>
          );
        },
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <PageLayout title={"Incentive Scheme Update"} loading={loading}>
      <Form onSubmit={handleSubmit(onSubmit)} className="m-4 g-2">
        <Row className="g-2">
          <Col lg={3} md={4} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                format={keys().shortDateFormat}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    variant: "standard",
                  },
                }}
                onChange={onDateChange}
                value={formData?.applicabledate}
                clearable
                minDate={dayjs(new Date().setDate(new Date().getDate() - 1))}
              />
            </LocalizationProvider>
          </Col>

          <Col lg={3} md={4} sm={12}>
            <FormGroup>
              <TextField
                {...register("voucherno")}
                error={errors?.voucherno}
                id="voucherno"
                name="voucherno"
                label="Voucher No"
                onChange={handleChange}
                {...textFieldProps}
                InputLabelProps={{ shrink: true }}
              />
              {errors?.voucherno && (
                <ErrorMessage message={errors?.voucherno?.message} />
              )}
            </FormGroup>
          </Col>

          <Col lg={6} md={6} sm={12}>
            <Autocomplete
              id="farmerid"
              name="farmerid"
              size="small"
              options={farmerDropdownList}
              value={formData.selectedfarmer}
              onChange={onfarmerdropdownchange}
              renderInput={(params) => (
                <TextField {...params} {...textFieldProps} label="Farmer" />
              )}
            />
          </Col>

          {formData?.selectedfarmer && (
            <Col lg={12} md={12} sm={12} className="mb-2">
              <FarmerDetails
                elevation={2}
                className="d-flex align-items-center"
                severity="info"
              >
                <Typography variant="h6" gutterBottom>
                  {formData?.selectedfarmer?.name}
                </Typography>
                <Typography variant="body2" gutterBottom className="mx-2">
                  {` [Address - ${formData?.selectedfarmer?.fulladdress}]`}
                </Typography>
              </FarmerDetails>
            </Col>
          )}

          <Col lg={3} md={4} sm={12}>
            <Autocomplete
              id="incentiveconfigid"
              name="incentiveconfigid"
              size="small"
              options={incentiveDropdownList}
              value={formData.selectedincentive}
              onChange={onincentivedropdownchange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...textFieldProps}
                  label="Incentive Scheme"
                />
              )}
            />
          </Col>

          {/* <Col lg={3} md={4} sm={12}>
            <FormGroup>
              <TextField
                {...register("rate")}
                error={errors?.rate}
                id="rate"
                name="rate"
                label="Rate"
                type="number"
                value={formData.rate}
                onChange={handleChange}
                {...textFieldProps}
                InputLabelProps={{ shrink: true }}
              />
              {errors?.rate && <ErrorMessage message={errors?.rate?.message} />}
            </FormGroup>
          </Col> */}

          {formData?.isfarmerphoto === 1 && (
            <Col lg={12} md={12} sm={12}>
              <Row>
                <Col lg={2} md={4} sm={12}>
                  Farmer Photo
                </Col>
                <Col lg={3} md={4} sm={12}>
                  <FileBase64 multiple={false} onDone={onFarmerPhotoChange} />
                </Col>
                {formData?.id > 0 && formData.farmerphoto !== null && (
                  <Col lg={2} md={4} sm={12}>
                    <Attachment fontSize="medium" color="success" />
                    <Link
                      target="_blank"
                      href={keys().ServiceIP + formData.farmerphoto}
                    >
                      {"Farmer Photo"}
                    </Link>
                  </Col>
                )}
              </Row>
            </Col>
          )}

          {formData?.isusedkitphoto === 1 && (
            <Col lg={12} md={12} sm={12}>
              <Row>
                <Col lg={2} md={4} sm={12}>
                  Used Kit Photo
                </Col>
                <Col lg={3} md={4} sm={12}>
                  <FileBase64 multiple={false} onDone={onUsedKitPhotoChange} />
                </Col>
                {formData?.id > 0 && formData.usedkitphoto !== null && (
                  <Col lg={2} md={4} sm={12}>
                    <Attachment fontSize="medium" color="success" />
                    <Link
                      target="_blank"
                      href={keys().ServiceIP + formData.usedkitphoto}
                    >
                      {"Used Kit Photo"}
                    </Link>
                  </Col>
                )}
              </Row>
            </Col>
          )}

          {formData?.isinvoicecopy === 1 && (
            <Col lg={12} md={12} sm={12}>
              <Row>
                <Col lg={2} md={4} sm={12}>
                  Invoice Copy
                </Col>
                <Col lg={3} md={4} sm={12}>
                  <FileBase64 multiple={false} onDone={onInvoiceCopyChange} />
                </Col>
                {formData?.id > 0 && formData.invoicecopy !== null && (
                  <Col lg={1} md={4} sm={12}>
                    <Attachment fontSize="medium" color="success" />
                    <Link
                      target="_blank"
                      href={keys().ServiceIP + formData.invoicecopy}
                    >
                      {"Invoice Copy"}
                    </Link>
                  </Col>
                )}
              </Row>
            </Col>
          )}
        </Row>
        <Row className="mt-2">
          <Col lg={2} md={4} sm={12}>
            <Button
              className="custom-btn-page-layout custom-btn-success"
              fullWidth
              type="submit"
              size="small"
              startIcon={<Save />}
            >
              {` Save`}
            </Button>
          </Col>
          <Col lg={2} md={4} sm={12}>
            <Button
              className="custom-btn-page-layout custom-btn-danger "
              type="button"
              onClick={resetFormData}
              startIcon={<Cancel />}
              fullWidth
              size="small"
            >
              {` Cancel`}
            </Button>
          </Col>
        </Row>
      </Form>

      <Col md={12}>
        {loading ? (
          ""
        ) : (
          <div
            className="d-grid"
            style={{ margin: "0 20px 20px 20px", border: "1px solid #DEE2E6" }}
          >
            <TableContainer
              columns={column}
              data={rowData}
              isGlobalFilter={true}
              showColumnFilters={true}
              isAddUserList={false}
              tableClass={"table-sm"}
              divClass={"table-responsive"}
              showPagination={true}
              initialState={{ pageSize: 10, pageIndex: 0 }}
            />
          </div>
        )}
      </Col>
    </PageLayout>
  );
};

export default IncentiveSchemeUpdate;
