export const FARMER_DROPDOWN_LIST = "FARMER_DROPDOWN_LIST";
export const FARMER_LAND_LIST = "FARMER_LAND_LIST";
export const FARMER_LIST = "FARMER_LIST";
export const FARMER_DOCUMENT_LIST = "FARMER_DOCUMENT_LIST";

export const FARMER_PURCHASE_LIST = "FARMER_PURCHASE_LIST";
export const FARMER_ALLOCATION_LIST = "FARMER_ALLOCATION_LIST";

export const PREVIOUS_PURCHASES_FROM_FARMER = "PREVIOUS_PURCHASES_FROM_FARMER";

export const FARMER_INVOICE_LIST = "FARMER_INVOICE_LIST";

export const PURCHASES_FROM_FARMER = "PURCHASES_FROM_FARMER";
export const FARMER_TRANSPORTATION = "FARMER_TRANSPORTATION";
export const FARMER_UNACCOUNTBAGS = "FARMER_UNACCOUNTBAGS";
export const FARMER_INCENTIVES = "FARMER_INCENTIVES";
