import { useEffect, useState } from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import TextField from "@mui/material/TextField";
import Flatpickr from "react-flatpickr";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Paper,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PageLayout from "../../Component/PageLayout";
import { useGRNHooks } from "./useGRNHooks";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "../../common/Error";
import { keys } from "../../config/keys";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";

import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { textFieldProps } from "../../common/commonControlProps";
import { Cancel, Save } from "@mui/icons-material";

const VendorDetails = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  padding: "5px 10px",
  lineHeight: "60px",
}));
const darkTheme = createTheme({ palette: { mode: "dark" } });

const GRN = () => {
  const [loading, setLoading] = useState(false);
  const [podetails, setpodetails] = useState([]);

  const l = useLocation();

  

  const {
    register,
    formData,
    handleChange,
    errors,
    handleSubmit,
    onSubmit,
    onDateChange,
    control,
    vendors,
    ondropdownchange,
    purchase_order_list,
    onpochange,
    handleReceivedQtyChange,
    grnDetailDataBYpoId,
    getTotalPRoductIdWise,
    checkDiavaibility,
  } = useGRNHooks(l.state?.id);

  useEffect(() => {
    // console.log(formData);
  }, formData);

  const navigate = useNavigate();
  return (
    <PageLayout
      title={"Goods Received Note (GRN)"}
      loading={loading}
      backBtnTitle={`Back to GRN List`}
      onClick={() => navigate("/grn")}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="m-4 g-2">
          {/* <Col lg={3} md={4} sm={12}>
            <FormGroup>
              <TextField
                {...register("voucher")}
                error={errors?.voucher}
                id="voucher"
                name="voucher"
                label="Voucher Name"
                onChange={handleChange}
                value={formData.voucherno}
                {...textFieldProps}
              />
              {errors?.voucher && (
                <ErrorMessage message={errors?.voucher?.message} />
              )}
            </FormGroup>
          </Col> */}
          <Col lg={3} md={4} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="GRN Date"
                format={keys().shortDateFormat}
                slotProps={{ textField: { ...textFieldProps } }}
                onChange={onDateChange}
                value={formData?.invoicedate}
                clearable
              />
            </LocalizationProvider>
          </Col>

          <Col lg={3} md={4} sm={12}>
            <FormGroup>
              <TextField
                {...register("invoicenumber")}
                id="invoicenumber"
                name="invoicenumber"
                onChange={handleChange}
                value={formData.invoicenumber}
                error={errors?.invoicenumber}
                label={`GRN Number`}
                title=""
                {...textFieldProps}
              />
              <FormHelperText>{`Last GRN # - ${formData.lastinvoicenumber}`}</FormHelperText>
              {errors?.invoicenumber && (
                <ErrorMessage message={errors?.invoicenumber?.message} />
              )}
            </FormGroup>
          </Col>
          <Col lg={3} md={4} sm={12}>
            <Autocomplete
              id="vendorid"
              name="vendorid"
              size="small"
              options={vendors}
              value={formData.selectedvendor}
              onChange={ondropdownchange}
              renderInput={(params) => (
                <TextField {...params} {...textFieldProps} label="Vendor" />
              )}
            />
          </Col>
          {/* {formData?.selectedvendor && (
            <Col lg={12} md={12} sm={12} className="mb-2">
              <VendorDetails
                elevation={2}
                className="d-flex align-items-center"
                severity="info"
                color="info"
              >
                <Typography variant="h6" gutterBottom>
                  {formData?.selectedvendor?.vendorname}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {`  [Address - ${formData?.selectedvendor?.fulladdress}]`}
                </Typography>
              </VendorDetails>
            </Col>
          )} */}
          {formData?.selectedvendor && (
            <Col lg={3} md={4} sm={12}>
              <Autocomplete
                id="purchaseordernumber"
                name="purchaseordernumber"
                size="small"
                options={
                  formData.vendorid > 0
                    ? purchase_order_list.filter(
                        (x) => x.vendorid_id === formData.vendorid
                      )
                    : purchase_order_list
                }
                value={formData?.selectedPO}
                onChange={onpochange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...textFieldProps}
                    label="Purchase order"
                  />
                )}
              />
            </Col>
          )}
          {formData?.podetails.length > 0 && (
            <table className="table-sm table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{`Product`}</th>
                  <th scope="col">{`Part #`}</th>
                  <th scope="col">{`Ordered Qty.`}</th>
                  <th scope="col">{`Recd. Qty.`}</th>
                  <th scope="col">{`Pending. Qty.`}</th>
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {formData.podetails.map((x, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{x.productName}</td>
                    <td>{x.partNo}</td>
                    <td>{x.qty}</td>
                    <td>
                      <TextField
                        fullWidth
                        name="receivedqty"
                        placeholder="Enter received qty."
                        inputProps={{ inputMode: "numeric" }}
                        //label="ReceivedQty"
                        onChange={(e) =>
                          handleReceivedQtyChange(e, x, idx, x.productid)
                        }
                        value={x.receivedQty}
                        variant="standard"
                        size="small"
                        type="number"
                        error={x.error || false}
                        helperText={x.error && x.errorMessage}
                        disabled={x.pendingQty <= 0 ? true : false}
                        // disabled={checkDiavaibility(
                        //   grnDetailDataBYpoId,
                        //   x.productid,
                        //   x.qty
                        // )}
                      />
                    </td>
                    <td>
                      {x.pendingQty}
                      {/* {getTotalPRoductIdWise(
                        grnDetailDataBYpoId,
                        x.product.value.productDetails.id,
                        x.qty
                      ) || 0} */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Row>

        <Row className="m-4 g-2">
          <Col lg={1} md={1} sm={12}>
            <Button
              className="custom-btn-page-layout text-white "
              startIcon={<Save />}
              fullWidth
              type="submit"
            >
              {` Save`}
            </Button>
          </Col>
          <Col lg={1} md={1} sm={12}>
            <Button
              type="button"
              className="custom-btn-page-layout custom-btn-danger "
              onClick={(e) => navigate("/grn")}
              startIcon={<Cancel />}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </PageLayout>
  );
};

export default GRN;
