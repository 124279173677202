import React from "react";
import { Col, Row, Button } from "reactstrap";

const CustomPagination = ({
  apiCallFunction,
  rowDetails,
  totalCount,
  limit,
  offset,
  setLimit,
  setOffset,
}) => {
  const currentPage = Math.ceil((offset + 1) / limit);
  const totalPages = Math.ceil(totalCount / limit);

  function handleNext() {
    // Increment the offset by the limit to get the next set of records
    setOffset(offset + limit);
    apiCallFunction(limit, offset + limit);
  }

  function handlePrevious() {
    // Decrement the offset by the limit to get the previous set of records
    setOffset(Math.max(offset - limit, 0)); // ensure offset is non-negative
    apiCallFunction(limit, Math.max(offset - limit, 0));
  }


  return (
    <>
      <Row className="pagination-row pb-2" >
        <Col >
          <Row className="justify-content-md-end justify-content-center align-items-center pe-2">
            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  color="success"
                  className="pagination-btn text-white btn-sm"
                  onClick={handlePrevious}
                  disabled={offset === 0}
                >
                  {"<"}
                </Button>
              </div>
            </Col>
            <Col className="col-md-auto  d-md-block">
              Page{" "}
              <strong>
                {currentPage || 0} of {totalPages || 0}
              </strong>
            </Col>

            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  color="success"
                  className="pagination-btn text-white btn-sm"
                  onClick={handleNext}
                  disabled={rowDetails?.length + offset === totalCount}
                >
                  {">"}
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CustomPagination;
