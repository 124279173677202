import storage from "redux-persist/lib/storage";
import { combineReducers } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
import { encryptTransform } from "redux-persist-transform-encrypt";
import layoutReducer from "./reducers/layoutReducer";
import { allReducers } from "../redux/reducers";

const persistEncryptTransformer = encryptTransform({
  secretKey: "secret-ket-for-esgdjango",
  onError: function (error) {
    // Handle the error.
  },
});

const rootReducer = combineReducers({
  ...allReducers,
  layout: persistReducer(
    {
      transforms: [persistEncryptTransformer],
      key: "layout",
      storage,
    },
    layoutReducer
  ),
});

export default rootReducer;
