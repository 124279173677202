import * as yup from "yup";

export const appointmentValidationSchema = yup.object({
  // voucher: yup.string().trim().required("Voucher Number is required"),
  //invoicenumber: yup.string().required("Invoice number is required"),
  //   podetails: yup.array().of(
  //     yup.object().shape({
  //       orderedqty: yup.number().notRequired(),
  //       receivedqty: yup.number().required("Qty Required"),
  //     })
  //   ),
});
