import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { yieldEstimationConfigValidationSchema } from "./validationSchema";
import { commonActions, yieldEstimationActions } from "../../redux/actions";

const initialValues = {
  id: 0,
  companyunitid: 0,
  selectedcompanyunit: null,
  productid: 0,
  selectedproduct: null,
  limitperbigha: 0,
};

export const useYieldEstimationConfigHooks = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialValues);
  const [rowData, setRowData] = useState([]);

  const { productlist, companyunitlist, yieldestimationconfigList } =
    useSelector((obj) => obj);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(yieldEstimationConfigValidationSchema),
  });

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const oncompanyunitdropdownchange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      companyunitid: selectedOption?.id || 0,
      selectedcompanyunit: selectedOption || null,
    }));
  };

  const onproductdropdownchange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      productid: selectedOption?.id || 0,
      selectedproduct: selectedOption || null,
    }));
  };

  const resetFormData = () => {
    setFormData((prev) => ({
      ...prev,
      id: 0,
      companyunitid: 0,
      selectedcompanyunit: null,
      productid: 0,
      selectedproduct: null,
    }));
    reset(initialValues);
  };

  const onSubmit = () => {
    let flagErr = 0;
    if (!(formData?.companyunitid > 0)) {
      flagErr = 1;
      toast.error("Please select company unit.");
    } else if (!(formData?.productid > 0)) {
      flagErr = 1;
      toast.error("Please select product.");
    } else if (!(formData?.limitperbigha > 0)) {
      flagErr = 1;
      toast.error("Please enter Limit Per Bigha greator than zero.");
    }

    if (flagErr === 0) {
      let isDuplicate = false;
      if (formData.id && formData.id > 0) {
        const tmpUpdateData = rowData.filter(
          (itm) =>
            itm.companyunitid === formData.companyunitid &&
            itm.id !== formData.id &&
            itm.productid === formData.productid
        );
        if (tmpUpdateData.length > 0) {
          isDuplicate = true;
        }
      } else {
        const tmpSaveData = rowData.filter(
          (itm) =>
            itm.companyunitid === formData.companyunitid &&
            itm.productid === formData.productid
        );
        if (tmpSaveData.length > 0) {
          isDuplicate = true;
        }
      }

      if (isDuplicate) {
        toast.error("Data selected company unit and product already exist.");
      } else {
        dispatch(
          yieldEstimationActions.saveYieldEstimationConfig(formData, {
            onSuccess: () => {
              setFormData(initialValues);
              resetFormData();
              get_yieldestimationconfigList();
              toast.success(
                formData.id && formData.id > 0
                  ? "Data Updated Successfully."
                  : "Data Saved Successfully."
              );
            },
            onError: (err) => {
              console.log(err);
              toast.error("Something went wrong. Please try again.");
            },
          })
        );
      }
    }
  };

  const onGridEdit = (data) => {
    // console.log("data:", data);
    const tmpselectedcompanyunit = companyunitlist.filter(
      (itm) => itm.id === data.companyunitid
    );
    const tmpselectedproduct = productlist.filter(
      (itm) => itm.id === data.productid
    );
    const tmpData = {
      ...data,
      selectedcompanyunit:
        tmpselectedcompanyunit.length > 0 ? tmpselectedcompanyunit[0] : null,
      selectedproduct:
        tmpselectedproduct.length > 0 ? tmpselectedproduct[0] : null,
    };

    setFormData(tmpData);
    reset(tmpData);
  };

  const onGridDelete = (data) => {
    // console.log("data:", data);
    dispatch(
      yieldEstimationActions.deleteYieldEstimationConfig(
        { id: data.id },
        {
          onSuccess: () => {
            get_yieldestimationconfigList();
            toast.success("Data Deleted Successfully.");
          },
          onError: (err) =>
            toast.error("Something went wrong. Please try again."),
        }
      )
    );
  };

  const get_productList = () => dispatch(commonActions.get_product_list());
  const get_companyunitList = () =>
    dispatch(commonActions.get_company_unit_list());
  const get_yieldestimationconfigList = () =>
    dispatch(yieldEstimationActions.getYieldEstimationConfigList());

  useEffect(() => {
    get_productList();
    get_companyunitList();
    get_yieldestimationconfigList();
  }, []);

  useEffect(() => {
    setRowData(yieldestimationconfigList);
  }, [yieldestimationconfigList]);

  return {
    rowData,
    formData,
    setFormData,
    handleChange,
    initialValues,
    register,
    handleSubmit,
    errors,
    resetFormData,
    control,
    onSubmit,
    oncompanyunitdropdownchange,
    onproductdropdownchange,
    onGridEdit,
    onGridDelete,
    productlist,
    companyunitlist,
  };
};
