/* eslint-disable no-restricted-globals */
import axios from "../../common/axios";
import { toast } from "react-toastify";
import { keys } from "../../config/keys";
import {
  YIELDESTIMATION_LIST,
  YIELDESTIMATION_CONFIG_LIST,
} from "../constTypes/yieldEstimation";

const _yieldEstimationConfigList = (data) => {
  return {
    type: YIELDESTIMATION_CONFIG_LIST,
    payload: data,
  };
};
export const getYieldEstimationConfigList = (callback) => async (dispatch) => {
  await axios
    .get(
      `${keys().ServicePath}/api/yieldestimation/getYieldEstimationConfigList`
    )
    .then((res) => {
      dispatch(_yieldEstimationConfigList(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const saveYieldEstimationConfig = (data, callback) => async () => {
  await axios({
    method: "post",
    url: `${keys().ServicePath}/api/yieldestimation/saveYieldEstimationConfig`,
    data: data,
    headers: { "Content-Type": "application/json" },
  })
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const deleteYieldEstimationConfig = (data, callback) => async () => {
  await axios({
    method: "post",
    url: `${
      keys().ServicePath
    }/api/yieldestimation/deleteYieldEstimationConfig`,
    data,
  })
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const saveYieldEstimation =
  (data, onSuccess, onError) => async (dispatch) => {
    await axios({
      method: "post",
      url: `${keys().ServicePath}/api/yieldestimation/save`,
      data: data,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (onSuccess) onSuccess(res);
      })
      .catch((e) => {
        toast.error(e.toString());
        if (onError) onError(e);
      });
  };

const _yieldEstimationList = (data) => {
  return {
    type: YIELDESTIMATION_LIST,
    payload: data,
  };
};

export const getYieldEstimationList =
  (id = 0, callback) =>
  async (dispatch) => {
    const url =
      id > 0
        ? `${keys().ServicePath}/api/yieldestimation/${id}`
        : `${keys().ServicePath}/api/yieldestimation`;
    await axios
      .get(url)
      .then((res) => {
        dispatch(_yieldEstimationList(res.data.rows[1]));
        if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

export const updateYieldEstimation =
  (data, onSuccess, onError) => async (dispatch) => {
    await axios({
      method: "patch",
      url: `${keys().ServicePath}/api/yieldestimation`,
      data: data,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (onSuccess) onSuccess(res);
      })
      .catch((e) => {
        toast.error(e.toString());
        if (onError) onError(e);
      });
  };

export const deleteYieldEstimation =
  (id, onSuccess, onError) => async (dispatch) => {
    await axios
      .delete(`/api/yieldestimation/${id}`)
      .then((res) => {
        if (onSuccess) onSuccess(res);
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };

export const get_latest_voucher_no =
  ({ onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .get(`${keys().ServicePath}/api/yieldestimation/latest_voucher_number`)
      .then((res) => {
        if (onSuccess) onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (onError) onError(e);
      });
  };
