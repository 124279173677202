import React, { useCallback, useEffect, useMemo, useState } from "react";
// import useBreakPoints from "../../hooks/useBreakPoints";
import PageLayout from "../../../Component/PageLayout";

// import CustomAutoComplete from "../../../Component/CustomAutoComplete";
// import { Col, Input, InputGroup, InputGroupText, Row, Label } from "reactstrap";
import { Col } from "reactstrap";
import { logOutRequest } from "../../../store/reducers/layoutReducer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { ClipLoader } from "react-spinners";
import TableContainer from "../../../Component/TableContainer";
// import CustomPagination from "../../../Component/CustomPagination";
import DeleteModal from "../../../Component/DeleteModal";
// import Select from "react-select";
// import { TextField, IconButton } from "@mui/material";
import { IconButton } from "@mui/material";
import { Delete, Edit, Visibility } from "@mui/icons-material";
// import Flatpickr from "react-flatpickr";
// import AddPurchaseOrder from "./AddPurchaseOrder";
import useBreakPoints from "../../../hooks/useBreakPoints";
import { purchaseActions } from "../../../redux/actions";
import { GridAction } from "../../../common/useGridAction";

// const { isMobile } = useBreakPoints();
const PurchaseOrderList = () => {
  const { permissions, token, loginDetails } = useSelector(
    (state) => state.layout
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let permissionsObj = {};
  // eslint-disable-next-line
  permissions?.filter((er) => {
    if (er?.ModuleData?.length > 0) {
      // eslint-disable-next-line
      let filtererdDarta = er?.ModuleData?.filter((ed) => {
        if (ed?.subModuleData?.length > 0) {
          // eslint-disable-next-line
          ed?.subModuleData?.map((qw) => {
            if (qw?.path === "/purchaseorderlist") {
              permissionsObj = qw;
            }
          });
        }
      });
      return filtererdDarta;
    }
  });

  let initialState = {};

  if (!permissionsObj?.edit && !permissionsObj?.delete) {
    initialState.hiddenColumns = ["Action"];
  }
  const { isMobile, isTablet } = useBreakPoints();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(5); // default limit
  const [offset, setOffset] = useState(0); // default offset
  const [counter, setCounter] = useState(0); // default offset
  const [deleteModal, setDeleteModal] = useState(false);
  const [purchaseOrderList, setPurchaseOrderList] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [singleData, setSingleData] = useState();
  let todayDate = new Date();

  const getPurchaseOrderListAPi = useCallback(async (limit, offset) => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/purchaseorderlist?limit=${limit}&offset=${offset}&companyId=${loginDetails?.companyId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        setPurchaseOrderList(_data?.purchaseOrderList);
        setTotalCount(_data?.paginationDetails?.count);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  const getPurchaseOrderByIdAPi = useCallback(
    async (purchaseorderid, redirectLink) => {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("purchaseorderId", purchaseorderid);
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/purchaseorderbyid`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          setLoading(false);
          navigate(redirectLink, { state: _data?.purchaseOrderList[0] });
        } else {
          setLoading(false);
          toast.error(_data?.error);
        }
      } catch (error) {
        console.log(error);
      }
      // eslint-disable-next-line
    },
    []
  );

  /*
  useEffect(() => {
    getPurchaseOrderListAPi(limit, offset);
    // eslint-disable-next-line
  }, [getPurchaseOrderListAPi]);
  */
  const { purchaseorderList } = useSelector((obj) => obj);
  const getPurchaseOrderList = () =>
    dispatch(purchaseActions.getPurchaseOrderList());

  useEffect(() => {
    getPurchaseOrderList();
  }, []);

  useEffect(() => {
    setPurchaseOrderList(purchaseorderList);
  }, [purchaseorderList]);

  //   handleDelete Btn
  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    let formData = new FormData();
    formData.append("id", deleteId);
    formData.append("isDelete", "Yes");

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/deletepurchaseorder`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        // getPurchaseOrderListAPi(limit, offset);
        getPurchaseOrderList();
        setDeleteModal(false);
        toast?.success(_data?.message);
        setDeleteLoading(false);
      } else {
        setDeleteLoading(false);
        setDeleteModal(false);

        toast.error(_data?.message);
      }
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
    }
  };

  const column = useMemo(
    () => [
      {
        Header: "PO No",
        accessor: "id",
      },
      {
        Header: "Date",
        // accessor: "orderDate",
        accessor: "orderDateStr",
      },
      {
        Header: "Vendor Name",
        // accessor: "vendorDetails.vendorName",
        accessor: "vendorName",
      },
      {
        Header: "GRN Status",
        Cell: (cellProps) => (
          <span>{cellProps.row.original?.grnStatus ? "True" : "False"}</span>
        ),
      },
      {
        Header: "Purchase Status",
        Cell: (cellProps) => (
          <span>
            {cellProps.row.original?.purchaseStatus ? "True" : "False"}
          </span>
        ),
      },
      {
        Header: "Amount",
        accessor: "totalAmount",
      },
      {
        ...GridAction({
          permission: permissionsObj,
          onEditClick: (cellProps) => {
            getPurchaseOrderByIdAPi(
              cellProps.row.original.id,
              "/editpurchaseorder"
            );
          },
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original.id);
          },
          onViewClick: (cellProps) =>
            getPurchaseOrderByIdAPi(
              cellProps.row.original.id,
              "/viewpurchaseorder"
            ),
        }),
      },

      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  //   const onSubmitModal = () => {
  //     getPurchaseOrderListAPi(limit, offset);
  //     setEditModal(false);
  //   };

  return (
    <PageLayout
      title={"PURCHASE ORDER CREATION"}
      loading={loading}
      addBtn={permissionsObj?.create ? true : false}
      backBtnTitle={`Create Purchase Order`}
      onClick={() => navigate("/addpurchaseorder")}
    >
      <div className="mx-1 my-3">
        <Col md={12}>
          {loading ? (
            ""
          ) : (
            <div
              className="d-grid"
              style={{ margin: "0 20px", border: "1px solid #DEE2E6" }}
            >
              {/* <TableContainer
                  columns={column}
                  data={purchaseOrderList || []}
                  isGlobalFilter={false}
                  isAddUserList={false}
                  tableClass={"table-sm"}
                  divClass={"table-responsive"}
                  initialState={initialState}
                />
                <CustomPagination
                  apiCallFunction={getPurchaseOrderListAPi}
                  rowDetails={purchaseOrderList}
                  totalCount={totalCount}
                  limit={limit}
                  offset={offset}
                  setLimit={setLimit}
                  setOffset={setOffset}
                /> */}
              <TableContainer
                columns={column}
                data={purchaseOrderList || []}
                isGlobalFilter={true}
                showColumnFilters={true}
                isAddUserList={false}
                tableClass={"table-sm"}
                divClass={"table-responsive"}
                showPagination={true}
                initialState={{ pageSize: 10, pageIndex: 0 }}
              />
            </div>
          )}
        </Col>

        {/* {editModal && (
          <EditPriceList
            editModal={editModal}
            onCloseModal={() => setEditModal(false)}
            onSubmitModal={onSubmitModal}
            singleData={singleData}
          />
        )} */}

        <DeleteModal
          deleteModal={deleteModal}
          handleDeleteRequest={handleDeleteRequest}
          setDeleteModal={setDeleteModal}
          deleteLoading={deleteLoading}
        />
      </div>
    </PageLayout>
  );
};

export default PurchaseOrderList;
