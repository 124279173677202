import React, { useState } from "react";
import { Label, Input, Row, Col, FormGroup } from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../Component/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import { underArray } from "../../hooks/StaticData";
import Select from "react-select";
import useBreakPoints from "../../hooks/useBreakPoints";
const EditGroup = ({ editModal, onCloseModal, onSubmitModal, singleData }) => {
  const { token } = useSelector((state) => state.layout);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useBreakPoints();
  const [loading, setLoading] = useState(false);

  const [inputData, setInputData] = useState({
    groupName: singleData?.groupName,
    under: underArray?.find((er) => er?.value === singleData?.under),
    Qty: singleData?.qty === "True" ? true : false,
    gst: singleData?.gst === "True" ? true : false,
  });

  const [inputDataError, setInputDataError] = useState({
    groupName: false,
    under: false,
  });

  const handleChangeInput = (name, value) => {
    if (name === "under") {
      setInputData({ ...inputData, [name]: value });

      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, under: false });
      } else {
        setInputDataError({ ...inputDataError, under: true });
      }
    } else if (name === "groupName") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, groupName: false });
      } else {
        setInputDataError({ ...inputDataError, groupName: true });
      }
    } else {
      setInputData({ ...inputData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputData.groupName) {
      toast.error("Group Name is Required!");
      setInputDataError({ ...inputDataError, groupName: true });
    } else {
      setLoading(true);
      let formData = new FormData();

      formData.append("groupName", inputData.groupName);
      formData.append("under", inputData.under.value);
      formData.append("qty", inputData.Qty ? "True" : "False");
      formData.append("gst", inputData.gst ? "True" : "False");
      formData.append("id", singleData.id);

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/editgroup`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setLoading(false);
          setInputData({
            groupName: "",
            under: { label: "Primary", value: "primary" },
            Qty: "",
            gst: "",
          });
          onSubmitModal();
        } else {
          setLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  return (
    <CustomModal
      loading={loading}
      title={"Edit Group"}
      handleSubmit={handleSubmit}
      modalState={editModal}
      onCloseModal={onCloseModal}
      editState={true}
    >
      <FormGroup row>
        <Row className="g-3">
          <Col md={10} className={`d-flex  ${isMobile ? "flex-column" : ""}`}>
            <Col md={4}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Group Name <span className="required_span"> * </span>
              </Label>
            </Col>
            <Col sm={8} className={`${isMobile ? "" : "pl-5"}`}>
              <Input
                id="exampleEmail"
                name="groupName"
                value={inputData?.groupName}
                onChange={(e) => {
                  handleChangeInput(
                    e.target.name,
                    e.target.value.toUpperCase()
                  );
                }}
                className={`cust-input ${
                  inputDataError?.groupName ? "border-danger" : ""
                }`}
                type="text"
              />
            </Col>
          </Col>
        </Row>
        <Row className="g-3">
          <Col
            md={10}
            className={`d-flex mx-0  ${isMobile ? "flex-column" : ""}`}
          >
            <Col md={4}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Under <span className="required_span"> * </span>
              </Label>
            </Col>
            <Col sm={8}>
              <Select
                isMulti={false}
                onChange={(e) => {
                  handleChangeInput("under", e);
                }}
                value={inputData?.under}
                name="under"
                options={underArray}
                // menuPlacement="top"

                className="w-100"
              ></Select>
            </Col>
          </Col>
        </Row>
        <Row className="g-3">
          <Col md={12} className="d-flex gap-2 align-items-center mx-0">
            <Col md={6} sm={6}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Should Qty. of items be added
              </Label>
            </Col>
            <Col md={2} sm={1}>
              <FormGroup switch>
                <Input
                  type="switch"
                  style={{ height: 24, width: 44 }}
                  checked={inputData?.Qty}
                  name="Qty"
                  onChange={(e) => {
                    handleChangeInput(e.target.name, e.target.checked);
                  }}
                />
              </FormGroup>
            </Col>
          </Col>
        </Row>
        <Row className="g-3">
          <Col md={12} className="d-flex align-items-center gap-2 mx-0">
            <Col md={6} sm={6}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Set/Alter GST details
              </Label>
            </Col>
            <Col md={2} sm={1}>
              <FormGroup switch>
                <Input
                  type="switch"
                  style={{ height: 24, width: 44 }}
                  checked={inputData?.gst}
                  name="gst"
                  onChange={(e) => {
                    handleChangeInput(e.target.name, e.target.checked);
                  }}
                />
              </FormGroup>
            </Col>
          </Col>
        </Row>
      </FormGroup>
    </CustomModal>
  );
};

export default EditGroup;
