import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageLayout from "../../../Component/PageLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import Flatpickr from "react-flatpickr";
import { Col, Input, InputGroup, InputGroupText, Row } from "reactstrap";
import { logOutRequest } from "../../../store/reducers/layoutReducer";
import { toast } from "react-toastify";
import CustomAutoComplete from "../../../Component/CustomAutoComplete";
import { ClipLoader } from "react-spinners";
import TableContainer from "../../../Component/TableContainer";
import CustomPagination from "../../../Component/CustomPagination";
import DeleteModal from "../../../Component/DeleteModal";
import EditTransportMaster from "./EditTransportMaster";
import { commonActions } from "../../../redux/actions";

const TransportMaster = () => {
  const { permissions, token, loginDetails } = useSelector(
    (state) => state.layout
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let permissionsObj = {};
  // eslint-disable-next-line
  permissions?.filter((er) => {
    if (er?.ModuleData?.length > 0) {
      // eslint-disable-next-line
      let filtererdDarta = er?.ModuleData?.filter((ed) => {
        if (ed?.subModuleData?.length > 0) {
          // eslint-disable-next-line
          ed?.subModuleData?.map((qw) => {
            if (qw?.path === "/farmerallocationfarmerwise") {
              permissionsObj = qw;
            }
          });
        }
      });
      return filtererdDarta;
    }
  });

  let initialState = {};

  if (!permissionsObj?.edit && !permissionsObj?.delete) {
    initialState.hiddenColumns = ["Action"];
  }

  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [unitOptions, setUnitOptions] = useState([]);
  const [transportList, setTransportList] = useState([]);
  const [villageMappingList, setVillageMappingList] = useState([]);
  const [villageOptions, setVillageOptions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(5); // default limit
  const [offset, setOffset] = useState(0); // default offset
  const [counter, setCounter] = useState(0); // default offset
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [editModal, setEditModal] = useState(false);
  const [singleData, setSingleData] = useState();
  let todayDate = new Date();

  const [inputData, setInputData] = useState({
    unitId: "",
    villageId: "",
    kilometer: "",
    rate: "",
    effectiveDate:
      todayDate.getFullYear() +
      "-" +
      (todayDate.getMonth() + 1) +
      "-" +
      todayDate.getDate(),
  });

  const [inputDataError, setInputDataError] = useState({
    unitId: false,
    villageId: false,
    kilometer: false,
    rate: false,
    effectiveDate: false,
  });

  const getUnitOptionsListAPi = useCallback(async () => {
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("companyId", loginDetails?.companyId);

      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}company/unitsbycompany`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];
        // eslint-disable-next-line
        _data?.unitsData[0]?.unitdetails?.map((er) => {
          arr.push({ label: er?.unitName, value: er.id });
        });
        setUnitOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, []);

  const getVillageOptionsListAPi = useCallback(async () => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}admin1/village`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];

        // eslint-disable-next-line
        _data?.villageList?.map((er) => {
          arr.push({ label: er?.villageName, value: er.id });
        });
        setVillageOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  const getTransportListAPi = useCallback(async (limit, offset) => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}company/listtransportation?limit=${limit}&offset=${offset}&companyId=${loginDetails?.companyId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        setTransportList(_data?.transportList);
        setTotalCount(_data?.paginationDetails?.count);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // getVillageOptionsListAPi();
    dispatch(
      commonActions.get_village_list({
        onSuccess: (res) => setVillageOptions(res),
      })
    );
  }, []);
  useEffect(() => {
    getUnitOptionsListAPi();
    getTransportListAPi(limit, offset);
    //getVillageOptionsListAPi();
    // eslint-disable-next-line
  }, [getUnitOptionsListAPi, getVillageOptionsListAPi]);

  const handleChangeInput = (name, value) => {
    setInputData({ ...inputData, [name]: value });
    if (value?.length > 0) {
      setInputDataError({ ...inputDataError, [name]: false });
    } else {
      setInputDataError({ ...inputDataError, [name]: true });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputData.unitId) {
      toast.error("Loaction Name is Required!");
      setInputDataError({ ...inputDataError, unitId: true });
    } else if (!inputData.villageId) {
      toast.error("Village is Required!");
      setInputDataError({ ...inputDataError, stateId: true });
    } else if (!inputData.kilometer) {
      toast.error("Kilometer is Required!");
      setInputDataError({ ...inputDataError, kilometer: true });
    } else if (!inputData.rate) {
      toast.error("Rate is Required!");
      setInputDataError({ ...inputDataError, rate: true });
    } else if (!inputData.effectiveDate) {
      toast.error("Effective Date is Required!");
      setInputDataError({ ...inputDataError, effectiveDate: true });
    } else {
      setSubmitLoading(true);
      let formData = new FormData();
      formData.append("companyUnitId", inputData.unitId);
      formData.append("villageId", inputData.villageId);
      formData.append("kiloMeters", inputData.kilometer);
      formData.append("rate", inputData.rate);
      formData.append("effectiveDate", inputData.effectiveDate);

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}company/addtransportation`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setSubmitLoading(false);
          setInputData({
            unitId: "",
            villageId: "",
            kilometer: "",
            rate: "",
            effectiveDate: "",
          });
          getTransportListAPi(limit, offset);
          setCounter(counter + 1);
        } else {
          setSubmitLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setSubmitLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  // handleDelete Btn
  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    let formData = new FormData();
    formData.append("id", deleteId);
    formData.append("isDelete", "Yes");

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}company/deletetrasportation`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        getTransportListAPi(limit, offset);

        setDeleteModal(false);
        toast?.success(_data?.message);
        setDeleteLoading(false);
      } else {
        setDeleteLoading(false);
        setDeleteModal(false);

        toast.error(_data?.message);
      }
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
    }
  };

  const column = useMemo(
    () => [
      {
        Header: "Unit Name",
        accessor: "unit.unitName",
        filterable: false,
      },
      {
        Header: "Village Name",
        accessor: "village.villageName",
        filterable: false,
      },
      {
        Header: "Kilometer",
        accessor: "kiloMeters",
        filterable: false,
      },
      {
        Header: "Rate",
        accessor: "rate",
        filterable: false,
      },
      {
        Header: "Eff. Date",
        accessor: "effectiveDate",
        filterable: false,
      },
      {
        id: "Action",
        Header: () => (
          <span
            className="d-flex justify-content-center"
            style={{ textAlign: "center" }}
          >
            Action
          </span>
        ),
        Cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <div className="hstack gap-2">
                {/* Edit Button */}
                {permissionsObj?.edit && (
                  <button
                    className="border-0"
                    style={{ background: "transparent" }}
                    onClick={() => {
                      setEditModal(true);
                      setSingleData(cellProps.row.original);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_32_318)">
                        <path
                          d="M6.87549 2.5H2.50049C2.16897 2.5 1.85103 2.6317 1.6166 2.86612C1.38218 3.10054 1.25049 3.41848 1.25049 3.75V12.5C1.25049 12.8315 1.38218 13.1495 1.6166 13.3839C1.85103 13.6183 2.16897 13.75 2.50049 13.75H11.2505C11.582 13.75 11.9 13.6183 12.1344 13.3839C12.3688 13.1495 12.5005 12.8315 12.5005 12.5V8.125"
                          stroke="#3E7531"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.563 1.56249C11.8116 1.31385 12.1489 1.17416 12.5005 1.17416C12.8521 1.17416 13.1893 1.31385 13.438 1.56249C13.6866 1.81113 13.8263 2.14836 13.8263 2.49999C13.8263 2.85162 13.6866 3.18885 13.438 3.43749L7.50049 9.37499L5.00049 9.99999L5.62549 7.49999L11.563 1.56249Z"
                          stroke="#3E7531"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_32_318">
                          <rect
                            width="15"
                            height="15"
                            fill="white"
                            transform="translate(0.000488281)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                )}

                {/* Delete Button */}
                {/* {permissionsObj?.delete && ( */}
                <button
                  className="border-0"
                  style={{ background: "transparent" }}
                  onClick={() => {
                    setDeleteModal(true);
                    setDeleteId(cellProps.row.original.id);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_32_330)">
                      <path
                        d="M6.63507 -0.0178833C5.50545 -0.0178833 4.59795 0.92077 4.59795 2.05558V2.30769H2.30814C1.99026 2.30769 1.73122 2.56673 1.73122 2.88462V3.46154H1.1543V4.61539H2.30814V13.2692C2.30814 14.2183 3.08987 15 4.03891 15H10.962C11.911 15 12.6928 14.2183 12.6928 13.2692V4.61539H13.8466V3.46154H13.2697V2.88462C13.2697 2.56673 13.0106 2.30769 12.6928 2.30769H10.403V2.055C10.403 0.920771 9.49545 -0.0178833 8.36584 -0.0178833H6.63507ZM6.63507 1.17173H8.36584C8.82968 1.17173 9.21334 1.55019 9.21334 2.055V2.30769H5.78699V2.055C5.78699 1.55019 6.17064 1.17231 6.63449 1.17231L6.63507 1.17173ZM3.46199 4.61539H6.41872C6.49026 4.62289 6.56122 4.63327 6.63507 4.63327H8.36584C8.43968 4.63327 8.51007 4.62289 8.58218 4.61539H11.5389V13.2692C11.5389 13.594 11.2868 13.8462 10.962 13.8462H4.03891C3.7141 13.8462 3.46199 13.594 3.46199 13.2692V4.61539ZM4.61584 5.76923V12.6923H5.76968V5.76923H4.61584ZM6.92353 5.76923V12.6923H8.07737V5.76923H6.92353ZM9.23122 5.76923V12.6923H10.3851V5.76923H9.23122Z"
                        fill="#EC0505"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_32_330">
                        <rect
                          width="15"
                          height="15"
                          fill="white"
                          transform="translate(0.000488281)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                {/* )} */}
              </div>
            </div>
          );
        },
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  const onChangeSearch = async (value) => {
    setLoading(true);
    setSearchInput(value);
    let formData = new FormData();

    formData.append("departmentName", value);

    try {
      const apiCall = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }company/departmentfilter?limit=${5}&offset=${0}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        setVillageMappingList(_data?.filteredDepartmentList);
        setTotalCount(_data?.filteredDepartmentList?.count);
      } else {
        setLoading(false);
        toast?.error(_data?.message);
      }
    } catch (error) {
      setLoading(false);
      toast?.error(error);
      console.log(error);
    }
  };

  const onSubmitModal = () => {
    getTransportListAPi(limit, offset);

    setEditModal(false);
  };

  return (
    <PageLayout
      title={"Farmers Management - Transport Master"}
      loading={loading}
    >
      <div className="" style={{ margin: 40 }}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div>
            <Row className=" g-3 mt-2 ">
              <Col lg={3} md={12} sm={12} className="d-flex">
                <CustomAutoComplete
                  name="unitId"
                  fullWidth
                  key={`unit-${unitOptions?.length}-${counter}`}
                  label="Location Name *"
                  value={
                    unitOptions.map(
                      (product) => product?.label === inputData?.unitId
                    )?.label
                  }
                  options={unitOptions.map((product) => product.label)}
                  size="small"
                  getOptionLabel={(option) => option}
                  onChange={(event, option) => {
                    let selectedData = unitOptions?.find(
                      (er) => er?.label === option
                    );
                    setInputData({ ...inputData, unitId: selectedData?.value });
                    setInputDataError({ ...inputDataError, unitId: false });
                  }}
                  error={inputDataError?.unitId}
                  renderOption={(props, option) => {
                    const { id, label } = unitOptions.filter(
                      (product) => product.label === option
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label}
                      </li>
                    );
                  }}
                />
              </Col>
              <Col lg={3} md={12} sm={12} className="d-flex">
                <CustomAutoComplete
                  name="VillageId"
                  fullWidth
                  key={`village-${villageOptions?.length}-${counter}`}
                  label="Village *"
                  value={
                    villageOptions.map(
                      (product) => product?.value === inputData?.villageId
                    )?.label
                  }
                  options={villageOptions}
                  size="small"
                  getOptionLabel={(option) => option?.label}
                  onChange={(event, option) => {
                    setInputData({ ...inputData, villageId: option?.value });
                    setInputDataError({ ...inputDataError, villageId: false });
                  }}
                  error={inputDataError?.villageId}
                  renderOption={(props, option) => {
                    const { id, label } = villageOptions?.filter(
                      (product) => product.label === option?.label
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label || ""}
                      </li>
                    );
                  }}
                />
              </Col>
              <Col lg={3} md={12} sm={12} className="d-flex">
                <TextField
                  id="outlined-basic"
                  label="Kilometer *"
                  type="number"
                  fullWidth
                  name="kilometer"
                  size="small"
                  value={inputData?.kilometer}
                  error={inputDataError?.kilometer}
                  onChange={(e) => {
                    handleChangeInput(e.target.name, e.target.value);
                  }}
                  variant="outlined"
                />
              </Col>
              <Col lg={3} md={12} sm={12} className="d-flex">
                <TextField
                  id="outlined-basic"
                  type="number"
                  size="small"
                  label="Rate *"
                  name="rate"
                  fullWidth
                  value={inputData?.rate}
                  error={inputDataError?.rate}
                  onChange={(e) => {
                    handleChangeInput(e.target.name, e.target.value);
                  }}
                  variant="outlined"
                />
              </Col>
            </Row>
          </div>
          <div>
            <Row className=" g-3 mt-2 ">
              <Col lg={3} md={12} sm={12} className="d-flex">
                <Flatpickr
                  className="form-control"
                  options={{
                    dateFormat: "Y-m-d",
                    minDate: new Date().fp_incr(-3),
                  }}
                  value={inputData?.effectiveDate}
                  onChange={(date, dateStr) => {
                    setInputData({ ...inputData, effectiveDate: dateStr });
                  }}
                />
              </Col>
              <Col lg={3} md={12} sm={12} className="d-flex">
                <button
                  type="submit"
                  disabled={submitLoading}
                  className="custom-btn-page-layout btn text-white"
                >
                  {submitLoading ? (
                    <ClipLoader color="#75D100" size={25} />
                  ) : (
                    "Submit"
                  )}
                </button>
              </Col>
            </Row>
          </div>
        </form>
        <div className="mt-5">
          <Col md={12}>
            <div className="d-flex justify-content-end" style={{ margin: 20 }}>
              <InputGroup className="search-inp">
                <Input
                  value={searchInput}
                  onChange={(e) => {
                    onChangeSearch(e.target.value);
                  }}
                  placeholder="Search For Village Name"
                />
                <InputGroupText>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M15.1987 13.8333L10.9037 9.53833C11.6977 8.33158 12.0157 6.87314 11.7962 5.44537C11.5766 4.01761 10.8351 2.72208 9.71524 1.80963C8.59536 0.897181 7.17674 0.432709 5.73406 0.506144C4.29138 0.579579 2.92726 1.1857 1.90581 2.20715C0.884365 3.22859 0.278248 4.59271 0.204813 6.03539C0.131379 7.47807 0.595851 8.89669 1.5083 10.0166C2.42075 11.1365 3.71628 11.878 5.14404 12.0975C6.57181 12.317 8.03025 11.999 9.237 11.205L13.532 15.5L15.1987 13.8333ZM1.86534 6.33333C1.86534 4.03584 3.7345 2.16667 6.032 2.16667C8.3295 2.16667 10.1987 4.03584 10.1987 6.33333C10.1987 8.63083 8.3295 10.5 6.032 10.5C3.7345 10.5 1.86534 8.63083 1.86534 6.33333Z"
                      fill="#999999"
                    />
                  </svg>
                </InputGroupText>
              </InputGroup>
            </div>
            {loading ? (
              ""
            ) : (
              <div className="d-grid" style={{ border: "1px solid #DEE2E6" }}>
                <TableContainer
                  columns={column}
                  data={transportList || []}
                  isGlobalFilter={false}
                  isAddUserList={false}
                  tableClass={"table-sm"}
                  divClass={"table-responsive"}
                  initialState={initialState}
                />
                <CustomPagination
                  apiCallFunction={getTransportListAPi}
                  rowDetails={transportList}
                  totalCount={totalCount}
                  limit={limit}
                  offset={offset}
                  setLimit={setLimit}
                  setOffset={setOffset}
                />
              </div>
            )}
          </Col>
        </div>
        {editModal && (
          <EditTransportMaster
            editModal={editModal}
            onCloseModal={() => setEditModal(false)}
            onSubmitModal={onSubmitModal}
            singleData={singleData}
          />
        )}

        <DeleteModal
          deleteModal={deleteModal}
          handleDeleteRequest={handleDeleteRequest}
          setDeleteModal={setDeleteModal}
          deleteLoading={deleteLoading}
        />
      </div>
    </PageLayout>
  );
};

export default TransportMaster;
