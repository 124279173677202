import dayjs from "dayjs";
import React from "react";
import QRCode from "react-qr-code";
import { keys } from "../../config/keys";
import Barcode from "react-barcode";

const Gatepass = React.forwardRef((props, ref) => {
  const { data, multifarmer, multifarmerdata } = props;

  return (
    <>
      {multifarmer && multifarmerdata.length > 0 ? (
        <div
          ref={ref}
          className="d-flex justify-content-center"
          //style={{ width: "300px" }}
        >
          <div className="row" style={{ width: "350px" }}>
            {multifarmerdata.map((data) => (
              <>
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                      <h4>{`NISOL MANUFACTURING COMPANY PRIVATE LIMITED`}</h4>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                      <h6>{`Gate Pass`}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div
                    style={{
                      height: "auto",
                      margin: "10px 0",
                      maxWidth: 350,
                      width: "100%",
                    }}
                  >
                    <QRCode
                      size={256}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={data.voucherno}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                </div>
                <div className="col-6">
                  {<b>{`Voucher #:`}</b>}
                  {` ${data.voucherno}`}
                </div>
                <div className="col-6">
                  {<b>{`Date :`}</b>}
                  {` ${dayjs(new Date()).format(keys().shortDateFormat)}`}
                </div>
                <div className="col-12">
                  {<b>{`Farmer Code:`}</b>}
                  {` ${data.selectedFarmer?.farmerCode || data.farmerCode}`}
                </div>
                <div className="col-12">
                  {<b>{`Name:`}</b>}
                  {` ${data.selectedFarmer?.farmer || data.farmer}`}
                </div>
                <div className="col-12">
                  {<b>{`Vehicle No:`}</b>}
                  {` ${data.vehicleno}`}
                </div>
                <div className="col-12">
                  {<b>{`Weight`}</b>}
                  {` ${data.vehicleweight} KG`}
                </div>
                <div className="col-12">
                  {<b>{`Print Time`}</b>}
                  {` ${dayjs(Date()).format(keys().dbTimeFormat)}`}
                </div>
                <div className="col-12">
                  <table className="table table-sm table-bordered">
                    <tbody>
                      {data?.purchasedetails
                        .filter((x) => x.producttypeid < 4)
                        .map((x) => (
                          <tr>
                            <th>{x.label || x.producttype}</th>
                            <td>{x.noofbags}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            ))}
          </div>
        </div>
      ) : (
        <div
          ref={ref}
          className="d-flex  p-5"
          //style={{ width: "300px" }}
        >
          <div className="row">
            <div className="col-2">
              <div
                style={{
                  height: "auto",
                  margin: "10px 0",
                  maxWidth: 100,
                  width: "100%",
                }}
              >
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={`${data.voucherno}`}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </div>
            <div className="col-9">
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <h4>{`NISOL MANUFACTURING COMPANY PRIVATE LIMITED`}</h4>
                </div>
                <div className="col-12 d-flex justify-content-center">
                  <h6>{`Gate Pass`}</h6>
                </div>
              </div>
            </div>

            <div className="col-6">
              {<b>{`Voucher #:`}</b>}
              {` ${data.voucherno}`}
            </div>
            <div className="col-6">
              {<b>{`Date :`}</b>}
              {` ${dayjs(new Date()).format(keys().shortDateFormat)}`}
            </div>
            <div className="col-6">
              {<b>{`Farmer Code:`}</b>}
              {` ${data.selectedFarmer?.farmerCode || data.farmerCode}`}
            </div>
            <div className="col-6">
              {<b>{`Vehicle No:`}</b>}
              {` ${data.vehicleno}`}
            </div>
            <div className="col-6">
              {<b>{`Name:`}</b>}
              {` ${data.selectedFarmer?.farmer || data.farmer}`}
            </div>

            <div className="col-6">
              {<b>{`Weight`}</b>}
              {` ${data.vehicleweight} KG`}
            </div>
            {/* <div className="col-12">
              {<b>{`Print Time`}</b>}
              {` ${dayjs(Date()).format(keys().dbTimeFormat)}`}
            </div> */}
            <div className="col-12 mt-2">
              <table className="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th>{`Material Name`}</th>
                    <th>{`Estimated Bag Qty`}</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.purchasedetails
                    .filter((x) => x.producttypeid < 4)
                    .map((x) => (
                      <tr key={Math.random()}>
                        <th>{x.label || x.producttype}</th>
                        <td>{x.noofbags}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="col-12 mt-2 text-center">
              <Barcode
                value={data.voucherno}
                height={30}
                displayValue={true}
                format={"CODE128"}
                fontSize={10}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default Gatepass;
