import * as types from "../constTypes";
import { EVENT_TYPE_LIST, FINANCIAL_YEAR } from "../constTypes/common";

const initialState = {
  country_list: [],
  state_list: [],
  district_list: [],
  taluka_list: [],
  village_list: [],
};

export const geo_location_list = (state = initialState, action) => {
  switch (action.type) {
    case types.commonTypes.COUNTRY_LIST:
      return { ...state, country_list: action.payload || [] };
    case types.commonTypes.STATE_LIST:
      return { ...state, state_list: action.payload || [] };
    case types.commonTypes.DISTRICT_LIST:
      return { ...state, district_list: action.payload || [] };
    case types.commonTypes.TALUKA_LIST:
      return { ...state, taluka_list: action.payload || [] };
    case types.commonTypes.VILLAGE_LIST:
      return { ...state, village_list: action.payload || [] };
    default:
      return state;
  }
};

const product_list = [];
export const productlist = (state = product_list, action) => {
  switch (action.type) {
    case types.commonTypes.PRODUCT_LIST:
      return action.payload;
    default:
      return state;
  }
};

const company_unit_list = [];
export const companyunitlist = (state = company_unit_list, action) => {
  switch (action.type) {
    case types.commonTypes.COMPANYUNIT_LIST:
      return action.payload;
    default:
      return state;
  }
};

const document_master = [];
export const document_master_list = (state = document_master, action) => {
  switch (action.type) {
    case types.commonTypes.DOCUMENT_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const bank_list = (state = [], action) => {
  switch (action.type) {
    case types.commonTypes.BANK_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const relation_list = (state = [], action) => {
  switch (action.type) {
    case types.commonTypes.RELATION_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const templates_list = (state = [], action) => {
  switch (action.type) {
    case types.TEMPLATES_LIST:
      return [...action.payload];
    default:
      return state;
  }
};

export const event_type_list = (state = [], action) => {
  switch (action.type) {
    case EVENT_TYPE_LIST:
      return [...action.payload] || [];
    default:
      return state;
  }
};

export const financial_year = (state = [], action) => {
  switch (action.type) {
    case FINANCIAL_YEAR:
      return [...action.payload] || [];
    default:
      return state;
  }
};
