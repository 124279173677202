import axios from "../../common/axios";
import { toast } from "react-toastify";
import { keys } from "../../config/keys";
import { _dispatchActions } from "./common";
import { MODULE_LIST, ROLE_LIST } from "../constTypes";

export const get_module_lists =
  ({ onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .get(`${keys().ServicePath}/api/role/modules`)
      .then((res) => {
        dispatch(_dispatchActions(MODULE_LIST, res.data.rows));
        if (onSuccess) onSuccess(res.data.rows);
      })
      .catch((e) => {
        toast.error(e.toString());
      });
  };

export const get_module_list_by_roleid =
  (id, { onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .post(`${keys().ServicePath}/api/role/role_by_id`, { id })
      .then((res) => {
        dispatch(_dispatchActions(MODULE_LIST, res.data.rows));
        if (onSuccess) onSuccess(res.data.rows);
      })
      .catch((e) => {
        toast.error(e.toString());
      });
  };

export const get_role_lists =
  ({ onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .get(`${keys().ServicePath}/api/role/get_role_list`)
      .then((res) => {
        dispatch(_dispatchActions(ROLE_LIST, res.data.rows));
        if (onSuccess) onSuccess(res.data.rows);
      })
      .catch((e) => {
        toast.error(e.toString());
      });
  };

export const insert_role =
  (data, { onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .post(`${keys().ServicePath}/api/role`, data)
      .then((res) => {
        if (onSuccess) onSuccess(res.data.rows);
      })
      .catch((e) => {
        toast.error(e.toString());
      });
  };

export const update_role =
  (data, { onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .post(`${keys().ServicePath}/api/role/update`, data)
      .then((res) => {
        if (onSuccess) onSuccess(res.data.rows);
      })
      .catch((e) => {
        toast.error(e.toString());
      });
  };
