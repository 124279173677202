export const COUNTRY_LIST = "COUNTRY_LIST";
export const STATE_LIST = "STATE_LIST";
export const DISTRICT_LIST = "DISTRICT_LIST";
export const TALUKA_LIST = "TALUKA_LIST";
export const VILLAGE_LIST = "VILLAGE_LIST";
export const PRODUCT_LIST = "PRODUCT_LIST";
export const COMPANYUNIT_LIST = "COMPANYUNIT_LIST";
export const DOCUMENT_LIST = "DOCUMENT_LIST";
export const BANK_LIST = "BANK_LIST";
export const RELATION_LIST = "RELATION_LIST";
export const EVENT_TYPE_LIST = "EVENT_TYPE_LIST";
export const FINANCIAL_YEAR = "FINANCIAL_YEAR";