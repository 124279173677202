/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import {
  bagissueActions,
  commonActions,
  productActions,
  yieldEstimationActions
} from "../../redux/actions";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { issueBagValidationSchema } from "./validationSchema";
import { keys } from "../../config/keys";
import { getYieldEstimtedFarmerDropdownList } from "../../redux/actions/farmer";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import { save_bag_issue } from "../../redux/actions/bagissue";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useBagIssueListHooks = () => {
  const dispatch = useDispatch();

  const { bag_issue_list } = useSelector((obj) => obj);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(!loading);
    dispatch(
      bagissueActions.get_bag_issue_list({
        onSuccess: () => setLoading(false),
        onError: () => setLoading(false)
      })
    );
  }, []);

  return {
    bag_issue_list,
    loading
  };
};

const initialValues = {
  issuedatestring: dayjs(Date()).format(keys().dbDateFormat),
  issuedate: dayjs(Date()),
  voucherno: "",
  lastvoucherno: "",
  selectedFarmer: null,
  farmerid: 0,
  selectedbagno: null,
  totalbagstoissue: 1,
  bags_no_to_issue: "",
  bags: [],
  productid: 0,
  selectedProduct: null
};

export const useBagIssueHooks = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { bag_issue_list_farmer, farmerDropdownList, products } = useSelector(
    (obj) => obj
  );
  const [loading, setLoading] = useState(true);
  const [farmerYieldData, setFarmerYieldEstimationData] = useState([]);
  const [bags_in_stock, setAvailableBags] = useState([]);
  const [total_bags_stock, settotalbagsStock] = useState([
    { stock: 0, capacity: 0 }
  ]);
  const [formData, setFormData] = useState(initialValues);
  const [totalEstimatedYield, setTotalEstimatedYield] = useState(0);
  const [estimatedBagsToIssue, setEstimatedBagsToIssue] = useState(0);
  const [previousbagsissued, setpreviousbagsissued] = useState(0);
  const [totalestimatedbagstoissue, settotalestimatedbagstoissue] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(issueBagValidationSchema)
  });

  useEffect(() => {
    setLoading(!loading);
    get_latest_voucher();
    bag_issuse_list();
    farmer_dropdown_list();
    _get_product_list();
  }, []);

  useEffect(() => {
    if (formData.selectedbagno !== null) {
      const bags = bags_in_stock.slice(
        Number(
          bags_in_stock.findIndex(
            (x) => x.label === formData.selectedbagno.label
          )
        ),
        Number(
          bags_in_stock.findIndex(
            (x) => x.label === formData.selectedbagno.label
          )
        ) + Number(formData.totalbagstoissue)
      );
      const bags_no_to_issue = bags.map((x) => x.bagno).join(",");
      setFormData((prev) => ({
        ...prev,
        bags,
        bags_no_to_issue
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        bags: [],
        bags_no_to_issue: ""
      }));
    }
  }, [formData?.selectedbagno, formData.totalbagstoissue]);

  useEffect(() => {
    if (location.state !== null) {
      const data = location.state;
      setFormData((prev) => ({
        ...prev,
        farmerid: data.farmerid,
        selectedFarmer: farmerDropdownList.find((x) => x.id === data.farmerid)
      }));
      //get_available_bags();
      get_bags_issued_to_farmer(data.farmerid);
      get_farmer_yield_estimation(data.farmerid);
    } else {
      get_bags_issued_to_farmer(0);
    }
  }, [location.state]);

  useEffect(() => {
    if (formData.farmerid > 0 && formData.productid > 0) {
      // console.log(bag_issue_list_farmer);

      const _data = total_bags_stock[0];
      const bag_capacity = _data?.capacity || 0;
      const totalbagstoIssue =
        Math.ceil(totalEstimatedYield / bag_capacity) || 0;

      settotalestimatedbagstoissue(
        Math.ceil(totalEstimatedYield / bag_capacity) || 0
      );
      setEstimatedBagsToIssue(
        (Math.ceil(totalEstimatedYield / bag_capacity) || 0) -
          previousbagsissued
      );
    }
  }, [
    totalEstimatedYield,
    total_bags_stock,
    formData.farmerid,
    formData.productid
  ]);

  useEffect(() => {
    if (formData.farmerid > 0) {
      const previousbagsissued =
        bag_issue_list_farmer.length > 0
          ? bag_issue_list_farmer.reduce(
              (sum, row) => row.bagsissued + sum,
              0
            ) || 0
          : 0;

      setpreviousbagsissued(previousbagsissued);
    }
  }, [bag_issue_list_farmer]);

  const column = useMemo(
    () => [
      {
        Header: "Estimation Data",
        accessor: "invoiceDate",
        Footer: "TOTAL"
      },
      {
        Header: "Voucher #",
        accessor: "voucherno"
      },
      {
        Header: "Land",
        accessor: "landname"
      },
      {
        Header: "Product",
        accessor: "productname"
      },
      {
        Header: "Estimated Yield (in Kg)",
        accessor: "totalestimated",
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => row.values.totalestimated + sum,
                0
              ),
            [info.rows]
          );
          setTotalEstimatedYield(total);
          return total;
        }
      },

      {
        Header: "Estimated By",
        accessor: "employeeName"
      }
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  const bags_issued_to_farmer_columns = useMemo(
    () => [
      {
        Header: "Issue Date",
        accessor: "issuedatetime",
        Cell: ({ value }) => keys().shortDate(value)
      },
      {
        Header: "Voucher #",
        accessor: "voucherno"
      },
      {
        Header: "Bag Nos.",
        accessor: "bagnos"
      }
    ],
    []
  );

  const _get_product_list = () =>
    dispatch(
      productActions.get_product_list({
        onSuccess: (res) => {
          setLoading(false);
        }
      })
    );
  const get_latest_voucher = () =>
    dispatch(
      commonActions.get_last_latest_voucherno("bag_issue", {
        onSuccess: (res) => {
          if (res.length > 0) {
            setFormData((prev) => ({ ...prev, ...res[0] }));
          }
        }
      })
    );

  const bag_issuse_list = () =>
    dispatch(
      bagissueActions.get_bag_issue_list({
        onSuccess: () => setLoading(false),
        onError: () => setLoading(false)
      })
    );

  const farmer_dropdown_list = () =>
    dispatch(
      getYieldEstimtedFarmerDropdownList({
        onSuccess: () => setLoading(false),
        onError: () => setLoading(false)
      })
    );

  const get_farmer_yield_estimation = (farmerId) => {
    setLoading(true);
    dispatch(
      yieldEstimationActions.getYieldEstimationList(farmerId, {
        onSuccess: (res) => {
          setFarmerYieldEstimationData([...res]);
          setLoading(false);
        },
        onError: () => setLoading(false)
      })
    );
  };

  const get_available_bags = (productid) => {
    setLoading(true);
    dispatch(
      bagissueActions.get_available_bags_in_stock(productid, {
        onSuccess: (res) => {
          setAvailableBags([...res[1]]);
          settotalbagsStock([...res[2]]);
          setLoading(false);
          // const _data = res[2][0];
          // const bag_capacity = _data.capacity;
          // setEstimatedBagsToIssue(
          //   Math.ceil(totalEstimatedYield / bag_capacity)
          // );
        },
        onError: () => setLoading(false)
      })
    );
  };

  const get_bags_issued_to_farmer = (farmerid) => {
    setLoading(true);
    dispatch(
      bagissueActions.get_bag_issue_list_to_farmer(farmerid, {
        onSuccess: (res) => {
          console.log("BagsIssued", res);
          setLoading(false);
        },
        onError: () => setLoading(false)
      })
    );
  };

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onFarmerChange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      farmerid: selectedOption?.id || 0,
      selectedFarmer: selectedOption || null
    }));

    get_bags_issued_to_farmer(selectedOption?.id || 0);
    if (selectedOption !== null) get_farmer_yield_estimation(selectedOption.id);
    else setFarmerYieldEstimationData([]);
  };

  const onBagChange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      selectedbagno: selectedOption || null
    }));
  };
  const onProductChange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      productid: selectedOption?.id || 0,
      selectedProduct: selectedOption || null
    }));
    if (selectedOption !== null) get_available_bags(selectedOption?.id);
    else setAvailableBags([]);
  };
  const onDateChange = (dateValue) => {
    setFormData((prev) => ({
      ...prev,
      issuedate: dateValue,
      issuedatestring: dayjs(dateValue).format("YYYY-MM-DD")
    }));
  };

  const onSubmit = () => {
    setLoading(!loading);
    const {
      issuedate,
      selectedFarmer,
      selectedbagno,
      bags,
      bags_no_to_issue,
      ...others
    } = formData;
    const bagIdsToIssue = bags.map((x) => x.id).join(",");
    const data = { ...others, bagIdsToIssue };

    dispatch(
      save_bag_issue(data, {
        onSuccess: (res) => {
          setLoading(false);
          toast.success("Bags Issued Successfully");
          navigate("/issuebag");
          setFormData({...initialValues});
        },
        onError: (ex) => {
          setLoading(false);
          toast.error(ex);
        }
      })
    );
  };

  return {
    register,
    handleSubmit,
    onSubmit,
    formData,
    farmerDropdownList,
    handleChange,
    onFarmerChange,
    onDateChange,
    farmerYieldData,
    errors,
    column,
    bags_in_stock,
    onBagChange,
    bag_issue_list_farmer,
    bags_issued_to_farmer_columns,
    products,
    onProductChange,
    total_bags_stock,
    estimatedBagsToIssue,
    loading,
    previousbagsissued,
    totalestimatedbagstoissue
  };
};
