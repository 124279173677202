import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import CustomModal from "../../Component/CustomModal";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
const ImageCapturePopup = ({
  onClose,
  onCapture,
  showCapturePopup,
  onFilebase64,
  filename="captured-image"
}) => {
  const [deviceId, setDeviceId] = React.useState({});
  const [devices, setDevices] = React.useState([]);

  const handleDevices = React.useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  useEffect(() => {
    console.log(devices);
  }, [devices]);

  const webcamRef = useRef(null);

  // eslint-disable-next-line
  const [imageData, setImageData] = useState(null);

  const bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
  };

  const filesizeinBytes = (base64) => {
    const base64String = base64.replaceAll("=", "");
    const bytes = base64String.length * (3 / 4);

    return bytes;
  };

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const byteCharacters = atob(imageSrc.split(",")[1]);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: "image/jpeg" });

    // Create a File object from the Blob
    const imageFile = new File([blob], `${filename}.jpg`, {
      type: "image/jpeg"
    });
    setImageData(imageFile);
    onCapture(imageFile);

    const fileObject = {
      name: `${dayjs(new Date()).unix()}.jpg`,
      type: "image/jpeg",
      base64: imageSrc,
      size: bytesToSize(imageFile?.size)
    };
    if (onFilebase64) onFilebase64(fileObject);

    onClose();
  };
  return (
    <CustomModal
      title={"Capture Image"}
      modalState={showCapturePopup}
      onCloseModal={onClose}
      handleSubmit={captureImage}
      btnText={"Capture Image"}
      disableButton={devices.length <= 0 ? true : false}
    >
      <div className="d-flex justify-content-center align-items-center ">
        {devices.length > 0 ? (
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            style={{ maxWidth: "100%", maxHeight: "200px" }} // Adjust the size of the Webcam feed
          />
        ) : (
          <Typography variant="h6">{`Webcam device not found`}</Typography>
        )}
      </div>
      {/* <button
        style={{ marginTop: "10px", padding: "5px 10px", borderRadius: "4px" }}
        onClick={captureImage}
      >
        Capture Image
      </button> */}
    </CustomModal>
  );
};
export default ImageCapturePopup;
