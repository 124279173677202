import React, { useEffect, useRef, useState } from "react";
import AvatarLogo from "../assests/Images/Avatar.png";
//import HeaderLogo from "../assests/Images/Nisol-Logo-dark-2.png";
import HeaderLogo from "../assests/Images/Nisol-Logo-dark.png";
import { getToken } from "../hooks/utils";
import { Col, Input, InputGroup, InputGroupText, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  logOutRequest,
  toggleSidebar,
  tokenRequest
} from "../store/reducers/layoutReducer";
import { useNavigate } from "react-router-dom";
import useBreakPoints from "../hooks/useBreakPoints";
import { deepOrange, green } from "@mui/material/colors";
import {
  Autocomplete,
  Avatar,
  Button,
  IconButton,
  InputAdornment,
  TextField
} from "@mui/material";
import {
  EventRepeat,
  Menu,
  MenuOpen,
  Notifications,
  NotificationsOutlined,
  Search
} from "@mui/icons-material";
import axios from "axios";
import Axios from "../common/axios";
import { setAuthorizationToken, setTokenToLocalStorage } from "../common/axios";
import { commonActions } from "../redux/actions";
import { textFieldProps } from "../common/commonControlProps";
import ModalContainer from "../Component/ModalContainer";
import { refreshToken } from "../redux/actions/auth";

const Header = () => {
  const { sidebarOpen, loginDetails, permissions } = useSelector(
    (state) => state.layout
  );
  const { financial_year } = useSelector((state) => state);
  const token = getToken();
  const [profileDropdownState, setProfileDropdownState] = useState(false);
  const [selectedFinancialYear, setSelectedFinancialYear] = useState(null);
  const [tempFinancialYear, setTempFinancialYear] = useState(null);
  const [confirmYearChangeModal, setConfirmYearChangeModal] = useState(false);
  const naviagte = useNavigate();
  const dispatch = useDispatch();
  const { isTablet, isMobile } = useBreakPoints();

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setProfileDropdownState);

  const _get_financial_year = () =>
    dispatch(commonActions.get_financial_year({}));

  useEffect(() => {
    if (token) {
      setAuthorizationToken(localStorage.getItem("newtoken"));
    }
    _get_financial_year();
  }, []);

  useEffect(() => {
    if (financial_year.length > 0) {
      const t = financial_year.find((x) => x.id === loginDetails.fyid);
      setSelectedFinancialYear(t);
    }
  }, [financial_year]);

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    dispatch(logOutRequest());
    naviagte("/login");
  };

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name) => {
    const s = name.split(" ");
    return {
      sx: {
        bgcolor: stringToColor(`${name} nisol`)
      },
      children: `${s[0][0].toUpperCase()} ${
        s.length > 1 ? s[1][0].toUpperCase() : ""
      }`
    };
  };

  const onFinancialYearChange = (e, option) => {
    setTempFinancialYear(option);
    setConfirmYearChangeModal(true);
    // setSelectedFinancialYear(option)
  };

  const onChangeFinancialYear = () => {
    let reqdata = {
      ...loginDetails,
      fyid: tempFinancialYear.id,
      display: tempFinancialYear.display
    };
    dispatch(
      refreshToken(
        { ...reqdata },
        {
          onSuccess: async (data) => {
            dispatch(
              tokenRequest({
                token: data.access,
                role: "admin",
                details: data.user,
                permissions
              })
            );

            setAuthorizationToken({ ...data.user, ...data.access });
            setTokenToLocalStorage(JSON.stringify({ ...data.user }));
            axios.defaults.headers.common["token"] = JSON.stringify({
              ...data.user
            });
            Axios.defaults.headers.common["token"] = JSON.stringify({
              ...data.user
            });

            axios.defaults.headers.common["newtoken"] = JSON.stringify({
              ...data.user
            });
            Axios.defaults.headers.common["newtoken"] = JSON.stringify({
              ...data.user
            });
            const t = financial_year.find((x) => x.id === data.user.fyid);
            setSelectedFinancialYear(t);
            setConfirmYearChangeModal(false);
          }
        }
      )
    );
  };

  return (
    <div
      className={`d-flex ${
        token ? "justify-content-between" : "justify-content-start"
      } align-items-center w-100 px-2`}
    >
      <div className="d-flex justify-content-center align-items-center">
        {/* <i
          className="fa fa-bars"
          style={{ cursor: "pointer" }}
          onClick={() => dispatch(toggleSidebar())}
          aria-hidden="true"
        ></i> */}

        {!sidebarOpen && (
          <img
            src={HeaderLogo}
            width={175}
            alt="Header-logo"
            className="header-nis-logo"
          />
        )}
      </div>
      {token && (
        <div className="gap-1 d-flex justify-content-between align-items-center w-100">
          <Col lg={2} sm={12}>
            <Autocomplete
              name="fyid"
              size="small"
              fullWidth
              options={financial_year}
              value={selectedFinancialYear}
              onChange={onFinancialYearChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...textFieldProps}
                  label="Financial Year"
                />
              )}
            />
          </Col>
          <div className="gap-1 d-flex justify-content-end align-items-center w-100">
            <TextField
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
              placeholder="Search"
              fullWidth={isMobile}
            />

            {/* Bell-Icon Svg */}
            <NotificationsOutlined sx={{ fontSize: 30 }} />

            <div ref={wrapperRef}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setProfileDropdownState(!profileDropdownState);
                }}
                className="d-flex justify-content-center align-items-center"
              >
                {/* <img
                src={
                  loginDetails.employee_photo
                    ? loginDetails.employee_photo
                    : AvatarLogo
                }
                alt="AvatarLogo"
                style={{ height: 35, width: 35 }}
              /> */}
                {loginDetails.employee_photo === null ? (
                  <Avatar
                    {...stringAvatar(
                      `${
                        loginDetails.employeename === null
                          ? loginDetails.userId
                          : loginDetails.employeename
                      }`
                    )}
                    variant="rounded"
                    sx={{ width: 35, height: 35, bgcolor: green[800] }}
                  />
                ) : (
                  <Avatar
                    src={loginDetails.employee_photo}
                    variant="rounded"
                    sx={{ width: 35, height: 35 }}
                  />
                )}

                <span style={{ marginLeft: 10 }}>
                  {" "}
                  {isTablet
                    ? ""
                    : loginDetails.employeename
                    ? loginDetails.employeename
                    : loginDetails.employeename}
                </span>
              </div>

              <div
                className={`dropdown__wrapper dropdown__wrapper--fade-in  ${
                  profileDropdownState ? "" : "hide none"
                }`}
                style={{ zIndex: 1000 }}
              >
                <div className="dropdown__group text-white d-flex justify-content-center align-items-center flex-column ">
                  <div className="user-name" style={{ fontSize: 14 }}>
                    Welcome
                  </div>
                  <div
                    className="email"
                    style={{ fontWeight: 700, fontSize: 18 }}
                  >
                    {loginDetails?.employeename}
                  </div>
                  {/* <img
                  src={
                    loginDetails.employee_photo
                      ? loginDetails.employee_photo
                      : AvatarLogo
                  }
                  alt="AvatarLogo"
                  className="mt-3"
                  style={{ height: 56, width: 56 }}
                /> */}
                  {loginDetails.employee_photo === null ? (
                    <Avatar
                      {...stringAvatar(
                        `${
                          loginDetails.employeename === null
                            ? loginDetails.userId
                            : loginDetails.employeename
                        }`
                      )}
                      variant="rounded"
                      sx={{ width: 56, height: 56, bgcolor: deepOrange[800] }}
                    />
                  ) : (
                    <Avatar
                      src={loginDetails.employee_photo}
                      variant="rounded"
                      sx={{ width: 56, height: 56 }}
                    />
                  )}
                </div>
                <hr className="divider text-white" />
                <nav className="text-white">
                  <ul>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M14 7.99995C14.002 9.19113 13.6477 10.3557 12.9827 11.344C12.4342 12.1619 11.6927 12.8322 10.8237 13.2954C9.95461 13.7586 8.98481 14.0006 8 14C7.01519 14.0006 6.04539 13.7586 5.17634 13.2954C4.30729 12.8322 3.56576 12.1619 3.01733 11.344C2.49515 10.5656 2.1626 9.67566 2.04634 8.74558C1.93007 7.8155 2.03333 6.87112 2.34783 5.98814C2.66233 5.10516 3.17933 4.30815 3.85738 3.66099C4.53543 3.01383 5.35566 2.53453 6.25234 2.26151C7.14901 1.98849 8.09718 1.92935 9.02083 2.08882C9.94448 2.2483 10.8179 2.62195 11.5711 3.17983C12.3244 3.73771 12.9364 4.4643 13.3582 5.30135C13.78 6.13841 13.9998 7.06263 14 7.99995Z"
                          stroke="white"
                        />
                        <path
                          d="M8.83332 5.99996C8.83332 6.45996 8.45999 6.83329 7.99999 6.83329V7.83329C8.48622 7.83329 8.95254 7.64014 9.29635 7.29632C9.64017 6.95251 9.83332 6.48619 9.83332 5.99996H8.83332ZM7.99999 6.83329C7.53999 6.83329 7.16666 6.45996 7.16666 5.99996H6.16666C6.16666 6.48619 6.35981 6.95251 6.70363 7.29632C7.04745 7.64014 7.51376 7.83329 7.99999 7.83329V6.83329ZM7.16666 5.99996C7.16666 5.53996 7.53999 5.16663 7.99999 5.16663V4.16663C7.51376 4.16663 7.04745 4.35978 6.70363 4.7036C6.35981 5.04741 6.16666 5.51373 6.16666 5.99996H7.16666ZM7.99999 5.16663C8.45999 5.16663 8.83332 5.53996 8.83332 5.99996H9.83332C9.83332 5.51373 9.64017 5.04741 9.29635 4.7036C8.95254 4.35978 8.48622 4.16663 7.99999 4.16663V5.16663ZM3.44399 11.904L2.96466 11.7613L2.88666 12.0226L3.06466 12.2293L3.44399 11.904ZM12.556 11.904L12.936 12.23L13.1133 12.0233L13.0353 11.7613L12.556 11.904ZM5.99999 10.5H9.99999V9.49996H5.99999V10.5ZM5.99999 9.49996C5.31806 9.49999 4.65434 9.72007 4.10748 10.1275C3.56062 10.5349 3.15982 11.1079 2.96466 11.7613L3.92332 12.0466C4.05701 11.5997 4.33128 11.2078 4.70539 10.9292C5.07951 10.6506 5.53352 10.5 5.99999 10.5V9.49996ZM7.99999 13.5C7.20627 13.5009 6.4218 13.3296 5.70071 12.9979C4.97963 12.6661 4.3391 12.1819 3.82332 11.5786L3.06466 12.2293C3.6743 12.9419 4.43122 13.5146 5.28325 13.9065C6.13528 14.2984 7.06216 14.5009 7.99999 14.5V13.5ZM9.99999 10.5C10.98 10.5 11.81 11.152 12.0767 12.0466L13.0353 11.7613C12.8402 11.1079 12.4394 10.5349 11.8925 10.1275C11.3456 9.72007 10.6819 9.49999 9.99999 9.49996V10.5ZM12.1767 11.5786C11.6609 12.1819 11.0204 12.6661 10.2993 12.9979C9.57818 13.3296 8.79371 13.5009 7.99999 13.5V14.5C8.93783 14.5009 9.8647 14.2984 10.7167 13.9065C11.5688 13.5146 12.3264 12.9426 12.936 12.23L12.1767 11.5786Z"
                          fill="white"
                        />
                      </svg>{" "}
                      Profile
                    </li>
                  </ul>
                  <hr className="divider" />
                  <ul>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M11.8095 6.47615H7.23809M12.5714 2.66663C12.9756 2.66663 13.3631 2.82717 13.6489 3.11294C13.9347 3.39871 14.0952 3.7863 14.0952 4.19044V11.8095C14.0952 12.2136 13.9347 12.6012 13.6489 12.887C13.3631 13.1727 12.9756 13.3333 12.5714 13.3333L10.2865 13.3325L8.53866 15.0803C8.40746 15.2115 8.23291 15.2903 8.04775 15.302C7.86259 15.3136 7.67954 15.2573 7.53295 15.1436L7.46133 15.0803L5.71352 13.3325L3.42856 13.3333C3.02442 13.3333 2.63684 13.1727 2.35107 12.887C2.0653 12.6012 1.90475 12.2136 1.90475 11.8095V4.19044C1.90475 3.7863 2.0653 3.39871 2.35107 3.11294C2.63684 2.82717 3.02442 2.66663 3.42856 2.66663H12.5714Z"
                          stroke="white"
                          strokeWidth="0.761905"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.9516 7.23804C5.33256 7.23804 5.71351 6.85709 5.71351 6.47614C5.71351 6.09519 5.33256 5.71423 4.9516 5.71423C4.57065 5.71423 4.19046 6.09519 4.19046 6.47614C4.19046 6.85709 4.57065 7.23804 4.9516 7.23804ZM4.9516 10.2857C5.33256 10.2857 5.71351 9.90471 5.71351 9.52376C5.71351 9.1428 5.33256 8.76185 4.9516 8.76185C4.57065 8.76185 4.19046 9.1428 4.19046 9.52376C4.19046 9.90471 4.57065 10.2857 4.9516 10.2857Z"
                          fill="white"
                        />
                        <path
                          d="M11.8095 9.5238H7.2381"
                          stroke="white"
                          strokeWidth="0.761905"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                      Message
                    </li>
                  </ul>
                  <hr className="divider" />
                  <ul>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M7 10.09L5.205 8.29504L4.5 9.00004L7 11.5L11.5 7.00004L10.795 6.29004L7 10.09Z"
                          fill="white"
                        />
                        <path
                          d="M12.5 2.5H11V2C11 1.73478 10.8946 1.48043 10.7071 1.29289C10.5196 1.10536 10.2652 1 10 1H6C5.73478 1 5.48043 1.10536 5.29289 1.29289C5.10536 1.48043 5 1.73478 5 2V2.5H3.5C3.23478 2.5 2.98043 2.60536 2.79289 2.79289C2.60536 2.98043 2.5 3.23478 2.5 3.5V14C2.5 14.2652 2.60536 14.5196 2.79289 14.7071C2.98043 14.8946 3.23478 15 3.5 15H12.5C12.7652 15 13.0196 14.8946 13.2071 14.7071C13.3946 14.5196 13.5 14.2652 13.5 14V3.5C13.5 3.23478 13.3946 2.98043 13.2071 2.79289C13.0196 2.60536 12.7652 2.5 12.5 2.5ZM6 2H10V4H6V2ZM12.5 14H3.5V3.5H5V5H11V3.5H12.5V14Z"
                          fill="white"
                        />
                      </svg>
                      Taskboard
                    </li>
                  </ul>
                  <hr className="divider" />
                  <ul>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M11.3333 5.33329V3.33329C11.3333 3.15648 11.2631 2.98691 11.1381 2.86189C11.013 2.73686 10.8435 2.66663 10.6667 2.66663H3.99999C3.64637 2.66663 3.30723 2.8071 3.05718 3.05715C2.80713 3.3072 2.66666 3.64634 2.66666 3.99996M2.66666 3.99996C2.66666 4.35358 2.80713 4.69272 3.05718 4.94277C3.30723 5.19282 3.64637 5.33329 3.99999 5.33329H12C12.1768 5.33329 12.3464 5.40353 12.4714 5.52856C12.5964 5.65358 12.6667 5.82315 12.6667 5.99996V7.99996M2.66666 3.99996V12C2.66666 12.3536 2.80713 12.6927 3.05718 12.9428C3.30723 13.1928 3.64637 13.3333 3.99999 13.3333H12C12.1768 13.3333 12.3464 13.2631 12.4714 13.138C12.5964 13.013 12.6667 12.8434 12.6667 12.6666V10.6666"
                          stroke="white"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13.3333 8V10.6667H10.6666C10.313 10.6667 9.97389 10.5262 9.72384 10.2761C9.47379 10.0261 9.33331 9.68696 9.33331 9.33333C9.33331 8.97971 9.47379 8.64057 9.72384 8.39052C9.97389 8.14048 10.313 8 10.6666 8H13.3333Z"
                          stroke="white"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Balance
                    </li>
                  </ul>
                  <hr className="divider" />
                  <ul>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M8 1C6.61553 1 5.26216 1.41054 4.11101 2.17971C2.95987 2.94888 2.06266 4.04213 1.53285 5.32122C1.00303 6.6003 0.86441 8.00776 1.13451 9.36563C1.4046 10.7235 2.07129 11.9708 3.05026 12.9497C4.02922 13.9287 5.2765 14.5954 6.63437 14.8655C7.99224 15.1356 9.3997 14.997 10.6788 14.4672C11.9579 13.9373 13.0511 13.0401 13.8203 11.889C14.5895 10.7378 15 9.38447 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1ZM8 14C6.81332 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19974 1.88378 7.99334 2.11529 6.82946C2.3468 5.66557 2.91825 4.59647 3.75736 3.75736C4.59648 2.91824 5.66558 2.3468 6.82946 2.11529C7.99335 1.88378 9.19975 2.0026 10.2961 2.45672C11.3925 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14Z"
                          fill="white"
                        />
                        <path
                          d="M8 12.5C8.41421 12.5 8.75 12.1642 8.75 11.75C8.75 11.3358 8.41421 11 8 11C7.58579 11 7.25 11.3358 7.25 11.75C7.25 12.1642 7.58579 12.5 8 12.5Z"
                          fill="white"
                        />
                        <path
                          d="M8.50001 4.00001H7.75001C7.45435 3.99935 7.16147 4.05709 6.88819 4.16993C6.61491 4.28277 6.36661 4.44848 6.15755 4.65755C5.94848 4.86661 5.78277 5.11491 5.66993 5.38819C5.55709 5.66147 5.49935 5.95435 5.50001 6.25001V6.50001H6.50001V6.25001C6.50001 5.91848 6.6317 5.60054 6.86612 5.36612C7.10054 5.1317 7.41848 5.00001 7.75001 5.00001H8.50001C8.83153 5.00001 9.14947 5.1317 9.38389 5.36612C9.61831 5.60054 9.75001 5.91848 9.75001 6.25001C9.75001 6.58153 9.61831 6.89947 9.38389 7.13389C9.14947 7.36831 8.83153 7.50001 8.50001 7.50001H7.50001V9.75001H8.50001V8.50001C9.09674 8.50001 9.66904 8.26295 10.091 7.841C10.513 7.41904 10.75 6.84674 10.75 6.25001C10.75 5.65327 10.513 5.08097 10.091 4.65902C9.66904 4.23706 9.09674 4.00001 8.50001 4.00001Z"
                          fill="white"
                        />
                      </svg>
                      Help
                    </li>
                  </ul>
                  <hr className="divider" />
                  <ul
                    onClick={(e) => {
                      handleLogout(e);
                    }}
                  >
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_1403_67)">
                          <path
                            d="M3.33333 7.33338H8.66667V8.66671H3.33333V10.6667L0 8.00004L3.33333 5.33338V7.33338ZM2.66667 12H4.472C5.24179 12.6789 6.19112 13.1213 7.20608 13.274C8.22105 13.4267 9.25852 13.2833 10.194 12.861C11.1295 12.4387 11.9233 11.7555 12.48 10.8933C13.0368 10.031 13.333 9.02643 13.333 8.00004C13.333 6.97366 13.0368 5.96907 12.48 5.10683C11.9233 4.2446 11.1295 3.56134 10.194 3.13904C9.25852 2.71675 8.22105 2.57336 7.20608 2.72608C6.19112 2.8788 5.24179 3.32115 4.472 4.00004H2.66667C3.28711 3.17149 4.09222 2.49905 5.01805 2.03614C5.94387 1.57322 6.9649 1.3326 8 1.33338C11.682 1.33338 14.6667 4.31804 14.6667 8.00004C14.6667 11.682 11.682 14.6667 8 14.6667C6.9649 14.6675 5.94387 14.4269 5.01805 13.9639C4.09222 13.501 3.28711 12.8286 2.66667 12Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1403_67">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      Logout
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <IconButton
              className="d-block d-sm-block d-md-none custom-btn-page-layout custom-btn-warning"
              size="small"
              onClick={() => {
                dispatch(toggleSidebar());
              }}
            >
              {sidebarOpen ? <MenuOpen /> : <Menu />}
            </IconButton>
            {/* <div
            className={`header-side-view  header-mobile-toggle-btn`}
            // style={{
            //   padding: "4px 8px 8px 8px",
            //   background:
            //     "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, #BEEE00 0%, #277625 100%)",
            // }}
          >
            <button
              className="border-0 m-0 p-0"
              style={{
                background:
                  "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, #BEEE00 0%, #277625 100%)",
              }}
              onClick={() => {
                dispatch(toggleSidebar());
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15 15H20V20H15V15ZM7.5 15H12.5V20H7.5V15ZM0 15H5V20H0V15ZM15 7.5H20V12.5H15V7.5ZM7.5 7.5H12.5V12.5H7.5V7.5ZM0 7.5H5V12.5H0V7.5ZM15 0H20V5H15V0ZM7.5 0H12.5V5H7.5V0ZM0 0H5V5H0V0Z"
                  fill="white"
                />
              </svg>
            </button>
          </div> */}
          </div>
        </div>
      )}

      {/* Sidebar Toggle Logo for Mobile View  */}
      <ModalContainer
        modalState={confirmYearChangeModal}
        onCloseModal={() => setConfirmYearChangeModal(false)}
        title={"Confirm Year Change"}
        onClick={() => onChangeFinancialYear()}
      >
        <Row className="m-2">
          <Col lg={12} md={12} sm={12}>
            {`Are you sure you want to change the financial year from `}
          </Col>
          <Col lg={12} md={12} sm={12}>
            {`${loginDetails.display} to ${tempFinancialYear?.display}`}
          </Col>
        </Row>
      </ModalContainer>
    </div>
  );
};

function useOutsideAlerter(ref, setProfileDropdownState) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setProfileDropdownState(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setProfileDropdownState]);
}

export default Header;
