import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { keys } from "../../config/keys";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { waterWasteManagementValidationSchema } from "./validationSchema";
import { wasteManagementActions } from "../../redux/actions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { usePermissionHooks } from "../../common/useCheckPermission";
import { GridAction } from "../../common/useGridAction";

const initialValues = {
  id: 0,
  readingdatestring: dayjs(Date()).format(keys().dbDateFormat),
  readingdate: dayjs(Date()),
  mainbore: 0,
  sftnrtankin: 0,
  sftnrtankout: 0,
  security: 0,
  canteen: 0,
  stpwash: 0,
  chrtoi: 0,
  chrsh: 0,
  sftnrwash: 0,
  stpin: 0,
  stpout: 0,
  totalizerreading: 0,
};

export const useWaterWasterManagementHooks = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { water_waste_list } = useSelector((obj) => obj);
  const [formData, setFormData] = useState(initialValues);

  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const permission = usePermissionHooks(location);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(waterWasteManagementValidationSchema),
  });

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onDateChange = (dateValue) => {
    setFormData((prev) => ({
      ...prev,
      readingdate: dateValue,
      readingdatestring: dayjs(dateValue).format("YYYY-MM-DD"),
    }));
  };

  const resetFormData = () => {
    setFormData(initialValues);
  };

  const _get_water_waste_list = () =>
    dispatch(wasteManagementActions.get_water_waste_list());

  const onSubmit = async () => {
    await dispatch(
      wasteManagementActions.saveReadingData(
        { ...formData },
        {
          onSuccess: () => {
            toast.success("Reading data saved successfully");
            setFormData(initialValues);
          },
          onError: (e) => {
            toast.error(e);
          },
        }
      )
    );
  };

  const onGridDelete = async () => {
    setDeleteLoading(true);
    if (deleteId > 0) {
      dispatch(
        wasteManagementActions.delete_water_waste_reading(
          { id: deleteId },
          {
            onSuccess: () => {
              toast.success("Reading Deleted Successfully!!!");
              _get_water_waste_list();
            },
            onError: (err) =>
              toast.error("Something went wrong. Please try again."),
          }
        )
      );
      setDeleteLoading(false);
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    _get_water_waste_list();
  }, []);

  const column = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "readingdate",
        Cell: ({ value }) => keys().shortDate(value),
      },
      {
        Header: "MAIN BORE",
        accessor: "mainbore",
      },
      {
        Header: "SFTR Tank In",
        accessor: "sftnrtankin",
      },
      {
        Header: "SFTR Tank Out",
        accessor: "sftnrtankout",
      },
      {
        Header: "Security",
        accessor: "security",
      },
      {
        Header: "Canteen",
        accessor: "canteen",
      },
      {
        Header: "STP Wash",
        accessor: "stpwash",
      },
      {
        Header: "CHR TOI",
        accessor: "chrtoi",
      },
      {
        Header: "CHR SH",
        accessor: "chrsh",
      },
      {
        Header: "SFTNR Wash",
        accessor: "sftnrwash",
      },
      {
        Header: "STP In",
        accessor: "stpin",
      },
      {
        Header: "STP Out",
        accessor: "stpout",
      },
      {
        Header: "Totalizer",
        accessor: "totalizerreading",
      },
      {
        ...GridAction({
          permission,
          //   onEditClick: (cellProps) => {
          //     console.log(cellProps.row.original);
          //   },
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original.id);
          },
        }),
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  return {
    formData,
    actions: {
      onDateChange,
      handleChange,
      resetFormData,
      onSubmit,
      onGridDelete,
    },
    form: { register, handleSubmit, errors },
    state: { deleteId, deleteLoading, deleteModal, setDeleteModal },
    water_waste_list,
    column,
  };
};
