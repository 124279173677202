/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import PageLayout from "../../Component/PageLayout";
import { Col, Row, FormGroup, Form } from "reactstrap";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Autocomplete,
  TextField,
  Typography,
  styled,
  Paper,
  Button,
  FormHelperText,
  Link,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { ErrorMessage } from "../../common/Error";
import { useYieldEstimationHooks } from "./useYieldEstimationHooks";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { keys } from "../../config/keys";
import TableContainer from "../../Component/TableContainer";
import DeleteModal from "../../Component/DeleteModal";
import { statusOption } from "../../hooks/StaticData";
import { textFieldProps } from "../../common/commonControlProps";
import { Cancel, Save, Update } from "@mui/icons-material";
import { GridAction } from "../../common/useGridAction";
import { usePermissionHooks } from "../../common/useCheckPermission";
import { useLocation, useNavigate } from "react-router-dom";
import VirtualizeAutoComplete from "../../common/VirtualizedAutoComplete";
import { deepOrange } from "@mui/material/colors";

const YieldEstimation = () => {
  const {
    register,
    errors,
    formData,
    handleChange,
    handleSubmit,
    onSubmit,
    setEditModal,
    employeeDropdownList,
    farmerDropdownList,
    yieldConfigurationList,
    onDateChange,
    onTechnicianchange,
    onFarmerchange,
    onLandchange,
    onyieldconfigchange,
    onCancelClick,
    handleDeleteRequest,
    setDeleteModal,
    deleteModal,
    deleteLoading,
    setSingleData,
    setDeleteId,
    ondropdownchange,
    farmerRowData,
    getLandList,
  } = useYieldEstimationHooks();

  const VendorDetails = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    padding: "5px 10px",
    lineHeight: "60px",
  }));
  const navigate = useNavigate();
  const location = useLocation();
  const permission = usePermissionHooks(location);

  const [loading] = useState(false);

  const column = useMemo(
    () => [
      {
        Header: "Voucher No.",
        accessor: "voucherno",
        filterable: false,
      },
      {
        Header: "Date",
        accessor: "invoiceDate",
        filterable: false,
      },
      {
        Header: "Technician Name",
        accessor: "employeeName",
        filterable: false,
        width: 250,
      },
      {
        Header: "Land No",
        accessor: "landname",
        filterable: false,
      },
      {
        Header: "Total Estimation",
        accessor: "totalestimated",
        filterable: false,
      },
      {
        Header: "Rate",
        accessor: "rate",
        filterable: false,
      },
      {
        Header: "Dakru",
        accessor: "dakru",
        filterable: false,
      },
      {
        Header: "Gariyu",
        accessor: "gariyu",
        filterable: false,
      },
      {
        Header: "Bhukho",
        accessor: "bhukho",
        filterable: false,
      },
      {
        ...GridAction({
          permission,
          onEditClick: (cellProps) => {
            setEditModal(true);
            setSingleData(cellProps.row.original);
          },
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original.id);
          },
        }),
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <PageLayout
      title={"Yield Estimation"}
      loading={loading}
      backBtnTitle={`Back to List`}
      onClick={() => navigate("/yieldestimationlist")}
    >
      <Form onSubmit={handleSubmit(onSubmit)} className="m-4 g-2">
        <Row>
          <Col lg={2} md={4} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Estimation Date"
                format={keys().shortDateFormat}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    variant: "standard",
                  },
                }}
                onChange={onDateChange}
                value={formData?.invoicedate}
                clearable
              />
            </LocalizationProvider>
          </Col>
          <Col lg={2} md={4} sm={12}>
            <FormGroup>
              <TextField
                {...register("voucherno")}
                error={errors?.voucherno}
                id="voucherno"
                name="voucherno"
                value={formData?.voucherno}
                label="Voucher No"
                onChange={handleChange}
                {...textFieldProps}
              />
              <FormHelperText>{`Last Voucher # - ${formData?.lastvoucherno}`}</FormHelperText>
              {errors?.voucherno && (
                <ErrorMessage message={errors?.voucherno?.message} />
              )}
            </FormGroup>
          </Col>
          <Col lg={3} md={4} sm={12}>
            <FormGroup>
              <Autocomplete
                id="technicianId"
                name="technicianId"
                size="small"
                options={employeeDropdownList}
                clearText={true}
                value={formData?.selectedTechnician}
                error={errors?.technicianId}
                onChange={onTechnicianchange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...textFieldProps}
                    label="Technician"
                  />
                )}
              />
              {errors?.technicianId && (
                <ErrorMessage message={errors?.technicianId?.message} />
              )}
            </FormGroup>
          </Col>

          <Col lg={3} md={6} sm={12}>
            <FormGroup>
              <VirtualizeAutoComplete
                id="farmerId"
                name="farmerId"
                size="small"
                options={farmerDropdownList}
                clearText={false}
                value={formData?.selectedFarmer}
                error={errors?.isactive}
                onChange={onFarmerchange}
                renderInput={(params) => (
                  <TextField {...params} {...textFieldProps} label="Farmer" />
                )}
              />
              {errors?.farmerId && (
                <ErrorMessage message={errors?.farmerId?.message} />
              )}
            </FormGroup>
          </Col>

          <Col lg={2} md={4} sm={12}>
            <FormGroup>
              <Autocomplete
                id="landId"
                name="landId"
                size="small"
                options={getLandList}
                clearText={true}
                value={formData?.selectedLand}
                error={errors?.isactive}
                onChange={onLandchange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...textFieldProps}
                    label="Land Number"
                  />
                )}
              />
              {errors?.landId && (
                <ErrorMessage message={errors?.landId?.message} />
              )}
            </FormGroup>
          </Col>

          {formData?.landId > 0 && (
            <Col lg={12} md={12} sm={12} className="mb-2">
              <VendorDetails elevation={2}>
                <Typography variant="h6" gutterBottom component={"div"}>
                  {`${formData?.selectedLand?.landname}`}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {`  Registered land for farming - ${formData?.selectedLand?.plantationlandsm} sq. mt. | ${formData?.selectedLand?.plantationlandbigha} vigha [Product: ${formData?.selectedLand?.productName} ]`}
                </Typography>
              </VendorDetails>
            </Col>
          )}

          {formData?.selectedLand?.yieldconfigurationid === 0 && (
            <Col lg={12} md={12} sm={12} className="mb-2">
              <Paper
                elevation={2}
                className="p-2 alert alert-warning d-flex justify-content-between"
                style={{ backgroundColor: `var(--bs-alert-bg)` }}
              >
                <Typography
                  variant="h6"
                  className="align-self-center"
                  color={"red"}
                >
                  {`Yield estimation Configuration has not been done for this Product.`}
                </Typography>
                <Link
                  href="/yieldestimationconfig"
                  target="_blank"
                  className="custom-btn-page-layout custom-btn-primary py-1 px-3"
                >
                  {" "}
                  {`Click here to configure the estimation`}{" "}
                </Link>
              </Paper>
            </Col>
          )}
          <Col lg={3} md={4} sm={12}>
            <FormGroup>
              <Autocomplete
                id="yieldconfigId"
                name="yieldconfigId"
                size="small"
                options={yieldConfigurationList}
                clearText={true}
                value={formData?.selectedYieldconfig}
                error={errors?.yieldconfigId}
                onChange={onyieldconfigchange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...textFieldProps}
                    label="Yield Configuration"
                  />
                )}
              />
              {errors?.yieldconfigId && (
                <ErrorMessage message={errors?.yieldconfigId?.message} />
              )}
            </FormGroup>
          </Col>

          <Col lg={3} md={4} sm={12}>
            <FormGroup>
              <TextField
                {...register("totalestimated")}
                error={errors?.totalestimated}
                id="totalestimated"
                name="totalestimated"
                value={formData?.totalestimated}
                label="Total Estimated (in Kg.)"
                onChange={handleChange}
                {...textFieldProps}
                color={
                  formData?.totalestimated >
                  formData?.selectedLand?.maxyieldallowed
                    ? "warning"
                    : "primary"
                }
                focused={
                  formData?.totalestimated >
                  formData?.selectedLand?.maxyieldallowed
                }
              />
              {formData?.totalestimated >
                formData?.selectedLand?.maxyieldallowed && (
                <FormHelperText
                  style={{ color: deepOrange[800] }}
                >{`Max value allowed is ${formData?.selectedLand?.maxyieldallowed}`}</FormHelperText>
              )}
              {errors?.totalestimated && (
                <ErrorMessage message={errors?.totalestimated?.message} />
              )}
            </FormGroup>
          </Col>

          {/*<Col lg={3} md={4} sm={12}>
            <FormGroup>
              <TextField
                {...register("rate")}
                error={errors?.rate}
                id="rate"
                name="rate"
                value={formData?.rate}
                label="Rate"
                onChange={handleChange}
                {...textFieldProps}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
              {errors?.rate && <ErrorMessage message={errors?.rate?.message} />}
            </FormGroup>
          </Col>

          <Col lg={3} md={4} sm={12}>
            <Autocomplete
              id="isactive"
              name="isactive"
              size="small"
              options={statusOption}
              clearText={true}
              value={formData?.selStatus}
              error={errors?.isactive}
              onChange={ondropdownchange}
              renderInput={(params) => (
                <TextField {...params} {...textFieldProps} label="Status" />
              )}
            />
            {errors?.isactive && (
              <ErrorMessage message={errors?.isactive?.message} />
            )}
          </Col> */}
        </Row>
        <Row className="mt-2">
          <Col lg={2} md={2} sm={12}>
            <Button
              className="custom-btn-page-layout custom-btn-success"
              fullWidth
              type="submit"
              size="small"
              startIcon={formData.id > 0 ? <Update /> : <Save />}
            >
              {formData.id > 0 ? ` Update` : `  Save`}
            </Button>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <Button
              className="custom-btn-page-layout custom-btn-danger btn btn-sm text-white btn-block"
              type="button"
              onClick={onCancelClick}
              startIcon={<Cancel />}
              fullWidth
              size="small"
            >
              {` Cancel`}
            </Button>
          </Col>
        </Row>
      </Form>

      <Col md={12}>
        <div
          className="d-grid"
          style={{ margin: "20px 20px", border: "1px solid #DEE2E6" }}
        >
          <TableContainer
            columns={column}
            data={farmerRowData}
            isGlobalFilter={true}
            showColumnFilters={true}
            isAddUserList={false}
            tableClass={"table-sm"}
            showPagination={true}
            initialState={{ pageSize: 20, pageIndex: 0 }}
            divClass={"table-responsive"}
          />
        </div>
      </Col>

      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={handleDeleteRequest}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
    </PageLayout>
  );
};

export default YieldEstimation;
