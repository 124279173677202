import { useNavigate } from "react-router-dom";
import PageLayout from "../../Component/PageLayout";
import { Col, Form, Row } from "reactstrap";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Button,
  Divider,
  FormHelperText,
  TextField,
  Typography
} from "@mui/material";
import VirtualizeAutoComplete from "../../common/VirtualizedAutoComplete";
import { useBagIssueHooks } from "./useBagIssueHooks";
import { textFieldProps } from "../../common/commonControlProps";
import { keys } from "../../config/keys";
import { ErrorMessage } from "../../common/Error";
import TableContainer from "../../Component/TableContainer";
import { Cancel, Save } from "@mui/icons-material";
import { green } from "@mui/material/colors";

const IssueBag = () => {
  const navigate = useNavigate();

  const {
    formData,
    handleSubmit,
    onSubmit,
    farmerDropdownList,
    handleChange,
    onFarmerChange,
    onDateChange,
    errors,
    farmerYieldData,
    column,
    bags_in_stock,
    onBagChange,
    bags_issued_to_farmer_columns,
    bag_issue_list_farmer,
    register,
    products,
    onProductChange,
    total_bags_stock,
    estimatedBagsToIssue,
    loading,
    previousbagsissued,
    totalestimatedbagstoissue
  } = useBagIssueHooks();

  return (
    <PageLayout
      title={"Bag Issue to Farmer"}
      loading={loading}
      //addBtn={permission?.create ? true : false}
      backBtnTitle={`BAck to List`}
      onClick={() => navigate("/issuebaglist")}
    >
      <Form onSubmit={handleSubmit(onSubmit)} className="m-4 g-2">
        <Row>
          <Col lg={2} md={4} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Issue Date"
                format={keys().shortDateFormat}
                slotProps={{
                  textField: {
                    ...textFieldProps
                  }
                }}
                onChange={onDateChange}
                value={formData?.issuedate}
                clearable
              />
            </LocalizationProvider>
          </Col>
          <Col lg={2} md={4} sm={12}>
            <TextField
              id="voucherno"
              name="voucherno"
              value={formData?.voucherno}
              label="Voucher No"
              onChange={handleChange}
              disabled
              {...textFieldProps}
            />
            <FormHelperText>{`Last Voucher # - ${formData?.lastvoucherno}`}</FormHelperText>
          </Col>
          <Col lg={5} md={6} sm={12}>
            <VirtualizeAutoComplete
              name="selectedFarmer"
              size="small"
              options={farmerDropdownList}
              clearText={false}
              value={formData?.selectedFarmer}
              onChange={onFarmerChange}
              renderInput={(params) => (
                <TextField
                  {...register("selectedFarmer")}
                  {...params}
                  label="Farmer Code"
                  {...textFieldProps}
                  error={errors?.selectedFarmer}
                />
              )}
            />

            {errors?.selectedFarmer && (
              <ErrorMessage message={errors?.selectedFarmer?.message} />
            )}
          </Col>

          {formData.farmerid > 0 && (
            <Col lg={12} sm={12}>
              <TableContainer
                columns={column}
                data={farmerYieldData}
                isGlobalFilter={false}
                tableClass={"table-sm"}
                showPagination={false}
                showFooter
                initialState={{ pageSize: 20, pageIndex: 0 }}
                divClass={"table-responsive"}
                exports={{ excel: false, pdf: false }}
              />
            </Col>
          )}
        </Row>
        <hr style={{ color: green[800], borderWidth: "2px" }} />
        <Row>
          <Col lg={3} sm={12} md={3}>
            <VirtualizeAutoComplete
              name="productid"
              size="small"
              options={products}
              value={formData.selectedProduct}
              onChange={onProductChange}
              getOptionDisabled={(options) =>
                options.currentstock === null || options.currentstock <= 0
              }
              renderInput={(params) => (
                <TextField {...params} {...textFieldProps} label="Product" />
              )}
            />
          </Col>
          <Col lg={2} sm={12} md={2}>
            <Typography variant="subtitle1">{`Total Bags in Stock: ${total_bags_stock[0]["stock"]}`}</Typography>
          </Col>
          <Col lg={2} sm={12} md={2}>
            <Typography variant="subtitle1">{`Total Bags To Issue: ${totalestimatedbagstoissue}`}</Typography>
          </Col>
          <Col lg={2} sm={12} md={2}>
            <Typography variant="subtitle1">{`Bags Issued: ${previousbagsissued}`}</Typography>
          </Col>
          <Col lg={2} sm={12} md={2}>
            <Typography variant="subtitle1">{`Pending Bags To Issue: ${estimatedBagsToIssue}`}</Typography>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col lg={2} md={2} sm={12}>
            <VirtualizeAutoComplete
              name="bagstartno"
              size="small"
              options={bags_in_stock}
              clearText={false}
              value={formData?.selectedbagno}
              onChange={onBagChange}
              getOptionLabel={(option) => option?.label}
              renderInput={(params) => (
                <TextField
                  {...register("bagstartno")}
                  {...params}
                  label="Bag Start No"
                  error={errors?.bagstartno}
                  {...textFieldProps}
                />
              )}
            />
            {errors?.bagstartno && (
              <ErrorMessage message={errors?.bagstartno?.message} />
            )}
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              {...register("totalbagstoissue")}
              name="totalbagstoissue"
              value={formData?.totalbagstoissue}
              label="No Of Bags to Issue"
              onChange={handleChange}
              {...textFieldProps}
              error={formData.totalbagstoissue > estimatedBagsToIssue}
            />
            {(errors?.totalbagstoissue ||
              formData.totalbagstoissue > estimatedBagsToIssue) && (
              <ErrorMessage
                message={
                  formData.totalbagstoissue > estimatedBagsToIssue
                    ? `No of bags cannot be greater than ${estimatedBagsToIssue}`
                    : errors?.totalbagstoissue?.message
                }
              />
            )}
          </Col>
          <Col lg={7} md={7} sm={12}>
            <TextField
              value={formData.bags_no_to_issue}
              label="Bag Nos. to Issue"
              disabled
              {...textFieldProps}
            />
          </Col>
        </Row>
        <Row className="mt-2 g-2">
          <Col lg={1} md={1} sm={12}>
            <Button
              className="custom-btn-page-layout text-white "
              startIcon={<Save />}
              fullWidth
              type="submit"
            >
              {` Save`}
            </Button>
          </Col>
          <Col lg={1} md={1} sm={12}>
            <Button
              type="button"
              className="custom-btn-page-layout custom-btn-danger "
              onClick={(e) => navigate("/issuebaglist")}
              startIcon={<Cancel />}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
      <Row className="m-2">
        <Col md={12}>
          <div className="d-grid" style={{ border: "1px solid #DEE2E6" }}>
            <TableContainer
              columns={bags_issued_to_farmer_columns}
              data={bag_issue_list_farmer}
              isGlobalFilter={true}
              showColumnFilters={true}
              isAddUserList={false}
              tableClass={"table-sm"}
              showPagination={true}
              initialState={{ pageSize: 20, pageIndex: 0 }}
              divClass={"table-responsive"}
            />
          </div>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default IssueBag;
