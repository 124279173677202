import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { grnValidationSchema } from "./validationSchema";
import dayjs from "dayjs";
import { keys } from "../../config/keys";
import { purchaseActions, vendorActions } from "../../redux/actions";
import { grnActions } from "../../redux/actions";
import { toast } from "react-toastify";
import { usePermissionHooks } from "../../common/useCheckPermission";
import { useLocation } from "react-router-dom";

const initialValues = {
  id: 0,
  voucherno: "",
  voucher: "",
  invoicenumber: "",
  invoicedatestring: dayjs(Date()).format(keys().dbDateFormat),
  invoicedate: dayjs(Date()),
  vendorid: 0,
  selectedvendor: null,
  selectedPO: null,
  gstno: "",
  purchaseOrderId: "",
  podetails: [],
  lastinvoicenumber: "",
};

export const useGRNHooks = (id = 0) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialValues);
  const location = useLocation();
  const [totalReceivedQntyArr, setTotalRecevedQntyArr] = useState([]);

  const {
    vendors,
    purchase: { purchase_order_list },
    grnList,
    grnDetailDataBYpoId,
  } = useSelector((obj) => obj);

  const permission = usePermissionHooks(location);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    // setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(grnValidationSchema),
  });

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const ondropdownchange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      vendorid: selectedOption?.id || 0,
      selectedvendor: selectedOption || null,
    }));
  };

  const getQtyNum = (data, productId) => {
    // this function will check any previous entry of ordered
    const value =
      grnDetailDataBYpoId.length === 0
        ? data
        : grnDetailDataBYpoId.filter((i) => i.productId === productId)[0]
            .pendingQty;

    return value;
  };

  const handleReceivedQtyChange = (e, detail, idx, productId) => {
    let val = e.target.value;
    let det = [...formData.podetails];
    // det[idx]["orderedqty"] = e.target.value || 0;
    det[idx]["receivedQty"] = e.target.value || 0;
    det[idx]["error"] =
      Number(e.target.value) > detail.pendingQty || Number(e.target.value) < 0;
    det[idx]["errorMessage"] = `Received qty should not exceed ordered qty.`;

    setFormData((prev) => ({
      ...prev,
      podetails: det,
    }));
  };

  const getTotalPRoductIdWise = (data, productId, qty) => {
    const getTotal =
      data.length > 0
        ? data.filter((i) => i.productId === productId)[0].pendingQnty
        : qty;

    return getTotal;
  };

  const checkDiavaibility = (data, productId, qty) => {
    // data = grnDetailDataBYpoId;
    const getTotal =
      data.length > 0
        ? data.filter((i) => i.productid === productId)[0].totalreceivedqty
        : 0;

    var flag = parseInt(getTotal) <= parseInt(qty) ? true : false;

    return flag;
  };

  const onpochange = async (e, selectedOption) => {
    if (selectedOption !== null) {
      // const t = selectedOption.productdata
      //   .replace('"[', "[")
      //   .replace(']"', "]");
      await get_grn_detail_data_by_po_id(selectedOption.id, {
        onSuccess: (res) => {
          setFormData((prev) => ({
            ...prev,
            podetails: res,
            purchaseOrderId: selectedOption.id,
            selectedPO: selectedOption,
            voucherno: selectedOption.voucherno,
          }));
        },
      });
    } else {
      setFormData((prev) => ({
        ...prev,
        podetails: [],
        purchaseOrderId: "",
        voucherno: "",
      }));
    }
  };

  const onDateChange = (dateValue) => {
    setFormData((prev) => ({
      ...prev,
      invoicedate: dateValue,
      invoicedatestring: dayjs(dateValue).format("YYYY-MM-DD"),
    }));
  };

  const totalSumCalc = (data, field) => {
    return data.reduce((accumulator, currentValue) => {
      return (
        parseFloat(accumulator) + parseFloat(currentValue[`${field}`] || 0)
      );
    }, 0);
  };

  const resetFormData = () => {
    reset(initialValues);
  };

  const onSubmit = async () => {
    const {
      voucherno,
      invoicedatestring,
      invoicenumber,
      vendorid,
      purchaseOrderId,
      podetails,
      id,
    } = formData;

    // This for transaction query
    const grn_detail_arr = podetails.map((i) => {
      return {
        partNo: i.partNo,
        productName: i.productName,
        productid: i.productid,
        orderedqty: i.qty,
        receivedqty: Number(i.receivedQty),
      };
    });

    const required_API_Data = {
      id,
      vouchernumber: voucherno,
      invoicedate: invoicedatestring,
      invoicenumber: invoicenumber,
      vendorid: vendorid,
      purchaseorderid: purchaseOrderId,
      totalorderqty: totalSumCalc(podetails, "qty"),
      totalreceivedqty: totalSumCalc(podetails, "receivedQty"),
      grn_detail_arr,
    };

    await dispatch(
      grnActions.savegrnData({ ...required_API_Data }, () => {
        toast.success("GRN saved successfully");
      })
    );
    setFormData(initialValues);
  };

  const get_vendor_list = () => dispatch(vendorActions.getVendors());
  const get_po_list = () => dispatch(purchaseActions.getPOList());
  const get_grn_list = () => dispatch(grnActions.getGrnData());
  const get_grn_detail_data_by_po_id = (Id, { onSuccess, onError }) =>
    dispatch(
      grnActions.getGrnDetailDataByPOid(Id, {
        onSuccess: (res) => {
          if (onSuccess) onSuccess(res);
        },
      })
    );

  useEffect(() => {
    get_vendor_list();
    get_po_list();
    get_grn_list();
  }, []);

  useEffect(() => {
    if (id === 0) {
      dispatch(
        grnActions.get_latest_grn_no({
          onSuccess: (res) => {
            if (res.length > 0) {
              setFormData((prev) => ({ ...prev, ...res[0] }));
              reset({ ...formData, ...res[0] });
            }
          },
        })
      );
    } else if (id > 0) {
      dispatch(
        grnActions.get_grn_by_id(id, {
          onSuccess: (res) => {
            setFormData((prev) => ({
              ...prev,
              ...res,
              invoicedatestring: dayjs(res.invoicedate).format(
                keys().dbDateFormat
              ),
              invoicedate: dayjs(res.invoicedate),
              selectedvendor: vendors.find((x) => x.id === res.vendorid),
              purchaseOrderId: res.purchaseorderid,
              selectedPO: purchase_order_list.find(
                (x) => x.id === res.purchaseorderid
              ),
            }));
            reset({ ...res });
          },
        })
      );
    }
  }, [id]);

  const delete_GRN = (id) =>
    dispatch(
      grnActions.delete_grn(
        { id },
        {
          onSuccess: () => {
            toast.success("GRN Deleted Successfully!!!");
            get_grn_list();
          },
          onError: (err) =>
            toast.error("Something went wrong. Please try again."),
        }
      )
    );

  useEffect(() => {
    // console.log(purchase_order_list);
  }, [purchase_order_list]);
  return {
    formData,
    setFormData,
    handleChange,
    initialValues,
    register,
    handleSubmit,
    errors,
    resetFormData,
    control,
    onSubmit,
    onDateChange,
    ondropdownchange,
    vendors,
    purchase_order_list,
    grnList,
    onpochange,
    handleReceivedQtyChange,
    get_grn_list,
    get_grn_detail_data_by_po_id,
    grnDetailDataBYpoId,
    getTotalPRoductIdWise,
    checkDiavaibility,
    permission,
    delete_GRN,
  };
};
