// import { Actions } from ".";
import * as types from "../constTypes";

const initialState = {};

export const grnList = (state = initialState, action) => {
    switch (action.type) {
      case types.grnTypes.GRN_DATA_LIST:
        return action.payload || [];
      default:
        return state;
    }
  };

  export const grnDetailDataBYpoId = (state = initialState, action) => {
    switch (action.type) {
      case types.grnTypes.GRN_DETAIL_DATA_BY_PO_ID:
        return action.payload || [];
      default:
        return state;
    }
  };
