/* eslint-disable no-restricted-globals */
import axios from "../../common/axios";
import { toast } from "react-toastify";
import { MAIN_MODULE_LIST, MODULE_LIST, MODULES_LIST, SUB_MODULE_LIST } from "../constTypes";
import { keys } from "../../config/keys";

const _dispatch_payload = (type, data) => {
  return {
    type,
    payload: data,
  };
};

export const get_main_modules_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/module/main_modules_list`)
    .then((res) => {
      dispatch(_dispatch_payload(MAIN_MODULE_LIST, res.data.rows));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      toast.error(e.toString());
      if (callback?.onError) callback.onError(e.toString());
    });
};

export const save_main_module = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys().ServicePath}/api/module/save_main_module`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      toast.error(e.toString());
      if (callback?.onError) callback.onError(e.toString());
    });
};

export const delete_main_module = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys().ServicePath}/api/module/delete_main_module`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      toast.error(e.toString());
      if (callback?.onError) callback.onError(e.toString());
    });
};


export const get_modules_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/module/modules_list`)
    .then((res) => {
      dispatch(_dispatch_payload(MODULES_LIST, res.data.rows));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      toast.error(e.toString());
      if (callback?.onError) callback.onError(e.toString());
    });
};

export const save_module = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys().ServicePath}/api/module/save_module`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      toast.error(e.toString());
      if (callback?.onError) callback.onError(e.toString());
    });
};

export const delete_module = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys().ServicePath}/api/module/delete_module`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      toast.error(e.toString());
      if (callback?.onError) callback.onError(e.toString());
    });
};

export const get_sub_modules_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/module/sub_modules_list`)
    .then((res) => {
      dispatch(_dispatch_payload(SUB_MODULE_LIST, res.data.rows));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      toast.error(e.toString());
      if (callback?.onError) callback.onError(e.toString());
    });
};

export const save_sub_module = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys().ServicePath}/api/module/save_sub_module`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      toast.error(e.toString());
      if (callback?.onError) callback.onError(e.toString());
    });
};

export const delete_sub_module = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys().ServicePath}/api/module/delete_sub_module`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      toast.error(e.toString());
      if (callback?.onError) callback.onError(e.toString());
    });
};