// import { Actions } from ".";
import * as types from "../constTypes";

const farmer_dropdown_list = [];
const initialState = {};

export const farmerDropdownList = (state = farmer_dropdown_list, action) => {
  switch (action.type) {
    case types.farmerTypes.FARMER_DROPDOWN_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const farmerLandList = (state = initialState, action) => {
  switch (action.type) {
    case types.farmerTypes.FARMER_LAND_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const farmer_list = (state = farmer_dropdown_list, action) => {
  switch (action.type) {
    case types.farmerTypes.FARMER_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const farmer_document_list = (state = farmer_dropdown_list, action) => {
  switch (action.type) {
    case types.farmerTypes.FARMER_DOCUMENT_LIST:
      return [...action.payload];
    default:
      return state;
  }
};

export const farmer_allocation_list = (state = initialState, action) => {
  switch (action.type) {
    case types.farmerTypes.FARMER_ALLOCATION_LIST:
      return action.payload;
    default:
      return state;
  }
};
