import { useEffect, useMemo, useState } from "react";
import {
  commonActions,
  productActions,
  templateActions,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { usePermissionHooks } from "../../common/useCheckPermission";
import Barcode from "react-barcode";
import { useForm } from "react-hook-form";
import { GridAction } from "../../common/useGridAction";

const initialValues = {
  templatename: "",
  templatecontent: null,
  selectedunit: null,
  unitid: 0,
  id: 0,
};

export const useDocumentCreatorHooks = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(initialValues);
  const [showForm, setShowForm] = useState(false);

  const permission = usePermissionHooks(location);
  const { companyunitlist } = useSelector((obj) => obj);

  const handleChange = (e) =>
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

  const handleEditorChange = (editor) =>
    setFormData((prev) => ({
      ...prev,
      templatecontent: editor.getData(),
    }));

  const handleUnitChange = (e, option) => {
    setFormData((prev) => ({
      ...prev,
      selectedunit: option,
      unitid: option?.value || 0,
    }));
    // setSelectedFinancialYear(option)
  };

  const onSubmit = (e) => {
    console.log(formData);
    // const data = {
    //   productName: formData.productname,
    //   partNo: formData.productname,
    //   typeId_id: 1,
    //   companyId_id: 8,
    //   calculationunitId_id: 9,
    //   groupId_id: formData.groupid,
    //   id: formData.id,
    // };

    dispatch(
      templateActions.save_templates(formData, {
        onSuccess: (res) => {
          toast.success("Template created successfully");
        },
        onError: (err) => console.log(err),
      })
    );
  };

  const onCancelClick = () => {
    setFormData((prev) => ({
      ...prev,
      ...initialValues,
    }));
  };

  const get_companyunitList = () =>
    dispatch(commonActions.get_company_unit_list());

  useEffect(() => {
    get_companyunitList();
  }, []);

  useState(() => {
    if (location.state !== null) {
      const un = companyunitlist.find(
        (x) => x.value === location?.state?.unitid
      );
      setFormData((prev) => ({ ...prev, ...location.state, selectedunit: un }));
    }
  }, [location.state]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    // resolver: yupResolver(issueBagValidationSchema)
  });

  const delete_Variety = (id) =>
    dispatch(
      productActions.delete_variety_master(
        { id },
        {
          onSuccess: () => {
            toast.success("Variety Deleted Successfully!!!");
          },
          onError: (err) =>
            toast.error("Something went wrong. Please try again."),
        }
      )
    );

  return {
    permission,
    loading,
    setLoading,
    handleChange,
    handleUnitChange,
    handleEditorChange,
    formData,
    companyunitlist,
    onSubmit,
    handleSubmit,
    onCancelClick,
    delete_Variety,
  };
};

export const useDocumentCreatorListHooks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const permission = usePermissionHooks(location);

  const { templates_list } = useSelector((obj) => obj);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const column = useMemo(
    () => [
      {
        Header: "Template Name",
        accessor: "templatename",
      },
      {
        ...GridAction({
          permission,
          onEditClick: (cellProps) => {
            navigate("/documentcreator", { state: cellProps.row.original });
          },
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original.id);
          },
        }),
      },
    ],
    // eslint-disable-next-line
    []
  );

  const onGridDelete = async () => {
    // setDeleteLoading(true);
    // if (deleteId > 0) {
    //   dispatch(
    //     farmerpurchaseActions.delete_farmer_invoice(
    //       { id: deleteId },
    //       {
    //         onSuccess: () => {
    //           toast.success("Invoice Deleted Successfully!!!");
    //           _invoice_list();
    //         },
    //         onError: (err) =>
    //           toast.error("Something went wrong. Please try again."),
    //       }
    //     )
    //   );
    //   setDeleteLoading(false);
    //   setDeleteModal(false);
    // }
  };

  const _template_list = () =>
    dispatch(
      templateActions.get_templates_list({
        onSuccess: () => setLoading(false),
        onError: () => setLoading(false),
      })
    );

  useEffect(() => {
    //setLoading(true);
    _template_list();
    //checkSerialPort();
  }, []);

  useEffect(() => {
    console.log(loading);
    console.log(permission);
  }, [loading]);

  return {
    templates_list,
    loading,
    deleteModal,
    onGridDelete,
    deleteLoading,
    setDeleteModal,
    column,
    permission,
  };
};
