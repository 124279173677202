export const countryArray = [
  { label: "India", value: "india" },
  { label: "America", value: "america" },
];

export const stateArray = [
  { label: "Gujarat", value: "gujarat" },
  { label: "Rajasthan", value: "rajasthan" },
];

export const villageArray = [
  { label: "Gujarat", value: "gujarat" },
  { label: "Rajasthan", value: "rajasthan" },
];

export const talukaArray = [
  { label: "Gujarat", value: "gujarat" },
  { label: "Rajasthan", value: "rajasthan" },
];

export const distArray = [
  { label: "Gujarat", value: "gujarat" },
  { label: "Rajasthan", value: "rajasthan" },
];

export const cityArray = [
  { label: "Vadodara", value: "vadodara" },
  { label: "Gujarat", value: "gujarat" },
];

export const unitArray = [
  { label: "Unit1", value: "unit" },
  { label: "Unit2", value: "unit" },
];

export const currencyArray = [{ label: "INR", value: "inr" }];

export const departmentArray = [
  { label: "Department1", value: "Department1" },
  { label: "Department2", value: "Department2" },
];

export const moduleData = [
  { id: 1, moduleName: "country", path: "/", icon: "adf" },
  { id: 2, moduleName: "state", path: "/", icon: "adf" },
  { id: 3, moduleName: "city", path: "/", icon: "adf" },
  { id: 4, moduleName: "Taluka", path: "/", icon: "adf" },
  {
    id: 5,
    moduleName: "district",
    path: "/",
    icon: "adf",
  },
  {
    id: 6,
    moduleName: "Farmer",
    icon: "adf",
    subModule: [{ id: 1, moduleName: "district", path: "/", icon: "adf" }],
  },
];

export const underArray = [
  {
    label: "Primary",
    value: "Primary",
  },
];
export const gstApplicableArray = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];
export const alterGstDetailArray = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

export const calculationTypeArray = [
  {
    label: "On Value",
    value: "On Value",
  },
  {
    label: "On Item Rate",
    value: "On Item Rate",
  },
];
export const taxabilityArray = [
  {
    label: "Exempt",
    value: "Exempt",
  },
  {
    label: "Nil Rated",
    value: "Nil Rated",
  },
  {
    label: "Non-GST",
    value: "Non-GST",
  },
  {
    label: "Taxable",
    value: "Taxable",
  },
];
export const coastingMethodArray = [
  {
    label: "At Zero Cost",
    value: "At Zero Cost",
  },
  {
    label: "Avg Cost",
    value: "Avg Cost",
  },
  {
    label: "FIFO",
    value: "FIFO",
  },
  {
    label: "FIFO Perpetual",
    value: "FIFO Perpetual",
  },
  {
    label: "Last Purchase Cos",
    value: "Last Purchase Cos",
  },
  {
    label: "LIFO Annual",
    value: "LIFO Annual",
  },
  {
    label: "LIFO Perpetual",
    value: "LIFO Perpetual",
  },
  {
    label: "Monthly Avg Cost",
    value: "Monthly Avg Cost",
  },
  {
    label: "Std. Cost",
    value: "Std. Cost",
  },
];
export const marketValuationArray = [
  {
    label: "At Zero Price",
    value: "At Zero Price",
  },
  {
    label: "Avg Price",
    value: "Avg Price",
  },
  {
    label: "Last Sales Price",
    value: "Last Sales Price",
  },
  {
    label: "Std Price",
    value: "Std Price",
  },
];
export const provideBehaviorArray = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];
export const alterMrpDetailsArray = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

export const vendorTypeArray = [
  {
    label: "Raw Material",
    value: "Raw Material",
  },
  {
    label: "Packing Material",
    value: "Packing Material",
  },
  {
    label: "Finish Product",
    value: "Finish Product",
  },
  {
    label: "Fixed Assets",
    value: "Fixed Assets",
  },
  {
    label: "Farmer Products",
    value: "FP",
  },
];

export const typeArray = [
  { label: "Compound", value: "Compound" },
  { label: "Simple", value: "Simple" },
];

export const registrationTypeArray = [
  {
    label: "Composition",
    value: "Composition",
  },
  {
    label: "Regular",
    value: "Regular",
  },
  {
    label: "Unregistered/Consumer",
    value: "Unregistered/Consumer",
  },
  {
    label: "Government Entity/TDS",
    value: "Government Entity/TDS",
  },
  {
    label: "Regular-SEZ",
    value: "Regular-SEZ",
  },
  {
    label: "Regular-Deemed Exporter",
    value: "Regular-Deemed Exporter",
  },
  {
    label: "Regular-Exports(EOU)",
    value: "Regular-Exports(EOU)",
  },
  {
    label: "E-Commerce Operator",
    value: "E-Commerce Operator",
  },
  {
    label: "Input Service Distributor",
    value: "Input Service Distributor",
  },
  {
    label: "Embassy/UN Body",
    value: "Embassy/UN Body",
  },
  {
    label: "Non-Resident Taxpayer",
    value: "Non-Resident Taxpayer",
  },
];
export const transactionTypeArray = [
  {
    label: "ATM",
    value: "ATM",
  },
  {
    label: "Card",
    value: "Card",
  },
  {
    label: "Cheque",
    value: "Cheque",
  },
  {
    label: "ECS",
    value: "ECS",
  },
  {
    label: "E-Fund Transfer",
    value: "E-Fund Transfer",
  },
  {
    label: "Electronic Cheque",
    value: "Electronic Cheque",
  },
  {
    label: "Electronic DD/PO",
    value: "Electronic DD/PO",
  },
  {
    label: "Others",
    value: "Others",
  },
];

export const typeOfSupplyArray = [
  {
    label: "Goods",
    value: "Goods",
  },
  {
    label: "Services",
    value: "Services",
  },
];

export const uqcArray = [
  {
    label: "BAG-BAGS",
    value: "BAG-BAGS",
  },
  {
    label: "BAL-BALE",
    value: "BAL-BALE",
  },
  {
    label: "BDL-BUNDLES",
    value: "BDL-BUNDLES",
  },
  {
    label: "BKL-BUCKLES",
    value: "BKL-BUCKLES",
  },
  {
    label: "BOU-BILLIONOFUNITS",
    value: "BOU-BILLIONOFUNITS",
  },
  {
    label: "BOX-BOX",
    value: "BOX-BOX",
  },
  {
    label: "BTL-BOTTLES",
    value: "BTL-BOTTLES",
  },
  {
    label: "BUN-BUNCHES",
    value: "BUN-BUNCHES",
  },
  {
    label: "CAN-CANS",
    value: "CAN-CANS",
  },
  {
    label: "CBM-CUBIC METERS",
    value: "CBM-CUBIC METERS",
  },
  {
    label: "CCM-CUBIC CENTIMETERS",
    value: "CCM-CUBIC CENTIMETERS",
  },
  {
    label: "CMS-CENTIMETERS",
    value: "CMS-CENTIMETERS",
  },
  {
    label: "CTN-CARTONS",
    value: "CTN-CARTONS",
  },
  {
    label: "DOZ-DOZENS",
    value: "DOZ-DOZENS",
  },
  {
    label: "DRM-DRUMS",
    value: "DRM-DRUMS",
  },
  {
    label: "GGK-GREATGROSS",
    value: "GGK-GREATGROSS",
  },
  {
    label: "GMS-GRAMMES",
    value: "GMS-GRAMMES",
  },
  {
    label: "GRS-GROSS",
    value: "GRS-GROSS",
  },
  {
    label: "GYD-GROSSYARDS",
    value: "GYD-GROSSYARDS",
  },
  {
    label: "KGS-KILOGRAMS",
    value: "KGS-KILOGRAMS",
  },
  {
    label: "KLR-KILOLITRE",
    value: "KLR-KILOLITRE",
  },
  {
    label: "KME-KILOMETRE",
    value: "KME-KILOMETRE",
  },
  {
    label: "LTR-LITRES",
    value: "LTR-LITRES",
  },
  {
    label: "MLT-MILLILITER",
    value: "MLT-MILLILITER",
  },
  {
    label: "MTR-METERS",
    value: "MTR-METERS",
  },
  {
    label: "MTS-METRIC TON",
    value: "MTS-METRIC TON",
  },
  {
    label: "NOS-NUMBERS",
    value: "NOS-NUMBERS",
  },
  {
    label: "PAC-PACKS",
    value: "PAC-PACKS",
  },
  {
    label: "PCS-PIECES",
    value: "PCS-PIECES",
  },
  {
    label: "PRS-PAIRS",
    value: "PRS-PAIRS",
  },
  {
    label: "QTL-QUINTAL",
    value: "QTL-QUINTAL",
  },
  {
    label: "ROL-ROLLS",
    value: "ROL-ROLLS",
  },
  {
    label: "SET-SETS",
    value: "SET-SETS",
  },
  {
    label: "SQF-SQUARE FEET",
    value: "SQF-SQUARE FEET",
  },
  {
    label: "SQM-SQUAREMETERS",
    value: "SQM-SQUAREMETERS",
  },
  {
    label: "SQY-SQUAREYARDS",
    value: "SQY-SQUAREYARDS",
  },
  {
    label: "TBS-TABLETS",
    value: "TBS-TABLETS",
  },
  {
    label: "TGM-TENGROSS",
    value: "TGM-TENGROSS",
  },
  {
    label: "THE-THOUSANDS",
    value: "THE-THOUSANDS",
  },
  {
    label: "TON-TONNES",
    value: "TON-TONNES",
  },
  {
    label: "TUB-TUBES",
    value: "TUB-TUBES",
  },
  {
    label: "UGS-US GALLONS",
    value: "UGS-US GALLONS",
  },
  {
    label: "UNT-UNITS",
    value: "UNT-UNITS",
  },
  {
    label: "YDS-YARDS",
    value: "YDS-YARDS",
  },
  {
    label: "OTH-OTHERS",
    value: "OTH-OTHERS",
  },
  {
    label: "Vigha",
    value: "Vigha",
  },
];

export const vendorGroupArray = [
  {
    label: "Branch / Divisions",
    value: "Branch / Divisions",
  },
  {
    label: "Capital Account",
    value: "Capital Account",
  },
  {
    label: "Reserves & Surplus",
    value: "Reserves & Surplus",
  },
  {
    label: "Current Assets",
    value: "Current Assets",
  },
  {
    label: "Bank Accounts",
    value: "Bank Accounts",
  },
  {
    label: "Cash-in-Hand",
    value: "Cash-in-Hand",
  },
  {
    label: "Deposits (Asset)",
    value: "Deposits (Asset)",
  },
  {
    label: "Loans & Advances (Asset)",
    value: "Loans & Advances (Asset)",
  },
  {
    label: "Stock-in-Hand",
    value: "Stock-in-Hand",
  },
  {
    label: "Sundry Debtors",
    value: "Sundry Debtors",
  },
  {
    label: "Current Liabilities",
    value: "Current Liabilities",
  },
  {
    label: "Duties & Taxes",
    value: "Duties & Taxes",
  },
  {
    label: "Provisions",
    value: "Provisions",
  },
  {
    label: "Sundry Creditors",
    value: "Sundry Creditors",
  },
  {
    label: "Direct Expenses",
    value: "Direct Expenses",
  },
  {
    label: "Direct Incomes",
    value: "Direct Incomes",
  },
  {
    label: "Fixed Assets",
    value: "Fixed Assets",
  },
  {
    label: "Indirect Expenses",
    value: "Indirect Expenses",
  },
  {
    label: "Indirect Incomes",
    value: "Indirect Incomes",
  },
  {
    label: "Investments",
    value: "Investments",
  },
  {
    label: "Loans (Liability)",
    value: "Loans (Liability)",
  },
  {
    label: "Bank OD A/c",
    value: "Bank OD A/c",
  },
  {
    label: "Secured Loans",
    value: "Secured Loans",
  },
  {
    label: "Unsecured Loans",
    value: "Unsecured Loans",
  },
  {
    label: "Misc. Expenses (ASSET)",
    value: "Misc. Expenses (ASSET)",
  },
  {
    label: "Purchase Accounts",
    value: "Purchase Accounts",
  },
  {
    label: "Sales Accounts",
    value: "Sales Accounts",
  },
  {
    label: "Suspense A/c",
    value: "Suspense A/c",
  },
];

export const alphabetArray = [
  { label: "a" },
  { label: "b" },
  { label: "c" },
  { label: "d" },
  { label: "e" },
  { label: "f" },
  { label: "g" },
  { label: "h" },
  { label: "i" },
  { label: "j" },
  { label: "k" },
  { label: "l" },
  { label: "m" },
  { label: "n" },
  { label: "o" },
  { label: "p" },
  { label: "q" },
  { label: "r" },
  { label: "s" },
  { label: "t" },
  { label: "u" },
  { label: "v" },
  { label: "w" },
  { label: "x" },
  { label: "y" },
  { label: "z" },
];

export const statusOption = [
  {label:'Active', value:1},
  {label:'Inactive', value:0}
];