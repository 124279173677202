import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { keys } from "../../config/keys";
import { get_farmer_by_purchases } from "../../redux/actions/farmer";
import { useForm } from "react-hook-form";
import { invoiceValidationSchema } from "./validationSchema";
import { commonActions, farmerpurchaseActions } from "../../redux/actions";
import { yupResolver } from "@hookform/resolvers/yup";
import { useReactToPrint } from "react-to-print";
import { insert_invoice } from "../../redux/actions/farmerpurchase";

const initialValues = {
  voucherdatestring: dayjs(Date()).format(keys().dbDateFormat),
  voucherdate: dayjs(Date()),
  voucherno: "",
  lastvoucherno: "",
  selectedFarmer: null,
  farmerid: 0,
  purchases: [],
  transportation: 0,
  unaccountedbags: 0,
  unaccountedbagsamount: 0,
  subtotal: 0,
  grandtotal: 0,
  totalqtymaan: 0,
  pricepermaan: 0,
  totalbigha: 0,
  netweight: 0,
  incentives: [],
  incentivetotal: 0,
  totaltransportation: 0,
};

export const useInvoiceHooks = () => {
  const dispatch = useDispatch();
  const invoiceComponentRef = useRef();
  const {
    farmerDropdownList,
    purchases_from_farmer,
    farmer_incentives,
    farmer_transportation,
    farmer_unaccountbags,
  } = useSelector((obj) => obj);

  const [formData, setFormData] = useState(initialValues);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(invoiceValidationSchema),
  });

  const get_latest_voucher = () =>
    dispatch(
      commonActions.get_last_latest_voucherno("invoice_master", {
        onSuccess: (res) => {
          if (res.length > 0) {
            setFormData((prev) => ({ ...prev, ...res[0] }));
          }
        },
      })
    );

  const _get_purchases_from_farmers = (farmerid) =>
    dispatch(farmerpurchaseActions.get_purchases_from_farmer(farmerid));

  const _get_farmers_incentives = (farmerid) =>
    dispatch(farmerpurchaseActions.get_farmer_incentives(farmerid));

  const farmer_dropdown_list = () =>
    dispatch(
      get_farmer_by_purchases({
        onSuccess: () => {},
        onError: () => {},
      })
    );

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onDateChange = (dateValue) => {
    setFormData((prev) => ({
      ...prev,
      purchasedate: dateValue,
      purchasedatestring: dayjs(dateValue).format("YYYY-MM-DD"),
    }));
  };

  const onFarmerChange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      farmerid: selectedOption?.id || 0,
      selectedFarmer: selectedOption || null,
      totalbigha: selectedOption?.totalbigha || 0,
    }));

    if (selectedOption !== null) {
      _get_purchases_from_farmers(selectedOption.id);
      _get_farmers_incentives(selectedOption.id);
    } else {
      _get_purchases_from_farmers(0);
      _get_farmers_incentives(0);
    }
  };

  useEffect(() => {
    get_latest_voucher();
    farmer_dropdown_list();
  }, []);

  //   useEffect(()=>{
  // console.log(formData.incentives)
  //   },[formData.incentives])

  useEffect(() => {
    if (purchases_from_farmer.length > 0) {
      const uniq = [
        ...new Set(purchases_from_farmer.map((x) => x.producttypeid)),
      ];
      const d = [];
      uniq.forEach((item) => {
        const temp = {
          type: item,
          data: purchases_from_farmer.filter((x) => x.producttypeid === item),
        };
        d.push(temp);
      });

      const subtotal =
        purchases_from_farmer.reduce((sum, row) => row.amount + sum, 0) || 0;
      const netweight =
        purchases_from_farmer.reduce((sum, row) => row.netweight + sum, 0) || 0;

      const qtymaan = (netweight / 20).toFixed(2);
      const pricepermaan = (subtotal / qtymaan).toFixed(2);
      setFormData((prev) => ({
        ...prev,
        purchases: d,
        subtotal,
        grandtotal: subtotal,
        totalqtymaan: qtymaan,
        pricepermaan,
        netweight,
      }));
    }
  }, [purchases_from_farmer]);

  useEffect(() => {
    if (farmer_transportation.length > 0) {
      setFormData((prev) => ({
        ...prev,
        ...farmer_transportation[0],
      }));
    }
  }, [farmer_transportation]);

  useEffect(() => {
    if (farmer_unaccountbags.length > 0) {
      setFormData((prev) => ({
        ...prev,
        ...farmer_unaccountbags[0],
      }));
    }
  }, [farmer_unaccountbags]);

  useEffect(() => {
    if (farmer_incentives.length > 0) {
      const temp = [];

      //  console.log(formData);
      const d = farmer_incentives.map((item) => {
        let incentiveamount = 0;
        if (item.incenid > 0) {
          if (Boolean(item.pervigha)) {
            if (item.incentivetypeid === 4)
              incentiveamount = item.rate * item.drip;
            else if (item.incentivetypeid === 5)
              incentiveamount = item.rate * item.solar;
            else if (item.incentivetypeid === 6)
              incentiveamount = item.rate * item.gmanuer;
            else if (item.incentivetypeid === 10) {
              if (item.desisinglecrop > 0) {
                const sg = Number(item.desisinglecrop);
                const pervighaweight = formData.netweight / formData.totalbigha;
                const dripweight = sg * pervighaweight;
                incentiveamount = item.rate * dripweight;
              } else incentiveamount = 0;
            } else incentiveamount = item.rate * formData.totalbigha;
          } else if (Boolean(item.perkg)) {
            incentiveamount = item.rate * formData.netweight;
          } else {
            incentiveamount = item.rate;
          }
        }

        const d = {
          ...item,
          incentiveamount: Number(incentiveamount).toFixed(2),
        };
        // console.log("incentives", temp);
        return temp.push(d);
      });

      setFormData((prev) => ({ ...prev, incentives: temp }));
    }
  }, [farmer_incentives, formData.netweight]);

  useEffect(() => {
    const incentivetotal =
      formData.incentives.reduce(
        (sum, row) => parseFloat(row.incentiveamount) + parseFloat(sum),
        0
      ) || 0;
    // console.log("netweight", formData.netweight);
    const totaltransportation = (
      formData.transportation * formData.netweight
    ).toFixed(2);
    const grandtotal =
      parseFloat(totaltransportation) +
      parseFloat(formData.subtotal) +
      parseFloat(incentivetotal) -
      parseFloat(formData.unaccountedbagsamount);

    const forPerMann =
      parseFloat(totaltransportation) +
      parseFloat(formData.subtotal) +
      parseFloat(incentivetotal);
    const pricepermaan = (forPerMann / formData.totalqtymaan).toFixed(2);

    // console.log(incentivetotal)
    setFormData((prev) => ({
      ...prev,
      grandtotal: grandtotal.toFixed(2),
      pricepermaan,
      incentivetotal,
      totaltransportation,
    }));
  }, [
    formData.transportation,
    formData.subtotal,
    formData.unaccountedbagsamount,
    formData.netweight,
    formData.incentives,
  ]);

  const calculateincentive = (item) => {
    return 0;
    // let incentiveamount = 0;
    // if (incenid > 0) {
    //   if (Boolean(item.pervigha)) {
    //     incentiveamount = item.rate * formData.totalbigha;
    //   } else if (Boolean(item.perkg)) {
    //     incentiveamount = item.rate * formData.netweight;
    //   } else {
    //     incentiveamount = item.rate;
    //   }
    // }
    // const inc = [...formData.incentives];
    // inc.push({ ...item, incentiveamount });
    // setFormData((prev) => ({ ...prev, incentives: inc }));
  };

  const onSubmit = () => {
    console.log(formData);
    dispatch(
      farmerpurchaseActions.insert_invoice(formData, {
        onSuccess: (res) => {
          setFormData(initialValues);
          get_latest_voucher();
        },
      })
    );
  };

  const onSubmitandPrint = () => {
    dispatch(
      farmerpurchaseActions.insert_invoice(formData, {
        onSuccess: (res) => {
          onPrintInvoice();
          get_latest_voucher();
          setFormData(initialValues);
        },
      })
    );
  };

  const onPrintInvoice = useReactToPrint({
    content: () => invoiceComponentRef.current,
  });

  const onCancelClick = () => {
    console.log("test");

    setFormData({ ...initialValues });
    onFarmerChange(null, null);
  };
  return {
    lists: {
      farmerDropdownList,
      purchases_from_farmer,
      farmer_incentives,
      farmer_transportation,
      farmer_unaccountbags,
    },
    state: { formData, invoiceComponentRef },
    form: { register, handleSubmit, errors, onSubmit },
    actions: {
      handleChange,
      onDateChange,
      onFarmerChange,
      calculateincentive,
      onSubmitandPrint,
      onCancelClick,
    },
  };
};
