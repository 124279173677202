import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { farmerActions, farmerpriceActions } from "../../redux/actions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ngfarmerPricetValidationSchema } from "./validationSchema";
import { GridAction } from "../../common/useGridAction";

const initialValues = {
  id: 0,
  selectedfarmer: null,
  farmerid: 0,
  overallprice: 0,
  avgprice: 0,
  sample1price: 0,
  sample2price: 0,
  sample3price: 0,
};
export const useNGFarmerPriceHooks = () => {
  const dispatch = useDispatch();

  const { farmerDropdownList, farmer_price_list } = useSelector((obj) => obj);

  const [formData, setFormData] = useState(initialValues);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(ngfarmerPricetValidationSchema),
  });

  const onfarmerdropdownchange = (e, selectedOption, id_name, value_name) => {
    setFormData((prev) => ({
      ...prev,
      [id_name]: selectedOption?.id || 0,
      [value_name]: selectedOption || null,
    }));
  };

  const onInputChange = (e) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const get_farmerDropdownList = () =>
    dispatch(farmerActions.getFarmerDropdownList());

  const get_farmer_price_list = () =>
    dispatch(farmerpriceActions.get_ng_farmer_price());

  const onSubmit = () => {
    console.log(formData);
    dispatch(
      farmerpriceActions.save(formData, {
        onSuccess: (res) => {
          get_farmer_price_list();
          setFormData(initialValues);
        },
      })
    );
  };

  const onCancel = () => {
    setFormData(initialValues);
  };

  useEffect(() => {
    get_farmerDropdownList();
    get_farmer_price_list();
  }, []);

  useEffect(() => {
    //console.log(farmer_price_list);
    if (formData.id > 0) {
      const selectedfarmer = farmerDropdownList.find(
        (x) => x.id === formData.farmerid
      );
      setFormData((prev) => ({ ...prev, selectedfarmer }));
    }
  }, [formData.farmerid, formData.id]);

  const columns = useMemo(
    () => [
      {
        Header: "Farmer Code",
        accessor: "farmerCode",
      },
      {
        Header: "Farmer",
        accessor: "farmer",
      },
      {
        Header: "Overall Price",
        accessor: "overallprice",
      },
      {
        Header: "Average Price",
        accessor: "avgprice",
      },
      {
        Header: "Sample 1 Price",
        accessor: "sample1price",
      },
      {
        Header: "Sample 2 Price",
        accessor: "sample2price",
      },
      {
        Header: "Sample 3 Price",
        accessor: "sample3price",
      },
      {
        ...GridAction({
          permission: { edit: true, delete: true },
          onEditClick: (cellProps) => {
            setFormData({
              ...cellProps.row.original,
            });
          },
          // onDeleteClick: (cellProps) => {
          //   setDeleteModal(true);
          //   setDeleteId(cellProps.row.original.id);
          // },
        }),
      },
    ],
    []
  );

  return {
    list: { farmerDropdownList, farmer_price_list },
    action: { onfarmerdropdownchange, onSubmit, onInputChange, onCancel },
    state: { formData, columns },
    form: { register, errors, handleSubmit },
  };
};
