import React, {useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Modal,
  Label,
  Input,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import { ClipLoader } from "react-spinners";

const SolarIncentive = ({
  inputData,
  setInputData,
  setShowModal,
  getConfigListAPi,
}) => {
  const { token } = useSelector((state) => state.layout);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  let todayDate = new Date();

  const [modalState, setModalState] = useState(true);
  const [fieldData, setFieldData] = useState({
    requiredPhoto: false,
    rate: "",
  });
  const [fieldError, setFieldError] = useState({
    rate: false,
  });

  const handleChangeInput = (name, value) => {
    if (name === "rate") {
      setFieldData({ ...fieldData, [name]: value });
      setFieldError({ ...fieldError, rate: value.length < 0 });
    } else {
      setFieldData({ ...fieldData, [name]: value });
    }
  };

  const onCloseModal = () => {
    setModalState(!modalState);
    setInputData({
      schemeName: "",
      schemeType: "",
      applicableDate: new Date(),
    });
    setFieldData({
      requiredPhoto: false,
      rate: "",
    });
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!fieldData?.rate) {
      toast?.error("Rate is Required!");
      setFieldError({ ...fieldError, rate: true });
    } else {
      setSubmitLoading(true);
      let formData = new FormData();

      formData.append("schemeTypeId", inputData?.schemeType?.value);
      formData.append("schemeName", inputData?.schemeName);
      formData.append("applicableFrom", inputData?.applicableDate);
      formData.append("photo", fieldData?.requiredPhoto ? "True" : "False");
      formData.append("rate", fieldData?.rate);
      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/addincentiveconfiguration`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setSubmitLoading(false);
          setModalState(!modalState);
          setInputData({
            schemeName: "",
            schemeType: "",
            applicableDate:
              todayDate.getFullYear() +
              "-" +
              todayDate.getMonth() +
              "-" +
              todayDate.getDate(),
          });
          setFieldData({
            requiredPhoto: false,
            rate: "",
          });
          setShowModal(false);
          getConfigListAPi();
        } else {
          setSubmitLoading(false);

          toast?.error(_data?.message);
        }
      } catch (error) {
        setSubmitLoading(false);

        toast?.error(error);
        console.log(error);
      }
    }
  };

  return (
    <Modal
      id="showModal"
      size="md"
      isOpen={modalState}
      toggle={() => onCloseModal()}
      centered
    >
      <form
      //   onSubmit={(e) => handleSubmit(e)}
      >
        <ModalHeader
          className="p-3 "
          style={{ color: "#1B5E0A", textTransform: "uppercase" }}
          toggle={() => onCloseModal()}
        >
          Solar Incentive Scheme
        </ModalHeader>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center w-100">
            <ClipLoader color="#75D100" size={50} />
          </div>
        ) : (
          <ModalBody className="p-3">
            <Row className="">
              <Col
                lg={12}
                md={12}
                sm={12}
                className="d-flex justify-content-center align-items-center"
              >
                <Col md={4}>
                  <Label
                    style={{
                      fontWeight: 600,

                      paddingRight: 10,
                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Photo of Uses Required{" "}
                    <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={8} className="align-items-center d-flex">
                  <FormGroup switch>
                    <Input
                      type="switch"
                      style={{ height: 24, width: 44 }}
                      checked={fieldData?.requiredPhoto}
                      name="requiredPhoto"
                      onChange={(e) => {
                        handleChangeInput(e.target.name, e.target.checked);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                lg={12}
                md={12}
                sm={12}
                className="d-flex justify-content-center align-items-center"
              >
                <Col md={4}>
                  <Label
                    style={{
                      fontWeight: 600,

                      paddingRight: 10,
                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Rate <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={8} className="align-items-center d-flex">
                  <Input
                    type="number"
                    checked={fieldData?.rate}
                    name="rate"
                    placeholder="Rate"
                    className={`cust-input ${
                      fieldError?.rate ? "border-danger" : ""
                    }`}
                    onChange={(e) => {
                      handleChangeInput(e.target.name, e.target.value);
                    }}
                  />
                </Col>
              </Col>
            </Row>
            <div className="d-flex justify-content-end align-items-end mt-5">
              <button
                type="button"
                className="custom-btn-page-layout btn text-white mx-4 "
                onClick={(e) => handleSubmit(e)}
                disabled={submitLoading}
              >
                {submitLoading ? (
                  <ClipLoader color="#75D100" size={25} />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </ModalBody>
        )}
      </form>
    </Modal>
  );
};

export default SolarIncentive;
