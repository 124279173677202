import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageLayout from "../../Component/PageLayout";
import {
  get_farmer_purchase_by_id,
  update_farmer_intransit,
} from "../../redux/actions/farmerpurchase";
import { Grid, Typography } from "@mui/material";
import { keys } from "../../config/keys";

const ScanResult = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});
  const [status, setStatus] = useState({ success: false, message: "" });

  const _purchase_by_id = (id) =>
    dispatch(
      get_farmer_purchase_by_id(id, {
        onSuccess: (res) => {
          if (res?.data?.rows[1].length > 0) {
            const data = res?.data?.rows[1][0];
            // console.log(data);
            setFormData((prev) => ({
              ...prev,
              ...data,
              purchasedate: keys().shortDate(data.purchasedate),
              purchasedetails: res?.data?.rows[2],
            }));
            console.log("success");
            dispatch(
              update_farmer_intransit(
                { id },
                {
                  onSuccess: () =>
                    setStatus({ success: true, message: "SUCCESS" }),
                  onError: () =>
                    setStatus({
                      success: false,
                      message: "FAILURE, TRY AGAIN",
                    }),
                }
              )
            );
          }
        },
        onError: () => {},
      })
    );

  useEffect(() => {
    if (params?.voucherno) {
      _purchase_by_id(params?.voucherno);
    }
  }, []);

  return (
    <PageLayout title="Gate Entry">
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid item md={4} sm={12} lg={4} gap={2} className="w-100" margin={2}>
          <table className="table table-sm table-bordered w-100">
            <tbody>
              <tr>
                <th>{`Voucher #`}</th>
                <td>{formData?.voucherno}</td>
              </tr>
              <tr>
                <th>{`Farmer Code`}</th>
                <td>{formData?.farmerCode}</td>
              </tr>
              <tr>
                <th>{`Farmer Name`}</th>
                <td>{formData?.name}</td>
              </tr>
              <tr>
                <th>{`Vehicle No`}</th>
                <td>{formData?.vehicleno}</td>
              </tr>
              <tr>
                <th>{`Status`}</th>
                <td>
                  <Typography
                    variant="button"
                    className={`fw-bold ${
                      status.success ? `text-success` : `text-danger`
                    }`}
                  >
                    {status.message}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default ScanResult;
