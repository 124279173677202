import { useEffect, useMemo, useState } from "react";
import { productActions } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { usePermissionHooks } from "../../common/useCheckPermission";
import Barcode from "react-barcode";
import { useForm } from "react-hook-form";
import { orange, purple } from "@mui/material/colors";
import { GridAction } from "../../common/useGridAction";

const initialValues = {
  productname: "",
  selectedgroup: null,
  ratePerUnit: 0,
  groupid: 0,
  id: 0,
};

export const useVarietyMasterHooks = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(initialValues);
  const [showForm, setShowForm] = useState(false);
  const [bagBarcodedetails, setBagBarcodeDetails] = useState([]);
  const { products, bags_list, product_group } = useSelector((obj) => obj);

  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const permission = usePermissionHooks(location);

  const onDropDownChange = (e, selectedOption) => {
    if (selectedOption === null) {
      setFormData((prev) => ({ ...prev, selectedgroup: null, groupid: 0 }));
    } else {
      setFormData((prev) => ({
        ...prev,
        selectedgroup: selectedOption,
        groupid: selectedOption?.id,
      }));
    }
  };

  const handleChange = (e) =>
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

  const get_bags_barcode_details = (id) => {
    dispatch(
      productActions.get_bags_barcode_details(id, {
        onSuccess: (res) => {
          setBagBarcodeDetails(res);
          setFormData((prev) => ({
            ...prev,
            startno: res[0].lastbagno + 1,
            totalbags: res[0].pendingbarcodetogenerate,
          }));
        },
      })
    );
  };

  const get_products = () =>
    dispatch(
      productActions.get_farming_product_list({
        onSuccess: (res) => {
          setLoading(false);
        },
        onError: (err) => toast.error(err),
      })
    );

  useEffect(() => {
    dispatch(
      productActions.get_plant_group_category({
        onSuccess: (res) => {
          setLoading(false);
        },
        onError: (err) => toast.error(err),
      })
    );
    get_products();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {

    if (formData?.groupid > 0 && formData.id > 0) {
      let selectedgroup = product_group.find(
        (x) => x.value === formData?.groupid
      );
      setFormData((prev) => ({
        ...prev,
        selectedgroup,
      }));
    }
  }, [formData.groupid]);

  const onSubmit = (e) => {
    const data = {
      productName: formData.productname,
      partNo: formData.productname,
      typeId_id: 1,
      companyId_id: 8,
      calculationunitId_id: 9,
      groupId_id: formData.groupid,
      id: formData.id,
      ratePerUnit: formData.ratePerUnit
    };

    dispatch(
      productActions.save_variety_master(data, {
        onSuccess: (res) => {
          toast.success("Variety created successfully");
          get_products();
        },
        onError: (err) => console.log(err),
      })
    );
  };

  const onCancelClick = () => {
    setFormData((prev) => ({
      ...prev,
      ...initialValues,
    }));
  };

  const barlistColumn = useMemo(
    () => [
      {
        Header: "Product",
        accessor: "productName",
      },
      {
        Header: "Bag #",
        accessor: "bagno",
      },

      {
        Header: "Barcode",
        accessor: "barcodetext",
        Cell: (props) => (
          <Barcode
            value={props.value}
            height={30}
            displayValue={false}
            format={"CODE128"}
            fontSize={10}
          />
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );

  const editClick = (cellProps) => {
    const { id, groupid, productName, ratePerUnit } = cellProps.row.original;
    let selectedgroup = product_group.find((x) => x.value === groupid);
    setFormData((prev) => ({
      ...prev,
      selectedgroup,
      groupid,
      id,
      ratePerUnit,
      productname: productName,
    }));
  };

  const column = useMemo(
    () => [
      {
        Header: "Variety",
        accessor: "groupName",
      },
      {
        Header: "Sub Variety",
        accessor: "productName",
      },
      {
        Header: "Base Price",
        accessor: "ratePerUnit",
      },
      {
        ...GridAction({
          permission: { edit: true, delete: true },
          onEditClick: (cellProps) => {
            editClick(cellProps);
            // navigate("/issuebag", { state: cellProps.row.original });
          },
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original.id);
          },
        }),
      },

      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    // resolver: yupResolver(issueBagValidationSchema)
  });

  const delete_Variety = (id) =>
    dispatch(
      productActions.delete_variety_master(
        { id },
        {
          onSuccess: () => {
            toast.success("Variety Deleted Successfully!!!");
            get_products();
          },
          onError: (err) =>
            toast.error("Something went wrong. Please try again."),
        }
      )
    );

  return {
    products,
    product_group,
    bags: bags_list,
    permission,
    loading,
    setLoading,
    onDropDownChange,
    handleChange,
    formData,
    bagBarcodedetails,
    onSubmit,
    handleSubmit,
    barlistColumn,
    onCancelClick,
    setShowForm,
    showForm,
    column,
    delete_Variety,
    setDeleteId,
    setDeleteModal,
    setDeleteLoading,
    deleteId,
    deleteModal,
    deleteLoading,
  };
};
