import axios from "../../common/axios";
import { toast } from "react-toastify";
import { keys } from "../../config/keys";
import {
  COUNTRY_LIST,
  DISTRICT_LIST,
  STATE_LIST,
  TALUKA_LIST,
  VILLAGE_LIST,
  PRODUCT_LIST,
  COMPANYUNIT_LIST,
  DOCUMENT_LIST,
  BANK_LIST,
  RELATION_LIST,
  EVENT_TYPE_LIST,
  FINANCIAL_YEAR,
} from "../constTypes/common";

const _common = (actionType, data) => {
  return {
    type: actionType,
    payload: data,
  };
};

export const get_country_list =
  ({ onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .get(`${keys().ServicePath}/api/common/country`)
      .then((res) => {
        dispatch(_common(COUNTRY_LIST, res.data.rows));
        if (onSuccess) onSuccess(res.data.rows);
      })
      .catch((e) => {
        toast.error(e.toString());
      });
  };

export const get_state_list =
  ({ onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .get(`${keys().ServicePath}/api/common/state`)
      .then((res) => {
        dispatch(_common(STATE_LIST, res.data.rows));
        if (onSuccess) onSuccess(res.data.rows);
      })
      .catch((e) => {
        toast.error(e.toString());
      });
  };

export const get_district_list =
  ({ onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .get(`${keys().ServicePath}/api/common/district`)
      .then((res) => {
        dispatch(_common(DISTRICT_LIST, res.data.rows));
        if (onSuccess) onSuccess(res.data.rows);
      })
      .catch((e) => {
        toast.error(e.toString());
      });
  };

export const get_taluka_list =
  ({ onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .get(`${keys().ServicePath}/api/common/taluka`)
      .then((res) => {
        dispatch(_common(TALUKA_LIST, res.data.rows));
        if (onSuccess) onSuccess(res.data.rows);
      })
      .catch((e) => {
        toast.error(e.toString());
      });
  };

export const get_village_list =
  ({ onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .get(`${keys().ServicePath}/api/common/village`)
      .then((res) => {
        dispatch(_common(VILLAGE_LIST, res.data.rows));
        if (onSuccess) onSuccess(res.data.rows);
      })
      .catch((e) => {
        toast.error(e.toString());
      });
  };

const _productList = (data) => {
  return {
    type: PRODUCT_LIST,
    payload: data,
  };
};
export const get_product_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/common/getProductList`)
    .then((res) => {
      dispatch(_productList(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

const _companyUnitList = (data) => {
  return {
    type: COMPANYUNIT_LIST,
    payload: data,
  };
};
export const get_company_unit_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/common/getCompanyUnitList`)
    .then((res) => {
      dispatch(_companyUnitList(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const get_last_latest_voucherno =
  (tablename, callback) => async (dispatch) => {
    await axios
      .get(
        `${keys().ServicePath}/api/common/latest_voucher_number/${tablename}`
      )
      .then((res) => {
        if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };

const _document_master_list = (data) => {
  return {
    type: DOCUMENT_LIST,
    payload: data,
  };
};
export const get_document_master = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/common/get_document_master`)
    .then((res) => {
      dispatch(_document_master_list(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const get_bank_list =
  ({ onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .get(`${keys().ServicePath}/api/common/bank`)
      .then((res) => {
        dispatch(_common(BANK_LIST, res.data.rows[1]));
        if (onSuccess) onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        toast.error(e.toString());
      });
  };

export const get_relation_list =
  ({ onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .get(`${keys().ServicePath}/api/common/relation`)
      .then((res) => {
        dispatch(_common(RELATION_LIST, res.data.rows[1]));
        if (onSuccess) onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        toast.error(e.toString());
      });
  };

export const get_event_types_list =
  ({ onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .get(`${keys().ServicePath}/api/common/eventtypes`)
      .then((res) => {
        dispatch(_dispatchActions(EVENT_TYPE_LIST, res.data.rows));
        if (onSuccess) onSuccess(res.data.rows);
      })
      .catch((e) => {
        toast.error(e.toString());
      });
  };


  export const get_financial_year =
  ({ onSuccess, onError }) =>
  async (dispatch) => {
    await axios
      .get(`${keys().ServicePath}/api/common/get_financial_year`)
      .then((res) => {
        dispatch(_dispatchActions(FINANCIAL_YEAR, res.data.rows[1]));
        if (onSuccess) onSuccess(res.data.rows[1]);
      })
      .catch((e) => {
        toast.error(e.toString());
      });
  };
export const _dispatchActions = (type, data) => {
  return {
    type,
    payload: data,
  };
};
