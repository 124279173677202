import React, { useState } from "react";
import { Label, Input, Row, Col, FormGroup } from "reactstrap";
import { toast } from "react-toastify";
import CustomModal from "../../Component/CustomModal";

import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import { useNavigate } from "react-router-dom";
import useBreakPoints from "../../hooks/useBreakPoints";
const EditTrainingMaster = ({
  editModal,
  onCloseModal,
  onSubmitModal,
  singleData,
}) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useBreakPoints();
  const { token } = useSelector((state) => state.layout);

  const [inputData, setInputData] = useState({
    trainingTypeName: singleData?.trainingTypeName,
    remarks: singleData?.remarks,
  });

  const [inputDataError, setInputDataError] = useState({
    trainingTypeName: false,
    unit: false,
  });

  const handleChangeInput = (name, value) => {
    if (name === "trainingTypeName") {
      setInputData({ ...inputData, [name]: value });
      setInputDataError({
        ...inputDataError,
        trainingTypeName: value.length < 0,
      });
    } else {
      setInputData({ ...inputData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputData?.trainingTypeName) {
      toast?.error("Training Type Name is Required!");
      setInputDataError({ ...inputDataError, trainingTypeName: true });
    } else {
      setSubmitLoading(true);
      let formData = new FormData();

      formData.append("trainingTypeName", inputData.trainingTypeName);
      formData.append("remarks", inputData.remarks);
      formData.append("id", singleData?.id);

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}company/edittraining`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setSubmitLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setSubmitLoading(false);

          onSubmitModal();
        } else {
          setSubmitLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setSubmitLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  return (
    <CustomModal
      loading={submitLoading}
      title={"Edit Training"}
      handleSubmit={handleSubmit}
      modalState={editModal}
      onCloseModal={onCloseModal}
      editState={true}
    >
      <FormGroup>
        <Row className="" style={{ margin: 20 }}>
          <Col
            md={12}
            className={`d-flex g-3  ${isMobile ? "flex-column" : ""}`}
          >
            <Col md={5}>
              <Label
                className="me-2"
                style={{ fontWeight: 500 }}
                for="exampleEmail"
                md={12}
              >
                Training Type Name <span className="required_span"> * </span>
              </Label>
            </Col>
            <Col md={7} className="">
              <Input
                id="exampleEmail"
                name="trainingTypeName"
                value={inputData?.trainingTypeName}
                onChange={(e) => {
                  handleChangeInput(e.target.name, e.target.value);
                }}
                className={`cust-input ${
                  inputDataError?.trainingTypeName ? "border-danger" : ""
                }`}
                type="text"
              />
            </Col>
          </Col>

          <Col
            md={12}
            className={`d-flex g-3  ${isMobile ? "flex-column" : ""}`}
          >
            <Col md={5}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Remark
              </Label>
            </Col>
            <Col md={7} className="">
              <Input
                id="exampleEmail"
                name="remarks"
                value={inputData?.remarks}
                onChange={(e) => {
                  handleChangeInput(e.target.name, e.target.value);
                }}
                className="cust-input"
                type="text"
              />
            </Col>
          </Col>
        </Row>
      </FormGroup>
    </CustomModal>
  );
};

export default EditTrainingMaster;
