/* eslint-disable no-restricted-globals */
import axios from "../../common/axios";
import { toast } from "react-toastify";
import { keys } from "../../config/keys";

export const login = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys().ServicePath}/api/auth/login`, data)
    .then((res) => {
      
      //dispatch(_poList(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const refreshToken = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys().ServicePath}/api/auth/refresh_token`, data)
    .then((res) => {
      
      //dispatch(_poList(res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};
