/* eslint-disable no-restricted-globals */
import axios from "../../common/axios";
import { toast } from "react-toastify";
import { keys } from "../../config/keys";
import { WATER_WASTE_LIST } from "../constTypes";

const dispatchPayload = (type, payload) => ({ type, payload });
export const saveReadingData = (data, callback) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${keys().ServicePath}/api/waste/water/save`,
    data,
  })
    .then((res) => {
      if (callback.onSuccess) callback.onSuccess(res);
    })
    .catch((e) => {
      toast.error(e.toString());
      if (callback.onError) callback.onError(e);
    });
};

export const get_water_waste_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys().ServicePath}/api/waste/waterwastelist`)
    .then((res) => {
      dispatch(dispatchPayload(WATER_WASTE_LIST, res.data.rows[1]));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows[1]);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
      toast.error(e.toString());
    });
};

export const delete_water_waste_reading =
  (data, callback) => async (dispatch) => {
    await axios
      .post(`${keys().ServicePath}/api/waste/delete_water_waste_reading`, data)
      .then((res) => {
        if (callback?.onSuccess) callback.onSuccess(res.data.rows);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
        toast.error(e.toString());
      });
  };
