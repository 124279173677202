import React from "react";
import { Link } from "react-router-dom";
import useBreakPoints from "../hooks/useBreakPoints";
import LoaderComponent from "./LoaderComponent";
import { Button, colors } from "@mui/material";
import { ControlPoint, NavigateBefore, Print } from "@mui/icons-material";

const PageLayout = ({
  children,
  title,
  subTitle,
  backBtnTitle,
  backBtnTitleLink,
  onClick,
  addBtn,
  loading,
  showPrint = false,
  printTitle = "",
  onPrintClick,
}) => {
  const { isTablet } = useBreakPoints();

  return (
    <div className="cus-page-layout mt-3">
      {loading && <LoaderComponent />}
      {(title || backBtnTitle) && (
        <div
          className={`d-flex   w-100 ${
            isTablet && backBtnTitle
              ? "flex-column gap-2 justify-content-start align-items-start"
              : "justify-content-between align-items-center"
          }`}
        >
          {title && (
            <div className="dashboard-header">
              <span>{title}</span>
              {subTitle && (
                <small className="text-white fst-italic text-muted text-white-50">
                  {subTitle}
                </small>
              )}
            </div>
          )}

          {backBtnTitle && (
            <div
              className={`d-flex justify-content-center align-items-center ${
                isTablet && backBtnTitle ? "mx-3" : ""
              }`}
              style={{ marginRight: 20, gap: "5px" }}
            >
              {addBtn ? (
                <Button
                  className="custom-btn-page-layout"
                  variant="contained"
                  startIcon={<ControlPoint />}
                  onClick={onClick}
                >
                  {backBtnTitle}
                </Button>
              ) : (
                <Button
                  className="custom-btn-page-layout"
                  variant="contained"
                  startIcon={<NavigateBefore />}
                  onClick={onClick}
                >
                  {backBtnTitle}
                </Button>
              )}
              {showPrint && (
                <Button
                  className="custom-btn-page-layout custom-btn-warning"
                  variant="contained"
                  startIcon={<Print />}
                  onClick={onPrintClick}
                >
                  {`Print ${printTitle}`}
                </Button>
              )}
            </div>
          )}
        </div>
      )}

      {children}
    </div>
  );
};

export default PageLayout;
