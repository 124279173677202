import React, { useCallback, useEffect, useState } from "react";
import PageLayout from "../../Component/PageLayout";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import {
  alterGstDetailArray,
  alterMrpDetailsArray,
  calculationTypeArray,
  coastingMethodArray,
  gstApplicableArray,
  marketValuationArray,
  provideBehaviorArray,
  taxabilityArray,
  typeOfSupplyArray
} from "../../hooks/StaticData";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import { useNavigate } from "react-router-dom";
import useBreakPoints from "../../hooks/useBreakPoints";
import {
  EventNote,
  NavigateBefore,
  NavigateNext,
  Save
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  Modal,
  Switch,
  TextField,
  createFilterOptions
} from "@mui/material";
import ProductTypeMaster from "./ProductTypeMaster";
import GroupList from "./groupMaster";
import CategoryMaster from "./categoryMaster";
import UnitMaster from "./UnitMaster";

const AddProductMaster = () => {
  const { token } = useSelector((state) => state.layout);
  const { isMobile, isTablet } = useBreakPoints();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState("step1");
  const [loading, setLoading] = useState(false);
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [unitsOptions, setUnitsOptions] = useState([]);
  const [groupOptions, setgroupOptions] = useState([]);
  const [isNewProductType, setIsNewProductType] = useState({
    status: false,
    id: 0
  });
  const [isNewGroup, setIsNewGroup] = useState({
    status: false,
    id: 0
  });
  const [isNewCategory, setIsNewCategory] = useState({
    status: false,
    id: 0
  });
  const [isNewUnit, setIsNewUnit] = useState({
    status: false,
    id: 0
  });

  const [showProductTypeDialog, setShowProductTypeDialog] = useState(false);
  const [showGroupDialog, setShowGroupDialog] = useState(false);
  const [showCategoryDialog, setShowCategoryDialog] = useState(false);
  const [showUnitDialog, setShowUnitDialog] = useState(false);

  const filter = createFilterOptions();

  const [inputData, setInputData] = useState({
    name: "",
    partNo: "",
    group: "",
    description: "",
    productType: "",
    defaultLedger: false,
    // under: "",
    category: "",
    units: "",
    maintainBatches: false,
    alterComponents: false,
    alterStandardRates: false,
    trackDate: false,
    maintainExpiry: false,
    gstApplicable: "",
    alterGstDetails: "",
    typeOfSupply: "",
    hsnDescription: "",
    hsnSac: "",
    nonGstGoods: false,
    calculationType: "",
    taxability: "",
    reverseCharge: false,
    ineligibleCredit: false,
    integratedTax: "",
    centralTax: "",
    stateTax: "",
    cess: "",
    costingMethod: "",
    marketValuation: "",
    provideBehavior: "",
    alterMrpDetails: "",
    rateDuty: "",
    enableBarcode: false
  });

  const [inputDataError, setInputDataError] = useState({
    name: false,
    partNo: false,
    group: false,
    productType: false,
    category: false,
    units: false,
    gstApplicable: false,
    alterGstDetails: false,
    typeOfSupply: false,
    hsnDescription: false,
    hsnSac: false,
    calculationType: false,
    taxability: false,
    integratedTax: false,
    centralTax: false,
    stateTax: false,
    cess: false
  });

  // handleChange Input
  const handleChangeInput = (name, value) => {
    if (name === "name") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, name: false });
      } else {
        setInputDataError({ ...inputDataError, name: true });
      }
    } else if (name === "partNo") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, partNo: false });
      } else {
        setInputDataError({ ...inputDataError, partNo: true });
      }
    } else if (name === "productType") {
      setInputData({ ...inputData, [name]: value });

      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, productType: false });
      } else {
        setInputDataError({ ...inputDataError, productType: true });
      }
    } else if (name === "category") {
      setInputData({ ...inputData, [name]: value });

      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, category: false });
      } else {
        setInputDataError({ ...inputDataError, category: true });
      }
    } else if (name === "group") {
      setInputData({ ...inputData, [name]: value });

      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, group: false });
      } else {
        setInputDataError({ ...inputDataError, group: true });
      }
    } else if (name === "units") {
      setInputData({ ...inputData, [name]: value });

      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, units: false });
      } else {
        setInputDataError({ ...inputDataError, units: true });
      }
    } else if (name === "gstApplicable") {
      setInputData({ ...inputData, [name]: value });

      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, gstApplicable: false });
      } else {
        setInputDataError({ ...inputDataError, gstApplicable: true });
      }
    } else if (name === "alterGstDetails") {
      setInputData({ ...inputData, [name]: value });

      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, alterGstDetails: false });
      } else {
        setInputDataError({ ...inputDataError, alterGstDetails: true });
      }
    } else if (name === "typeOfSupply") {
      setInputData({ ...inputData, [name]: value });

      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, typeOfSupply: false });
      } else {
        setInputDataError({ ...inputDataError, typeOfSupply: true });
      }
    } else if (name === "hsnDescription") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, hsnDescription: false });
      } else {
        setInputDataError({ ...inputDataError, hsnDescription: true });
      }
    } else if (name === "hsnSac") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, hsnSac: false });
      } else {
        setInputDataError({ ...inputDataError, hsnSac: true });
      }
    } else if (name === "calculationType") {
      setInputData({ ...inputData, [name]: value });

      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, calculationType: false });
      } else {
        setInputDataError({ ...inputDataError, calculationType: true });
      }
    } else if (name === "taxability") {
      setInputData({ ...inputData, [name]: value });

      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, taxability: false });
      } else {
        setInputDataError({ ...inputDataError, taxability: true });
      }
    } else if (name === "integratedTax") {
      let centralTax = parseInt(value) / 2;
      let stateTax = parseInt(value) / 2;
      setInputData({
        ...inputData,
        centralTax: centralTax,
        stateTax: stateTax,
        [name]: value
      });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, integratedTax: false });
      } else {
        setInputDataError({ ...inputDataError, integratedTax: true });
      }
    } else if (name === "centralTax") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, centralTax: false });
      } else {
        setInputDataError({ ...inputDataError, centralTax: true });
      }
    } else if (name === "stateTax") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, stateTax: false });
      } else {
        setInputDataError({ ...inputDataError, stateTax: true });
      }
    } else if (name === "cess") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, cess: false });
      } else {
        setInputDataError({ ...inputDataError, cess: true });
      }
    } else {
      setInputData({ ...inputData, [name]: value });
    }
  };

  const handleNextBtn = (stepper) => {
    if (stepper === "step1") {
      if (!inputData?.name) {
        setInputDataError({ ...inputDataError, name: true });

        toast.error("Name is Required!");
      } else if (!inputData?.partNo) {
        setInputDataError({ ...inputDataError, partNo: true });

        toast.error("Part No is Required!");
      } else if (!inputData?.productType) {
        setInputDataError({ ...inputDataError, productType: true });

        toast.error("Product Type is Required!");
      } else if (!inputData?.group) {
        setInputDataError({ ...inputDataError, group: true });

        toast.error("Group is Required!");
      } else if (!inputData?.category) {
        setInputDataError({ ...inputDataError, category: true });

        toast.error("Category is Required!");
      } else if (!inputData?.units) {
        setInputDataError({ ...inputDataError, units: true });

        toast.error("Unit is Required!");
      } else {
        setStep("step2");
      }
    }

    if (stepper === "step2") {
      if (!inputData?.gstApplicable) {
        setInputDataError({ ...inputDataError, gstApplicable: true });

        toast.error("GST Applicable is Required!");
      } else if (
        !inputData?.alterGstDetails &&
        inputData?.gstApplicable?.label === "Yes"
      ) {
        setInputDataError({ ...inputDataError, alterGstDetails: true });

        toast.error("alterGstDetails is Required!");
      } else if (
        !inputData?.typeOfSupply &&
        inputData?.gstApplicable?.label === "Yes"
      ) {
        setInputDataError({ ...inputDataError, typeOfSupply: true });

        toast.error("Type Of Supply is Required!");
      } else if (
        !inputData?.hsnDescription &&
        inputData?.gstApplicable?.label === "Yes"
      ) {
        setInputDataError({ ...inputDataError, hsnDescription: true });

        toast.error("Description is Required!");
      } else if (
        !inputData?.hsnSac &&
        inputData?.gstApplicable?.label === "Yes"
      ) {
        setInputDataError({ ...inputDataError, hsnSac: true });

        toast.error("HSN/SAC is Required!");
      } else if (
        !inputData?.calculationType &&
        inputData?.gstApplicable?.label === "Yes"
      ) {
        setInputDataError({ ...inputDataError, calculationType: true });

        toast.error("Calculation Type is Required!");
      } else if (
        !inputData?.taxability &&
        inputData?.gstApplicable?.label === "Yes"
      ) {
        setInputDataError({ ...inputDataError, taxability: true });

        toast.error("Taxability is Required!");
      } else if (
        !inputData?.integratedTax &&
        inputData?.gstApplicable?.label === "Yes"
      ) {
        setInputDataError({ ...inputDataError, unintegratedTaxits: true });

        toast.error("Integrated Tax is Required!");
      } else if (
        !inputData?.centralTax &&
        inputData?.gstApplicable?.label === "Yes"
      ) {
        setInputDataError({ ...inputDataError, centralTax: true });

        toast.error("Central Tax is Required!");
      } else if (
        !inputData?.stateTax &&
        inputData?.gstApplicable?.label === "Yes"
      ) {
        setInputDataError({ ...inputDataError, stateTax: true });

        toast.error("State Tax is Required!");
      } else if (
        !inputData?.cess &&
        inputData?.gstApplicable?.label === "Yes"
      ) {
        setInputDataError({ ...inputDataError, cess: true });

        toast.error("Cess is Required!");
      } else {
        setStep("step3");
      }
    }
  };

  // Get getProductTypeListAPi List
  const getProductTypeListAPi = useCallback(async () => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/itemtypelist`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json"
          }
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);

        let arr = [];
        // eslint-disable-next-line
        _data?.itemTypeList?.map((er) => {
          arr.push({ label: er?.typeName, value: er?.id });
        });
        setProductTypeOptions([...arr]);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  // Get Unit List
  const getUnitListAPi = useCallback(async () => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/unitlist`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json"
          }
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);

        let arr = [];
        // eslint-disable-next-line
        _data?.unitList?.map((er) => {
          arr.push({ label: er?.formalName, value: er?.id });
        });
        setUnitsOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  const getGroupListAPi = useCallback(async () => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/grouplist`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json"
          }
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];
        // eslint-disable-next-line
        _data?.groupList?.map((er) => {
          arr.push({ label: er?.groupName, value: er?.id });
        });

        setgroupOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  const getCategoryListAPi = useCallback(async () => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/categorylist`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json"
          }
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];
        // eslint-disable-next-line
        _data?.categoryList?.map((er) => {
          arr.push({ label: er?.categoryName, value: er?.id });
        });
        setCategoryOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getProductTypeListAPi();
    getUnitListAPi();
    getGroupListAPi();
    getCategoryListAPi();
  }, [
    getProductTypeListAPi,
    getCategoryListAPi,
    getGroupListAPi,
    getUnitListAPi
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let formData = new FormData();

    formData.append("productName", inputData.name);
    formData.append("partNo", inputData?.partNo);
    formData.append("description", inputData?.description);
    formData.append("typeId", inputData?.productType?.value);
    formData.append(
      "ledgerForInvoice",
      inputData?.defaultLedger ? "True" : "False"
    );
    // formData.append("under", inputData?.under?.value);
    formData.append("categoryId", inputData?.category?.value);
    formData.append("groupId", inputData?.group?.value);
    formData.append("calculationunitId", inputData?.units?.value);
    formData.append(
      "maintainBatches",
      inputData?.maintainBatches ? "True" : "False"
    );
    formData.append(
      "alterComponents",
      inputData?.alterComponents ? "True" : "False"
    );
    formData.append(
      "alterStandardRate",
      inputData?.alterStandardRates ? "True" : "False"
    );
    formData.append(
      "trackDateOfManufacture",
      inputData?.trackDate ? "True" : "False"
    );
    formData.append(
      "maintainExpiry",
      inputData?.maintainExpiry ? "True" : "False"
    );
    formData.append("gstApplicable", inputData?.gstApplicable?.value);
    formData.append(
      "setAlterGst",
      inputData?.alterGstDetails?.value ? inputData?.alterGstDetails?.value : ""
    );
    formData.append(
      "supplyType",
      inputData?.typeOfSupply?.value ? inputData?.typeOfSupply?.value : ""
    );
    formData.append("hsnDescription", inputData?.hsnDescription);
    formData.append("hsnOrSac", inputData?.hsnSac);
    formData.append("nonGstGoods", inputData?.nonGstGoods ? "True" : "False");
    formData.append(
      "reverseCharge",
      inputData?.reverseCharge ? "True" : "False"
    );
    formData.append(
      "ineligibleForInputCredit",
      inputData?.ineligibleCredit ? "True" : "False"
    );
    formData.append(
      "calculationType",
      inputData?.calculationType?.value ? inputData?.calculationType?.value : ""
    );
    formData.append(
      "taxability",
      inputData?.taxability?.value ? inputData?.taxability?.value : ""
    );
    formData.append("integratedTaxRate", inputData?.integratedTax);
    formData.append("centralTaxRate", inputData?.centralTax);
    formData.append("stateTaxRate", inputData?.stateTax);
    formData.append("cessRate", inputData?.cess);
    formData.append(
      "costingMethod",
      inputData?.costingMethod?.value ? inputData?.costingMethod?.value : ""
    );
    formData.append(
      "marketEvaluationMethod",
      inputData?.marketValuation?.value ? inputData?.marketValuation?.value : ""
    );
    formData.append(
      "provideBehaviorOptions",
      inputData?.provideBehavior?.value ? inputData?.provideBehavior?.value : ""
    );
    formData.append(
      "mrpDetails",
      inputData?.alterMrpDetails?.value ? inputData?.alterMrpDetails?.value : ""
    );
    formData.append("rateOfDuty", inputData?.rateDuty);
    formData.append(
      "enableBarcode",
      inputData?.enableBarcode ? "True" : "False"
    );

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/addproduct`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: formData
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        toast?.success(_data?.message);
        setLoading(false);
        navigate("/productmaster");
      } else {
        setLoading(false);
        toast?.error(_data?.message);
      }
    } catch (error) {
      setLoading(false);
      toast?.error(error);
      console.log(error);
    }
  };

  const onCreateNew = (data) => {
    getProductTypeListAPi().then((dat) => {
      setIsNewProductType({ status: true, id: data.insertedid });
    });
  };

  const onCreateNewGroup = (data) => {
    getGroupListAPi().then((dat) => {
      setIsNewGroup({ status: true, id: data.insertedid });
    });
  };

  const onCreateNewCategory = (data) => {
    getCategoryListAPi().then((dat) => {
      setIsNewCategory({ status: true, id: data.insertedid });
    });
  };

  const onCreateNewUnit = (data) => {
    getUnitListAPi().then((dat) => {
      setIsNewUnit({ status: true, id: data.insertedid });
    });
  };

  useEffect(() => {
    if (isNewProductType?.status) {
      const sel = productTypeOptions.find(
        (x) => x.value === isNewProductType?.id
      );
      handleChangeInput("productType", sel);
      setShowProductTypeDialog(false);
      setIsNewProductType({ status: false, id: 0 });
    }
  }, [productTypeOptions, isNewProductType]);

  useEffect(() => {
    if (isNewGroup?.status) {
      const sel = groupOptions.find((x) => x.value === isNewGroup?.id);
      handleChangeInput("group", sel);
      setShowGroupDialog(false);
      setIsNewGroup({ status: false, id: 0 });
    }
  }, [groupOptions, isNewGroup]);

  useEffect(() => {
    if (isNewCategory?.status) {
      const sel = categoryOptions.find((x) => x.value === isNewCategory?.id);
      handleChangeInput("category", sel);
      setShowCategoryDialog(false);
      setIsNewCategory({ status: false, id: 0 });
    }
  }, [categoryOptions, isNewCategory]);

  useEffect(() => {
    if (isNewUnit?.status) {
      const sel = unitsOptions.find((x) => x.value === isNewUnit?.id);
      handleChangeInput("units", sel);
      setShowUnitDialog(false);
      setIsNewUnit({ status: false, id: 0 });
    }
  }, [unitsOptions, isNewUnit]);

  return (
    <PageLayout
      title={"Add product Master"}
      backBtnTitle={`Back to Product List`}
      onClick={() => navigate("/productmaster")}
    >
      {/* Stepper View  */}
      <div className="farm-add-stepper">
        <div
          className={`${step === "step1" ? "farm-stepper-active" : ""} ${
            isTablet ? "step1-prod-mobile-view" : "step1-farm-add"
          } `}
        >
          <EventNote
            fontSize="medium"
            className={`${step === "step1" ? "text-white" : "text-black"}`}
          />

          {!isTablet && (
            <span
              className={`${step === "step1" ? "text-white" : "text-black"}`}
            >
              basic Details
            </span>
          )}
        </div>
        <div
          className={`${step === "step2" ? "farm-stepper-active" : ""}${
            isTablet ? "step1-prod-mobile-view" : " step2-farm-add"
          } `}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M16.5003 1.83325H11.0003V8.24992L8.70866 6.87492L6.41699 8.24992V1.83325H5.50033C5.0141 1.83325 4.54778 2.02641 4.20396 2.37022C3.86015 2.71404 3.66699 3.18036 3.66699 3.66659V18.3333C3.66699 18.8195 3.86015 19.2858 4.20396 19.6296C4.54778 19.9734 5.0141 20.1666 5.50033 20.1666H16.5003C16.9866 20.1666 17.4529 19.9734 17.7967 19.6296C18.1405 19.2858 18.3337 18.8195 18.3337 18.3333V3.66659C18.3337 3.18036 18.1405 2.71404 17.7967 2.37022C17.4529 2.02641 16.9866 1.83325 16.5003 1.83325ZM12.8337 10.9999C13.1963 10.9999 13.5507 11.1074 13.8522 11.3089C14.1537 11.5103 14.3887 11.7967 14.5274 12.1317C14.6662 12.4667 14.7025 12.8353 14.6318 13.1909C14.561 13.5465 14.3864 13.8732 14.13 14.1296C13.8736 14.386 13.547 14.5606 13.1913 14.6314C12.8357 14.7021 12.4671 14.6658 12.1321 14.527C11.7971 14.3883 11.5107 14.1533 11.3093 13.8518C11.1078 13.5503 11.0003 13.1959 11.0003 12.8333C11.0003 12.347 11.1935 11.8807 11.5373 11.5369C11.8811 11.1931 12.3474 10.9999 12.8337 10.9999ZM16.5003 18.3333H9.16699V17.4166C9.16699 16.1974 11.6145 15.5833 12.8337 15.5833C14.0528 15.5833 16.5003 16.1974 16.5003 17.4166V18.3333Z"
              fill={step === "step2" ? "white" : "black"}
            />
          </svg>
          {!isTablet && (
            <span
              className={`${step === "step2" ? "text-white" : "text-black"}`}
            >
              statutory details
            </span>
          )}
        </div>
        <div
          className={`${step === "step3" ? "farm-stepper-active" : ""} ${
            isTablet ? "step1-prod-mobile-view" : "step3-farm-add"
          } `}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M18.9062 3.95312H15.2969V2.57812C15.2969 2.48359 15.2195 2.40625 15.125 2.40625H13.9219C13.8273 2.40625 13.75 2.48359 13.75 2.57812V3.95312H8.25V2.57812C8.25 2.48359 8.17266 2.40625 8.07812 2.40625H6.875C6.78047 2.40625 6.70312 2.48359 6.70312 2.57812V3.95312H3.09375C2.71348 3.95312 2.40625 4.26035 2.40625 4.64062V18.9062C2.40625 19.2865 2.71348 19.5938 3.09375 19.5938H18.9062C19.2865 19.5938 19.5938 19.2865 19.5938 18.9062V4.64062C19.5938 4.26035 19.2865 3.95312 18.9062 3.95312ZM13.9283 9.16953L12.0441 12.6307H13.026C13.1441 12.6307 13.2408 12.7273 13.2408 12.8455V13.3031C13.2408 13.4213 13.1441 13.518 13.026 13.518H11.6639V14.1561H13.026C13.1441 14.1561 13.2408 14.2527 13.2408 14.3709V14.8285C13.2408 14.9467 13.1441 15.0434 13.026 15.0434H11.6639V16.1562C11.6639 16.2744 11.5672 16.3711 11.449 16.3711H10.5617C10.4436 16.3711 10.3469 16.2744 10.3469 16.1562V15.0434H8.99121C8.87305 15.0434 8.77637 14.9467 8.77637 14.8285V14.3709C8.77637 14.2527 8.87305 14.1561 8.99121 14.1561H10.3469V13.518H8.99121C8.87305 13.518 8.77637 13.4213 8.77637 13.3031V12.8455C8.77637 12.7273 8.87305 12.6307 8.99121 12.6307H9.96231L8.07168 9.16953C8.01582 9.06641 8.05234 8.93535 8.15762 8.87734C8.18984 8.86016 8.22422 8.85156 8.26074 8.85156H9.24902C9.33066 8.85156 9.40371 8.89668 9.44023 8.96973L11.0064 12.0699L12.5791 8.96973C12.597 8.9343 12.6243 8.9045 12.658 8.88364C12.6918 8.86279 12.7306 8.85168 12.7703 8.85156H13.7371C13.8553 8.85156 13.952 8.94824 13.952 9.06641C13.9541 9.10293 13.9455 9.1373 13.9283 9.16953Z"
              fill={step === "step3" ? "white" : "black"}
            />
          </svg>
          {!isTablet && (
            <span
              className={`${step === "step3" ? "text-white" : "text-black"}`}
            >
              Other details
            </span>
          )}
        </div>
      </div>

      {step === "step1" && (
        <div className="m-4">
          <Row className={`${isMobile ? "" : "mt-2 g-2"}`}>
            <Col md={3} sm={12}>
              <TextField
                variant="standard"
                size="small"
                label="Part No"
                fullWidth
                value={inputData?.partNo}
                name="partNo"
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                error={inputDataError?.partNo}
                required
                type="text"
              />
            </Col>
            <Col md={3} sm={12}>
              <TextField
                value={inputData?.name}
                name="name"
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                variant="standard"
                size="small"
                label="Product Name"
                fullWidth
                error={inputDataError?.name}
                type="text"
                required
              />
            </Col>
            <Col md={3} sm={12}>
              <TextField
                variant="standard"
                size="small"
                label="Description"
                fullWidth
                value={inputData?.description}
                name="description"
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                type="text"
              />
            </Col>
          </Row>
          <Row className="g-2 mt-2">
            <Col md={3} sm={12}>
              <Autocomplete
                name="product"
                size="small"
                freeSolo
                options={productTypeOptions}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    //navigate("/producttypemaster");
                    return option;
                  }
                  if (option.inputValue) {
                    return `${option.label}`;
                  }
                  return option.label;
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  const isExisting = options.some(
                    (option) => inputValue === option.label
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      id: 0,
                      inputValue: `Add "${inputValue}"`,
                      label: `${inputValue}`
                    });
                  }

                  return filtered;
                }}
                onChange={(e, option) => {
                  handleChangeInput("productType", option);
                  if (option?.inputValue) {
                    // navigate("/producttypemaster");
                    setShowProductTypeDialog(true);
                  }
                }}
                value={inputData?.productType}
                renderOption={(props, option) => {
                  return (
                    <li {...props}>{option?.inputValue || option.label}</li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Product Type"
                    size="small"
                    error={inputDataError?.productType}
                    required
                  />
                )}
              />
            </Col>
            <Col md={3} sm={12}>
              <Autocomplete
                name="product"
                size="small"
                freeSolo
                value={inputData?.group}
                options={groupOptions}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return `${option.label}`;
                  }
                  return option.label;
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  const isExisting = options.some(
                    (option) => inputValue === option.label
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      id: 0,
                      inputValue: `Add "${inputValue}"`,
                      label: `${inputValue}`
                    });
                  }

                  return filtered;
                }}
                onChange={(e, option) => {
                  handleChangeInput("group", option);
                  if (option?.inputValue) {
                    setShowGroupDialog(true);
                  }
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props}>{option?.inputValue || option.label}</li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Product Group"
                    size="small"
                    error={inputDataError?.group}
                    required
                  />
                )}
              />
            </Col>
            <Col md={3} sm={12}>
              <Autocomplete
                name="product"
                size="small"
                freeSolo
                value={inputData?.category}
                options={categoryOptions}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return `${option.label}`;
                  }
                  return option.label;
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  const isExisting = options.some(
                    (option) => inputValue === option.label
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      id: 0,
                      inputValue: `Add "${inputValue}"`,
                      label: `${inputValue}`
                    });
                  }

                  return filtered;
                }}
                onChange={(e, option) => {
                  handleChangeInput("category", option);
                  if (option?.inputValue) {
                    setShowCategoryDialog(true);
                  }
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props}>{option?.inputValue || option.label}</li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Product Category"
                    size="small"
                    error={inputDataError?.category}
                    required
                  />
                )}
              />
            </Col>
            <Col md={3} sm={12}>
              
              <Autocomplete
                name="product"
                size="small"
                freeSolo
                value={inputData?.units}
                options={unitsOptions}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return `${option.label}`;
                  }
                  return option.label;
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  const isExisting = options.some(
                    (option) => inputValue === option.label
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      id: 0,
                      inputValue: `Add "${inputValue}"`,
                      label: `${inputValue}`
                    });
                  }

                  return filtered;
                }}
                onChange={(e, option) => {
                  handleChangeInput("units", option);
                  if (option?.inputValue) {
                    setShowUnitDialog(true);
                  }
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props}>{option?.inputValue || option.label}</li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Unit"
                    size="small"
                    error={inputDataError?.units}
                    required
                  />
                )}
              />
            </Col>
          </Row>
          <Row className="g-2 mt-2">
            <Col md={3} sm={12}>
              <FormControlLabel
                className="m-0"
                control={
                  <Switch
                    name="defaultLedger"
                    color="warning"
                    onChange={(e) => {
                      handleChangeInput(e.target.name, e.target.checked);
                    }}
                    checked={inputData?.defaultLedger}
                  />
                }
                label={`Set / Modify default ledgers for Invoicing`}
                labelPlacement="start"
              />
            </Col>
            <Col md={3} sm={12}>
              <FormControlLabel
                className="m-0"
                control={
                  <Switch
                    color="warning"
                    checked={inputData?.maintainBatches}
                    name="maintainBatches"
                    onChange={(e) => {
                      handleChangeInput(e.target.name, e.target.checked);
                    }}
                  />
                }
                label={`Maintain in batches`}
                labelPlacement="start"
              />
            </Col>
            <Col md={3} sm={12}>
              <FormControlLabel
                className="m-0"
                control={
                  <Switch
                    color="warning"
                    checked={inputData?.alterComponents}
                    name="alterComponents"
                    onChange={(e) => {
                      handleChangeInput(e.target.name, e.target.checked);
                    }}
                  />
                }
                label={`Alter Components(BOM)`}
                labelPlacement="start"
              />
            </Col>
            <Col md={3} sm={12}>
              <FormControlLabel
                className="m-0"
                control={
                  <Switch
                    color="warning"
                    checked={inputData?.alterStandardRates}
                    name="alterStandardRates"
                    onChange={(e) => {
                      handleChangeInput(e.target.name, e.target.checked);
                    }}
                  />
                }
                label={`Alter Standard Rates`}
                labelPlacement="start"
              />
            </Col>
            <Col md={3} sm={12}>
              <FormControlLabel
                className="m-0"
                control={
                  <Switch
                    color="warning"
                    checked={inputData?.trackDate}
                    name="trackDate"
                    onChange={(e) => {
                      handleChangeInput(e.target.name, e.target.checked);
                    }}
                  />
                }
                label={`Track Manufacturing Date`}
                labelPlacement="start"
              />
            </Col>
            <Col md={3} sm={12}>
              <FormControlLabel
                className="m-0"
                control={
                  <Switch
                    color="warning"
                    checked={inputData?.maintainExpiry}
                    name="maintainExpiry"
                    onChange={(e) => {
                      handleChangeInput(e.target.name, e.target.checked);
                    }}
                  />
                }
                label={`Maintain Expiry`}
                labelPlacement="start"
              />
            </Col>
          </Row>

          <Row className="mt-2 g-2 justify-content-end">
            <Col lg={2} md={2} sm={12}>
              <Button
                type="button"
                className="custom-btn-page-layout btn text-white"
                onClick={() => handleNextBtn("step1")}
                endIcon={<NavigateNext />}
                fullWidth
              >
                {`Next`}
              </Button>
            </Col>
          </Row>
        </div>
      )}

      {step === "step2" && (
        <div className="m-4 ">
          <Row className={`mt-2 g-2"}`}>
            <Col lg={2} md={2} sm={12}>
              <Autocomplete
                size="small"
                value={inputData?.gstApplicable}
                options={gstApplicableArray}
                onChange={(e, option) => {
                  handleChangeInput("gstApplicable", option);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Is GST Applicable ?"
                    size="small"
                    error={inputDataError?.gstApplicable}
                    required
                  />
                )}
              />
            </Col>
          </Row>
          {inputData?.gstApplicable?.value === "Yes" && (
            <Card variant="outlined" className="mt-3">
              <CardContent>
                <Row>
                  <Col md={3} sm={12}>
                    <Autocomplete
                      size="small"
                      value={inputData?.alterGstDetails}
                      options={alterGstDetailArray}
                      onChange={(e, option) => {
                        handleChangeInput("alterGstDetails", option);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Set/ Alter GST Details?"
                          size="small"
                          error={inputDataError?.alterGstDetails}
                          required={
                            inputData?.gstApplicable?.label === "No"
                              ? false
                              : true
                          }
                        />
                      )}
                    />
                  </Col>
                  <Col md={3} sm={12}>
                    <Autocomplete
                      size="small"
                      value={inputData?.typeOfSupply}
                      options={typeOfSupplyArray}
                      onChange={(e, option) => {
                        handleChangeInput("typeOfSupply", option);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Type of Supply"
                          size="small"
                          error={inputDataError?.typeOfSupply}
                          required={
                            inputData?.gstApplicable?.label === "No"
                              ? false
                              : true
                          }
                        />
                      )}
                    />
                  </Col>
                </Row>

                <div class="mt-4 h6 pb-2 mb-2 text-info border-bottom border-secondary border-2 fw-bold">
                  {`HSN/SAC Details`}
                </div>

                <Row className={`g-2`}>
                  <Col lg={3} md={3} sm={12}>
                    <TextField
                      variant="standard"
                      size="small"
                      fullWidth
                      label={`Description`}
                      value={inputData?.hsnDescription}
                      name="hsnDescription"
                      onChange={(e) =>
                        handleChangeInput(e.target.name, e.target.value)
                      }
                      disabled={inputData?.gstApplicable?.value === "No"}
                      className={`cust-input ${
                        inputDataError?.hsnDescription
                          ? "border border-danger"
                          : ""
                      }`}
                      error={inputDataError?.hsnDescription}
                      type="text"
                    />
                  </Col>
                  <Col lg={3} md={3} sm={12}>
                    <TextField
                      variant="standard"
                      size="small"
                      fullWidth
                      label={`HSN/SAC`}
                      value={inputData?.hsnSac}
                      name="hsnSac"
                      onChange={(e) =>
                        handleChangeInput(e.target.name, e.target.value)
                      }
                      disabled={inputData?.gstApplicable?.value === "No"}
                      error={inputDataError?.hsnSac}
                      type="text"
                    />
                  </Col>

                  <Col lg={3} md={3} sm={12}>
                    <FormControlLabel
                      className="m-0"
                      control={
                        <Switch
                          color="warning"
                          checked={inputData?.nonGstGoods}
                          name="nonGstGoods"
                          onChange={(e) => {
                            handleChangeInput(e.target.name, e.target.checked);
                          }}
                          disabled={inputData?.gstApplicable?.value === "No"}
                        />
                      }
                      label={`Is Non-GST goods?`}
                      labelPlacement="start"
                    />
                  </Col>
                </Row>

                <div class="mt-4 h6 pb-2 mb-2 text-info border-bottom border-secondary border-2 fw-bold">
                  {`Tax Details`}
                </div>

                <Row className={`g-2 mb-4`}>
                  <Col lg={3} md={3} sm={12}>
                    <Autocomplete
                      size="small"
                      value={inputData?.calculationType}
                      options={calculationTypeArray}
                      onChange={(e, option) => {
                        handleChangeInput("calculationType", option);
                      }}
                      disabled={inputData?.gstApplicable?.value === "No"}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Calculation Type ?"
                          size="small"
                          error={inputDataError?.calculationType}
                        />
                      )}
                    />
                  </Col>
                  <Col lg={3} md={3} sm={12}>
                    <Autocomplete
                      size="small"
                      value={inputData?.taxability}
                      options={taxabilityArray}
                      onChange={(e, option) => {
                        handleChangeInput("taxability", option);
                      }}
                      disabled={inputData?.gstApplicable?.value === "No"}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Taxability"
                          size="small"
                          error={inputDataError?.taxability}
                        />
                      )}
                    />
                  </Col>
                  <Col lg={3} md={3} sm={12}>
                    <FormControlLabel
                      className="m-0"
                      control={
                        <Switch
                          color="warning"
                          checked={inputData?.reverseCharge}
                          name="reverseCharge"
                          onChange={(e) => {
                            handleChangeInput(e.target.name, e.target.checked);
                          }}
                          disabled={inputData?.gstApplicable?.value === "No"}
                        />
                      }
                      label={`Is Reverse Charge Applicable?`}
                      labelPlacement="start"
                    />
                  </Col>
                  <Col lg={3} md={3} sm={12}>
                    <FormControlLabel
                      className="m-0"
                      control={
                        <Switch
                          color="warning"
                          checked={inputData?.ineligibleCredit}
                          name="ineligibleCredit"
                          onChange={(e) => {
                            handleChangeInput(e.target.name, e.target.checked);
                          }}
                          disabled={inputData?.gstApplicable?.value === "No"}
                        />
                      }
                      label={` Is ineligible for input credit?`}
                      labelPlacement="start"
                    />
                  </Col>
                </Row>
                <table className="table table-sm mt-3">
                  <thead>
                    <tr>
                      <th>Tax Type</th>
                      <th>Valuation Type</th>
                      <th>Rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Integrated Tax</td>
                      <td>Based on Value</td>
                      <td>
                        <TextField
                          name="integratedTax"
                          value={inputData?.integratedTax}
                          onChange={(e) => {
                            handleChangeInput(e.target.name, e.target.value);
                          }}
                          disabled={inputData?.gstApplicable?.value === "No"}
                          error={inputDataError?.integratedTax}
                          type="number"
                          size="small"
                          variant="outlined"
                        />
                      </td>
                    </tr>

                    {/* central tax  */}
                    <tr>
                      <td>Central Tax</td>
                      <td>Based on Value</td>
                      <td>
                        <TextField
                          name="integratedTax"
                          value={inputData?.centralTax}
                          disabled
                          type="number"
                          size="small"
                          variant="outlined"
                        />
                      </td>
                    </tr>

                    {/* state Taz  */}
                    <tr>
                      <td>State Tax</td>
                      <td>Based on Value</td>
                      <td>
                        <TextField
                          name="integratedTax"
                          value={inputData?.stateTax}
                          disabled
                          size="small"
                          variant="outlined"
                          type="number"
                        />
                      </td>
                    </tr>

                    {/* cess  */}
                    <tr>
                      <td>Cess Tax</td>
                      <td>Based on Value</td>
                      <td>
                        <TextField
                          size="small"
                          variant="outlined"
                          name="cess"
                          value={inputData?.cess}
                          onChange={(e) => {
                            handleChangeInput(e.target.name, e.target.value);
                          }}
                          disabled={inputData?.gstApplicable?.value === "No"}
                          error={inputDataError?.cess}
                          type="number"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
          )}
          <Row className="mt-2 g-2 justify-content-end">
            <Col lg={2} md={2} sm={12}>
              <Button
                type="button"
                className="custom-btn-page-layout btn text-white "
                onClick={() => setStep("step1")}
                startIcon={<NavigateBefore />}
                fullWidth
              >
                Previous
              </Button>
            </Col>
            <Col lg={2} md={2} sm={12}>
              <Button
                type="button"
                className="custom-btn-page-layout btn text-white"
                onClick={() => handleNextBtn("step2")}
                endIcon={<NavigateNext />}
                fullWidth
              >
                Next
              </Button>
            </Col>
          </Row>
        </div>
      )}

      {step === "step3" && (
        <div className="m-4">
          <Row className={`mt-2 g-2`}>
            <Col lg={4} md={4} sm={12}>
              <Autocomplete
                size="small"
                value={inputData?.costingMethod}
                options={coastingMethodArray}
                onChange={(e, option) => {
                  handleChangeInput("costingMethod", option);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Costing Method"
                    size="small"
                  />
                )}
              />
            </Col>
            <Col lg={4} md={4} sm={12}>
              <Autocomplete
                size="small"
                value={inputData?.marketValuation}
                options={marketValuationArray}
                onChange={(e, option) => {
                  handleChangeInput("marketValuation", option);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Market Valuation Method"
                    size="small"
                  />
                )}
              />
            </Col>
            <Col lg={4} md={4} sm={12}>
              <Autocomplete
                size="small"
                value={inputData?.provideBehavior}
                options={provideBehaviorArray}
                onChange={(e, option) => {
                  handleChangeInput("provideBehavior", option);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label=" Provide Behavior Options"
                    size="small"
                  />
                )}
              />
            </Col>
            <Col lg={4} md={4} sm={12}>
              <Autocomplete
                size="small"
                value={inputData?.alterMrpDetails}
                options={alterMrpDetailsArray}
                onChange={(e, option) => {
                  handleChangeInput("alterMrpDetails", option);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Set/ Alter MRP Details"
                    size="small"
                  />
                )}
              />
            </Col>
            <Col lg={4} md={4} sm={12}>
              <TextField
                variant="standard"
                size="small"
                fullWidth
                label="Rate of duty"
                value={inputData?.rateDuty}
                name="rateDuty"
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                type="text"
              />
            </Col>
            <Col lg={4} md={4} sm={12}>
              <FormControlLabel
                className="m-0"
                control={
                  <Switch
                    color="warning"
                    checked={inputData?.enableBarcode}
                    name="enableBarcode"
                    onChange={(e) => {
                      handleChangeInput(e.target.name, e.target.checked);
                    }}
                  />
                }
                label={`Enable Barcode`}
                labelPlacement="start"
              />
            </Col>
          </Row>
          <Row className="mt-5 g-2 justify-content-end">
            <Col lg={2} md={2} sm={12}>
              <Button
                type="button"
                className="custom-btn-page-layout btn text-white "
                onClick={() => setStep("step2")}
                startIcon={<NavigateBefore />}
                fullWidth
              >
                Previous
              </Button>
            </Col>
            <Col lg={2} md={2} sm={12}>
              <Button
                type="button"
                className="custom-btn-page-layout btn text-white custom-btn-success"
                onClick={(e) => handleSubmit(e)}
                startIcon={
                  loading ? (
                    <CircularProgress size={15} color="warning" />
                  ) : (
                    <Save />
                  )
                }
                disabled={loading}
                fullWidth
              >
                Submit
              </Button>
            </Col>
          </Row>
        </div>
      )}

      <Modal
        open={showProductTypeDialog}
        onClose={() => setShowProductTypeDialog(false)}
        style={{ backgroundColor: "#fff" }}
      >
        <Box sx={{ ...style }}>
          <ProductTypeMaster
            onCreate={onCreateNew}
            data={inputData?.productType}
          />
        </Box>
      </Modal>

      <Modal
        open={showGroupDialog}
        onClose={() => setShowGroupDialog(false)}
        style={{ backgroundColor: "#fff" }}
      >
        <Box sx={{ ...style }}>
          <GroupList onCreate={onCreateNewGroup} data={inputData?.group} />
        </Box>
      </Modal>

      <Modal
        open={showCategoryDialog}
        onClose={() => setShowCategoryDialog(false)}
        style={{ backgroundColor: "#fff" }}
      >
        <Box sx={{ ...style }}>
          <CategoryMaster
            onCreate={onCreateNewCategory}
            data={inputData?.category}
          />
        </Box>
      </Modal>

      <Modal
        open={showUnitDialog}
        onClose={() => setShowUnitDialog(false)}
        style={{ backgroundColor: "#fff" }}
      >
        <Box sx={{ ...style }}>
          <UnitMaster
            onCreate={onCreateNewUnit}
            data={inputData?.units}
          />
        </Box>
      </Modal>
    </PageLayout>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  boxShadow: 2,
  p: 4
};

export default AddProductMaster;
