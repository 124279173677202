import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import PageLayout from "../../Component/PageLayout";
import { useAppointmentHooks } from "./useSlotBookingHooks";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { keys } from "../../config/keys";
import { Col, Form, Row } from "reactstrap";
import VirtualizeAutoComplete from "../../common/VirtualizedAutoComplete";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Cancel, Save } from "@mui/icons-material";
import { textFieldProps } from "../../common/commonControlProps";

const AppointmentBooking = () => {
  const navigate = useNavigate();
  const {
    formData,
    employeeDropdownList,
    companyunitlist,
    ondropdownchange,
    farmerDropdownList,
    handleSubmit,
    onDateChange,
    onSubmit,
    onTimeChange,
    handleChange,
    onfarmerdropdownchange,
  } = useAppointmentHooks(0);

  return (
    <PageLayout
      title={"Book Appointment"}
      backBtnTitle={`Back to Appointment List`}
      onClick={() => navigate("/purchaseappointmentlist", { state: { id: 0 } })}
    >
      <Form className="m-4" onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-2">
          <Col lg={4} sm={12} md={4}>
            <VirtualizeAutoComplete
              fullWidth
              options={employeeDropdownList}
              value={formData.selectedemployee}
              onChange={(e, opt) =>
                ondropdownchange(e, opt, "employeeid", "selectedemployee")
              }
              disabled={formData.id > 0}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...textFieldProps}
                  label="Field Technician"
                />
              )}
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <VirtualizeAutoComplete
              fullWidth
              options={companyunitlist}
              value={formData.selectedunit}
              onChange={(e, opt) =>
                ondropdownchange(e, opt, "companyunitid", "selectedunit")
              }
              disabled={formData.id > 0}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...textFieldProps}
                  label="Select Unit"
                />
              )}
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <VirtualizeAutoComplete
              fullWidth
              options={farmerDropdownList}
              value={formData.selectedfarmer}
              onChange={(e, opt) =>
                onfarmerdropdownchange(e, opt, "farmerid", "selectedfarmer")
              }
              disabled={formData.id > 0}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...textFieldProps}
                  label="Select Farmer"
                />
              )}
            />
          </Col>
          <Col lg={2} md={2} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Appointment Date"
                format={keys().shortDateFormat}
                slotProps={{
                  textField: {
                    ...textFieldProps,
                  },
                }}
                onChange={onDateChange}
                value={formData?.appointmentdate}
                clearable
              />
            </LocalizationProvider>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Appointment time"
                value={formData.appointmenttime}
                onChange={onTimeChange}
                slotProps={{
                  textField: { ...textFieldProps },
                }}
                clearable
              />
            </LocalizationProvider>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              name="noofbags"
              value={formData?.noofbags}
              label="# Estimated Bags"
              onChange={handleChange}
              {...textFieldProps}
            />
          </Col>
          <Col
            lg={1}
            md={1}
            sm={12}
            className="d-flex justify-content-center align-items-center"
          >
            <Button
              className="custom-btn-page-layout text-white "
              startIcon={<Save />}
              fullWidth
              type="submit"
            >
              {` Save`}
            </Button>
          </Col>
          <Col
            lg={1}
            md={1}
            sm={12}
            className="d-flex justify-content-center align-items-center"
          >
            <Button
              type="button"
              className="custom-btn-page-layout custom-btn-danger "
              onClick={(e) => navigate("/purchaseappointmentlist")}
              startIcon={<Cancel />}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </PageLayout>
  );
};

export default AppointmentBooking;
