import * as yup from "yup";

export const mainModuleValidationSchema = yup.object({
    mainModuleName: yup.string().trim().required("Module Name is required"),
    path: yup.string().trim().notRequired()
});

export const moduleValidationSchema = yup.object({
    moduleName: yup.string().trim().required("Module Name is required"),
    path: yup.string().trim().notRequired()
});

export const subModuleValidationSchema = yup.object({
    subModuleName: yup.string().trim().required("Sub Module Name is required"),
    path: yup.string().trim().notRequired()
});