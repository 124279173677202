import React,{useState} from "react";
import PageLayout from "../../Component/PageLayout";
import {
  Col,
  Row,
  FormGroup,
  Form,
} from "reactstrap";
import { Autocomplete, TextField } from "@mui/material";
import { usePurchaseInvoiceHooks } from "./usePurchaseInvoiceHooks";

const PurchaseInvoice = () => {
      const {
        register,
        formData,
        errors,
        handleSubmit,
        onSubmit,
        onCancelClick,
      } = usePurchaseInvoiceHooks();
      const [loading, setLoading] = useState(false);

  return (
    <>
      <PageLayout title={"Purchase Invoice"} loading={loading}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mt-2 g-3" style={{ margin: "20px" }}>
            <Col lg={3} md={4} sm={12}>
              <FormGroup>
                <TextField
                  {...register("name")}
                  error={errors?.name}
                  id="name"
                  name="name"
                  label="Configuration Name"
                //   value={formData?.name}
                //   onChange={handleChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
                {/* {errors?.name && (
                  <ErrorMessage message={errors?.name?.message} />
                )} */}
              </FormGroup>
            </Col>


            <Col lg={3} md={4} sm={12}>
              <Autocomplete
                id="isactive"
                name="isactive"
                size="small"
                // options={statusOption}
                clearText={true}
                // value={formData?.selStatus}
                // error={errors?.isactive}
                // onChange={ondropdownchange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Status"
                    size="small"
                  />
                )}
              />
              {/* {errors?.isactive && (
                <ErrorMessage message={errors?.isactive?.message} />
              )} */}
            </Col>

            <Col lg={3} md={4} sm={12}>
              <button className="custom-btn-page-layout btn text-white btn-block">
                <i className="fa fa-save" />
                {formData.id > 0 ? ` Edit` : ` Save`}
              </button>

              <button
                type="button"
                onClick={onCancelClick}
                className="custom-btn-page-layout btn text-white btn-block"
                style={{ margin: "0px 30px" }}
              >
                <i className="fa fa-cancel" />
                Cancel
              </button>
            </Col>
          </Row>
        </Form>
      </PageLayout>
    </>
  );
};

export default PurchaseInvoice;
