import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import PageLayout from "../../Component/PageLayout";
import {
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import TableContainer from "../../Component/TableContainer";
import { toast } from "react-toastify";
import Select from "react-select";
import { textFieldProps } from "../../common/commonControlProps";

import StateOption from "../../hooks/StateOptions";
import CountryOption from "../../hooks/CountryOption";
import DistrictOption from "../../hooks/DistrictOption";
import { useNavigate } from "react-router-dom";
import TalukaOption from "../../hooks/TalukaOption";
import VillageOption from "../../hooks/VillageOption";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import useBreakPoints from "../../hooks/useBreakPoints";
import FetchFarmer from "./FetchFarmer";
import ImageCapturePopup from "./ImageCapturePopup";
import CheckLandAvalibility from "../Farmer/CheckLandAvailibility";
import {
  TextField,
  Button as MuiButton,
  Grid,
  styled,
  Divider,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  IconButton,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
} from "@mui/material";
import {
  AccountBalance,
  Add,
  Agriculture,
  ChangeCircle,
  Check,
  CloudUpload,
  ControlPoint,
  Delete,
  Directions,
  Edit,
  ExpandLess,
  ExpandMore,
  FamilyRestroom,
  GetApp,
  Landscape,
  NavigateBefore,
  NavigateNext,
  Person2,
  PersonAdd,
  PhotoCamera,
  Save,
  Upload,
} from "@mui/icons-material";
import { ErrorMessage } from "../../common/Error";
import {
  commonActions,
  farmerActions,
  productActions,
} from "../../redux/actions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { keys } from "../../config/keys";
import dayjs from "dayjs";
import { GridAction } from "../../common/useGridAction";
import VirtualizeAutoComplete from "../../common/VirtualizedAutoComplete";
import ModalContainer from "../../Component/ModalContainer";
const genderArray = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
];

const panRegex = /^([A-Z]{5}[0-9]{4}[A-Z]{1})$/;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddFarmer = () => {
  const navigate = useNavigate();
  const {
    layout: { token, loginDetails },
    bank_list,
    relation_list,
  } = useSelector((state) => state);
  // eslint-disable-next-line
  const { isTablet, isMobile } = useBreakPoints();
  const dispatch = useDispatch();

  const [step, setStep] = useState("step4");
  // const [step, setStep] = useState("step6");
  const [aadharCardNo, setAadharCardNo] = useState("");
  const [selectedDropdownValues, setSelectedDropdownValues] = useState({
    village: null,
    taluka: null,
    district: null,
    dist: null,
    state: null,
    country: null,
  });
  const [selectedGuardianDropdownValues, setSelectedGuardianDropdownValues] =
    useState({
      guardianVillage: null,
      guardianTaluka: null,
      guardianDist: null,
      guardianState: null,
      guardianCountry: null,
    });
  const [updateState, setUpdateState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitloading, setSubmitLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [fetchDetails, setFetchDetails] = useState(true);
  const [editState, setEditState] = useState(false);
  // eslint-disable-next-line
  const [familyInputDataId, setFamilyInputDataId] = useState();
  const [countryArray, setCountryArray] = useState([]);

  const [stateArray, setStateArray] = useState([]);
  const [districtArray, setDistrictArray] = useState([]);
  const [talukaArray, setTalukaArray] = useState([]);
  const [villageArray, setVillageArray] = useState([]);
  const [showCapturePopup, setShowCapturePopup] = useState(false);
  // eslint-disable-next-line
  const [photo, setPhoto] = useState(null);
  const [availibilityModal, setAvailibilityModal] = useState(false);
  const [availibilityData, setAvailibilityData] = useState(false);
  const [availibilityDataIndex, setAvailibilityDataIndex] = useState();
  const [availibilityLoading, setAvailibilityLoading] = useState(false);
  const [checkAvailabilty, setCheckAvailabilty] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [selectedRelation, setSelectdeRelation] = useState(null);

  const { getStateOption } = StateOption();
  const { getCountryOption } = CountryOption();
  const { getDistrictOption } = DistrictOption();

  const { getTalukaOption } = TalukaOption();
  const { getVillageOption } = VillageOption();

  // LandDetails
  const [guardianStateArray, setGuardianStateArray] = useState([]);
  const [guardianDistrictArray, setGuardianDistrictArray] = useState([]);
  const [guardianTalukaArray, setGuardianTalukaArray] = useState([]);
  const [guardianVillageArray, setGuardianVillageArray] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [inputData, setInputData] = useState({
    farmerId: "",
    unit: "",
    farmerName: "",
    farmerCode: "",
    farmerPhoto: "",
    gender: "",
    email: "",
    dob: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    panNo: "",
    country: null,
    state: null,
    panCardImage: "",
    age: 16,
    dist: {
      label: "ANAND",
      value: 6,
    },
    village: null,
    taluka: null,
    city: "",
    aadharCardNo: "",
    pincode: "",
    phoneNo: "",
    aadharCardImage: "",
    mobileNo: "",
    ifscCode: "",
    bankName: "",
    accountNo: "",
    branchName: "",
    familyDetails: [],
    cancelCheque: "",
    primaryContactPerson: "",
  });

  const [singleFarmingDetails, setSingleFarmingDetails] = useState({
    product: "",
    category: "",
    vigha: "",
    totalVigha: "",
  });

  const [remainingLandinVigha, setRemainingLandinVigha] = useState(0);
  const [showConfirmSubmitModal, setShowConfirmSubmitModal] = useState(false);

  const [singleFarmingIndex, setSingleFarmingIndex] = useState();
  const [editFarmingDetails, setEditFarmingDetails] = useState(false);

  const [singleFarmingDetailsError, setSingleFarmingDetailsError] = useState({
    product: false,
    // category: false,
    vigha: false,
    totalVigha: false,
  });

  const [farmingDetails, setFarmingDetails] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const [landDetails, setLandDetails] = useState([]);

  const [landDetailsError, setLandDetailsError] = useState([
    {
      country: false,
      state: false,
      dist: false,
      taluka: false,
      village: false,
      surveyNo: false,
      totalLand: false,
      registeredLand: false,
      vigha: false,
      regVigha: false,
      ownerShipStatus: false,
      landOwnerName: false,
      address: false,
      guardianCountry: false,
      guardianState: false,
      guardianDist: false,
      guardianTaluka: false,
      guardianVillage: false,
      guardianPincode: false,
      guardianName: false,
      tenantDocument: false,
      agreementDocument: false,
      landDocument: false,
    },
  ]);

  const [selectedGroup, setSelectedGroup] = useState(null);
  const { product_group } = useSelector((obj) => obj);

  const [open, setOpen] = useState(-1);
  const toggle = (id) => {
    if (open === id) {
      setOpen(-1);
    } else {
      setOpen(id);
    }
  };

  const handleCapture = (imageData) => {
    handleImageChange("farmerPhoto", imageData);
  };

  const [inputDataError, setInputDataError] = useState({
    farmerName: false,
    unit: false,
    farmerPhoto: false,
    farmerCode: false,
    gender: false,
    dob: false,
    primaryContactPerson: false,
    addressLine1: false,
    addressLine2: false,
    addressLine3: false,
    // panNo: false,
    country: false,
    state: false,
    // panCardImage: false,
    dist: false,
    taluka: false,
    aadharCardNo: false,
    pincode: false,
    aadharCardImage: false,
    mobileNo: false,
    ifscCode: false,
    bankName: false,
    accountNo: false,
    branchName: false,
    cancelCheque: false,
    village: false,
    familyName: false,
    familyRelation: false,
    familyGender: false,
    // familyDob: false,
  });

  const [familyInputData, setFamilyInputData] = useState({
    name: "",
    relation: "",
    gender: "",
    dob: "",
  });

  const getUnitOptionsListAPi = useCallback(async () => {
    setLoading(true);
    try {
      let formData = new FormData();

      formData.append("companyId", loginDetails?.companyId);
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}company/unitsbycompany`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];

        // eslint-disable-next-line
        _data?.unitsData[0]?.unitdetails?.map((er) => {
          arr.push({ label: er?.unitName, value: er.id });
        });
        setUnitOptions(arr);

        const user = JSON.parse(localStorage.getItem("newtoken"));
        if (user?.unitid_id === null || user?.unitid_id <= 0) {
          setInputData({ ...inputData, unit: arr[0] });
        } else {
          const t = arr.find(
            (x) => Number(x.value) === Number(user?.unitid_id)
          );
          setInputData({ ...inputData, unit: t });
        }
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUnitOptionsListAPi();
  }, [getUnitOptionsListAPi]);

  // LAnd Details handleChange Input
  const handleVillageDropdownChange = (e, opt) => {
    console.log(opt);
  };

  const handleChangeLandDetailsInput = (index, name, value) => {
    let arr = [...landDetails];

    if (name === "surveyNo") {
      arr[index][name] = value;
      let arr9 = [...checkAvailabilty];
      arr9[index]["check"] = false;
      setCheckAvailabilty(arr9);
      let arr5 = [...landDetailsError];
      if (value?.length > 0) {
        arr5[index]["surveyNo"] = false;
      } else {
        arr5[index]["surveyNo"] = true;
      }
      setLandDetailsError(arr5);
    } else if (name === "regVigha") {
      if (arr[index]["remainLandVigha"]) {
        if (parseFloat(value) <= parseFloat(arr[index]["remainLandVigha"])) {
          arr[index][name] = value;
          arr[index]["registeredLand"] = +parseFloat(value * 2400).toFixed(3);

          let arr5 = [...landDetailsError];
          if (value?.length > 0) {
            arr5[index]["regVigha"] = false;
          } else {
            arr5[index]["regVigha"] = true;
          }
          setLandDetailsError(arr5);
        } else {
          if (value === "") {
            arr[index][name] = value;
            arr[index]["registeredLand"] = +parseFloat(value * 2400).toFixed(3);
            let arr5 = [...landDetailsError];
            if (value?.length > 0) {
              arr5[index]["regVigha"] = false;
            } else {
              arr5[index]["regVigha"] = true;
            }
            setLandDetailsError(arr5);
          }
        }
      } else {
        if (parseFloat(value) <= parseFloat(arr[index]["vigha"])) {
          arr[index][name] = value;
          arr[index]["registeredLand"] = +parseFloat(value * 2400).toFixed(3);

          let arr5 = [...landDetailsError];
          if (value?.length > 0) {
            arr5[index]["regVigha"] = false;
          } else {
            arr5[index]["regVigha"] = true;
          }
          setLandDetailsError(arr5);
        } else {
          if (value === "") {
            arr[index][name] = value;
            arr[index]["registeredLand"] = +parseFloat(value * 2400).toFixed(3);

            let arr5 = [...landDetailsError];
            if (value?.length > 0) {
              arr5[index]["regVigha"] = false;
            } else {
              arr5[index]["regVigha"] = true;
            }
            setLandDetailsError(arr5);
          }
        }
      }
    } else if (name === "totalLand") {
      arr[index][name] = value;
      arr[index]["vigha"] = +parseFloat(value / 2400).toFixed(3);
      let arr5 = [...landDetailsError];
      if (value?.length > 0) {
        arr5[index]["totalLand"] = false;
      } else {
        arr5[index]["totalLand"] = true;
      }
      setLandDetailsError(arr5);
    } else if (name === "vigha") {
      arr[index][name] = value;
      let arr5 = [...landDetailsError];
      if (value?.length > 0) {
        arr5[index]["vigha"] = false;
      } else {
        arr5[index]["vigha"] = true;
      }
      setLandDetailsError(arr5);
    } else if (name === "guardianPincode") {
      arr[index][name] = value;
      let arr5 = [...landDetailsError];
      if (value?.length === 6) {
        arr5[index]["guardianPincode"] = false;
      } else {
        arr5[index]["guardianPincode"] = true;
      }
      setLandDetailsError(arr5);
    } else if (name === "address") {
      arr[index][name] = value;
      let arr5 = [...landDetailsError];
      if (value?.length > 0) {
        arr5[index]["address"] = false;
      } else {
        arr5[index]["address"] = true;
      }
      setLandDetailsError(arr5);
    } else {
      arr[index][name] = value;
    }

    // if (name === "country") {
    //   getStateOption(value.value)
    //     .then((res) => {
    //       const updatedLandDetails = [...landDetails];
    //       updatedLandDetails[index][name] = value;
    //       updatedLandDetails[index]["state"] = "";
    //       updatedLandDetails[index]["dist"] = "";
    //       updatedLandDetails[index]["taluka"] = "";
    //       updatedLandDetails[index]["village"] = "";
    //       updatedLandDetails[index] = {
    //         ...updatedLandDetails[index],
    //         stateArray: res,
    //       };

    //       setLandDetails(updatedLandDetails);
    //     })

    //     .catch((error) => {
    //       console.error("Error fetching state options:", error);
    //     });

    //   let arr5 = [...landDetailsError];
    //   if (value?.label?.length > 0) {
    //     arr5[index]["country"] = false;
    //   } else {
    //     arr5[index]["country"] = true;
    //   }
    //   setLandDetailsError(arr5);
    // } else if (name === "state") {
    //   getDistrictOption(value.value)
    //     .then((res) => {
    //       const updatedLandDetails = [...landDetails];
    //       updatedLandDetails[index][name] = value;
    //       updatedLandDetails[index]["dist"] = "";
    //       updatedLandDetails[index]["taluka"] = "";
    //       updatedLandDetails[index]["village"] = "";
    //       updatedLandDetails[index] = {
    //         ...updatedLandDetails[index],
    //         districtArray: res,
    //       };

    //       setLandDetails(updatedLandDetails);
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching state options:", error);
    //     });
    //   let arr5 = [...landDetailsError];
    //   if (value?.label?.length > 0) {
    //     arr5[index]["state"] = false;
    //   } else {
    //     arr5[index]["state"] = true;
    //   }
    //   setLandDetailsError(arr5);
    // } else if (name === "dist") {
    //   getTalukaOption(value.value)
    //     .then((res) => {
    //       const updatedLandDetails = [...landDetails];
    //       updatedLandDetails[index][name] = value;
    //       updatedLandDetails[index]["taluka"] = "";
    //       updatedLandDetails[index]["village"] = "";
    //       updatedLandDetails[index] = {
    //         ...updatedLandDetails[index],
    //         talukaArray: res,
    //       };

    //       setLandDetails(updatedLandDetails);
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching state options:", error);
    //     });
    //   let arr5 = [...landDetailsError];
    //   if (value?.label?.length > 0) {
    //     arr5[index]["dist"] = false;
    //   } else {
    //     arr5[index]["dist"] = true;
    //   }
    //   setLandDetailsError(arr5);
    // } else if (name === "taluka") {
    //   getVillageOption(value.value)
    //     .then((res) => {
    //       const updatedLandDetails = [...landDetails];
    //       updatedLandDetails[index][name] = value;

    //       updatedLandDetails[index]["village"] = "";
    //       updatedLandDetails[index] = {
    //         ...updatedLandDetails[index],
    //         villageArray: res,
    //       };

    //       setLandDetails(updatedLandDetails);
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching state options:", error);
    //     });
    //   let arr5 = [...landDetailsError];
    //   if (value?.label?.length > 0) {
    //     arr5[index]["taluka"] = false;
    //   } else {
    //     arr5[index]["taluka"] = true;
    //   }
    //   setLandDetailsError(arr5);
    // } else if (name === "village") {
    //   let arr5 = [...landDetailsError];
    //   let arr9 = [...checkAvailabilty];
    //   arr9[index]["check"] = false;
    //   setCheckAvailabilty(arr9);

    //   if (value?.label?.length > 0) {
    //     arr5[index]["village"] = false;
    //   } else {
    //     arr5[index]["village"] = true;
    //   }
    //   setLandDetailsError(arr5);
    // }

    // if (name === "guardianCountry") {
    //   getStateOption(value.value).then((res) => {
    //     setGuardianStateArray(res);
    //   });
    //   let arr5 = [...landDetailsError];
    //   if (value?.label?.length > 0) {
    //     arr5[index]["guardianCountry"] = false;
    //   } else {
    //     arr5[index]["guardianCountry"] = true;
    //   }
    //   setLandDetailsError(arr5);
    // }
    // if (name === "guardianState") {
    //   getDistrictOption(value.value).then((res) => {
    //     setGuardianDistrictArray(res);
    //   });
    //   let arr5 = [...landDetailsError];
    //   if (value?.label?.length > 0) {
    //     arr5[index]["guardianState"] = false;
    //   } else {
    //     arr5[index]["guardianState"] = true;
    //   }
    //   setLandDetailsError(arr5);
    // }
    // if (name === "guardianDist") {
    //   getTalukaOption(value.value).then((res) => {
    //     setGuardianTalukaArray(res);
    //   });

    //   let arr5 = [...landDetailsError];
    //   if (value?.label?.length > 0) {
    //     arr5[index]["guardianDist"] = false;
    //   } else {
    //     arr5[index]["guardianDist"] = true;
    //   }
    //   setLandDetailsError(arr5);
    // }
    // if (name === "guardianTaluka") {
    //   getVillageOption(value.value).then((res) => {
    //     setGuardianVillageArray(res);
    //   });
    //   let arr5 = [...landDetailsError];
    //   if (value?.label?.length > 0) {
    //     arr5[index]["guardianTaluka"] = false;
    //   } else {
    //     arr5[index]["guardianTaluka"] = true;
    //   }
    //   setLandDetailsError(arr5);
    // }

    if (name === "ownerShipStatus") {
      if (value) {
        arr[index]["guardianCountry"] = inputData?.country;
        arr[index]["guardianState"] = inputData?.state;
        arr[index]["guardianDist"] = inputData?.dist;
        arr[index]["guardianTaluka"] = inputData?.taluka;
        arr[index]["guardianVillage"] = inputData?.village;
        arr[index]["address"] =
          inputData?.addressLine1 +
          inputData?.addressLine2 +
          inputData?.addressLine3;
      } else {
        arr[index]["guardianCountry"] = inputData?.country;
        arr[index]["guardianState"] = inputData?.state;
        arr[index]["guardianDist"] = inputData?.dist;
        arr[index]["guardianTaluka"] = inputData?.taluka;
        arr[index]["guardianVillage"] = inputData?.village;
        arr[index]["address"] =
          inputData?.addressLine1 +
          inputData?.addressLine2 +
          inputData?.addressLine3;
      }
    }

    setLandDetails(arr);
  };

  const handleDeleteLandDetails = (index) => {
    let arr = [...landDetails];

    arr.splice(index, 1);
    setLandDetails(arr);

    let arr5 = [...landDetailsError];
    arr5.splice(index, 1);
    setLandDetailsError(arr5);

    let arr6 = [...checkAvailabilty];
    arr6.splice(index, 1);
    setCheckAvailabilty(arr6);
  };

  // eslint-disable-next-line
  const handleImageLandDetailsChange = async (index, name, value) => {
    if (value) {
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!allowedTypes.includes(value.type)) {
        toast.error("Invalid file type. Please select a JPEG, PNG.");
      } else if (value.size > maxSize) {
        toast.error("File size exceeds the limit of 5MB.");
      } else {
        if (name === "landDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["landDocument"] = false;

          setLandDetailsError(arr5);
        } else if (name === "tenantDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["tenantDocument"] = false;
          setLandDetailsError(arr5);
        } else if (name === "agreementDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["agreementDocument"] = false;
          setLandDetailsError(arr5);
        }
        const formData = new FormData();
        formData.append("media", value);

        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/uploadmedia`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          let arr = [...landDetails];
          arr[index][name] = { url: _data.uploadedURL, name: _data?.filename };
          setLandDetails(arr);
        } else {
          toast.error(_data?.error);
        }

        return { url: "https://httpbin.org/post" };
      }
    }
  };

  // handleChange Input
  const handleChangeInput = (name, value) => {
    if (name === "farmerName") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, farmerName: false });
      } else {
        setInputDataError({ ...inputDataError, farmerName: true });
      }
    } else if (name === "farmerCode") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, farmerCode: false });
      } else {
        setInputDataError({ ...inputDataError, farmerCode: true });
      }
    } else if (name === "primaryContactPerson") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, primaryContactPerson: false });
      } else {
        setInputDataError({ ...inputDataError, primaryContactPerson: true });
      }
    } else if (name === "unit") {
      setInputData({ ...inputData, [name]: value });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, unit: false });
      } else {
        setInputDataError({ ...inputDataError, unit: true });
      }
    } else if (name === "gender") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, gender: false });
      } else {
        setInputDataError({ ...inputDataError, gender: true });
      }
    } else if (name === "dob") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, dob: false });
      } else {
        setInputDataError({ ...inputDataError, dob: true });
      }
    } else if (name === "addressLine1") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, addressLine1: false });
      } else {
        setInputDataError({ ...inputDataError, addressLine1: true });
      }
    } else if (name === "addressLine2") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, addressLine2: false });
      } else {
        setInputDataError({ ...inputDataError, addressLine2: true });
      }
    } else if (name === "addressLine3") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, addressLine3: false });
      } else {
        setInputDataError({ ...inputDataError, addressLine3: true });
      }
    } else if (name === "panNo") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0 && panRegex.test(value)) {
        setInputDataError({ ...inputDataError, panNo: false });
      } else {
        setInputDataError({ ...inputDataError, panNo: true });
      }
    } else if (name === "village") {
      setInputData({ ...inputData, [name]: value });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, village: false });
      } else {
        setInputDataError({ ...inputDataError, village: true });
      }
    } else if (name === "aadharCardNo") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length === 12) {
        setInputDataError({ ...inputDataError, aadharCardNo: false });
      } else {
        setInputDataError({ ...inputDataError, aadharCardNo: true });
      }
    } else if (name === "pincode") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length === 6) {
        setInputDataError({ ...inputDataError, pincode: false });
      } else {
        setInputDataError({ ...inputDataError, pincode: true });
      }
    } else if (name === "mobileNo") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length === 10) {
        setInputDataError({ ...inputDataError, mobileNo: false });
      } else {
        setInputDataError({ ...inputDataError, mobileNo: true });
      }
    } else if (name === "bankName") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, bankName: false });
      } else {
        setInputDataError({ ...inputDataError, bankName: true });
      }
    } else if (name === "ifscCode") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0 && validateIFSCCode(value)) {
        setInputDataError({ ...inputDataError, ifscCode: false });
      } else {
        setInputDataError({ ...inputDataError, ifscCode: true });
      }
    } else if (name === "accountNo") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, accountNo: false });
      } else {
        setInputDataError({ ...inputDataError, accountNo: true });
      }
    } else if (name === "branchName") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, branchName: false });
      } else {
        setInputDataError({ ...inputDataError, branchName: true });
      }
    }

    if (name === "country") {
      getStateOption(value.value).then((res) => {
        setStateArray(res);
      });
      setInputData({
        ...inputData,
        [name]: value,
        state: "",
        dist: "",
        taluka: "",
        village: "",
      });

      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, country: false });
      } else {
        setInputDataError({ ...inputDataError, country: true });
      }
    } else if (name === "state") {
      getDistrictOption(value.value).then((res) => {
        setDistrictArray(res);
      });
      setInputData({
        ...inputData,
        [name]: value,
        dist: "",
        taluka: "",
        village: "",
      });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, state: false });
      } else {
        setInputDataError({ ...inputDataError, state: true });
      }
    } else if (name === "dist") {
      getTalukaOption(value.value).then((res) => {
        setTalukaArray(res);
      });
      setInputData({ ...inputData, [name]: value, taluka: "", village: "" });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, dist: false });
      } else {
        setInputDataError({ ...inputDataError, dist: true });
      }
    } else if (name === "taluka") {
      getVillageOption(value.value).then((res) => {
        setVillageArray(res);
      });
      setInputData({ ...inputData, [name]: value, village: "" });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, taluka: false });
      } else {
        setInputDataError({ ...inputDataError, taluka: true });
      }
    } else {
      setInputData({ ...inputData, [name]: value });
    }
  };
  // handleChange Family Details Input
  const handleChangFamilyDetailsInput = (name, value) => {
    if (name === "name") {
      setFamilyInputData({ ...familyInputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, familyName: false });
      } else {
        setInputDataError({ ...inputDataError, familyName: true });
      }
    } else if (name === "relation") {
      setFamilyInputData({ ...familyInputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, familyRelation: false });
      } else {
        setInputDataError({ ...inputDataError, familyRelation: true });
      }
    } else if (name === "gender") {
      setFamilyInputData({ ...familyInputData, [name]: value });

      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, familyGender: false });
      } else {
        setInputDataError({ ...inputDataError, familyGender: true });
      }
    }
  };

  // Age Calculation Function
  function calculateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const now = new Date();

    let age = now.getFullYear() - dob.getFullYear();

    // Check if the birthday for the current year has already occurred
    // If not, subtract 1 from the age
    if (
      now.getMonth() < dob.getMonth() ||
      (now.getMonth() === dob.getMonth() && now.getDate() < dob.getDate())
    ) {
      age--;
    }

    return age;
  }

  // handle Add Btn
  const handleAddIconStep2 = () => {
    if (!familyInputData?.name) {
      setInputDataError({ ...inputDataError, familyName: true });
      toast.error("Name is Required!");
    } else if (!familyInputData?.relation) {
      setInputDataError({ ...inputDataError, familyRelation: true });
      toast.error("Relation  is Required!");
    } else if (!familyInputData?.gender) {
      setInputDataError({ ...inputDataError, familyGender: true });
      toast.error("Gender is Required!");
    }
    // else if (!familyInputData?.dob) {
    //   setInputDataError({ ...inputDataError, familyDob: true });
    //   toast.error("dob is Required!");
    // }
    else {
      if (editState) {
        let filteredData = inputData?.familyDetails?.map((er) => {
          if (er?.id === familyInputData.id) {
            let newData = {
              id: familyInputData?.id,
              farmerId: inputData?.farmerId,
              name: familyInputData?.name,
              relation: familyInputData?.relation,
              gender: familyInputData?.gender?.value,
              dob: familyInputData?.dob || "",
              age: calculateAge(familyInputData?.dob),
            };
            return {
              ...er,
              ...newData,
            };
          }
          return er;
        });

        setInputData({ ...inputData, familyDetails: filteredData });
        setFamilyInputData({ name: "", relation: "", gender: "", dob: "" });
        setEditState(false);
        setSelectdeRelation(null);
      } else {
        let arr = [...inputData?.familyDetails];

        arr.push({
          id: inputData?.familyDetails?.length + 1,
          farmerId: inputData?.farmerId,
          name: familyInputData?.name,
          relation: familyInputData?.relation,
          gender: familyInputData?.gender?.value,
          dob: familyInputData?.familydobstring,
          age: calculateAge(familyInputData?.dob),
        });

        setInputData({ ...inputData, familyDetails: arr });
        setFamilyInputData({ name: "", relation: "", gender: "", dob: "" });
        setEditState(false);
        setSelectdeRelation(null);
      }
    }
  };

  const handleDeleteIconStep2 = (index) => {
    let arr = [...inputData?.familyDetails];
    arr.splice(index, 1);
    setInputData({ ...inputData, familyDetails: arr });
  };

  // Farmer Upload Photo
  const farmerPhotoImage = useRef(null);
  const panCardPhotoImage = useRef(null);
  const aadharCardPhotoImage = useRef(null);
  const cancelChequeImage = useRef(null);

  // Image HandleChange
  // eslint-disable-next-line
  const handlefarmerPhotoImageUploadBtn = () =>
    farmerPhotoImage.current.click();
  const handlepanCardPhotoImageUploadBtn = () =>
    panCardPhotoImage.current.click();
  const handleaadharCardPhotoImageUploadBtn = () =>
    aadharCardPhotoImage.current.click();
  const handlecancelChequeImageUploadBtn = () =>
    cancelChequeImage.current.click();

  //   HandleChange Farmer Photo
  const handleImageChange = async (name, value) => {
    if (value) {
      const allowedTypes = ["image/jpeg", "image/png"];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!allowedTypes.includes(value.type)) {
        toast.error("Invalid file type. Please select a JPEG, PNG.");
      } else if (value.size > maxSize) {
        toast.error("File size exceeds the limit of 5MB.");
      } else {
        if (name === "farmerPhoto") {
          setInputDataError({ ...inputDataError, farmerPhoto: false });
        } else if (name === "aadharCardImage") {
          setInputDataError({ ...inputDataError, aadharCardImage: false });
        }
        // else if (name === "panCardImage") {
        //   setInputDataError({ ...inputDataError, panCardImage: false });
        // }
        else if (name === "cancelCheque") {
          setInputDataError({ ...inputDataError, cancelCheque: false });
        }

        const formData = new FormData();
        formData.append("media", value);

        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/uploadmedia`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          setInputData({
            ...inputData,
            [name]: { url: _data.uploadedURL, name: _data?.filename },
          });
        } else {
          toast.error(_data?.error);
        }

        return { url: "https://httpbin.org/post" };
      }
    }
  };

  const handleFarmerImageChange = async (name, value) => {
    if (value) {
      const allowedTypes = ["image/jpeg", "image/png"];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!allowedTypes.includes(value.type)) {
        toast.error("Invalid file type. Please select a JPEG, PNG.");
      } else if (value.size > maxSize) {
        toast.error("File size exceeds the limit of 5MB.");
      } else {
        if (name === "farmerPhoto") {
          setInputDataError({ ...inputDataError, farmerPhoto: false });
        }
        const formData = new FormData();
        formData.append("media", value);

        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/uploadmedia`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          setPhoto({ url: _data.uploadedURL, name: _data?.filename });
        } else {
          toast.error(_data?.error);
        }

        return { url: "https://httpbin.org/post" };
      }
    }
  };

  // eslint-disable-next-line
  function isValidEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  const validateIFSCCode = (code) => {
    // Regular expression pattern for IFSC code validation
    const ifscPattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;

    return ifscPattern.test(code);
  };

  // handleNext Btn
  const handleNextBtn = (stepper) => {
    if (stepper === "step1") {
      if (!inputData?.farmerName) {
        toast.error("Farmer Name is Required!");
        setInputDataError({ ...inputDataError, farmerName: true });
      } else if (!inputData.unit) {
        setInputDataError({ ...inputDataError, unit: true });
        toast.error("Unit Is required!");
      } else if (!inputData.farmerCode) {
        setInputDataError({ ...inputDataError, farmerCode: true });
        toast.error("Farmer Code Is required!");
      }
      // else if (!inputData.farmerPhoto) {
      //   setInputDataError({ ...inputDataError, farmerPhoto: true });
      //   toast.error("Farmer Photo is Required!");
      // }
      else if (!inputData?.gender) {
        setInputDataError({ ...inputDataError, gender: true });

        toast.error("Gender is Required!");

        // ####################################################################################################
        // ####################################################################################################
        // start Remove validation For Date Entry on 12/10/2023
        // ####################################################################################################
        // ####################################################################################################
        // } else if (!inputData?.dob) {
        //   setInputDataError({ ...inputDataError, dob: true });

        //   toast.error("DOB is Required!");
        // } else if (!inputData?.addressLine1) {
        //   setInputDataError({ ...inputDataError, addressLine1: true });

        //   toast.error("Address Line 1 is Required!");
        // } else if (!inputData?.addressLine2) {
        //   setInputDataError({ ...inputDataError, addressLine2: true });

        //   toast.error("Address Line 2 is Required!");

        // ####################################################################################################
        // ####################################################################################################
        // End Remove validation For Date Entry on 12/10/2023
        // ####################################################################################################
        // ####################################################################################################
      }
      // else if (!inputData?.panNo) {
      //   setInputDataError({ ...inputDataError, panNo: true });

      //   toast.error("Pan No  is Required!");
      // }
      else if (
        inputData?.panNo?.length > 0 &&
        !panRegex.test(inputData?.panNo)
      ) {
        setInputDataError({ ...inputDataError, panNo: true });

        toast.error("PAN No is not in Correct Format!");
      } else if (!inputData?.country) {
        setInputDataError({ ...inputDataError, country: true });

        toast.error("Country is Required!");
      } else if (!inputData?.state) {
        setInputDataError({ ...inputDataError, state: true });

        toast.error("State is Required!");
      }
      // else if (!inputData?.panCardImage) {
      //   setInputDataError({ ...inputDataError, panCardImage: true });

      //   toast.error("PanCard Image is Required!");
      // }
      else if (!inputData?.dist) {
        setInputDataError({ ...inputDataError, dist: true });

        toast.error("Dist is Required!");
      } else if (!inputData?.taluka) {
        setInputDataError({ ...inputDataError, taluka: true });

        toast.error("taluka is Required!");
      } else if (!inputData?.village) {
        setInputDataError({ ...inputDataError, village: true });

        toast.error("Village is Required!");
      } else if (!inputData?.pincode) {
        toast.error("Pincode is Required!");
        setInputDataError({ ...inputDataError, pincode: true });
      } else if (
        inputData?.pincode?.length < 6 ||
        inputData?.pincode?.length > 6
      ) {
        toast.error("Pincode is not in Correct Format!");
        setInputDataError({ ...inputDataError, pincode: true });
      } else if (
        (inputData?.phoneNo && inputData?.phoneNo?.length < 10) ||
        inputData?.phoneNo?.length > 10
      ) {
        toast.error("phone No is not in Correct Format!");
      } else if (!inputData?.mobileNo) {
        setInputDataError({ ...inputDataError, mobileNo: true });

        toast.error("Mobile No is Required!");
      } else if (
        (inputData?.mobileNo && inputData?.mobileNo?.length < 10) ||
        inputData?.mobileNo?.length > 10
      ) {
        setInputDataError({ ...inputDataError, mobileNo: true });

        toast.error("Mobile No is not in Correct Format!");

        // ####################################################################################################
        // ####################################################################################################
        // start Remove validation For Date Entry on 12/10/2023
        // ####################################################################################################
        // ####################################################################################################

        // } else if (!inputData?.primaryContactPerson) {
        //   toast.error("Designated Successor is Required!");
        //   setInputDataError({ ...inputDataError, primaryContactPerson: true });

        // ####################################################################################################
        // ####################################################################################################
        // End Remove validation For Date Entry on 12/10/2023
        // ####################################################################################################
        // ####################################################################################################
      } else {
        setStep("step2");
      }
    }

    if (stepper === "step2") {
      // ####################################################################################################
      // ####################################################################################################
      // start Remove validation For Date Entry on 12/10/2023
      // ####################################################################################################
      // ####################################################################################################

      // Condition Added Based on Dicussion on 16-09-2023 by Dweep Sir
      // if (inputData?.familyDetails?.length <= 0) {
      //   toast.error("minimum of one Family Detail is Required");
      // } else {
      setStep("step3");
      // }
    }

    if (stepper === "step3") {
      // if (!inputData?.bankName) {
      //   setInputDataError({ ...inputDataError, bankName: true });
      //   toast.error("Bank Name is Required!");
      // } else if (!inputData?.ifscCode) {
      //   setInputDataError({ ...inputDataError, ifscCode: true });
      //   toast.error("IFSC Code   is Required!");
      // }
      // else if (!validateIFSCCode(inputData?.ifscCode)) {
      //   setInputDataError({ ...inputDataError, ifscCode: true });
      //   toast.error("IFSC Code is not in Correct Format!");
      // }
      // else if (!inputData?.accountNo) {
      //   setInputDataError({ ...inputDataError, accountNo: true });
      //   toast.error("Account No is Required!");

      //   // ####################################################################################################
      //   // ####################################################################################################
      //   // start Remove validation For Date Entry on 12/10/2023
      //   // ####################################################################################################
      //   // ####################################################################################################

      //   // } else if (!inputData?.branchName) {
      //   //   setInputDataError({ ...inputDataError, branchName: true });
      //   //   toast.error("Branch Name is Required!");

      //   // ####################################################################################################
      //   // ####################################################################################################
      //   // End Remove validation For Date Entry on 12/10/2023
      //   // ####################################################################################################
      //   // ####################################################################################################
      // }
      // else if (!inputData?.cancelCheque) {
      //   setInputDataError({ ...inputDataError, cancelCheque: true });
      //   toast.error("Cancel Cheque is Required!");
      // }
      // else {
      setStep("step6");
      //}
    }

    if (stepper === "step5") {
      let error = false;

      // eslint-disable-next-line
      landDetails?.map((er, index) => {
        if (!er?.country) {
          toast.error("Country is Required!");
          let arr = [...landDetailsError];
          arr[index]["country"] = true;
          setLandDetailsError(arr);
          error = true;
        } else if (!er?.state) {
          toast?.error("State is Required!");
          let arr = [...landDetailsError];
          arr[index]["state"] = true;
          setLandDetailsError(arr);
          error = true;
        } else if (!er?.dist) {
          toast?.error("District is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["dist"] = true;
          setLandDetailsError(arr);
        } else if (!er?.taluka) {
          toast?.error("Taluka is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["taluka"] = true;
          setLandDetailsError(arr);
        } else if (!er?.village) {
          toast?.error("village is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["village"] = true;
          setLandDetailsError(arr);
        }
        // else if (!er?.address) {
        //   toast?.error("address is Required!");
        //   error = true;
        //   let arr = [...landDetailsError];
        //   arr[index]["address"] = true;
        //   setLandDetailsError(arr);
        // }
        else if (!er?.surveyNo) {
          toast?.error("surveyNo is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["surveyNo"] = true;
          setLandDetailsError(arr);
        } else if (!er?.totalLand) {
          toast?.error("Total Land is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["totalLand"] = true;
          setLandDetailsError(arr);
        } else if (!er?.vigha) {
          toast?.error("Vigha is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["vigha"] = true;
          setLandDetailsError(arr);
        } else if (!er?.registeredLand) {
          toast?.error("Registered Land is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["registeredLand"] = true;
          setLandDetailsError(arr);
        } else if (!er?.regVigha) {
          toast?.error("Register Vigha is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["regVigha"] = true;
          setLandDetailsError(arr);
        }
        // else if (!er?.address) {
        //   toast?.error("Country is Required!");
        //   error = true;
        //   let arr = [...landDetailsError];
        //   arr[index]["address"] = true;
        //   setLandDetailsError(arr);
        // }
        // else if (!er?.guardianCountry) {
        //   toast?.error("G Country is Required!");
        //   error = true;
        //   let arr = [...landDetailsError];
        //   arr[index]["guardianCountry"] = true;
        //   setLandDetailsError(arr);
        // }
        // else if (!er?.guardianState) {
        //   toast?.error("State is Required!");
        //   error = true;
        //   let arr = [...landDetailsError];
        //   arr[index]["guardianState"] = true;
        //   setLandDetailsError(arr);
        // } else if (!er?.guardianDist) {
        //   toast?.error("District is Required!");
        //   error = true;
        //   let arr = [...landDetailsError];
        //   arr[index]["guardianDist"] = true;
        //   setLandDetailsError(arr);
        // } else if (!er?.guardianTaluka) {
        //   toast?.error("Taluka is Required!");
        //   error = true;
        //   let arr = [...landDetailsError];
        //   arr[index]["guardianTaluka"] = true;
        //   setLandDetailsError(arr);
        // } else if (!er?.guardianVillage) {
        //   toast?.error("village is Required!");
        //   error = true;
        //   let arr = [...landDetailsError];
        //   arr[index]["guardianVillage"] = true;
        //   setLandDetailsError(arr);
        // }
        // else if (!er?.guardianPincode) {
        //   toast.error("Pincode is Required!");
        //   error = true;
        //   let arr = [...landDetailsError];
        //   arr[index]["guardianPincode"] = true;
        //   setLandDetailsError(arr);
        // }
        // else if (
        //   er?.guardianPincode?.length < 6 ||
        //   er?.guardianPincode?.length > 6
        // ) {
        //   toast.error("Pincode is not in Correct Format!");
        //   error = true;
        //   let arr = [...landDetailsError];
        //   arr[index]["guardianPincode"] = true;
        //   setLandDetailsError(arr);
        // }
        // else if (!er?.landDocument) {
        //   toast?.error("LandDocument is Required!");
        //   error = true;
        //   let arr = [...landDetailsError];
        //   arr[index]["landDocument"] = true;
        //   setLandDetailsError(arr);
        // }
        else if (!er?.tenantDocument && er?.ownerShipStatus) {
          toast?.error("Tenant Document is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["tenantDocument"] = true;
          setLandDetailsError(arr);
        } else if (!er?.agreementDocument && er?.ownerShipStatus) {
          toast?.error("Agreement Document is Required!");
          error = true;
          let arr = [...landDetailsError];
          arr[index]["agreementDocument"] = true;
          setLandDetailsError(arr);
        } else if (!checkAvailabilty[index]?.check) {
          toast?.error("Please Check Land Availabilty");
          error = true;
        }
      });

      if (landDetails?.length <= 0) {
        toast.error("Minimum one land is Required");
      } else if (!inputData?.aadharCardNo) {
        setInputDataError({ ...inputDataError, aadharCardNo: true });

        toast.error("AadharCard No is Required!");
      } else if (
        (inputData?.aadharCardNo && inputData?.aadharCardNo?.length < 12) ||
        inputData?.aadharCardNo?.length > 12
      ) {
        toast.error("AadharCard No is not in Correct Format!");
        setInputDataError({ ...inputDataError, aadharCardNo: true });
      }
      //  else if (!inputData?.aadharCardImage) {
      //   toast.error("AadharCard Image is Required!");
      //   setInputDataError({ ...inputDataError, aadharCardImage: true });
      // }
      else if (!error) {
        setStep("step1");

        let totalVigha = landDetails?.reduce(
          (acc, curr) => acc + Number(curr?.regVigha),
          0
        );
        setSingleFarmingDetails({
          ...singleFarmingDetails,
          totalVigha: Number(totalVigha).toFixed(3),
        });
      }
    }
  };

  // step2 Table Column
  const column = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
      },
      {
        Header: "Gender",
        accessor: "gender",
        filterable: false,
      },
      {
        Header: "Relation",
        accessor: "relation",
        filterable: false,
      },
      {
        Header: "DOB",
        accessor: "dob",
        filterable: false,
        Cell: (props) => keys().shortDate(props.value),
      },

      {
        id: "Action",
        Header: () => (
          <span
            className="d-flex justify-content-center"
            style={{ textAlign: "center" }}
          >
            Action
          </span>
        ),
        Cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <div className="hstack gap-2">
                {/* Edit Button */}
                <MuiButton
                  className="border-0"
                  style={{ background: "transparent" }}
                  onClick={() =>
                    handleEditStateFamilyDetails(
                      cellProps.row.original,
                      cellProps.row.index
                    )
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_32_318)">
                      <path
                        d="M6.87549 2.5H2.50049C2.16897 2.5 1.85103 2.6317 1.6166 2.86612C1.38218 3.10054 1.25049 3.41848 1.25049 3.75V12.5C1.25049 12.8315 1.38218 13.1495 1.6166 13.3839C1.85103 13.6183 2.16897 13.75 2.50049 13.75H11.2505C11.582 13.75 11.9 13.6183 12.1344 13.3839C12.3688 13.1495 12.5005 12.8315 12.5005 12.5V8.125"
                        stroke="#3E7531"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.563 1.56249C11.8116 1.31385 12.1489 1.17416 12.5005 1.17416C12.8521 1.17416 13.1893 1.31385 13.438 1.56249C13.6866 1.81113 13.8263 2.14836 13.8263 2.49999C13.8263 2.85162 13.6866 3.18885 13.438 3.43749L7.50049 9.37499L5.00049 9.99999L5.62549 7.49999L11.563 1.56249Z"
                        stroke="#3E7531"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_32_318">
                        <rect
                          width="15"
                          height="15"
                          fill="white"
                          transform="translate(0.000488281)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </MuiButton>

                {/* Delete Button */}
                <button
                  className="border-0"
                  style={{ background: "transparent" }}
                  onClick={() => handleDeleteIconStep2(cellProps.row.index)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_32_330)">
                      <path
                        d="M6.63507 -0.0178833C5.50545 -0.0178833 4.59795 0.92077 4.59795 2.05558V2.30769H2.30814C1.99026 2.30769 1.73122 2.56673 1.73122 2.88462V3.46154H1.1543V4.61539H2.30814V13.2692C2.30814 14.2183 3.08987 15 4.03891 15H10.962C11.911 15 12.6928 14.2183 12.6928 13.2692V4.61539H13.8466V3.46154H13.2697V2.88462C13.2697 2.56673 13.0106 2.30769 12.6928 2.30769H10.403V2.055C10.403 0.920771 9.49545 -0.0178833 8.36584 -0.0178833H6.63507ZM6.63507 1.17173H8.36584C8.82968 1.17173 9.21334 1.55019 9.21334 2.055V2.30769H5.78699V2.055C5.78699 1.55019 6.17064 1.17231 6.63449 1.17231L6.63507 1.17173ZM3.46199 4.61539H6.41872C6.49026 4.62289 6.56122 4.63327 6.63507 4.63327H8.36584C8.43968 4.63327 8.51007 4.62289 8.58218 4.61539H11.5389V13.2692C11.5389 13.594 11.2868 13.8462 10.962 13.8462H4.03891C3.7141 13.8462 3.46199 13.594 3.46199 13.2692V4.61539ZM4.61584 5.76923V12.6923H5.76968V5.76923H4.61584ZM6.92353 5.76923V12.6923H8.07737V5.76923H6.92353ZM9.23122 5.76923V12.6923H10.3851V5.76923H9.23122Z"
                        fill="#EC0505"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_32_330">
                        <rect
                          width="15"
                          height="15"
                          fill="white"
                          transform="translate(0.000488281)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>
          );
        },
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    [inputData?.familyDetails]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (landDetails?.length <= 0) {
      toast.error("Minimum one land is Required");
    } else if (farmingDetails?.length <= 0) {
      toast.error("Minimum Farming Details is Required");
    } else {
      setSubmitLoading(true);
      let formData = new FormData();

      // formData.append("companyId", "1");
      // formData.append("unitId", "1");
      // formData.append("financialYearId", "1");
      formData.append("name", inputData?.farmerName);
      formData.append("farmerCode", inputData?.farmerCode);
      formData.append("primaryContactPerson", inputData?.primaryContactPerson);
      formData.append("unitId", inputData.unit.value);
      formData.append("email", inputData?.email);
      if (inputData.phoneNo?.length > 0) {
        formData.append("phoneNo", `+91${inputData?.phoneNo}`);
      }
      if (inputData.mobileNo?.length > 0) {
        formData.append("mobileNo", `+91${inputData?.mobileNo}`);
      }
      formData.append("gender", inputData?.gender);
      if (inputData?.dob) {
        formData.append("dob", inputData?.dobstring);
      }

      formData.append("addressLine1", inputData?.addressLine1);
      formData.append("addressLine2", inputData?.addressLine2);
      formData.append("addressLine3", inputData?.addressLine3);
      formData.append("countryId", inputData?.country?.value);
      formData.append("stateId", inputData?.state?.value);
      formData.append("districtId", inputData?.dist?.value);
      formData.append("talukaId", inputData?.taluka.value);
      formData.append("villageId", inputData?.village.value);
      formData.append("pincode", inputData?.pincode);
      formData.append("aadharCardNo", inputData?.aadharCardNo);
      formData.append("panNo", inputData?.panNo);
      if (inputData?.farmerPhoto.url) {
        formData.append("photo", inputData?.farmerPhoto.url);
      }
      if (inputData?.panCardImage.url) {
        formData.append("panImg", inputData?.panCardImage.url);
      }
      formData.append("aadharImg", inputData?.aadharCardImage.url);
      formData.append("bankName", inputData?.bankName);
      formData.append("accountNo", inputData?.accountNo);
      formData.append("ifscCode", inputData?.ifscCode);
      formData.append("branchName", inputData?.branchName);
      formData.append("cancelledChequeImg", inputData?.cancelCheque.url);
      formData.append("familyData", JSON.stringify(inputData?.familyDetails));
      formData.append("landData", JSON.stringify(landDetails));
      formData.append("farmingData", JSON.stringify(farmingDetails));

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/addfarmer`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setSubmitLoading(false);
        }
        // dispatch(logOutRequest());
        // toast.error("Session Expired.");

        if (_data?.status) {
          toast?.success(_data?.message);
          setSubmitLoading(false);
          navigate("/farmerdashboard");
        } else {
          setSubmitLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setSubmitLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  const handleEditStateFamilyDetails = (data, index) => {
    setFamilyInputData({
      name: data.name,
      dob: data.dob,
      gender: genderArray?.find((er) => er?.value === data.gender),
      relation: data.relation,
      id: data?.id,
    });
    setFamilyInputDataId(index);
    setEditState(true);
  };

  const getFarmerDetailsByAadhar = async (e) => {
    e.preventDefault();
    if (!aadharCardNo) {
      toast.error("AadharCard No is Required!");
    } else if (
      (aadharCardNo && aadharCardNo?.length < 12) ||
      aadharCardNo?.length > 12
    ) {
      toast.error("AadharCard No is not in Correct Format!");
    } else {
      setFetchLoading(true);
      let formData = new FormData();

      formData.append("farmerAadharNo", aadharCardNo);

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/farmerdatabyaadhar`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },

            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");
          setFetchLoading(false);

          navigate("/login");
        }

        if (_data?.status) {
          if (_data?.farmerDataByAadhar?.length === 0) {
            setFetchDetails(false);
            setFetchLoading(false);
            // toast?.warning("No Record Found ");
            onAddFarmer();
          } else {
            setFetchLoading(false);
            setFetchDetails(_data?.farmerDataByAadhar?.[0]);
            setStep("step6");
            // let obj = {
            //   farmerId: _data?.farmerDataByAadhar?.[0]?.id,
            //   farmerName: _data?.farmerDataByAadhar?.[0]?.name,
            //   farmerPhoto: {
            //     url: _data?.farmerDataByAadhar?.[0]?.photo,
            //     name: _data?.farmerDataByAadhar?.[0]?.photo
            //       ?.split("/")
            //       .at(-1)
            //       .slice(5),
            //   },
            //   gender: _data?.farmerDataByAadhar?.[0]?.gender,
            //   email: _data?.farmerDataByAadhar?.[0]?.email,
            //   dob: _data?.farmerDataByAadhar?.[0]?.dob,
            //   addressLine1: _data?.farmerDataByAadhar?.[0]?.addressLine1,
            //   addressLine2: _data?.farmerDataByAadhar?.[0]?.addressLine2,
            //   panNo: _data?.farmerDataByAadhar?.[0]?.panNo,
            //   country: {
            //     label: _data?.farmerDataByAadhar?.[0]?.countryDetails?.name,
            //     value: _data?.farmerDataByAadhar?.[0]?.countryDetails?.id,
            //   },
            //   state: {
            //     label: _data?.farmerDataByAadhar?.[0]?.stateDetails?.stateName,
            //     value: _data?.farmerDataByAadhar?.[0]?.stateDetails?.id,
            //   },
            //   dist: {
            //     label:
            //       _data?.farmerDataByAadhar?.[0]?.districtDetails?.districtName,
            //     value: _data?.farmerDataByAadhar?.[0]?.districtDetails?.id,
            //   },
            //   taluka: {
            //     label:
            //       _data?.farmerDataByAadhar?.[0]?.talukaDetails?.talukaName,
            //     value: _data?.farmerDataByAadhar?.[0]?.talukaDetails?.id,
            //   },
            //   village: {
            //     label:
            //       _data?.farmerDataByAadhar?.[0]?.villageDetails?.villageName,
            //     value: _data?.farmerDataByAadhar?.[0]?.villageDetails?.id,
            //   },
            //   panCardImage: {
            //     url: _data?.farmerDataByAadhar?.[0]?.panImg,
            //     name: _data?.farmerDataByAadhar?.[0]?.panImg
            //       ?.split("/")
            //       .at(-1)
            //       .slice(5),
            //   },
            //   aadharCardNo: _data?.farmerDataByAadhar?.[0]?.aadharCardNo,
            //   pincode: _data?.farmerDataByAadhar?.[0]?.pincode,
            //   phoneNo: _data?.farmerDataByAadhar?.[0]?.phoneNo?.split("+91")[1],
            //   aadharCardImage: {
            //     url: _data?.farmerDataByAadhar?.[0]?.aadharImg,
            //     name: _data?.farmerDataByAadhar?.[0]?.aadharImg
            //       ?.split("/")
            //       .at(-1)
            //       .slice(5),
            //   },
            //   mobileNo:
            //     _data?.farmerDataByAadhar?.[0]?.mobileNo?.split("+91")[1],
            //   ifscCode:
            //     _data?.farmerDataByAadhar?.[0]?.bankDetails?.[0]?.ifscCode,
            //   bankName:
            //     _data?.farmerDataByAadhar?.[0]?.bankDetails?.[0]?.bankName,
            //   accountNo:
            //     _data?.farmerDataByAadhar?.[0]?.bankDetails?.[0]?.accountNo,
            //   branchName:
            //     _data?.farmerDataByAadhar?.[0]?.bankDetails?.[0]?.branchName,
            //   familyDetails: _data?.farmerDataByAadhar?.[0]?.familyDetails,
            //   cancelCheque: {
            //     url: _data?.farmerDataByAadhar?.[0]?.bankDetails?.[0]
            //       ?.cancelledChequeImg,
            //     name: _data?.farmerDataByAadhar?.[0]?.bankDetails?.[0]?.cancelledChequeImg
            //       ?.split("/")
            //       .at(-1)
            //       .slice(5),
            //   },
            //   bankDetailsId:
            //     _data?.farmerDataByAadhar?.[0]?.bankDetails?.[0]?.id,
            // };

            // if (_data?.farmerDataByAadhar?.[0]?.familyDetails) {
            //   let arr = [];
            //   _data?.farmerDataByAadhar?.[0]?.familyDetails?.map(
            //     (er, index) => {
            //       arr.push({
            //         id: index + 1,
            //         farmerFamilyDetailsId: er?.id,
            //         farmerId: inputData?.farmerId,
            //         name: er?.name,
            //         relation: er?.relation,
            //         gender: er?.gender,
            //         dob: er?.dob,
            //         age: calculateAge(er?.dob),
            //       });
            //     }
            //   );

            //   obj.familyDetails = arr;
            // }
            // console.log("obj: ", obj);
            // setInputData(obj);

            setUpdateState(true);
          }
        } else {
          setFetchLoading(false);
          toast.error(_data?.error);
        }
      } catch (error) {
        console.log(error);
        setFetchLoading(false);
      }
    }
  };

  const minAllowedDate = new Date();
  let maxDateVar = minAllowedDate.setFullYear(
    minAllowedDate.getFullYear() - 18
  );

  const onCloseAvailibilityModal = () => {
    setAvailibilityModal(false);
  };

  const handleCheckAvailabilty = async (landItem, index) => {
    let checkVillage = landDetails?.find(
      (er, i) => i !== index && er?.village?.value === landItem?.village?.value
    );
    let checkSurveyNo = landDetails?.find(
      (er, i) => i !== index && er?.surveyNo === landItem?.surveyNo
    );

    if (checkSurveyNo && checkVillage) {
      // toast.error(
      //   "Land Already Registration. Please Check Village and Survey No "
      // );
      const t = landDetails?.filter(
        (er, i) =>
          i !== index && er?.village?.value === landItem?.village?.value
      );

      const remainingVigha =
        Number(checkSurveyNo.vigha) - Number(checkSurveyNo.regVigha);
      if (remainingVigha > 0) {
        toast.warning(`${remainingVigha} Vigha is available for registration.`);
      }
    } else {
      if (landItem?.village?.value && landItem?.surveyNo) {
        setAvailibilityLoading(true);
        let formData = new FormData();
        formData.append("villageId", landItem?.village.value);
        formData.append("surveyNo", landItem?.surveyNo);

        try {
          const apiCall = await fetch(
            `${process.env.REACT_APP_API_URL}farmer/checklandavability`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          );
          const _data = await apiCall.json();

          if (_data?.code === "token_not_valid") {
            dispatch(logOutRequest());
            toast.error("Session Expired.");
            setAvailibilityLoading(false);
            navigate("/login");
            setLoading(false);
          }
          if (_data?.status) {
            setAvailibilityModal(true);
            setAvailibilityData(_data);
            setAvailibilityDataIndex(index);
            setAvailibilityLoading(false);
          } else {
            setAvailibilityLoading(false);

            // setDeleteLoading(false);
            setAvailibilityModal(false);
            toast.error(_data?.message);
          }
        } catch (error) {
          console.log(error);
          setAvailibilityLoading(false);

          // setDeleteLoading(false);
        }
      } else {
        toast.error(
          "Please give input for Village and Survey No for check Land Availability"
        );
      }
    }
  };

  // Farming Details 02-10-2023

  const getProductListAPi = useCallback(async () => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/productdrop`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];
        // eslint-disable-next-line
        _data?.productList?.map((er) => {
          if (er?.isplantitem === 1) {
            arr.push({ label: er?.productName, value: er });
          }
        });
        setProductOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(
      productActions.get_plant_group_category({
        onSuccess: (res) => {
          setLoading(false);
        },
        onError: (err) => toast.error(err),
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getProductListAPi();
    // getCategoryListAPi();
  }, [
    getProductListAPi,
    //  getCategoryListAPi
  ]);

  const getCheckOptions = (productOptions) => {
    let arr = [];
    // eslint-disable-next-line
    productOptions?.map((er) => {
      let check = farmingDetails?.find(
        (ed) => ed?.product.value?.id === er?.value?.id
      );

      if (!check) {
        arr?.push(er);
      }
    });
    return arr;
  };

  const handleOnChangeFarmingDetails = (name, value) => {
    if (name === "vigha") {
      if (farmingDetails?.length === 0) {
        if (
          +Number(value).toFixed(3) <=
          +Number(singleFarmingDetails?.totalVigha).toFixed(3)
        ) {
          setSingleFarmingDetails({ ...singleFarmingDetails, [name]: value });
          setSingleFarmingDetailsError({
            ...singleFarmingDetailsError,
            [name]: false,
          });
        } else {
          if (value === "") {
            setSingleFarmingDetails({ ...singleFarmingDetails, [name]: value });
            setSingleFarmingDetailsError({
              ...singleFarmingDetailsError,
              [name]: true,
            });
          }
        }
      } else {
        if (editFarmingDetails) {
          let totalfarmingDetails = [...farmingDetails];
          totalfarmingDetails.splice(singleFarmingIndex, 1);

          let totalRegisterLand = totalfarmingDetails?.reduce(
            (acc, curr) => acc + Number(curr?.vigha),
            0
          );

          let remainingland =
            singleFarmingDetails?.totalVigha - totalRegisterLand;

          if (+Number(value) <= +Number(remainingland)) {
            setSingleFarmingDetails({ ...singleFarmingDetails, [name]: value });
            setSingleFarmingDetailsError({
              ...singleFarmingDetailsError,
              [name]: false,
            });
          } else {
            if (value === "") {
              setSingleFarmingDetails({
                ...singleFarmingDetails,
                [name]: value,
              });
              setSingleFarmingDetailsError({
                ...singleFarmingDetailsError,
                [name]: true,
              });
            }
          }
        } else {
          let totalRegisterLand = farmingDetails?.reduce(
            (acc, curr) => acc + Number(curr?.vigha),
            0
          );

          let remainingland =
            singleFarmingDetails?.totalVigha - totalRegisterLand;

          if (+Number(value).toFixed(3) <= +Number(remainingland).toFixed(3)) {
            setSingleFarmingDetails({ ...singleFarmingDetails, [name]: value });
            setSingleFarmingDetailsError({
              ...singleFarmingDetailsError,
              [name]: false,
            });
          } else {
            if (value === "") {
              setSingleFarmingDetails({
                ...singleFarmingDetails,
                [name]: value,
              });
              setSingleFarmingDetailsError({
                ...singleFarmingDetailsError,
                [name]: true,
              });
            }
          }
        }
      }
    } else if (name === "product") {
      setSingleFarmingDetails({
        ...singleFarmingDetails,
        [name]: value,
        category: value?.value?.categoryId,
      });
      setSingleFarmingDetailsError({
        ...singleFarmingDetailsError,
        [name]: false,
      });
    } else {
      setSingleFarmingDetails({ ...singleFarmingDetails, [name]: value });
      setSingleFarmingDetailsError({
        ...singleFarmingDetailsError,
        [name]: false,
      });
    }
  };

  const handleAddFarmingDetails = () => {
    if (!singleFarmingDetails?.product) {
      setSingleFarmingDetailsError({
        ...singleFarmingDetailsError,
        product: true,
      });
      toast.error("product Name is Required!");
    } else if (!singleFarmingDetails?.vigha) {
      setSingleFarmingDetailsError({
        ...singleFarmingDetailsError,
        vigha: true,
      });
      toast.error("Vigha is Required!");
    } else {
      if (editFarmingDetails) {
        let arr = [...farmingDetails];
        arr[singleFarmingIndex].product = singleFarmingDetails.product;
        arr[singleFarmingIndex].category = singleFarmingDetails.category;
        arr[singleFarmingIndex].vigha = singleFarmingDetails.vigha;

        setFarmingDetails(arr);

        setSingleFarmingDetails({
          ...singleFarmingDetails,
          product: "",
          category: "",
          vigha: "",
        });
        setSingleFarmingIndex("");
        setEditFarmingDetails(false);
      } else {
        let arr = [...farmingDetails];
        const d = {
          id: Math.random(),
          ...singleFarmingDetails,
        };
        arr.push(d);

        const tot = singleFarmingDetails.totalVigha;
        const rem = arr?.reduce((acc, curr) => acc + Number(curr?.vigha), 0);
        const r = tot - rem;
        setRemainingLandinVigha(+Number(r).toFixed(3));

        setFarmingDetails(arr);

        setSingleFarmingDetails({
          ...singleFarmingDetails,
          product: "",
          category: "",
          vigha: "",
        });
      }
    }
  };

  useEffect(() => {
    //console.log(farmingDetails);
  }, [farmingDetails]);

  const handleEditStateFarmingDetails = (data, index) => {
    setSingleFarmingDetails(data);
    setSingleFarmingIndex(index);
    setEditFarmingDetails(true);
  };

  const handleDeleteFarmingDetails = (index) => {
    let arr = [...farmingDetails];

    arr.splice(index, 1);

    const tot = singleFarmingDetails.totalVigha;
    const rem = arr?.reduce((acc, curr) => acc + Number(curr?.vigha), 0);
    const r = tot - rem;
    setRemainingLandinVigha(+Number(r).toFixed(3));
    setFarmingDetails(arr);
  };

  const Farmingcolumn = useMemo(
    () => [
      {
        Header: "Name Of Product",
        Cell: (cellProps) => {
          return <span>{cellProps?.row?.original?.product?.label}</span>;
        },
      },
      {
        Header: "Category",
        Cell: (cellProps) => {
          return (
            <span>
              {cellProps?.row?.original?.product?.value?.categoryName}
            </span>
          );
        },
      },
      {
        Header: "Size of Vigha",
        accessor: "vigha",
        filterable: false,
      },
      {
        Header: "Total Vigha",
        accessor: "totalVigha",
        filterable: false,
      },

      {
        id: "Action",
        Header: () => (
          <span
            className="d-flex justify-content-center"
            style={{ textAlign: "center" }}
          >
            Action
          </span>
        ),
        Cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <div className="hstack gap-2">
                {/* Edit Button */}
                <button
                  className="border-0"
                  style={{ background: "transparent" }}
                  onClick={() =>
                    handleEditStateFarmingDetails(
                      cellProps.row.original,
                      cellProps.row.index
                    )
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_32_318)">
                      <path
                        d="M6.87549 2.5H2.50049C2.16897 2.5 1.85103 2.6317 1.6166 2.86612C1.38218 3.10054 1.25049 3.41848 1.25049 3.75V12.5C1.25049 12.8315 1.38218 13.1495 1.6166 13.3839C1.85103 13.6183 2.16897 13.75 2.50049 13.75H11.2505C11.582 13.75 11.9 13.6183 12.1344 13.3839C12.3688 13.1495 12.5005 12.8315 12.5005 12.5V8.125"
                        stroke="#3E7531"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.563 1.56249C11.8116 1.31385 12.1489 1.17416 12.5005 1.17416C12.8521 1.17416 13.1893 1.31385 13.438 1.56249C13.6866 1.81113 13.8263 2.14836 13.8263 2.49999C13.8263 2.85162 13.6866 3.18885 13.438 3.43749L7.50049 9.37499L5.00049 9.99999L5.62549 7.49999L11.563 1.56249Z"
                        stroke="#3E7531"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_32_318">
                        <rect
                          width="15"
                          height="15"
                          fill="white"
                          transform="translate(0.000488281)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>

                {/* Delete Button */}
                <button
                  className="border-0"
                  style={{ background: "transparent" }}
                  onClick={() =>
                    handleDeleteFarmingDetails(cellProps.row.index)
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_32_330)">
                      <path
                        d="M6.63507 -0.0178833C5.50545 -0.0178833 4.59795 0.92077 4.59795 2.05558V2.30769H2.30814C1.99026 2.30769 1.73122 2.56673 1.73122 2.88462V3.46154H1.1543V4.61539H2.30814V13.2692C2.30814 14.2183 3.08987 15 4.03891 15H10.962C11.911 15 12.6928 14.2183 12.6928 13.2692V4.61539H13.8466V3.46154H13.2697V2.88462C13.2697 2.56673 13.0106 2.30769 12.6928 2.30769H10.403V2.055C10.403 0.920771 9.49545 -0.0178833 8.36584 -0.0178833H6.63507ZM6.63507 1.17173H8.36584C8.82968 1.17173 9.21334 1.55019 9.21334 2.055V2.30769H5.78699V2.055C5.78699 1.55019 6.17064 1.17231 6.63449 1.17231L6.63507 1.17173ZM3.46199 4.61539H6.41872C6.49026 4.62289 6.56122 4.63327 6.63507 4.63327H8.36584C8.43968 4.63327 8.51007 4.62289 8.58218 4.61539H11.5389V13.2692C11.5389 13.594 11.2868 13.8462 10.962 13.8462H4.03891C3.7141 13.8462 3.46199 13.594 3.46199 13.2692V4.61539ZM4.61584 5.76923V12.6923H5.76968V5.76923H4.61584ZM6.92353 5.76923V12.6923H8.07737V5.76923H6.92353ZM9.23122 5.76923V12.6923H10.3851V5.76923H9.23122Z"
                        fill="#EC0505"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_32_330">
                        <rect
                          width="15"
                          height="15"
                          fill="white"
                          transform="translate(0.000488281)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>
          );
        },
      },

      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    [farmingDetails]
  );

  useEffect(() => {
    dispatch(
      commonActions.get_village_list({
        onSuccess: (res) => setVillageArray(res),
      })
    );
    dispatch(
      commonActions.get_taluka_list({
        onSuccess: (res) => setTalukaArray(res),
      })
    );
    dispatch(
      commonActions.get_state_list({
        onSuccess: (res) => setStateArray(res),
      })
    );
    dispatch(
      commonActions.get_district_list({
        onSuccess: (res) => setDistrictArray(res),
      })
    );
    dispatch(
      commonActions.get_country_list({
        onSuccess: (res) => setCountryArray(res),
      })
    );
    dispatch(
      commonActions.get_bank_list({
        onSuccess: (res) => {},
      })
    );
    dispatch(
      commonActions.get_relation_list({
        onSuccess: (res) => {},
      })
    );
  }, []);

  const onAddFarmer = () => {
    if (landDetails?.length === 0) {
      getStateOption(inputData?.country?.value).then((res) => {
        setGuardianStateArray(res);
      });

      getDistrictOption(inputData?.state?.value).then((res) => {
        setGuardianDistrictArray(res);
      });

      getTalukaOption(inputData?.dist?.value).then((res) => {
        setGuardianTalukaArray(res);
      });

      getVillageOption(inputData?.taluka?.value).then((res) => {
        setGuardianVillageArray(res);
      });

      setLandDetails([
        {
          indexId: 1,
          country: "",
          state: "",
          dist: "",
          taluka: "",
          village: "",
          surveyNo: "",
          totalLand: "",
          registeredLand: "",
          vigha: "",
          regVigha: "",
          countryArray: countryArray,
          stateArray: [],
          districtArray: [],
          villageArray: [],
          talukaArray: [],
          ownerShipStatus: false,
          landOwnerName: "",
          address: "",
          guardianCountry: "",
          guardianState: "",
          guardianDist: "",
          guardianTaluka: "",
          guardianVillage: "",
          guardianPincode: "",
          guardianName: "",
          tenantDocument: "",
          agreementDocument: "",
          landDocument: "",
        },
      ]);
      setCheckAvailabilty([...checkAvailabilty, { check: false }]);
    }
    setStep("step5");
  };
  // useEffect(() => {
  //   console.log("updatedLandDetails", landDetails);
  // }, [landDetails]);

  const [value, setValue] = React.useState("land");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(value) {
    return {
      id: `${value}`,
      "aria-controls": `${value}`,
    };
  }
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`${value}`}
        aria-labelledby={`${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  }
  const FarmerTabs = () => {
    return (
      <PageLayout
        title={"Add New Farmer"}
        subTitle={
          isTablet
            ? ""
            : "Please provide information for farmer participating in this program"
        }
      >
        <Tabs
          className="custom-tabs mt-3"
          value={value}
          onChange={handleChange}
          textColor="secondary"
          // textColor="success"
          // indicatorColor="warning"
          aria-label="farmertab"
          variant="fullWidth"
        >
          <Tab
            value="land"
            label="Land Details"
            icon={<Landscape />}
            iconPosition="start"
            {...a11yProps("land")}
          ></Tab>
          <Tab
            value="personal"
            label="Personal Details"
            icon={<Person2 />}
            iconPosition="start"
            {...a11yProps("personal")}
          />
          <Tab
            value="family"
            label="Family Details"
            icon={<FamilyRestroom />}
            iconPosition="start"
            {...a11yProps("family")}
          />
          <Tab
            value="bank"
            label="Bank Details"
            icon={<AccountBalance />}
            iconPosition="start"
            {...a11yProps("bank")}
          />
          <Tab
            value="farming"
            label="Farming Details"
            icon={<Agriculture />}
            iconPosition="start"
            {...a11yProps("farming")}
          />
        </Tabs>
        <CustomTabPanel value={value} index={"land"}>
          <LandDetails />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={"personal"}>
          Item Two
        </CustomTabPanel>
        <CustomTabPanel value={value} index={"family"}>
          Item Three
        </CustomTabPanel>
        <CustomTabPanel value={value} index={"bank"}>
          Item Three
        </CustomTabPanel>
        <CustomTabPanel value={value} index={"farming"}>
          Item Three
        </CustomTabPanel>
      </PageLayout>
    );
  };

  const LandDetails = () => {
    return (
      <div style={{ margin: 20 }}>
        <Row className=" g-3 mt-2 ">
          <Col lg={4} md={4} sm={12}>
            <TextField
              id="aadharCardNo"
              name="aadharCardNo"
              size="small"
              label="Aadhar Card"
              required
              error={inputDataError?.aadharCardNo}
              value={inputData?.aadharCardNo}
              onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
              fullWidth
              variant="standard"
            />
            {inputDataError?.aadharCardNo && (
              <ErrorMessage message={"Aadhar number is required"} />
            )}
          </Col>
          <Col lg={2} md={3} sm={12}>
            <TextField
              disabled
              variant="standard"
              label={`File name`}
              placeholder="Aadhar Card Image"
              helperText={"(Max size 150 KB)"}
              InputLabelProps={{ shrink: true }}
              error={`${inputDataError?.aadharCardImage}`}
              value={
                inputData?.aadharCardImage && inputData?.aadharCardImage?.name
              }
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <MuiButton
              type="button"
              onClick={handleaadharCardPhotoImageUploadBtn}
              className={`custom-btn-page-layout custom-btn-upload ${
                inputDataError?.aadharCardImage ? " border border-danger" : ""
              }`}
              startIcon={<CloudUpload />}
            >
              {`Upload Aadhar Card`}
            </MuiButton>
            <input
              style={{ display: "none" }}
              type="file"
              accept="image/png, image/jpeg , image/jpg"
              name="aadharCardImage"
              ref={aadharCardPhotoImage}
              onChange={(e) =>
                handleImageChange(e.target.name, e.target.files[0])
              }
            />

            {/* <span className="mx-3" style={{ fontWeight: 500, fontSize: 10 }}>
                {inputData?.aadharCardImage
                  ? inputData?.aadharCardImage?.name
                  : "Option to Upload Cancel Cheque or Bank Passbook Front Page"}
              </span> */}
            {/* <MuiButton
              component="label"
              variant="contained"
              startIcon={<CloudUpload />}
              // onClick={handleaadharCardPhotoImageUploadBtn}
              className="custom-btn-page-layout custom-btn-warning"
            >
              Upload Aadhar Card
              <VisuallyHiddenInput
                type="file"
                accept="image/png, image/jpeg , image/jpg"
                name="aadharCardImage"
                // ref={aadharCardPhotoImage}
                onChange={(e) =>
                  handleImageChange(e.target.name, e.target.files[0])
                }
              />
            </MuiButton> */}
          </Col>
        </Row>
        <Divider
          className="border-danger my-2"
          style={{ borderBottomWidth: 2 }}
        />
        <div
          className={`d-flex justify-content-end align-items-center w-100 ${
            isTablet ? "flex-column gap-2" : ""
          }`}
        >
          <MuiButton
            onClick={() => {
              setLandDetails([
                ...landDetails,
                {
                  indexId: landDetails?.length + 1,
                  country: "",
                  state: "",
                  dist: "",
                  taluka: "",
                  village: "",
                  surveyNo: "",
                  totalLand: "",
                  registeredLand: "",
                  vigha: "",
                  regVigha: "",
                  countryArray: countryArray,
                  stateArray: [],
                  districtArray: [],
                  villageArray: [],
                  talukaArray: [],
                  ownerShipStatus: false,
                  landOwnerName: inputData?.farmerName,
                  address: "",
                  guardianCountry: "",
                  guardianState: "",
                  guardianDist: "",
                  guardianTaluka: "",
                  guardianVillage: "",
                  guardianPincode: "",
                  guardianName: "",
                  tenantDocument: "",
                  agreementDocument: "",
                  landDocument: "",
                },
              ]);

              setSelectedDropdownValues({
                village: null,
                taluka: null,
                district: null,
                dist: null,
                state: null,
                country: null,
              });

              setLandDetailsError([
                ...landDetailsError,
                {
                  country: false,
                  state: false,
                  dist: false,
                  taluka: false,
                  village: false,
                  surveyNo: false,
                  totalLand: false,
                  registeredLand: false,
                  vigha: false,
                  regVigha: false,
                  ownerShipStatus: false,
                  landOwnerName: false,

                  guardianPincode: false,
                  guardianName: false,
                  tenantDocument: false,
                  agreementDocument: false,
                  landDocument: false,
                },
              ]);
              setCheckAvailabilty([...checkAvailabilty, { check: false }]);
            }}
            className="custom-btn-page-layout custom-btn-primary text-white "
            variant="contained"
            startIcon={<ControlPoint />}
            size="medium"
          >{`Add New Land Details`}</MuiButton>
        </div>

        {landDetails?.map((landItem, landIndex) => (
          <Accordion
            key={landIndex}
            open={open}
            className={landIndex === 0 ? "mt-1" : ""}
            toggle={toggle}
          >
            <fieldset
              className="cus-add-req-sur border-none m-0 p-0"
              style={{ border: "none" }}
            >
              <AccordionItem>
                <AccordionHeader targetId={`${landIndex + 1}`}>
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div>
                      {`Land No : ${landIndex + 1} ${
                        landItem?.surveyNo &&
                        `[${landItem?.landOwnerName}  ${landItem?.village?.label} - ${landItem?.surveyNo}]`
                      }`}
                    </div>
                    <div>
                      {landDetails?.length > 1 && (
                        <IconButton
                          //className="p-0 text-danger"
                          className="custom-btn-page-layout custom-btn-danger text-white no-shadow rounded p-1 text-danger me-1"
                          onClick={() => {
                            handleDeleteLandDetails(landIndex);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      )}

                      {Number(landIndex + 1) === Number(open) ? (
                        <IconButton className="p-1 text-white custom-btn-page-layout custom-btn-black text-white no-shadow rounded">
                          <ExpandMore />
                        </IconButton>
                      ) : (
                        <IconButton className="p-1 text-white custom-btn-page-layout custom-btn-black text-white no-shadow rounded">
                          <ExpandLess />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </AccordionHeader>
                <AccordionBody accordionId={`${landIndex + 1}`}>
                  {/* State, Dist and Taluka  */}
                  <div>
                    <Row className="g-2 mt-2">
                      <Col lg={3} md={3} sm={12}>
                        <Autocomplete
                          id="countryAddCompany"
                          name="countryAddCompany"
                          size="small"
                          options={villageArray}
                          value={selectedDropdownValues.village}
                          onChange={(e, opt) => {
                            const optValues = {
                              village: opt,
                              taluka: talukaArray.find(
                                (x) => x.id === opt?.talukaId_id
                              ),
                              district: districtArray.find(
                                (x) => x.id === opt?.districtId_id
                              ),
                              state: stateArray.find(
                                (x) => x.id === opt?.stateId_id
                              ),
                              country: countryArray.find(
                                (x) => x.id === opt?.countryId_id
                              ),
                            };
                            setSelectedDropdownValues({ ...optValues });
                            setInputData({ ...inputData, ...optValues });
                            const a = [...landDetails];
                            a[landIndex]["village"] = opt;
                            a[landIndex]["taluka"] = optValues?.taluka;
                            a[landIndex]["dist"] = optValues?.district;
                            a[landIndex]["state"] = optValues?.state;
                            a[landIndex]["country"] = optValues?.country;

                            setLandDetails(a);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Village"
                              size="small"
                              error={landDetailsError[landIndex]["village"]}
                            />
                          )}
                        />
                      </Col>
                      <Col lg={3} md={3} sm={12}>
                        <Autocomplete
                          id="countryAddCompany"
                          name="countryAddCompany"
                          size="small"
                          options={talukaArray}
                          value={selectedDropdownValues.taluka}
                          // onChange={ondropdownchange}
                          // onChange={(e, opt) => {
                          //   setSelectedDropdownValues((prec) => ({
                          //     ...prec,
                          //     taluka: opt,
                          //   }));
                          //   const a = [...landDetails];
                          //   a[landIndex]["taluka"] = opt.label;
                          //   setLandDetails(a);
                          // }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Taluka"
                              size="small"
                              error={landDetailsError[landIndex]["taluka"]}
                            />
                          )}
                        />
                      </Col>
                      <Col lg={2} md={2} sm={12}>
                        <Autocomplete
                          id="countryAddCompany"
                          name="countryAddCompany"
                          size="small"
                          options={districtArray}
                          value={selectedDropdownValues.district}
                          // onChange={ondropdownchange}
                          // onChange={(e, opt) => {

                          //   const a = [...landDetails];
                          //   a[landIndex]["taluka"] = opt.label;
                          //   setLandDetails(a);
                          // }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="District"
                              size="small"
                              error={landDetailsError[landIndex]["dist"]}
                            />
                          )}
                        />
                      </Col>
                      <Col lg={2} md={2} sm={12}>
                        <Autocomplete
                          id="countryAddCompany"
                          name="countryAddCompany"
                          size="small"
                          options={stateArray}
                          value={selectedDropdownValues.state}
                          // onChange={ondropdownchange}
                          // onChange={(e, opt) => {
                          //   setSelectedDropdownValues((prec) => ({
                          //     ...prec,
                          //     taluka: opt,
                          //   }));
                          //   const a = [...landDetails];
                          //   a[landIndex]["taluka"] = opt.label;
                          //   setLandDetails(a);
                          // }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="State"
                              size="small"
                              error={landDetailsError[landIndex]["state"]}
                            />
                          )}
                        />
                      </Col>
                      <Col lg={2} md={2} sm={12}>
                        <Autocomplete
                          id="countryAddCompany"
                          name="countryAddCompany"
                          size="small"
                          options={countryArray}
                          value={selectedDropdownValues.country}
                          // onChange={ondropdownchange}
                          // onChange={(e, opt) => {
                          //   setSelectedDropdownValues((prec) => ({
                          //     ...prec,
                          //     taluka: opt,
                          //   }));
                          //   const a = [...landDetails];
                          //   a[landIndex]["taluka"] = opt.label;
                          //   setLandDetails(a);
                          // }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Country"
                              size="small"
                              error={landDetailsError[landIndex]["country"]}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </div>
                  {/* village,surveyNo and totalLand  */}
                  <div>
                    <Row className=" g-2 mt-2">
                      <Col lg={3} md={12} sm={12} className="d-flex ">
                        <TextField
                          value={landItem?.surveyNo}
                          name="surveyNo"
                          label="Survey #"
                          onChange={(e) => {
                            handleChangeLandDetailsInput(
                              landIndex,
                              e.target.name,
                              e.target.value
                            );
                          }}
                          required
                          error={landDetailsError[landIndex]["surveyNo"]}
                          placeholder="Enter Survey #"
                          id="surveyNo"
                          size="small"
                          variant="standard"
                          fullWidth
                          onBlur={() =>
                            handleCheckAvailabilty(landItem, landIndex)
                          }
                        />
                      </Col>
                      <Col lg={6}>
                        <MuiButton
                          type="button"
                          className="custom-btn-page-layout btn text-white mx-3"
                          onClick={() => {
                            handleCheckAvailabilty(landItem, landIndex);
                          }}
                          startIcon={
                            availibilityLoading ? (
                              <CircularProgress size={15} color="warning" />
                            ) : (
                              <Check />
                            )
                          }
                        >
                          {` Check Land Availability`}
                        </MuiButton>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row className=" g-2 mt-2">
                      <Col lg={3} md={3} sm={12}>
                        <TextField
                          value={landItem?.totalLand}
                          name="totalLand"
                          label="Total Land in Document (In Sqm)"
                          onChange={(e) => {
                            handleChangeLandDetailsInput(
                              landIndex,
                              e.target.name,
                              e.target.value
                            );
                          }}
                          required
                          disabled={landItem?.remainLandVigha ? true : false}
                          error={landDetailsError[landIndex]["totalLand"]}
                          placeholder="Enter Total Land"
                          id="totalLand"
                          size="small"
                          variant="standard"
                          fullWidth
                        />
                      </Col>
                      <Col lg={3} md={3} sm={12}>
                        <TextField
                          value={landItem?.vigha}
                          name="vigha"
                          label="Total Vigha"
                          onChange={(e) => {
                            handleChangeLandDetailsInput(
                              landIndex,
                              e.target.name,
                              e.target.value
                            );
                          }}
                          required
                          disabled
                          error={landDetailsError[landIndex]["vigha"]}
                          placeholder="Enter Vigha"
                          id="vigha"
                          size="small"
                          variant="standard"
                          fullWidth
                        />
                      </Col>

                      <Col lg={3} md={3} sm={12}>
                        <TextField
                          value={landItem?.regVigha}
                          name="regVigha"
                          label="Registered Vigha"
                          onChange={(e) => {
                            handleChangeLandDetailsInput(
                              landIndex,
                              e.target.name,
                              e.target.value
                            );
                          }}
                          required
                          error={landDetailsError[landIndex]["regVigha"]}
                          size="small"
                          variant="standard"
                          fullWidth
                        />
                      </Col>
                      <Col lg={3} md={3} sm={12}>
                        <TextField
                          value={landItem?.registeredLand}
                          name="registeredLand"
                          label="Total Registered Land (In Sqm)"
                          onChange={(e) => {
                            handleChangeLandDetailsInput(
                              landIndex,
                              e.target.name,
                              e.target.value
                            );
                          }}
                          error={landDetailsError[landIndex]["registeredLand"]}
                          size="small"
                          variant="standard"
                          fullWidth
                          disabled
                        />
                      </Col>
                    </Row>
                  </div>
                  {checkAvailabilty[landIndex]?.check && (
                    <>
                      {/*  Ownership Status */}
                      <div
                        style={{}}
                        className={`mt-2 ${
                          isTablet
                            ? ""
                            : "d-flex justify-content-start align-items-center"
                        }`}
                      >
                        <Row>
                          <Col>
                            <FormControlLabel
                              value="start"
                              style={{ marginLeft: "unset" }}
                              control={
                                <Checkbox
                                  checked={landItem?.ownerShipStatus}
                                  name="ownerShipStatus"
                                  onChange={(e) =>
                                    handleChangeLandDetailsInput(
                                      landIndex,
                                      e.target.name,
                                      e.target.checked
                                    )
                                  }
                                  color="success"
                                />
                              }
                              label="Ownership Status (Is Rented?) "
                              labelPlacement="start"
                            />
                          </Col>
                        </Row>
                      </div>

                      {/* LandOwnerName/ FarmerName  and Address  */}
                      <div className="pb-0">
                        <Row className=" g-2 mt-2">
                          <Col lg={4} md={12} sm={12}>
                            <TextField
                              value={
                                !landItem?.ownerShipStatus
                                  ? landItem?.landOwnerName
                                  : landItem?.guardianName
                              }
                              name={
                                !landItem?.ownerShipStatus
                                  ? "landOwnerName"
                                  : "guardianName"
                              }
                              label={`${
                                !landItem?.ownerShipStatus
                                  ? "Land Owner Name"
                                  : "Farmer Name"
                              }`}
                              onChange={(e) => {
                                handleChangeLandDetailsInput(
                                  landIndex,
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                              required
                              size="small"
                              variant="standard"
                              fullWidth
                            />
                          </Col>
                          <Col lg={8} md={12} sm={12}>
                            <TextField
                              value={landItem?.address}
                              name="address"
                              label="Address"
                              onChange={(e) => {
                                handleChangeLandDetailsInput(
                                  landIndex,
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                              error={landDetailsError[landIndex]["address"]}
                              rows={2}
                              size="small"
                              variant="standard"
                              fullWidth
                            />
                          </Col>
                        </Row>
                      </div>
                      {/* Farmer State  and Farmer Dist  */}
                      <div>
                        <Row className=" g-2 mt-2">
                          <Col lg={2} md={2} sm={12}>
                            <TextField
                              value={landItem?.guardianPincode}
                              name="guardianPincode"
                              label="Pincode"
                              onChange={(e) => {
                                handleChangeLandDetailsInput(
                                  landIndex,
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                              error={
                                landDetailsError[landIndex]["guardianPincode"]
                              }
                              size="small"
                              variant="standard"
                              fullWidth
                            />
                          </Col>
                          <Col lg={2} md={2} sm={12}>
                            <Autocomplete
                              id="guardianVillage"
                              name="guardianVillage"
                              size="small"
                              options={villageArray}
                              value={
                                selectedGuardianDropdownValues.guardianVillage
                              }
                              onChange={(e, opt) => {
                                const optValues = {
                                  guardianVillage: opt,
                                  guardianTaluka: talukaArray.find(
                                    (x) => x.id === opt?.talukaId_id
                                  ),
                                  guardianDist: districtArray.find(
                                    (x) => x.id === opt?.districtId_id
                                  ),
                                  guardianState: stateArray.find(
                                    (x) => x.id === opt?.stateId_id
                                  ),
                                  guardianCountry: countryArray.find(
                                    (x) => x.id === opt?.countryId_id
                                  ),
                                };
                                setSelectedGuardianDropdownValues({
                                  ...optValues,
                                });
                                const a = [...landDetails];
                                a[landIndex]["guardianVillage"] = opt;
                                a[landIndex]["guardianTaluka"] =
                                  optValues?.guardianTaluka;
                                a[landIndex]["guardianDist"] =
                                  optValues?.guardianDist;
                                a[landIndex]["guardianState"] =
                                  optValues?.guardianState;
                                a[landIndex]["guardianCountry"] =
                                  optValues?.guardianCountry;
                                setInputData({
                                  ...inputData,
                                  ...optValues,
                                });
                                setLandDetails(a);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  label="Village"
                                  size="small"
                                  error={
                                    landDetailsError[landIndex][
                                      "guardianVillage"
                                    ]
                                  }
                                />
                              )}
                            />
                          </Col>
                          <Col lg={2} md={2} sm={12}>
                            <Autocomplete
                              id="guardianTaluka"
                              name="guardianTaluka"
                              size="small"
                              options={talukaArray}
                              value={
                                selectedGuardianDropdownValues.guardianTaluka
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  label="Taluka"
                                  size="small"
                                  error={
                                    landDetailsError[landIndex][
                                      "guardianTaluka"
                                    ]
                                  }
                                />
                              )}
                            />
                          </Col>
                          <Col lg={2} md={2} sm={12}>
                            <Autocomplete
                              id="guardianDist"
                              name="guardianDist"
                              size="small"
                              options={districtArray}
                              value={
                                selectedGuardianDropdownValues.guardianDist
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  label="District"
                                  size="small"
                                  error={
                                    landDetailsError[landIndex]["guardianDist"]
                                  }
                                />
                              )}
                            />
                          </Col>
                          <Col lg={2} md={2} sm={12}>
                            <Autocomplete
                              id="guardianState"
                              name="guardianState"
                              size="small"
                              options={stateArray}
                              value={
                                selectedGuardianDropdownValues.guardianState
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  label="State"
                                  size="small"
                                  error={
                                    landDetailsError[landIndex]["guardianState"]
                                  }
                                />
                              )}
                            />
                          </Col>
                          <Col lg={2} md={2} sm={12}>
                            <Autocomplete
                              id="guardianCountry"
                              name="guardianCountry"
                              size="small"
                              options={countryArray}
                              value={
                                selectedGuardianDropdownValues.guardianCountry
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  label="Country"
                                  size="small"
                                  error={
                                    landDetailsError[landIndex][
                                      "guardianCountry"
                                    ]
                                  }
                                />
                              )}
                            />
                          </Col>
                        </Row>
                      </div>

                      {/* Upload Document  */}
                      <div style={{}}>
                        <Row className=" g-2 mt-2">
                          {/* <Col lg={4} md={12} sm={12} className="d-flex ">
                            <LandDocumentComponent
                              index={landIndex}
                              landDetails={landDetails}
                              landDetailsError={landDetailsError}
                              setLandDetailsError={setLandDetailsError}
                              setLandDetails={setLandDetails}
                              setLoading={setLoading}
                            />
                          </Col> */}

                          <Col lg={4} md={12} sm={12} className="d-flex ">
                            {landItem?.ownerShipStatus && (
                              <>
                                <AgreementComponent
                                  index={landIndex}
                                  landDetails={landDetails}
                                  landDetailsError={landDetailsError}
                                  setLandDetailsError={setLandDetailsError}
                                  setLandDetails={setLandDetails}
                                  setLoading={setLoading}
                                />
                              </>
                            )}
                          </Col>
                          <Col lg={4} md={12} sm={12} className="d-flex ">
                            {landItem?.ownerShipStatus && (
                              <>
                                <TenetComponent
                                  index={landIndex}
                                  landDetails={landDetails}
                                  landDetailsError={landDetailsError}
                                  setLandDetailsError={setLandDetailsError}
                                  setLandDetails={setLandDetails}
                                  setLoading={setLoading}
                                />
                              </>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}{" "}
                </AccordionBody>
              </AccordionItem>
            </fieldset>
          </Accordion>
        ))}

        <div className="d-flex justify-content-end">
          {!checkAvailabilty?.find((er) => er?.check === false) && (
            <MuiButton
              type="button"
              className="custom-btn-page-layout"
              onClick={(e) => {
                handleNextBtn("step5");
              }}
              endIcon={<NavigateNext />}
            >
              Next
            </MuiButton>
          )}
        </div>
      </div>
    );
  };

  return (
    <PageLayout
      title={"Add New Farmer"}
      subTitle={
        isTablet
          ? ""
          : "Please provide information for farmer participating in this program"
      }
      loading={submitloading}
    >
      {step === "step4" && (
        <div className="" style={{ margin: 20 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <TextField
                value={aadharCardNo}
                name="aadharCardNo"
                label="Aadhar #"
                onChange={(e) => {
                  setAadharCardNo(e.target.value);
                  handleChangeInput(e.target.name, e.target.value);
                }}
                placeholder="Enter Aadhar#"
                id="aadharCardNo"
                size="small"
                variant="standard"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <MuiButton
                onClick={(e) => getFarmerDetailsByAadhar(e)}
                className="custom-btn-page-layout custom-btn-success text-white "
                variant="contained"
                startIcon={
                  fetchLoading ? (
                    <ClipLoader size={15} color="warning" />
                  ) : (
                    <GetApp />
                  )
                }
                size="medium"
                disabled={fetchLoading}
                fullWidth
              >{`Fetch Aadhar Status`}</MuiButton>
            </Grid>
            {!fetchDetails && (
              <Grid item xs={12} sm={12} md={3} lg={2}>
                <MuiButton
                  className="custom-btn-page-layout"
                  variant="contained"
                  fullWidth
                  startIcon={<ControlPoint />}
                  onClick={onAddFarmer}
                >
                  {`Add Farmer`}
                </MuiButton>
              </Grid>
            )}
          </Grid>
        </div>
      )}

      {/* Stepper View  */}
      {step !== "step4" && !updateState && (
        <div className="farm-add-stepper">
          <div
            className={`${
              isTablet ? "step1-farm-mobile-view" : "step1-farm-add"
            } ${step === "step5" ? "farm-stepper-active" : ""} `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M9.00001 2.25C8.40327 2.25 7.83098 2.48705 7.40902 2.90901C6.98706 3.33097 6.75001 3.90326 6.75001 4.5C6.75001 5.09674 6.98706 5.66903 7.40902 6.09099C7.83098 6.51295 8.40327 6.75 9.00001 6.75C9.59675 6.75 10.169 6.51295 10.591 6.09099C11.013 5.66903 11.25 5.09674 11.25 4.5C11.25 3.90326 11.013 3.33097 10.591 2.90901C10.169 2.48705 9.59675 2.25 9.00001 2.25ZM5.25001 4.5C5.25016 3.79048 5.45159 3.09555 5.83091 2.49594C6.21024 1.89633 6.75189 1.41663 7.39296 1.11257C8.03402 0.808505 8.7482 0.692549 9.45254 0.778169C10.1569 0.863789 10.8225 1.14747 11.372 1.59627C11.9216 2.04507 12.3325 2.64056 12.5571 3.31359C12.7818 3.98662 12.8108 4.70956 12.641 5.39846C12.4711 6.08735 12.1093 6.71392 11.5976 7.2054C11.0859 7.69688 10.4452 8.03309 9.75001 8.175V12.75H8.25001V8.175C7.40312 8.00184 6.64201 7.54153 6.09537 6.8719C5.54874 6.20227 5.25012 5.36441 5.25001 4.5ZM2.32876 8.25H6.00001V9.75H3.67126L3.08776 15H14.9123L14.3288 9.75H12V8.25H15.6713L16.5878 16.5H1.41226L2.32876 8.25Z"
                fill={step === "step5" ? "white" : "black"}
              />
            </svg>

            <span
              className={`${step === "step5" ? "text-white" : "text-black"}`}
            >
              {isTablet ? "" : "Land Details"}
            </span>
          </div>
          <div
            className={`${
              isTablet ? "step1-farm-mobile-view" : "step2-farm-add"
            } ${step === "step1" ? "farm-stepper-active" : ""} `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="24"
              viewBox="0 0 21 24"
              fill="none"
            >
              <g clipPath="url(#clip0_123_361)">
                <path
                  d="M10.5 12C12.0913 12 13.6174 11.3679 14.7426 10.2426C15.8679 9.11742 16.5 7.5913 16.5 6C16.5 4.4087 15.8679 2.88258 14.7426 1.75736C13.6174 0.632141 12.0913 0 10.5 0C8.9087 0 7.38258 0.632141 6.25736 1.75736C5.13214 2.88258 4.5 4.4087 4.5 6C4.5 7.5913 5.13214 9.11742 6.25736 10.2426C7.38258 11.3679 8.9087 12 10.5 12ZM8.35781 14.25C3.74063 14.25 0 17.9906 0 22.6078C0 23.3766 0.623438 24 1.39219 24H19.6078C20.3766 24 21 23.3766 21 22.6078C21 17.9906 17.2594 14.25 12.6422 14.25H8.35781Z"
                  fill={step === "step1" ? "white" : "black"}
                />
              </g>
              <defs>
                <clipPath id="clip0_123_361">
                  <rect
                    width="21"
                    height="24"
                    fill={step === "step1" ? "white" : "black"}
                  />
                </clipPath>
              </defs>
            </svg>

            <span
              className={`${step === "step1" ? "text-white" : "text-black"}`}
            >
              {isTablet ? "" : "Personal Details"}
            </span>
          </div>
          <div
            className={`${
              isTablet ? "step1-farm-mobile-view" : "step3-farm-add"
            } ${step === "step2" ? "farm-stepper-active" : ""}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clipPath="url(#clip0_123_363)">
                <path
                  d="M14.25 11.25C14.2498 11.8469 14.0125 12.4193 13.5903 12.8413C13.168 13.2633 12.5955 13.5002 11.9985 13.5C11.4016 13.4998 10.8292 13.2625 10.4072 12.8402C9.98528 12.418 9.74834 11.8454 9.74854 11.2485C9.74873 10.6516 9.98606 10.0792 10.4083 9.6572C10.8305 9.23524 11.4031 8.9983 12 8.9985C12.597 8.9987 13.1694 9.23602 13.5913 9.65826C14.0133 10.0805 14.2502 10.6531 14.25 11.25ZM21.405 6H17.595C16.9068 6 16.2467 6.2734 15.7601 6.76006C15.2734 7.24671 15 7.90676 15 8.595V13.5C15 13.8978 15.1581 14.2794 15.4394 14.5607C15.7207 14.842 16.1022 15 16.5 15V24H22.5V15C22.8979 15 23.2794 14.842 23.5607 14.5607C23.842 14.2794 24 13.8978 24 13.5V8.595C24 7.90676 23.7266 7.24671 23.24 6.76006C22.7533 6.2734 22.0933 6 21.405 6Z"
                  fill={step === "step2" ? "white" : "black"}
                />
                <path
                  d="M22.5 3.00005C22.509 3.39956 22.4382 3.79684 22.2915 4.16858C22.1449 4.54033 21.9255 4.87903 21.6462 5.16481C21.3669 5.45059 21.0333 5.67768 20.665 5.83276C20.2967 5.98784 19.9011 6.06777 19.5015 6.06787C19.1019 6.06797 18.7063 5.98823 18.3379 5.83334C17.9696 5.67845 17.6359 5.45152 17.3564 5.16588C17.0769 4.88024 16.8573 4.54165 16.7105 4.16998C16.5637 3.79831 16.4927 3.40106 16.5015 3.00155C16.5188 2.21771 16.8423 1.4718 17.4028 0.923492C17.9632 0.375189 18.716 0.0680672 19.5 0.0678712C20.284 0.0676751 21.037 0.374421 21.5977 0.922443C22.1584 1.47047 22.4823 2.21621 22.5 3.00005ZM6.405 7.50005H2.595C2.25412 7.50015 1.9166 7.56739 1.60171 7.69792C1.28681 7.82846 1.00072 8.01975 0.759747 8.26086C0.518779 8.50196 0.32766 8.78817 0.197303 9.10314C0.0669451 9.41811 -9.8383e-05 9.75567 1.0836e-07 10.0965C9.85998e-05 10.4374 0.0673372 10.7749 0.197877 11.0898C0.328416 11.4047 0.519701 11.6908 0.760808 11.9318C1.00192 12.1728 1.28812 12.3639 1.60309 12.4942C1.91806 12.6246 2.25562 12.6916 2.5965 12.6915C3.28494 12.6913 3.9451 12.4177 4.43175 11.9307C4.91841 11.4438 5.1917 10.7835 5.1915 10.095C5.1913 9.40661 4.91763 8.74645 4.43069 8.2598C3.94375 7.77314 3.28344 7.49985 2.595 7.50005C1.90676 7.50005 1.2464 7.7742 0.759747 8.26086C0.27309 8.74751 1.0836e-07 9.40831 1.0836e-07 10.0965L1.63061e-07 13.5C1.63061e-07 13.8979 0.158035 14.2794 0.43934 14.5607C0.720645 14.842 1.10218 15 1.5 15L1.63061e-07 19.5H1.5V24H7.5V19.5H9L7.5 15C7.89782 15 8.27936 14.842 8.56066 14.5607C8.84196 14.2794 9 13.8979 9 13.5V10.095C9 9.75427 8.93288 9.41682 8.80247 9.10198C8.67206 8.78714 8.48091 8.50107 8.23994 8.26011C7.99897 8.01914 7.7129 7.82799 7.39806 7.69758C7.08322 7.56717 6.74578 7.50005 6.405 7.50005Z"
                  fill={step === "step2" ? "white" : "black"}
                />
                <path
                  d="M7.49997 4.50005C7.509 4.89956 7.43813 5.29684 7.29151 5.66858C7.14489 6.04033 6.92548 6.37903 6.64616 6.66481C6.36684 6.95059 6.03324 7.17768 5.66495 7.33276C5.29666 7.48784 4.90109 7.56777 4.50148 7.56787C4.10187 7.56797 3.70627 7.48823 3.3379 7.33334C2.96953 7.17845 2.63582 6.95152 2.35635 6.66588C2.07689 6.38024 1.85731 6.04165 1.71051 5.66998C1.5637 5.29831 1.49263 4.90106 1.50147 4.50155C1.5188 3.71771 1.8423 2.9718 2.40272 2.42349C2.96315 1.87519 3.71595 1.56807 4.49998 1.56787C5.28402 1.56768 6.03697 1.87442 6.59767 2.42244C7.15836 2.97047 7.48224 3.71621 7.49997 4.50005ZM10.5 19.5V24H13.5V19.5C13.8978 19.5 14.2793 19.342 14.5606 19.0607C14.8419 18.7794 15 18.3979 15 18V15.69C15 15.1092 14.7692 14.5522 14.3585 14.1415C13.9478 13.7308 13.3908 13.5 12.81 13.5H11.19C10.6091 13.5 10.0521 13.7308 9.6414 14.1415C9.2307 14.5522 8.99997 15.1092 8.99997 15.69V18C8.99997 18.3979 9.158 18.7794 9.43931 19.0607C9.72061 19.342 10.1021 19.5 10.5 19.5Z"
                  fill={step === "step2" ? "white" : "black"}
                />
              </g>
              <defs>
                <clipPath id="clip0_123_363">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span
              className={`${step === "step2" ? "text-white" : "text-black"}`}
            >
              {isTablet ? "" : "Family Details"}
            </span>
          </div>
          <div
            className={`${
              isTablet ? "step1-farm-mobile-view" : "step4-farm-add"
            } ${step === "step3" ? "farm-stepper-active" : ""}  `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M10.968 2.32496C11.2676 2.10618 11.629 1.98828 12 1.98828C12.371 1.98828 12.7324 2.10618 13.032 2.32496L20.453 7.74096C21.43 8.45296 20.927 9.99796 19.719 10.001H4.28C3.072 9.99796 2.57 8.45296 3.546 7.74096L10.967 2.32496H10.968ZM13 6.24996C13 5.98474 12.8946 5.73039 12.7071 5.54285C12.5196 5.35532 12.2652 5.24996 12 5.24996C11.7348 5.24996 11.4804 5.35532 11.2929 5.54285C11.1054 5.73039 11 5.98474 11 6.24996C11 6.51518 11.1054 6.76953 11.2929 6.95707C11.4804 7.1446 11.7348 7.24996 12 7.24996C12.2652 7.24996 12.5196 7.1446 12.7071 6.95707C12.8946 6.76953 13 6.51518 13 6.24996ZM11.25 16H9.25V11H11.25V16ZM14.75 16H12.75V11H14.75V16ZM18.5 16H16.25V11H18.5V16ZM18.75 17H5.25C4.65326 17 4.08097 17.237 3.65901 17.659C3.23705 18.0809 3 18.6532 3 19.25V19.75C3 20.165 3.336 20.5 3.75 20.5H20.25C20.4489 20.5 20.6397 20.4209 20.7803 20.2803C20.921 20.1396 21 19.9489 21 19.75V19.25C21 18.6532 20.7629 18.0809 20.341 17.659C19.919 17.237 19.3467 17 18.75 17ZM7.75 16H5.5V11H7.75V16Z"
                fill={step === "step3" ? "white" : "black"}
              />
            </svg>
            <span
              className={`${step === "step3" ? "text-white" : "text-black"}`}
            >
              {isTablet ? "" : "Bank Details"}
            </span>
          </div>

          <div
            className={`${
              isTablet ? "step1-farm-mobile-view" : "step5-farm-add"
            } ${step === "step6" ? "farm-stepper-active" : ""} `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M0.632812 17.3672L1.91074 5.75158C2.14727 9.5351 2.05439 13.9015 3.16076 16.1462C3.55468 13.1606 3.71296 10.2337 3.53208 7.39197C4.06733 9.91232 4.78164 12.3729 4.91963 15.0258C5.17961 13.0593 5.36495 11.1052 6.03752 9.06998C6.0108 11.3618 5.43424 13.461 6.4832 14.606C6.99061 12.8127 7.50245 11.0236 7.58921 8.80947C8.33699 10.9843 8.23405 13.5844 8.46629 16.0172C8.7622 13.6245 9.00348 6.95252 10.1154 4.83154C10.006 9.22537 9.97959 12.7044 11.0786 14.4724C12.1648 13.1128 12.2586 10.4492 12.2837 7.92143C13.0506 10.0923 13.5654 12.5656 13.6146 15.5974C13.9179 13.0678 14.2709 10.5381 15.1401 8.00861C14.938 11.1126 15.0949 13.5264 15.8265 14.8349C16.3103 12.882 16.7231 10.929 16.8503 8.97576C17.4978 11.886 17.3073 14.5863 17.4376 17.3672H0.632812Z"
                fill={step === "step6" ? "white" : "black"}
              />
            </svg>

            <span
              className={`${step === "step6" ? "text-white" : "text-black"}`}
            >
              {isTablet ? "" : "Farming Details"}
            </span>
          </div>
        </div>
      )}

      {step === "step1" && (
        <div className="m-4">
          <Row className="g-2">
            {/* <Col lg={3} md={3} sm={12}> */}
            {/* <Autocomplete
                id="unit"
                name="unit"
                size="small"
                options={unitOptions}
                value={inputData?.unit}
                onChange={(e, opt) => {
                  handleChangeInput("unit", opt);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Unit"
                    size="small"
                    required
                    error={inputDataError?.unit}
                  />
                )}
              /> */}
            {/* <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Unit Address <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={8}>
                  <Select
                    isMulti={false}
                    onChange={(e) => {
                      handleChangeInput("unit", e);
                    }}
                    value={inputData?.unit}
                    options={unitOptions}
                    // menuPlacement="top"
                    name="choices-single-default"
                    id="countryAddCompany"
                    className={`w-100 ${
                      inputDataError?.unit ? "border border-danger" : ""
                    }`}
                  ></Select>
                </Col> */}
            {/* </Col> */}
            <Col lg={3} md={3} sm={12}>
              <TextField
                name="farmerCode"
                size="small"
                label="Farmer Code"
                required
                error={inputDataError?.farmerCode}
                value={inputData?.farmerCode}
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                fullWidth
                variant="standard"
              />
              {inputDataError?.farmerCode && (
                <ErrorMessage message={"Farmer Code is required"} />
              )}

              {/* <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Farmer Code <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.farmerCode}
                    name="farmerCode"
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.value)
                    }
                    className={`cust-input ${
                      inputDataError?.farmerCode ? "border-danger" : ""
                    }`}
                    type="text"
                  />
                </Col> */}
            </Col>
            <Col lg={3} md={3} sm={12}>
              <TextField
                name="farmerName"
                size="small"
                label="Farmer Name "
                required
                error={inputDataError?.farmerName}
                value={inputData?.farmerName}
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                fullWidth
                variant="standard"
                helperText="(Firstname) (Middlename) (Lastname)"
              />
              {inputDataError?.farmerName && (
                <ErrorMessage message={"Farmer Name is required"} />
              )}
              {/* <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Farmer Name <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col md={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.farmerName}
                    name="farmerName"
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.value)
                    }
                    className={`cust-input ${
                      inputDataError?.farmerName ? "border-danger" : ""
                    }`}
                    type="text"
                  />
                </Col> */}
            </Col>
            <Col lg={3} md={3} sm={12}>
              <TextField
                name="panNo"
                size="small"
                label="Pan No"
                error={inputDataError?.panNo}
                value={inputData?.panNo}
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value.toUpperCase())
                }
                fullWidth
                variant="standard"
              />
            </Col>
          </Row>
          <Row className=" g-2 mt-2">
            <Col lg={3} md={12} sm={12}>
              <TextField
                name="email"
                size="small"
                label="Farmer Email"
                value={inputData?.email}
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                fullWidth
                variant="standard"
              />

              {/* <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Farmer Email
                  </Label>
                </Col>
                <Col sm={8}>
                
                  <Input
                    id="exampleEmail"
                    value={inputData?.email}
                    name="email"
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.value)
                    }
                    className="cust-input"
                    type="email"
                  />
                </Col> */}
            </Col>
            <Col lg={3} md={12} sm={12}>
              <FormControl>
                <FormLabel id="gender-group">Gender</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="gender-group"
                  name="gender"
                  onChange={(e) => {
                    handleChangeInput(e.target.name, e.target.value);
                  }}
                  value={inputData.gender}
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
              {/* <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Gender <span className="required_span"> * </span>
                  </Label>
                </Col>
                <Col sm={8} className="d-flex mt-2">
                  <div>
                    <Input
                      id="exampleEmail"
                      checked={inputData?.gender === "male"}
                      style={{ marginRight: 5 }}
                      name="gender"
                      onChange={(e) => handleChangeInput(e.target.name, "male")}
                      className={`cust-input ${
                        inputDataError?.gender ? "border-danger" : ""
                      }`}
                      type="radio"
                    />
                    Male
                  </div>
                  <div style={{ marginLeft: 10 }}>
                    <Input
                      id="exampleEmail"
                      checked={inputData?.gender === "female"}
                      style={{ marginRight: 5 }}
                      name="gender"
                      onChange={(e) =>
                        handleChangeInput(e.target.name, "female")
                      }
                      className={`cust-input ${
                        inputDataError?.gender ? "border-danger" : ""
                      }`}
                      type="radio"
                    />
                    female
                  </div>
                  <div style={{ marginLeft: 10 }}>
                    <Input
                      id="exampleEmail"
                      checked={inputData?.gender === "other"}
                      style={{ marginRight: 5 }}
                      name="gender"
                      onChange={(e) =>
                        handleChangeInput(e.target.name, "other")
                      }
                      className={`cust-input ${
                        inputDataError?.gender ? "border-danger" : ""
                      }`}
                      type="radio"
                    />
                    other
                  </div>
                </Col> */}
            </Col>
            <Col lg={3} md={12} sm={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="DOB"
                  format={keys().shortDateFormat}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "standard",
                      fullWidth: true,
                    },
                  }}
                  onChange={(dateValue) => {
                    let age = calculateAge(
                      dayjs(dateValue).format("YYYY-MM-DD")
                    );
                    if (age >= 18) {
                      setInputData({
                        ...inputData,
                        dob: dateValue,
                        age,
                        dobstring: dayjs(dateValue).format("YYYY-MM-DD"),
                      });
                      setInputDataError({ ...inputDataError, dob: false });
                    } else {
                      toast.error("Age should be greater than 18");
                    }
                  }}
                  value={inputData?.dob}
                  clearable
                />
              </LocalizationProvider>
              {inputData.age < 18 && (
                <ErrorMessage message={"Age should be greater than 18"} />
              )}
              {/*   <Col md={4}></Col>
                <Col sm={8}>
                  <Flatpickr
                    className={`form-control ${
                      inputDataError?.dob ? " border border-danger" : ""
                    }`}
                    options={{
                      dateFormat: "Y-m-d",
                      maxDate: maxDateVar,
                    }}
                    value={inputData?.dob}
                    onChange={(date, dateStr) => {
                      console.log(dateStr);
                      let age = calculateAge(dateStr);
                      if (age >= 18) {
                        setInputData({ ...inputData, dob: dateStr });
                        setInputDataError({ ...inputDataError, dob: false });
                      } else {
                        toast.error("Age is not at least 18 years old");
                      }
                    }}
                  />
                </Col> */}
            </Col>
          </Row>
          {/* <Row className="g-2 mt-2">
            <Col lg={4} md={4} sm={12}>
              <>
                {inputData?.farmerPhoto ? (
                  <div style={{ display: "flex", columnGap: 6 }}>
                    <img
                      src={inputData?.farmerPhoto?.url}
                      style={{ height: "100px", width: "100px" }}
                      alt="Captured"
                    />
                    <MuiButton
                      // style={{
                      //   height: "30px",
                      //   width: "80px",
                      //   marginTop: "30px",
                      // }}
                      className="custom-btn-page-layout custom-btn-upload"
                      onClick={() => setShowCapturePopup(true)}
                      startIcon={<ChangeCircle />}
                    >
                      {`Change`}
                    </MuiButton>
                  </div>
                ) : (
                  <MuiButton
                    type="button"
                    onClick={() => setShowCapturePopup(true)}
                    className={`custom-btn-page-layout custom-btn-upload ${
                      inputDataError?.farmerPhoto ? "border border-danger" : ""
                    }`}
                    startIcon={<PhotoCamera />}
                  >
                    {`Capture photo`}
                  </MuiButton>
                )}
                <input
                  style={{ display: "none" }}
                  type="file"
                  name="farmerPhoto"
                  ref={farmerPhotoImage}
                  onChange={(e) =>
                    handleFarmerImageChange(e.target.name, e.target.files[0])
                  }
                />
                <span
                  className="mx-3"
                  style={{ fontWeight: 500, fontSize: 10 }}
                >
                  {inputData?.farmerPhoto
                    ? inputData?.farmerPhoto?.name
                    : ".jpg, .png, .jpeg upload (Max size 150 KB)"}
                </span>
              </>
            </Col>

            <Col lg={4} md={4} sm={12}>
              <MuiButton
                type="button"
                onClick={handlepanCardPhotoImageUploadBtn}
                className={`custom-btn-page-layout custom-btn-upload ${
                  inputDataError?.panCardImage ? "border border-danger" : ""
                }`}
                startIcon={<Upload />}
              >
                Upload Pan Image
              </MuiButton>

              <input
                style={{ display: "none" }}
                type="file"
                accept="image/png, image/jpeg , image/jpg"
                name="panCardImage"
                ref={panCardPhotoImage}
                onChange={(e) =>
                  handleImageChange(e.target.name, e.target.files[0])
                }
              />

              <span className="mx-3" style={{ fontWeight: 500, fontSize: 10 }}>
                {inputData?.panCardImage
                  ? inputData?.panCardImage?.name
                  : ".jpg, .png, .jpeg upload (Max size 150 KB)"}
              </span>
            </Col>
          </Row> */}
          {/* FarmerName , gender and dob */}

          {/* address1 , address2 and panNo */}
          <Row className=" g-2 mt-2">
            <Col lg={6} md={6} sm={12}>
              <TextField
                name="addressLine1"
                size="small"
                label="Address Line 1"
                value={inputData?.addressLine1}
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                fullWidth
                error={inputDataError?.addressLine1}
                variant="standard"
              />
              {/* <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Address Line 1
                    
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.addressLine1}
                    name="addressLine1"
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.value)
                    }
                    className={`cust-input ${
                      inputDataError?.addressLine1 ? "border-danger" : ""
                    }`}
                    type="text"
                  />
                </Col> */}
            </Col>
            <Col lg={6} md={6} sm={12}>
              <TextField
                name="addressLine2"
                size="small"
                label="Address Line 2"
                value={inputData?.addressLine2}
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                fullWidth
                error={inputDataError?.addressLine2}
                variant="standard"
              />
              {/* <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    Address Line 2
                                      </Label>
                </Col>
                <Col sm={8} className="d-flex ">
                  <Input
                    id="exampleEmail"
                    value={inputData?.addressLine2}
                    name="addressLine2"
                    onChange={(e) =>
                      handleChangeInput(e.target.name, e.target.value)
                    }
                    className={`cust-input ${
                      inputDataError?.addressLine2 ? "border-danger" : ""
                    }`}
                    type="text"
                  />
                </Col> */}
            </Col>
            {/* <Col lg={4} md={12} sm={12} className="d-flex">
                <Col md={4}>
                  <Label
                    className="me-3"
                    style={{ fontWeight: 500 }}
                    for="exampleEmail"
                    md={12}
                  >
                    PAN No.
                  </Label>
                </Col>
                <Col sm={8}>
                  <Input
                    id="exampleEmail"
                    value={inputData?.panNo}
                    name="panNo"
                    onChange={(e) =>
                      handleChangeInput(
                        e.target.name,
                        e.target.value.toUpperCase()
                      )
                    }
                    className={`cust-input ${
                      inputDataError?.panNo ? "border-danger" : ""
                    }`}
                    type="text"
                  />
                </Col>
              </Col> */}
          </Row>
          <Row className="g-2 mt-2">
            <Col lg={2} md={2} sm={12}>
              <TextField
                value={inputData?.pincode}
                name="pincode"
                label="Pincode"
                onChange={(e) => {
                  handleChangeInput(e.target.name, e.target.value);
                }}
                error={inputDataError?.pincode}
                size="small"
                variant="standard"
                fullWidth
              />
            </Col>
            <Col lg={2} md={2} sm={12}>
              <Autocomplete
                id="countryAddCompany"
                name="countryAddCompany"
                size="small"
                options={villageArray}
                value={inputData?.village}
                onChange={(e, opt) => {
                  const optValues = {
                    village: opt,
                    taluka: talukaArray.find((x) => x.id === opt?.talukaId_id),
                    district: districtArray.find(
                      (x) => x.id === opt?.districtId_id
                    ),
                    state: stateArray.find((x) => x.id === opt?.stateId_id),
                    country: countryArray.find(
                      (x) => x.id === opt?.countryId_id
                    ),
                  };

                  const optValues1 = {
                    guardianVillage: opt,
                    guardianTaluka: optValues?.taluka,
                    guardianDist: optValues?.district,
                    guardianState: optValues?.state,
                    guardianCountry: optValues.country,
                  };

                  setSelectedDropdownValues({ ...optValues });
                  setSelectedGuardianDropdownValues({ ...optValues1 });
                  setInputData({
                    ...inputData,
                    ...optValues,
                    ...optValues1,
                    dist: optValues.district,
                  });
                  setInputDataError({
                    ...inputDataError,
                    village: false,
                    dist: false,
                    country: false,
                    taluka: false,
                    state: false,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Village"
                    size="small"
                    error={inputDataError?.village}
                  />
                )}
              />
            </Col>
            <Col lg={2} md={2} sm={12}>
              <Autocomplete
                id="countryAddCompany"
                name="countryAddCompany"
                size="small"
                options={talukaArray}
                value={inputData?.taluka}
                // onChange={ondropdownchange}
                // onChange={(e, opt) => {
                //   setSelectedDropdownValues((prec) => ({
                //     ...prec,
                //     taluka: opt,
                //   }));
                //   const a = [...landDetails];
                //   a[landIndex]["taluka"] = opt.label;
                //   setLandDetails(a);
                // }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Taluka"
                    size="small"
                    error={inputDataError?.taluka}
                  />
                )}
              />
            </Col>
            <Col lg={2} md={2} sm={12}>
              <Autocomplete
                id="countryAddCompany"
                name="countryAddCompany"
                size="small"
                options={districtArray}
                value={inputData?.dist}
                // onChange={ondropdownchange}
                // onChange={(e, opt) => {

                //   const a = [...landDetails];
                //   a[landIndex]["taluka"] = opt.label;
                //   setLandDetails(a);
                // }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="District"
                    size="small"
                    error={inputDataError?.dist}
                  />
                )}
              />
            </Col>
            <Col lg={2} md={2} sm={12}>
              <Autocomplete
                id="countryAddCompany"
                name="countryAddCompany"
                size="small"
                options={stateArray}
                value={inputData?.state}
                // onChange={ondropdownchange}
                // onChange={(e, opt) => {
                //   setSelectedDropdownValues((prec) => ({
                //     ...prec,
                //     taluka: opt,
                //   }));
                //   const a = [...landDetails];
                //   a[landIndex]["taluka"] = opt.label;
                //   setLandDetails(a);
                // }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="State"
                    size="small"
                    error={inputDataError?.state}
                  />
                )}
              />
            </Col>
            <Col lg={2} md={2} sm={12}>
              <Autocomplete
                id="countryAddCompany"
                name="countryAddCompany"
                size="small"
                options={countryArray}
                value={inputData?.country}
                // onChange={ondropdownchange}
                // onChange={(e, opt) => {
                //   setSelectedDropdownValues((prec) => ({
                //     ...prec,
                //     taluka: opt,
                //   }));
                //   const a = [...landDetails];
                //   a[landIndex]["taluka"] = opt.label;
                //   setLandDetails(a);
                // }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Country"
                    size="small"
                    error={inputDataError?.country}
                  />
                )}
              />
            </Col>
          </Row>
          <Row className="g-2 mt-2">
            <Col lg={4} md={12} sm={12}>
              <TextField
                name="mobileNo"
                size="small"
                label="Mobile No"
                required
                value={inputData?.mobileNo}
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                fullWidth
                variant="standard"
                error={inputDataError?.mobileNo}
              />
            </Col>
            <Col lg={4} md={12} sm={12}>
              <TextField
                name="phoneNo"
                size="small"
                label="Alternate Phone No"
                value={inputData?.phoneNo}
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                fullWidth
                variant="standard"
                error={inputDataError?.phoneNo}
              />
            </Col>
            <Col lg={4} md={12} sm={12}>
              <TextField
                name="primaryContactPerson"
                size="small"
                label="Designated Successor"
                value={inputData?.primaryContactPerson}
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                fullWidth
                variant="standard"
                error={inputDataError?.primaryContactPerson}
              />
            </Col>
          </Row>
          {/* Next Button View */}
          <Row className="g-2 mt-2">
            <Col
              md={12}
              className="d-flex justify-content-end align-items-end mt-2"
            >
              <MuiButton
                type="button"
                className="custom-btn-page-layout mx-2"
                onClick={() => setStep("step5")}
                startIcon={<NavigateBefore />}
              >
                Previous
              </MuiButton>
              <MuiButton
                type="button"
                className="custom-btn-page-layout "
                onClick={() => handleNextBtn("step1")}
                endIcon={<NavigateNext />}
              >
                Next
              </MuiButton>
            </Col>
          </Row>
        </div>
      )}

      {step === "step2" && (
        <div className="m-4">
          {/* <div className="step2-farm-div"> */}

          <Row className="g-3 mt-2 ">
            <Col lg={3} md={3} sm={12}>
              <TextField
                name="name"
                size="small"
                label="Name"
                required
                value={familyInputData?.name}
                onChange={(e) =>
                  handleChangFamilyDetailsInput(e.target.name, e.target.value)
                }
                fullWidth
                variant="standard"
                error={inputDataError?.familyName}
              />
              {/* <Col md={3}>
                <Label
                  className="me-3"
                  style={{ fontWeight: 500 }}
                  for="exampleEmail"
                  md={12}
                >
                  Name <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col md={9}>
                <Input
                  id="exampleEmail"
                  value={familyInputData?.name}
                  name="name"
                  onChange={(e) =>
                    handleChangFamilyDetailsInput(e.target.name, e.target.value)
                  }
                  className={`cust-input ${
                    inputDataError?.familyName ? "border-danger" : ""
                  }`}
                  type="text"
                />
              </Col> */}
            </Col>
            <Col lg={2} md={2} sm={12}>
              <VirtualizeAutoComplete
                fullWidth
                options={relation_list}
                value={selectedRelation}
                onChange={(e, opt) => {
                  // ondropdownchange(e, opt, "employeeid", "selectedemployee")
                  setSelectdeRelation(opt);
                  handleChangFamilyDetailsInput(
                    "relation",
                    opt === null ? "" : opt.label
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} {...textFieldProps} label="Relation" />
                )}
              />
              {/* <TextField
                name="relation"
                size="small"
                label="Relation"
                required
                value={familyInputData?.relation}
                onChange={(e) =>
                  handleChangFamilyDetailsInput(e.target.name, e.target.value)
                }
                fullWidth
                variant="standard"
                error={inputDataError?.familyRelation}
              /> */}
              {/* <Col md={4}>
                <Label
                  className="me-3"
                  style={{ fontWeight: 500 }}
                  for="exampleEmail"
                  md={12}
                >
                  Relation <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col sm={8} className="d-flex mt-2">
                <Input
                  id="exampleEmail"
                  value={familyInputData?.relation}
                  name="relation"
                  onChange={(e) =>
                    handleChangFamilyDetailsInput(e.target.name, e.target.value)
                  }
                  className={`cust-input ${
                    inputDataError?.familyRelation ? "border-danger" : ""
                  }`}
                  type="text"
                />
              </Col> */}
            </Col>
            <Col lg={2} md={2} sm={12}>
              <Autocomplete
                id="countryAddCompany"
                name="countryAddCompany"
                size="small"
                options={genderArray}
                value={familyInputData?.gender}
                onChange={(e, opt) => {
                  handleChangFamilyDetailsInput("gender", opt);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Gender"
                    size="small"
                    error={inputDataError?.familyGender}
                  />
                )}
              />
              {/* <Col md={4}>
                <Label
                  className="me-3"
                  style={{ fontWeight: 500 }}
                  for="exampleEmail"
                  md={12}
                >
                  Gender <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col sm={7} style={{ marginLeft: 10 }} className=" d-flex mt-2">
                <Select
                  isMulti={false}
                  onChange={(e) => {
                    handleChangFamilyDetailsInput("gender", e);
                  }}
                  value={familyInputData?.gender}
                  options={genderArray}
                  // menuPlacement="top"
                  name="choices-single-default"
                  id="countryAddCompany"
                  className={`w-100 ${
                    inputDataError?.familyGender ? "border border-danger" : ""
                  }`}
                ></Select>
              </Col> */}
            </Col>
            <Col lg={2} md={12} sm={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="DOB"
                  format={keys().shortDateFormat}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "standard",
                      fullWidth: true,
                    },
                  }}
                  onChange={(dateValue) => {
                    try {
                      setFamilyInputData({
                        ...familyInputData,
                        dob: dateValue,
                        familydobstring: dayjs(dateValue).format("YYYY-MM-DD"),
                      });
                      setInputDataError({ ...inputDataError, dob: false });
                    } catch (ex) {
                      console.log("DATE EXCEPTION", ex);
                    }
                  }}
                  value={familyInputData?.dob}
                  clearable
                />
              </LocalizationProvider>
              {/* <Col md={4}>
                <Label
                  className="me-3"
                  style={{ fontWeight: 500 }}
                  for="exampleEmail"
                  md={12}
                >
                  DOB
                  
                </Label>
              </Col>
              <Col sm={8} className="d-flex mt-2">
                <Flatpickr
                  className={`form-control ${
                    inputDataError?.familyDob ? "border border-danger" : ""
                  }`}
                  options={{
                    dateFormat: "Y-m-d",
                    maxDate: new Date(),
                  }}
                  value={familyInputData?.dob}
                  onChange={(date, dateStr) => {
                    console.log(date,dateStr)
                    setFamilyInputData({
                      ...familyInputData,
                      dob: dateStr,
                    });
                    if (dateStr?.length > 0) {
                      setInputDataError({
                        ...inputDataError,
                        familyDob: false,
                      });
                    } else {
                      setInputDataError({ ...inputDataError, familyDob: true });
                    }
                  }}
                />
              </Col> */}
            </Col>
            <Col lg={1} md={1} sm={12} className="d-flex">
              <IconButton
                className="custom-btn-page-layout custom-btn-success text-white no-shadow"
                onClick={() => {
                  handleAddIconStep2();
                }}
              >
                <PersonAdd />
              </IconButton>
            </Col>
          </Row>

          <div
            className="d-grid"
            style={{ margin: "20px 0px", border: "1px solid #DEE2E6" }}
          >
            <TableContainer
              columns={column}
              data={inputData?.familyDetails}
              isGlobalFilter={true}
              showColumnFilters={false}
              isAddUserList={false}
              tableClass={"table-sm"}
              divClass={"table-responsive"}
              showPagination={
                inputData?.familyDetails.length > 10 ? true : false
              }
              initialState={{ pageSize: 10, pageIndex: 0 }}
            />
          </div>
          <div
            className={`d-flex justify-content-end align-items-end`}
            style={{ marginBottom: 20 }}
          >
            <MuiButton
              type="button"
              className="custom-btn-page-layout text-white mx-4 "
              onClick={() => setStep("step1")}
              startIcon={<NavigateBefore />}
            >
              Previous
            </MuiButton>
            <MuiButton
              type="button"
              className="custom-btn-page-layout btn text-white"
              onClick={() => handleNextBtn("step2")}
              endIcon={<NavigateNext />}
            >
              Next
            </MuiButton>
          </div>
        </div>
      )}

      {step === "step3" && (
        <div className="m-4">
          {/* bankName and  ifsc  */}

          <Row className="g-3 mt-2">
            <Col lg={3} md={12} sm={12}>
              <VirtualizeAutoComplete
                fullWidth
                options={bank_list}
                value={selectedBank}
                onChange={(e, opt) => {
                  // ondropdownchange(e, opt, "employeeid", "selectedemployee")
                  setSelectedBank(opt);
                  handleChangeInput("bankName", opt === null ? "" : opt.label);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...textFieldProps}
                    label="Bank Name"
                  />
                )}
              />

              {/* <TextField
                name="bankName"
                size="small"
                label="Bank Name"
                required
                value={inputData?.bankName}
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                fullWidth
                variant="standard"
                error={inputDataError?.bankName}
              /> */}
            </Col>
            <Col lg={3} md={12} sm={12}>
              <TextField
                name="ifscCode"
                size="small"
                label="IFSC Code"
                required
                value={inputData?.ifscCode}
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value.toUpperCase())
                }
                fullWidth
                variant="standard"
                error={inputDataError?.ifscCode}
              />
            </Col>
            <Col lg={3} md={12} sm={12}>
              <TextField
                name="accountNo"
                size="small"
                label="Account Numbe"
                required
                value={inputData?.accountNo}
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                fullWidth
                variant="standard"
                type="number"
                error={inputDataError?.accountNo}
              />
            </Col>
            <Col lg={3} md={12} sm={12}>
              <TextField
                name="branchName"
                size="small"
                label="Branch Name"
                value={inputData?.branchName}
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                fullWidth
                variant="standard"
                error={inputDataError?.branchName}
              />
            </Col>
          </Row>

          {/* Upload Options  */}

          <Row className=" g-3 mt-2 ">
            <Col lg={12} md={12} sm={12}>
              <MuiButton
                type="button"
                onClick={handlecancelChequeImageUploadBtn}
                className={`custom-btn-page-layout custom-btn-upload ${
                  inputDataError?.cancelCheque ? " border border-danger" : ""
                }`}
                startIcon={<Upload />}
              >
                {`Upload Cheque Image`}
              </MuiButton>

              <input
                style={{ display: "none" }}
                type="file"
                accept="image/png, image/jpeg , image/jpg"
                name="cancelCheque"
                ref={cancelChequeImage}
                onChange={(e) =>
                  handleImageChange(e.target.name, e.target.files[0])
                }
              />

              <span className="mx-3" style={{ fontWeight: 500, fontSize: 10 }}>
                {inputData?.cancelCheque
                  ? inputData?.cancelCheque?.name
                  : "Option to Upload Cancel Cheque or Bank Passbook Front Page"}
              </span>
            </Col>
          </Row>

          <div
            className={`d-flex justify-content-end align-items-end`}
            style={{ marginBottom: 20 }}
          >
            <MuiButton
              type="button"
              className="custom-btn-page-layout text-white mx-4"
              onClick={() => setStep("step2")}
              startIcon={<NavigateBefore />}
            >
              Previous
            </MuiButton>
            <MuiButton
              type="button"
              className="custom-btn-page-layout text-white"
              onClick={() => handleNextBtn("step3")}
              endIcon={<NavigateNext />}
            >
              Next
            </MuiButton>
          </div>
        </div>
      )}

      {step === "step5" && (
        <div style={{ margin: 20 }}>
          <Row className=" g-3 mt-2 ">
            <Col lg={4} md={4} sm={12}>
              <TextField
                id="aadharCardNo"
                name="aadharCardNo"
                size="small"
                label="Aadhar Card"
                required
                error={inputDataError?.aadharCardNo}
                value={inputData?.aadharCardNo}
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                fullWidth
                variant="standard"
              />
              {inputDataError?.aadharCardNo && (
                <ErrorMessage message={"Aadhar number is required"} />
              )}
            </Col>
            {/* <Col lg={2} md={3} sm={12}>
              <TextField
                disabled
                variant="standard"
                label={`File name`}
                placeholder="Aadhar Card Image"
                helperText={"(Max size 150 KB)"}
                InputLabelProps={{ shrink: true }}
                error={`${inputDataError?.aadharCardImage}`}
                value={
                  inputData?.aadharCardImage && inputData?.aadharCardImage?.name
                }
              />
            </Col> */}
            {/* <Col lg={4} md={4} sm={12}>
              <MuiButton
                component="label"
                variant="contained"
                startIcon={<CloudUpload />}
                //onClick={handleaadharCardPhotoImageUploadBtn}
                className="custom-btn-page-layout custom-btn-warning"
              >
                Upload Aadhar Card
                <VisuallyHiddenInput
                  type="file"
                  accept="image/png, image/jpeg , image/jpg"
                  name="aadharCardImage"
                  ref={aadharCardPhotoImage}
                  onChange={(e) =>
                    handleImageChange(e.target.name, e.target.files[0])
                  }
                />
              </MuiButton>
            </Col> */}
          </Row>
          <Divider
            className="border-danger my-2"
            style={{ borderBottomWidth: 2 }}
          />
          <div
            className={`d-flex justify-content-end align-items-center w-100 ${
              isTablet ? "flex-column gap-2" : ""
            }`}
          >
            <MuiButton
              onClick={() => {
                setLandDetails([
                  ...landDetails,
                  {
                    indexId: landDetails?.length + 1,
                    country: "",
                    state: "",
                    dist: "",
                    taluka: "",
                    village: "",
                    surveyNo: "",
                    totalLand: "",
                    registeredLand: "",
                    vigha: "",
                    regVigha: "",
                    countryArray: countryArray,
                    stateArray: [],
                    districtArray: [],
                    villageArray: [],
                    talukaArray: [],
                    ownerShipStatus: false,
                    landOwnerName: inputData?.farmerName,
                    address:
                      inputData?.addressLine1 +
                      inputData?.addressLine2 +
                      inputData?.addressLine3,
                    guardianCountry: inputData?.country,
                    guardianState: inputData?.state,
                    guardianDist: inputData?.dist,
                    guardianTaluka: inputData?.taluka,
                    guardianVillage: inputData?.village,
                    guardianPincode: inputData?.pincode,
                    guardianName: "",
                    tenantDocument: "",
                    agreementDocument: "",
                    landDocument: "",
                  },
                ]);

                setLandDetailsError([
                  ...landDetailsError,
                  {
                    country: false,
                    state: false,
                    dist: false,
                    taluka: false,
                    village: false,
                    surveyNo: false,
                    totalLand: false,
                    registeredLand: false,
                    vigha: false,
                    regVigha: false,
                    ownerShipStatus: false,
                    landOwnerName: false,
                    address: false,
                    guardianCountry: false,
                    guardianState: false,
                    guardianDist: false,
                    guardianTaluka: false,
                    guardianVillage: false,
                    guardianPincode: false,
                    guardianName: false,
                    tenantDocument: false,
                    agreementDocument: false,
                    landDocument: false,
                  },
                ]);
                setCheckAvailabilty([...checkAvailabilty, { check: false }]);
              }}
              className="custom-btn-page-layout custom-btn-primary text-white "
              variant="contained"
              startIcon={<ControlPoint />}
              size="medium"
            >{`Add New Land Details`}</MuiButton>
          </div>

          {landDetails?.map((landItem, landIndex) => (
            <Accordion
              key={landIndex}
              open={open}
              className={landIndex === 0 ? "mt-1" : ""}
              toggle={toggle}
            >
              <fieldset
                className="cus-add-req-sur border-none m-0 p-0"
                style={{ border: "none" }}
              >
                <AccordionItem>
                  <AccordionHeader targetId={`${landIndex + 1}`}>
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div>
                        {`Land No : ${landIndex + 1} ${
                          landItem?.surveyNo &&
                          `[${landItem?.landOwnerName}  ${landItem?.village?.label} - ${landItem?.surveyNo}]`
                        }`}
                      </div>
                      <div>
                        {landDetails?.length > 1 && (
                          <IconButton
                            //className="p-0 text-danger"
                            className="custom-btn-page-layout custom-btn-danger text-white no-shadow rounded p-1 text-danger me-1"
                            onClick={() => {
                              handleDeleteLandDetails(landIndex);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        )}

                        {Number(landIndex + 1) === Number(open) ? (
                          <IconButton className="p-1 text-white custom-btn-page-layout custom-btn-black text-white no-shadow rounded">
                            <ExpandMore />
                          </IconButton>
                        ) : (
                          <IconButton className="p-1 text-white custom-btn-page-layout custom-btn-black text-white no-shadow rounded">
                            <ExpandLess />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  </AccordionHeader>
                  <AccordionBody accordionId={`${landIndex + 1}`}>
                    {/* State, Dist and Taluka  */}
                    <div>
                      <Row className="g-2 mt-2">
                        <Col lg={3} md={3} sm={12}>
                          <Autocomplete
                            id="countryAddCompany"
                            name="countryAddCompany"
                            size="small"
                            options={villageArray}
                            value={selectedDropdownValues.village}
                            onChange={(e, opt) => {
                              const optValues = {
                                village: opt,
                                taluka: talukaArray.find(
                                  (x) => x.id === opt?.talukaId_id
                                ),
                                district: districtArray.find(
                                  (x) => x.id === opt?.districtId_id
                                ),
                                state: stateArray.find(
                                  (x) => x.id === opt?.stateId_id
                                ),
                                country: countryArray.find(
                                  (x) => x.id === opt?.countryId_id
                                ),
                              };
                              const optValues1 = {
                                guardianVillage: opt,
                                guardianTaluka: optValues?.taluka,
                                guardianDist: optValues?.guardianDist,
                                guardianState: optValues?.guardianState,
                                guardianCountry: optValues?.country,
                              };
                              setSelectedDropdownValues({ ...optValues });
                              setSelectedGuardianDropdownValues({
                                ...optValues1,
                              });
                              setInputData({
                                ...inputData,
                                ...optValues,
                                optValues1,
                              });
                              const a = [...landDetails];
                              a[landIndex]["village"] = opt;
                              a[landIndex]["taluka"] = optValues?.taluka;
                              a[landIndex]["dist"] = optValues?.district;
                              a[landIndex]["state"] = optValues?.state;
                              a[landIndex]["country"] = optValues?.country;

                              a[landIndex]["guardianVillage"] = opt;
                              a[landIndex]["guardianTaluka"] =
                                optValues?.taluka;
                              a[landIndex]["guardianDist"] =
                                optValues?.district;
                              a[landIndex]["guardianState"] = optValues?.state;
                              a[landIndex]["guardianCountry"] =
                                optValues?.country;

                              setLandDetails(a);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Village"
                                size="small"
                                error={landDetailsError[landIndex]["village"]}
                              />
                            )}
                          />
                        </Col>
                        <Col lg={3} md={3} sm={12}>
                          <Autocomplete
                            id="countryAddCompany"
                            name="countryAddCompany"
                            size="small"
                            options={talukaArray}
                            value={selectedDropdownValues.taluka}
                            // onChange={ondropdownchange}
                            // onChange={(e, opt) => {
                            //   setSelectedDropdownValues((prec) => ({
                            //     ...prec,
                            //     taluka: opt,
                            //   }));
                            //   const a = [...landDetails];
                            //   a[landIndex]["taluka"] = opt.label;
                            //   setLandDetails(a);
                            // }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Taluka"
                                size="small"
                                error={landDetailsError[landIndex]["taluka"]}
                              />
                            )}
                          />
                        </Col>
                        <Col lg={2} md={2} sm={12}>
                          <Autocomplete
                            id="countryAddCompany"
                            name="countryAddCompany"
                            size="small"
                            options={districtArray}
                            value={selectedDropdownValues.district}
                            // onChange={ondropdownchange}
                            // onChange={(e, opt) => {

                            //   const a = [...landDetails];
                            //   a[landIndex]["taluka"] = opt.label;
                            //   setLandDetails(a);
                            // }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="District"
                                size="small"
                                error={landDetailsError[landIndex]["dist"]}
                              />
                            )}
                          />
                        </Col>
                        <Col lg={2} md={2} sm={12}>
                          <Autocomplete
                            id="countryAddCompany"
                            name="countryAddCompany"
                            size="small"
                            options={stateArray}
                            value={selectedDropdownValues.state}
                            // onChange={ondropdownchange}
                            // onChange={(e, opt) => {
                            //   setSelectedDropdownValues((prec) => ({
                            //     ...prec,
                            //     taluka: opt,
                            //   }));
                            //   const a = [...landDetails];
                            //   a[landIndex]["taluka"] = opt.label;
                            //   setLandDetails(a);
                            // }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="State"
                                size="small"
                                error={landDetailsError[landIndex]["state"]}
                              />
                            )}
                          />
                        </Col>
                        <Col lg={2} md={2} sm={12}>
                          <Autocomplete
                            id="countryAddCompany"
                            name="countryAddCompany"
                            size="small"
                            options={countryArray}
                            value={selectedDropdownValues.country}
                            // onChange={ondropdownchange}
                            // onChange={(e, opt) => {
                            //   setSelectedDropdownValues((prec) => ({
                            //     ...prec,
                            //     taluka: opt,
                            //   }));
                            //   const a = [...landDetails];
                            //   a[landIndex]["taluka"] = opt.label;
                            //   setLandDetails(a);
                            // }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Country"
                                size="small"
                                error={landDetailsError[landIndex]["country"]}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </div>
                    {/* village,surveyNo and totalLand  */}
                    <div>
                      <Row className=" g-2 mt-2">
                        <Col lg={3} md={12} sm={12} className="d-flex ">
                          <TextField
                            value={landItem?.surveyNo}
                            name="surveyNo"
                            label="Survey #"
                            onChange={(e) => {
                              handleChangeLandDetailsInput(
                                landIndex,
                                e.target.name,
                                e.target.value
                              );
                            }}
                            required
                            error={landDetailsError[landIndex]["surveyNo"]}
                            placeholder="Enter Survey #"
                            id="surveyNo"
                            size="small"
                            variant="standard"
                            fullWidth
                            onBlur={() =>
                              handleCheckAvailabilty(landItem, landIndex)
                            }
                          />
                        </Col>
                        <Col lg={6}>
                          <MuiButton
                            type="button"
                            className="custom-btn-page-layout btn text-white mx-3"
                            onClick={() => {
                              handleCheckAvailabilty(landItem, landIndex);
                            }}
                            startIcon={
                              availibilityLoading ? (
                                <CircularProgress size={15} color="warning" />
                              ) : (
                                <Check />
                              )
                            }
                          >
                            {` Check Land Availability`}
                          </MuiButton>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row className=" g-2 mt-2">
                        <Col lg={3} md={3} sm={12}>
                          <TextField
                            value={landItem?.totalLand}
                            name="totalLand"
                            label="Total Land in Document (In Sqm)"
                            onChange={(e) => {
                              handleChangeLandDetailsInput(
                                landIndex,
                                e.target.name,
                                e.target.value
                              );
                            }}
                            required
                            disabled={landItem?.remainLandVigha ? true : false}
                            error={landDetailsError[landIndex]["totalLand"]}
                            placeholder="Enter Total Land"
                            id="totalLand"
                            size="small"
                            variant="standard"
                            fullWidth
                          />
                        </Col>
                        <Col lg={3} md={3} sm={12}>
                          <TextField
                            value={landItem?.vigha}
                            name="vigha"
                            label="Total Vigha"
                            onChange={(e) => {
                              handleChangeLandDetailsInput(
                                landIndex,
                                e.target.name,
                                e.target.value
                              );
                            }}
                            required
                            disabled
                            error={landDetailsError[landIndex]["vigha"]}
                            placeholder="Enter Vigha"
                            id="vigha"
                            size="small"
                            variant="standard"
                            fullWidth
                          />
                        </Col>

                        <Col lg={3} md={3} sm={12}>
                          <TextField
                            value={landItem?.regVigha}
                            name="regVigha"
                            label="Registered Vigha"
                            onChange={(e) => {
                              handleChangeLandDetailsInput(
                                landIndex,
                                e.target.name,
                                e.target.value
                              );
                            }}
                            required
                            error={landDetailsError[landIndex]["regVigha"]}
                            size="small"
                            variant="standard"
                            fullWidth
                          />
                        </Col>
                        <Col lg={3} md={3} sm={12}>
                          <TextField
                            value={landItem?.registeredLand}
                            name="registeredLand"
                            label="Total Registered Land (In Sqm)"
                            onChange={(e) => {
                              handleChangeLandDetailsInput(
                                landIndex,
                                e.target.name,
                                e.target.value
                              );
                            }}
                            error={
                              landDetailsError[landIndex]["registeredLand"]
                            }
                            size="small"
                            variant="standard"
                            fullWidth
                            disabled
                          />
                        </Col>
                      </Row>
                    </div>
                    {checkAvailabilty[landIndex]?.check && (
                      <>
                        {/*  Ownership Status */}
                        <div
                          style={{}}
                          className={`mt-2 ${
                            isTablet
                              ? ""
                              : "d-flex justify-content-start align-items-center"
                          }`}
                        >
                          <Row>
                            <Col>
                              <FormControlLabel
                                value="start"
                                style={{ marginLeft: "unset" }}
                                control={
                                  <Checkbox
                                    checked={landItem?.ownerShipStatus}
                                    name="ownerShipStatus"
                                    onChange={(e) =>
                                      handleChangeLandDetailsInput(
                                        landIndex,
                                        e.target.name,
                                        e.target.checked
                                      )
                                    }
                                    color="success"
                                  />
                                }
                                label="Ownership Status (Is Rented?) "
                                labelPlacement="start"
                              />
                            </Col>
                          </Row>
                        </div>

                        {/* LandOwnerName/ FarmerName  and Address  */}
                        {/* <div className="pb-0">
                          <Row className=" g-2 mt-2">
                            <Col lg={4} md={12} sm={12}>
                              <TextField
                                value={
                                  !landItem?.ownerShipStatus
                                    ? landItem?.landOwnerName
                                    : landItem?.guardianName
                                }
                                name={
                                  !landItem?.ownerShipStatus
                                    ? "landOwnerName"
                                    : "guardianName"
                                }
                                label={`${
                                  !landItem?.ownerShipStatus
                                    ? "Land Owner Name"
                                    : "Farmer Name"
                                }`}
                                onChange={(e) => {
                                  handleChangeLandDetailsInput(
                                    landIndex,
                                    e.target.name,
                                    e.target.value
                                  );
                                }}
                                required
                                size="small"
                                variant="standard"
                                fullWidth
                              />
                            </Col>
                            <Col lg={8} md={12} sm={12}>
                              <TextField
                                value={landItem?.address}
                                name="address"
                                label="Address"
                                onChange={(e) => {
                                  handleChangeLandDetailsInput(
                                    landIndex,
                                    e.target.name,
                                    e.target.value
                                  );
                                }}
                                error={landDetailsError[landIndex]["address"]}
                                rows={2}
                                size="small"
                                variant="standard"
                                fullWidth
                              />
                            </Col>
                          </Row>
                        </div> */}
                        {/* Farmer State  and Farmer Dist  */}
                        {/* <div>
                          <Row className=" g-2 mt-2">
                            <Col lg={2} md={2} sm={12}>
                              <TextField
                                value={landItem?.guardianPincode}
                                name="guardianPincode"
                                label="Pincode"
                                onChange={(e) => {
                                  handleChangeLandDetailsInput(
                                    landIndex,
                                    e.target.name,
                                    e.target.value
                                  );
                                }}
                                error={
                                  landDetailsError[landIndex]["guardianPincode"]
                                }
                                size="small"
                                variant="standard"
                                fullWidth
                              />
                            </Col>
                            <Col lg={2} md={2} sm={12}>
                              <Autocomplete
                                id="guardianVillage"
                                name="guardianVillage"
                                size="small"
                                options={villageArray}
                                value={
                                  selectedGuardianDropdownValues.guardianVillage
                                }
                                onChange={(e, opt) => {
                                  const optValues = {
                                    guardianVillage: opt,
                                    guardianTaluka: talukaArray.find(
                                      (x) => x.id === opt?.talukaId_id
                                    ),
                                    guardianDist: districtArray.find(
                                      (x) => x.id === opt?.districtId_id
                                    ),
                                    guardianState: stateArray.find(
                                      (x) => x.id === opt?.stateId_id
                                    ),
                                    guardianCountry: countryArray.find(
                                      (x) => x.id === opt?.countryId_id
                                    )
                                  };
                                  setSelectedGuardianDropdownValues({
                                    ...optValues
                                  });
                                  const a = [...landDetails];
                                  a[landIndex]["guardianVillage"] = opt;
                                  a[landIndex]["guardianTaluka"] =
                                    optValues?.guardianTaluka;
                                  a[landIndex]["guardianDist"] =
                                    optValues?.guardianDist;
                                  a[landIndex]["guardianState"] =
                                    optValues?.guardianState;
                                  a[landIndex]["guardianCountry"] =
                                    optValues?.guardianCountry;
                                  setInputData({
                                    ...inputData,
                                    ...optValues
                                  });
                                  setLandDetails(a);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label="Village"
                                    size="small"
                                    error={
                                      landDetailsError[landIndex][
                                        "guardianVillage"
                                      ]
                                    }
                                  />
                                )}
                              />
                            </Col>
                            <Col lg={2} md={2} sm={12}>
                              <Autocomplete
                                id="guardianTaluka"
                                name="guardianTaluka"
                                size="small"
                                options={talukaArray}
                                value={
                                  selectedGuardianDropdownValues.guardianTaluka
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label="Taluka"
                                    size="small"
                                    error={
                                      landDetailsError[landIndex][
                                        "guardianTaluka"
                                      ]
                                    }
                                  />
                                )}
                              />
                            </Col>
                            <Col lg={2} md={2} sm={12}>
                              <Autocomplete
                                id="guardianDist"
                                name="guardianDist"
                                size="small"
                                options={districtArray}
                                value={
                                  selectedGuardianDropdownValues.guardianDist
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label="District"
                                    size="small"
                                    error={
                                      landDetailsError[landIndex][
                                        "guardianDist"
                                      ]
                                    }
                                  />
                                )}
                              />
                            </Col>
                            <Col lg={2} md={2} sm={12}>
                              <Autocomplete
                                id="guardianState"
                                name="guardianState"
                                size="small"
                                options={stateArray}
                                value={
                                  selectedGuardianDropdownValues.guardianState
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label="State"
                                    size="small"
                                    error={
                                      landDetailsError[landIndex][
                                        "guardianState"
                                      ]
                                    }
                                  />
                                )}
                              />
                            </Col>
                            <Col lg={2} md={2} sm={12}>
                              <Autocomplete
                                id="guardianCountry"
                                name="guardianCountry"
                                size="small"
                                options={countryArray}
                                value={
                                  selectedGuardianDropdownValues.guardianCountry
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label="Country"
                                    size="small"
                                    error={
                                      landDetailsError[landIndex][
                                        "guardianCountry"
                                      ]
                                    }
                                  />
                                )}
                              />
                            </Col>
                          </Row>
                        </div> */}

                        {/* Upload Document  */}
                        <div style={{}}>
                          <Row className=" g-2 mt-2">
                            {/* <Col lg={4} md={12} sm={12} className="d-flex ">
                              <LandDocumentComponent
                                index={landIndex}
                                landDetails={landDetails}
                                landDetailsError={landDetailsError}
                                setLandDetailsError={setLandDetailsError}
                                setLandDetails={setLandDetails}
                                setLoading={setLoading}
                              />
                            </Col> */}

                            <Col lg={4} md={12} sm={12} className="d-flex ">
                              {landItem?.ownerShipStatus && (
                                <>
                                  <AgreementComponent
                                    index={landIndex}
                                    landDetails={landDetails}
                                    landDetailsError={landDetailsError}
                                    setLandDetailsError={setLandDetailsError}
                                    setLandDetails={setLandDetails}
                                    setLoading={setLoading}
                                  />
                                </>
                              )}
                            </Col>
                            <Col lg={4} md={12} sm={12} className="d-flex ">
                              {landItem?.ownerShipStatus && (
                                <>
                                  <TenetComponent
                                    index={landIndex}
                                    landDetails={landDetails}
                                    landDetailsError={landDetailsError}
                                    setLandDetailsError={setLandDetailsError}
                                    setLandDetails={setLandDetails}
                                    setLoading={setLoading}
                                  />
                                </>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}{" "}
                  </AccordionBody>
                </AccordionItem>
              </fieldset>
            </Accordion>
          ))}

          <div className="d-flex justify-content-end">
            {!checkAvailabilty?.find((er) => er?.check === false) && (
              <MuiButton
                type="button"
                className="custom-btn-page-layout"
                onClick={(e) => {
                  handleNextBtn("step5");
                }}
                endIcon={<NavigateNext />}
              >
                Next
              </MuiButton>
            )}
          </div>
        </div>
      )}

      {step === "step6" && !updateState && (
        <div className="m-4">
          <Row className={`mt-2 g-3`}>
            <Col lg={3} md={3} sm={12}>
              <Autocomplete
                name="variety"
                size="small"
                options={product_group}
                value={selectedGroup}
                onChange={(e, opt) => {
                  setSelectedGroup(opt);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Variety"
                    size="small"
                    required
                    error={singleFarmingDetailsError?.product}
                  />
                )}
              />
            </Col>
            <Col lg={3} md={3} sm={12}>
              <Autocomplete
                name="product"
                size="small"
                options={getCheckOptions(
                  productOptions.filter(
                    (x) => x.value.groupId === selectedGroup?.value || 0
                  )
                )}
                value={singleFarmingDetails?.product}
                onChange={(e, opt) => {
                  handleOnChangeFarmingDetails("product", opt);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Sub Variety"
                    size="small"
                    required
                    error={singleFarmingDetailsError?.product}
                  />
                )}
              />
            </Col>
            {/* <Col lg={3} md={3} sm={12}>
              <TextField
                value={singleFarmingDetails?.product?.value?.categoryName || ""}
                label="Sub Variety"
                disabled
                size="small"
                variant="standard"
                fullWidth
              />
            </Col> */}
            <Col lg={2} md={2} sm={12}>
              <TextField
                value={singleFarmingDetails?.vigha}
                name="vigha"
                label="Vigha"
                onChange={(e) => {
                  handleOnChangeFarmingDetails(e.target.name, e.target.value);
                }}
                error={singleFarmingDetailsError?.vigha}
                size="small"
                variant="standard"
                fullWidth
                required
              />
            </Col>
            <Col lg={2} md={2} sm={12}>
              <TextField
                value={singleFarmingDetails?.totalVigha}
                label="Total Vigha"
                size="small"
                variant="standard"
                fullWidth
                disabled
              />
            </Col>
            <Col lg={2} md={2} sm={12}>
              <TextField
                value={
                  farmingDetails.length <= 0
                    ? singleFarmingDetails?.totalVigha
                    : remainingLandinVigha
                }
                label="Remaing Land"
                size="small"
                variant="standard"
                fullWidth
                disabled
              />
            </Col>
            <Col lg={2} md={2} sm={12}>
              <MuiButton
                type="button"
                className="custom-btn-page-layout  custom-btn-success text-white"
                onClick={(e) => handleAddFarmingDetails()}
                startIcon={!editFarmingDetails ? <Add /> : <Edit />}
              >
                {editFarmingDetails ? "Update" : "Add"}
              </MuiButton>
            </Col>
          </Row>

          {/* Table View */}
          <div
            className="d-grid"
            style={{ margin: "20px 0", border: "1px solid #DEE2E6" }}
          >
            <TableContainer
              columns={Farmingcolumn}
              data={farmingDetails || []}
              isGlobalFilter={false}
              isAddUserList={false}
              tableClass={"table-sm"}
              divClass={"table-responsive"}
            />
          </div>

          <div className="d-flex justify-content-end">
            <MuiButton
              type="button"
              className="custom-btn-page-layout btn text-white mx-3"
              onClick={() => setStep("step3")}
              startIcon={<NavigateBefore />}
            >
              Previous
            </MuiButton>

            <MuiButton
              type="button"
              className="custom-btn-page-layout btn text-white mx-3"
              disabled={submitloading}
              onClick={(e) => {
                if (remainingLandinVigha > 0) {
                  setShowConfirmSubmitModal(true);
                } else {
                  handleSubmit(e);
                }
              }}
              startIcon={
                submitloading ? (
                  <CircularProgress size={15} color="warning" />
                ) : (
                  <Save />
                )
              }
            >
              {`Submit`}
            </MuiButton>
          </div>
        </div>
      )}

      {updateState && aadharCardNo && (
        <FetchFarmer
          updateState={updateState}
          fetchDetails={fetchDetails}
          setUpdateState={setUpdateState}
          aadharCardNo={aadharCardNo}
        />
      )}

      {showCapturePopup && (
        <ImageCapturePopup
          onClose={() => setShowCapturePopup(false)}
          onCapture={handleCapture}
          showCapturePopup={showCapturePopup}
        />
      )}

      <CheckLandAvalibility
        editModal={availibilityModal}
        landDetailsError={landDetailsError}
        setLandDetailsError={setLandDetailsError}
        checkAvailabilty={checkAvailabilty}
        setCheckAvailabilty={setCheckAvailabilty}
        onCloseModal={onCloseAvailibilityModal}
        availibilityDataIndex={availibilityDataIndex}
        availibilityData={availibilityData}
        landDetails={landDetails}
        setLandDetails={setLandDetails}
      />

      <ModalContainer
        modalState={showConfirmSubmitModal}
        onCloseModal={() => setShowConfirmSubmitModal(false)}
        loading={loading}
        title={"Confirm Submit"}
        onClick={handleSubmit}
      >
        <Row className="m-2">
          <Col lg={12} md={12} sm={12}>
            {`${remainingLandinVigha} is still available for registration.`}
          </Col>
          <Col>{`Are you sure you want to submit with registering remaining land?`}</Col>
        </Row>
      </ModalContainer>
    </PageLayout>
  );
};

export default AddFarmer;

const LandDocumentComponent = ({
  index,
  landDetails,
  landDetailsError,
  setLandDetailsError,
  setLandDetails,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.layout);

  const landDocumentImage = useRef(null);
  const handlelandDocumentImageUploadBtn = () =>
    landDocumentImage.current.click();

  const handleImageLandDetailsChange = async (name, value) => {
    if (value) {
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!allowedTypes.includes(value.type)) {
        toast.error("Invalid file type. Please select a JPEG, PNG.");
      } else if (value.size > maxSize) {
        toast.error("File size exceeds the limit of 5MB.");
      } else {
        // if (name === "landDocument") {
        //   let arr5 = [...landDetailsError];

        //   arr5[index]["landDocument"] = false;

        //   setLandDetailsError(arr5);
        // }
        // else if (name === "tenantDocument") {
        //   let arr5 = [...landDetailsError];

        //   arr5[index]["tenantDocument"] = false;
        //   setLandDetailsError(arr5);
        // }
        // else if (name === "agreementDocument") {
        //   let arr5 = [...landDetailsError];

        //   arr5[index]["agreementDocument"] = false;
        //   setLandDetailsError(arr5);
        // }
        const formData = new FormData();
        formData.append("media", value);

        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/uploadmedia`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          let arr = [...landDetails];

          arr[index][name] = { url: _data.uploadedURL, name: _data?.filename };

          setLandDetails(arr);
        } else {
          toast.error(_data?.error);
        }

        return { url: "https://httpbin.org/post" };
      }
    }
  };

  return (
    <div>
      <MuiButton
        type="button"
        onClick={handlelandDocumentImageUploadBtn}
        className={`custom-btn-page-layout custom-btn-upload ${
          landDetailsError[index]["landDocument"] ? "border border-danger" : ""
        }`}
        startIcon={<CloudUpload />}
      >
        Upload
      </MuiButton>

      <input
        type="file"
        style={{ display: "none" }}
        name="landDocument"
        ref={landDocumentImage}
        accept="image/png, image/jpeg , image/jpg,application/pdf "
        onChange={(e) =>
          handleImageLandDetailsChange(e.target.name, e.target.files[0])
        }
      />

      <span className="mx-3" style={{ fontWeight: 500, fontSize: 10 }}>
        {landDetails[index]?.landDocument
          ? landDetails[index]?.landDocument?.name
          : " Upload 7/12 Land Document. (PDF/image)"}
      </span>
    </div>
  );
};

const AgreementComponent = ({
  index,
  landDetails,
  landDetailsError,
  setLandDetailsError,
  setLandDetails,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.layout);

  const agreementImage = useRef(null);
  const handleagreementImageUploadBtn = () => agreementImage.current.click();

  const handleImageLandDetailsChange = async (name, value) => {
    if (value) {
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!allowedTypes.includes(value.type)) {
        toast.error("Invalid file type. Please select a JPEG, PNG.");
      } else if (value.size > maxSize) {
        toast.error("File size exceeds the limit of 5MB.");
      } else {
        if (name === "landDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["landDocument"] = false;

          setLandDetailsError(arr5);
        } else if (name === "tenantDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["tenantDocument"] = false;
          setLandDetailsError(arr5);
        } else if (name === "agreementDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["agreementDocument"] = false;
          setLandDetailsError(arr5);
        }
        const formData = new FormData();
        formData.append("media", value);

        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/uploadmedia`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          let arr = [...landDetails];

          arr[index][name] = { url: _data.uploadedURL, name: _data?.filename };

          setLandDetails(arr);
        } else {
          toast.error(_data?.error);
        }

        return { url: "https://httpbin.org/post" };
      }
    }
  };

  return (
    <div>
      <MuiButton
        type="button"
        onClick={handleagreementImageUploadBtn}
        className={`custom-btn-page-layout custom-btn-upload ${
          landDetailsError[index]["agreementDocument"]
            ? "border border-danger"
            : ""
        }`}
        startIcon={<CloudUpload />}
      >
        Upload
      </MuiButton>

      <input
        style={{ display: "none" }}
        type="file"
        accept="image/png, image/jpeg , image/jpg,application/pdf "
        name="agreementDocument"
        ref={agreementImage}
        onChange={(e) =>
          handleImageLandDetailsChange(e.target.name, e.target.files[0])
        }
      />

      <span className="mx-3" style={{ fontWeight: 500, fontSize: 10 }}>
        {landDetails[index]?.agreementDocument
          ? landDetails[index]?.agreementDocument?.name
          : " Option to upload copy of 7/12 (PDF/image)"}
      </span>
    </div>
  );
};

const TenetComponent = ({
  index,
  landDetails,
  landDetailsError,
  setLandDetailsError,
  setLandDetails,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.layout);

  const tenetImage = useRef(null);
  const handletenetImageUploadBtn = () => tenetImage.current.click();

  const handleImageLandDetailsChange = async (name, value) => {
    if (value) {
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!allowedTypes.includes(value.type)) {
        toast.error("Invalid file type. Please select a JPEG, PNG.");
      } else if (value.size > maxSize) {
        toast.error("File size exceeds the limit of 5MB.");
      } else {
        if (name === "landDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["landDocument"] = false;

          setLandDetailsError(arr5);
        } else if (name === "tenantDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["tenantDocument"] = false;
          setLandDetailsError(arr5);
        } else if (name === "agreementDocument") {
          let arr5 = [...landDetailsError];

          arr5[index]["agreementDocument"] = false;
          setLandDetailsError(arr5);
        }
        const formData = new FormData();
        formData.append("media", value);

        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/uploadmedia`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          let arr = [...landDetails];

          arr[index][name] = { url: _data.uploadedURL, name: _data?.filename };
          setLandDetails(arr);
        } else {
          toast.error(_data?.error);
        }

        return { url: "https://httpbin.org/post" };
      }
    }
  };

  return (
    <div>
      <MuiButton
        type="button"
        onClick={handletenetImageUploadBtn}
        className={`custom-btn-page-layout custom-btn-upload ${
          landDetailsError[index]["tenantDocument"]
            ? "border border-danger"
            : ""
        }`}
        startIcon={<CloudUpload />}
      >
        Upload
      </MuiButton>

      <input
        style={{ display: "none" }}
        type="file"
        accept="image/png, image/jpeg , image/jpg,application/pdf "
        name="tenantDocument"
        ref={tenetImage}
        onChange={(e) =>
          handleImageLandDetailsChange(e.target.name, e.target.files[0])
        }
      />

      <span className="mx-3" style={{ fontWeight: 500, fontSize: 10 }}>
        {landDetails[index]?.tenantDocument
          ? landDetails[index]?.tenantDocument?.name
          : "Photo of Tenant"}
      </span>
    </div>
  );
};
