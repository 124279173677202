import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageLayout from "../../../Component/PageLayout";

import CustomAutoComplete from "../../../Component/CustomAutoComplete";
import { Col, Input, InputGroup, InputGroupText, Row } from "reactstrap";
import { logOutRequest } from "../../../store/reducers/layoutReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import StateOption from "../../../hooks/StateOptions";
import DistrictOption from "../../../hooks/DistrictOption";
import TalukaOption from "../../../hooks/TalukaOption";
import VillageOption from "../../../hooks/VillageOption";
import { ClipLoader } from "react-spinners";
import TableContainer from "../../../Component/TableContainer";
import CustomPagination from "../../../Component/CustomPagination";
import DeleteModal from "../../../Component/DeleteModal";

const FarmerWiseAllocation = () => {
  const { permissions, token, loginDetails } = useSelector(
    (state) => state.layout
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let permissionsObj = {};
  // eslint-disable-next-line
  permissions?.filter((er) => {
    if (er?.ModuleData?.length > 0) {
      // eslint-disable-next-line
      let filtererdDarta = er?.ModuleData?.filter((ed) => {
        if (ed?.subModuleData?.length > 0) {
          // eslint-disable-next-line
          ed?.subModuleData?.map((qw) => {
            if (qw?.path === "/farmerallocationfarmerwise") {
              permissionsObj = qw;
            }
          });
        }
      });
      return filtererdDarta;
    }
  });

  let initialState = {};

  if (!permissionsObj?.edit && !permissionsObj?.delete) {
    initialState.hiddenColumns = ["Action"];
  }

  const { getStateOption } = StateOption();
  const { getDistrictOption } = DistrictOption();
  const { getTalukaOption } = TalukaOption();
  const { getVillageOption } = VillageOption();

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [unitOptions, setUnitOptions] = useState([]);
  const [villageMappingList, setVillageMappingList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(5); // default limit
  const [offset, setOffset] = useState(0); // default offset
  const [counter, setCounter] = useState(0); // default offset
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const [inputData, setInputData] = useState({
    unitId: "",
    stateId: "",
    distId: "",
    talukaId: "",
    villages: [],
  });
  const [inputDataError, setInputDataError] = useState({
    unitId: false,
    stateId: false,
    distId: false,
    talukaId: false,
    villageId: false,
  });

  const getUnitOptionsListAPi = useCallback(async () => {
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("companyId", loginDetails?.companyId);

      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}company/unitsbycompany`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];
        // eslint-disable-next-line
        _data?.unitsData[0]?.unitdetails?.map((er) => {
          arr.push({ label: er?.unitName, value: er.id });
        });
        setUnitOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, []);

  const [stateArray, setStateArray] = useState([]);
  const [districtArray, setDistrictArray] = useState([]);
  const [talukaArray, setTalukaArray] = useState([]);
  const [villageArray, setVillageArray] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputData.unitId) {
      toast.error("Loaction Name is Required!");
      setInputDataError({ ...inputDataError, unitId: true });
    } else if (!inputData.stateId) {
      toast.error("State is Required!");
      setInputDataError({ ...inputDataError, stateId: true });
    } else if (!inputData.distId) {
      toast.error("District is Required!");
      setInputDataError({ ...inputDataError, distId: true });
    } else if (!inputData.talukaId) {
      toast.error("Taluka is Required!");
      setInputDataError({ ...inputDataError, talukaId: true });
    } else if (inputData.villages?.length === 0) {
      toast.error("Village is Required!");
      setInputDataError({ ...inputDataError, villageId: true });
    } else {
      setSubmitLoading(true);
      let formData = new FormData();
      formData.append("companyUnitId", inputData.unitId);
      formData.append("stateId", inputData.stateId);
      formData.append("districtId", inputData.distId);
      formData.append("talukaId", inputData.talukaId);
      formData.append("villages", JSON.stringify(inputData.villages));

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}company/addvillagemapping`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setSubmitLoading(false);
          setInputData({
            unitId: "",
            stateId: "",
            distId: "",
            talukaId: "",
            villages: [],
          });
          getVillageMappingListAPi(limit, offset);
          setDistrictArray([]);
          setTalukaArray([]);
          setVillageArray([]);
          setCounter(counter + 1);
        } else {
          setSubmitLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setSubmitLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  const getVillageMappingListAPi = useCallback(async (limit, offset) => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}company/listmappedvillage?limit=${limit}&offset=${offset}&companyId=${loginDetails?.companyId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        setVillageMappingList(_data?.mappedVillageList);
        setTotalCount(_data?.paginationDetails?.count);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUnitOptionsListAPi();
    getStateOption("18").then((res) => {
      setStateArray(res);
    });
    getVillageMappingListAPi(limit, offset);
    // eslint-disable-next-line
  }, [getUnitOptionsListAPi, getVillageMappingListAPi, getStateOption]);

  // handleDelete Btn
  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    let formData = new FormData();
    formData.append("id", deleteId);
    formData.append("isDelete", "Yes");

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}company/deletevillagemapping`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        getVillageMappingListAPi(limit, offset);
        setDeleteModal(false);
        toast?.success(_data?.message);
        setDeleteLoading(false);
      } else {
        setDeleteLoading(false);
        setDeleteModal(false);

        toast.error(_data?.message);
      }
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
    }
  };

  const column = useMemo(
    () => [
      {
        Header: "Unit Name",
        accessor: "unit.unitName",
        filterable: false,
      },
      {
        Header: "State Name",
        accessor: "state.stateName",
        filterable: false,
      },
      {
        Header: "District Name",
        accessor: "district.districtName",
        filterable: false,
      },
      {
        Header: "Taluka Name",
        accessor: "taluka.talukaName",
        filterable: false,
      },
      {
        Header: "Village Name",
        accessor: "village.villageName",
        filterable: false,
      },

      {
        id: "Action",
        Header: () => (
          <span
            className="d-flex justify-content-center"
            style={{ textAlign: "center" }}
          >
            Action
          </span>
        ),
        Cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <div className="hstack gap-2">
                {/* Edit Button */}
                {/* {permissionsObj?.edit && (
                    <button
                      className="border-0"
                      style={{ background: "transparent" }}
                      onClick={() => {
                        setEditModal(true);
                        setSingleData(cellProps.row.original);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_32_318)">
                          <path
                            d="M6.87549 2.5H2.50049C2.16897 2.5 1.85103 2.6317 1.6166 2.86612C1.38218 3.10054 1.25049 3.41848 1.25049 3.75V12.5C1.25049 12.8315 1.38218 13.1495 1.6166 13.3839C1.85103 13.6183 2.16897 13.75 2.50049 13.75H11.2505C11.582 13.75 11.9 13.6183 12.1344 13.3839C12.3688 13.1495 12.5005 12.8315 12.5005 12.5V8.125"
                            stroke="#3E7531"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.563 1.56249C11.8116 1.31385 12.1489 1.17416 12.5005 1.17416C12.8521 1.17416 13.1893 1.31385 13.438 1.56249C13.6866 1.81113 13.8263 2.14836 13.8263 2.49999C13.8263 2.85162 13.6866 3.18885 13.438 3.43749L7.50049 9.37499L5.00049 9.99999L5.62549 7.49999L11.563 1.56249Z"
                            stroke="#3E7531"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_32_318">
                            <rect
                              width="15"
                              height="15"
                              fill="white"
                              transform="translate(0.000488281)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  )} */}

                {/* Delete Button */}
                {/* {permissionsObj?.delete && ( */}
                <button
                  className="border-0"
                  style={{ background: "transparent" }}
                  onClick={() => {
                    setDeleteModal(true);
                    setDeleteId(cellProps.row.original.id);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_32_330)">
                      <path
                        d="M6.63507 -0.0178833C5.50545 -0.0178833 4.59795 0.92077 4.59795 2.05558V2.30769H2.30814C1.99026 2.30769 1.73122 2.56673 1.73122 2.88462V3.46154H1.1543V4.61539H2.30814V13.2692C2.30814 14.2183 3.08987 15 4.03891 15H10.962C11.911 15 12.6928 14.2183 12.6928 13.2692V4.61539H13.8466V3.46154H13.2697V2.88462C13.2697 2.56673 13.0106 2.30769 12.6928 2.30769H10.403V2.055C10.403 0.920771 9.49545 -0.0178833 8.36584 -0.0178833H6.63507ZM6.63507 1.17173H8.36584C8.82968 1.17173 9.21334 1.55019 9.21334 2.055V2.30769H5.78699V2.055C5.78699 1.55019 6.17064 1.17231 6.63449 1.17231L6.63507 1.17173ZM3.46199 4.61539H6.41872C6.49026 4.62289 6.56122 4.63327 6.63507 4.63327H8.36584C8.43968 4.63327 8.51007 4.62289 8.58218 4.61539H11.5389V13.2692C11.5389 13.594 11.2868 13.8462 10.962 13.8462H4.03891C3.7141 13.8462 3.46199 13.594 3.46199 13.2692V4.61539ZM4.61584 5.76923V12.6923H5.76968V5.76923H4.61584ZM6.92353 5.76923V12.6923H8.07737V5.76923H6.92353ZM9.23122 5.76923V12.6923H10.3851V5.76923H9.23122Z"
                        fill="#EC0505"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_32_330">
                        <rect
                          width="15"
                          height="15"
                          fill="white"
                          transform="translate(0.000488281)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                {/* )} */}
              </div>
            </div>
          );
        },
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  const onChangeSearch = async (value) => {
    setLoading(true);
    setSearchInput(value);
    let formData = new FormData();

    formData.append("departmentName", value);

    try {
      const apiCall = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }company/departmentfilter?limit=${5}&offset=${0}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        setVillageMappingList(_data?.filteredDepartmentList);
        setTotalCount(_data?.filteredDepartmentList?.count);
      } else {
        setLoading(false);
        toast?.error(_data?.message);
      }
    } catch (error) {
      setLoading(false);
      toast?.error(error);
      console.log(error);
    }
  };

  return (
    <PageLayout
      title={"Farmers Management- Farmer Allocation - Farmer Wise Allocation"}
      loading={loading}
    >
      <div className="" style={{ margin: 40 }}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div>
            <Row className=" g-3 mt-2 ">
              <Col lg={3} md={12} sm={12} className="d-flex">
                <CustomAutoComplete
                  name="unitId"
                  fullWidth
                  key={`unit-${unitOptions?.length}-${counter}`}
                  label="Field Technician Name *"
                  value={
                    unitOptions.map(
                      (product) => product?.label === inputData?.unitId
                    )?.label
                  }
                  options={unitOptions.map((product) => product.label)}
                  size="small"
                  getOptionLabel={(option) => option}
                  onChange={(event, option) => {
                    let selectedData = unitOptions?.find(
                      (er) => er?.label === option
                    );
                    setInputData({ ...inputData, unitId: selectedData?.value });
                    setInputDataError({ ...inputDataError, unitId: false });
                  }}
                  error={inputDataError?.unitId}
                  renderOption={(props, option) => {
                    const { id, label } = unitOptions.filter(
                      (product) => product.label === option
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label}
                      </li>
                    );
                  }}
                />
              </Col>
              <Col lg={3} md={12} sm={12} className="d-flex">
                <CustomAutoComplete
                  name="unitId"
                  fullWidth
                  key={`unit-${unitOptions?.length}-${counter}`}
                  label="Loaction Name *"
                  value={
                    unitOptions.map(
                      (product) => product?.label === inputData?.unitId
                    )?.label
                  }
                  options={unitOptions.map((product) => product.label)}
                  size="small"
                  getOptionLabel={(option) => option}
                  onChange={(event, option) => {
                    let selectedData = unitOptions?.find(
                      (er) => er?.label === option
                    );
                    setInputData({ ...inputData, unitId: selectedData?.value });
                    setInputDataError({ ...inputDataError, unitId: false });
                  }}
                  error={inputDataError?.unitId}
                  renderOption={(props, option) => {
                    const { id, label } = unitOptions.filter(
                      (product) => product.label === option
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label}
                      </li>
                    );
                  }}
                />
              </Col>
              <Col lg={3} md={12} sm={12} className="d-flex">
                <CustomAutoComplete
                  name="stateId"
                  fullWidth
                  label="State *"
                  key={`state-${stateArray?.length}-${counter}`}
                  value={
                    stateArray.map(
                      (product) => product?.label === inputData?.stateId
                    )?.label
                  }
                  options={stateArray.map((product) => product.label)}
                  size="small"
                  getOptionLabel={(option) => option}
                  onChange={(event, option) => {
                    let selectedData = stateArray?.find(
                      (er) => er?.label === option
                    );
                    setInputData({
                      ...inputData,
                      stateId: selectedData?.value,
                    });
                    getDistrictOption(selectedData?.value).then((res) => {
                      setDistrictArray(res);
                    });
                    setInputDataError({ ...inputDataError, stateId: false });
                  }}
                  error={inputDataError?.stateId}
                  renderOption={(props, option) => {
                    const { id, label } = stateArray.filter(
                      (product) => product.label === option
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label}
                      </li>
                    );
                  }}
                />
              </Col>
              <Col lg={3} md={12} sm={12} className="d-flex">
                <CustomAutoComplete
                  name="distId"
                  fullWidth
                  key={`dist-${districtArray?.length}-${counter}`}
                  label="District *"
                  value={
                    districtArray.map(
                      (product) => product?.label === inputData?.distId
                    )?.label
                  }
                  options={districtArray.map((product) => product.label)}
                  size="small"
                  getOptionLabel={(option) => option}
                  onChange={(event, option) => {
                    let selectedData = districtArray?.find(
                      (er) => er?.label === option
                    );
                    setInputData({ ...inputData, distId: selectedData?.value });
                    getTalukaOption(selectedData?.value).then((res) => {
                      setTalukaArray(res);
                    });
                    setInputDataError({ ...inputDataError, distId: false });
                  }}
                  error={inputDataError?.distId}
                  renderOption={(props, option) => {
                    const { id, label } = districtArray.filter(
                      (product) => product.label === option
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label}
                      </li>
                    );
                  }}
                />
              </Col>
            </Row>
          </div>
          <div>
            <Row className=" g-3 mt-2 ">
              <Col lg={3} md={12} sm={12} className="d-flex">
                <CustomAutoComplete
                  name="talukaId"
                  fullWidth
                  label="Taluka *"
                  key={`taluka-${talukaArray?.length}-${counter}`}
                  value={
                    talukaArray.map(
                      (product) => product?.label === inputData?.talukaId
                    )?.label
                  }
                  options={talukaArray.map((product) => product.label)}
                  size="small"
                  getOptionLabel={(option) => option}
                  onChange={(event, option) => {
                    let selectedData = talukaArray?.find(
                      (er) => er?.label === option
                    );
                    setInputData({
                      ...inputData,
                      talukaId: selectedData?.value,
                    });

                    getVillageOption(selectedData?.value).then((res) => {
                      setVillageArray(res);
                    });

                    setInputDataError({ ...inputDataError, talukaId: false });
                  }}
                  error={inputDataError?.talukaId}
                  renderOption={(props, option) => {
                    const { id, label } = talukaArray.filter(
                      (product) => product.label === option
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label}
                      </li>
                    );
                  }}
                />
              </Col>

              <Col lg={3} md={12} sm={12} className="d-flex">
                <CustomAutoComplete
                  name="VillageId"
                  fullWidth
                  multiple
                  label="Village *"
                  value={inputData?.villages}
                  options={villageArray}
                  size="small"
                  getOptionLabel={(option) => option?.label}
                  onChange={(event, option) => {
                    console.log("option: ", option);

                    setInputData({ ...inputData, villages: option });
                    setInputDataError({ ...inputDataError, villageId: false });
                  }}
                  error={inputDataError?.villageId}
                  renderOption={(props, option) => {
                    const { id, label } = villageArray?.filter(
                      (product) => product.label === option?.label
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label}
                      </li>
                    );
                  }}
                />
              </Col>
              <Col lg={3} md={12} sm={12} className="d-flex">
                <CustomAutoComplete
                  name="VillageId"
                  fullWidth
                  multiple
                  label="Alphabate *"
                  value={inputData?.villages}
                  options={villageArray}
                  size="small"
                  getOptionLabel={(option) => option?.label}
                  onChange={(event, option) => {
                    console.log("option: ", option);

                    setInputData({ ...inputData, villages: option });
                    setInputDataError({ ...inputDataError, villageId: false });
                  }}
                  error={inputDataError?.villageId}
                  renderOption={(props, option) => {
                    const { id, label } = villageArray?.filter(
                      (product) => product.label === option?.label
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={id}>
                        {label}
                      </li>
                    );
                  }}
                />
              </Col>
              <Col lg={3} md={12} sm={12} className="d-flex">
                <button
                  type="submit"
                  disabled={submitLoading}
                  className="custom-btn-page-layout btn text-white"
                >
                  {submitLoading ? (
                    <ClipLoader color="#75D100" size={25} />
                  ) : (
                    "Submit"
                  )}
                </button>
              </Col>
            </Row>
          </div>
        </form>

        <div className="mt-5">
          <Col md={12}>
            <div className="d-flex justify-content-end" style={{ margin: 20 }}>
              <InputGroup className="search-inp">
                <Input
                  value={searchInput}
                  onChange={(e) => {
                    onChangeSearch(e.target.value);
                  }}
                  placeholder="Search For Village Name"
                />
                <InputGroupText>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M15.1987 13.8333L10.9037 9.53833C11.6977 8.33158 12.0157 6.87314 11.7962 5.44537C11.5766 4.01761 10.8351 2.72208 9.71524 1.80963C8.59536 0.897181 7.17674 0.432709 5.73406 0.506144C4.29138 0.579579 2.92726 1.1857 1.90581 2.20715C0.884365 3.22859 0.278248 4.59271 0.204813 6.03539C0.131379 7.47807 0.595851 8.89669 1.5083 10.0166C2.42075 11.1365 3.71628 11.878 5.14404 12.0975C6.57181 12.317 8.03025 11.999 9.237 11.205L13.532 15.5L15.1987 13.8333ZM1.86534 6.33333C1.86534 4.03584 3.7345 2.16667 6.032 2.16667C8.3295 2.16667 10.1987 4.03584 10.1987 6.33333C10.1987 8.63083 8.3295 10.5 6.032 10.5C3.7345 10.5 1.86534 8.63083 1.86534 6.33333Z"
                      fill="#999999"
                    />
                  </svg>
                </InputGroupText>
              </InputGroup>
            </div>
            {loading ? (
              ""
            ) : (
              <div
                className="d-grid"
                style={{ margin: "0 20px", border: "1px solid #DEE2E6" }}
              >
                <TableContainer
                  columns={column}
                  data={villageMappingList || []}
                  isGlobalFilter={false}
                  isAddUserList={false}
                  tableClass={"table-sm"}
                  divClass={"table-responsive"}
                  initialState={initialState}
                />
                <CustomPagination
                  apiCallFunction={getVillageMappingListAPi}
                  rowDetails={villageMappingList}
                  totalCount={totalCount}
                  limit={limit}
                  offset={offset}
                  setLimit={setLimit}
                  setOffset={setOffset}
                />
              </div>
            )}
          </Col>
        </div>

        <DeleteModal
          deleteModal={deleteModal}
          handleDeleteRequest={handleDeleteRequest}
          setDeleteModal={setDeleteModal}
          deleteLoading={deleteLoading}
        />
      </div>
    </PageLayout>
  );
};

export default FarmerWiseAllocation;
