import React, { useCallback, useEffect, useState } from "react";
import { Label, Input, Row, Col, FormGroup } from "reactstrap";
import { toast } from "react-toastify";
import CustomModal from "../../Component/CustomModal";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import useBreakPoints from "../../hooks/useBreakPoints";
import { Autocomplete, TextField } from "@mui/material";
import { textFieldProps } from "../../common/commonControlProps";
const EditDepartment = ({
  editModal,
  onCloseModal,
  onSubmitModal,
  singleData
}) => {
  const [loading, setLoading] = useState(false);
  const { isMobile } = useBreakPoints();
  const [submitLoading, setSubmitLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginDetails, token } = useSelector((state) => state.layout);

  const [unitOptions, setUnitOptions] = useState([]);

  const [inputData, setInputData] = useState({
    depName: singleData?.departmentName,
    unit: JSON.parse(singleData?.units),
    remark: singleData?.remarks
  });

  const [inputDataError, setInputDataError] = useState({
    depName: false,
    unit: false
  });

  // Get Unit Options
  const getUnitOptionsListAPi = useCallback(async (limit, offset) => {
    setLoading(true);
    try {
      let formData = new FormData();

      formData.append("companyId", loginDetails?.companyId);
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}company/unitsbycompany`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: formData
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];
        // eslint-disable-next-line
        _data?.unitsData[0]?.unitdetails?.map((er) => {
          arr.push({ label: er?.unitName, value: er.id });
        });
        setUnitOptions(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUnitOptionsListAPi();
  }, [getUnitOptionsListAPi]);

  const handleChangeInput = (name, value) => {
    if (name === "depName") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, depName: false });
      } else {
        setInputDataError({ ...inputDataError, depName: true });
      }
    } else if (name === "unit") {
      setInputData({ ...inputData, [name]: value });

      if (value?.length > 0) {
        console.log("-------");
        setInputDataError({ ...inputDataError, unit: false });
      } else {
        console.log("-------123");
        setInputDataError({ ...inputDataError, unit: true });
      }
    } else {
      setInputData({ ...inputData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputData.depName) {
      toast.error("Department name is Required!");
      setInputDataError({ ...inputDataError, depName: true });
    } else if (inputData.unit.length <= 0) {
      toast.error("Unit is Required!");
      setInputDataError({ ...inputDataError, unit: true });
    } else {
      setSubmitLoading(true);
      let formData = new FormData();

      formData.append("departmentName", inputData.depName);
      formData.append("units", JSON.stringify(inputData.unit));
      formData.append("remarks", inputData.remark);
      formData.append("id", singleData?.id);

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}company/editdepartment`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`
            },
            body: formData
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setSubmitLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setSubmitLoading(false);

          onSubmitModal();
        } else {
          setSubmitLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setSubmitLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  return (
    <CustomModal
      loading={submitLoading}
      title={"Edit Department"}
      handleSubmit={handleSubmit}
      modalState={editModal}
      onCloseModal={onCloseModal}
      editState={true}
    >
      {loading ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <ClipLoader color="#75D100" size={50} />
        </div>
      ) : (
        <FormGroup>
          <Row className="m-1">
            <Col
              md={12}
              className={`g-3 d-flex ${isMobile ? "flex-column" : ""}`}
            >
              <TextField
                variant="standard"
                size="small"
                label="Department Name"
                fullWidth
                value={inputData?.depName}
                name="depName"
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                error={inputDataError?.depName}
                required
                type="text"
              />
              {/* <Col md={5}>
                <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                  Department Name <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col md={7} className="">
                <Input
                  id="exampleEmail"
                  name="depName"
                  value={inputData?.depName}
                  onChange={(e) => {
                    handleChangeInput(e.target.name, e.target.value);
                  }}
                  className={`cust-input ${
                    inputDataError?.depName ? "border-danger" : ""
                  }`}
                  type="text"
                />
              </Col> */}
            </Col>

            <Col
              md={12}
              className={`g-3 d-flex  ${isMobile ? "flex-column" : ""}`}
            >
              <Autocomplete
                multiple
                name="productid"
                size="small"
                fullWidth
                value={inputData?.unit}
                options={unitOptions}
                onChange={(e, option) => {
                  handleChangeInput("unit", option);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...textFieldProps}
                    label="Unit Address"
                  />
                )}
              />
              {/* <Col md={5}>
                <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                  Unit Address <span className="required_span"> * </span>
                </Label>
              </Col>
              <Col md={7} className="">
                <Select
                  isMulti={true}
                  onChange={(e) => {
                    handleChangeInput("unit", e);
                  }}
                  value={inputData?.unit}
                  options={unitOptions}
                  // menuPlacement="top"
                  name="choices-single-default"
                  id="countryAddCompany"
                  className={`w-100 ${
                    inputDataError?.unit ? "border border-danger" : ""
                  }`}
                ></Select>
              </Col> */}
            </Col>
            <Col
              md={12}
              className={`g-3 d-flex  ${isMobile ? "flex-column" : ""}`}
            >
              <TextField
                variant="standard"
                size="small"
                label="Remarks"
                fullWidth
                value={inputData?.remark}
                name="remark"
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                type="text"
              />
            </Col>
          </Row>
        </FormGroup>
      )}
    </CustomModal>
  );
};

export default EditDepartment;
