import { Col, Form, Row } from "reactstrap";
import PageLayout from "../../Component/PageLayout";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useInvoiceHooks } from "./useInvoiceHooks";
import { textFieldProps } from "../../common/commonControlProps";
import { Link, useNavigate } from "react-router-dom";
import { keys } from "../../config/keys";
import { Button, FormHelperText, TextField, Typography } from "@mui/material";
import VirtualizeAutoComplete from "../../common/VirtualizedAutoComplete";
import { Cancel, Print, Save } from "@mui/icons-material";
import PrintInvoice from "./PrintNGInvoice";
import { useEffect, useState } from "react";
import { useNGInvoiceHooks } from "./useNGInvoiceHooks";

const NGInvoice = () => {
  const navigate = useNavigate();
  const { lists, state, form, actions } = useNGInvoiceHooks();

  const [productpercent, setproductpercent] = useState([]);

  const getType = (type) => {
    if (type === 1) return `(B)`;
    else if (type === 2) return `(D)`;
    else if (type === 3) return `(G)`;
    else return ``;
  };

  useEffect(() => {
    const d = [].concat(...state?.formData?.purchases.map((x) => x.data));
    const totalNetweight = d.reduce((sum, row) => row.netweight + sum, 0);
    const perc = [];
    state?.formData?.purchases.map((item) => {
      let netweight = 0,
        percentage = 0;
      netweight = item.data.reduce((sum, row) => row.netweight + sum, 0);

      percentage = (netweight / totalNetweight) * 100;

      if (item.data.length > 0) {
        if (item?.type === 1) {
          perc.push({
            id: 1,
            type: "Tobacco Leaves (B)",
            netweight: Number(netweight).toFixed(2),
            percentage: Number(percentage).toFixed(2),
            required: Number(percentage).toFixed(2),
            deduction: 0,
            final: Number(netweight).toFixed(2),
          });
          setproductpercent([...perc]);
        } else if (item.type === 2) {
          perc.push({
            id: 2,
            type: "Tobacco Leaves (D)",
            netweight: Number(netweight).toFixed(2),
            percentage: Number(percentage).toFixed(2),
            required: Number(percentage).toFixed(2),
            deduction: 0,
            final: Number(netweight).toFixed(2),
          });
          setproductpercent([...perc]);
        } else if (item.type === 3) {
          perc.push({
            id: 3,
            type: "Tobacco Leaves (G)",
            netweight: Number(netweight).toFixed(2),
            percentage: Number(percentage).toFixed(2),
            required: Number(percentage).toFixed(2),
            deduction: 0,
            final: Number(netweight).toFixed(2),
          });
          setproductpercent([...perc]);
        }
      }
      return perc;
    });
  }, [state?.formData?.purchases]);

  // useEffect(() => {
  //   console.log("productpercent", productpercent);
  // }, [productpercent]);

  const handlePercentageChange = (e, item) => {
    if (
      Number(e.target.value) <= item.percentage &&
      Number(e.target.value) > 0
    ) {
      const t = [...productpercent.filter((x) => x.id !== item.id)];
      const itemIndex = productpercent.findIndex((x) => x.id === item.id);
      console.log(itemIndex);
      const finalVal = Number(
        (item.netweight * Number(e.target.value)) / item.percentage
      ).toFixed(3);
      const deductionkg = Number(item.netweight - finalVal).toFixed(3);
      const changedItem = {
        ...item,
        required: Number(e.target.value),
        deduction: deductionkg,
        final: finalVal,
      };
      t.splice(itemIndex, 0, { ...changedItem });
      setproductpercent([...t]);
    }
  };

  return (
    <PageLayout
      title={"Generate New Invoice (North Gujarat)"}
      backBtnTitle={`BAck to List`}
      onClick={() => navigate("/invoice")}
    >
      <Form className="m-4 g-2" onSubmit={form.handleSubmit(form.onSubmit)}>
        <Row>
          <Col lg={2} md={4} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Invoice Date"
                format={keys().shortDateFormat}
                slotProps={{
                  textField: {
                    ...textFieldProps,
                  },
                }}
                onChange={actions.onDateChange}
                value={state?.formData?.voucherdate}
                clearable
              />
            </LocalizationProvider>
          </Col>
          <Col lg={2} md={4} sm={12}>
            <TextField
              name="voucherno"
              value={state?.formData?.voucherno}
              label="Invoice No"
              onChange={actions.handleChange}
              disabled
              {...textFieldProps}
            />
            <FormHelperText>{`Last Voucher # - ${state?.formData?.lastvoucherno}`}</FormHelperText>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <VirtualizeAutoComplete
              name="selectedFarmer"
              size="small"
              options={lists.farmerDropdownList}
              clearText={false}
              value={state?.formData?.selectedFarmer}
              onChange={actions.onFarmerChange}
              renderInput={(params) => (
                <TextField
                  {...form.register("selectedFarmer")}
                  {...params}
                  label="Farmer Code"
                  {...textFieldProps}
                  error={form?.errors?.selectedFarmer}
                />
              )}
            />
          </Col>
          <Col lg={2} md={4} sm={12}>
            <TextField
              value={state?.formData?.totalbigha}
              label="Total Land (vigha)"
              disabled
              {...textFieldProps}
            />
            <FormHelperText>{`Last Voucher # - ${state?.formData?.lastvoucherno}`}</FormHelperText>
          </Col>
        </Row>
        <Row>
          <Col lg={2} md={2} sm={12}>
            <TextField
              name="ngprice"
              value={state?.formData?.ngprice}
              label="Overall price"
              onChange={actions.handleChange}
              onBlur={actions.onBlur}
              InputLabelProps={{ shrink: true }}
              {...textFieldProps}
            />
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              name="voucherno"
              value={state?.formData?.selectedFarmer?.overallprice}
              label="Overall price"
              onChange={actions.handleChange}
              InputLabelProps={{ shrink: true }}
              disabled
              {...textFieldProps}
            />
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              name="voucherno"
              value={state?.formData?.selectedFarmer?.avgprice}
              label="Average price"
              InputLabelProps={{ shrink: true }}
              disabled
              {...textFieldProps}
            />
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              name="voucherno"
              value={state?.formData?.selectedFarmer?.sample1price}
              label="Sample 1 price"
              InputLabelProps={{ shrink: true }}
              disabled
              {...textFieldProps}
            />
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              name="voucherno"
              value={state?.formData?.selectedFarmer?.sample2price}
              label="Sample 2 price"
              InputLabelProps={{ shrink: true }}
              disabled
              {...textFieldProps}
            />
          </Col>
          <Col lg={2} md={2} sm={12}>
            <TextField
              name="voucherno"
              value={state?.formData?.selectedFarmer?.sample3price}
              label="Sample 3 price"
              InputLabelProps={{ shrink: true }}
              disabled
              {...textFieldProps}
            />
          </Col>
        </Row>
        {state?.formData?.farmerid > 0 && (
          <>
            <Row className="mt-1">
              <Col md={12} sm={12} lg={12}>
                <table className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <th>{`Receipt`}</th>
                      <th>{`Product Name`}</th>
                      <th>{`HSN Code`}</th>
                      <th>{`Bags`}</th>
                      <th>{`Net KG`}</th>
                      <th>{`Base Price/Mann`}</th>
                      {/* <th>{`Quality Incentive`}</th> */}
                      {/* <th>{`Quality Incentive Amount`}</th> */}
                      <th>{`Amount`}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state?.formData?.purchases.map((item) => {
                      let bags = 0,
                        netweight = 0,
                        amount = 0;

                      return item.data.map((pd, idx) => {
                        bags += pd.actualbags;
                        netweight += pd.netweight;
                        amount += pd.amount;

                        return (
                          <>
                            <tr key={Math.random()}>
                              <td>{pd.voucherno}</td>
                              <td>{`${pd.productname}${getType(
                                item.type
                              )}`}</td>
                              <td>{pd.hsncode}</td>
                              <td className="text-end">{pd.actualbags}</td>
                              <td className="text-end">{pd.netweight}</td>
                              <td className="text-end">{pd.rateperunit}</td>
                              {/* <td className="text-end">{pd.incentiveperkg}</td> */}
                              <td className="text-end">
                                {Number(pd.amount).toFixed(3)}
                              </td>
                            </tr>

                            {item.data.length - 1 === idx && (
                              <tr className="table-secondary">
                                <th>{}</th>
                                <th>{`Total`}</th>
                                <th>{``}</th>
                                <th className="text-end">{bags}</th>
                                <th className="text-end">
                                  {Number(netweight).toFixed(3)}
                                </th>
                                <th>{}</th>
                                {/* <th>{}</th> */}
                                <th className="text-end">
                                  {Number(amount).toFixed(3)}
                                </th>
                              </tr>
                            )}
                          </>
                        );
                      });
                    })}
                    <tr className="table-warning">
                      <th>{}</th>
                      <th>{`Sub Total`}</th>
                      <th>{``}</th>
                      <th className="text-end">
                        {lists.purchases_from_farmer.reduce((sum, row) => {
                          return row.actualbags + sum;
                        }, 0)}
                      </th>
                      <th className="text-end">
                        {lists.purchases_from_farmer.reduce(
                          (sum, row) => row.netweight + sum,
                          0
                        )}
                      </th>
                      <th>{}</th>
                      {/* <th>{}</th> */}
                      <th className="text-end">
                        {Number(
                          lists.purchases_from_farmer.reduce(
                            (sum, row) => row.amount + sum,
                            0
                          )
                        ).toFixed(3)}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </Col>
              <Col md={12} sm={12} lg={12}>
                <table className="table table-sm table-bordered">
                  <thead>
                    <th>{`Incentives`}</th>
                    <th>{`Applicable on`}</th>
                    <th>{`Is Eligible`}</th>
                    <th>{`Photo`}</th>
                    <th>{`KIT`}</th>
                    <th>{`Invoice Copy`}</th>
                    <th>{`Rate`}</th>
                    <th>{`Incentive Amount`}</th>
                  </thead>
                  <tbody>
                    {state.formData.incentives.map((item) => {
                      return (
                        <tr key={Math.random()}>
                          <td>{item.schemeAliasName}</td>
                          <td>
                            {Boolean(item.pervigha)
                              ? "Vigha"
                              : Boolean(item.perkg)
                              ? "KG"
                              : "-"}
                          </td>
                          <td>{item.incenid > 0 ? "Yes" : "No"}</td>

                          <td>
                            {item.photo !== "True" ? (
                              "NA"
                            ) : item.photo === "True" &&
                              item.isfarmerphoto === 1 ? (
                              <Link
                                to={`${keys().ServicePath}${item.farmerphoto}`}
                                target="_blank"
                              >
                                {`View`}
                              </Link>
                            ) : (
                              "NOT UPLOADED"
                            )}
                          </td>
                          <td>
                            {item.returnUsedKit !== "True" ? (
                              "NA"
                            ) : item.photo === "True" &&
                              item.isusedkitphoto === 1 ? (
                              <Link
                                to={`${keys().ServicePath}${item.usedkitphoto}`}
                                target="_blank"
                              >
                                {`View`}
                              </Link>
                            ) : (
                              "NOT UPLOADED"
                            )}
                          </td>
                          <td>
                            {item.invoiceCopy !== "True" ? (
                              "NA"
                            ) : item.invoiceCopy === "True" &&
                              item.isinvoicecopy === 1 ? (
                              <Link
                                to={`${keys().ServicePath}${item.invoicecopy}`}
                                target="_blank"
                              >
                                {`View`}
                              </Link>
                            ) : (
                              "NOT UPLOADED"
                            )}
                          </td>
                          <td>{item.rate}</td>
                          <td>
                            <TextField
                              name="incentiveamount"
                              value={item.incentiveamount}
                              // onChange={(e) =>
                              //   (item.rateprovided = e.target.value)
                              // }
                              {...textFieldProps}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                md={1}
                lg={1}
                className="d-flex align-items-center justify-content-center"
              >
                <TextField
                  name="subtotal"
                  value={state.formData.subtotal}
                  label="Sub Total"
                  //onChange={actions.handleChange}
                  disabled
                  {...textFieldProps}
                />
                <Typography>{`+`}</Typography>
              </Col>

              <Col
                md={1}
                lg={1}
                className="d-flex align-items-center justify-content-center"
              >
                <TextField
                  name="transportation"
                  value={state.formData.totaltransportation}
                  label="Transportation"
                  disabled
                  //onChange={actions.handleChange}
                  {...textFieldProps}
                />
                <Typography>{`+`}</Typography>
              </Col>
              <Col
                md={1}
                lg={1}
                className="d-flex align-items-center justify-content-center"
              >
                <TextField
                  name="transportation"
                  value={state.formData.incentivetotal}
                  label="Incentives"
                  disabled
                  {...textFieldProps}
                />
                <Typography>{`-`}</Typography>
              </Col>
              <Col
                md={1}
                lg={1}
                className="d-flex align-items-center justify-content-center"
              >
                <TextField
                  name="unaccountbagscharge"
                  value={state.formData.unaccountedbagsamount}
                  label="Bag Charges"
                  disabled
                  {...textFieldProps}
                />
                <Typography>{`=`}</Typography>
              </Col>
              <Col md={1} lg={1}>
                <TextField
                  name="grandtotal"
                  value={state.formData.grandtotal}
                  label="Grand Total"
                  onChange={actions.handleChange}
                  disabled
                  {...textFieldProps}
                />
              </Col>
              <Col md={1} lg={1}>
                <TextField
                  name="totalqtymaan"
                  value={state.formData.totalqtymaan}
                  label="Total Qty (Mann)"
                  disabled
                  onChange={actions.handleChange}
                  {...textFieldProps}
                />
              </Col>
              <Col md={1} lg={1}>
                <TextField
                  name="pricepermaan"
                  value={state.formData.pricepermaan}
                  label="Price/Mann"
                  disabled
                  onChange={actions.handleChange}
                  {...textFieldProps}
                />
              </Col>
              <Col md={1} lg={1}>
                <TextField
                  name="unaccountbags"
                  value={state.formData.unaccountedbags}
                  label="Unaccount bags"
                  disabled
                  {...textFieldProps}
                />
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md={8} lg={8} sm={12}>
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th>{`Product`}</th>
                      <th>{`Weight`}</th>
                      <th>{`Percentage`}</th>
                      <th>{`Required %`}</th>
                      <th>{`Deduction`}</th>
                      <th>{`Final KG`}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productpercent.length > 0 &&
                      productpercent.map((item) => (
                        <tr>
                          <td>{item.type}</td>
                          <td>{item.netweight}</td>
                          <td>{item.percentage}</td>
                          <td>
                            <input
                              type="number"
                              // onChange={(e) => handlePercentageChange(e, item)}
                              onBlur={(e) => handlePercentageChange(e, item)}
                            ></input>
                          </td>
                          <td>{item.deduction}</td>
                          <td>{item.final}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Col>
            </Row>
          </>
        )}
        <Row className="mt-2 g-2">
          <Col lg={1} md={1} sm={12}>
            <Button
              className="custom-btn-page-layout text-white "
              startIcon={<Save />}
              fullWidth
              type="submit"
              disabled
            >
              {` Save`}
            </Button>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <Button
              className="custom-btn-page-layout custom-btn-warning text-white "
              startIcon={<Print />}
              fullWidth
              type="button"
              onClick={actions.onSubmitandPrint}
              //disabled={error[1].value || error[2].value || error[3].value}
            >
              {` Save & Print`}
            </Button>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <Button
              type="button"
              className="custom-btn-page-layout custom-btn-danger "
              startIcon={<Cancel />}
              onClick={actions.onCancelClick}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
      <div className="d-none">
        {state?.formData.farmerid > 0 && (
          <PrintInvoice
            ref={state?.invoiceComponentRef}
            data={state?.formData}
            lists={lists}
          />
        )}
      </div>
    </PageLayout>
  );
};
export default NGInvoice;
