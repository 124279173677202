import { Col, Row } from "reactstrap";
import PageLayout from "../../Component/PageLayout";
import QrReader from "../../common/QRScanner";
import { useNavigate } from "react-router-dom";

const GateEntryScanner = () => {
  const navigate = useNavigate();
  const onSuccess = (data) => {
    navigate(`/scanresult/${Number(data)}`);
  };
  return (
    <PageLayout title={"Scan Gate Entry"}>
      <Row justify>
        <Col lg={4} md={4} sm={12}>
          <QrReader type="entry" onSuccess={onSuccess} />
        </Col>
      </Row>
    </PageLayout>
  );
};

export default GateEntryScanner;
