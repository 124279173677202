import { combineReducers } from "redux";
import * as farmerReducer from "./farmer";
import * as vendorReducer from "./vendor";
import * as yieldConfigurationReducer from "./yieldConfiguration";
import * as purchaseOrderReducer from "./purchase";
import * as employeeReducer from "./employee";
import * as grnReducer from "./grn";
import * as incentiveReducer from "./incentive";
import * as pricelistReducer from "./pricelist";
import * as commonReducer from "./common";
import * as yieldEstimationReducer from "./yieldEstimation";
import * as landDetailReducer from "./landdetail";
import * as productReducer from "./product";
import * as bagIssueReducer from "./bagissue";
import * as dashboardReducer from "./dashboard";
import * as farmerPurchaseReducer from "./farmerpurchase";
import * as surveyReducer from "./survey";
import * as wasteReducer from "./wastemanagement";
import * as settingsReducer from "./settings";
import * as roleReducer from "./role";
import * as moduleReducer from "./modules";

const rootReducer = combineReducers({
  ...farmerReducer,
  ...vendorReducer,
  ...yieldConfigurationReducer,
  ...employeeReducer,
  ...grnReducer,
  ...incentiveReducer,
  ...pricelistReducer,
  ...commonReducer,
  ...yieldEstimationReducer,
  ...landDetailReducer,
  ...productReducer,
  ...bagIssueReducer,
  ...dashboardReducer,
  ...farmerPurchaseReducer,
  ...surveyReducer,
  ...wasteReducer,
  ...settingsReducer,
  ...roleReducer,
  ...moduleReducer
});

export const allReducers = {
  ...farmerReducer,
  ...vendorReducer,
  ...yieldConfigurationReducer,
  ...purchaseOrderReducer,
  ...employeeReducer,
  ...grnReducer,
  ...incentiveReducer,
  ...pricelistReducer,
  ...commonReducer,
  ...yieldEstimationReducer,
  ...landDetailReducer,
  ...productReducer,
  ...bagIssueReducer,
  ...dashboardReducer,
  ...farmerPurchaseReducer,
  ...surveyReducer,
  ...wasteReducer,
  ...settingsReducer,
  ...roleReducer,
  ...moduleReducer
};

export default rootReducer;
