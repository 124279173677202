/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import {
  bagissueActions,
  commonActions,
  farmerpurchaseActions,
  productActions,
} from "../../redux/actions";
import { useEffect, useMemo, useRef, useState } from "react";
import dayjs from "dayjs";
import { keys } from "../../config/keys";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { farmerPurchaseValidationSchema } from "./validationSchema";
import {
  getYieldEstimtedFarmerDropdownList,
  get_farmer_by_booking,
} from "../../redux/actions/farmer";
import { get_bag_issued_to_farmer_summary } from "../../redux/actions/bagissue";
import { GridAction } from "../../common/useGridAction";
import { toast } from "react-toastify";
import { usePermissionHooks } from "../../common/useCheckPermission";
import { useReactToPrint } from "react-to-print";
import {
  get_farmer_purchase_by_id,
  update_weight_transaction,
} from "../../redux/actions/farmerpurchase";
import { IconButton, TextField } from "@mui/material";
import { textFieldProps } from "../../common/commonControlProps";
import socket from "../../common/socketClient";
import { Save } from "@mui/icons-material";

const initialValues = {
  id_voucherno: 0,
  purchasedatestring: dayjs(Date()).format(keys().dbDateFormat),
  purchasedate: dayjs(Date()),
  voucherno: "",
  lastvoucherno: "",
  selectedFarmer: null,
  farmerid: 0,
  farmercode: "",
  vehicleno: "",
  vehicleweight: 0,
  purchasedfrom: "",
  kasor: 0,
  bagdetails: {
    farmerid: 0,
    bagnos: "",
    farmername: "",
    noofbagsissued: 0,
    bagsused: 0,
  },
  selectedproducttype: null,
  producttypeid: 0,
  productid: 0,
  weight: 0,
  noofbags: 0,
  bagweight: 0,
  addkasor: 0,
  minuskasor: 0,
  incentive: 0,
  netweight: 0,
  purchasedetails: [],
  bookingtype: 1,
  bookingid: 0,
  productweight: 0,
  verifiedproduct: [],
  incentiveparams: [
    {
      label: "Thickness",
      name: "thickness",
      disabled: false,
      value: 0,
      star: 0,
    },
    {
      label: "Color",
      name: "color",
      disabled: false,
      value: 0,
      star: 0,
    },
    {
      label: "Moisture",
      name: "moisture",
      disabled: true,
      value: 0,
      star: 0,
    },
    {
      label: "Size",
      name: "size",
      disabled: true,
      value: 0,
      star: 0,
    },
    {
      label: "Clean",
      name: "clean",
      disabled: true,
      value: 0,
      star: 0,
    },
  ],
  bhuko_kasor_1: 0,
  bhuko_kasor_2: 0,
  bhuko_kasor_3: 0,
  bhuko_kasor_4: 0,
  bhuko_kasor_5: 0,
  dakru_kasor_1: 0,
  dakru_kasor_2: 0,
  dakru_kasor_3: 0,
  dakru_kasor_4: 0,
  gariyu_kasor_1: 0,
  gariyu_kasor_2: 0,
  gariyu_kasor_3: 0,
  gariyu_kasor_4: 0,
  producttype: [
    {
      value: 1,
      label: "Bhukho",
      details: [
        {
          id: 1,
          name: "bhuko_kasor_1",
          label: `પત્તીમાં  માં ડોખરું `,
          operation: `addsub`,
          value: 0,
          producttypeid: 1,
        },
        {
          id: 2,
          name: "bhuko_kasor_2",
          label: `પત્તીમાં માં રાડીયું `,
          operation: `sub`,
          value: 0,
          producttypeid: 1,
        },
        {
          id: 3,
          name: "bhuko_kasor_3",
          label: `પત્તીમાં માં માટી `,
          operation: `sub`,
          value: 0,
          producttypeid: 1,
        },
        {
          id: 4,
          name: "bhuko_kasor_4",
          label: `પત્તીમાં માં ભેજ  `,
          operation: `per`,
          value: 0,
          producttypeid: 1,
        },
        {
          id: 5,
          name: "bhuko_kasor_5",
          label: `Others`,
          operation: `sub`,
          value: 0,
          producttypeid: 1,
        },
      ],
    },
    {
      value: 2,
      label: "Dakru",
      details: [
        {
          id: 1,
          name: "dakru_kasor_1",
          label: `ડોખરું માં રાડીયું`,
          operation: `sub`,
          value: 0,
          producttypeid: 2,
        },
        {
          id: 2,
          name: "dakru_kasor_2",
          label: `ડોખરું માં માટી `,
          operation: `sub`,
          value: 0,
          producttypeid: 2,
        },
        {
          id: 3,
          name: "dakru_kasor_3",
          label: `ડોખરું માં ભેજ `,
          operation: `per`,
          value: 0,
          producttypeid: 2,
        },
        {
          id: 4,
          name: "dakru_kasor_4",
          label: `Others`,
          operation: `sub`,
          value: 0,
        },
      ],
    },
    {
      value: 3,
      label: "Gariyo",
      details: [
        {
          id: 1,
          name: "gariyu_kasor_1",
          label: `ગારિયામાં ડોખરું`,
          operation: `sub`,
          value: 0,
          producttypeid: 3,
        },
        {
          id: 2,
          name: "gariyu_kasor_2",
          label: `ગારિયામાં માં માટી `,
          operation: `sub`,
          value: 0,
          producttypeid: 3,
        },
        {
          id: 3,
          name: "gariyu_kasor_3",
          label: `ગારિયામાં માં ભેજ `,
          operation: `per`,
          value: 0,
          producttypeid: 3,
        },
        {
          id: 4,
          name: "gariyu_kasor_4",
          label: `Others`,
          operation: `sub`,
          value: 0,
        },
      ],
    },
  ],
};

const initialErrorValues = {
  1: {
    value: false,
    message: "Product weight should not be greater than allowed limit",
  },
  2: {
    value: false,
    message: "",
  },
  3: {
    value: false,
    message: "",
  },
};

export const useFarmerPurchaseHooks_1 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const gatepassComponentRef = useRef();
  const {
    farmerDropdownList,
    bag_issue_list_farmer,
    previous_purchases_by_farmer,
  } = useSelector((obj) => obj);
  const [loading, setLoading] = useState(false);
  const [farmerYieldData, setFarmerYieldEstimationData] = useState([]);
  const [bags_in_stock, setAvailableBags] = useState([]);
  const [total_bags_stock, settotalbagsStock] = useState([
    { stock: 0, capacity: 0 },
  ]);
  const [formData, setFormData] = useState(initialValues);
  const [error, setError] = useState(initialErrorValues);
  const [totalEstimatedYield, setTotalEstimatedYield] = useState(0);
  const [estimatedBagsToIssue, setEstimatedBagsToIssue] = useState(0);
  const [previousProduct, setPreviousProduct] = useState(undefined);
  const [currentProduct, setCurrentProduct] = useState(undefined);
  const [updateProduct, setUpdateProduct] = useState(undefined);
  const [grossweight, setgrossweight] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(farmerPurchaseValidationSchema),
  });

  useEffect(() => {
    setLoading(!loading);
    get_latest_voucher();
    farmer_dropdown_list();
  }, []);

  useEffect(() => {
    if (location.state !== null) {
      const data = location.state;
      setFormData((prev) => ({
        ...prev,
        farmerid: data.farmerid,
        selectedFarmer: farmerDropdownList.find((x) => x.id === data.farmerid),
      }));

      get_farmer_yield_estimation(data.farmerid);
      bag_issused_to_farmer_summary(data.farmerid);
    }
  }, [location.state]);

  socket.on("purchaseweight", (data) => {
    setCurrentProduct((prev) => ({ ...prev, weight: data }));
    // setFormData((prev) => ({ ...prev, weight: data }));
  });
  // useEffect(() => {
  //   if (bag_issue_list_farmer.length > 0) {
  //     setFormData((prev) => ({
  //       ...prev,
  //       bagdetails: bag_issue_list_farmer[0],
  //     }));
  //   }
  // }, [bag_issue_list_farmer]);

  // useEffect(() => {
  //   if (formData.farmerid > 0 && formData.productid > 0) {
  //     const _data = total_bags_stock[0];
  //     const bag_capacity = _data?.capacity || 0;
  //     setEstimatedBagsToIssue(
  //       Math.ceil(totalEstimatedYield / bag_capacity) || 0
  //     );
  //   }
  // }, [
  //   totalEstimatedYield,
  //   total_bags_stock,
  //   formData.farmerid,
  //   formData.productid,
  // ]);

  const column = useMemo(
    () => [
      {
        Header: "Product",
        accessor: "productname",
        Footer: "TOTAL",
      },
      {
        Header: "Type",
        accessor: "yield",
      },
      {
        Header: "Minimum",
        accessor: "minimum",
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => Number(row.values.minimum) + sum,
                0
              ),
            [info.rows]
          );

          return Number(total).toFixed(3);
        },
      },
      {
        Header: "Actual",
        accessor: "actual",
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = useMemo(
            () => info.rows.reduce((sum, row) => row.values.actual + sum, 0),
            [info.rows]
          );
          return Number(total).toFixed(3);
        },
      },
      {
        Header: "Maximum",
        accessor: "maximum",
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = useMemo(
            () => info.rows.reduce((sum, row) => row.values.maximum + sum, 0),
            [info.rows]
          );
          return Number(total).toFixed(3);
        },
      },
      {
        Header: "Previous Purchases",
        accessor: "previouspurchase",
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => row.values.previouspurchase + sum,
                0
              ),
            [info.rows]
          );
          return Number(total).toFixed(3);
        },
      },

      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  const updatetabledata = (e, cellProps) => {
    let new_updated_data = cellProps.data.map((item) => {
      if (item.id === cellProps.cell.row.id) {
        if (e.target.name === "bagweight" || e.target.name === "actualbags") {
          return {
            ...item,
            [e.target.name]: Number(e.target.value),
            totalbagweight:
              e.target.name === "bagweight"
                ? e.target.value * item.actualbags
                : item.bagweight * e.target.value,
          };
        } else
          return {
            ...item,
            [e.target.name]: Number(e.target.value),
          };
      }
      return item;
    });
    setFormData((prev) => ({
      ...prev,
      purchasedetails: [...new_updated_data],
    }));
  };
  const purchasedetailscolumn = useMemo(
    () => [
      {
        Header: "Type",
        accessor: "producttype",
      },
      {
        Header: "Gross Weight",
        accessor: "weight",
        Cell: (cellProps) =>
          cellProps.row.original.netweight > 0 ? (
            cellProps.value
          ) : (
            <TextField
              name="grossweight"
              {...textFieldProps}
              InputLabelProps={{ shrink: true }}
              onBlur={(e) => setgrossweight(e.target.value)}
              // onChange={(e) => {
              //   console.log(e.target.value);
              //   setgrossweight(e.target.value);
              //   // console.log(cellProps);
              //   // //cellProps.value = e.target.value;
              // }}
              // value={cellProps.row.original.weight}
            />
          ),
      },
      {
        Header: "Weight",
        accessor: "productweight",
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = useMemo(
            () =>
              info.rows.reduce((sum, row) => row.values.productweight + sum, 0),
            [info.rows]
          );
          return Number(total).toFixed(0);
        },
      },
      {
        Header: "Actual Bags Counted",
        accessor: "actualbags",
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = useMemo(
            () =>
              info.rows.reduce((sum, row) => row.values.actualbags + sum, 0),
            [info.rows]
          );
          return Number(total).toFixed(0);
        },
      },
      {
        Header: "Total Bag weight",
        accessor: "totalbagweight",
        Cell: ({ value }) => Number(value).toFixed(3),
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => row.values.totalbagweight + sum,
                0
              ),
            [info.rows]
          );
          return Number(total).toFixed(3);
        },
      },
      {
        Header: "Add Kasor",
        accessor: "addkasor",
        Cell: ({ value }) => Number(value).toFixed(3),
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = useMemo(
            () => info.rows.reduce((sum, row) => row.values.addkasor + sum, 0),
            [info.rows]
          );
          return Number(total).toFixed(3);
        },
      },
      {
        Header: "Minus Kasor",
        accessor: "minuskasor",
        Cell: ({ value }) => Number(value).toFixed(3),
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = useMemo(
            () =>
              info.rows.reduce((sum, row) => row.values.minuskasor + sum, 0),
            [info.rows]
          );
          return Number(total).toFixed(3);
        },
      },

      {
        Header: "Incentive/Kg",
        accessor: "incentiveperkg",
      },
      {
        Header: "Incentive",
        accessor: "incentive",
      },
      {
        Header: "Net Weight",
        accessor: "netweight",
        Cell: ({ value }) => Number(value).toFixed(3),
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = useMemo(
            () => info.rows.reduce((sum, row) => row.values.netweight + sum, 0),
            [info.rows]
          );
          return Number(total).toFixed(3);
        },
      },
      // {
      //   id: "ssavegross",
      //   Header: "",
      //   Cell: (cellProps) => {
      //     return (
      //       <IconButton
      //         size={"small"}
      //         color="info"
      //         onClick={() => {
      //           console.log(cellProps);
      //           console.log({ ...cellProps.row.original, grossweight });
      //           dispatch(
      //             update_weight_transaction(
      //               {
      //                 ...cellProps.row.original,
      //                 grossweight: Number(grossweight),
      //               },
      //               {
      //                 onSuccess: () => {
      //                   let id = formData?.id_voucherno;
      //                   onCancelClick();
      //                   _purchase_by_id(id);
      //                   setFormData((prev) => ({ ...prev, id_voucherno: id }));
      //                 },
      //               }
      //             )
      //           );
      //         }}
      //       >
      //         <Save fontSize={"small"} />
      //       </IconButton>
      //     );
      //     //_purchase_by_id(formData?.id_voucherno);
      //   },
      // },
      {
        ...GridAction({
          permission: { edit: false, delete: true, print: false },
          onDeleteClick: (cellProps) => {
            deleteRecord(cellProps);
          },
        }),
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  const deleteRecord = (pCellProps) => {
    const t = Array.from(pCellProps.data);
    const tmpData = t.filter((x) => Number(x.id) !== Number(pCellProps.row.id));
    setFormData((prev) => ({ ...prev, purchasedetails: tmpData }));
  };

  const bags_issued_to_farmer_columns = useMemo(
    () => [
      {
        Header: "Issue Date",
        accessor: "issuedatetime",
        Cell: ({ value }) => keys().shortDate(value),
      },
      {
        Header: "Voucher #",
        accessor: "voucherno",
      },
      {
        Header: "Bag Nos.",
        accessor: "bagnos",
      },
    ],
    []
  );

  const previous_purchase_columns = useMemo(
    () => [
      {
        Header: "Voucher #",
        accessor: "voucherno",
      },

      {
        Header: "Purchase Date",
        accessor: "purchasedate",
        Cell: ({ value }) => keys().shortDate(value),
      },
      {
        Header: "Vehicle No",
        accessor: "vehicleno",
      },
      {
        Header: "Bhuko",
        accessor: "bhuko",
      },
      {
        Header: "Dakru",
        accessor: "dakru",
      },
      {
        Header: "Gariyo",
        accessor: "gariyo",
      },
    ],
    // eslint-disable-next-line
    []
  );

  const _get_product_list = () =>
    dispatch(
      productActions.get_product_list({
        onSuccess: (res) => {
          setLoading(false);
        },
      })
    );

  const _get_previous_purchase_from_farmers = (farmerid) =>
    dispatch(
      farmerpurchaseActions.get_previous_purchases_from_farmer(farmerid)
    );

  const get_latest_voucher = () =>
    dispatch(
      commonActions.get_last_latest_voucherno("purchase_master", {
        onSuccess: (res) => {
          if (res.length > 0) {
            setFormData((prev) => ({ ...prev, ...res[0] }));
          }
        },
      })
    );

  const bag_issused_to_farmer_summary = (farmerid) =>
    dispatch(
      bagissueActions.get_bag_issued_to_farmer_summary(farmerid, {
        onSuccess: () => setLoading(false),
        onError: () => setLoading(false),
      })
    );

  const farmer_dropdown_list = () =>
    dispatch(
      get_farmer_by_booking(formData.bookingtype, {
        onSuccess: () => setLoading(false),
        onError: () => setLoading(false),
      })
    );

  const _purchase_by_id = (id) =>
    dispatch(
      get_farmer_purchase_by_id(id, {
        onSuccess: (res) => {
          setLoading(false);
          if (res?.data?.rows[1].length > 0) {
            const data = res?.data?.rows[1][0];
            get_farmer_yield_estimation(data.farmerid);
            _get_previous_purchase_from_farmers(data.farmerid);
            console.log(res?.data?.rows[2]);
            setFormData((prev) => ({
              ...prev,
              ...data,
              purchasedate: dayjs(data.purchasedate),
              purchasedetails: [...res?.data?.rows[2]],
              verifiedproduct:
                [...res?.data?.rows[2].filter((x) => x.idx > 0)] || [],
            }));
          }
        },
        onError: () => setLoading(false),
      })
    );

  const get_farmer_yield_estimation = (farmerId) =>
    dispatch(
      farmerpurchaseActions.get_farmers_yield_estimation(farmerId, {
        onSuccess: (res) => {
          setFarmerYieldEstimationData([...res]);
          if (res.length > 0) {
            setFormData((prev) => ({
              ...prev,
              productid: res[0].productid,
            }));
          }
        },
      })
    );

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleChangeUpperCase = (e) => {
    let val = String(e.target.value).toUpperCase();
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: val,
    }));
  };

  const handleIncentiveChange = (e, value, star) => {
    const position = formData.incentiveparams.findIndex(
      (x) => x.name === e.target.name
    );
    const t = [
      ...formData.incentiveparams.filter((x) => x.name !== e.target.name),
    ];

    const d = {
      ...formData.incentiveparams.filter((x) => x.name === e.target.name)[0],
      value,
      star,
    };
    t.splice(position, 0, d); // t.push(d);

    const final = t.map((x) => ({
      ...x,
      disabled: ["thickness", "color"].includes(x.name)
        ? false
        : t[0].star >= 1 && t[1].star >= 1
        ? false
        : true,
    }));

    const totIncent = t.reduce((sum, row) => row.value + sum, 0);

    setFormData((prev) => ({
      ...prev,
      incentiveparams: [...final],
    }));
    setCurrentProduct((prev) => ({ ...prev, incentiveperkg: totIncent }));
  };

  const onFarmerChange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      farmerid: selectedOption?.id || 0,
      selectedFarmer: selectedOption || null,
    }));

    if (selectedOption !== null) {
      get_farmer_yield_estimation(selectedOption.id);
      bag_issused_to_farmer_summary(selectedOption.id);
      _get_previous_purchase_from_farmers(selectedOption.id);
    } else setFarmerYieldEstimationData([]);
  };

  const onCancelClick = (e) => {
    setFormData(initialValues);
    setError(initialErrorValues);
  };

  const onProductTypeChange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      producttypeid: selectedOption?.producttypeid || 0,
      selectedproducttype: selectedOption || null,
    }));

    let index = formData.verifiedproduct.findIndex(
      (x) => x.producttypeid === selectedOption?.producttypeid || 0
    );

    let g = JSON.parse(localStorage.getItem("newtoken"));
    console.log(g);
    let bagweight = selectedOption?.bagweight;
    if (selectedOption?.producttypeid === 2 && !g.isnorthgujarat) {
      bagweight = 800;
    } else if (selectedOption?.producttypeid === 3 && g.isnorthgujarat) {
      bagweight = 2000;
    }

    setCurrentProduct({
      ...selectedOption,
      bagweight: bagweight,
      idx: index < 0 ? formData.verifiedproduct.length + 1 : index + 1,
    });
    // setCurrentProduct({
    //   ...selectedOption,
    //   bagweight:
    //     selectedOption?.producttypeid === 2 ? 800 : selectedOption?.bagweight,
    //   idx: index < 0 ? formData.verifiedproduct.length + 1 : index + 1,
    // });
  };

  const onProductTypeChangeIncentiveRole = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      producttypeid: selectedOption?.producttypeid || 0,
      selectedproducttype: selectedOption || null,
    }));

    let c = formData.verifiedproduct.find(
      (x) => x.producttypeid === selectedOption?.producttypeid || 0
    );

    setCurrentProduct({
      ...selectedOption,
      idx: c ? c.idx : formData.verifiedproduct.length + 1,
    });
  };

  const validatePurchaseEntry = async (e, cellProps) => {
    const wt = e.target.value;
    let value = false;
    let message = "";

    const cap = await farmerYieldData.find(
      (x) => x.producttype === cellProps.cell.row.original.producttypeid
    );

    if (cap) {
      const maxVal = cap.maximum - cap.previouspurchase;
      //console.log(cap, cellProps.cell.row.original);
      // console.log(
      //   "one",
      //   wt,
      //   cap.maximum - cap.previouspurchase,
      //   wt > cap.maximum - cap.previouspurchase,
      //   cap
      // );
      if (wt > maxVal) {
        value = true;
        message = `${
          cellProps.cell.row.original.producttype
        } cannot be more than allowed limit (${
          cap.maximum - cap.previouspurchase
        })`;
        // console.log("two");
        // console.log(value, message);
      }
      setError((prev) => ({
        ...prev,
        [cellProps.cell.row.original.producttypeid]: { value, message },
      }));
    }
  };

  const onproductkasorchange = (e, item) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setCurrentProduct((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    // let kasor = 0;
    // let adddakrukasor = 0;
    // if (currentProduct.producttypeid === 1) {
    //   kasor =
    //     (Number(formData.bhuko_kasor_1).toFixed(3) +
    //       Number(formData.bhuko_kasor_2).toFixed(3) +
    //       Number(formData.bhuko_kasor_3).toFixed(3) +
    //       Number(formData.bhuko_kasor_4).toFixed(3) +
    //       Number(formData.bhuko_kasor_5).toFixed(3)) *
    //     -1;

    //   adddakrukasor = Number(formData.bhuko_kasor_1).toFixed(3);

    //   setCurrentProduct((prev) => ({ ...prev, kasor, adddakrukasor }));
    // }
  };

  // useEffect(() => {
  //   console.log(error);
  // }, [error]);

  const handleBlur = (e) => {
    const wt = formData.weight;
    const capacity =
      bag_issue_list_farmer.length > 0 ? bag_issue_list_farmer[0].capacity : 0;
    const estimatedBags = Math.ceil(wt / capacity);
    setFormData((prev) => ({
      ...prev,
      noofbags: estimatedBags,
      netweight: formData.weight,
    }));
    validatePurchaseEntry();
  };

  const onDateChange = (dateValue) => {
    setFormData((prev) => ({
      ...prev,
      purchasedate: dateValue,
      purchasedatestring: dayjs(dateValue).format("YYYY-MM-DD"),
    }));
  };

  const onSubmit = () => {
    const data = {
      purchasedetails: formData.purchasedetails,
    };

    dispatch(
      farmerpurchaseActions.update_purchase_transaction(data, {
        onSuccess: (res) => {
          setCurrentProduct(undefined);
          setFormData(initialValues);
        },
      })
    );
  };

  const onSubmitandPrint = () => {
    const data = {
      purchasedetails: formData.purchasedetails,
    };
    dispatch(
      farmerpurchaseActions.update_purchase_transaction(data, {
        onSuccess: (res) => {
          onPrintGatePass();
          setCurrentProduct(undefined);
          setFormData(initialValues);
        },
      })
    );
  };

  const onEmptyandPrint = () => {
    let maxValue = formData.purchasedetails
      .filter((x) => x.producttypeid !== 4)
      .reduce((acc, value) => {
        return (acc = acc > value.idx ? acc : value.idx);
      }, 0);

    const d = formData.purchasedetails.find((x) => x.idx === maxValue);
    const data = formData.purchasedetails.find((x) => x.producttypeid === 4);
    dispatch(
      farmerpurchaseActions.update_emptyvehicle_transaction(
        { ...data, grossweight: d.weight, idx: Number(maxValue) + 1 },
        {
          onSuccess: (res) => {
            const id = formData?.id_voucherno;
            onCancelClick();
            _purchase_by_id(id);
            setFormData((prev) => ({ ...prev, id_voucherno: id }));
            onPrintGatePass();
          },
        }
      )
    );
  };

  const fetch_details = () => {
    _purchase_by_id(formData?.id_voucherno);
  };

  const onPrintGatePass = useReactToPrint({
    content: () => gatepassComponentRef.current,
  });

  const handleCurrentProductDetailsChange = (e) => {
    setCurrentProduct((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    const t =
      (Number(currentProduct?.actualbags) * currentProduct?.bagweight) / 1000;

    setCurrentProduct((prev) => ({ ...prev, totalbagweight: t }));
  }, [currentProduct?.actualbags]);

  useEffect(() => {
    const t =
      (Number(currentProduct?.actualbags) *
        Number(currentProduct?.kasorperbag)) /
      1000;

    setCurrentProduct((prev) => ({
      ...prev,
      kasor: t < 0 ? t * -1 : t,
      addkasor: t > 0 ? t : 0,
      minuskasor: t < 0 ? t * -1 : 0,
    }));
  }, [currentProduct?.kasorperbag]);

  useEffect(() => {
    const t =
      Number(currentProduct?.netweight) *
      Number(currentProduct?.incentiveperkg);

    setCurrentProduct((prev) => ({
      ...prev,
      incentive: t,
    }));
  }, [currentProduct?.kasorperbag]);

  const onAdd = () => {
    let _previousProduct = null;
    let t = formData.verifiedproduct.filter(
      (x) => x.idx === currentProduct.idx - 1
    );
    if (t.length === 0) {
      t = formData.verifiedproduct.filter(
        (x) => x.idx === currentProduct.idx - 2
      );
    }

    const crproduct = { ...currentProduct };
    const crdakruproduct = { ...currentProduct };
    if (t.length > 0) {
      _previousProduct = t[0];
      const pweight = _previousProduct.weight - currentProduct.weight;
      _previousProduct.productweight = pweight;

      const bhejkasor = (pweight * _previousProduct.bhej) / 100;
      const totkasor =
        (Number(_previousProduct?.actualbags) *
          Number(
            _previousProduct?.kasorperbag < 0
              ? _previousProduct?.kasorperbag * -1
              : _previousProduct?.kasorperbag
          )) /
        1000;
      const mkasor = totkasor + bhejkasor;

      const nweight =
        pweight -
        _previousProduct.totalbagweight -
        mkasor +
        _previousProduct.addkasor;
      _previousProduct.netweight = nweight;
      _previousProduct.minuskasor = mkasor;
      _previousProduct.incentive =
        (nweight / 20) * _previousProduct.incentiveperkg;

      //   crproduct.weight = _previousProduct.weight - currentProduct.weight;
    } else {
    }

    let kperbag = 0;
    if (crproduct.producttypeid === 1) {
      kperbag =
        (Number(crproduct.bhuko_kasor_1) +
          Number(crproduct.bhuko_kasor_2) +
          Number(crproduct.bhuko_kasor_3) +
          Number(crproduct.bhuko_kasor_5)) *
        -1;
      crproduct.kasorperbag = kperbag;
      crproduct.bhej = Number(crproduct.bhuko_kasor_4);
    } else if (crproduct.producttypeid === 2) {
      kperbag =
        (Number(crproduct.dakru_kasor_1) +
          Number(crproduct.dakru_kasor_2) +
          Number(crproduct.dakru_kasor_4)) *
        -1;
      crproduct.kasorperbag = kperbag;
      crproduct.bhej = Number(formData.dakru_kasor_3);
    } else if (crproduct.producttypeid === 3) {
      kperbag =
        (Number(crproduct.gariyu_kasor_1) +
          Number(crproduct.gariyu_kasor_2) +
          Number(crproduct.gariyu_kasor_4)) *
        -1;

      crproduct.kasorperbag = kperbag;
      crproduct.bhej = Number(formData.gariyu_kasor_3);
    }

    if (crproduct.netweight > 0) {
      crproduct.incentive =
        (crproduct.netweight / 20) * crproduct.incentiveperkg;
      const t =
        (Number(crproduct?.actualbags) *
          Number(kperbag < 0 ? kperbag * -1 : kperbag)) /
        1000;

      const bhejkasor =
        (crdakruproduct.productweight * Number(crproduct.bhej)) / 100;
      crproduct.minuskasor = t + bhejkasor;

      // console.log(
      //   "KASOR CALCULATION",
      //   (crdakruproduct.productweight * Number(crproduct.bhej)) / 100,
      //   crproduct.minuskasor
      // );
    }
    // console.log("PREVIOUS PRODUCT", _previousProduct);
    // console.log("CURRENT PRODUCT", crproduct);

    let newProduct;
    if (crproduct?.bhuko_kasor_1 > 0) {
      const t = formData.purchasedetails.find((x) => x.producttypeid === 2);
      if (t) {
        newProduct = {
          ...t,
          vehicleweight: crproduct.weight,
          addkasor: (crproduct.bhuko_kasor_1 * crproduct.actualbags) / 1000,
        };
      } else {
        newProduct = {
          ...crproduct,
          actualbags: 0,
          addkasor: (crproduct.bhuko_kasor_1 * crproduct.actualbags) / 1000,
          minuskasor: 0,
          bagweight: 0,
          bhej: 0,
          bhuko_kasor_1: 0,
          bhuko_kasor_2: 0,
          bhuko_kasor_3: 0,
          bhuko_kasor_4: 0,
          bhuko_kasor_5: 0,
          dakru_kasor_1: 0,
          dakru_kasor_2: 0,
          dakru_kasor_3: 0,
          dakru_kasor_4: 0,
          gariyu_kasor_1: 0,
          gariyu_kasor_2: 0,
          gariyu_kasor_3: 0,
          gariyu_kasor_4: 0,
          id: -1,
          vehicleweight: crproduct.weight,
          idx: formData.purchasedetails.length + 1,
          kasorperbag: crproduct.bhuko_kasor_1,
          label: "Dakru",
          netweight: (crproduct.bhuko_kasor_1 * crproduct.actualbags) / 1000,
          noofbags: 0,
          producttype: "Dakru",
          producttypeid: 2,
          totalbagweight: 0,
        };
      }
    }
    // else {
    //   const t1 = [
    //     ...formData.verifiedproduct.filter((x) => x.idx < currentProduct.idx),
    //   ];
    //   console.log(
    //     formData.verifiedproduct,
    //     currentProduct.idx,
    //     formData.verifiedproduct.filter((x) => x.idx < currentProduct.idx)
    //   );
    //   _previousProduct = t1.length > 0 ? t1[t1.length - 1] : null;
    // }
    // console.log("_previousProduct", _previousProduct, formData.verifiedproduct);

    const data = {
      purchasedetails:
        _previousProduct === null
          ? [
              {
                ...crproduct,
                weight:
                  crproduct.weight <= 0
                    ? crproduct.vehicleweight
                    : crproduct.weight,
              },
            ]
          : [
              _previousProduct,
              {
                ...crproduct,
                weight:
                  crproduct.weight <= 0
                    ? crproduct.vehicleweight
                    : crproduct.weight,
              },
            ],
    };

    if (crproduct?.bhuko_kasor_1 > 0) {
      data.purchasedetails.push({ ...newProduct });
    }

    console.log(data);
    // dispatch(
    //   farmerpurchaseActions.update_purchase_transaction(data, {
    //     onSuccess: (res) => {
    //       toast.success("Bags Updated");
    //       setCurrentProduct(undefined);
    //       let id = formData?.id_voucherno;
    //       onCancelClick();
    //       _purchase_by_id(id);
    //       setFormData((prev) => ({ ...prev, id_voucherno: id }));
    //       // _purchase_by_id(formData?.id_voucherno);
    //     },
    //   })
    // );
  };

  const update_weight = (data) => {
    dispatch(
      farmerpurchaseActions.update_weight_transaction(data, {
        onSuccess: (res) => {
          const id = formData?.id_voucherno;
          onCancelClick();
          _purchase_by_id(id);
          setFormData((prev) => ({ ...prev, id_voucherno: id }));
        },
      })
    );
  };

  const update_weight_admin = (data) => {
    dispatch(
      farmerpurchaseActions.update_weight_transaction_admin(data, {
        onSuccess: (res) => {
          const id = formData?.id_voucherno;
          onCancelClick();
          _purchase_by_id(id);
          setFormData((prev) => ({ ...prev, id_voucherno: id }));
        },
      })
    );
  };

  const [id, setId] = useState(0);
  const handleKeyDown = (e) => {
    if (e.keyCode > 47 && e.keyCode < 58) {
      setId(e.key);
    } else if (e.key === "Enter") {
      _purchase_by_id(id);
      setFormData((prev) => ({ ...prev, id_voucherno: "" }));
    }
  };

  const onAddAdmin = () => {
    let _previousProduct = null;
    let t = formData.verifiedproduct.filter(
      (x) => x.idx === currentProduct.idx - 1
    );
    if (t.length === 0) {
      t = formData.verifiedproduct.filter(
        (x) => x.idx === currentProduct.idx - 2
      );
    }

    const crproduct = { ...currentProduct };
    const crdakruproduct = { ...currentProduct };
    if (t.length > 0) {
      _previousProduct = t[0];
      const pweight = _previousProduct.weight - currentProduct.weight;
      _previousProduct.productweight = pweight;

      const bhejkasor = (pweight * _previousProduct.bhej) / 100;
      const totkasor =
        (Number(_previousProduct?.actualbags) *
          Number(
            _previousProduct?.kasorperbag < 0
              ? _previousProduct?.kasorperbag * -1
              : _previousProduct?.kasorperbag
          )) /
        1000;
      const mkasor = totkasor + bhejkasor;

      const nweight =
        pweight -
        _previousProduct.totalbagweight -
        mkasor +
        _previousProduct.addkasor;
      _previousProduct.netweight = nweight;
      _previousProduct.minuskasor = mkasor;
      _previousProduct.incentive =
        (nweight / 20) * _previousProduct.incentiveperkg;

      //   crproduct.weight = _previousProduct.weight - currentProduct.weight;

      crproduct.vehicleweight = _previousProduct.weight;
    }

    let kperbag = 0;
    if (crproduct.producttypeid === 1) {
      kperbag =
        (Number(crproduct.bhuko_kasor_1) +
          Number(crproduct.bhuko_kasor_2) +
          Number(crproduct.bhuko_kasor_3) +
          Number(crproduct.bhuko_kasor_5)) *
        -1;
      crproduct.kasorperbag = kperbag;
      crproduct.bhej = Number(crproduct.bhuko_kasor_4);
    } else if (crproduct.producttypeid === 2) {
      kperbag =
        (Number(crproduct.dakru_kasor_1) +
          Number(crproduct.dakru_kasor_2) +
          Number(crproduct.dakru_kasor_4)) *
        -1;
      crproduct.kasorperbag = kperbag;
      crproduct.bhej = Number(formData.dakru_kasor_3);
    } else if (crproduct.producttypeid === 3) {
      kperbag =
        (Number(crproduct.gariyu_kasor_1) +
          Number(crproduct.gariyu_kasor_2) +
          Number(crproduct.gariyu_kasor_4)) *
        -1;

      crproduct.kasorperbag = kperbag;
      crproduct.bhej = Number(formData.gariyu_kasor_3);
    }

    if (crproduct.netweight > 0) {
      crproduct.incentive =
        (crproduct.netweight / 20) * crproduct.incentiveperkg;
      const t =
        (Number(crproduct?.actualbags) *
          Number(kperbag < 0 ? kperbag * -1 : kperbag)) /
        1000;

      const bhejkasor =
        (crdakruproduct.productweight * Number(crproduct.bhej)) / 100;
      crproduct.minuskasor = t + bhejkasor;

      // console.log(
      //   "KASOR CALCULATION",
      //   (crdakruproduct.productweight * Number(crproduct.bhej)) / 100,
      //   crproduct.minuskasor
      // );
    }
    // console.log("PREVIOUS PRODUCT", _previousProduct);
    // console.log("CURRENT PRODUCT", crproduct);

    let newProduct;
    if (crproduct?.bhuko_kasor_1 > 0) {
      const t = formData.purchasedetails.find((x) => x.producttypeid === 2);
      if (t) {
        newProduct = {
          ...t,
          vehicleweight: crproduct.weight,
          addkasor: (crproduct.bhuko_kasor_1 * crproduct.actualbags) / 1000,
        };
      } else {
        newProduct = {
          ...crproduct,
          actualbags: 0,
          addkasor: (crproduct.bhuko_kasor_1 * crproduct.actualbags) / 1000,
          minuskasor: 0,
          bagweight: 0,
          bhej: 0,
          bhuko_kasor_1: 0,
          bhuko_kasor_2: 0,
          bhuko_kasor_3: 0,
          bhuko_kasor_4: 0,
          bhuko_kasor_5: 0,
          dakru_kasor_1: 0,
          dakru_kasor_2: 0,
          dakru_kasor_3: 0,
          dakru_kasor_4: 0,
          gariyu_kasor_1: 0,
          gariyu_kasor_2: 0,
          gariyu_kasor_3: 0,
          gariyu_kasor_4: 0,
          vehicleweight: crproduct.weight,
          id: -1,
          idx: formData.purchasedetails.length + 1,
          kasorperbag: crproduct.bhuko_kasor_1,
          label: "Dakru",
          netweight: (crproduct.bhuko_kasor_1 * crproduct.actualbags) / 1000,
          noofbags: 0,
          producttype: "Dakru",
          producttypeid: 2,
          totalbagweight: 0,
        };
      }
    }
    // else {
    //   const t1 = [
    //     ...formData.verifiedproduct.filter((x) => x.idx < currentProduct.idx),
    //   ];
    //   console.log(
    //     formData.verifiedproduct,
    //     currentProduct.idx,
    //     formData.verifiedproduct.filter((x) => x.idx < currentProduct.idx)
    //   );
    //   _previousProduct = t1.length > 0 ? t1[t1.length - 1] : null;
    // }
    // console.log("_previousProduct", _previousProduct, formData.verifiedproduct);

    const data = {
      purchasedetails:
        _previousProduct === null
          ? [
              {
                ...crproduct,
                weight:
                  crproduct.weight <= 0
                    ? crproduct.vehicleweight
                    : crproduct.weight,
              },
            ]
          : [
              //_previousProduct,
              {
                ...crproduct,
                weight:
                  crproduct.weight <= 0
                    ? crproduct.vehicleweight
                    : crproduct.weight,
              },
            ],
    };

    if (crproduct?.bhuko_kasor_1 > 0) {
      data.purchasedetails.push({ ...newProduct });
    }

    dispatch(
      farmerpurchaseActions.update_purchase_transaction_admin(data, {
        onSuccess: (res) => {
          toast.success("Bags Updated");
          setCurrentProduct(undefined);
          let id = formData?.id_voucherno;
          onCancelClick();
          _purchase_by_id(formData?.id_voucherno);
        },
      })
    );
  };

  return {
    register,
    error,
    errors,
    column,
    onSubmit,
    formData,
    handleBlur,
    handleSubmit,
    handleChange,
    onDateChange,
    onCancelClick,
    bags_in_stock,
    onFarmerChange,
    farmerYieldData,
    total_bags_stock,
    farmerDropdownList,
    onProductTypeChange,
    estimatedBagsToIssue,
    purchasedetailscolumn,
    bag_issue_list_farmer,
    previous_purchase_columns,
    previous_purchases_by_farmer,
    bags_issued_to_farmer_columns,
    gatepassComponentRef,
    onPrintGatePass,
    onSubmitandPrint,
    fetch_details,
    producttype: keys().producttype,
    previousProduct,
    currentProduct,
    handleCurrentProductDetailsChange,
    onProductTypeChangeIncentiveRole,
    onAdd,
    handleIncentiveChange,
    onproductkasorchange,
    handleChangeUpperCase,
    update_weight,
    onEmptyandPrint,
    handleKeyDown,
    update_weight_admin,
    onAddAdmin,
  };
};
