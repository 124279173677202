import * as yup from "yup";

export const landDetailValidationSchema = yup.object({
  // name: yup.string().trim().required("Configuration Name is required"),
  // farmerId: yup.number().min(1).required("Survey no. is required"),
  // surveynoId: yup.number().min(1).required("Survey no. is required"),
  // selectedFarmer: yup.object({
  //   value: yup.string().required("Please select farmer name"),
  // }),
  // selectedSurveyno: yup.object({
  //   value: yup.string().required("Please select farmer"),
  // }),
  landname: yup.string().required("Land name is required"),
  // landname: yup.string().required("Land name is required"),
  actuallandbigha: yup.string().required("Actual Land is required"),
  // landname: yup.string().required("Land name is required"),
  //plantationlandbigha: yup.string().required("Plantation Land is required"),
});
