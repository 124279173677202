import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { farmerActions } from "../../../redux/actions";

export const useIssueMaterialHooks = () => {
  const dispatch = useDispatch();
  const { farmerDropdownList } = useSelector((obj) => obj);

  const [farmerDropdownData, setFarmerDropdownData] = useState([]); 

  const get_farmerDropdownList = () => dispatch(farmerActions.getFarmerDropdownList());

  useEffect(() => {
    get_farmerDropdownList();
  }, []);

  return {
    farmerDropdownData,
    setFarmerDropdownData,
    farmerDropdownList,
  };
};
