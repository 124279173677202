import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { CloudUpload } from "@mui/icons-material";

const FileToBase64 = ({ onChange }) => {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const { name, type } = file;
      const size = formatBytes(file.size);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({ name, type, size, base64: reader.result });
      reader.onerror = reject;
    });

  const getFiles = async (e) => {
    const result = await toBase64(e.target.files[0]);
    if (onChange) onChange(result);
  };

  return (
    <Button
      component="label"
      variant="contained"
      className="custom-btn-page-layout custom-btn-upload"
      startIcon={<CloudUpload />}
    >
      Upload file
      <VisuallyHiddenInput type="file" onChange={getFiles} />
    </Button>
  );
};

export default FileToBase64;
