// const { ToWords } = require("to-words");
// const formatter = new Intl.NumberFormat("en-IN", {
//   style: "currency",
//   currency: "INR",
// });

import dayjs from "dayjs";

// const formatterWithoutCurrency = new Intl.NumberFormat("en-IN");
// const formatterwithoutDecimal = new Intl.NumberFormat("en-IN", {
//   style: "currency",
//   currency: "INR",
//   maximumFractionDigits: 0,
// });

// const formatterWithoutCurrencyWithDecimal = new Intl.NumberFormat("en-IN", {
//   minimumFractionDigits: 2,
//   maximumFractionDigits: 2,
// });

// const formatterWithoutCurrencyWithoutDecimal = new Intl.NumberFormat("en-IN", {
//   maximumFractionDigits: 0,
// });

// const toWords = new ToWords({
//   localeCode: "en-IN",
//   converterOptions: {
//     currency: true,
//     ignoreDecimal: false,
//     ignoreZeroCurrency: false,
//     doNotAddOnly: false,
//     currencyOptions: {
//       // can be used to override defaults for the selected locale
//       name: "Rupee",
//       plural: "Rupees",
//       symbol: "₹",
//       fractionalUnit: {
//         name: "Paisa",
//         plural: "Paise",
//         symbol: "",
//       },
//     },
//   },
// });

export const keys = {
  // // QA-IP
  // ServicePath: "http://115.124.117.226:5036",
  // ServiceIP: "http://115.124.117.226:5036",
  // authorizedURL: "http://nisolqa.imageio.in",

  // // QA
  // ServicePath: "https://nisolapi.imageio.in",
  // ServiceIP: "http://115.124.117.226:5036",
  // authorizedURL: "https://nisolqa.imageio.in",

  // // LIVE-NISOL
  ServicePath: "https://api.nisolindia.com",
  ServiceIP: "http://103.174.87.68:5036",
  authorizedURL: "https://erp.nisolindia.com",

  // //LOCAL
  // ServicePath: "http://localhost:5036",
  // ServiceIP: "http://localhost:5036",
  // authorizedURL: "http://localhost:3000",

  shortDateFormat: "DD-MM-YYYY",
  longDateFormat: "DD-MMM-YYYY",
  dateTimeFormat: "DD-MMM-YYYY hh:mm A",
  dbDateFormat: "YYYY-MM-DD",
  dbTimeFormat: "YYYY-MM-DD HH:mm",
  timeFormat: "HH:mm",
  shortDate: (value) => dayjs(value).format("DD-MM-YYYY"),
  displayTime: (value) => dayjs(value).format("hh:mm A"),
  producttype: [
    {
      value: 1,
      label: "Bhukho",
      details: [
        {
          id: 1,
          label: `પત્તીમાં  માં ડોખરું `,
          operation: `addsub`,
          value: 0,
          producttypeid: 1,
        },
        {
          id: 2,
          label: `પત્તીમાં માં રાડીયું `,
          operation: `sub`,
          value: 0,
          producttypeid: 1,
        },
        {
          id: 3,
          label: `પત્તીમાં માં માટી `,
          operation: `sub`,
          value: 0,
          producttypeid: 1,
        },
        {
          id: 4,
          label: `પત્તીમાં માં ભેજ  `,
          operation: `per`,
          value: 0,
          producttypeid: 1,
        },
        {
          id: 5,
          label: `Others`,
          operation: `sub`,
          value: 0,
          producttypeid: 1,
        },
      ],
    },
    {
      value: 2,
      label: "Dakru",
      details: [
        {
          id: 1,
          label: `ડોખરું માં રાડીયું`,
          operation: `sub`,
          value: 0,
          producttypeid: 2,
        },
        {
          id: 2,
          label: `ડોખરું માં માટી `,
          operation: `sub`,
          value: 0,
          producttypeid: 2,
        },
        {
          id: 3,
          label: `ડોખરું માં ભેજ `,
          operation: `per`,
          value: 0,
          producttypeid: 2,
        },
        { id: 4, label: `Others`, operation: `sub`, value: 0 },
      ],
    },
    {
      value: 3,
      label: "Gariyo",
      details: [
        {
          id: 1,
          label: `ગારિયામાં ડોખરું`,
          operation: `sub`,
          value: 0,
          producttypeid: 3,
        },
        {
          id: 2,
          label: `ગારિયામાં માં માટી `,
          operation: `sub`,
          value: 0,
          producttypeid: 3,
        },
        {
          id: 3,
          label: `ગારિયામાં માં ભેજ `,
          operation: `per`,
          value: 0,
          producttypeid: 3,
        },
        { id: 4, label: `Others`, operation: `sub`, value: 0 },
      ],
    },
  ],

  // alertServerURL: "https://controltapi.imageio.in",
  // alertServerURL: "https://115.124.123.147:5004",
  // formatter,
  // formatterWithoutCurrency,
  // formatterwithoutDecimal,
  // formatterWithoutCurrencyWithDecimal,
  // formatterWithoutCurrencyWithoutDecimal,
  // toWords,
};
