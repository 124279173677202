import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { roleActions } from "../../redux/actions";
import { Checkbox } from "@mui/material";
import {
  IndeterminateCheckbox,
  SelectColumnFilter,
} from "../../Component/filters";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

export const useRoleHooks = () => {
  const dispatch = useDispatch();
  const [moduleListNew, setModuleListNew] = useState([]);
  const [filteredModuleList, setFilteredModuleList] = useState([]);
  const location = useLocation();
  const [dropDownValues, setDropDownValues] = useState({
    mainmodule: [],
    module: [],
    submodule: [],
  });

  const [selectedValues, setSelectedValues] = useState({
    mainmodule: null,
    module: null,
    submodule: null,
  });

  const get_module_list = () =>
    dispatch(
      roleActions.get_module_lists({
        onSuccess: (res) => {
          setModuleListNew([...res]);
          // setFilteredModuleList([...res]);
          // const mainmodule = [...new Set(res.map((x) => x.mainModuleName))];
          // const module = [...new Set(res.map((x) => x.moduleName))].map((x) => {
          //   let a = res.find((y) => y.moduleName === x);
          //   return {
          //     label: a.moduleName,
          //     mainModuleName: a.mainModuleName,
          //     value: a.moduleName,
          //   };
          // });
          // const submodule = [...new Set(res.map((x) => x.subModuleName))].map(
          //   (x) => {
          //     let a = res.find((y) => y.subModuleName === x);
          //     return {
          //       label: a.subModuleName,
          //       mainModuleName: a.mainModuleName,
          //       modulename: a.moduleName,
          //       value: a.subModuleName,
          //     };
          //   }
          // );
          // setDropDownValues({ mainmodule, module, submodule });
        },
      })
    );

  const get_module_list_by_id = (id) =>
    dispatch(
      roleActions.get_module_list_by_roleid(id, {
        onSuccess: (res) => {
          setModuleListNew([...res]);
        },
      })
    );

  // useEffect(() => {
  //   get_module_list();
  // }, []);

  useEffect(() => {
    console.log(moduleListNew);
  }, [moduleListNew]);

  useEffect(() => {
    console.log(location?.state);
    if (location?.state === null) {
      get_module_list();
    } else if (location?.state !== null) {
      get_module_list_by_id(location?.state?.id);
    }
  }, [location?.state]);

  const onCheckShowinMenuChange = (name, e, props) => {
    const flt = props.filteredFlatRows.map((x) => {
      const s = moduleListNew?.findIndex((item) => item.id === x.id);
      if (s > -1) moduleListNew[s][name] = e.target.checked ? 1 : 0;
    });

    //console.log(moduleListNew);
    const t = [...props.data];
    const t1 = t.map((x) => ({
      ...x,
      [name]: e.target.checked ? 1 : 0,
    }));
    // if (selectedValues.mainmodule !== null) {
    //   console.log(moduleListNew);
    //   const t = moduleListNew.filter(
    //     (x) => x[name] === selectedValues.mainmodule
    //   );
    //   setFilteredModuleList([...t]);
    // }

    setModuleListNew([...t1]);
  };

  const onIndividualChange = (e, row, data) => {
    const t = [...data];
    const s = t.findIndex((x) => x.id === row.id);
    // console.log(s);
    t[s][e.target.name] = e.target.checked ? 1 : 0;

    setModuleListNew([...t]);

    // console.log(t[s], t);
  };

  const getIndeterminateState = (val, data) => {
    console.log(d, data);
    const d = data.data.filter((x) => x[val] === 1);

    if (d.length === data.length) return true;
    return false;
    // else if (data.length < d.length) {
    //   return true;
    // }
    // return false;
  };

  const onChange = (e, row) => {
    console.log(row);
  };
  const column = useMemo(
    () => [
      {
        id: "sr",
        Header: "Sr. No.",
        Cell: (cellProps) => {
          return <span>{cellProps?.row?.index + 1}</span>;
        },
      },
      {
        Header: "Main Module Name",
        accessor: "mainModuleName",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Module",

        accessor: "moduleName",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Pages",
        accessor: "subModuleName",
        Filter: SelectColumnFilter,
      },

      {
        Header: "Show in Menu",
        columns: [
          {
            id: "showinmenu",
            accessor: "showinmenu",
            disableSortBy: true,
            Header: (props) => {
              return (
                // <IndeterminateCheckbox
                //   {...props.getToggleAllRowsSelectedProps()}
                // />
                <Checkbox
                  onChange={(e) =>
                    onCheckShowinMenuChange("showinmenu", e, props)
                  }
                  //   indeterminate={() =>
                  //     getIndeterminateState("showinmenu", props)
                  //   }
                  // checked={false}
                  size="small"
                  color="warning"
                  className="grid_checkbox"
                />
              );
            },
            Cell: ({ row, value, data }) => {
              return (
                <Checkbox
                  name={`showinmenu`}
                  onChange={(e) => onIndividualChange(e, row, data)}
                  size="small"
                  color="warning"
                  className="grid_checkbox"
                  checked={value === 1 ? true : false}
                />
              );
            },
          },
        ],
      },
      {
        Header: "View",
        columns: [
          {
            id: "view",
            accessor: "view",
            Header: (props) => {
              return (
                <Checkbox
                  id="view"
                  onChange={(e) => onCheckShowinMenuChange("view", e, props)}
                  size="small"
                  color="warning"
                  className="grid_checkbox"
                />
              );
            },
            Cell: ({ row, value, data }) => {
              return (
                <Checkbox
                  name={`view`}
                  onChange={(e) => onIndividualChange(e, row, data)}
                  size="small"
                  color="warning"
                  className="grid_checkbox"
                  checked={value === 1 ? true : false}
                />
              );
            },
          },
        ],
      },
      {
        Header: "Create",
        columns: [
          {
            id: "create",
            accessor: "create",
            Header: (props) => {
              return (
                <Checkbox
                  id="create"
                  onChange={(e) => onCheckShowinMenuChange("create", e, props)}
                  size="small"
                  color="warning"
                  className="grid_checkbox"
                />
              );
            },
            Cell: ({ row, value, data }) => {
              return (
                <Checkbox
                  name={`create`}
                  onChange={(e) => onIndividualChange(e, row, data)}
                  size="small"
                  color="warning"
                  className="grid_checkbox"
                  checked={value === 1 ? true : false}
                />
              );
            },
          },
        ],
      },
      {
        Header: "Edit",
        columns: [
          {
            id: "edit",
            accessor: "edit",
            Header: (props) => {
              return (
                <Checkbox
                  id="edit"
                  onChange={(e) => onCheckShowinMenuChange("edit", e, props)}
                  size="small"
                  color="warning"
                  className="grid_checkbox"
                />
              );
            },
            Cell: ({ row, value, data }) => {
              return (
                <Checkbox
                  name={`edit`}
                  onChange={(e) => onIndividualChange(e, row, data)}
                  size="small"
                  color="warning"
                  className="grid_checkbox"
                  checked={value === 1 ? true : false}
                />
              );
            },
          },
        ],
      },
      {
        Header: "Delete",
        columns: [
          {
            id: "delete",
            accessor: "delete",
            Header: (props) => {
              return (
                <Checkbox
                  id="delete"
                  onChange={(e) => onCheckShowinMenuChange("delete", e, props)}
                  size="small"
                  color="warning"
                  className="grid_checkbox"
                />
              );
            },
            Cell: ({ row, value, data }) => {
              return (
                <Checkbox
                  name={`delete`}
                  onChange={(e) => onIndividualChange(e, row, data)}
                  size="small"
                  color="warning"
                  className="grid_checkbox"
                  checked={value === 1 ? true : false}
                />
              );
            },
          },
        ],
      },
    ],
    // eslint-disable-next-line
    []
  );

  const handleSubmit = (e, data) => {
    e.preventDefault();
    const modules = moduleListNew.filter(
      (x) =>
        x.showinmenu === 1 ||
        x.view === 1 ||
        x.create === 1 ||
        x.edit === 1 ||
        x.delete === 1
    );
    if (data?.id > 0) {
      dispatch(
        roleActions.update_role(
          { ...data, modules },
          { onSuccess: (res) => toast.success("Role Updated successfully") }
        )
      );
    } else {
      dispatch(
        roleActions.insert_role(
          { ...data, modules },
          { onSuccess: (res) => toast.success("Role inserted successfully") }
        )
      );
    }
  };

  const onDropDownChange = (name, value) => {
    const t = [...moduleListNew];
    const s = t.filter((x) => x[name] === value);
    setFilteredModuleList([...s]);
  };

  return {
    moduleListNew,
    filteredModuleList,
    onCheckShowinMenuChange,
    onIndividualChange,
    column,
    handleSubmit,
    dropDownValues,
    selectedValues,
    setSelectedValues,
    onDropDownChange,
  };
};
