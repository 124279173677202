import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import { persistStore } from "redux-persist";


import rootReducer_1 from "../redux/reducers";
import { createStore, applyMiddleware, compose } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
// import storage from "redux-persist/lib/storage";
import storage from "redux-persist/lib/storage/session";
// import { configureStore } from "@reduxjs/toolkit";
import {composeWithDevTools }from "redux-devtools-extension"


import rootReducer_2 from "./rootReducer";
import rootSaga from "./sagas/rootSaga";

const rootReducer= {...rootReducer_1,...rootReducer_2}
const middlewares = [];


const persistConfig = {
    key: "root",
    storage,
    blacklist: ["user"],
    whitelist: ["auth", "filterList"],
};


const persistedReducer = persistReducer(persistConfig, rootReducer_1);

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(
//   rootReducer,
//   composeEnhancers(applyMiddleware(thunk))
// );


const store1 = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(thunk))
);

// const store = configureStore({
//     reducer: {
//         ...persistedReducer
//     }
// });


// let persistor1 = persistStore(store1);

// eslint-disable-next-line import/no-anonymous-default-export
// export default { store1, persistor1 };

// Infer the `RootState` and `AppDispatch` types from the store itself
// export RootState = store.getState;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export AppDispatch = store.dispatch;

// export default store;


const sagaMiddleware = createSagaMiddleware();

middlewares.push(sagaMiddleware);
middlewares.push(thunk)

if (process.env.NODE_ENV === "development") {
  // const { createLogger } = require(`redux-logger`);
  // const logger = createLogger({
  //   collapsed: true,
  //   duration: true,
  //   level: "info",
  // });
  //middlewares.push(logger);
}


/*const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === "development",
  middleware: middlewares
  
});*/

const store= createStore(rootReducer_2,
  composeWithDevTools(applyMiddleware(...middlewares)))

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default store;




