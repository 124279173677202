import React, { useEffect, useState } from "react";
import PageLayout from "../../Component/PageLayout";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import useBreakPoints from "../../hooks/useBreakPoints";

const ViewLandRegistration = () => {
  const location = useLocation();
  const { isTablet } = useBreakPoints();

  const [landDetails, setLandDetails] = useState([
    {
      country: "",
      state: "",
      dist: "",
      taluka: "",
      village: "",
      surveyNo: "",
      totalLand: "",
      registeredLand: "",
      vigha: "",
      ownerShipStatus: false,
      landOwnerName: "",
      address: "",
      guardianCountry: "",
      guardianState: "",
      guardianDist: "",
      guardianTaluka: "",
      guardianVillage: "",
      guardianPincode: "",
      guardianName: "",
      tenantDocument: "",
      agreementDocument: "",
      landDocument: "",
    },
  ]);

  useEffect(() => {
    if (location?.state?.landDetails) {
      let arr = [];
      // eslint-disable-next-line
      location?.state?.landDetails?.map((er) => {
        arr.push({
          landDetailsId: er?.id,
          country: er?.country?.name,
          state: er?.state?.stateName,
          dist: er?.district?.districtName,
          taluka: er?.taluka?.talukaName,
          village: er?.village?.villageName,
          surveyNo: er?.surveyNo,
          totalLand: er?.totalLand,
          vigha: er?.vigha,
          registeredLand: er?.registeredLand,
          ownerShipStatus: er?.ownerShipStatus === "True" ? true : false,
          landOwnerName: er?.landOwnerName,
          address: er?.address,
          guardianCountry: er?.guardianCountry?.name,
          guardianState: er?.guardianState?.stateName,
          guardianDist: er?.guardianDistrict?.districtName,
          guardianTaluka: er?.guardianTaluka?.talukaName,
          guardianVillage: er?.guardianVillage?.villageName,
          guardianPincode: er?.guardianPincode,
          guardianName: er?.guardianName,
          tenantDocument: er?.tenantDocument,
          agreementDocument: er?.agreementDocument,
          landDocument: er?.landDocument,
        });
      });
      setLandDetails(arr);
    }
  }, [location?.state?.landDetails]);

  // Accordian Toggle For Multiple land Registration
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <PageLayout
      title={"View Land registration"}
      backBtnTitle={"Back"}
      backBtnTitleLink={"/landregistration"}
    >
      {landDetails?.map((item, index) => (
        <Accordion
          key={index}
          open={open}
          className={index === 0 ? "mt-4" : ""}
          toggle={toggle}
        >
          <fieldset
            className="cus-add-req-sur border-none m-0 p-2"
            style={{ border: "none" }}
          >
            <AccordionItem>
              <AccordionHeader targetId={`${index + 1}`}>
                Land No : {index + 1}
              </AccordionHeader>
              <AccordionBody accordionId={`${index + 1}`}>
                {/* State, Dist and Taluka  */}
                <div>
                  <Row className=" mt-2 g-3">
                    <Col lg={3} md={12} sm={12} className="d-flex ">
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Country
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Input
                          id="exampleEmail"
                          value={item?.country}
                          name="guardianPincode"
                          disabled
                          className="cust-input"
                          type="text"
                        />
                      </Col>
                    </Col>
                    <Col
                      lg={3}
                      md={12}
                      sm={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          State
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Input
                          id="exampleEmail"
                          value={item?.state}
                          name="guardianPincode"
                          disabled
                          className="cust-input"
                          type="text"
                        />
                      </Col>
                    </Col>
                    <Col
                      lg={3}
                      md={12}
                      sm={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Dist
                        </Label>
                      </Col>
                      <Col sm={8} className="d-flex mt-2">
                        <Input
                          id="exampleEmail"
                          value={item?.dist}
                          name="guardianPincode"
                          disabled
                          className="cust-input"
                          type="text"
                        />
                      </Col>
                    </Col>
                    <Col
                      lg={3}
                      md={12}
                      sm={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Taluka
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Input
                          id="exampleEmail"
                          value={item?.taluka}
                          name="guardianPincode"
                          disabled
                          className="cust-input"
                          type="text"
                        />
                      </Col>
                    </Col>
                  </Row>
                </div>

                {/* village,surveyNo and totalLand  */}
                <div>
                  <Row className=" mt-2 g-3">
                    <Col
                      lg={3}
                      md={12}
                      sm={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Village
                        </Label>
                      </Col>
                      <Col md={8}>
                        <Input
                          id="exampleEmail"
                          value={item?.village}
                          name="guardianPincode"
                          disabled
                          className="cust-input"
                          type="text"
                        />
                      </Col>
                    </Col>
                    <Col
                      lg={3}
                      md={12}
                      sm={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Survey No.
                        </Label>
                      </Col>
                      <Col md={8} className="d-flex mt-2">
                        <Input
                          id="exampleEmail"
                          value={item?.surveyNo}
                          name="surveyNo"
                          disabled
                          className="cust-input"
                          type="text"
                        />
                      </Col>
                    </Col>
                    <Col
                      lg={6}
                      md={12}
                      sm={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Col md={4}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Total Land in Document (In Sqm)
                        </Label>
                      </Col>
                      <Col md={8}>
                        <Input
                          id="exampleEmail"
                          value={item?.totalLand}
                          name="totalLand"
                          disabled
                          className="cust-input"
                          type="text"
                        />
                      </Col>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row className=" mt-2 g-3">
                    <Col
                      lg={4}
                      md={12}
                      sm={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Col md={4}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Total Vigha <span className="required_span">*</span>
                        </Label>
                      </Col>
                      <Col md={8}>
                        <Input
                          id="exampleEmail"
                          value={item?.vigha}
                          disabled
                          name="vigha"
                          className="cust-input"
                          type="text"
                        />
                      </Col>
                    </Col>
                    <Col
                      lg={4}
                      md={12}
                      sm={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Col md={7}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Total Registered Land (In Sqm)
                        </Label>
                      </Col>
                      <Col md={5}>
                        <Input
                          id="exampleEmail"
                          value={item?.registeredLand}
                          disabled
                          name="registeredLand"
                          className="cust-input"
                          type="text"
                        />
                      </Col>
                    </Col>
                  </Row>
                </div>

                {/*  Ownership Status */}

                <div
                  style={{}}
                  className={`mt-4 ${
                    isTablet
                      ? ""
                      : "d-flex justify-content-start align-items-center"
                  }`}
                >
                  <Label className="me-4" style={{ fontWeight: 500 }}>
                    Ownership Status :
                  </Label>
                  <div>
                    <Label style={{ fontWeight: 500 }} className="me-2">
                      Is it Rented ?{" "}
                    </Label>
                    <Input
                      id="exampleEmail"
                      checked={item?.ownerShipStatus}
                      name="ownerShipStatus"
                      disabled
                      className="cust-input "
                      type="checkbox"
                    />
                  </div>
                </div>

                {/* LandOwnerName/ FarmerName  and Address  */}
                <div style={{}} className="pb-0">
                  <Row className=" mt-2 g-3">
                    <Col
                      lg={4}
                      md={12}
                      sm={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          {!item?.ownerShipStatus
                            ? "Land Owner Name"
                            : "Farmer Name"}
                        </Label>
                      </Col>
                      <Col md={8}>
                        <Input
                          id="exampleEmail"
                          value={
                            !item?.ownerShipStatus
                              ? item?.landOwnerName
                              : item?.guardianName
                          }
                          name={
                            !item?.ownerShipStatus
                              ? "landOwnerName"
                              : "guardianName"
                          }
                          disabled
                          className="cust-input"
                          type="text"
                        />
                      </Col>
                    </Col>
                    <Col
                      lg={8}
                      md={6}
                      sm={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Col md={1}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Address
                        </Label>
                      </Col>
                      <Col md={9} className="d-flex mt-2">
                        <Input
                          id="exampleEmail"
                          value={item?.address}
                          name="address"
                          disabled
                          className="cust-input"
                          type="text"
                        />
                      </Col>
                    </Col>
                  </Row>
                </div>
                {/* Farmer State  and Farmer Dist  */}
                <div style={{}}>
                  <Row className=" mt-2 g-3">
                    <Col lg={4} md={12} sm={12} className="d-flex ">
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Country
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Input
                          id="exampleEmail"
                          value={item?.guardianCountry}
                          name="guardianPincode"
                          disabled
                          className="cust-input"
                          type="text"
                        />
                      </Col>
                    </Col>
                    <Col
                      lg={4}
                      md={12}
                      sm={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          State
                        </Label>
                      </Col>
                      <Col md={8}>
                        <Input
                          id="exampleEmail"
                          value={item?.guardianState}
                          name="guardianPincode"
                          disabled
                          className="cust-input"
                          type="text"
                        />
                      </Col>
                    </Col>
                    <Col
                      lg={4}
                      md={12}
                      sm={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Dist
                        </Label>
                      </Col>
                      <Col md={8} className="d-flex mt-2">
                        <Input
                          id="exampleEmail"
                          value={item?.guardianDist}
                          name="guardianPincode"
                          disabled
                          className="cust-input"
                          type="text"
                        />
                      </Col>
                    </Col>
                  </Row>
                </div>

                {/* Farmer Village, Farmer Taluka   and pincode  */}
                <div style={{}}>
                  <Row className=" mt-2 g-3">
                    <Col
                      lg={4}
                      md={12}
                      sm={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Taluka
                        </Label>
                      </Col>
                      <Col md={8} className="d-flex mt-2">
                        <Input
                          id="exampleEmail"
                          value={item?.guardianTaluka}
                          name="guardianPincode"
                          disabled
                          className="cust-input"
                          type="text"
                        />
                      </Col>
                    </Col>
                    <Col
                      lg={4}
                      md={12}
                      sm={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Village
                        </Label>
                      </Col>
                      <Col md={8}>
                        <Input
                          id="exampleEmail"
                          value={item?.guardianVillage}
                          name="guardianPincode"
                          disabled
                          className="cust-input"
                          type="text"
                        />
                      </Col>
                    </Col>

                    <Col
                      lg={4}
                      md={12}
                      sm={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Col md={3}>
                        <Label
                          className="me-3"
                          style={{ fontWeight: 500 }}
                          for="exampleEmail"
                          md={12}
                        >
                          Pincode
                        </Label>
                      </Col>
                      <Col md={8} className="d-flex mt-2">
                        <Input
                          id="exampleEmail"
                          value={item?.guardianPincode}
                          name="guardianPincode"
                          disabled
                          className="cust-input"
                          type="number"
                          maxLength={6}
                        />
                      </Col>
                    </Col>
                  </Row>
                </div>
                {/* Upload Document  */}
                <div style={{}}>
                  <Row className=" mt-2 g-3">
                    <Col
                      lg={4}
                      md={12}
                      sm={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <span
                        className="mx-3"
                        style={{ fontWeight: 500, fontSize: 10 }}
                      >
                        {item?.landDocument
                          ? item?.landDocument?.name
                          : " Upload Agreement between owner and Tenant. (PDF/image)"}
                      </span>
                    </Col>

                    <Col
                      lg={4}
                      md={12}
                      sm={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      {item?.ownerShipStatus && (
                        <>
                          <span
                            className="mx-3"
                            style={{ fontWeight: 500, fontSize: 10 }}
                          >
                            {item?.agreementDocument
                              ? item?.agreementDocument?.name
                              : " Option to upload copy of 7/12 (PDF/image)"}
                          </span>
                        </>
                      )}
                    </Col>
                    <Col
                      lg={4}
                      md={12}
                      sm={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      {item?.ownerShipStatus && (
                        <>
                          <span
                            className="mx-3"
                            style={{ fontWeight: 500, fontSize: 10 }}
                          >
                            {item?.tenantDocument
                              ? item?.tenantDocument?.name
                              : "Photo of Tenant"}
                          </span>
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
              </AccordionBody>
            </AccordionItem>
          </fieldset>
        </Accordion>
      ))}
    </PageLayout>
  );
};

export default ViewLandRegistration;
