import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../../redux/actions";
import { useEffect, useState } from "react";
import { brown, green } from "@mui/material/colors";
import Highcharts, { chart } from "highcharts";

const initalValue = {
  famercount_pie: {},
  farmercount_column: {},
  bags_issued: [],
  yield_estimated: [],
  average_yield: {},
  variety_wise_land_size_pie: {},
  village_wise_variety_plantation: {},
  variety_wise_yield_estimation: {},
};

export const useDashboardHooks = () => {
  const dispatch = useDispatch();
  const { dashboard } = useSelector((obj) => obj);
  const [user, setUser] = useState(null);

  const [dashboardWidget, setDashboardWidget] = useState(initalValue);
  const [famercount_pie, setfamercount_pie] = useState(
    initalValue.famercount_pie
  );
  const [farmercount_column, setfarmercount_column] = useState(
    initalValue.farmercount_column
  );
  const [average_yield, setaverage_yield] = useState(initalValue.average_yield);
  const [variety_wise_land_size_pie, setvariety_wise_land_size_pie] = useState(
    initalValue.variety_wise_land_size_pie
  );
  const [variety_wise_yield_estimation, setvariety_wise_yield_estimation] =
    useState(initalValue.variety_wise_yield_estimation);
  const [village_wise_variety_plantation, setvillage_wise_variety_plantation] =
    useState(initalValue.village_wise_variety_plantation);

  const farmer_count_widget = () =>
    dispatch(dashboardActions.get_farmers_village_taluka_wise());

  const dashboard_widgets = () => {
    dispatch(dashboardActions.get_bags_issued());
    dispatch(dashboardActions.get_yield_estimated());
    dispatch(dashboardActions.get_average_yield_estimated());
    dispatch(dashboardActions.get_variety_wise_land_size());
    // dispatch(dashboardActions.get_village_wise_variety());
    dispatch(dashboardActions.get_variety_wise_yield_estimation());
  };

  useEffect(() => {
    farmer_count_widget();
    dashboard_widgets();
    const s = localStorage.getItem("newtoken");
    setUser(JSON.parse(s));
  }, []);

  useEffect(() => {
    if (dashboard?.district_farmer_count) {
      farmercount_widget(dashboard?.district_farmer_count);
      farmercount_village();
      // village_wise_variety();
    }

    // setDashboardWidget((prev) => ({
    //   ...prev,
    //   ...dashboard,
    // }));
  }, [dashboard?.district_farmer_count]);

  const farmercount_widget = () => {
    const chartOption = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: true,
        type: "pie",
        height: 250,
        options3d: {
          enabled: false,
          alpha: 45,
          beta: 0,
        },
      },
      title: {
        text: "District wise Farmer(s) Count ",
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat:
          "Farmer(s) Reg.: <b>{point.y}</b> <br>Percentage: <b>{point.percentage:.1f}%</b>",
      },
      exporting: {
        enabled: true,
      },
      plotOptions: {
        series: {
          borderRadius: 0,
          dataLabels: [
            {
              enabled: true,
              distance: 15,
              format: "{point.name}",
              style: {
                fontSize: "0.5em",
                textOutline: "none",
                "text-decoration": "none",
              },
            },
            {
              enabled: true,
              distance: "-30%",
              format: "{point.y}",
              style: {
                fontSize: "0.7em",
                color: "#fff",
                textOutline: "none",
              },
            },
          ],
        },
      },
      series: [
        {
          name: "Farmer(s)",
          colorByPoint: true,
          data: [...dashboard?.district_farmer_count],
        },
      ],
      drilldown: {
        series: [
          ...dashboard?.district_farmer_count.map((x) => {
            return {
              name: x.name,
              id: x.name,
              data: dashboard?.taluka_farmer_count.filter(
                (y) => y.districtName === x.name
              ),
            };
          }),
        ],
      },
    };

    setfamercount_pie(chartOption);
    // setDashboardWidget((prev) => ({
    //   ...prev,
    //   famercount_pie: chartOption,
    // }));
  };

  const farmercount_village = () => {
    const chartOption = {
      chart: {
        type: "column",
        height: 250,
        options3d: {
          enabled: false,
          alpha: 15,
          beta: 15,
          depth: 50,
          viewDistance: 25,
        },
      },
      title: {
        align: "left",
        text: "Registered Farmer(s) Taluka/Village wise",
      },
      subtitle: {
        align: "left",
        text: "Click the columns to view village wise counts",
      },
      xAxis: {
        type: "category",
      },
      yAxis: {
        title: {
          text: "Total Registered Farmer Count & Per.",
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderRadius: 0,
          borderWidth: 0,

          dataLabels: {
            enabled: true,
            format: "{point.y}",
          },
        },
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>',
      },

      series: [
        {
          name: "Farmer(s)",
          colorByPoint: true,
          data: [...dashboard?.taluka_farmer_count],
        },
      ],
      drilldown: {
        breadcrumbs: {
          position: {
            align: "right",
          },
        },
        series: [
          ...dashboard?.taluka_farmer_count.map((x) => {
            return {
              name: x.name,
              id: x.name,
              data: dashboard?.village_farmer_count.filter(
                (y) => y.talukaName === x.name
              ),
            };
          }),
        ],
      },
    };
    setfarmercount_column(chartOption);
    // setDashboardWidget((prev) => ({
    //   ...prev,
    //   farmercount_column: chartOption,
    // }));
  };

  useEffect(() => {
    if (dashboard?.average_yield_estimation.length > 0) {
      averageYield();
    }
  }, [dashboard?.average_yield_estimation]);

  const averageYield = () => {
    let from = 0,
      to = 0;
    const gaugeOptions = {
      chart: {
        type: "solidgauge",
        height: 250,
      },
      title: { text: "Average yield per bigha" },
      pane: {
        center: ["50%", "85%"],
        size: "140%",
        startAngle: -90,
        endAngle: 90,
        background: {
          // backgroundColor:
          //   Highcharts.defaultOptions.legend.backgroundColor || "#EEE",
          innerRadius: "60%",
          outerRadius: "100%",
          shape: "arc",
        },
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      yAxis: {
        stops: [
          [0.1, "#DF5353"],
          [0.2, "#FFAC1C"],
          [0.3, "#DDDF0D"],
          [0.4, "#55BF3B"],
          [0.5, brown[900]],
          [0.6, brown[900]],
          [0.7, green[400]],
          [0.8, green[700]],
          [0.9, green[900]],
        ],
        // stops: [
        //   { val: 0.9, color: "#55BF3B" },
        //   { val: 0.5, color: "#DDDF0D" },
        //   { val: 0.3, color: "#FFAC1C" },
        //   { val: 0.1, color: "#DF5353" },
        // ].map((x) => {
        //   let val = dashboard?.average_yield_estimation[0]["land"];
        //   let to = val - (val * x.val) / 100;

        //   return [to, x.color];
        // }),

        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
          y: -70,
        },
        labels: {
          y: 16,
        },
        min: 0,
        max: dashboard?.average_yield_estimation[0]["land"],
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            //y: 5,
            borderWidth: 0,
            useHTML: true,
          },
        },
      },
      // yAxis: {
      //   min: 0,
      //   max: dashboard?.average_yield_estimation[0]["land"],
      //   title: {
      //     text: "",
      //   },
      // },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "Yield",
          // data: [dashboard?.average_yield_estimation[0]["perbighayield"]],
          data: [25],
          dataLabels: {
            format:
              '<div style="text-align:center">' +
              '<span style="font-size:25px">{y}</span><br/>' +
              '<span style="font-size:12px;opacity:0.4">kg/bigha</span>' +
              "</div>",
          },
          tooltip: {
            valueSuffix: " kg/bigha",
          },
        },
      ],
    };
    setaverage_yield(gaugeOptions);
    // setDashboardWidget((prev) => ({
    //   ...prev,
    //   average_yield: gaugeOptions,
    // }));
  };

  const variety_land_size = () => {
    const chartOption = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: true,
        type: "pie",
        height: 250,
        options3d: {
          enabled: false,
          alpha: 45,
          beta: 0,
        },
      },
      title: {
        text: "Land Size (Variety)",
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat:
          "Land size: <b>{point.y}</b> <br>Percentage: <b>{point.percentage:.1f}%</b>",
      },
      exporting: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            distance: 10,
            format: `<span style="font-size: 1.2em"> <b>{point.name} </b></span><br>
               <span style="opacity: 0.6;font-size: 1em">{point.percentage:.1f}% ({point.y} bigha) </span>`,
            // connectorColor: "rgba(128,128,128,0.5)",
          },
        },
      },
      series: [
        {
          name: "Variety",
          colorByPoint: true,
          data: [...dashboard?.variety_wise_land_size],
        },
      ],
    };
    setvariety_wise_land_size_pie(chartOption);
    // setDashboardWidget((prev) => ({
    //   ...prev,
    //   variety_wise_land_size_pie: chartOption,
    // }));
  };

  useEffect(() => {
    if (dashboard?.variety_wise_land_size.length > 0) {
      variety_land_size();
    }
  }, [dashboard?.variety_wise_land_size]);

  useEffect(() => {
    if (dashboard?.variety_wise_yield_estimation.length > 0) {
      variety_wise_yield();
    }
  }, [dashboard?.variety_wise_yield_estimation]);

  const village_wise_variety = () => {
    const colors = Highcharts.getOptions().colors;
    const chartOption = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: true,
        type: "pie",
        height: 250,
        options3d: {
          enabled: false,
          alpha: 45,
          beta: 0,
        },
      },
      title: {
        text: "Village wise Variety plantation",
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: `<span style="font-size:1em">Land size:<b>{point.y}</b> <br>Percentage: <b>{point.percentage:.1f}%</b> </span>  `,
      },
      exporting: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            distance: 10,
            format: `<span style="font-size: 1.2em"> <b>{point.name} </b></span><br>
               <span style="opacity: 0.6;font-size: 1.1em">{point.percentage:.1f}% <br>({point.y} bigha) </span>`,
            // connectorColor: "rgba(128,128,128,0.5)",
          },
        },
      },
      series: [
        {
          name: "Village",
          colorByPoint: true,
          data: [...dashboard?.variety_wise_village],
        },
      ],
      drilldown: {
        series: [...dashboard?.variety_wise_village_drill],
      },
    };

    setvillage_wise_variety_plantation(chartOption);
    // setDashboardWidget((prev) => ({
    //   ...prev,
    //   village_wise_variety_plantation: chartOption,
    // }));
  };

  const variety_wise_yield = () => {
    const product = [
      ...new Set(
        dashboard?.variety_wise_yield_estimation.map((x) => x.productname)
      ),
    ];
    const yields = [
      ...new Set(dashboard?.variety_wise_yield_estimation.map((x) => x.yield)),
    ];

    const series = yields.map((x) => {
      return {
        name: x,
        data: dashboard?.variety_wise_yield_estimation
          .filter((y) => y.yield === x)
          .map((z) => z.actual),
      };
    });

    const chartOption = {
      chart: {
        type: "column",
        height: 250,
        options3d: {
          enabled: false,
          alpha: 15,
          beta: 15,
          depth: 50,
          viewDistance: 25,
        },
      },
      title: {
        align: "left",
        text: "Variety Yeild Estimation .",
      },
      // subtitle: {
      //   align: "left",
      //   text: "Click the columns to view village wise counts",
      // },
      xAxis: {
        categories: product,
        crosshair: true,
        accessibility: {
          description: "Countries",
        },
      },
      yAxis: {
        title: {
          text: "Yield Estimations",
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderRadius: 0,
          borderWidth: 0,

          dataLabels: {
            enabled: true,
            format: "{point.y}",
          },
        },
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>',
      },
      series,
    };
    setvariety_wise_yield_estimation(chartOption);
    // setDashboardWidget((prev) => ({
    //   ...prev,
    //   variety_wise_yield_estimation: chartOption,
    // }));
  };

  return {
    dashboard,
    dashboardWidget: {
      ...dashboardWidget,
      famercount_pie,
      farmercount_column,
      average_yield,
      variety_wise_land_size_pie,
      variety_wise_yield_estimation,
      village_wise_variety_plantation,
    },
    user,
  };
};
