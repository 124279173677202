import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageLayout from "../../Component/PageLayout";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import TableContainer from "../../Component/TableContainer";
import CustomPagination from "../../Component/CustomPagination";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import useBreakPoints from "../../hooks/useBreakPoints";
import { Col, Form, Row } from "reactstrap";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const Heading = [
  {
    farmerId: "Farmer ID",
    farmerCode: "Farmer Code",
    farmerName: "Farmer Name",
    mobileNo: "Mobile No",
    state: "State",
    district: "Dist",
    taluka: "Taluka",
    village: "Village",
    dob: "DOB",
    gender: "Gender",
    TabacumGT5: "Tabacum - GT5",
    TabacumGT7: "Tabacum - GT7",
    RUSTICAGC1: "RUSTICA - GC1",
    RUSTICAGCT3: "RUSTICA - GCT3",
    RUSTICADCT4: "RUSTICA - DCT4",
    totalVighaRegistered: "Total Vigha registered",
    surveyNo: "Survey No.",
    bankName: "Bank Name",
    accountNo: "Account No",
    ifscCode: "IFSC Code",
    branchName: "Branch Name",
    PPEBillNo: "PPE Bill No",
    PPEBillAmount: "PPE Bill  Amount",
    BioFertilizerNo: "Bio Fertilizer Bill No",
    BioFertilizerAmount: "Bio Fertilizer Amount",
    totalAmount: "Total Amount",
  },
];

// const wscols = [
//   { wch: Math.max(...customers.map(customer => customer.firstName.length)) },
//   { wch: Math.max(...customers.map(customer => customer.lastName.length)) },
//   { wch: Math.max(...customers.map(customer => customer.email.length)) },
//   { wch: Math.max(...customers.map(customer => customer.address.length)) },
//   {
//     wch: Math.max(...customers.map(customer => customer.postcode.length)) + 3
//   }
// ];

const FarmerReport = () => {
  // eslint-disable-next-line
  const { permissions, token } = useSelector((state) => state.layout);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isTablet } = useBreakPoints();
  const [loading, setLoading] = useState(false);
  const [farmerList, setFarmerList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10); // default limit
  const [offset, setOffset] = useState(0); // default offset

  // Get Farmer List
  const getFarmerListAPi = useCallback(async (limit, offset) => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/farmerdatareport?limit=${limit}&offset=${offset}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        setFarmerList(_data?.farmerDataList);
        setTotalCount(_data?.paginationDetails?.count);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getFarmerListAPi(limit, offset);
    
    // eslint-disable-next-line
  }, [getFarmerListAPi]);

  const column = useMemo(
    () => [
      {
        Header: "Farmer ID",
        accessor: "farmerId",
        filterable: false,
      },
      {
        Header: "Farmer Code",
        accessor: "farmerCode",
        filterable: false,
      },
      {
        Header: "Farmer Name",
        accessor: "name",
        filterable: false,
      },
      {
        Header: "Mobile No",
        accessor: "mobileNo",
        filterable: false,
      },
      {
        Header: "State",
        accessor: "stateDetails.stateName",
        filterable: false,
      },
      {
        Header: "Dist",
        accessor: "districtDetails.districtName",
        filterable: false,
      },
      {
        Header: "Taluka",
        accessor: "talukaDetails.talukaName",
        filterable: false,
      },
      {
        Header: "Village",
        accessor: "villageDetails.villageName",
        filterable: false,
      },
      {
        Header: "DOB",
        accessor: "dob",
        filterable: false,
      },
      {
        Header: "Gender",
        accessor: "gender",
        filterable: false,
      },
      {
        id: "TabacumGT5",
        Header: "Tabacum - GT5",
        Cell: (cellprops) => {
          return (
            <span>
              {cellprops?.row?.original?.farmingProductDetails?.productName ===
              "TABACCUM GT5"
                ? cellprops?.row?.original?.vigha
                : ""}
            </span>
          );
        },
      },
      {
        id: "TabacumGT7",
        Header: "Tabacum - GT7",
        Cell: (cellprops) => {
          return (
            <span>
              {cellprops?.row?.original?.farmingProductDetails?.productName ===
              "TABACCUM GT7"
                ? cellprops?.row?.original?.vigha
                : ""}
            </span>
          );
        },
      },
      {
        id: "RUSTICAGC1",
        Header: "RUSTICA - GC1",
        Cell: (cellprops) => {
          return (
            <span>
              {cellprops?.row?.original?.farmingProductDetails?.productName ===
              "RUSTICA GC1"
                ? cellprops?.row?.original?.vigha
                : ""}
            </span>
          );
        },
      },
      {
        id: "RUSTICAGCT3",
        Header: "RUSTICA - GCT3",
        Cell: (cellprops) => {
          return (
            <span>
              {cellprops?.row?.original?.farmingProductDetails?.productName ===
              "RUSTICA GCT3"
                ? cellprops?.row?.original?.vigha
                : ""}
            </span>
          );
        },
      },
      {
        id: "RUSTICADCT4",
        Header: "RUSTICA - DCT4",
        Cell: (cellprops) => {
          return (
            <span>
              {cellprops?.row?.original?.farmingProductDetails?.productName ===
              "RUSTICA DCT4"
                ? cellprops?.row?.original?.vigha
                : ""}
            </span>
          );
        },
      },
      {
        Header: "Total Vigha registered",
        accessor: "vigha",
        filterable: false,
      },
      {
        Header: "Survey No.",
        Cell: (cellprops) => {
          return (
            <span>{cellprops?.row?.original?.landDetails?.join(",")}</span>
          );
        },
      },
      {
        id: "bank Name",
        Header: "Bank Name",
        Cell: (cellprops) => {
          return (
            <span>{cellprops?.row?.original?.bankDetails?.[0]?.bankName}</span>
          );
        },
      },
      {
        id: "AccountNo",
        Header: "Account No",
        Cell: (cellprops) => {
          return (
            <span>{cellprops?.row?.original?.bankDetails?.[0]?.accountNo}</span>
          );
        },
      },
      {
        id: "IfscCode",
        Header: "IFSC Code",
        Cell: (cellprops) => {
          return (
            <span>{cellprops?.row?.original?.bankDetails?.[0]?.ifscCode}</span>
          );
        },
      },
      {
        id: "BranchName",
        Header: "Branch Name",
        Cell: (cellprops) => {
          return (
            <span>
              {cellprops?.row?.original?.bankDetails?.[0]?.branchName}
            </span>
          );
        },
      },
      {
        id: "PPEBill",
        Header: "PPE Bill No",
        Cell: (cellprops) => {
          let data = cellprops?.row?.original?.kitDetails?.find(
            (er) => er?.productName === "PPE KIT"
          );
          console.log("data: ", data);
          return <span>{data?.invoiceNo}</span>;
        },
      },
      {
        id: "PPEBillAmount",
        Header: "PPE Bill  Amount",
        Cell: (cellprops) => {
          let data = cellprops?.row?.original?.kitDetails?.find(
            (er) => er?.productName === "PPE KIT"
          );
          console.log("data: ", data);
          return <span>{data?.amount}</span>;
        },
      },
      {
        id: "BioFertilizer",
        Header: "Bio Fertilizer Bill No",
        Cell: (cellprops) => {
          let data = cellprops?.row?.original?.kitDetails?.find(
            (er) => er?.productName === "Bio Fertilizer"
          );
          console.log("data: ", data);
          return <span>{data?.invoiceNo}</span>;
        },
      },
      {
        id: "BioFertilizerAmount",
        Header: "Bio Fertilizer Amount",
        Cell: (cellprops) => {
          let data = cellprops?.row?.original?.kitDetails?.find(
            (er) => er?.productName === "Bio Fertilizer"
          );
          console.log("data: ", data);
          return <span>{data?.amount}</span>;
        },
      },
      {
        id: "TotalAmount",
        Header: "Total Amount",
        Cell: (cellprops) => {
          let data = cellprops?.row?.original?.kitDetails?.find(
            (er) => er?.productName === "Bio Fertilizer"
          );
          let PpeData = cellprops?.row?.original?.kitDetails?.find(
            (er) => er?.productName === "PPE KIT"
          );

          let totalAmount =
            parseInt(data?.amount || 0) + parseInt(PpeData?.amount || 0);
          return <span>{totalAmount}</span>;
        },
      },

      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  const getExcel = async () => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/farmerreportexport`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
      }

      if (_data?.status) {
        let arr = [];

        // eslint-disable-next-line
        _data?.farmerDataList?.map((er) => {
          let obj = {
            farmerId: er?.farmerId,
            farmerCode: er?.farmerCode,
            farmerName: er?.name,
            mobileNo: er?.mobileNo,
            state: er?.stateDetails.stateName,
            district: er?.districtDetails.districtName,
            taluka: er?.talukaDetails.talukaName,
            village: er?.villageDetails.villageName,
            dob: er?.dob,
            gender: er?.gender,
            totalVighaRegistered: er?.vigha,
            surveyNo: er?.landDetails?.join(","),
            bankName: er?.bankDetails?.[0]?.bankName,
            accountNo: er?.bankDetails?.[0]?.accountNo,
            ifscCode: er?.bankDetails?.[0]?.ifscCode,
            branchName: er?.bankDetails?.[0]?.branchName,
          };

          if (er?.farmingProductDetails?.productName === "TABACCUM GT5") {
            obj.TabacumGT5 = er?.vigha;
          } else {
            obj.TabacumGT5 = "";
          }

          if (er?.farmingProductDetails?.productName === "TABACCUM GT7") {
            obj.TabacumGT7 = er?.vigha;
          } else {
            obj.TabacumGT7 = "";
          }

          if (er?.farmingProductDetails?.productName === "RUSTICA GC1") {
            obj.RUSTICAGC1 = er?.vigha;
          } else {
            obj.RUSTICAGC1 = "";
          }

          if (er?.farmingProductDetails?.productName === "RUSTICA GCT3") {
            obj.RUSTICAGCT3 = er?.vigha;
          } else {
            obj.RUSTICAGCT3 = "";
          }

          if (er?.farmingProductDetails?.productName === "RUSTICA DCT4") {
            obj.RUSTICADCT4 = er?.vigha;
          } else {
            obj.RUSTICADCT4 = "";
          }

          let Ppedata = er?.kitDetails?.find(
            (er) => er?.productName === "PPE KIT"
          );

          let BioFertdata = er?.kitDetails?.find(
            (er) => er?.productName === "Bio Fertilizer"
          );

          if (Ppedata) {
            obj.PPEBillNo = Ppedata?.invoiceNo;
            obj.PPEBillAmount = Ppedata?.amount;
          } else {
            obj.PPEBillNo = "";
            obj.PPEBillAmount = "";
          }
          if (BioFertdata) {
            obj.BioFertilizerNo = BioFertdata?.invoiceNo;
            obj.BioFertilizerAmount = BioFertdata?.amount;
          } else {
            obj.BioFertilizerNo = "";
            obj.BioFertilizerAmount = "";
          }

          obj.totalAmount =
            parseInt(Ppedata?.amount || 0) + parseInt(BioFertdata?.amount || 0);

          arr.push(obj);

          console.log("er =========================> ", Object.keys(er));
        });

        console.log("arr =========================> ", arr);

        const ws = XLSX.utils.json_to_sheet(Heading, {
          header: [
            "farmerId",
            "farmerCode",
            "farmerName",
            "mobileNo",
            "state",
            "district",
            "taluka",
            "village",
            "dob",
            "gender",
            "totalVighaRegistered",
            "surveyNo",
            "bankName",
            "accountNo",
            "ifscCode",
            "branchName",
            "TabacumGT5",
            "TabacumGT7",
            "RUSTICAGC1",
            "RUSTICAGCT3",
            "RUSTICADCT4",
            "PPEBillNo",
            "PPEBillAmount",
            "BioFertilizerNo",
            "BioFertilizerAmount",
            "totalAmount",
          ],
          skipHeader: true,
          origin: 0, //ok
        });
        // ws["!cols"] = wscols;
        XLSX.utils.sheet_add_json(ws, arr, {
          header: [
            "farmerId",
            "farmerCode",
            "farmerName",
            "mobileNo",
            "state",
            "district",
            "taluka",
            "village",
            "dob",
            "gender",
            "totalVighaRegistered",
            "surveyNo",
            "bankName",
            "accountNo",
            "ifscCode",
            "branchName",
            "TabacumGT5",
            "TabacumGT7",
            "RUSTICAGC1",
            "RUSTICAGCT3",
            "RUSTICADCT4",
            "PPEBillNo",
            "PPEBillAmount",
            "BioFertilizerNo",
            "BioFertilizerAmount",
            "totalAmount",
          ],
          skipHeader: true,
          origin: -1, //ok
        });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "Farmer_Report" + fileExtension);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <PageLayout title={"Farmer Report"} loading={loading}>
      {/* <Form>
        <Row className="mt-4 g-2" style={{ margin: "10px" }}>
          <Col lg={3} md={4} sm={12}>
            <div
              className={`d-flex ${
                isTablet ? "justify-content-center" : "justify-content-end"
              }`}
            >
              <button
                className="custom-btn-page-layout btn text-white mb-2 "
                onClick={() => {
                  getExcel();
                }}
              >
                Export
              </button>
            </div>
            <TableContainer
              columns={column}
              data={farmerList || []}
              isGlobalFilter={false}
              isAddUserList={false}
              tableClass={"table-striped rounded"}
              divClass={"table-responsive"}
              thClass={"reportClass"}
            />
            <CustomPagination
              apiCallFunction={getFarmerListAPi}
              rowDetails={farmerList}
              totalCount={totalCount}
              limit={limit}
              offset={offset}
              setLimit={setLimit}
              setOffset={setOffset}
            />
          </Col>
        </Row>
      </Form> */}
      {loading ? (
        ""
      ) : (
        <div
          className="d-grid"
          style={{ margin: "20px", border: "1px solid #DEE2E6" }}
        >
          <TableContainer
            columns={column}
            data={farmerList || []}
            isGlobalFilter={false}
            isAddUserList={false}
            tableClass={"table-sm"}
            divClass={"table-responsive"}
          />
          {/* <CustomPagination
            apiCallFunction={getProductListAPi}
            rowDetails={productList}
            totalCount={totalCount}
            limit={limit}
            offset={offset}
            setLimit={setLimit}
            setOffset={setOffset}
          /> */}
        </div>
      )}
    </PageLayout>
  );
};

export default FarmerReport;
