/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PageLayout from "../../Component/PageLayout";
import { Col, Row } from "reactstrap";
import TableContainer from "../../Component/TableContainer";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Component/DeleteModal";
import { DesignSurvey, Print } from "..";
import {
  useFarmerSurveyListHooks,
  useInvoiceListHooks,
} from "./useFarmerSurveyListHooks";
import { Box } from "@mui/material";
import { Survey } from "survey-react-ui";
import { Model } from "survey-core";
import CustomModal from "../../Component/CustomModal";

const FarmerSurveyList = () => {
  const navigate = useNavigate();
  const {
    farmer_survey_list,
    loading,
    column,
    onGridDelete,
    setDeleteModal,
    deleteModal,
    deleteLoading,
    viewData,
    openModal,
    setOpenModal,
  } = useFarmerSurveyListHooks();

  return (
    <PageLayout
      title={"Farmer's Survey List"}
      loading={loading}
      // addBtn={permission?.create ? true : false}
      addBtn={true}
      backBtnTitle={`Add New Farmer Survey`}
      onClick={() => navigate(`/farmersurvey`)}
    >
      <Row>
        <Col md={12}>
          <div
            className="d-grid"
            style={{ margin: "10px 10px", border: "1px solid #DEE2E6" }}
          >
            {farmer_survey_list && (
              <TableContainer
                columns={column}
                data={farmer_survey_list}
                isGlobalFilter={true}
                showColumnFilters={true}
                isAddUserList={false}
                tableClass={"table-sm"}
                showPagination={true}
                initialState={{ pageSize: 20, pageIndex: 0 }}
                divClass={"table-responsive"}
                exportfileName="Bags Issue List"
              />
            )}
          </div>
        </Col>
        {/* <Col md={12}>
          <Box sx={{ padding: 4 }}>
            <DesignSurvey
              jsonObj={{ ...viewData }}
              options={{ showDesignerTab: false }}
              onClose={() => setOpenModal(false)}
            />
            <Survey model={new Model(viewData)} />
          </Box>
        </Col> */}
      </Row>

      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={onGridDelete}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
      <CustomModal
        title={viewData?.farmer}
        modalState={openModal}
        onCloseModal={() => setOpenModal(false)}
        customBtn={false}
        showButton={false}
      >
        <Survey model={viewData?.surveymodel} />
      </CustomModal>
    </PageLayout>
  );
};

export default FarmerSurveyList;
