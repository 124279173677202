import React, { useState } from "react";
import { Label, Input, Row, Col, FormGroup } from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../Component/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import Select from "react-select";
import { underArray } from "../../hooks/StaticData";
import useBreakPoints from "../../hooks/useBreakPoints";
const EditProductTypeMaster = ({
  editModal,
  onCloseModal,
  onSubmitModal,
  singleData,
}) => {
  const { token } = useSelector((state) => state.layout);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useBreakPoints();
  const [loading, setLoading] = useState(false);

  const [inputData, setInputData] = useState({
    name: singleData?.typeName,
    under: underArray?.find((er) => er?.value === singleData?.under),
  });

  const [inputDataError, setInputDataError] = useState({
    name: false,
    under: false,
  });

  const handleChangeInput = (name, value) => {
    if (name === "name") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, name: false });
      } else {
        setInputDataError({ ...inputDataError, name: true });
      }
    } else if (name === "under") {
      setInputData({ ...inputData, [name]: value });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, under: false });
      } else {
        setInputDataError({ ...inputDataError, under: true });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputData.name) {
      toast.error("Type Name is Required!");
      setInputDataError({ ...inputDataError, name: true });
    } else if (!inputData.under) {
      toast.error("Under is Required!");
      setInputDataError({ ...inputDataError, under: true });
    } else {
      setLoading(true);
      let formData = new FormData();

      formData.append("typeName", inputData.name);
      formData.append("name", inputData.under.value);
      formData.append("id", singleData.id);

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/edititemtype`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setLoading(false);
          setInputData({
            name: "",
            under: "",
          });
          onSubmitModal();
        } else {
          setLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  return (
    <CustomModal
      loading={loading}
      title={"Edit Product Type "}
      handleSubmit={handleSubmit}
      modalState={editModal}
      onCloseModal={onCloseModal}
      editState={true}
    >
      <FormGroup>
        <Row className="" style={{ margin: 20 }}>
          <Col
            lg={12}
            md={12}
            sm={12}
            className={`d-flex g-3  ${isMobile ? "flex-column" : ""}`}
          >
            <Col md={3}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Type Name <span className="required_span"> * </span>
              </Label>
            </Col>
            <Col sm={9} className={`${isMobile ? "" : "pl-5"}`}>
              <Input
                id="exampleEmail"
                name="name"
                value={inputData?.name}
                onChange={(e) => {
                  handleChangeInput(
                    e.target.name,
                    e.target.value.toUpperCase()
                  );
                }}
                className={`cust-input ${
                  inputDataError?.name ? "border-danger" : ""
                } `}
                type="text"
              />
            </Col>
          </Col>
          <Col
            lg={12}
            md={12}
            sm={12}
            className={`d-flex mx-0 g-3  ${isMobile ? "flex-column" : ""}`}
          >
            <Col md={3}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Under <span className="required_span"> * </span>
              </Label>
            </Col>
            <Col sm={9}>
              <Select
                isMulti={false}
                onChange={(e) => {
                  handleChangeInput("under", e);
                }}
                value={inputData?.under}
                options={underArray}
                // menuPlacement="top"
                name="choices-single-default"
                id="countryAddCompany"
                className={`w-100 ${
                  inputDataError?.under ? "border-danger" : ""
                } `}
              ></Select>
            </Col>
          </Col>
        </Row>
      </FormGroup>
    </CustomModal>
  );
};

export default EditProductTypeMaster;
