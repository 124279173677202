import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { incentiveSchemeUpdateValidationSchema } from "./validationSchema";
import dayjs from "dayjs";
import { keys } from "../../../config/keys";
import { farmerActions, incentiveActions } from "../../../redux/actions";

const initialValues = {
  id: 0,
  voucherno: "",
  applicabledatestring: dayjs(Date()).format(keys().dbDateFormat),
  applicabledate: dayjs(Date()),
  farmerid: 0,
  farmerCode: "",
  selectedfarmer: null,
  incentiveconfigid: 0,
  selectedincentive: null,
  isfarmerphoto: 0,
  farmerphoto: null,
  isusedkitphoto: 0,
  usedkitphoto: null,
  isinvoicecopy: 0,
  invoicecopy: null,
  rate: 0,
};

export const useIncentiveSchemeUpdateHooks = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialValues);
  const [rowData, setRowData] = useState([]);

  const {
    farmerDropdownList,
    incentiveDropdownList,
    farmerincentiveDetailList,
  } = useSelector((obj) => obj);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(incentiveSchemeUpdateValidationSchema),
  });

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onfarmerdropdownchange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      farmerid: selectedOption?.id || 0,
      farmerCode: selectedOption?.farmerCode || "",
      selectedfarmer: selectedOption || null,
    }));
  };

  const onincentivedropdownchange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      incentiveconfigid: selectedOption?.id || 0,
      selectedincentive: selectedOption || null,
      isfarmerphoto: selectedOption?.photo || 0,
      isusedkitphoto: selectedOption?.returnUsedKit || 0,
      isinvoicecopy: selectedOption?.invoiceCopy || 0,
      rate: selectedOption.rate,
    }));
  };

  const onDateChange = (dateValue) => {
    setFormData((prev) => ({
      ...prev,
      applicabledate: dateValue,
      applicabledatestring: dayjs(dateValue).format("YYYY-MM-DD"),
    }));
  };

  const onFarmerPhotoChange = (files) => {
    setFormData((prev) => ({ ...prev, farmerphoto: files }));
  };

  const onUsedKitPhotoChange = (files) => {
    setFormData((prev) => ({ ...prev, usedkitphoto: files }));
  };

  const onInvoiceCopyChange = (files) => {
    setFormData((prev) => ({ ...prev, invoicecopy: files }));
  };

  const resetFormData = () => {
    setFormData((prev) => ({
      ...prev,
      id: 0,
      applicabledatestring: dayjs(Date()).format(keys().dbDateFormat),
      applicabledate: dayjs(Date()),
      farmerid: 0,
      farmerCode: "",
      selectedfarmer: null,
      incentiveconfigid: 0,
      selectedincentive: null,
      isfarmerphoto: 0,
      farmerphoto: null,
      isusedkitphoto: 0,
      usedkitphoto: null,
      isinvoicecopy: 0,
      invoicecopy: null,
    }));
    reset(initialValues);
  };

  const onSubmit = () => {
     console.log("formData:", formData);
    let flagErr = 0;
    if (!(formData?.farmerid > 0)) {
      flagErr = 1;
      toast.error("Please select farmer.");
    } else if (!(formData?.incentiveconfigid > 0)) {
      flagErr = 1;
      toast.error("Please select incentive scheme.");
    } else if (!(formData?.rate > 0)) {
      flagErr = 1;
      toast.error("Please enter rate greator than zero.");
    } else if (formData.isfarmerphoto === 1 && formData.farmerphoto === null) {
      flagErr = 1;
      toast.error("Please upload farmer photo.");
    } else if (
      formData.isusedkitphoto === 1 &&
      formData.usedkitphoto === null
    ) {
      flagErr = 1;
      toast.error("Please upload used kit photo.");
    } else if (formData.isinvoicecopy === 1 && formData.invoicecopy === null) {
      flagErr = 1;
      toast.error("Please upload invoice copy.");
    }

    if (flagErr === 0) {
      let isDuplicate = false;
      if (formData.id && formData.id > 0) {
        const tmpUpdateData = rowData.filter(
          (itm) =>
            itm.farmerid === formData.farmerid &&
            itm.id !== formData.id &&
            itm.incentiveconfigid === formData.incentiveconfigid
        );
        if (tmpUpdateData.length > 0) {
          isDuplicate = true;
        }
      } else {
        const tmpSaveData = rowData.filter(
          (itm) =>
            itm.farmerid === formData.farmerid &&
            itm.incentiveconfigid === formData.incentiveconfigid
        );
        if (tmpSaveData.length > 0) {
          isDuplicate = true;
        }
      }

      if (isDuplicate) {
        toast.error("Scheme name for selected farmer already exist.");
      } else {
        dispatch(
          incentiveActions.saveFarmerIncentiveDetails(formData, {
            onSuccess: () => {
              setFormData(initialValues);
              resetFormData();
              get_farmerincentiveDetailList();
              toast.success(
                formData.id && formData.id > 0
                  ? "Data Updated Successfully."
                  : "Data Saved Successfully."
              );
            },
            onError: (err) => {
              console.log(err);
              toast.error("Something went wrong. Please try again.");
            },
          })
        );
      }
    }
  };

  const onGridEdit = (data) => {
    // console.log("data:", data);
    const tmpSelectedFarmer = farmerDropdownList.filter(
      (itm) => itm.id === data.farmerid
    );
    const tmpSelectedincentive = incentiveDropdownList.filter(
      (itm) => itm.id === data.incentiveconfigid
    );
    const tmpData = {
      ...data,
      selectedfarmer:
        tmpSelectedFarmer.length > 0 ? tmpSelectedFarmer[0] : null,
      selectedincentive:
        tmpSelectedincentive.length > 0 ? tmpSelectedincentive[0] : null,
      applicabledate: dayjs(data.applicabledate),
      applicabledatestring: dayjs(data.applicabledate).format("YYYY-MM-DD"),
    };

    setFormData(tmpData);
    reset(tmpData);
  };

  const onGridDelete = (data) => {
    // console.log("data:", data);
    dispatch(
      incentiveActions.deleteFarmerIncentiveDetails(
        { id: data.id },
        {
          onSuccess: () => {
            get_farmerincentiveDetailList();
            toast.success("Data Deleted Successfully.");
          },
          onError: (err) =>
            toast.error("Something went wrong. Please try again."),
        }
      )
    );
  };

  const get_farmerDropdownList = () =>
    dispatch(farmerActions.getFarmerDropdownList());
  const get_incentiveDropdownList = () =>
    dispatch(incentiveActions.getIncentiveDropdownList());
  const get_farmerincentiveDetailList = () =>
    dispatch(incentiveActions.getFarmerIncentiveDetailList());

  useEffect(() => {
    get_farmerDropdownList();
    get_incentiveDropdownList();
    get_farmerincentiveDetailList();
  }, []);

  useEffect(() => {
    setRowData(farmerincentiveDetailList);
  }, [farmerincentiveDetailList]);

  return {
    rowData,
    formData,
    setFormData,
    handleChange,
    initialValues,
    register,
    handleSubmit,
    errors,
    resetFormData,
    control,
    onSubmit,
    onDateChange,
    onfarmerdropdownchange,
    onincentivedropdownchange,
    onFarmerPhotoChange,
    onUsedKitPhotoChange,
    onInvoiceCopyChange,
    onGridEdit,
    onGridDelete,
    farmerDropdownList,
    incentiveDropdownList,
  };
};
