// import { Actions } from ".";
import * as types from "../constTypes";

const vendorlist = [];

const vendor_dropdown_list = [];

export const vendors = (state = vendorlist, action) => {
  switch (action.type) {
    case types.vendorTypes.VENDOR_LIST:
      return action.payload || [];
    default:
      return state;
  }
};

export const vendor_dropdown = (state = vendor_dropdown_list, action) => {
  switch (action.type) {
    case types.vendorTypes.VENDOR_DROPDOWN:
      return action.payload;
    default:
      return state;
  }
};
