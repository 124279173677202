import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { publishingValidationSchema } from "./validationScheme";
import { yupResolver } from "@hookform/resolvers/yup";
import { settingsAction } from "../../redux/actions";
import { usePermissionHooks } from "../../common/useCheckPermission";
import { GridAction } from "../../common/useGridAction";
import { toast } from "react-toastify";
import { keys } from "../../config/keys";
import dayjs from "dayjs";

const initialValues = {
  id: 0,
  title: "",
  description: "",
  eventdatestring: dayjs(Date()).format(keys().dbDateFormat),
  eventdate: dayjs(Date()),
  eventtimestring: dayjs(Date()).format(keys().dbTimeFormat),
  eventtime: dayjs(Date()),
  content: "",
};

export const usePublishingHooks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialValues);
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    // setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(publishingValidationSchema),
  });

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleEditorChange = (editor) =>
    setFormData((prev) => ({
      ...prev,
      content: editor.getData(),
    }));

  const onFileChange = (result) => {
    console.log(result);
    setFormData((prev) => ({
      ...prev,
      file: result,
      documentsize: result?.size,
      documenttype: result?.type,
    }));
  };

  const onSubmit = () => {
    dispatch(
      settingsAction.save_publishing_documents(formData, {
        onSuccess: () => {
          setFormData((prev) => ({
            ...prev,
            ...initialValues,
          }));
          navigate("/publishedlist");
        },
      })
    );
  };

  useEffect(() => {
    if (location?.state?.id > 0) {
      setFormData((prev) => ({ ...prev, ...location?.state?.data }));
    }
  }, [location?.state?.id]);

  return {
    formData,
    setFormData,
    handleChange,
    initialValues,
    register,
    handleSubmit,
    onSubmit,
    handleEditorChange,
    onFileChange,
    errors,
    control,
  };
};

export const usePublishedListHooks = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const permission = usePermissionHooks(location);
  const { published_documents } = useSelector((obj) => obj);

  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const column = useMemo(
    () => [
      {
        id: "sr",
        Header: "Sr. No.",
        Cell: (cellProps) => {
          return <span>{cellProps?.row?.index + 1}</span>;
        },
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Published On",
        accessor: "createddatetime",
        Cell: ({ value }) => keys().shortDate(value),
      },
      {
        ...GridAction({
          permission,
          onEditClick: (cellProps) => {
            navigate(`/publishing`, {
              state: {
                data: cellProps.row.original,
                id: cellProps.row.original.id,
              },
            });
          },
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original.id);
          },
        }),
      },
    ],
    // eslint-disable-next-line
    []
  );

  const onGridDelete = async () => {
    setDeleteLoading(true);
    if (deleteId > 0) {
      dispatch(
        settingsAction.delete_published_documents(
          { id: deleteId },
          {
            onSuccess: () => {
              toast.success("Document Deleted Successfully!!!");
              _get_published_documents();
            },
            onError: (err) =>
              toast.error("Something went wrong. Please try again."),
          }
        )
      );
      setDeleteLoading(false);
      setDeleteModal(false);
    }
  };

  const _get_published_documents = () =>
    dispatch(settingsAction.get_published_documents());

  useEffect(() => {
    _get_published_documents();
  }, []);

  return {
    published_documents,
    column,
    permission,
    deleteModal,
    onGridDelete,
    deleteLoading,
    setDeleteModal,
  };
};
