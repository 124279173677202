import React, { useCallback, useEffect, useState } from "react";
import { Label, Input, Row, Col, FormGroup } from "reactstrap";
import { toast } from "react-toastify";
import CustomModal from "../../../Component/CustomModal";
import Select from "react-select";
import Flatpickr from "react-flatpickr";

import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../../store/reducers/layoutReducer";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import useBreakPoints from "../../../hooks/useBreakPoints";
import CustomAutoComplete from "../../../Component/CustomAutoComplete";
import { TextField } from "@mui/material";
const UnblockSurveyNo = ({
  editModal,
  onCloseModal,
  onSubmitModal,
  singleData,
}) => {
  console.log("singleData: ", singleData);
  const [loading, setLoading] = useState(false);
  const { isMobile } = useBreakPoints();
  const [submitLoading, setSubmitLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginDetails, token } = useSelector((state) => state.layout);

  const [inputData, setInputData] = useState({
    stateId: singleData?.stateDetails?.stateName,
    districtId: singleData?.districtDetails?.districtName,
    talukaId: singleData?.talukaDetails?.talukaName,
    villageId: singleData?.villageDetails?.villageName,
    surveyNo: singleData?.surveyNo,
    isLandBlocked: singleData?.isLandBlocked,
    remark: singleData?.remark,
  });
  console.log("inputData: ", inputData);

  const [inputDataError, setInputDataError] = useState({
    stateId: false,
    districtId: false,
    talukaId: false,
    villageId: false,
    surveyNo: false,
    isLandBlocked: false,
    remark: false,
  });

  const handleChangeInput = (name, value) => {
    setInputData({ ...inputData, [name]: value });
    if (value?.length > 0) {
      setInputDataError({ ...inputDataError, [name]: false });
    } else {
      setInputDataError({ ...inputDataError, [name]: true });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
     if (!inputData.remark) {
      toast.error("Remark is Required!");
      setInputDataError({ ...inputDataError, remark: true });
    } else {
      setSubmitLoading(true);
      let formData = new FormData();

      formData.append(
        "isLandBlocked",
        inputData?.isLandBlocked === true ? "True" : "False"
      );
      formData.append("remark", inputData.remark);
      formData.append("id", singleData?.id);

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/unblocksurveyno`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setSubmitLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setSubmitLoading(false);

          onSubmitModal();
        } else {
          setSubmitLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setSubmitLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  return (
    <CustomModal
      loading={submitLoading}
      title={"Unblock Survey No"}
      handleSubmit={handleSubmit}
      modalState={editModal}
      onCloseModal={onCloseModal}
      editState={true}
    >
      {loading ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <ClipLoader color="#75D100" size={50} />
        </div>
      ) : (
        <form>
          <div>
            <Row className=" g-3 mt-2 ">
              <Col lg={12} md={12} sm={12} className="d-flex">
                <TextField
                  id="outlined-basic"
                  label="State Name *"
                  fullWidth
                  name="stateId"
                  size="small"
                  value={inputData?.stateId}
                  error={inputDataError?.stateId}
                  disabled
                  variant="outlined"
                />
              </Col>
              <Col lg={12} md={12} sm={12} className="d-flex">
                <TextField
                  id="outlined-basic"
                  label="District Name *"
                  fullWidth
                  name="districtId"
                  size="small"
                  value={inputData?.districtId}
                  error={inputDataError?.districtId}
                  disabled
                  variant="outlined"
                />
              </Col>
              <Col lg={12} md={12} sm={12} className="d-flex">
                <TextField
                  id="outlined-basic"
                  label="Taluka Name *"
                  fullWidth
                  name="talukaId"
                  size="small"
                  value={inputData?.talukaId}
                  error={inputDataError?.talukaId}
                  disabled
                  variant="outlined"
                />
              </Col>
              <Col lg={12} md={12} sm={12} className="d-flex">
                <TextField
                  id="outlined-basic"
                  label="Village Name *"
                  fullWidth
                  name="villageId"
                  size="small"
                  disabled
                  value={inputData?.villageId}
                  error={inputDataError?.villageId}
                  variant="outlined"
                />
              </Col>
              <Col lg={12} md={12} sm={12} className="d-flex">
                <TextField
                  id="outlined-basic"
                  label="Survey No *"
                  type="number"
                  fullWidth
                  name="surveyNo"
                  size="small"
                  value={inputData?.surveyNo}
                  error={inputDataError?.surveyNo}
                  disabled
                  variant="outlined"
                />
              </Col>
              <Col lg={12} md={12} sm={12} className="d-flex">
                <Col md={3}>
                  <Label
                    className="me-2"
                    style={{
                      fontWeight: 600,
                      fontSize: 14,
                    }}
                    for="exampleEmail"
                    md={12}
                  >
                    Block
                  </Label>
                </Col>
                <Col md={3} className="align-items-center d-flex">
                  <FormGroup switch>
                    <Input
                      type="switch"
                      style={{ height: 24, width: 44 }}
                      checked={inputData?.isLandBlocked}
                      name="isLandBlocked"
                      onChange={(e) => {
                        handleChangeInput(e.target.name, e.target.checked);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Col>
              <Col lg={12} md={12} sm={12} className="d-flex">
                <TextField
                  id="outlined-basic"
                  type="text"
                  size="small"
                  label="Remark *"
                  name="remark"
                  fullWidth
                  value={inputData?.remark}
                  error={inputDataError?.remark}
                  onChange={(e) => {
                    handleChangeInput(e.target.name, e.target.value);
                  }}
                  variant="outlined"
                />
              </Col>
            </Row>
          </div>
        </form>
      )}
    </CustomModal>
  );
};

export default UnblockSurveyNo;
