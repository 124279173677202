/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PageLayout from "../../Component/PageLayout";
import { Col, Row } from "reactstrap";
import TableContainer from "../../Component/TableContainer";
import { useNavigate } from "react-router-dom";
import { useFarmerPurchaseListHooks } from "./useFarmerPurchaseHooks";
import DeleteModal from "../../Component/DeleteModal";
import { Print } from "..";
import { Button } from "@mui/material";

const FarmerPurchaseList = () => {
  const navigate = useNavigate();
  const g = JSON.parse(localStorage.getItem("newtoken"));
  const {
    farmer_purchase_list,
    loading,
    column,
    onGridDelete,
    setDeleteModal,
    deleteModal,
    deleteLoading,
    permission,
    showprint,
    printData,
    setShowPrint,
    printType,
    exportTally,
  } = useFarmerPurchaseListHooks();

  return (
    <PageLayout
      title={"Farmer's Purchase List"}
      loading={loading}
      addBtn={permission?.create ? true : false}
      backBtnTitle={`Add Purchase`}
      onClick={() =>
        navigate(
          g.isnorthgujarat ? "/farmerpurchases/addng" : "/farmerpurchases/add"
        )
      }
    >
      <Row>
        <Col md={showprint ? 6 : 12}>
          <div
            className="d-grid"
            style={{ margin: "10px 10px", border: "1px solid #DEE2E6" }}
          >
            <TableContainer
              columns={column}
              data={farmer_purchase_list}
              isGlobalFilter={true}
              showColumnFilters={true}
              isAddUserList={false}
              tableClass={"table-sm"}
              showPagination={true}
              initialState={{ pageSize: 20, pageIndex: 0 }}
              divClass={"table-responsive"}
              exportfileName="purchase list"
              exports={{
                excel: true,
                pdf: true,
                tally: true,
                onTallyClick: () => exportTally(),
              }}
            />
          </div>
        </Col>

        {showprint && (
          <Col md={6}>
            <Print
              data={printData}
              onPrint={() => setShowPrint(false)}
              bill={!printType.gatepass}
              gatepass={printType.gatepass}
              onCancel={() => setShowPrint(false)}
            />
          </Col>
        )}
      </Row>
      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={onGridDelete}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
      {/* <button type="button" onClick={exportTally} className="custom-login-btn">
        Export in Tally
      </button> */}
    </PageLayout>
  );
};

export default FarmerPurchaseList;
