import AllRoutes from "./AllRoutes";
import "./App.css";

// import External Css File
import "./assests/css/Custom.css";

// import Toast Message
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App vh-100">
      {/* Toast Message Link  */}
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* <Layout> */}
      <AllRoutes />
      {/* </Layout> */}
    </div>
  );
}

export default App;
