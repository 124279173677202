import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageLayout from "../../Component/PageLayout";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import TableContainer from "../../Component/TableContainer";
import CustomPagination from "../../Component/CustomPagination";
import DeleteModal from "../../Component/DeleteModal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import useBreakPoints from "../../hooks/useBreakPoints";

const CompanyList = () => {
  const { permissions, token } = useSelector((state) => state.layout);
  const { isMobile } = useBreakPoints();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let permissionsObj = permissions?.find((er) => er?.path === "/companylist");

  let initialState = {};

  if (
    !permissionsObj?.edit &&
    !permissionsObj?.delete &&
    !permissionsObj?.print
  ) {
    initialState.hiddenColumns = ["Action"];
  }

  const [companyList, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(5); // default limit
  const [offset, setOffset] = useState(0); // default offset
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  // Get company List
  const getCompanyListAPi = useCallback(async (limit, offset) => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}company/company?limit=${limit}&offset=${offset}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        setCompanyList(_data?.companyList);
        setTotalCount(_data?.paginationDetails?.count);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCompanyListAPi(limit, offset);
    // eslint-disable-next-line
  }, [getCompanyListAPi]);

  // handleDelete Btn
  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    let formData = new FormData();
    formData.append("id", deleteId);
    formData.append("isDelete", "Yes");

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}company/deletecompany`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        getCompanyListAPi(limit, offset);
        setDeleteModal(false);
        toast?.success(_data?.message);
        setDeleteLoading(false);
      } else {
        setDeleteLoading(false);
        setDeleteModal(false);

        toast.error(_data?.message);
      }
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
    }
  };

  const column = useMemo(
    () => [
      {
        Header: "Sr. No.",
        Cell: (cellProps) => {
          return <span>{cellProps.row.index + 1}</span>;
        },
      },
      {
        Header: "Company",
        accessor: "companyName",
        filterable: false,
      },
      {
        Header: "Code",
        accessor: "companyCode",
        filterable: false,
      },
      {
        id: "Action",
        Header: () => (
          <span
            className="d-flex justify-content-center"
            style={{ textAlign: "center" }}
          >
            Action
          </span>
        ),
        Cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <div className="hstack gap-2">
                {/* Edit Button */}
                {permissionsObj?.edit && (
                  <Link
                    to={"/editcompany"}
                    state={cellProps.row.original}
                    className="border-0"
                    style={{ background: "transparent" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_32_318)">
                        <path
                          d="M6.87549 2.5H2.50049C2.16897 2.5 1.85103 2.6317 1.6166 2.86612C1.38218 3.10054 1.25049 3.41848 1.25049 3.75V12.5C1.25049 12.8315 1.38218 13.1495 1.6166 13.3839C1.85103 13.6183 2.16897 13.75 2.50049 13.75H11.2505C11.582 13.75 11.9 13.6183 12.1344 13.3839C12.3688 13.1495 12.5005 12.8315 12.5005 12.5V8.125"
                          stroke="#3E7531"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.563 1.56249C11.8116 1.31385 12.1489 1.17416 12.5005 1.17416C12.8521 1.17416 13.1893 1.31385 13.438 1.56249C13.6866 1.81113 13.8263 2.14836 13.8263 2.49999C13.8263 2.85162 13.6866 3.18885 13.438 3.43749L7.50049 9.37499L5.00049 9.99999L5.62549 7.49999L11.563 1.56249Z"
                          stroke="#3E7531"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_32_318">
                          <rect
                            width="15"
                            height="15"
                            fill="white"
                            transform="translate(0.000488281)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </Link>
                )}
                {/* View Button  */}

                {permissionsObj?.print && (
                  <Link
                    to={"/viewcompany"}
                    state={cellProps.row.original}
                    className="border-0"
                    style={{ background: "transparent" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_32_322)">
                        <path
                          d="M3.12549 7.5C3.12549 7.5 4.71611 4.375 7.50049 4.375C10.2842 4.375 11.8755 7.5 11.8755 7.5C11.8755 7.5 10.2842 10.625 7.50049 10.625C4.71611 10.625 3.12549 7.5 3.12549 7.5Z"
                          stroke="#03A8DC"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13.1255 10.625V11.875C13.1255 12.2065 12.9938 12.5245 12.7594 12.7589C12.525 12.9933 12.207 13.125 11.8755 13.125H3.12549C2.79397 13.125 2.47603 12.9933 2.2416 12.7589C2.00718 12.5245 1.87549 12.2065 1.87549 11.875V10.625M13.1255 4.375V3.125C13.1255 2.79348 12.9938 2.47554 12.7594 2.24112C12.525 2.0067 12.207 1.875 11.8755 1.875H3.12549C2.79397 1.875 2.47603 2.0067 2.2416 2.24112C2.00718 2.47554 1.87549 2.79348 1.87549 3.125V4.375M7.50049 8.125C7.66625 8.125 7.82522 8.05915 7.94243 7.94194C8.05964 7.82473 8.12549 7.66576 8.12549 7.5C8.12549 7.33424 8.05964 7.17527 7.94243 7.05806C7.82522 6.94085 7.66625 6.875 7.50049 6.875C7.33473 6.875 7.17576 6.94085 7.05855 7.05806C6.94134 7.17527 6.87549 7.33424 6.87549 7.5C6.87549 7.66576 6.94134 7.82473 7.05855 7.94194C7.17576 8.05915 7.33473 8.125 7.50049 8.125Z"
                          stroke="#03A8DC"
                          strokeWidth="1.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_32_322">
                          <rect
                            width="15"
                            height="15"
                            fill="white"
                            transform="translate(0.000488281)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </Link>
                )}
                {/* Delete Button */}
                {permissionsObj?.delete && (
                  <button
                    className="border-0 m-0 p-0"
                    style={{ background: "transparent" }}
                    onClick={() => {
                      setDeleteModal(true);
                      setDeleteId(cellProps.row.original.id);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_32_330)">
                        <path
                          d="M6.63507 -0.0178833C5.50545 -0.0178833 4.59795 0.92077 4.59795 2.05558V2.30769H2.30814C1.99026 2.30769 1.73122 2.56673 1.73122 2.88462V3.46154H1.1543V4.61539H2.30814V13.2692C2.30814 14.2183 3.08987 15 4.03891 15H10.962C11.911 15 12.6928 14.2183 12.6928 13.2692V4.61539H13.8466V3.46154H13.2697V2.88462C13.2697 2.56673 13.0106 2.30769 12.6928 2.30769H10.403V2.055C10.403 0.920771 9.49545 -0.0178833 8.36584 -0.0178833H6.63507ZM6.63507 1.17173H8.36584C8.82968 1.17173 9.21334 1.55019 9.21334 2.055V2.30769H5.78699V2.055C5.78699 1.55019 6.17064 1.17231 6.63449 1.17231L6.63507 1.17173ZM3.46199 4.61539H6.41872C6.49026 4.62289 6.56122 4.63327 6.63507 4.63327H8.36584C8.43968 4.63327 8.51007 4.62289 8.58218 4.61539H11.5389V13.2692C11.5389 13.594 11.2868 13.8462 10.962 13.8462H4.03891C3.7141 13.8462 3.46199 13.594 3.46199 13.2692V4.61539ZM4.61584 5.76923V12.6923H5.76968V5.76923H4.61584ZM6.92353 5.76923V12.6923H8.07737V5.76923H6.92353ZM9.23122 5.76923V12.6923H10.3851V5.76923H9.23122Z"
                          fill="#EC0505"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_32_330">
                          <rect
                            width="15"
                            height="15"
                            fill="white"
                            transform="translate(0.000488281)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                )}
                {/* Privacy Button */}
                <button
                  className="border-0 m-0 p-0"
                  style={{ background: "transparent" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M7.50049 14.375C4.02736 13.6031 1.25049 10.3263 1.25049 6.875V3.125L7.50049 0.625L13.7505 3.125V6.875C13.7505 10.3275 10.9736 13.6031 7.50049 14.375ZM2.50049 3.75V6.875C2.53621 8.32008 3.04446 9.71365 3.94747 10.8424C4.85049 11.9712 6.09849 12.7729 7.50049 13.125C8.90248 12.7729 10.1505 11.9712 11.0535 10.8424C11.9565 9.71365 12.4648 8.32008 12.5005 6.875V3.75L7.50049 1.875L2.50049 3.75Z"
                      fill="#9F07E7"
                    />
                    <path
                      d="M7.50049 6.875C8.36343 6.875 9.06299 6.17545 9.06299 5.3125C9.06299 4.44956 8.36343 3.75 7.50049 3.75C6.63754 3.75 5.93799 4.44956 5.93799 5.3125C5.93799 6.17545 6.63754 6.875 7.50049 6.875Z"
                      fill="#9F07E7"
                    />
                    <path
                      d="M4.37549 9.375C4.68354 9.93638 5.13523 10.4058 5.68433 10.7353C6.23342 11.0647 6.86019 11.2424 7.50049 11.25C8.14079 11.2424 8.76756 11.0647 9.31665 10.7353C9.86574 10.4058 10.3174 9.93638 10.6255 9.375C10.6099 8.19 8.53674 7.5 7.50049 7.5C6.45861 7.5 4.39111 8.19 4.37549 9.375Z"
                      fill="#9F07E7"
                    />
                  </svg>
                </button>
              </div>
            </div>
          );
        },
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  const onChangeSearch = async (value) => {
    setLoading(true);
    setSearchInput(value);

    let formData = new FormData();

    formData.append("companyCode", value);

    try {
      const apiCall = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }company/companyfilter?limit=${5}&offset=${0}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        setCompanyList(_data?.companyList);
        setTotalCount(_data?.companyList?.count);
      } else {
        setLoading(false);
        toast?.error(_data?.message);
      }
    } catch (error) {
      setLoading(false);
      toast?.error(error);
      console.log(error);
    }
  };
  return (
    <PageLayout title={"company List"} loading={loading}>
      {/* Search Button */}
      <div
        className={`d-flex mt-4 justify-content-between align-items-center ${
          isMobile ? "flex-column gap-3" : ""
        }`}
        style={{ margin: 20 }}
      >
        <div className="">
          <InputGroup className="search-inp">
            <Input
              value={searchInput}
              onChange={(e) => {
                onChangeSearch(e.target.value);
              }}
              placeholder="Search For Company Code"
            />
            <InputGroupText>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M15.1987 13.8333L10.9037 9.53833C11.6977 8.33158 12.0157 6.87314 11.7962 5.44537C11.5766 4.01761 10.8351 2.72208 9.71524 1.80963C8.59536 0.897181 7.17674 0.432709 5.73406 0.506144C4.29138 0.579579 2.92726 1.1857 1.90581 2.20715C0.884365 3.22859 0.278248 4.59271 0.204813 6.03539C0.131379 7.47807 0.595851 8.89669 1.5083 10.0166C2.42075 11.1365 3.71628 11.878 5.14404 12.0975C6.57181 12.317 8.03025 11.999 9.237 11.205L13.532 15.5L15.1987 13.8333ZM1.86534 6.33333C1.86534 4.03584 3.7345 2.16667 6.032 2.16667C8.3295 2.16667 10.1987 4.03584 10.1987 6.33333C10.1987 8.63083 8.3295 10.5 6.032 10.5C3.7345 10.5 1.86534 8.63083 1.86534 6.33333Z"
                  fill="#999999"
                />
              </svg>
            </InputGroupText>
          </InputGroup>
        </div>

        {permissionsObj?.create && (
          <Link
            to={"/addcompany"}
            className="custom-btn-page-layout text-white btn btn-success d-flex justify-content-between align-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              className="me-2"
            >
              <path
                d="M8.00041 2.66668C10.9411 2.66668 13.3337 5.05934 13.3337 8.00001C13.3337 10.9407 10.9411 13.3333 8.00041 13.3333C5.05974 13.3333 2.66707 10.9407 2.66707 8.00001C2.66707 5.05934 5.05974 2.66668 8.00041 2.66668ZM8.00041 1.33334C4.31841 1.33334 1.33374 4.31801 1.33374 8.00001C1.33374 11.682 4.31841 14.6667 8.00041 14.6667C11.6824 14.6667 14.6671 11.682 14.6671 8.00001C14.6671 4.31801 11.6824 1.33334 8.00041 1.33334ZM11.3337 7.33334H8.66707V4.66668H7.33374V7.33334H4.66707V8.66668H7.33374V11.3333H8.66707V8.66668H11.3337V7.33334Z"
                fill="white"
              />
            </svg>
            <span className="ps-2"> ADD NEW COMPANY</span>
          </Link>
        )}
      </div>

      {/* Table View */}
      {loading ? (
        ""
      ) : (
        <div
          className="d-grid"
          style={{ margin: "0 20px", border: "1px solid #DEE2E6" }}
        >
          <TableContainer
            columns={column}
            data={companyList}
            isGlobalFilter={false}
            isAddUserList={false}
            initialState={initialState}
            tableClass={"table-sm"}
            divClass={"table-responsive"}
          />
          <CustomPagination
            apiCallFunction={getCompanyListAPi}
            rowDetails={companyList}
            totalCount={totalCount}
            limit={limit}
            offset={offset}
            setLimit={setLimit}
            setOffset={setOffset}
          />
        </div>
      )}

      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={handleDeleteRequest}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
    </PageLayout>
  );
};

export default CompanyList;
