import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import LoaderComponent from "./LoaderComponent";
import { Button, CircularProgress, styled } from "@mui/material";
import { Add, Check, Close, Update } from "@mui/icons-material";

const CustomModalContainer = styled(Modal)(({ theme, headerColor }) => {
  return {
    "& .modal-content": { borderRadius: "0.2rem" },
    "& .modal-footer": {
      padding: "0.3rem",
    },
    "& .modal-header": {
      padding: "0.8rem",
      borderRadius: 0,
      background: "#000000",
      background: `-webkit-linear-gradient(to right,#000000,${headerColor.color})`,
      background: `linear-gradient(to right,#000000,${headerColor.color})`,
      color: "#fff",
    },
  };
});

const CustomModal = ({
  loading,
  children,
  title,
  modalState,
  onCloseModal,
  handleSubmit,
  editState,
  btnText,
  size,
  customBtn,
  disableButton = false,
  headerColor = "#0f9b0f",
  showButton = true,
}) => {
  return (
    <CustomModalContainer
      id="showModal"
      size={size ? size : "md"}
      isOpen={modalState}
      toggle={() => onCloseModal()}
      centered
      headerColor={{ color: headerColor }}
    >
      {loading && <LoaderComponent />}
      <form onSubmit={(e) => handleSubmit(e)}>
        <ModalHeader
          className="p-3 modal-header-color text-white"
          style={{ textTransform: "uppercase" }}
          toggle={() => onCloseModal()}
        >
          {title}
        </ModalHeader>
        <ModalBody className="p-3">{children}</ModalBody>

        {showButton && (
          <div className="p-3">
            {customBtn ? (
              <div className="hstack gap-2 justify-content-end">
                <Button
                  type={handleSubmit ? "submit" : "button"}
                  className="custom-btn-page-layout custom-btn-success btn text-white mx-3"
                  disabled={loading}
                  startIcon={<Check />}
                >
                  Ok
                </Button>
              </div>
            ) : (
              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="button"
                  className="custom-btn-page-layout custom-btn-grey"
                  onClick={() => {
                    onCloseModal(false);
                  }}
                  startIcon={<Close />}
                >
                  Close
                </Button>

                <Button
                  type="submit"
                  className="custom-btn-page-layout custom-btn-success"
                  disabled={disableButton ? disableButton : loading}
                  startIcon={
                    loading ? (
                      <CircularProgress size={15} color="secondary" />
                    ) : editState ? (
                      <Update />
                    ) : (
                      <Add />
                    )
                  }
                >
                  {editState ? "Update" : btnText ? btnText : "Add"}
                </Button>
              </div>
            )}
          </div>
        )}
      </form>
    </CustomModalContainer>
  );
};
export default CustomModal;
