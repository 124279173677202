import React, { useState } from "react";
import {
  Label,
  Input,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../Component/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";

const EditCountry = ({
  editModal,
  onCloseModal,
  onSubmitModal,
  singleData,
}) => {
  const { token } = useSelector((state) => state.layout);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [inputData, setInputData] = useState({
    countyCode: singleData?.code,
    countryName: singleData?.name,
  });
  const [countryCodeError, setCountryCodeErrorInput] = useState(false);
  const [countryNameError, setCountryNameErrorInput] = useState(false);

  const nonNumericRegex = /^[A-Za-z]+$/;

  const handleChangeInput = (name, value) => {
    if (name === "countryName") {
      if (nonNumericRegex.test(value) || value === "" || value === "") {
        setInputData({ ...inputData, [name]: value });

        if (value?.length > 0) {
          setCountryNameErrorInput(false);
        } else {
          setCountryNameErrorInput(true);

        }
      } else {
        toast.error("Type characters only");
      }
    } else if (name === "countyCode") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length > 0) {
        setCountryCodeErrorInput(false);
      }else {
        setCountryCodeErrorInput(true);

      }
    } else {
      setInputData({ ...inputData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputData.countyCode) {
      toast.error("County Code is Required!");
      setCountryCodeErrorInput(true)
    } else if (!inputData.countryName) {
      toast.error("County Name is Required!");
      setCountryNameErrorInput(true)
    } else {
      setLoading(true);
      let formData = new FormData();

      // formData.append("code", inputData.countyCode);
      formData.append("name", inputData.countryName);
      formData.append("id", singleData.id);

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}admin1/editcountry`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setLoading(false);
          setInputData({
            countyCode: "",
            countryName: "",
          });
          onSubmitModal();
        } else {
          setLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  return (
    <CustomModal
      loading={loading}
      title={"Edit Country"}
      handleSubmit={handleSubmit}
      modalState={editModal}
      onCloseModal={onCloseModal}
      editState={true}
    >
      <FormGroup row>
        <Row className="g-3 country-edit-margin" style={{ margin: 20 }}>
          <Col md={10} className="d-flex country-edit-flex">
            <Col md={4}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Country Code <span className="required_span">*</span>{" "}
              </Label>
            </Col>
            <Col sm={8} className="pl-5">
              <Input
                id="exampleEmail"
                name="countyCode"
                value={inputData?.countyCode}
                disabled
                onChange={(e) => {
                  handleChangeInput(
                    e.target.name,
                    e.target.value.toUpperCase()
                  );
                }}
                className={`cust-input ${countryCodeError ? "border-danger" : ""}`}
                type="text"
              />
            </Col>
          </Col>
        </Row>
        <Row className="g-3 country-edit-margin" style={{ margin: 20 }}>
          <Col md={10} className="d-flex mx-0 country-edit-flex">
            <Col md={4}>
              <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
                Country Name <span className="required_span">*</span>{" "}
              </Label>
            </Col>
            <Col sm={8}>
              <Input
                id="exampleEmail"
                name="countryName"
                value={inputData?.countryName}
                onChange={(e) => {
                  handleChangeInput(
                    e.target.name,
                    e.target.value.toUpperCase()
                  );
                }}
                className={`cust-input ${countryNameError ? "border-danger" : ""}`}
                type="text"
              />
            </Col>
          </Col>
        </Row>
      </FormGroup>
    </CustomModal>
  );
};

export default EditCountry;
