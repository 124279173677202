import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import useBreakPoints from "../hooks/useBreakPoints";
import { toggleSidebar } from "../store/reducers/layoutReducer";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const { sidebarOpen } = useSelector((state) => state.layout);
  const { isMobile } = useBreakPoints();

  // console.log(isMobile, sidebarOpen);

  useEffect(() => {
    if (isMobile) {
      if (sidebarOpen) dispatch(toggleSidebar());
      //  console.log(sidebarOpen ? 307 : isMobile ? 0 : 78);
    } else {
      if (!sidebarOpen) dispatch(toggleSidebar());
    }
  }, [isMobile]);

  return (
    <div className="d-flex vh-100">
      <Sidebar />
      <div
        className="w-100 p-3 pb-0"
        style={{ marginLeft: isMobile ? 0 : sidebarOpen ? 307 : 78 }}
      >
        <Header />
        <Outlet />
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Layout;
