import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { logOutRequest } from "../store/reducers/layoutReducer";
import { useNavigate } from "react-router-dom";

const StateOption = () => {
  const { token } = useSelector((state) => state.layout);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getStateOption = useCallback(async (countryId) => {
    let arr = [];
    let formData = new FormData();

    if (countryId) {
      formData.append("countryId", countryId);
      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}admin1/statebycountry`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },

            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
        }

        if (_data?.status) {
          _data?.stateList?.map((er) => {
            arr.push({ label: er?.stateName, value: er?.id });
            return er;
          });
        } else {
          toast.error(_data?.error);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}admin1/state`,
          {
            method: "Get",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
        }

        if (_data?.status) {
          _data?.stateList?.map((er) => {
            arr.push({ label: er?.stateName, value: er?.id });
            return er;
          });
        } else {
          toast.error(_data?.error);
        }
      } catch (error) {
        console.log(error);
      }
    }
    return arr;
    // eslint-disable-next-line
  }, []);

  return { getStateOption };
};

export default StateOption;
