import React from "react";
import { Col, Row } from "reactstrap";
import PageLayout from "../../Component/PageLayout";
import { useDashboardHooks } from "./useDashboardHooks";
import { Paper, Typography, styled } from "@mui/material";
import HighCharts from "../../common/HighChart";
import { brown, green, grey, red } from "@mui/material/colors";
import { Grain, Grass, ShoppingBag } from "@mui/icons-material";
import { PlantIcon } from "../../Icons";

const PaperWidget = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  padding: theme.spacing(1),
  minHeight: 100,
  height: 100,
}));

const AdminDashboard = () => {
  const { dashboardWidget, dashboard } = useDashboardHooks();
  return (
    <PageLayout>
      <Row className="p-2">
        <Col lg={3} md={3} sm={6}>
          {dashboard?.bags_issued.length && (
            <PaperWidget elevation={4}>
              <div className="d-flex flex-column justify-content-between h-100 ">
                <div className="d-flex flex-row justify-content-between">
                  <div className="d-flex flex-column">
                    <Typography
                      variant="button"
                      color={grey[600]}
                      fontWeight="bold"
                    >{`Bag(s) Issued`}</Typography>
                    <Typography
                      variant="overline"
                      fontWeight={"bolder"}
                      fontSize={"large"}
                      lineHeight="unset"
                      color={grey[900]}
                    >{`${
                      dashboard?.bags_issued.length &&
                      dashboard?.bags_issued[0][0]["bagsissued"]
                    }`}</Typography>
                  </div>
                  <div>
                    <ShoppingBag
                      sx={{ fontSize: 45, opacity: 0.6 }}
                      htmlColor={brown[600]}
                    />
                  </div>
                </div>
                <div className="align-items-end align-self-stretch">
                  <Typography
                    variant="caption"
                    color={green[400]}
                    fontWeight={"bold"}
                  >{`Available Stock:`}</Typography>
                  <Typography variant="caption">{` ${
                    dashboard?.bags_issued.length &&
                    dashboard?.bags_issued[1][0]["stock"]
                  }`}</Typography>
                </div>
              </div>
            </PaperWidget>
          )}
        </Col>
        {dashboard?.yield_estimated.map((x, index) => (
          <Col lg={3} md={3} sm={6} key={x.yield}>
            <PaperWidget elevation={4}>
              <div className="d-flex flex-column justify-content-between h-100 ">
                <div className="d-flex flex-row justify-content-between">
                  <div className="d-flex flex-column">
                    <Typography
                      variant="button"
                      color={grey[600]}
                      fontWeight="bold"
                    >{`Estimated ${x.yield} (Kg.)`}</Typography>
                    <Typography
                      variant="overline"
                      fontWeight={"bolder"}
                      fontSize={"large"}
                      lineHeight="unset"
                      color={grey[900]}
                    >{`${Number(x.actual).toFixed(2)}`}</Typography>
                  </div>
                  <div>
                    {index === 1 && (
                      <Grass sx={{ fontSize: 45 }} htmlColor={green[700]} />
                    )}
                    {index === 2 && (
                      <Grain sx={{ fontSize: 45 }} htmlColor={brown[700]} />
                    )}
                    {index !== 1 && index !== 2 && (
                      <PlantIcon sx={{ fontSize: 40 }} htmlColor={green[700]} />
                    )}
                  </div>
                </div>
                <div className="align-items-end align-self-stretch">
                  <Typography
                    variant="caption"
                    color={red[400]}
                    fontWeight={"bold"}
                  >{`Min.:`}</Typography>
                  <Typography variant="caption">{` ${Number(x.minimum).toFixed(2)}`}</Typography>
                  <Typography
                    variant="caption"
                    color={grey[900]}
                    fontWeight={"bold"}
                  >{` | `}</Typography>
                  <Typography
                    variant="caption"
                    color={green[400]}
                    fontWeight={"bold"}
                  >{`Max.:`}</Typography>
                  <Typography variant="caption">{` ${Number(x.maximum).toFixed(2)}`}</Typography>
                </div>
              </div>
            </PaperWidget>
          </Col>
        ))}
      </Row>
      <Row className="p-2">
        <Col lg={4} md={4} sm={12}>
          <PaperWidget elevation={4} style={{ height: "auto" }}>
            {dashboardWidget?.famercount_pie && (
              <HighCharts options={dashboardWidget?.famercount_pie} />
            )}
          </PaperWidget>
        </Col>
        <Col lg={8} md={8} sm={12}>
          <PaperWidget elevation={4} style={{ height: "auto" }}>
            {dashboardWidget?.farmercount_column && (
              <HighCharts options={dashboardWidget?.farmercount_column} />
            )}
          </PaperWidget>
        </Col>
      </Row>
      <Row className="p-2">
        <Col lg={3} md={3} sm={12}>
          <PaperWidget elevation={4} style={{ height: "auto" }}>
            {dashboardWidget?.average_yield && (
              <HighCharts options={dashboardWidget?.average_yield} />
            )}
          </PaperWidget>
        </Col>

        <Col lg={3} md={3} sm={12}>
          <PaperWidget elevation={4} style={{ height: "auto" }}>
            {dashboardWidget?.variety_wise_land_size_pie && (
              <HighCharts
                options={dashboardWidget?.variety_wise_land_size_pie}
              />
            )}
          </PaperWidget>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <PaperWidget elevation={4} style={{ height: "auto" }}>
            {dashboardWidget?.variety_wise_yield_estimation && (
              <HighCharts
                options={dashboardWidget?.variety_wise_yield_estimation}
              />
            )}
          </PaperWidget>
        </Col>
      </Row>
      {/* <Row className="p-2">
        <Col lg={12} md={12} sm={12}>
          <PaperWidget elevation={4} style={{ height: "auto" }}>
            {dashboardWidget?.village_wise_variety_plantation && (
              <HighCharts
                options={dashboardWidget?.village_wise_variety_plantation}
              />
            )}
          </PaperWidget>
        </Col>
      </Row> */}
    </PageLayout>
  );
};

export default AdminDashboard;
