import {
  Autocomplete,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { Col, Row } from "reactstrap";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { MuiColorInput } from "mui-color-input";
import PageLayout from "../../Component/PageLayout";
import { useBagMaster } from "./useBagMaster";
import Barcode from "react-barcode";
import { indigo } from "@mui/material/colors";
import { useEffect, useRef, useState } from "react";
import { textFieldProps } from "../../common/commonControlProps";
import { Print, Settings } from "@mui/icons-material";
import LoaderComponent from "../../Component/LoaderComponent";

const initialValues = {
  format: "CODE128",
  height: 40,
  width: 2,
  displayValue: true,
  fontOptions: "",
  font: "monospace",
  textAlign: "center",
  textPosition: "bottom",
  textMargin: 2,
  fontSize: 15,
  background: "#ffffff",
  lineColor: "#000000",
  margin: 10,
  marginTop: undefined,
  marginBottom: undefined,
  marginLeft: undefined,
  marginRight: undefined,
};
const PrintBarcode = (props) => {
  const { bags } = useBagMaster();
  const [barcodeOptions, setBarcodeOptions] = useState({ ...initialValues });
  const [loading, setLoading] = useState(true);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (bags.length > 0) setLoading(false);
  }, [bags]);
  return (
    <div style={{ margin: "40px", height: "100vh" }}>
      <div
        class="h6 pb-2 mb-2 border-bottom border-secondary border-2 fw-bold"
        style={{ color: indigo[500] }}
      >
        {`Barcode Settings`}
      </div>
      <Row>
        <Col lg={2} sm={12} md={2}>
          <Autocomplete
            name="productid"
            size="small"
            options={[
              "CODE128",
              "EAN13",
              "CODE39",
              "ITF14",
              "MSI",
              "pharmacode",
              "codabar",
            ]}
            value={barcodeOptions.format}
            onChange={(e, option) => {
              setBarcodeOptions({ ...barcodeOptions, format: option });
            }}
            renderInput={(params) => (
              <TextField {...params} {...textFieldProps} label="Format" />
            )}
          />
        </Col>
        <Col lg={1} sm={12} md={1}>
          <TextField
            name="height"
            onChange={(e) =>
              setBarcodeOptions((prev) => ({ ...prev, height: e.target.value }))
            }
            value={barcodeOptions.height}
            label={`Height`}
            title=""
            {...textFieldProps}
            type="number"
            max={10}
          />
        </Col>
        <Col lg={1} sm={12} md={1}>
          <TextField
            name="width"
            onChange={(e) =>
              setBarcodeOptions({ ...barcodeOptions, width: e.target.value })
            }
            value={barcodeOptions.width}
            label={`Width`}
            title=""
            {...textFieldProps}
            type="number"
            max={10}
          />
        </Col>
        <Col lg={1} sm={12} md={1}>
          <TextField
            value={barcodeOptions.fontSize}
            onChange={(e) =>
              setBarcodeOptions({ ...barcodeOptions, fontSize: e.target.value })
            }
            label="Font Size"
            type="number"
            {...textFieldProps}
          />
        </Col>
        <Col lg={2} sm={12} md={2}>
          <MuiColorInput
            value={barcodeOptions.background}
            format="hex"
            onChange={(color) =>
              setBarcodeOptions({ ...barcodeOptions, background: color })
            }
            label="Background Color"
            {...textFieldProps}
          />
        </Col>
        <Col lg={2} sm={12} md={2}>
          <MuiColorInput
            value={barcodeOptions.lineColor}
            format="hex"
            onChange={(color) =>
              setBarcodeOptions({ ...barcodeOptions, lineColor: color })
            }
            label="Line Color"
            {...textFieldProps}
          />
        </Col>
        <Col lg={1} md={2} sm={12}>
          <FormControlLabel
            className="m-0"
            control={
              <Switch
                color="warning"
                checked={barcodeOptions?.displayValue}
                name="displayValue"
                onChange={(e) => {
                  setBarcodeOptions({
                    ...barcodeOptions,
                    displayValue: e.target.checked,
                  });
                }}
              />
            }
            label={`Display Value`}
            labelPlacement="start"
          />
        </Col>
        <Col lg={1} md={12} sm={12}>
          <Button
            className="custom-btn-page-layout custom-btn-primary"
            variant="contained"
            startIcon={<Print />}
            onClick={handlePrint}
            fullWidth
          >
            {`Print`}
          </Button>
        </Col>
        <Col lg={1} md={12} sm={12}>
          <Button
            className="custom-btn-page-layout custom-btn-warning"
            variant="contained"
            startIcon={<Settings />}
            onClick={() => setBarcodeOptions({ ...initialValues })}
            fullWidth
          >
            {`Default`}
          </Button>
        </Col>
      </Row>
      <div
        class="h6 pb-2 mb-2 border-bottom border-secondary border-2 fw-bold"
        style={{ color: indigo[500] }}
      ></div>
      {loading ? (
        <LoaderComponent />
      ) : (
        <div ref={componentRef}>
          <Grid container spacing={2}>
            {bags.map((x) => (
              <Grid item lg={3} sm={12} md={3}>
                <Paper
                  key={x.barcodetext}
                  style={{
                    textAlign: "center",
                    backgroundColor: barcodeOptions.background,
                  }}
                >
                  <Barcode value={x.barcodetext} {...barcodeOptions} />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </div>
  );
};

export default PrintBarcode;
