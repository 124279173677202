import React, { useEffect, useMemo, useState } from "react";
import PageLayout from "../../Component/PageLayout";
import {
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  InputGroup,
  InputGroupText,
  Form,
} from "reactstrap";
import * as yup from "yup";
import TableContainer from "../../Component/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { incentiveActions } from "../../redux/actions";
import { GridAction } from "../../common/useGridAction";
import { Button, Checkbox, FormControlLabel, Stack, Switch, TextField, Typography } from "@mui/material";
import { textFieldProps } from "../../common/commonControlProps";
import { Cancel, Save } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "../../common/Error";
import { toast } from "react-toastify";
import DeleteModal from "../../Component/DeleteModal";

const initialValues = {
  id: 0,
  schemeTypeName: "",
  priority: 0,
  perkg: false,
  pervigha: true,
};

const IncentiveSchemeTypeList = () => {
  const dispatch = useDispatch();
  const { incentivetypeList } = useSelector(obj => obj);
  const [formData, setFormData] = useState(initialValues);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const column = useMemo(
    () => [

      {
        Header: "Scheme Type Name",
        accessor: "schemeTypeName",
        filterable: false,
      },
      {
        Header: "Priority",
        accessor: "priority",
        filterable: false,
      },
      {
        Header: "Calculate on Vigha",
        accessor: "pervigha",
        filterable: false,
        Cell: (cellProps) =>
          cellProps.row.original.pervigha ? (
            <Checkbox size="small" color="success" checked />
          ) : (
            <Checkbox size="small" color="success" checked={false} />
          )
      },
      {
        Header: "Calculate on KG",
        accessor: "perkg",
        filterable: false,
        Cell: (cellProps) =>
          cellProps.row.original.perkg ? (
            <Checkbox size="small" color="success" checked />
          ) : (
            <Checkbox size="small" color="success" checked={false} />
          )
      },
      {
        ...GridAction({
          permission: { edit: true, delete: true },
          onEditClick: (cellProps) => {
            console.log(cellProps.row.original)
            setFormData({ ...cellProps.row.original })
            // editClick(cellProps);
            // navigate("/issuebag", { state: cellProps.row.original });
          },
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original.id);
          },
        }),
      },
      // {
      //   id: "Action",
      //   Header: () => (
      //     <span
      //       className="d-flex justify-content-center"
      //       style={{ textAlign: "center" }}
      //     >
      //       Action
      //     </span>
      //   ),
      //   Cell: (cellProps) => {
      //     return (
      //       <div className="d-flex justify-content-center align-items-center gap-2">
      //         <div className="hstack gap-2">
      //           {/* Edit Button */}
      //           <button
      //             className="border-0"
      //             style={{ background: "transparent" }}
      //           >
      //             <svg
      //               xmlns="http://www.w3.org/2000/svg"
      //               width="15"
      //               height="15"
      //               viewBox="0 0 15 15"
      //               fill="none"
      //             >
      //               <g clipPath="url(#clip0_32_318)">
      //                 <path
      //                   d="M6.87549 2.5H2.50049C2.16897 2.5 1.85103 2.6317 1.6166 2.86612C1.38218 3.10054 1.25049 3.41848 1.25049 3.75V12.5C1.25049 12.8315 1.38218 13.1495 1.6166 13.3839C1.85103 13.6183 2.16897 13.75 2.50049 13.75H11.2505C11.582 13.75 11.9 13.6183 12.1344 13.3839C12.3688 13.1495 12.5005 12.8315 12.5005 12.5V8.125"
      //                   stroke="#3E7531"
      //                   strokeWidth="2"
      //                   strokeLinecap="round"
      //                   strokeLinejoin="round"
      //                 />
      //                 <path
      //                   d="M11.563 1.56249C11.8116 1.31385 12.1489 1.17416 12.5005 1.17416C12.8521 1.17416 13.1893 1.31385 13.438 1.56249C13.6866 1.81113 13.8263 2.14836 13.8263 2.49999C13.8263 2.85162 13.6866 3.18885 13.438 3.43749L7.50049 9.37499L5.00049 9.99999L5.62549 7.49999L11.563 1.56249Z"
      //                   stroke="#3E7531"
      //                   strokeWidth="2"
      //                   strokeLinecap="round"
      //                   strokeLinejoin="round"
      //                 />
      //               </g>
      //               <defs>
      //                 <clipPath id="clip0_32_318">
      //                   <rect
      //                     width="15"
      //                     height="15"
      //                     fill="white"
      //                     transform="translate(0.000488281)"
      //                   />
      //                 </clipPath>
      //               </defs>
      //             </svg>
      //           </button>

      //           {/* View Button  */}
      //           <button
      //             className="border-0"
      //             style={{ background: "transparent" }}
      //           >
      //             <svg
      //               xmlns="http://www.w3.org/2000/svg"
      //               width="15"
      //               height="15"
      //               viewBox="0 0 15 15"
      //               fill="none"
      //             >
      //               <g clipPath="url(#clip0_32_322)">
      //                 <path
      //                   d="M3.12549 7.5C3.12549 7.5 4.71611 4.375 7.50049 4.375C10.2842 4.375 11.8755 7.5 11.8755 7.5C11.8755 7.5 10.2842 10.625 7.50049 10.625C4.71611 10.625 3.12549 7.5 3.12549 7.5Z"
      //                   stroke="#03A8DC"
      //                   strokeWidth="2"
      //                   strokeLinecap="round"
      //                   strokeLinejoin="round"
      //                 />
      //                 <path
      //                   d="M13.1255 10.625V11.875C13.1255 12.2065 12.9938 12.5245 12.7594 12.7589C12.525 12.9933 12.207 13.125 11.8755 13.125H3.12549C2.79397 13.125 2.47603 12.9933 2.2416 12.7589C2.00718 12.5245 1.87549 12.2065 1.87549 11.875V10.625M13.1255 4.375V3.125C13.1255 2.79348 12.9938 2.47554 12.7594 2.24112C12.525 2.0067 12.207 1.875 11.8755 1.875H3.12549C2.79397 1.875 2.47603 2.0067 2.2416 2.24112C2.00718 2.47554 1.87549 2.79348 1.87549 3.125V4.375M7.50049 8.125C7.66625 8.125 7.82522 8.05915 7.94243 7.94194C8.05964 7.82473 8.12549 7.66576 8.12549 7.5C8.12549 7.33424 8.05964 7.17527 7.94243 7.05806C7.82522 6.94085 7.66625 6.875 7.50049 6.875C7.33473 6.875 7.17576 6.94085 7.05855 7.05806C6.94134 7.17527 6.87549 7.33424 6.87549 7.5C6.87549 7.66576 6.94134 7.82473 7.05855 7.94194C7.17576 8.05915 7.33473 8.125 7.50049 8.125Z"
      //                   stroke="#03A8DC"
      //                   strokeWidth="1.8"
      //                   strokeLinecap="round"
      //                   strokeLinejoin="round"
      //                 />
      //               </g>
      //               <defs>
      //                 <clipPath id="clip0_32_322">
      //                   <rect
      //                     width="15"
      //                     height="15"
      //                     fill="white"
      //                     transform="translate(0.000488281)"
      //                   />
      //                 </clipPath>
      //               </defs>
      //             </svg>
      //           </button>
      //           {/* Delete Button */}
      //           <button
      //             className="border-0"
      //             style={{ background: "transparent" }}
      //           >
      //             <svg
      //               xmlns="http://www.w3.org/2000/svg"
      //               width="15"
      //               height="15"
      //               viewBox="0 0 15 15"
      //               fill="none"
      //             >
      //               <g clipPath="url(#clip0_32_330)">
      //                 <path
      //                   d="M6.63507 -0.0178833C5.50545 -0.0178833 4.59795 0.92077 4.59795 2.05558V2.30769H2.30814C1.99026 2.30769 1.73122 2.56673 1.73122 2.88462V3.46154H1.1543V4.61539H2.30814V13.2692C2.30814 14.2183 3.08987 15 4.03891 15H10.962C11.911 15 12.6928 14.2183 12.6928 13.2692V4.61539H13.8466V3.46154H13.2697V2.88462C13.2697 2.56673 13.0106 2.30769 12.6928 2.30769H10.403V2.055C10.403 0.920771 9.49545 -0.0178833 8.36584 -0.0178833H6.63507ZM6.63507 1.17173H8.36584C8.82968 1.17173 9.21334 1.55019 9.21334 2.055V2.30769H5.78699V2.055C5.78699 1.55019 6.17064 1.17231 6.63449 1.17231L6.63507 1.17173ZM3.46199 4.61539H6.41872C6.49026 4.62289 6.56122 4.63327 6.63507 4.63327H8.36584C8.43968 4.63327 8.51007 4.62289 8.58218 4.61539H11.5389V13.2692C11.5389 13.594 11.2868 13.8462 10.962 13.8462H4.03891C3.7141 13.8462 3.46199 13.594 3.46199 13.2692V4.61539ZM4.61584 5.76923V12.6923H5.76968V5.76923H4.61584ZM6.92353 5.76923V12.6923H8.07737V5.76923H6.92353ZM9.23122 5.76923V12.6923H10.3851V5.76923H9.23122Z"
      //                   fill="#EC0505"
      //                 />
      //               </g>
      //               <defs>
      //                 <clipPath id="clip0_32_330">
      //                   <rect
      //                     width="15"
      //                     height="15"
      //                     fill="white"
      //                     transform="translate(0.000488281)"
      //                   />
      //                 </clipPath>
      //               </defs>
      //             </svg>
      //           </button>
      //         </div>
      //       </div>
      //     );
      //   },
      // },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  useEffect(() => {

    getSchemeList();
  }, [])

  const getSchemeList = () => dispatch(incentiveActions.getIncentivetypeList());

  const handleChange = (e) =>
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

  const handleCheckChange = (name, value) => {
    if (name === "perkg")
      setFormData((prev) => ({
        ...prev,
        [name]: value,
        pervigha: !value
      }));
    else if (name === "pervigha")
      setFormData((prev) => ({
        ...prev,
        [name]: value,
        perkg: !value
      }));
  };

  const validationSchema = yup.object({
    schemeTypeName: yup.string().required("Scheme Type is required"),
    priority: yup.number().required("Field is required").moreThan(0),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    // setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = () => {
    console.log(formData);
    dispatch(incentiveActions.saveIncentivetype(formData, {
      onSuccess: (res) => {
        if (res.duplicate) toast.error(res.message);
        else { getSchemeList(); setFormData({ ...initialValues }) }
      },
      onError: (err) => console.log(err)
    }))
  }

  const onGridDelete = async () => {
    setDeleteLoading(true);
    if (deleteId > 0) {
      dispatch(
        incentiveActions.delete_incentive_type(
          { id: deleteId },
          {
            onSuccess: () => {
              toast.success("Incentive Type Deleted Successfully!!!");
              getSchemeList();
            },
            onError: (err) =>
              toast.error(err),
          }
        )
      );
      setDeleteLoading(false);
      setDeleteModal(false);
    }
  };

  return (
    <PageLayout title={"Incentive Scheme Type Master"}>
      <Form className="m-4 g-2" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={3} md={4} sm={12}>
            <TextField
              {...register("schemeTypeName")}
              label="Scheme Type Name"
              name="schemeTypeName"
              InputLabelProps={{ shrink: true }}
              value={formData?.schemeTypeName}
              onChange={handleChange}
              required
              {...textFieldProps}
            />
            {errors?.schemeTypeName && (
              <ErrorMessage message={errors?.schemeTypeName?.message} />
            )}
          </Col>
          <Col lg={3} md={4} sm={12}>
            <TextField
              {...register("priority")}
              label="Priority"
              name="priority"
              InputLabelProps={{ shrink: true }}
              value={formData?.priority}
              onChange={handleChange}
              type="number"
              required
              {...textFieldProps}
            />
            {errors?.priority && (
              <ErrorMessage message={errors?.priority?.message} />
            )}
          </Col>
          <Col lg={3} md={4} sm={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="pervigha"
                  color="success"
                  size="small"
                  checked={formData.pervigha}
                  onChange={(e) => {
                    handleCheckChange(e.target.name, e.target.checked);
                  }}
                />
              }
              label="Calculate on Per vigha"
              labelPlacement="top"
            />

          </Col>
          <Col lg={3} md={4} sm={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="perkg"
                  color="success"
                  size="small"
                  checked={formData.perkg}
                  onChange={(e) => {
                    handleCheckChange(e.target.name, e.target.checked);
                  }}
                />
              }
              label="Calculate on PerKg"
              labelPlacement="top"
            />

          </Col>

        </Row>
        <Row className="mt-2 g-2">
          <Col lg={1} md={1} sm={12}>
            <Button
              className="custom-btn-page-layout text-white "
              size="small"
              startIcon={<Save />}
              fullWidth
              type="submit"
            >
              {` Save`}
            </Button>
          </Col>
          <Col lg={1} md={1} sm={12}>
            <Button
              type="button"
              size="small"
              className="custom-btn-page-layout custom-btn-danger "
              fullWidth
              startIcon={<Cancel />}
            >
              Cancel
            </Button>
          </Col>

        </Row>
        {/* <div
          style={{ margin: 20, marginRight: "66px" }}
          className="d-flex justify-content-end"
        >
          <button
            type="button"
            className="custom-btn-page-layout btn text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M7.99967 2.66659C10.9403 2.66659 13.333 5.05925 13.333 7.99992C13.333 10.9406 10.9403 13.3333 7.99967 13.3333C5.05901 13.3333 2.66634 10.9406 2.66634 7.99992C2.66634 5.05925 5.05901 2.66659 7.99967 2.66659ZM7.99967 1.33325C4.31767 1.33325 1.33301 4.31792 1.33301 7.99992C1.33301 11.6819 4.31767 14.6666 7.99967 14.6666C11.6817 14.6666 14.6663 11.6819 14.6663 7.99992C14.6663 4.31792 11.6817 1.33325 7.99967 1.33325ZM11.333 7.33325H8.66634V4.66658H7.33301V7.33325H4.66634V8.66658H7.33301V11.3333H8.66634V8.66658H11.333V7.33325Z"
                fill="white"
              />
            </svg>{" "}
            Add Scheme
          </button>
        </div> */}
      </Form>

      <Col md={12}>

        <div
          className="d-grid"
          style={{ margin: "0 20px", border: "1px solid #DEE2E6" }}
        >
          <TableContainer
            columns={column}
            data={incentivetypeList}
            isGlobalFilter={true}
            showColumnFilters={true}
            isAddUserList={false}
            tableClass={"table-sm"}
            showPagination={true}
            initialState={{ pageSize: 20, pageIndex: 0 }}
            divClass={"table-responsive"}
            exportfileName="purchase list"
            exports={{
              excel: true,
              pdf: true,
              tally: false,

            }}
          />
        </div>
      </Col>
      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={onGridDelete}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
    </PageLayout>
  );
};

export default IncentiveSchemeTypeList;
