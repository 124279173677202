const { useSelector } = require("react-redux");

export const usePermissionHooks = (location) => {
  const { permissions } = useSelector((obj) => obj.layout);

  let objPermission = {};

  permissions?.forEach((er) => {
    if (er?.ModuleData?.length > 0) {
      // eslint-disable-next-line
      let filtererdDarta = er?.ModuleData?.filter((ed) => {
        if (ed?.subModuleData?.length > 0) {
          // eslint-disable-next-line
          ed?.subModuleData?.map((qw) => {
            if (qw?.path === location.pathname) {
              objPermission = qw;
            }
          });
        }
        // console.log(er, ed?.path, location.pathname);
        if (ed?.path === location.pathname) {
          objPermission = ed;
        }
      });
      return filtererdDarta;
    } else if (er?.path === location.pathname) {
      objPermission = er;
    }
  });
  return objPermission;
};
