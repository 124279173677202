/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { surveyActions } from "../../redux/actions";
import { useEffect, useMemo, useState } from "react";
import { keys } from "../../config/keys";
import { useLocation, useNavigate } from "react-router-dom";
import { GridAction } from "../../common/useGridAction";
import { toast } from "react-toastify";
import { usePermissionHooks } from "../../common/useCheckPermission";
import { SurveyPDF } from "survey-pdf";
import { Model } from "survey-core";

export const useFarmerSurveyListHooks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const permission = usePermissionHooks(location);

  const { farmer_survey_list } = useSelector((obj) => obj);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [printData, setPrintData] = useState(null);
  const [showprint, setShowPrint] = useState(false);
  const [printType, setPrintType] = useState({ gatepass: true });
  const [openModal, setOpenModal] = useState(false);
  const [viewData, setViewData] = useState();

  const column = useMemo(
    () => [
      {
        Header: "Survey Date",
        accessor: "surveydate",
        Cell: (props) => keys().shortDate(props.value),
      },
      {
        Header: "Farmer",
        accessor: "name",
      },
      {
        Header: "Survey",
        accessor: "title",
      },
      {
        ...GridAction({
          permission: {
            edit: false,
            delete: true,
            print: true,
            printpdf: true,
          },
          // onEditClick: (cellProps) => {
          //   navigate("/farmerpurchases/add", { state: cellProps.row.original });
          // },
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original.id);
          },
          onViewClick: (cellprops) => {
            const s = new Model(cellprops.row.original.surveyjsondata);
            s.data = JSON.parse(cellprops.row.original.surveyJSONData);
            s.mode = "display";
            setViewData({
              farmer: cellprops.row.original.name,
              surveymodel: s,
            });
            setOpenModal(true);
          },
          onPrintPDFClick: (cellprops) => {
            const pdfDocOptions = {
              fontSize: 6,
              fontName: "Helvetica",
              compress: true,
            };

            console.log("DATA", cellprops.row.original.surveyjsondata);
            console.log("NEW DATA", cellprops.row.original.surveyJSONData);
            const s = new Model(cellprops.row.original.surveyjsondata);
            s.data = JSON.parse(cellprops.row.original.surveyJSONData);
            s.mode = "display";
            s.questionsOnPageMode = "singlePage";
            s.showProgressBar = "off";

            const surveyPdf = new SurveyPDF(
              cellprops.row.original.surveyjsondata,
              pdfDocOptions
            );
            surveyPdf.data = s.data;

            console.log(surveyPdf);
            surveyPdf.save(
              `${cellprops.row.original.name}-${cellprops.row.original.title}`
            );
          },
        }),
      },
    ],
    // eslint-disable-next-line
    []
  );

  const onGridDelete = async () => {
    setDeleteLoading(true);
    if (deleteId > 0) {
      dispatch(
        surveyActions.delete_farmer_survey(
          { id: deleteId },
          {
            onSuccess: () => {
              toast.success("Famer Survey Deleted Successfully!!!");
              _farmer_survey_list();
            },
            onError: (err) =>
              toast.error("Something went wrong. Please try again."),
          }
        )
      );
      setDeleteLoading(false);
      setDeleteModal(false);
    }
  };

  const _farmer_survey_list = () =>
    dispatch(
      surveyActions.get_farmer_survey_list({
        onSuccess: () => setLoading(false),
        onError: () => setLoading(false),
      })
    );

  useEffect(() => {
    setLoading(!loading);
    _farmer_survey_list();
    //checkSerialPort();
  }, []);

  return {
    farmer_survey_list,
    loading,
    deleteModal,
    onGridDelete,
    deleteLoading,
    setDeleteModal,
    column,
    permission,
    showprint,
    printData,
    setShowPrint,
    printType,
    openModal,
    viewData,
    setOpenModal,
  };
};
