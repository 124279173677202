import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: "",
  role: "",
  loginDetails: {},
  sidebarOpen: true,
  toggledOpen: true,
  permissions: [],
  imageData: "",
  subopenMenu: {
    selectedMenu: "",
    SubmenuOpen: false,
  },
  sidebarMenu: {
    selectedMenu: "",
    SubmenuOpen: false,
  },
};

const layoutSlice = createSlice({
  name: "layout",
  initialState: initialState,
  reducers: {
    tokenRequest: (state, action) => {
      state.token = action.payload.token;
      state.role = action.payload.role;
      state.loginDetails = action.payload.details;
      state.permissions = action.payload.permissions;
    },

    logOutRequest: (state) => {
      state.token = "";
      state.role = "admin";
    },

    toggleSidebar: (state) => {
      state.sidebarOpen = !state.toggledOpen;
      state.toggledOpen = !state.toggledOpen;
      //state.permanentSidebarOpen = !state.permanentSidebarOpen;
    },
    openSidebar: (state) => {
      if (!state.toggledOpen) state.sidebarOpen = true;
    },
    closeSidebar: (state) => {
      if (!state.toggledOpen) state.sidebarOpen = false;
    },
    getImageData: (state, action) => {
      state.imageData = action.payload;
    },

    updateSidebarMenu: (state, action) => {
      state.sidebarMenu = action.payload;
    },
    updateSubopenMenu: (state, action) => {
      state.subopenMenu = action.payload;
    },
  },
});

export const {
  tokenRequest,
  logOutRequest,
  toggleSidebar,
  getImageData,
  updateSidebarMenu,
  updateSubopenMenu,
  closeSidebar,
  openSidebar,
} = layoutSlice.actions;

const layoutReducer = layoutSlice.reducer;
export default layoutReducer;
