import React, { useEffect, useState, useRef, useCallback } from "react";
import { Label, Input, Row, Col } from "reactstrap";
import { toast } from "react-toastify";
import CustomModal from "../../Component/CustomModal";
import VendorOption from "../../hooks/VendorOption";
import Select from "react-select";
import ProductOption from "../../hooks/ProductOption";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import Flatpickr from "react-flatpickr";
import useBreakPoints from "../../hooks/useBreakPoints";

const EditNewKitIssue = ({
  editModal,
  onCloseModal,
  onSubmitModal,
  singleData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.layout);
  const { isMobile } = useBreakPoints();

  const [vendorArray, setVendorArray] = useState([]);
  const { getVendorOption } = VendorOption();
  // eslint-disable-next-line
  const { getProductOption } = ProductOption();
  const [productArray, setProductArray] = useState([]);
  const getProductListAPi = useCallback(async () => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/productdrop`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        let arr = [];
        // eslint-disable-next-line
        _data?.productList?.map((er) => {
          if (er?.typeName === "RAW MATERIAL") {
            arr.push({ label: er?.productName, value: er?.id });
          }
        });
        setProductArray(arr);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getVendorOption().then((res) => {
      setVendorArray(res);
    });
    getProductListAPi();
    // eslint-disable-next-line
  }, [getProductListAPi]);

  const invoiceDocument = useRef(null);
  const handleInvoiceDocumentUploadBtn = () => invoiceDocument.current.click();

  const [inputData, setInputData] = useState({
    vendor: {
      label: singleData?.vendor?.vendorName,
      value: singleData?.vendor?.id,
    },
    product: {
      label: singleData?.product?.productName,
      value: singleData?.product?.id,
    },
    invoiceNo: singleData?.invoiceNo,
    invoiceDate: singleData?.invoiceDate,
    amount: singleData?.amount,
    remark: singleData?.remark,
    invoiceDoc: {
      url: singleData?.invoiceDoc,
      name: singleData?.invoiceDoc?.split("/").at(-1).slice(5),
    },
  });

  const [inputDataError, setInputDataError] = useState({
    vendor: false,
    product: false,
    invoiceNo: false,
    invoiceDate: false,
    amount: false,
    invoiceDoc: false,
  });

  const handleChangeInput = (name, value) => {
    if (name === "vendor") {
      setInputData({ ...inputData, [name]: value });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, vendor: false });
      } else {
        setInputDataError({ ...inputDataError, vendor: true });
      }
    } else if (name === "product") {
      setInputData({ ...inputData, [name]: value });
      if (value?.label?.length > 0) {
        setInputDataError({ ...inputDataError, product: false });
      } else {
        setInputDataError({ ...inputDataError, product: true });
      }
    } else if (name === "invoiceNo") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, invoiceNo: false });
      } else {
        setInputDataError({ ...inputDataError, invoiceNo: true });
      }
    } else if (name === "amount") {
      setInputData({ ...inputData, [name]: value });
      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, amount: false });
      } else {
        setInputDataError({ ...inputDataError, amount: true });
      }
    } else {
      setInputData({ ...inputData, [name]: value });
    }
  };

  const handleImageChange = async (name, value) => {
    if (value) {
      const allowedTypes = ["image/jpeg", "image/png"];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!allowedTypes.includes(value.type)) {
        toast.error("Invalid file type. Please select a JPEG, PNG.");
      } else if (value.size > maxSize) {
        toast.error("File size exceeds the limit of 5MB.");
      } else {
        if (name === "invoiceDoc") {
          setInputDataError({ ...inputDataError, invoiceDoc: false });
        }

        const formData = new FormData();
        formData.append("media", value);

        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/uploadmedia`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          setInputData({
            ...inputData,
            [name]: { url: _data.uploadedURL, name: _data?.filename },
          });
        } else {
          toast.error(_data?.error);
        }

        return { url: "https://httpbin.org/post" };
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputData?.vendor) {
      setInputDataError({ ...inputDataError, vendor: true });
      toast?.error("Vendor Name is Required!");
    } else if (!inputData?.product) {
      setInputDataError({ ...inputDataError, product: true });
      toast?.error("Product Name is Required!");
    } else if (!inputData?.invoiceNo) {
      setInputDataError({ ...inputDataError, invoiceNo: true });
      toast?.error("Invoice No is Required!");
    } else if (!inputData?.invoiceDate) {
      setInputDataError({ ...inputDataError, invoiceDate: true });
      toast?.error("Invoice Date is Required!");
    } else if (!inputData?.amount) {
      setInputDataError({ ...inputDataError, amount: true });
      toast?.error("Amount is Required!");
    } else if (!inputData?.invoiceDoc) {
      setInputDataError({ ...inputDataError, invoiceDoc: true });
      toast?.error("Invoice Document is Required!");
    } else {
      setLoading(true);
      let formData = new FormData();

      formData.append("vendorId", inputData.vendor.value);
      formData.append("productId", inputData.product.value);
      formData.append("invoiceNo", inputData.invoiceNo);
      formData.append("invoiceDate", inputData.invoiceDate);
      formData.append("amount", inputData.amount);
      formData.append("remark", inputData.remark);
      formData.append("invoiceDoc", inputData?.invoiceDoc?.url);
      formData.append("id", singleData.id);

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}farmer/editkitissue`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setLoading(false);
          setInputData({
            vendor: "",
            product: "",
            invoiceNo: "",
            invoiceDate: "",
            amount: "",
            remark: "",
            invoiceDocument: "",
          });
          onSubmitModal();
        } else {
          setLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setLoading(false);
        toast?.error(error);
        console.log(error);
      }
    }
  };

  return (
    <CustomModal
      loading={loading}
      title={"EDIT KIT ISSUENCE & INVOICE UPDATION"}
      handleSubmit={handleSubmit}
      modalState={editModal}
      onCloseModal={onCloseModal}
      editState={true}
    >
      <Row className={`${isMobile ? "" : " mt-2 g-3"}`} style={{ margin: 20 }}>
        <Col md={12} className={`${isMobile ? "" : "d-flex"}`}>
          <Col md={3}>
            <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
              Vendor <span className="required_span"> * </span>
            </Label>
          </Col>
          <Col sm={9} className="pl-5">
            <Select
              isMulti={false}
              onChange={(e) => {
                handleChangeInput("vendor", e);
              }}
              value={inputData?.vendor}
              options={vendorArray}
              // menuPlacement="top"
              name="choices-single-default"
              id="countryAddCompany"
              className={`w-100 ${
                inputDataError?.vendor ? "border border-danger " : ""
              }`}
            ></Select>
          </Col>
        </Col>
      </Row>
      <Row className={`${isMobile ? "" : " mt-2 g-3"}`} style={{ margin: 20 }}>
        <Col md={12} className={`${isMobile ? "" : "d-flex mx-0"}`}>
          <Col md={3}>
            <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
              Product <span className="required_span"> * </span>
            </Label>
          </Col>
          <Col sm={9}>
            <Select
              isMulti={false}
              onChange={(e) => {
                handleChangeInput("product", e);
              }}
              value={inputData?.product}
              options={productArray}
              // menuPlacement="top"
              name="choices-single-default"
              className={`w-100 ${
                inputDataError?.product ? "border border-danger " : ""
              }`}
            ></Select>
          </Col>
        </Col>
      </Row>

      <Row className={`${isMobile ? "" : " mt-2 g-3"}`} style={{ margin: 20 }}>
        <Col md={12} className={`${isMobile ? "" : "d-flex"}`}>
          <Col md={3}>
            <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
              Invoice No. <span className="required_span">*</span>
            </Label>
          </Col>
          <Col sm={9} className="pl-5">
            <Input
              id="exampleEmail"
              name="invoiceNo"
              value={inputData?.invoiceNo}
              onChange={(e) => {
                handleChangeInput(e.target.name, e.target.value);
              }}
              className={`cust-input ${
                inputDataError?.invoiceNo ? "border-danger" : ""
              }`}
              type="text"
            />
          </Col>
        </Col>
      </Row>
      <Row className={`${isMobile ? "" : " mt-2 g-3"}`} style={{ margin: 20 }}>
        <Col md={12} className={`${isMobile ? "" : "d-flex"}`}>
          <Col md={3}>
            <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
              Invoice Date <span className="required_span">*</span>
            </Label>
          </Col>
          <Col sm={9} className="pl-5">
            <Flatpickr
              className={`form-control ${
                inputDataError?.invoiceDate ? "border border-danger " : ""
              }`}
              options={{
                dateFormat: "Y-m-d",
                minDate: new Date().setMonth(new Date().getMonth() - 1),
                maxDate: new Date(),
              }}
              value={inputData?.invoiceDate}
              onChange={(date, dateStr) => {
                setInputData({ ...inputData, invoiceDate: dateStr });
                if (dateStr?.length > 0) {
                  setInputDataError({
                    ...inputDataError,
                    invoiceDate: false,
                  });
                } else {
                  setInputDataError({
                    ...inputDataError,
                    invoiceDate: true,
                  });
                }
              }}
            />
          </Col>
        </Col>
      </Row>
      <Row className={`${isMobile ? "" : " mt-2 g-3"}`} style={{ margin: 20 }}>
        <Col md={12} className={`${isMobile ? "" : "d-flex"}`}>
          <Col md={3}>
            <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
              Amount <span className="required_span">*</span>
            </Label>
          </Col>
          <Col sm={9} className="pl-5">
            <Input
              id="exampleEmail"
              name="amount"
              value={inputData?.amount}
              onChange={(e) => {
                handleChangeInput(e.target.name, e.target.value);
              }}
              className={`cust-input ${
                inputDataError?.amount ? "border-danger" : ""
              }`}
              type="text"
            />
          </Col>
        </Col>
      </Row>
      <Row className={`${isMobile ? "" : " mt-2 g-3"}`} style={{ margin: 20 }}>
        <Col md={12} className={`${isMobile ? "" : "d-flex"}`}>
          <Col md={3}>
            <Label style={{ fontWeight: 500 }} for="exampleEmail" md={12}>
              Remark{" "}
            </Label>
          </Col>
          <Col sm={9} className="pl-5">
            <Input
              id="exampleEmail"
              name="remark"
              value={inputData?.remark}
              onChange={(e) => {
                handleChangeInput(e.target.name, e.target.value);
              }}
              className="cust-input"
              type="text"
            />
          </Col>
        </Col>
      </Row>
      <Row className={`${isMobile ? "" : " mt-2 g-3"}`} style={{ margin: 20 }}>
        <Col lg={12} md={12} sm={12} className={`${isMobile ? "" : "d-flex"}`}>
          <button
            type="button"
            onClick={handleInvoiceDocumentUploadBtn}
            className={`${
              inputDataError?.invoiceDocument ? "border border-danger" : ""
            }  `}
            style={{
              border: "none",
              color: "white",
              padding: 8,
              background: "linear-gradient(180deg, #C1C1C1 0%, #6D6D6D 100%)",
              borderRadius: 5,
            }}
          >
            Upload
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              style={{ height: 16, width: 16, marginLeft: 2 }}
            >
              <path
                d="M4 12.6667H12"
                stroke="white"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 10H9.33333V8H11L8 5M8 10H6.66667V8H5L8 5"
                stroke="white"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>

          <input
            style={{ display: "none" }}
            type="file"
            accept="image/png, image/jpeg , image/jpg,application/pdf "
            name="invoiceDoc"
            ref={invoiceDocument}
            onChange={(e) =>
              handleImageChange(e.target.name, e.target.files[0])
            }
          />

          <span className="mx-3" style={{ fontWeight: 500, fontSize: 10 }}>
            {inputData?.invoiceDoc
              ? inputData?.invoiceDoc?.name
              : "Upload copy of invoice (PDF/image)"}
          </span>
        </Col>
      </Row>
    </CustomModal>
  );
};

export default EditNewKitIssue;
