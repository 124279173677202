import React from 'react'
import Loader from "../assests/Images/Loader.gif"
const LoaderComponent = () => {
  return (
    <div className='loader-overlay '>
          <img src={Loader} alt="loader " />
    </div>
  )
}

export default LoaderComponent